import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Message } from 'semantic-ui-react';
import styled from 'styled-components';
const QuadrantColors = ['lightgreen', 'lightyellow', 'lightcoral'];

const Quadrant = styled.div<{ isSelected: boolean }>`
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid rgba(34,36,38,.15);
  background-color: ${({ isSelected, color }) => (isSelected ? color : 'white')};
  border-radius:2px;
  transition: background-color 0.5s ease-in-out;
  box-shadow: ${({ isSelected }) => (isSelected ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none')};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  font-size: ${({ isSelected }) => (isSelected ? '18px' : 'inherit')};
  -webkit-print-color-adjust: exact !important;
  &:hover {
    opacity: 0.8;
    font-weight:bold;
    font-size: 18px;
    background-color: ${({ color }) => color};
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 300px;
`;

const HorizontalLabel = styled.div`
  font-size: 18px;
  margin-left: 10%;
  font-weight: bold;
`;

const SmallHorizontalLabel = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  gap: 8%;
  margin-left: 9%;
`;

const VerticalLabel = styled.div`
  display: flex;
  justify-content: center;
  writing-mode: vertical-lr;
  transform: scale(-1);
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
`;

const SmallVerticalLabel = styled.div`
  writing-mode: vertical-lr;
  transform: scale(-1);
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  justify-content: space-evenly;
`;

interface EisenhowerMatrixProps {
  onSelectQuadrant: (quadrant: number) => void;
  selectedQuadrant: number | null;
  showHeader: boolean
}

const EisenhowerMatrix: React.FC<EisenhowerMatrixProps> = ({ onSelectQuadrant, selectedQuadrant, showHeader }) => {
  const { t } = useTranslation()
  return (
    <Grid.Column>
      {showHeader && <Header>{t('priority')}</Header>}
      <Message info>
        <p>{t('priorityInfo')}</p>
      </Message>
      <Grid celled>
        <Grid.Row>
          <VerticalLabel>{t('importanceValue')}</VerticalLabel>
          <SmallVerticalLabel><span>{t('low')}</span> - <span>{t('high')}</span></SmallVerticalLabel>

          <Container>
            <Quadrant color={QuadrantColors[0]} isSelected={selectedQuadrant === 1} onClick={() => onSelectQuadrant(1)}>
              Prio 1
            </Quadrant>
            <Quadrant color={QuadrantColors[1]} isSelected={selectedQuadrant === 2} onClick={() => onSelectQuadrant(2)}>
              Prio 2
            </Quadrant>
            <Quadrant color={QuadrantColors[1]} isSelected={selectedQuadrant === 2} onClick={() => onSelectQuadrant(2)}>
              Prio 2
            </Quadrant>
            <Quadrant color={QuadrantColors[2]} isSelected={selectedQuadrant === 3} onClick={() => onSelectQuadrant(3)}>
              Prio 3
            </Quadrant>
          </Container>
        </Grid.Row>
      </Grid>
      <Grid.Column >
        <SmallHorizontalLabel><span>{t('low')}</span> - <span>{t('high')}</span></SmallHorizontalLabel>
        <HorizontalLabel>{t('difficultyCost')}</HorizontalLabel>
      </Grid.Column>

    </Grid.Column>
  );
};

export default EisenhowerMatrix;
