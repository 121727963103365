import React from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { getState } from '../../state'

//export interface DepartmentDropdownProps extends DropdownProps {}

const DepartmentDropdown: React.FunctionComponent<DropdownProps> = ({ ...dropdownProps }) => {
    const {
        state: { departments },
    } = getState()
    const options = departments.map(d => ({ value: d.id, text: d.name })) ?? []
    return <Dropdown {...dropdownProps} options={options} />
}

export default DepartmentDropdown
