import { Column, ColumnFiltersState, Row, Table, flexRender } from "@tanstack/react-table"
import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components"
import SimpleFilter from "./Filters/SimpleFilter"
import { useTranslation } from "react-i18next"
import { Filter, Plus, XCircle } from 'react-feather'
import { Button, Card, CardContent, CardGroup, CardHeader, Checkbox, Icon, Input, Select } from 'semantic-ui-react'
import SemanticFeatherIcon from "../../icons/SemanticFeatherIcon"
import TableSettings from "./TableSettings"
import DraggableHeader from "./DraggableHeader"
import { useDrop } from "react-dnd";
import { generateReport } from "./ReportExport"
import { getState } from "../../../state"
import { formatDate } from "../../../actions/util"
import GlobalFilter from "./Filters/GlobalFilter"
import DraggableRow from "./DraggableRow"
import ModalWrapper from "../ModalWrapper"

export const countRowsWithSubRows = (rows: any) => {
  let total = rows.length;
  rows.forEach((row: any) => {
    if (row.subRows && row.subRows.length > 0) {
      total += countRowsWithSubRows(row.subRows);
    }
  });
  return total;
};

export const FooterLabel = styled.div`
  font-size: smaller;
  font-weight: bold;
  color: #c0c0c0;
`
interface IShowAllFilter {
  showAll: boolean
  setShowAll: Dispatch<SetStateAction<boolean>>
  checked: ColumnFiltersState
  notChecked: ColumnFiltersState
}

interface ITableReactProps<T = any> {
  table: Table<T>
  rowClassRenderer?: (row: Row<T>) => string
  canExport: boolean,
  hideGroupRowValue?: boolean
  onAddCallback?: () => void
  showAllFilter?: IShowAllFilter
  overflowable?: boolean
  noRightBorder?: boolean
  overrideOnAddTitle?: string
  customToolbarComponent?: React.ReactElement
  draggableRow?: boolean
  onDropRow?: (draggedRow: Row<T>, targetRow: Row<T> | null) => void
}

const pageSize: number[] = [5, 10, 20, 30, 40, 50, 100]
const fileNameMapping = {
  pdf: `Pdf-Export-${formatDate(new Date())}`,
  xls: `Xls-Export-${formatDate(new Date())}`
};

const formatMapping = {
  pdf: 1,
  xls: 0
};
const TableReact = ({ table, onAddCallback, showAllFilter, rowClassRenderer, canExport, overflowable = false, noRightBorder = false,
  hideGroupRowValue = true, overrideOnAddTitle, customToolbarComponent, draggableRow = false, onDropRow }: ITableReactProps) => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const [openExportModal, setOpenExportModal] = useState(false);
  const [exportFormatState, setExportFormatState] = useState<"xls" | "pdf" | null>(null);

  const { dispatch } = getState()
  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn: Column<any>) => {
      draggedColumn.getToggleGroupingHandler()()
    },
  });

  const handleResetFilter = () => {
    table.resetGrouping(true)
    table.resetColumnFilters()
    table.resetSorting()
    table.resetGlobalFilter()
    table.resetExpanded()
    if (showAllFilter) {
      showAllFilter.setShowAll(false)
      table.setColumnFilters(showAllFilter.notChecked)
    }
  }
  const handleSetExportFormat = (format: "xls" | "pdf") => {
    setExportFormatState(format)
    setOpenExportModal(true);
  };
  const handleExportFileName = () => {
    if (exportFormatState === null) return "";
    return fileNameMapping[exportFormatState];
  }

  const handleExportFormat = () => {
    if (exportFormatState === null) return 0;
    return formatMapping[exportFormatState];
  }
  const handleExport = async (type: "all" | "visible") => {
    const fileName = handleExportFileName()
    const format = handleExportFormat()
    if (type === 'all') {
      await generateReport(dispatch)(format, fileName, table.getCoreRowModel().rows ?? [], table.getVisibleFlatColumns().filter(x => x.id !== "actions"))
    } else if (type === 'visible') {
      await generateReport(dispatch)(format, fileName, table.getRowModel().rows ?? [], table.getVisibleFlatColumns().filter(x => x.id !== "actions"))
    }
    setOpenExportModal(false);
  };
  return (
    <Container>
      <ToolbarContainer ref={dropRef} >

        <ToolbarControlsWrapper className="hide-in-print">
          <ToolbarControls>
            {onAddCallback && (
              <Button
                onClick={() => onAddCallback()}
                type="button"
                primary
                icon
                floated="left"
                labelPosition="left"
                title={overrideOnAddTitle ? overrideOnAddTitle : t('addNew')}
              >
                <SemanticFeatherIcon FeatherIcon={Plus} size={'60%'} centerIcon={true} />{' '}
                {overrideOnAddTitle ? overrideOnAddTitle : t('addNew')}
              </Button>
            )}
            {showAllFilter && <Checkbox
              style={{ margin: "0 1em" }}
              label={t('showAll')}
              checked={showAllFilter.showAll}
              onChange={(e, data) => {
                showAllFilter.setShowAll(!!data.checked)
                table.setColumnFilters(data.checked ? showAllFilter.checked : showAllFilter.notChecked)
              }
              }
            />}
            {table.options.enableGlobalFilter !== false && <GlobalFilter table={table} />}
            <div>
              {table.options.enableColumnFilters !== false && <Button
                onClick={() => setShowFilters(!showFilters)}
                floated="right"
                title={t('filter')}
                icon={<SemanticFeatherIcon FeatherIcon={Filter} />}
                content={t('filter')}
                type="button"
              />}
              {table.options.enableHiding !== false && <TableSettings table={table} />}
              {table.options.enableColumnFilters !== false && <Button
                onClick={handleResetFilter}
                floated="right"
                title={t('resetFilters')}
                icon={<SemanticFeatherIcon FeatherIcon={Filter} hasCross />}
                type="button"
              />}
            </div>
            {table.getState().grouping.map(x => (
              <Button
                icon
                size="tiny"
                onClick={() => table.setGrouping(table.getState().grouping.filter(item => item !== x))}
                key={`groupby-${x}`}
                type="button"
              >
                {t(`${x}`)}
                <SemanticFeatherIcon FeatherIcon={XCircle} style={{ marginTop: '-2px' }} />
              </Button>

            ))}
          </ToolbarControls>
          {customToolbarComponent ? customToolbarComponent : null}
        </ToolbarControlsWrapper>
        {table.options.enableGrouping !== false && <DropTargetOverlay className="hide-in-print">{t('dropToGroupBy')}</DropTargetOverlay>}
      </ToolbarContainer>
      <TableContainer overflowable={overflowable}>
        <CustomTable width={table.getTotalSize()}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                <tr>
                  {headerGroup.headers.map((header) => (
                    <DraggableHeader
                      key={header.id}
                      header={header}
                      enableGrouping={table.options.enableGrouping}
                    />
                  ))}
                </tr>
                {showFilters && <tr>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHeader key={header.id} colSpan={header.colSpan} headerWidth={header.getSize()} >
                        {header.isPlaceholder ? null : (
                          <>
                            {header.column.getCanFilter() && !header.column.columnDef?.meta?.filterComponent ? (
                              <SimpleFilter column={header.column} table={table} />
                            ) : null}
                            {header.column.columnDef?.meta?.filterComponent && header.column.columnDef?.meta?.filterComponent(
                              header.column.setFilterValue,
                              header.column.getFilterValue()
                            )}
                          </>
                        )}
                      </TableHeader>
                    )
                  })}
                </tr>}
              </React.Fragment>
            ))}
          </thead>
          <tbody >
            {table.getRowModel().rows.map((row) => (
              <DraggableRow onDropRow={onDropRow} key={row.id} row={row} draggableRow={draggableRow} hideGroupRowValue={hideGroupRowValue} noRightBorder={noRightBorder} rowClassRenderer={rowClassRenderer} />
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <FooterRow key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <TableHeader key={header.id} headerWidth={header.getSize()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </TableHeader>
                ))}
              </FooterRow>
            ))}
          </tfoot>
        </CustomTable>
      </TableContainer>

      <PaginationContainer className="hide-in-print">

        <Button type="button" content={t('previous')} primary onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()} />
        <PaginationItemWrapper>
          <Input
            variant="outlined"
            type="number"
            size="small"
            sx={{ backgroundColor: "white", marginRight: "4px" }}
            label={t('page')}
            value={table.getState().pagination.pageIndex + 1 || 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
          />
          <strong>{t('of')} {table.getPageCount()}</strong>
        </PaginationItemWrapper>
        <PaginationItemWrapper>
          <label>{t('pageSize')}</label>
          <Select value={table.getState().pagination.pageSize}
            onChange={(e, { value }) => table.setPageSize(Number(value))} options={pageSize.map(d => ({ value: d, text: d }))} />
        </PaginationItemWrapper>
        <Button type="button" content={t('next')} primary onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
      </PaginationContainer>

      {canExport && <BottomButtonsContainer>
        <Button
          type="button"
          icon="file excel outline"
          title={t('exportToExcel')}
          onClick={() => handleSetExportFormat("xls")}
        />
        <Button
          type="button"
          icon="file pdf outline"
          title={t('exportToPdf')}
          onClick={() => handleSetExportFormat("pdf")}
        />
      </BottomButtonsContainer>}
      <ModalWrapper title={exportFormatState === "pdf" ? t('exportToPdf') : t('exportToExcel')} open={openExportModal} onClose={() => setOpenExportModal(false)}>
        <CustomCardGroup centered>
          <Card onClick={() => handleExport("all")} raised>
            <CardContent textAlign='center'>
              <CardHeader>{t('all')}</CardHeader>
              <Icon name="eye slash" size='massive' color='grey' />
            </CardContent>
          </Card>
          <Card onClick={() => handleExport("visible")} raised>
            <CardContent textAlign='center'>
              <CardHeader>{t('visible')}</CardHeader>
              <Icon name="eye" size='massive' color='grey' />
            </CardContent>
          </Card>
        </CustomCardGroup>
      </ModalWrapper>
    </Container>
  )
}

export default TableReact

const CustomCardGroup = styled(CardGroup)`
    gap:1em;
    margin:1em !important;
`

const BottomButtonsContainer = styled.div`
    margin-top: 5px;
`

const TableContainer = styled.div<{ overflowable: boolean }>`
    flex: 1 1 0%;
    position: relative;
    ${(p) => p.overflowable && 'overflow-y: auto'};
`

const CustomTable = styled.table<{ width: number }>`
    table-layout: fixed;
    background-color: #ffffff;
    width: ${(p) => `${p.width}px`};
    min-width:fill-available;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 6px;
    font-size: 14px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    .ui.progress{
      margin: 0;
    }

`
const DropTargetOverlay = styled.div`
    padding: 15px;
    width: 100%;
    height: 50px;
    background-color: #dddddd;
    color: white;
    border-radius:4px;
`

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`

const PaginationItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap:4px;

`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  th {
    font-weight: 600;
    padding: 1em;
    text-align: start;
    position: relative;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
 
  thead {
  background: #f9f9f9;
  }


`
const FooterRow = styled.tr`
th {
  border:none;
}
`
const TableHeader = styled.th<{ headerWidth: number }>`
  width: ${(p) => `${p.headerWidth}px`};
`

const ToolbarContainer = styled.div`
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
`

const ToolbarControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const ToolbarControls = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`