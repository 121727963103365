import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import IssueForm from '../../../forms/IssueForm'
import { IDeviationSettingUser } from '../../../../api/issues'

interface IIssueFormPageProps {
    refresh: () => void
    isNew: boolean
    responsibleSafetyUsers: IDeviationSettingUser[]
}

const IssueFormPage = ({ refresh, isNew, responsibleSafetyUsers }: IIssueFormPageProps) => {
    const { t } = useTranslation()
    const { deviationId } = useParams<{ deviationId: string }>()
    const {
        isLoading,
        data: selectedDeviation,
    } = useGetQueryByParams(!!deviationId, API.issues.getById, "selectedDeviation", deviationId)

    if (isLoading) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('deviation').toLowerCase() }) : t('editValueOwnId', { value: t('deviation').toLowerCase(), ownId: selectedDeviation?.ownId })}</Header>
            <IssueForm refresh={refresh} isNew={isNew} selectedDeviation={selectedDeviation} responsibleSafetyUsers={responsibleSafetyUsers} />
        </Container>
    )
}

export default IssueFormPage
