import React, { useRef } from 'react'
import { Row, flexRender } from '@tanstack/react-table'
import { useDrag, useDrop } from "react-dnd";
import styled from 'styled-components';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { ChevronDown, ChevronRight } from 'react-feather';

interface IDraggableRowProps<T = any> {
    row: Row<T>;
    hideGroupRowValue: boolean
    noRightBorder: boolean
    draggableRow: boolean
    rowClassRenderer?: (row: Row<T>) => string
    onDropRow?: (draggedRow: Row<T>, targetRow: Row<T> | null) => void
}
const DraggableRow = ({ row, draggableRow, noRightBorder, hideGroupRowValue, rowClassRenderer, onDropRow }: IDraggableRowProps) => {
    const [{ isDragging }, dragRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: draggableRow,
        item: () => row,
        type: "row",
    });

    const [{ isOver }, dropRef]: any = useDrop({
        accept: 'row',
        drop: (draggedRow: Row<any>) => {
            const draggedIndex = draggedRow.original.id;
            const targetIndex = row.original.id;
            const draggedParent = draggedRow.original.parentId
            if (draggedIndex === targetIndex) return;
            if (draggedParent === targetIndex) return;
            onDropRow && onDropRow(draggedRow, row)
        },
        collect: () => ({
            canDrag: draggableRow,
        }),
    });

    const ref = useRef(null);
    const combinedRef = (el: any) => {
        dragRef(el);
        dropRef(el);  // Make the row both draggable and a drop target
        ref.current = el;
    };
    return (
        <StyledTr
            key={row.id}
            className={rowClassRenderer?.(row)}
            ref={combinedRef}
            isDragging={isDragging}
            draggableRow={draggableRow}
            $isOver={isOver}
        >
            {row.getVisibleCells().map((cell) => (
                <TableData
                    cellWidth={cell.column.getSize()}
                    isAction={cell.id.toLowerCase().includes("actions")}
                    className={cell.id.toLowerCase().includes("actions") ? 'hide-in-print' : undefined}
                    center={cell.column.columnDef.meta?.checkbox ?? false}
                    noRightBorder={noRightBorder}
                    key={cell.id}>
                    {cell.getIsGrouped()
                        ? (
                            <>
                                <SemanticFeatherIcon onClick={row.getToggleExpandedHandler()} FeatherIcon={row.getIsExpanded() ? ChevronDown : ChevronRight} />
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}{' '}
                                ({row.subRows.length})
                            </>
                        )
                        :
                        row.subRows.length && row.index === 0 && hideGroupRowValue
                            ? null
                            : row.subRows.length && !hideGroupRowValue ?
                                flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )
                                : cell.getIsAggregated() ? (
                                    flexRender(
                                        cell.column.columnDef.aggregatedCell ??
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )
                                ) : cell.getIsPlaceholder() ? null
                                    : (
                                        flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )
                                    )}

                </TableData>
            ))}
        </StyledTr>
    );
};
export default DraggableRow

const StyledTr = styled.tr<{ isDragging: boolean, draggableRow: boolean, $isOver: boolean }>`
  opacity: ${(p) => (p.isDragging ? 0.5 : 1)};
  cursor: ${(p) => (p.draggableRow ? 'move' : 'default')};
  background-color: ${props => (props.$isOver ? '#dddddd' : 'transparent')}; /* Change background on hover */
`;
const TableData = styled.td<{ cellWidth: number, isAction: boolean, center: boolean, noRightBorder: boolean }>`
  text-align: ${(p) => p.isAction && "end"};
  width: ${(p) => `${p.cellWidth}px`};
  word-wrap: break-word;
  white-space:pre-wrap;
  padding: 1em 0.2em;
  border-right: ${(p) => p.noRightBorder ? "none" : `1px solid #ddd`}; 
  border-bottom: 1px solid #ddd;
   ${(p) => p.center && `
   text-align: center;
    vertical-align: middle;
   `};
`