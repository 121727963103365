import React from 'react'
import Spacer from '../common/spacer'
import { Routes, Route, NavLink, Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import i18n from '../../i18n'
import { logout } from '../../auth'
import { getState } from '../../state'
import { useTranslation } from 'react-i18next'
import { getModule } from '../../auth/modules'
import DataPrintModule from '../dataprint'
import { BackgroundColor, OnBackgroundActiveLinkColor } from '../../styles'
import { Form, Input } from 'semantic-ui-react'

const AppContainer = styled.div`
    padding: 0;
    margin: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    box-sizing: content-box;
    background-color: ${BackgroundColor};
`

const UpperHeader = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`

const Logo = styled.div`
    display: flex;
    margin: 20px 0 0 20px;

    img {
        float: left;
        max-width: 180px;
    }

    span {
        align-self: flex-end;
        color: white;
        font-weight: bold;
        font-style: italic;
    }
`

const RightSideContainer = styled.div`
    color: white;
`

const LangContainer = styled.div`
    text-align: right;
    margin-bottom: 10px;

    * {
        display: inline-block;
    }
`

const UserInfoContainer = styled.div`
    padding: 10px;
`

const UserInfo = styled.div``

const CompanyInfo = styled.div``

const LogoutButton = styled.button`
    color: white;
    border: none;
    text-decoration: underline;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
`

const ModuleMenu = styled.div`
    margin: 20px 0 0 20px;
`

const ModuleLink = styled(NavLink)`
    display: inline-block;
    color: white;
    padding: 10px;

    &.active {
        background-color: ${OnBackgroundActiveLinkColor};
    }

    &:hover {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100vw;
    padding: 0 20px;
`

const Footer = styled.div`
    justify-self: flex-end;
    box-sizing: content-box;
    padding: 15px 10px;
    background-color: ${BackgroundColor};
    margin-top: 20px;
    color: white;
    display: flex;
    justify-content: space-between;

    a {
        color: white;
        text-decoration: underline;

        &:hover {
            color: gray;
        }
    }
`

const App: React.FunctionComponent = () => {
    const {
        state: { userProfile, modules, company },
    } = getState()
    const { t } = useTranslation()

    const navigate = useNavigate()

    const resolvedModules = modules?.map(m => getModule(m, t)).filter(x => x) ?? []

    return (
        <AppContainer>
            <Header>
                <UpperHeader>
                    <Logo>
                        <Link to="/">
                            <img src="/images/viqma-system-logo.png" alt="VIQMA System" />
                        </Link>
                        <span>...makes it easier, faster and more fun!</span>
                    </Logo>

                    <RightSideContainer>
                        <Form>
                            <Form.Field>
                                <Input
                                    icon="search"
                                    onBlur={(e: any) => navigate(`/search/${e.target.value}`)}
                                />
                            </Form.Field>
                        </Form>
                        <LangContainer>
                            <LogoutButton onClick={() => i18n.changeLanguage('sv')}>
                                sv
                            </LogoutButton>
                            <LogoutButton onClick={() => i18n.changeLanguage('en')}>
                                en
                            </LogoutButton>
                            <LogoutButton onClick={() => logout()}>{t('signout')}</LogoutButton>
                        </LangContainer>

                        <UserInfoContainer>
                            <UserInfo>
                                {t('signedInAs')}: {userProfile?.fullname ?? userProfile?.name} (
                                {userProfile?.email})
                            </UserInfo>

                            <CompanyInfo>
                                {t('company')}: {company?.name ?? ''}
                            </CompanyInfo>
                        </UserInfoContainer>
                    </RightSideContainer>
                </UpperHeader>
                <ModuleMenu>
                    {resolvedModules
                        .filter(m => !m?.excludeFromMenu)
                        .map(m => (
                            <ModuleLink key={m!.key} to={`/${m!.key}`}>
                                {m!.text}
                            </ModuleLink>
                        ))}

                    <ModuleLink to="/dataprint">Print data</ModuleLink>
                </ModuleMenu>
            </Header>
            <Spacer spacing="20px" />
            <ContentContainer>
                <Routes>
                    <Route path="/dataprint" element={<DataPrintModule />} />

                    {resolvedModules
                        .sort((a, b) => b!.key.length - a!.key.length)
                        .map(m => (
                            <Route
                                key={m!.key}
                                path={m?.key ? `/${m!.key}/*` : '*'}
                                element={m!.component}
                            />
                        ))}
                </Routes>
            </ContentContainer>
            <Footer>
                <span>Systemet är utvecklat av VIQMA System</span>

                <span>
                    Maila <a href="mailto:lena@viqma.se">systemansvarig</a> om du har frågor
                </span>
            </Footer>
        </AppContainer>
    )
}

export default App
