import React, { useState } from 'react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { ProcessHierarchyItem } from '../../../hooks/docs/process'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { getDocumentLinkStartFromStartpage } from './flowItem'
import DocumentName from '../../modules/docs/common/documentName'

const DrawerContainer = styled.div<{ level: number }>`
    ${props =>
        props.level === 0
            ? `
        margin-top: 5px;
    `
            : ''}
`

const DrawerContentContainer = styled.div<{ level: number }>``

const ClickableHeader = styled.a`
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.28571429em;
    font-size: 18px;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
        color: rgba(0, 0, 0, 0.87);
        text-decoration: underline;
    }
`

const DocumentLink = styled.a`
    padding: 2px 0;
    display: block;
    font-size: 1em;
    margin-bottom: 2px;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: rgba(0, 0, 0, 0.95);

    &:hover {
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.95);
    }
`

const UnPublishedDocument = styled.span`
    padding: 2px 0;
    display: block;
    font-size: 1em;
    margin-bottom: 2px;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: #7e7e7ef2;
`

const RowContainer = styled.div<{ level: number }>`
    margin-left: ${props => `${props.level * 30}px`};

    display: flex;
    align-items: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`

const ButtonContainer = styled.div`
    flex-basis: 40px;
`

interface ProcessHierarchyDrawerProps {
    item: ProcessHierarchyItem
    level: number
}

const ProcessHierarchyDrawer: React.FunctionComponent<ProcessHierarchyDrawerProps> = ({
    item,
    level,
}) => {
    const [isOpen, setIsOpen] = useState(level === 0)

    const hasPublishedChildren = (item: ProcessHierarchyItem): boolean => {
        if (!item.children?.length) return false

        return item.children.some(c => c.item?.isPublished || hasPublishedChildren(c))
    }

    const renderSingleItem = (item: ProcessHierarchyItem) =>
        item.item?.isPublished ? (
            <RowContainer key={`single-${item.item.id}`} level={level + 1}>
                <ButtonContainer />

                <DocumentLink href={getDocumentLinkStartFromStartpage(item.item.id)}>
                    <DocumentName name={item.item.rubrik} isPublished={item.item.isPublished} />
                </DocumentLink>
            </RowContainer>
        ) : null

    const renderChildrenItem = (item: ProcessHierarchyItem) => (
        <ProcessHierarchyDrawer key={`multi-${item.item?.id}`} item={item} level={level + 1} />
    )

    return (
        <DrawerContainer level={level}>
            {level > 0 && (
                <RowContainer
                    key={`single-${item.item?.id}`}
                    onClick={() => setIsOpen(!isOpen)}
                    level={level}
                >
                    <ButtonContainer>
                        {item.children.length > 0 && (
                            <Button
                                size="tiny"
                                compact
                                icon={
                                    <SemanticFeatherIcon
                                        FeatherIcon={isOpen ? ChevronDown : ChevronRight}
                                        style={{ marginTop: '-2px' }}
                                    />
                                }
                                onClick={() => setIsOpen(!isOpen)}
                            />
                        )}
                    </ButtonContainer>

                    {item.item?.isPublished ? (
                        <DocumentLink href={getDocumentLinkStartFromStartpage(item.item.id)}>
                            <DocumentName
                                name={item.item.rubrik}
                                isPublished={item.item.isPublished}
                            />
                        </DocumentLink>
                    ) : (
                        <UnPublishedDocument>{item.item?.rubrik}</UnPublishedDocument>
                    )}
                </RowContainer>
            )}

            <DrawerContentContainer level={level}>
                {isOpen &&
                    item.children.map(c =>
                        hasPublishedChildren(c) ? renderChildrenItem(c) : renderSingleItem(c),
                    )}
            </DrawerContentContainer>
        </DrawerContainer>
    )
}

export interface ProcessHierarchyDrawersProps {
    process?: ProcessHierarchyItem
}

const ProcessHierarchyDrawers: React.FunctionComponent<ProcessHierarchyDrawersProps> = ({
    process,
}) => {
    if (!process) return null

    return (
        <>
            <ClickableHeader href={getDocumentLinkStartFromStartpage(process.item?.id ?? -1)}>
                {process.item?.rubrik}
            </ClickableHeader>
            <ProcessHierarchyDrawer item={process!} level={0} />
        </>
    )
}

export default ProcessHierarchyDrawers
