import React, { useMemo } from 'react'
import * as API from '../../../../../../api'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams'
import { getGraphColorsFactory } from '../../../../../../styles'
import Loading from '../../../../../common/Loading'
import { useTranslation } from 'react-i18next'
import { buildEffeciencyTitleKey, transformToStatisticsYearMonthRow } from '../../../../../../utils/Mappings/Issues'
import { Grid, GridRow, Header, Message, SemanticWIDTHS } from 'semantic-ui-react'
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { IEffenciencyDeviationStatistic } from '../../../../../../api/issues'
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import TableReact, { FooterLabel } from '../../../../../wrappers/TableReact'
import styled from 'styled-components'
import CustomBarLabel from '../../CustomBarLabel'

const ChartHeader = styled.p`
    font-size: 1.2em;
`
const ChartContainer: React.FunctionComponent<{
    title?: string
    width?: SemanticWIDTHS

}> = ({ title, width = '8', children }) => {
    return (
        <Grid.Column width={width}>
            {title && <ChartHeader as="h6">{title}</ChartHeader>}
            <ResponsiveContainer height={350}>{children as any}</ResponsiveContainer>
        </Grid.Column>
    )
}

interface IEffeciencyStatisticProps {
    selectedYears: number[]
    selectedStats: number[]
    selectedMonths: number[]
}
const EffeciencyStatistic = ({ selectedYears, selectedStats, selectedMonths }: IEffeciencyStatisticProps) => {
    const barColors = getGraphColorsFactory()
    const { t } = useTranslation()

    const {
        isLoading: isLoadingEffeciency,
        data: effeciency,
    } = useGetQueryByParams(selectedStats.includes(3) || selectedStats.includes(4), API.issues.postIssuesEffeciencyFilteredStats, "issuesEffeciency", { selectedYears, selectedMonths })

    const columnHelper = createColumnHelper<IEffenciencyDeviationStatistic>()
    const hasMonth = selectedMonths.length
    const columns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            ...(
                hasMonth
                    ? [columnHelper.accessor('month', {
                        id: 'month',
                        header: t('month') as any,

                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('averageDaysUntilResolve', {
                id: 'averageDaysUntilResolve',
                header: t('averageDaysUntilResolve') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.averageDaysUntilResolve)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
            columnHelper.accessor('count', {
                id: 'count',
                header: t('count') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.count)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t, hasMonth])

    const table = useReactTable({
        data: effeciency || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    const inTimeColumns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            ...(
                hasMonth
                    ? [columnHelper.accessor('month', {
                        id: 'month',
                        header: t('month') as any,

                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('handledInTime', {
                id: 'handledInTime',
                header: t('handledInTime') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.handledInTime)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
            columnHelper.accessor('handledToLate', {
                id: 'handledToLate',
                header: t('handledToLate') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.handledToLate)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
            columnHelper.accessor('count', {
                id: 'count',
                header: t('count') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.count)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t, hasMonth])

    const inTimeTable = useReactTable({
        data: effeciency || [],
        columnResizeMode: "onChange",
        columns: inTimeColumns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoadingEffeciency) {
        return <Loading />
    }

    const mappedData = {
        inTimePieData: effeciency ? Object.values(
            effeciency?.reduce(
                (acc: any, cur: any) => ({
                    ...acc,
                    [cur.year]: [...(acc[cur.year] ?? []), cur],
                }),
                {},
            ),
        ) as any[] : [],
        effeciencyBarData: transformToStatisticsYearMonthRow(
            effeciency ? effeciency : [],
            x => buildEffeciencyTitleKey(x, selectedYears, selectedMonths, t),
            x => ({ [buildEffeciencyTitleKey(x, selectedYears, selectedMonths, t)]: x.averageDaysUntilResolve }),
        ),
    };


    return (
        <>
            {selectedStats.includes(3) &&
                <>
                    <GridRow >
                        <Grid.Column>
                            <Header as='h3' textAlign='center'>{t('remedyEffecieny')}</Header>
                        </Grid.Column>
                    </GridRow>
                    <GridRow >
                        <Grid.Column>
                            <Message info>
                                {t('remedyEffecienyStatsInfo')}
                            </Message>
                        </Grid.Column>
                    </GridRow>
                    <GridRow >
                        <Grid.Column >
                            <TableReact table={table}
                                canExport
                                overflowable />
                        </Grid.Column>
                    </GridRow>
                    <GridRow columns={1}>
                        <Grid.Column>
                            {(mappedData?.effeciencyBarData?.rows?.length ?? 0) > 0 ? (
                                <ResponsiveContainer height={500}
                                >

                                    <BarChart data={mappedData?.effeciencyBarData?.rows}>
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <CartesianGrid vertical={false} />
                                        <Legend layout="horizontal" />
                                        <ReferenceLine y={0} stroke="#000" />
                                        {mappedData?.effeciencyBarData?.dataColumns?.map(x => (
                                            <Bar key={x} stackId="0" dataKey={x} isAnimationActive={false} fill={barColors()}>
                                                <LabelList dataKey={x} content={CustomBarLabel} />
                                            </Bar>
                                        ))}

                                    </BarChart>
                                </ResponsiveContainer>
                            ) : null}
                        </Grid.Column>

                    </GridRow>
                </>
            }

            {selectedStats.includes(4) && (mappedData?.inTimePieData?.length ?? 0) > 0 ? (
                <>
                    <GridRow >
                        <Grid.Column>
                            <Header as='h3' textAlign='center'>{t('deviationsReportedInTime')}</Header>
                        </Grid.Column>
                    </GridRow>

                    <GridRow >
                        <Grid.Column>
                            <Message info>
                                {t('deviationsReportedInTimeStatsInfo')}
                            </Message>
                        </Grid.Column>
                    </GridRow>

                    <GridRow >
                        <Grid.Column >
                            <TableReact table={inTimeTable}
                                canExport
                                overflowable />
                        </Grid.Column>
                    </GridRow>

                    <GridRow columns={1}>
                        <Grid.Column width="16">
                            <Grid stackable>
                                {mappedData?.inTimePieData?.map((yearMonthGroup, index) => {
                                    const transformedData = yearMonthGroup.map((item: any) => {
                                        const total = item.count;
                                        const handledInTimePercentage = total > 0 ? Math.round((item.handledInTime / total) * 100) : 0;
                                        const handledToLatePercentage = total > 0 ? Math.round((item.handledToLate / total) * 100) : 0;

                                        return [
                                            { name: t('handledInTime'), value: handledInTimePercentage },
                                            { name: t('handledToLate'), value: handledToLatePercentage }
                                        ];
                                    });
                                    const key = `${mappedData.inTimePieData[index][0].year}`;
                                    return (
                                        <ChartContainer
                                            key={key}
                                            title={`${key}`}
                                        >
                                            <PieChart>
                                                <Tooltip />
                                                <Pie
                                                    data={transformedData[0]}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    label={({ name, value }) => `${name} (${value}%)`}
                                                    isAnimationActive={false}
                                                >
                                                    {transformedData[0].map((_: any, index: number) => (
                                                        <Cell key={index} fill={barColors()} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ChartContainer>
                                    )
                                })}
                            </Grid>
                        </Grid.Column>
                    </GridRow>
                </>) : null}

        </>
    )
}

export default EffeciencyStatistic