import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import ControlInput from '../../../../fields/ControlInput';
import * as API from '../../../../../api'
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IActionPlanDto } from '../../../../../api/suppliers';
import FormActions from '../../../FormActions';
import { mockedKeyFigure } from '../../../../../utils/MockedData/Suppliers';
import ControlDropdown from '../../../../fields/ControlDropdown';
import { useUserOptionsOverride } from '../../../../../hooks/users';
import ControlDateField from '../../../../fields/ControlDateField';
import ControlTextArea from '../../../../fields/ControlTextArea';
import CloseSection from '../../../common/CloseSection';
import EvaluateSection from '../../../common/EvaluateSection';
import { useIsInRole } from '../../../../../hooks/role';
import { AdminRoles } from '../../../../../auth/roles';
import { mapActionPlanToRequest } from '../../../../../utils/Mappings/Suppliers';
import styled from 'styled-components';
import Files, { UploadAllPendingFilesCallback } from '../../../../common/files';

const StyledGridRow = styled(GridRow)`
    margin: 1em 0;
`
interface IActionPlanFormProps {
    isNew: boolean
    onCancel: () => void
    refresh: () => void
    selectedActionPlan?: IActionPlanDto
    supplierId: number
}

export const actionPlanSchema = z.object({
    id: z.number(),
    status: z.number().optional(),
    companyId: z.number().nullable(),
    supplierId: z.number().nullable(),
    topic: z.string().min(1, "required"),
    responsibleId: z.string().min(1, "required"),
    description: z.string().optional().nullable(),
    doneBefore: z.date().optional().nullable(),
    useReview: z.boolean().optional().nullable(),
    reviewDate: z.date().optional().nullable(),
    reviewResponsibleId: z.string().optional().nullable(),
    reviewText: z.string().optional().nullable(),
    endedDate: z.date().optional().nullable(),
    endedBy: z.string().optional().nullable(),
});

const ActionPlanForm = ({ refresh, onCancel, isNew, selectedActionPlan, supplierId }: IActionPlanFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit, watch } = useForm<IActionPlanDto>({
        resolver: zodResolver(actionPlanSchema),
        defaultValues: selectedActionPlan ? mapActionPlanToRequest(selectedActionPlan) : mockedKeyFigure()
    });
    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()
    const userOptions = useUserOptionsOverride(t)
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.SupplierAdminRole)

    const onKeyFigureRequest = async (data: IActionPlanDto) => {
        if (isNew) {
            return await API.suppliers.createActionPlan(supplierId, data)
        } else {
            return await API.suppliers.editActionPlan(supplierId, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onKeyFigureRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                if (uploadAllPendingFiles) await uploadAllPendingFiles((data.data as any).id?.toString())
                toast.success(t("saved"))
                refresh()
                onCancel()
            }

        },
    })
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlInput name="topic" label={t('topic')} required control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn >
                        <GridColumn>
                            <ControlDropdown
                                required
                                name="responsibleId"
                                label={t('responsible')}
                                control={control}
                                disabled={isSaving}
                                collection={userOptions} />
                        </GridColumn>
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField
                            name="doneBefore"
                            label={t("doneBefore")}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn >
                        <ControlTextArea label={t("description")} name="description" control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                {!isNew && <GridRow >
                    <GridColumn>
                        <CloseSection
                            control={control}
                            isSaving={isSaving}
                            title={t('end')}
                            isAdmin={isAdmin}
                            isNew={isNew}
                            feedback={false} />
                    </GridColumn>
                </GridRow>}
                {!isNew && watch("useReview") ? (
                    <GridRow >
                        <GridColumn>
                            <EvaluateSection control={control} isSaving={isSaving} users={userOptions} reviewBeforeDate={false} />
                        </GridColumn>
                    </GridRow>
                ) : null}
                <StyledGridRow>
                    <GridColumn >
                        <Files
                            displayTitle
                            module="supplier-actionPlan"
                            mayAutoUpload={!!selectedActionPlan?.id}
                            collectionId={selectedActionPlan && selectedActionPlan.id.toString()}
                            onFilesAdded={selectedActionPlan ? undefined : (files, uploadAllPendingFiles) => {
                                if (!files.length) return
                                setUploadAllPendingFiles(() => uploadAllPendingFiles)
                            }}
                        />
                    </GridColumn>
                </StyledGridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <FormActions
                            onCancel={() => onCancel()}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default ActionPlanForm;
