import moment from "moment";
import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";
import { Button } from "semantic-ui-react";

export const DateFormater = ({ row, column }: any) => {
    const value = row[column.key];

    if (value)
        return (
            <>{moment(value).format('Y-MM-DD')}</>
        );
    else
        return null;
}

export const YesNoFormatter = ({ row, column }: any) => {
    const value = row[column.key];
    const { t } = useTranslation();

    return (
        <>
            {value ? t('yes') : t('no')}
        </>
    );
}

const ButtonContentCell = styled.div`
    text-align: center;
`;

export const SingleButtonFormatter = (props: any) => {
    const { onClick, icon, text } = props;

    return (
        <ButtonContentCell>
            <Button icon={icon} style={{ margin: '0' }} label={text} onClick={(e) => { e.stopPropagation(); e.preventDefault(); onClick(props); }} />
        </ButtonContentCell>
    );
}