import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import { getDepartmentFromState, getUserFromState } from '../../../../state/helpers'
import { getState } from '../../../../state'
import { Button, Header, Popup } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import {
    GroupingState,
    createColumnHelper,
    ColumnDef,
    useReactTable,
    getExpandedRowModel,
    getGroupedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    VisibilityState,
    PaginationState,
    ColumnFiltersState,
    SortingState,
    ExpandedState
} from '@tanstack/react-table'
import DateCell from '../../../common/cells/DateCell'
import TableReact from '../../../wrappers/TableReact'
import { projectStatusRowClass } from '../../../wrappers/TableReact/RowClassHelpers'
import { formatDate } from '../../../../actions/util'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import { IProjectDto } from '../../../../api/projects'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Bell, Edit, Trash2 } from 'react-feather'
import { useProjectNotify } from './hooks/useProjectNotify'
import { useDeleteProject } from './hooks/useDeleteProject'
import { DepartmentDisplay } from '../../../common/globalStateDisplay'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { ModuleStatus } from '../../../../api/types/Enum'
import EmptyRequiredCollection from '../../../common/EmptyRequiredCollection'
interface IProjectListProps {
    projects: IProjectDto[]
    refresh: () => void
}

const ProjectList = ({ projects, refresh }: IProjectListProps) => {
    const { isSending, onNotify } = useProjectNotify()
    const { isDestroying, onDestroy } = useDeleteProject(refresh)
    const { t } = useTranslation()
    const { state } = getState()
    const navigate = useNavigate()
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("projectExpandedState", {})
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('projectVisibilityState', {});
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('projectGroupingVisibility', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('projectPaginationState', { pageSize: 10, pageIndex: 0 });
    const [showAll, setShowAll] = useTableStorageBackedState('projectListShowAllState', false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('projectFilteringState', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('projectGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('projectSortingState', []);

    const { isInRole: isAdmin } = useIsInRole(AdminRoles.ProjectsAdminRole)

    const columnHelper = createColumnHelper<IProjectDto>()
    const projectColumns: ColumnDef<IProjectDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 140,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => navigate(`./detail/${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Popup
                            content={!getUserFromState(state)(row.original.responsibleId)?.active ? t('disabledResponsibleReminder') : t('sendReminder')}
                            trigger={
                                <span>
                                    <Button type='button' onClick={() => onNotify(row.original.id)} icon={<SemanticFeatherIcon FeatherIcon={Bell} />} disabled={!getUserFromState(state)(row.original.responsibleId)?.active} loading={isSending} />
                                </span>
                            }
                        />
                        {isAdmin && <Button
                            title={t('delete')}
                            disabled={isDestroying}
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.topic }), projectId: row.original.id })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor((r) => t(`moduleStatus.${r.status}`), {
                id: "status",
                header: t('status'),
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | ModuleStatus[]) => void, value: ModuleStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={ModuleStatus}
                            enumLangKey="moduleStatus"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`moduleStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor((r) => r.ownId.toString(), {
                id: "ownId",
                header: t('ownId'),
                size: 95
            }),
            columnHelper.accessor('topic', {
                id: "topic",
                header: t('topic'),
                size: 600
            }),
            columnHelper.accessor((r) => r.departmentId ? getDepartmentFromState(state)(r.departmentId)?.name : "", {
                id: "departmentId",
                header: t('department'),
                cell: ({ row }) => <DepartmentDisplay departmentId={row.original.departmentId} />
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsibleId'),
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.responsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),

            columnHelper.accessor(r => r.startDate ? formatDate(r.startDate) : "", {
                id: "startDate",
                header: t('projectStart'),
                cell: ({ row }) => <DateCell date={row.original.startDate} />
            }),
            columnHelper.accessor(r => r.endDate ? formatDate(r.endDate) : "", {
                id: "endDate",
                header: t('projectEnd'),
                cell: ({ row }) => <DateCell date={row.original.endDate} />
            }),
            columnHelper.accessor((r) => r.time ? r.time.toString() : "", {
                id: "time",
                header: t('time'),
            }),
            columnHelper.accessor((r) => r.cost ? r.cost.toString() : "", {
                id: "cost",
                header: t('cost'),
            }),

        ], [columnHelper, navigate, state, t, isAdmin, isSending, onNotify, isDestroying, onDestroy])

    const table = useReactTable({
        data: projects || [],
        columns: projectColumns,
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [{ id: "status", value: [ModuleStatus.Active, ModuleStatus.Evaluate] }],
            grouping: [],
            sorting: [{ id: "endDate", desc: false }],
        },
        globalFilterFn: globalFilterFn,
        onExpandedChange: setExpanded,
        onGroupingChange: setGrouping,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    return (
        <FullHeightStackingContainer>
            <Header dividing>{t('projectList')}</Header>
            <EmptyRequiredCollection />
            <TableReact table={table}
                onAddCallback={() => navigate("./detail/new")}
                rowClassRenderer={(r) => projectStatusRowClass(r)}
                canExport
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: [ModuleStatus.Active, ModuleStatus.Evaluate] }] }} />
        </FullHeightStackingContainer>
    )
}

export default ProjectList

