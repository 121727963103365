import useSWR from 'swr'
import { FileListing } from '../../components/common/files'
import { IViqmaDocument } from '../../api/documents'

export interface FileListingWithDocumentName extends FileListing {
    documentName: string
}

export const useDocumentTemplates = (language: string, monthsBack?: number) =>
    useSWR<IViqmaDocument[]>(
        `${window.env.REACT_APP_DOC_SERVICE}/api/document/template?language=${language}${monthsBack ? `&monthsBack=${monthsBack}` : ''
        }`,
    )

export const useDocumentTemplatesForCompany = (companyId: string, monthsBack?: number) =>
    useSWR<IViqmaDocument[]>(
        `${window.env.REACT_APP_DOC_SERVICE}/api/document/template/company/${companyId}${monthsBack ? `&monthsBack=${monthsBack}` : ''
        }`,
    )

export const useDocumentTemplateFiles = (language: string) =>
    useSWR<FileListingWithDocumentName[]>(
        `${window.env.REACT_APP_DOC_SERVICE}/api/document/template/files?language=${language}`,
    )
