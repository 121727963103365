import React, { createContext, Dispatch, SetStateAction, useContext } from 'react'
import { SemanticWIDTHS } from 'semantic-ui-react'
import { SummaryDataItem } from '../../actions/startpage'
import { ModuleData } from './pane'

export type RowInfoBasic = {
    linkUrl?: string | ((row: any) => string)
    linkProperty?: string
    reminderTemplate?: string | ((row: any) => string)
    reminderUserField?: string | ((row: any) => string)
    reminderTrackingSet?: string | ((row: any) => string)
    reminderParameterFactory?: (x: any) => any
    isNegative?: (item: any) => boolean
    hideRowIfUserIsInactive?: boolean
    displayRowIfNoUserSet?: boolean
    userIdField?: string[]
    width?: SemanticWIDTHS
    overrideRenderComponent?: (dataItem: SummaryDataItem) => JSX.Element
    translateColumns?: string[]
    hideColumnIfNoValuesAreSet?: boolean
    hiddenColumns?: string[]
    bypassRouter?: boolean
    overrideCellInnerContent?: Record<string, (row: any, cellValue: any) => JSX.Element>
    overrideHeaderString?: Record<string, string>
    overrideTitle?: string
}

export type RowInfoWithOverride = RowInfoBasic & {
    overrides?: { [id: string]: RowInfoBasic }
}

export type RowInfo = { [id: number]: RowInfoWithOverride }

export type RowInfoFunction = (subTitleKey: string, moduleId: number, row: any) => RowInfoBasic

const RowInfoContext = createContext<RowInfoFunction | undefined>(undefined)

export const RowInfoProvider: React.FC<{ getRowInfo: RowInfoFunction }> = ({
    getRowInfo,
    children,
}) => <RowInfoContext.Provider value={getRowInfo}>{children}</RowInfoContext.Provider>

export const useRowInfoFunction = () => useContext(RowInfoContext)!
