import { FileListing } from "../../components/common/files";
import { get } from "../restClient";

export enum FileCollectionNameType {
    DOCS = "Docs",
    PROJECTS = "Projects",
    GOALS = "Goals",
    IMPROVEMENTIDEAS = "ImprovementIdeas",
    IMPROVEMENTACTIVITIES = "ImprovementActivities",
    ISSUES = "Issues",
    ISSUESACCIDENTS = "IssuesAccidents",
    COMPETENCEUSER = "CompetenceUser"

}


export const getAll = (fileModule: FileCollectionNameType, targetId: string | number) => get<FileListing[]>(`${window.env.REACT_APP_FILE_SERVICE}/api/File/${fileModule}/${targetId}`)


