import React from 'react'
import { Container } from 'semantic-ui-react'
import { useDocumentListing } from '../../../../hooks/docs/listing'
import { getState } from '../../../../state'
import LoadingContainer from '../../../common/loadingContainer'
import DocumentIndex from './documentIndex'

const DocumentIndexPage: React.FunctionComponent = () => {
    const { data: documentListing, isValidating: documentListingLoading } = useDocumentListing()
    const {
        state: { company },
    } = getState()

    return (
        <LoadingContainer loading={documentListingLoading} conditional={true}>
            <Container>
                {!!documentListing?.concernDocuments?.length && (
                    <DocumentIndex
                        documents={documentListing!.concernDocuments}
                        isConcernDocuments={true}
                    />
                )}

                <DocumentIndex
                    documents={documentListing?.companyDocuments ?? []}
                    companyName={company?.name}
                />
            </Container>
        </LoadingContainer>
    )
}

export default DocumentIndexPage
