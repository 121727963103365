import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Form } from 'semantic-ui-react'
import { handleAxiosError } from '../../actions/error'
import { useMobileMode } from '../../hooks/mobileMode'
import { useObjectCategories, useObjects } from '../../hooks/objects'
import { BasicObjectCase, useAdminObjectCase } from '../../hooks/objects/cases'
import { getState } from '../../state'
import DynamicFields, {
    DynamicFieldsSpecification,
    DynamicFieldType,
} from '../common/dynamicFields'
import ErrorBox from '../common/errorBox'
import Files, { UploadAllPendingFilesCallback } from '../common/files'
import SaveCancelPrintButtonGroup from '../common/saveCancelPrintButtonGroup'
import { startOfToday } from 'date-fns'
import moment from 'moment'

const NewObjectCase: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { isInMobileMode } = useMobileMode()
    const {
        state: { userProfile },
        dispatch,
    } = getState()

    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()

    const { data: objects, isValidating: objectsLoading } = useObjects()
    const { data: categories, isValidating: categoriesLoading } = useObjectCategories()
    const { create, error, loading } = useAdminObjectCase()
    const [objectCase, setObjectCase] = useState<BasicObjectCase>({
        title: '',
        priority: 2,
        sendNotificationToDoneBy: true,
        reportedBy: userProfile?.sub ?? '',
        doneBefore: moment(startOfToday()).format('Y-MM-DD')
    })

    const onCreate = async () => {
        const newObjectCase = await create(objectCase)

        try {
            if (uploadAllPendingFiles) await uploadAllPendingFiles(newObjectCase.id?.toString())
            navigate(-1)
        } catch (error: any) {
            handleAxiosError(dispatch)(error)
        }
    }

    const isCaseValid = () =>
        objectCase?.categoryId && objectCase?.objectId && objectCase?.priority && objectCase?.doneBy && objectCase?.doneBefore

    const fields: DynamicFieldsSpecification = {
        title: {
            required: true,
            labelOverride: 'case',
            type: DynamicFieldType.String,
            value: objectCase?.title,
        },

        objectId: {
            required: true,
            labelOverride: 'object',
            type: DynamicFieldType.GenericDropdown,
            value: objectCase?.objectId,
            dropdownItems:
                objects?.map(x => ({ text: `${x.objectNumber}: ${x.name}`, value: x.id })) ?? [],
            loading: objectsLoading,
        },

        categoryId: {
            required: true,
            labelOverride: 'category',
            type: DynamicFieldType.GenericDropdown,
            value: objectCase?.categoryId,
            dropdownItems: categories?.map(x => ({ text: x.name, value: x.id })) ?? [],
            loading: categoriesLoading,
        },

        priority: {
            required: true,
            type: DynamicFieldType.GenericDropdown,
            value: objectCase?.priority,
            dropdownItems: [
                {
                    text: t('high'),
                    value: 3,
                },
                {
                    text: t('medium'),
                    value: 2,
                },
                {
                    text: t('low'),
                    value: 1,
                },
            ],
        },

        doneBy: {
            required: true,
            type: DynamicFieldType.UserDropdown,
            value: objectCase?.doneBy,
        },

        sendNotificationToDoneBy: {
            labelOverride: 'sendMailToHandler',
            required: false,
            type: DynamicFieldType.Boolean,
            value: objectCase?.sendNotificationToDoneBy ?? false,
        },

        doneBefore: {
            required: true,
            type: DynamicFieldType.Date,
            value: objectCase?.doneBefore,
        },

        comment: {
            labelOverride: 'instruction',
            type: DynamicFieldType.Text,
            required: false,
            value: objectCase?.comment,
        },

        externalActor: {
            required: false,
            type: DynamicFieldType.String,
            value: objectCase?.externalActor,
        },

        result: {
            required: false,
            type: DynamicFieldType.Text,
            value: objectCase?.result,
        },
    }

    return (
        <>
            <Form>
                <DynamicFields
                    fields={fields}
                    onChange={(name, value) => 
                        setObjectCase(oldCase => ({ ...oldCase, [name]: value }))
                    }
                    translateFieldNames={true}
                    standaloneGrid={true}
                    columns={isInMobileMode ? 1 : 2}
                />
            </Form>

            <ErrorBox error={error} />

            <Files
                displayTitle={true}
                mayAutoUpload={false}
                module="objects-case"
                enableDragDrop={false}
                onFilesAdded={(files, uploadAllPendingFiles) => {
                    if (!files.length) return
                    setUploadAllPendingFiles(() => uploadAllPendingFiles)
                }}
            />

            <SaveCancelPrintButtonGroup
                location="bottom"
                onCancel={() => navigate(-1)}
                onSave={onCreate}
                disableSave={!isCaseValid() || loading}
            />
        </>
    )
}

export default NewObjectCase
