import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header, Popup } from 'semantic-ui-react'
import { IProjectActivityDto, IProjectRequest, ProjectActivityCheckListStatus, ProjectActivityStatus } from '../../../../api/projects'
import { FieldArrayWithId } from 'react-hook-form'
import { ColumnDef, ColumnFiltersState, ExpandedState, GroupingState, PaginationState, SortingState, VisibilityState, createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getGroupedRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Bell, Edit } from 'react-feather'
import { getUserFromState } from '../../../../state/helpers'
import { getState } from '../../../../state'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { formatDate } from '../../../../actions/util'
import DateCell from '../../../common/cells/DateCell'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import TableReact from '../../../wrappers/TableReact'
import { projectActivityStatusRowClass } from '../../../wrappers/TableReact/RowClassHelpers'
import { useNotifyProjectActivity } from './useNotifyProjectActivity'

export interface IProjectActivityListProps {
    project: IProjectRequest
    activities?: FieldArrayWithId<IProjectRequest, "activities", "id">[]
    activitiesIsChecklist?: boolean
    isNew: boolean
    onAdd(): void
    onActivitySelected(rowData: IProjectActivityDto): void
}
const defaultHiddenColumns: { [x: string]: boolean; } = {
    "description": false,
    "doneBy": false,
    "dateDone": false,
}

const ProjectActivityList: React.FunctionComponent<IProjectActivityListProps> = ({
    project,
    activities,
    activitiesIsChecklist,
    isNew,
    onAdd,
    onActivitySelected,
}) => {
    const { t } = useTranslation()
    const { state } = getState()
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('projectActivityListVisibilityState', defaultHiddenColumns);
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('projectActivityGroupingVisibility', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('projectActivityPaginationState', { pageSize: 10, pageIndex: 0 });
    const [showAll, setShowAll] = useTableStorageBackedState('projectActivityShowAllState', false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('projectActivityFilteringState', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('projectActivityGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('projectActivitySortingState', []);
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("projectActivityExpandedState", {})

    const { isSending, onNotify } = useNotifyProjectActivity()
    const columnHelper = createColumnHelper<IProjectActivityDto>()

    const projectActivityColumns: ColumnDef<IProjectActivityDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableResizing: false,
                enableHiding: false,
                enableGrouping: false,
                size: isNew ? 40 : 95,
                minSize: isNew ? 40 : 95,
                maxSize: isNew ? 40 : 95,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => onActivitySelected(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        {!isNew && <Popup
                            content={!getUserFromState(state)(row.original.responsibleId)?.active ? t('disabledResponsibleReminder') : t('sendReminder')}
                            trigger={
                                <span>
                                    <Button type='button'
                                        onClick={() => onNotify({ activity: row.original, project: project })}
                                        icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                                        disabled={!getUserFromState(state)(row.original.responsibleId)?.active}
                                        loading={isSending} />
                                </span>
                            }
                        />}
                    </>
            }),
            activitiesIsChecklist ? columnHelper.accessor((r) => t(`projectActivityChecklistStatus.${r.status}`), {
                id: "status",
                header: t('done'),
                filterFn: enumMultiFilter,
                size: 80,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | ProjectActivityCheckListStatus[]) => void, value: ProjectActivityCheckListStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={ProjectActivityCheckListStatus}
                            enumLangKey="projectActivityChecklistStatus"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`projectActivityChecklistStatus.${row.original.status}`)}</span>
            }) :
                columnHelper.accessor((r) => t(`projectActivityStatus.${r.status}`), {
                    id: "status",
                    header: t('status'),
                    filterFn: enumMultiFilter,
                    size: 80,
                    meta: {
                        filterComponent: (setFilterValue: (updater: string | ProjectActivityStatus[]) => void, value: ProjectActivityStatus[]) => (
                            <EnumMultiFilterSelect
                                setFilterValue={setFilterValue}
                                value={value}
                                Enum={ProjectActivityStatus}
                                enumLangKey="projectActivityStatus"
                            />
                        ),
                    },
                    cell: ({ row }) => <span>{t(`projectActivityStatus.${row.original.status}`)}</span>
                }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsibleId'),
                size: 100,
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.responsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor('topic', {
                id: "topic",
                header: t('activity'),
                size: 500,
            }),
            columnHelper.accessor((r) => r.time ? r.time.toString() : "", {
                id: "time",
                header: t('time'),
                size: 80
            }),
            columnHelper.accessor((r) => r.cost ? r.cost.toString() : "", {
                id: "cost",
                header: t('cost'),
                size: 80
            }),
            columnHelper.accessor("description", {
                id: "description",
                header: t('description'),
                size: 80,
            }),
            columnHelper.accessor("doneBy", {
                id: "doneBy",
                header: t('doneBy'),
                size: 80,
            }),
            columnHelper.accessor("dateDone", {
                id: "dateDone",
                header: t('dateDone'),
                size: 80,
            }),
            columnHelper.accessor(r => r.doneBefore ? formatDate(r.doneBefore) : "", {
                id: "doneBefore",
                header: t('doneBefore'),
                cell: ({ row }) => <DateCell date={row.original.doneBefore} />
            }),

        ], [columnHelper, state, t, activitiesIsChecklist, onActivitySelected, isNew, isSending, onNotify, project])
    const table = useReactTable({
        data: activities || [],
        columns: projectActivityColumns.filter(x => activitiesIsChecklist ? x.id !== "cost" && x.id !== "time" : x),
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [{ id: "status", value: [ProjectActivityStatus.Active] }],
            grouping: [],
            sorting: [{ id: "doneBefore", desc: false }],
        },
        globalFilterFn: globalFilterFn,
        onExpandedChange: setExpanded,
        onGlobalFilterChange: setGlobalFilter,
        onGroupingChange: setGrouping,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    return (
        <>
            <Header dividing content={t('activity')} />
            <TableReact table={table}
                onAddCallback={onAdd}
                rowClassRenderer={(r) => projectActivityStatusRowClass(r)}
                canExport={false}
                overflowable
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: [ProjectActivityStatus.Active] }] }} />
        </>
    )
}

export default ProjectActivityList
