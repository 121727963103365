import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { handleAxiosError } from '../../actions/error'
import { getAuthedAxios } from '../../auth'
import { getState } from '../../state'
import { getUserFromState } from '../../state/helpers'
import useApi from '../useApi'

export interface ReadConfirmationCaseDto {
    id: number
    documentName: string
    targetedVersion: number
    dateCreated: string
    active: boolean
    confirmations: ReadConfirmationDto[]
}

export interface ReadConfirmationDto {
    id: number
    confirmationCaseId: number
    userId: string
    confirmed: boolean
    dateConfirmed?: string
}

export interface ReadConfirmationDtoWithUser extends ReadConfirmationDto {
    userName: string
    isUserActive: boolean
}

export interface ReadConfirmationCaseCreateDto {
    documentId: number
    positionIds: number[]
}

export const useReadConfirmation = (documentId: string) => {
    const baseUrl = `${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}/readconfirmation`

    const { dispatch } = getState()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<ReadConfirmationDto>()

    useEffect(() => {
        getAuthedAxios().then(axios => {
            setLoading(true)

            return axios
                .get(baseUrl)
                .then(x => {
                    setData(x.data)
                })
                .catch((reason: AxiosError<any>) => {
                    if (reason.response?.status !== 404) {
                        handleAxiosError(dispatch)(reason)
                    }
                })
                .finally(() => setLoading(false))
        })
    }, [])

    return {
        hasPendingReadConfirmation: data !== undefined,
        loading,
        markViewed: () =>
            getAuthedAxios().then(axios => {
                setLoading(true)

                return axios
                    .post(`${baseUrl}/${data!.id}`)
                    .then(() => {
                        setData(undefined)
                    })
                    .catch(handleAxiosError(dispatch))
                    .finally(() => setLoading(false))
            }),
    }
}

export const useReadConfirmationCases = (includeInactive: boolean) => {
    const { dispatch, state } = getState()
    const [localError, setLocalError] = useState()
    const { data, loading, mutate, post, error, setLoading } = useApi<
        ReadConfirmationCaseDto[],
        ReadConfirmationCaseCreateDto
    >(
        `${window.env.REACT_APP_DOC_SERVICE}/api/readconfirmation?includeInactive=${includeInactive}`,
        {
            mutateDataFetched: data =>
                data.map(d => {
                    d.confirmations = d.confirmations
                        .map(x => {
                            const user = getUserFromState(state)(x.userId)

                            return {
                                ...x,
                                userName: user?.name,
                                isUserActive: user?.active ?? false,
                            } as ReadConfirmationDtoWithUser
                        })
                        .filter(d => d.isUserActive)

                    return d
                }),
        },
    )

    const inlineUpdateCases =
        (updatedCase: ReadConfirmationCaseDto) => (cases?: ReadConfirmationCaseDto[]) =>
            cases?.map(existingCase =>
                existingCase.id === updatedCase.id ? updatedCase : existingCase,
            )

    return {
        data,
        loading,
        error: error ?? localError,
        create: (data: ReadConfirmationCaseCreateDto) =>
            post<ReadConfirmationCaseDto>(data)
                .then((newCase: ReadConfirmationCaseDto | void) => {
                    if (!newCase) return

                    mutate(ls => [newCase, ...(ls ?? [])])
                })
                .catch(handleAxiosError(dispatch)),

        addPositions: (caseId: number, newPositions: number[]) =>
            getAuthedAxios().then(axios => {
                setLoading(true)
                setLocalError(undefined)
                return axios
                    .post<ReadConfirmationCaseDto>(
                        `${window.env.REACT_APP_DOC_SERVICE}/api/readconfirmation/${caseId}`,
                        newPositions,
                    )
                    .then(response => {
                        const { data: updatedCase } = response
                        mutate(inlineUpdateCases(updatedCase))
                    })
                    .catch(err => setLocalError(err))
                    .finally(() => setLoading(false))
            }),

        setActive: (caseId: number, isActive: boolean) =>
            getAuthedAxios().then(axios => {
                setLoading(true)
                setLocalError(undefined)
                return axios
                    .post<ReadConfirmationCaseDto>(
                        `${window.env.REACT_APP_DOC_SERVICE}/api/readconfirmation/${caseId}/active?isActive=${isActive}`,
                    )
                    .then(response => {
                        const { data: updatedCase } = response
                        mutate(inlineUpdateCases(updatedCase))
                    })
                    .catch(err => setLocalError(err))
                    .finally(() => setLoading(false))
            }),

        deleteUser: (caseId: number, userId: string) =>
            getAuthedAxios().then(axios => {
                setLoading(true)
                setLocalError(undefined)
                return axios
                    .delete<ReadConfirmationCaseDto>(
                        `${window.env.REACT_APP_DOC_SERVICE}/api/readconfirmation/${caseId}/user/${userId}`,
                    )
                    .then(response => {
                        const { data: updatedCase } = response
                        mutate(inlineUpdateCases(updatedCase))
                    })
                    .catch(err => setLocalError(err))
                    .finally(() => setLoading(false))
            }),
    }
}
