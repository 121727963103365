import * as API from "../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { IAuthorizedUserNotifyRequest } from "../../../api/improvements"
import { handleAxiosError } from "../../../actions/error"
import { getState } from "../../../state"

export function useSendAuthorizedNotify() {
    const { t } = useTranslation()
    const { dispatch } = getState()
    const notificationAuthorized = async (data: IAuthorizedUserNotifyRequest) => await API.improvements.notifyAuthorized(data)

    const { isLoading: isSending, mutate: onNotifyAuthorized } = useMutation(notificationAuthorized, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })

    return {
        isSending,
        onNotifyAuthorized,
    }
}