import { AccidentWithCreationOptionsDto, DeviationMethod, IActivityDto, IIssueRequest } from "../../../api/issues";
import { ProfileWithRoles } from "../../../auth/roles";

export const mockedIssueRequest = (userProfile: ProfileWithRoles | undefined): IIssueRequest => ({
    type: null,
    errorCode: null,
    causeCode: null,
    topic: '',
    departmentId: null,
    locationId: null,
    responsibleId: '',
    supplierId: null,
    customerSupplier: '',
    doneBefore: null,
    description: '',
    actionsTaken: '',
    reportedBy: userProfile?.sub ?? '',
    reportDate: new Date(),
    extraInformation1: '',
    extraInformation2: '',
    useReview: true,
    reviewDate: null,
    reviewResponsibleId: "",
    reviewBeforeDate: null,
    reviewText: "",
    endedDate: null,
    endedBy: "",
    analysis: "",
    resolvingAction: "",
    time: null,
    cost: null,
    feedback: false,
    sendEmailToResponsible: true,
    supplierShouldReply: false,
    sendDeviationManagementEmail: false,
    sent: null,
    replied: null,
    alternativeEmail: "",
    extraFields: {},
    teamMembers: [],
    desiredEffect: false,
    desiredEffectComment: "",
    deviationMethod: DeviationMethod.Pdca,
    sendEmailToTeamMembers: false,
    sendEightDReportToTeamMembers: false
});

export const mockedAccident = (userProfile: ProfileWithRoles | undefined): AccidentWithCreationOptionsDto => ({
    id: 0,
    companyId: 0,
    ownId: 0,
    type: 0,
    isReported: false,
    responsibleId: '',
    safetyOfficerId: null,
    departmentId: 0,
    reportedBy: userProfile?.sub ?? '',
    reportDate: new Date(),
    extentOfOutcome: '',
    accidentDate: null,
    accidentTime: '',
    accidentName: '',
    employmentType: null,
    employmentYear: null,
    whatHappened: null,
    location: '',
    task: '',
    factor: '',
    differencesFromNormal: '',
    conditionalEffects: '',
    locationHadImpact: false,
    equipmentHadImpact: false,
    workEnvironmentHadImpact: false,
    preventiveActionsHadImpact: false,
    companyClimateHadImpact: false,
    educationHadImpact: false,
    otherCausesHadImpact: false,
    estimatedAbsenceTime: null,
    description: '',
    actionsTaken: '',
    analysis: '',
    resolvingAction: '',
    doneBefore: null,
    useReview: true,
    endedDate: null,
    endedBy: '',
    status: 0,
    reviewText: '',
    reviewDate: null,
    reviewResponsibleId: '',
    reviewBeforeDate: null,
    projectId: null,
    customerSupplier: '',
    locationId: null,
    sendEmailToResponsible: true,
    sendEmailToSafetyOfficer: false,
    feedback: false,
    extraFields: {}
});

export const mockedActivity: IActivityDto = {
    id: 0,
    activity: "",
    description: "",
    responsibleId: "",
    doneBefore: null,
    status: 1,
    doneDate: null,
    doneBy: "",
    closingNotifyResponsible: false,
    creationNotifyResponsible: false
}