import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../api'
import { IConnectPositionSkillRequest, ISkillsListViewDto } from '../../../api/skills';
import FormActions from '../FormActions';
import ControlDropdown from '../../fields/ControlDropdown';
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams';
import Loading from '../../common/Loading';

export interface IConnectPositionFormProps {
    skill: ISkillsListViewDto
    onCancel: () => void
    refresh: () => void
}

export const connectPositionRequestSchema = z.object({
    id: z.number().min(1, "required"),
    positionIds: z.array(z.number()).min(1, "required"),
});

const ConnectPositionForm = ({ skill, onCancel, refresh }: IConnectPositionFormProps) => {
    const { t } = useTranslation();
    const { dispatch } = getState()
    const { control, handleSubmit } = useForm<IConnectPositionSkillRequest>({
        defaultValues: { id: skill.id, positionIds: [] },
        resolver: zodResolver(connectPositionRequestSchema),
    });

    const {
        isLoading: isLoadingPositions,
        data: positions,
    } = useGetQueryByParams(true, API.skills.getPositions, "positions")

    const onUserRequest = async (data: IConnectPositionSkillRequest) => await API.skills.editSkillPositionConnect(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onUserRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    if (isLoadingPositions || !positions) {
        return <Loading />
    }
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlDropdown
                            name="positionIds"
                            label={t('skillPositions')}
                            popupText={t('addMultiValue', { value: t('skillPositions').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            collection={positions.companyPositions.filter(x => !skill.positions.some(p => p.id === x.id)).map(d => ({ value: d.id, text: d.name })) ?? []} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default ConnectPositionForm;