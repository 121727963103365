import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader, Tab } from 'semantic-ui-react'
import styled from 'styled-components'
import { buildPanes, ModuleData } from './pane'
import { useRowInfoFunction } from './rowInfo'

const NoDataMessage = styled.span`
    color: gray;
    font-size: 3em;
    border-bottom: 2px solid gray;
    margin: 40px auto 0 auto;
    padding: 10px;
    display: inline-block;
`

export interface SearchPageTabsProps {
    moduleData: ModuleData
    initialLoadComplete: boolean
    onSelectedModuleChange?: (module: string) => void
}

const SearchPageTabs: React.FunctionComponent<SearchPageTabsProps> = ({
    moduleData,
    onSelectedModuleChange,
    initialLoadComplete,
}) => {
    const { t } = useTranslation()

    const getRowInfo = useRowInfoFunction()
    const panes = buildPanes(moduleData, t, getRowInfo, true)

    const noData = !Object.values(moduleData).some(x => x.some(z => z.rows?.length))
    if (noData && initialLoadComplete) return <NoDataMessage>{t('noSearchHits')}</NoDataMessage>
    if (!initialLoadComplete) return <Loader active={true} />

    return (
        <Tab
            panes={panes as any}
            menu={{ fluid: true, vertical: true, tabular: true }}
            onTabChange={(_, c) => {
                const pane = c.panes?.[c.activeIndex as number]
                if (!pane) return

                const module = (pane as { key: string }).key
                onSelectedModuleChange?.(module)
            }}
        />
    )
}

export default SearchPageTabs
