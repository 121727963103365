import React, { useEffect, useState } from 'react'
import { ArrowLeftCircle, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Dropdown, Form, Grid, Input, Modal } from 'semantic-ui-react'
import ActionButton from '../common/actionButton'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import {
    FieldDataItem,
    FieldTypeOption,
    MobileSystematicTaskFieldDataType,
} from './EditMobileSystematicTask'

export interface EditFieldModalProps {
    open: boolean
    onClose: () => void
    field: FieldDataItem | null
    onSave: (field: FieldDataItem) => void
    fieldTypeOptions: FieldTypeOption[]
}

const EditFieldModal = ({
    open,
    onClose,
    field,
    onSave,
    fieldTypeOptions,
}: EditFieldModalProps) => {
    const { t } = useTranslation()

    const [newField, setNewField] = useState<FieldDataItem | null>(null)

    useEffect(() => {
        if (field) {
            setNewField(field)
            return
        }
        setNewField({
            label: '',
            order: 0,
            type: MobileSystematicTaskFieldDataType.CheckboxWithComment,
            required: false,
        })
    }, [field, open])

    if (!newField) return null

    const onSaveClick = () => {
        onSave(newField)
    }

    const onChange = (e: any, { name, value }: any) => {
        setNewField({ ...newField, [name]: value })
    }

    const onRequiredChange = (e: any, { name, checked }: any) => {
        setNewField({ ...newField, [name]: checked })
    }

    return (
        <Modal open={open} size="small">
            <Modal.Header>
                {newField?.id !== undefined
                    ? t('editFieldForMobileForm')
                    : t('addFieldForMobileForm')}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Grid stackable>
                        <Grid.Row columns="1">
                            <Grid.Column>
                                <Form.Field
                                    required
                                    control={Input}
                                    options={fieldTypeOptions}
                                    name="label"
                                    value={newField.label ?? ''}
                                    label={t('fieldName')}
                                    onChange={onChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="2">
                            <Grid.Column>
                                <Form.Field
                                    required
                                    control={Dropdown}
                                    options={fieldTypeOptions}
                                    name="type"
                                    value={newField.type ?? ''}
                                    label={t('type')}
                                    onChange={onChange}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    control={Checkbox}
                                    name="required"
                                    checked={newField.required ?? false}
                                    label={t('required')}
                                    onChange={onRequiredChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <ActionButton
                    action={async () => onSaveClick()}
                    postActionCallback={() => onClose()}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                    disabled={!newField.label}
                >
                    <SemanticFeatherIcon FeatherIcon={Plus} size={'60%'} centerIcon={true} />
                    {newField?.id !== undefined ? t('save') : t('add')}
                </ActionButton>

                <Button onClick={() => onClose()} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default EditFieldModal
