import React from 'react'
import ModuleMenu from '../../../common/moduleMenu'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AdminRoles, isInRole } from '../../../../auth/roles'
import { getState } from '../../../../state'

const SkillsMenu: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const {
        state: { userProfile },
    } = getState()

    /* eslint react/jsx-key: "off" */
    return ModuleMenu({
        all: [<NavLink to={`./`}>{t('skills')}</NavLink>],
        admin: isInRole(userProfile, AdminRoles.CompetenceAdmin) ? [
            <NavLink to={`./admin/users`}>{t('users')}</NavLink>,
            <NavLink to={`./admin/positions`}>{t('skillPositions')}</NavLink>,
            <NavLink to={`./admin/skills`}>{t('skills')}</NavLink>,
            // <NavLink to={`./admin/educations`}>{t('educations')}</NavLink>,
            // <NavLink to={`./admin/matrices`}>{t('matrices')}</NavLink>,
            // <NavLink to={`./admin/engagementStatistics`}>{t('engagementStatistics')}</NavLink>,


        ]
            : undefined,
    })
}

export default SkillsMenu
