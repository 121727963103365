import { Action } from '../reducer'
import { getAuthedAxios } from '../auth'
import { handleAxiosError } from './error'

const configureFileAccessCookie = (dispatch: React.Dispatch<Action>) =>
    getAuthedAxios().then(axios =>
        axios
            .get(window.env.REACT_APP_FILE_SERVICE + '/api/Cookie', {
                withCredentials: true,
            })
            .then(x => {
                const refreshIn = x.data.expiresIn - 60 * 5
                setTimeout(configureFileAccessCookie, refreshIn * 1000)
            }, handleAxiosError(dispatch)),
    )

export default configureFileAccessCookie
