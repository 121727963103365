import React, { useState } from 'react'
import { CheckSquare, Plus, Square } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Container, Dimmer, Grid, Loader, Table } from 'semantic-ui-react'
import { formatDate } from '../../../../actions/util'
import { useReadConfirmationCases } from '../../../../hooks/docs/readConfirmation'
import ErrorBox from '../../../common/errorBox'
import FlexBox from '../../../common/flexBox'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import CreateReadConfirmationModal from './createReadConfirmationModal'
import ReadConfirmationsTable from './readConfirmationsTable'

const DocumentReadConfirmationAdminPage: React.FunctionComponent = () => {
    const { t } = useTranslation()

    const [includeInactive, setIncludeInactive] = useState(false)
    const {
        data: readConfirmationCases,
        create,
        error,
        addPositions,
        setActive,
        deleteUser,
        loading,
    } = useReadConfirmationCases(includeInactive)
    const [selectedReadConfirmationCaseId, setSelectedReadConfirmationCaseId] = useState<number>()

    return (
        <Container>
            <Grid>
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>
                <Grid.Column width={6}>
                    <ErrorBox error={error} />
                    <FlexBox alignItems="flex-end" justifyContent="space-between">
                        <CreateReadConfirmationModal
                            title={t('createReadConfirmationCase')}
                            trigger={
                                <Button icon labelPosition="left" primary type="button">
                                    <SemanticFeatherIcon
                                        FeatherIcon={Plus}
                                        size={'60%'}
                                        centerIcon={true}
                                    />{' '}
                                    {t('new')}
                                </Button>
                            }
                            onSave={create}
                        />

                        <Checkbox
                            checked={includeInactive}
                            onChange={(_, d) => setIncludeInactive(d.checked ?? false)}
                            label={t('showClosed')}
                        />
                    </FlexBox>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>{t('topic')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('version')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {readConfirmationCases?.map(x => (
                                <Table.Row
                                    key={x.id}
                                    onClick={() => setSelectedReadConfirmationCaseId(x.id)}
                                    active={selectedReadConfirmationCaseId === x.id}
                                    className="clickable"
                                    positive={!x.confirmations?.some(c => !c.confirmed)}
                                >
                                    <Table.Cell collapsing>
                                        <SemanticFeatherIcon
                                            FeatherIcon={x.active ? Square : CheckSquare}
                                            color={x.active ? undefined : 'green'}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{x.documentName}</Table.Cell>
                                    <Table.Cell>{x.targetedVersion}</Table.Cell>
                                    <Table.Cell>{formatDate(x.dateCreated)}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={10}>
                    <ReadConfirmationsTable
                        readConfirmationCase={readConfirmationCases?.find(
                            x => x.id === selectedReadConfirmationCaseId,
                        )}
                        addPositions={addPositions}
                        setActive={setActive}
                        removeUser={deleteUser}
                    />
                </Grid.Column>
            </Grid>
        </Container>
    )
}

export default DocumentReadConfirmationAdminPage
