import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Grid, Header, Input, InputProps } from 'semantic-ui-react'
import { ColoredSegment } from '.'
import { AdminRoles } from '../../../auth/roles'
import {
    Measurement,
    useMeasurements,
    useStandardMeasurements,
} from '../../../hooks/goals/measurement'
import { useIsInRole } from '../../../hooks/role'
import LinkButton from '../../common/linkButton'
import CreateMeasurementModal from '../common/createMeasurementModal'
import MeasurementConnection, { MeasurementConnectionProps } from './measurementConnection'

export interface MeasurementSegmentProps extends MeasurementConnectionProps {
    isKpi?: boolean
    targetValue?: number
    targetValueUpdated: (value: number) => void
    isClosed?: boolean
}

const MeasurementSegment: React.FunctionComponent<MeasurementSegmentProps> = ({
    onMeasurementSelected,
    connectedMeasurement,
    isKpi,
    initialValueId,
    targetValue,
    targetValueUpdated,
    isClosed,
}) => {
    const { t } = useTranslation()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.GoalsAdminRole)
    const { data: measurements, loading: measurementsLoading, create, error } = useMeasurements()
    const { data: standardMeasurements, loading: standardMeasurementsLoading } =
        useStandardMeasurements()

    const onMeasurementCreated = (measurement: Measurement) =>
        create(measurement).then(createdMeasurement => {
            onMeasurementSelected(
                { id: createdMeasurement.id ?? 0, isStandard: false },
                createdMeasurement.measurements?.[0]?.id ?? 0,
            )
        })

    return (
        <ColoredSegment $closed={!!isClosed}>
            <Header content={t('measurement')} />

            {isAdmin && (
                <p style={{ marginTop: '-10px' }}>
                    {t('chooseExistingOrCreate')}
                    <CreateMeasurementModal
                        error={error}
                        trigger={<LinkButton content={t('newMeasurement')} />}
                        onCreate={onMeasurementCreated}
                        requireFirstValueCreated={true}
                    />
                </p>
            )}

            <Grid columns={2}>
                <MeasurementConnection
                    measurements={measurements}
                    standardMeasurements={standardMeasurements}
                    measurementsLoading={measurementsLoading || standardMeasurementsLoading}
                    isKpi={isKpi}
                    onMeasurementSelected={onMeasurementSelected}
                    connectedMeasurement={connectedMeasurement}
                    initialValueId={initialValueId}
                />

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Form.Field
                            label={t('targetValue')}
                            control={Input}
                            value={targetValue ?? ''}
                            type="number"
                            onChange={(_: Event, data: InputProps) =>
                                targetValueUpdated(!data.value ? null : data.value)
                            }
                            readOnly={!isAdmin}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </ColoredSegment>
    )
}

export default MeasurementSegment
