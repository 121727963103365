import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Dropdown, Form, Header, Input, Message } from 'semantic-ui-react'
import { handleAxiosError } from '../../actions/error'
import { getModuleSummary, getTabOrder } from '../../actions/startpage'
import { hasModule } from '../../auth/modules'
import { usePositionOptions } from '../../hooks/positions'
import { useDebouncedState } from '../../hooks/useDebouncedState'
import { getState } from '../../state'
import { getSearchDataUri } from './dataUris'
import { ModuleData } from './pane'
import { RowInfoProvider } from './rowInfo'
import { getSearchPageRowInfo } from './searchPageRowInfo'
import SearchPageTabs from './searchPageTabs'
import { FormGroupWithoutBottomMargin } from './startPage'

const SearchPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()
    const { searchPhrase: initialSearchPhrase = '' } = useParams()
    const [searchPhrase, setSearchPhrase] = useDebouncedState<string | undefined>(
        initialSearchPhrase,
        500,
    )

    const [showFilterPosition, setShowFilterPosition] = useState(true)
    const [filterPosition, setFilterPosition] = useState<number | undefined>()
    const positionOptions = usePositionOptions()

    const [moduleData, setModuleData] = useState<ModuleData>({})
    const [loading, setLoading] = useState(false)
    const [initialLoadComplete, setInitialLoadComplete] = useState(false)

    const getRowInfo = getSearchPageRowInfo(setModuleData)

    useEffect(() => {
        setLoading?.(true)

        const jobs = getTabOrder()
            .filter(moduleId => hasModule(state)(moduleId))
            .map(moduleId => {
                const dataUri = getSearchDataUri(searchPhrase ?? '', filterPosition)[moduleId]
                if (!dataUri) return Promise.reject('not configured')

                return getModuleSummary(
                    state,
                    t,
                    dataUri,
                    getRowInfo,
                )(moduleId)
                    .then(value =>
                        setModuleData(moduleData => ({ ...moduleData, [moduleId]: value })),
                    )
                    .catch(handleAxiosError(dispatch))
            })

        Promise.allSettled(jobs).finally(() => {
            setLoading?.(false)
            setInitialLoadComplete(true)
        })
    }, [searchPhrase, filterPosition])

    return (
        <>
            <Header>{t('searchPage')}</Header>
            <Message>
                <Form>
                    <FormGroupWithoutBottomMargin>
                        <Form.Field>
                            <Input
                                defaultValue={searchPhrase}
                                onChange={(_, data) => setSearchPhrase(data.value)}
                                icon="search"
                                placeholder={t('searchPhrase')}
                                loading={loading}
                                disabled={loading}
                            />
                        </Form.Field>
                        {showFilterPosition && (
                            <Form.Field>
                                <Dropdown
                                    options={positionOptions}
                                    value={filterPosition}
                                    onChange={(_, x) => setFilterPosition(x.value as number)}
                                    clearable
                                    search
                                    selection
                                    placeholder={t('filterResponsiblePosition')}
                                />
                            </Form.Field>
                        )}
                    </FormGroupWithoutBottomMargin>
                </Form>
            </Message>
            <RowInfoProvider getRowInfo={getRowInfo}>
                <SearchPageTabs
                    moduleData={moduleData}
                    initialLoadComplete={initialLoadComplete}
                    onSelectedModuleChange={module => {
                        if (module === 'docs') {
                            setShowFilterPosition(true)
                        } else {
                            setShowFilterPosition(false)
                            setFilterPosition(undefined)
                        }
                    }}
                />
            </RowInfoProvider>
        </>
    )
}

export default SearchPage
