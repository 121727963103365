import React from "react"
import styled from "styled-components"
import { Modal, ModalHeader, ModalContent, SemanticShorthandItem, ModalDimmerProps } from "semantic-ui-react"

interface IModalWrapperProps {
  title: string
  open: boolean
  children: JSX.Element | JSX.Element[] | null
  onClose: () => void
  size?: "small" | "mini" | "tiny" | "large" | "fullscreen" | undefined
  className?: string
  dimmer?: SemanticShorthandItem<ModalDimmerProps>
}


const CustomDialogContent = styled(ModalContent)`
  padding-top: 10px !important;
`

const ModalWrapper = ({ children, open, title, onClose, size = "large", className = "", dimmer }: IModalWrapperProps) => {
  return (
    <Modal dimmer={dimmer} size={size} open={open} onClose={onClose} closeIcon className={className}>
      <ModalHeader>{title}</ModalHeader>
      <CustomDialogContent>{children}</CustomDialogContent>
    </Modal>
  )
}

export default ModalWrapper
