import React, { useState, useRef, useEffect, useMemo } from 'react'
import {
    Grid,
    GridRow,
    GridColumn,
    Form,
    Header,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Files, { UploadAllPendingFilesCallback } from '../../common/files'
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup'
import { getState } from '../../../state'
import { ProjectActivityDialog } from './projectActivityDialog'
import ProjectPrintView from './printView'
import DynamicFields from '../../common/dynamicFields'
import { AdminRoles } from '../../../auth/roles'
import { useUserOptionsOverride } from '../../../hooks/users'
import { IProjectActivityDto, IProjectDetailsDto, IProjectRequest, IProjectTemplateDto } from '../../../api/projects'
import ApplyProjectTemplateBox from './applyProjectTemplateBox'
import { useNavigate } from 'react-router'
import { useIsInRole } from '../../../hooks/role'
import { useFieldArray, useForm } from 'react-hook-form'
import { mockedProjectActivity, mockedProjectRequest } from '../../../utils/MockedData/Projects'
import { mapProjectDates } from '../../../utils/Mappings/Projects'

import ControlInput from '../../fields/ControlInput'
import ControlDropdown from '../../fields/ControlDropdown'
import ControlDateField from '../../fields/ControlDateField'
import ControlTextArea from '../../fields/ControlTextArea'
import ControlCheckbox from '../../fields/ControlCheckbox'
import { toast } from 'react-toastify'
import * as API from '../../../api'
import { useMutation } from 'react-query'
import { handleAxiosError } from '../../../actions/error'
import * as z from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import ProjectActivityList from './ProjectActivityList'
import { isEmptyGuid } from '../../../actions/util'
import { v4 as uuidv4 } from 'uuid';

const IExtraFieldDtoSchema = z.object({
    value: z.any().nullable(),
    type: z.number(),
    required: z.boolean(),
}).refine((data) => {
    if (data.required) {
        if (data.value == null) return false;
        if (typeof data.value === 'string' && data.value.trim() === '') return false;
        // Add more checks if necessary for other types
    }
    return true;
}, {
    message: "required",
    path: ["value"], // Path to the field that fails validation
});

const IProjectActivityDtoSchema = z.object({
    id: z.string().optional(),
    topic: z.string({ invalid_type_error: "required" }).min(1, { message: "required" }),
    description: z.string().nullable(),
    time: z.coerce.number().nullable(),
    cost: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
    doneBefore: z.date().nullable(),
    dateDone: z.date().nullable(),
    doneBy: z.string().nullable(),
    status: z.number().nullable(),
    responsibleId: z.string({ invalid_type_error: "required" }).min(1, { message: "required" }),
    sendAsAppointment: z.boolean(),
});

const IProjectRequestSchema = z.object({
    description: z.string(),
    goal: z.string(),
    implementation: z.string(),
    activitiesIsChecklist: z.boolean(),
    useReview: z.boolean(),
    reviewDate: z.date().nullable(),
    reviewResponsibleId: z.string().nullable(),
    reviewText: z.string(),
    endedDate: z.date().nullable(),
    endedBy: z.string(),
    ownId: z.number(),
    status: z.number(),
    topic: z.string({ invalid_type_error: "required" }).min(1, { message: "required" }),
    responsibleId: z.string({ invalid_type_error: "required" }).min(1, { message: "required" }),
    departmentId: z.coerce.number().min(1, { message: "required" }),
    startDate: z.date({ invalid_type_error: "required" }),
    endDate: z.date().nullable(),
    time: z.coerce.number().nullable(),
    cost: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
    extraFields: z.record(IExtraFieldDtoSchema).nullable(),
    activities: z.array(IProjectActivityDtoSchema),
});

export interface IProjectFormProps {
    isNew: boolean
    refresh: () => void
    project?: IProjectDetailsDto
    templates?: IProjectTemplateDto[]
}

const ProjectForm: React.FunctionComponent<IProjectFormProps> = ({
    isNew,
    refresh,
    project,
    templates,
}) => {
    const printComponentRef = useRef()
    const { t } = useTranslation()
    const {
        dispatch,
        state: { userProfile, departments, companyLogoPath, },
    } = getState()
    const navigate = useNavigate()
    const [currentActivity, setCurrentActivity] = useState<number | null>(null)
    const userOptions = useUserOptionsOverride(t, isNew ? u => u.active : undefined)
    const userOptionsWithActiveItemUser = useMemo(() => {
        if (
            project?.responsibleId &&
            !userOptions.find(({ value }) => project.responsibleId === value)
        ) {
            userOptions.push({
                key: project.responsibleId,
                value: project.responsibleId,
                text: `${t('userFromAnotherCompany')}`,
            })
        } else if (userProfile?.sub && !userOptions.find(({ value }) => value === userProfile?.sub)) {
            userOptions.push({
                key: userProfile?.sub,
                value: userProfile?.sub,
                text: `${t('userFromAnotherCompany')}`,
            })
        }

        return userOptions
    }, [userOptions, project, t, userProfile])

    const { isInRole: isAdmin } = useIsInRole(AdminRoles.ProjectsAdminRole)
    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()

    const { control, handleSubmit, setValue, reset, watch, getValues, formState, clearErrors, trigger } = useForm<IProjectRequest>({
        resolver: zodResolver(IProjectRequestSchema),
        defaultValues: project ? mapProjectDates(project) : mockedProjectRequest
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "activities",
    })
    const watchFieldArray = watch("activities")
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index],
        }
    })
    const onProjectRequest = async (data: IProjectRequest) => {
        if (!project) {
            return await API.projects.create(data)
        } else {
            return API.projects.update(project!.id, data)
        }
    }

    const { isLoading: isSaving, mutateAsync: onSubmit } = useMutation(onProjectRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                if (uploadAllPendingFiles) await uploadAllPendingFiles((data.data as any).id?.toString())
                reset(mapProjectDates(data.data as any))
            }
            refresh()
            toast.success(t("saved"))
            if (isNew) navigate(-1)
        },
    })

    const onTemplateApplied = (template: IProjectTemplateDto) => {
        setValue("activities", [])
        setValue("activitiesIsChecklist", template.activitiesIsChecklist)
        template.activities?.forEach((v) => {
            append({
                ...mockedProjectActivity,
                topic: v.topic,
                description: v.description,
                /*temporary id before save, since they all have same id.*/
                id: isEmptyGuid(v.id) ? uuidv4() : v.id,
                responsibleId: getValues("responsibleId") ? getValues("responsibleId") : userProfile?.sub
            })
        })
        setValue("extraFields", template.extraFields)
        toast(t('templateApplied'), {
            type: 'success',
        })
    }

    const handleAppendActivity = () => {
        append(mockedProjectActivity)
        setCurrentActivity(getValues("activities")!.length - 1)
    }

    const handleRemove = (idx: number, isNewActivity: boolean) => {
        if (isNewActivity) remove(idx)
        setCurrentActivity(null)
    }

    const handleSaveActivity = async () => {
        if (!isNew) {
            await onSubmit(getValues())
        }
        setCurrentActivity(null)
    }

    const handleOnchangeExtraField = (name: string, value: any) => {
        const currentValues = getValues("extraFields"); // Get the current values of extraFields
        if (currentValues) {
            const updatedValues = {
                ...currentValues,
                [name]: { ...currentValues[name], value: value }
            };

            setValue("extraFields", updatedValues); // Update the entire extraFields object
            clearErrors(`extraFields.${name}`);
        }

    }

    const handleSetCurrentActivity = (rowData: IProjectActivityDto) => {
        const index = getValues("activities").findIndex(obj => obj.id === rowData.id);
        setCurrentActivity(index)
    }

    useEffect(() => {
        return () => reset()
    }, [reset])
    return (
        <>
            <ApplyProjectTemplateBox
                templates={project?.id ? undefined : templates}
                onTemplateApplied={onTemplateApplied}
            />

            <Form onSubmit={handleSubmit(data => onSubmit(data))} ref={printComponentRef} noValidate>

                <SaveCancelPrintButtonGroup
                    location="top"
                    onCancel={() => navigate(-1)}
                    printComponentRef={printComponentRef}
                    printComponent={<ProjectPrintView project={getValues()} />}
                />

                {companyLogoPath ? (
                    <img
                        className="show-in-print"
                        src={companyLogoPath}
                        style={{ maxWidth: '150px' }}
                        alt='Logo'
                    />
                ) : null}

                <Grid stackable>
                    <GridRow columns={2}>
                        <GridColumn >
                            <ControlInput
                                control={control}
                                disabled={isSaving}
                                label={t('topic')}
                                required
                                name='topic'
                            />
                        </GridColumn>

                        <GridColumn >
                            <ControlDropdown
                                name="departmentId"
                                label={t("department")}
                                control={control}
                                disabled={isSaving}
                                required
                                collection={departments.map(d => ({ key: d.id, text: d.name, value: d.id }))} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="3">
                        <GridColumn>
                            <ControlDropdown
                                name="responsibleId"
                                label={t("responsible")}
                                control={control}
                                disabled={isSaving}
                                required
                                collection={userOptionsWithActiveItemUser} />
                        </GridColumn>
                        <GridColumn>
                            <ControlDateField
                                name="startDate"
                                label={t('projectStart')}
                                control={control}
                                showYearDropdown
                                disabled={isSaving}
                                required />
                        </GridColumn>
                        <GridColumn>
                            <ControlDateField
                                name="endDate"
                                label={t('projectEnd')}
                                control={control}
                                disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                    <GridRow columns={2}>
                        <GridColumn >
                            <ControlTextArea label={t('projectDescription')} name="description" control={control} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn >
                            <ControlTextArea label={t('projectGoal')} name="goal" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    {watch("extraFields") ?
                        <DynamicFields
                            fields={watch("extraFields") as any}
                            onChange={handleOnchangeExtraField}
                            errors={formState.errors}
                        />
                        : null}

                    <GridRow >
                        <GridColumn >
                            <Header dividing content={t('projectPlan')} />
                        </GridColumn>
                    </GridRow>


                    <GridRow >
                        <GridColumn >
                            <ControlTextArea label={t('implementation')} name="implementation" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn >
                            <ProjectActivityList
                                activities={controlledFields}
                                project={watch()}
                                activitiesIsChecklist={getValues("activitiesIsChecklist")}
                                isNew={isNew}
                                onAdd={handleAppendActivity}
                                onActivitySelected={rowData => handleSetCurrentActivity(rowData)}
                            />
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn >
                            <Files
                                displayTitle
                                module="Projects"
                                disableDelete={!isAdmin}
                                mayAutoUpload={!!project}
                                enableDragDrop
                                collectionId={project && project.id}
                                onFilesAdded={project ? undefined : (files, uploadAllPendingFiles) => {
                                    if (!files.length) return
                                    setUploadAllPendingFiles(() => uploadAllPendingFiles)
                                }}
                            />
                        </GridColumn>
                    </GridRow>


                    <GridRow >
                        <GridColumn >
                            <Header dividing content={t('endProject')} />
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn >
                            <ControlCheckbox control={control} disabled={isSaving} label={t('evaluationRequired')} name='useReview' />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns={2}>
                        <GridColumn >
                            <ControlInput
                                control={control}
                                disabled={isSaving}
                                label={t('endedBy')}
                                name='endedBy' />
                        </GridColumn>
                        <GridColumn>
                            <ControlDateField
                                name="endedDate"
                                label={t('endedDate')}
                                control={control}
                                disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                    {watch("useReview") ? (
                        <>
                            <Grid.Row columns="1">
                                <Grid.Column>
                                    <ControlTextArea label={t('evaluationResult')} name="reviewText" control={control} disabled={isSaving} />
                                </Grid.Column>
                            </Grid.Row>
                            <GridRow columns={2}>
                                <GridColumn >
                                    <ControlDropdown
                                        name="reviewResponsibleId"
                                        label={t("reviewResponsibleId")}
                                        control={control}
                                        disabled={isSaving}
                                        collection={userOptionsWithActiveItemUser} />
                                </GridColumn>
                                <GridColumn>
                                    <ControlDateField
                                        name="reviewDate"
                                        label={t('reviewDate')}
                                        control={control}
                                        disabled={isSaving} />
                                </GridColumn>
                            </GridRow>
                        </>
                    ) : null}
                </Grid>

                <SaveCancelPrintButtonGroup
                    location="bottom"
                    onCancel={() => navigate(-1)}
                    printComponentRef={printComponentRef}
                    printComponent={<ProjectPrintView project={project} />}
                />

                {currentActivity !== null ? <ProjectActivityDialog
                    activityIdx={currentActivity}
                    trigger={trigger}
                    onSave={handleSaveActivity}
                    handleRemove={handleRemove}
                    disabled={isSaving}
                    control={control}
                    setValue={setValue}
                    isNewActivity={watch("activities") && watch(`activities.${currentActivity}`).id ? false : true}
                    activitiesIsChecklist={getValues("activitiesIsChecklist")}
                    userOptions={userOptionsWithActiveItemUser}
                /> : null}
            </Form>

        </>
    )
}

export default ProjectForm
