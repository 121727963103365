import React from 'react'
import { Header, flexRender } from '@tanstack/react-table'
import { useDrag } from "react-dnd";
import styled from 'styled-components';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { ChevronDown, ChevronUp } from 'react-feather';
interface IDraggableHeaderProps {
    header: Header<any, unknown>;
    enableGrouping?: boolean
}
const DraggableHeader = ({ header, enableGrouping }: IDraggableHeaderProps) => {
    const { column } = header;
    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => column,
        type: "column",
    });

    return (
        <TableHeader
            key={header.id}
            colSpan={header.colSpan}
            isAction={header.id.toLowerCase().includes("actions")}
            className={header.id.toLowerCase().includes("actions") ? 'hide-in-print' : undefined}
            headerWidth={header.getSize()}
            ref={previewRef}
        >

            <SortContainer
                canSort={header.column.getCanSort()}
                onClick={header.column.getToggleSortingHandler()}
                ref={enableGrouping !== false ? dragRef : undefined}
            >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {{
                    asc: <SemanticFeatherIcon FeatherIcon={ChevronUp} />,
                    desc: <SemanticFeatherIcon FeatherIcon={ChevronDown} />,
                }[header.column.getIsSorted() as string] ?? null}
            </SortContainer>


            <Resizer
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
                canResize={header.column.getCanResize()}
                isResizing={header.column.getIsResizing()}
            />
        </TableHeader>
    );
};
export default DraggableHeader

const SortContainer = styled.div<{ canSort?: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${(p) => (p.canSort ? "pointer" : "not-allowed")};
`

const Resizer = styled.div<{ canResize: boolean, isResizing: boolean }>`
  ${(p) =>
        p.canResize &&
        `
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 2px;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  `}
  ${(p) => p.isResizing && `opacity: 1;`}
`

const TableHeader = styled.th<{ headerWidth?: number, isAction?: boolean }>`
  width: ${(p) => `${p.headerWidth}px`};
  text-align: ${(p) => p.isAction && "end"};
`