import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { ChevronDown, ChevronRight } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { DocumentLawConnection } from '../../../../../hooks/laws/documentConnection'
import { Button, TableCell } from 'semantic-ui-react'
import InlineConfirmDelete from '../../../../common/inlineConfirmDelete'
import InfoTableRow from '../../../../common/infoTableRow'

export interface LawRowProp {
    t: TFunction
    onClickRemoveConnection?(): void
    law: DocumentLawConnection
    isAdmin: boolean
    onInfoClicked?(): void
    isInfoOpen: boolean
}

const LawsTableRow: FunctionComponent<LawRowProp> = ({
    t,
    onClickRemoveConnection,
    law,
    isAdmin,
    onInfoClicked,
    isInfoOpen,
}) => {
    return (
        <InfoTableRow $isInfo={law.isInheritedFromConcern}>
            <TableCell collapsing>
                <Button
                    icon={
                        <SemanticFeatherIcon
                            FeatherIcon={isInfoOpen ? ChevronDown : ChevronRight}
                        />
                    }
                    onClick={() => {
                        onInfoClicked?.()
                    }}
                    type="button"
                />
            </TableCell>
            <TableCell>{law.denomination}</TableCell>
            <TableCell>{law.name}</TableCell>
            <TableCell textAlign="right">
                {isAdmin && !law.isInheritedFromConcern && (
                    <InlineConfirmDelete onConfirm={() => onClickRemoveConnection?.()} />
                )}
            </TableCell>
        </InfoTableRow>
    )
}
export default LawsTableRow
