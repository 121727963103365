import React, { useState, useEffect } from 'react'
import HtmlDiff from 'htmldiff-js-compat'
import styled from 'styled-components'

const DiffContainer = styled.div`
    ins {
        background-color: rgba(0, 255, 0, 0.4);
    }

    del {
        background-color: rgba(255, 0, 0, 0.4);
    }
`

export interface DiffDisplayProps {
    oldValue: string
    newValue: string
}

const DiffDisplay: React.FunctionComponent<DiffDisplayProps> = ({ oldValue, newValue }) => {
    const [diff, setDiff] = useState('')

    useEffect(() => {
        //if (!oldValue || !newValue) return

        const updatedDiff = HtmlDiff.execute(oldValue ?? '', newValue ?? '')
        setDiff(updatedDiff)
    }, [oldValue, newValue])

    return <DiffContainer dangerouslySetInnerHTML={{ __html: diff }} />
}

export default DiffDisplay
