import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import ImprovementIdeaForm from '../../../forms/ImprovementIdeaForm'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import { IImprovementGroupDto } from '../../../../api/improvements'

interface IIdeaFormPageProps {
    refresh: () => void
    isNew: boolean
    improvementGroups: IImprovementGroupDto[]
}
const IdeaFormPage = ({ refresh, isNew, improvementGroups }: IIdeaFormPageProps) => {
    const { t } = useTranslation()
    const { ideaId } = useParams<{ ideaId: string }>()
    const {
        isLoading: isLoadingIdea,
        data: selectedIdea,
    } = useGetQueryByParams(!!ideaId, API.improvements.getIdeaById, "selectedIdea", ideaId)

    if (isLoadingIdea) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('improvementIdea').toLowerCase() }) : t('editValue', { value: t('improvementIdea').toLowerCase() })}</Header>
            <ImprovementIdeaForm refresh={refresh} selectedIdea={selectedIdea} isNew={isNew} improvementGroups={improvementGroups} />
        </Container>
    )
}

export default IdeaFormPage
