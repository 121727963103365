import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import ImprovementActivityForm from '../../../forms/ImprovementActivityForm'
import { IImprovementGroupDto } from '../../../../api/improvements'

interface IActivityFormPageProps {
    refresh: () => void
    isNew: boolean
    improvementGroups: IImprovementGroupDto[]
}
const ActivityFormPage = ({ refresh, isNew, improvementGroups }: IActivityFormPageProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { activityId } = useParams<{ activityId: string }>()
    const {
        isLoading: isLoadingActivity,
        data: selectedActivity,
    } = useGetQueryByParams(!!activityId, API.improvements.getActivityById, "selectedActivity", activityId)

    if (isLoadingActivity) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('improvementActivity').toLowerCase() }) : t('editValue', { value: t('improvementActivity').toLowerCase() })}</Header>
            <ImprovementActivityForm refresh={refresh} onCancel={() => navigate(-1)} selectedActivity={selectedActivity} isNew={isNew} improvementGroups={improvementGroups} />
        </Container>
    )
}

export default ActivityFormPage
