import React from 'react'
import SurveyBase from './surveyBase'

export interface SurveyDoneProps {
    imageUrl: string
    content: string
}

const SurveyDone: React.FunctionComponent<SurveyDoneProps> = ({ imageUrl, content }) => {
    return (
        <SurveyBase imageUrl={imageUrl}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </SurveyBase>
    )
}

export default SurveyDone
