import React from 'react'
import styled from 'styled-components'
import { getState } from '../../state'

const CompanyLogoImage = styled.img<CompanyLogoProps>`
    display: block;
    max-width: ${props => props.maxWidth};
    width: auto;
    height: auto;
    align-self: flex-start;
`

export interface CompanyLogoProps extends LogoImageProps {
    fallbackToViqmaLogo?: boolean
}

interface LogoImageProps {
    maxWidth?: string
}

const CompanyLogo: React.FunctionComponent<CompanyLogoProps> = ({
    fallbackToViqmaLogo = false,
    ...imageProps
}) => {
    const {
        state: { companyLogoPath, company },
    } = getState()

    return (
        <CompanyLogoImage
            src={
                companyLogoPath ?? (fallbackToViqmaLogo ? '/images/viqma-system-logo-dark.png' : '')
            }
            alt={`Logo for ${company?.name}`}
            {...imageProps}
        />
    )
}

export default CompanyLogo
