import React, { useState } from 'react'
import { DownloadCloud } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Container, Grid, Header, Message } from 'semantic-ui-react'
import { AdminRoles } from '../../../../auth/roles'
import { ProcessHierarchyItem, useDocumentStructureAdmin } from '../../../../hooks/docs/process'
import { getState } from '../../../../state'
import IfIsInRole from '../../../common/ifIsInRole'
import Tabs from '../../../common/tabs'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import DocumentEditModal, { ActiveEditItem } from './documentEditModal'
import DocumentImportModal from './documentImport'
import ProcessArea, { ProcessType } from './processArea'
import { IViqmaDocument } from '../../../../api/documents'

const DocumentStructureAdminPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const {
        state: { hasSubCompanies },
    } = getState()
    const { data, move, loading, deleteDocument, forceRefresh } = useDocumentStructureAdmin()
    const [activeEditItem, setActiveEditItem] = useState<ActiveEditItem>()

    const onEdit = (item: ProcessHierarchyItem, parentIsUnpublished: boolean) => {
        if (!item.item) return

        setActiveEditItem({
            documentId: item.item.id,
            type: item.item.typ,
            hasChildren: item.children.length > 0,
            parentIsUnpublished,
        })
    }

    const onNew = (type: ProcessType, parentId: number, parentIsUnpublished: boolean) =>
        setActiveEditItem({ type, parentId, hasChildren: false, parentIsUnpublished })

    const listAtOrEmpty = <T extends unknown>(
        list: T[] | undefined,
        index: number,
    ): T | undefined => {
        if (!list || list.length < index) return undefined
        return list[index]
    }

    const onDocumentSaved = (document: IViqmaDocument, isNew: boolean) => {
        setActiveEditItem(undefined)
        forceRefresh()
    }

    const tabs = [
        {
            key: 'governingProcess',
            title: t('governingProcess'),
            type: ProcessType.GoverningProcess,
            routines: listAtOrEmpty(data?.governingProcess, 0)?.children ?? [],
        },
        {
            key: 'mainProcess',
            title: `${t('mainProcess')} 1`,
            type: ProcessType.MainProcess,
            routines: listAtOrEmpty(data?.mainProcesses, 0)?.children ?? [],
        },
        {
            key: 'mainProcess2',
            title: `${t('mainProcess')} 2${hasSubCompanies ? ` (${t('notSharedToSubCompanies')})` : ''
                }`,
            type: ProcessType.MainProcess2,
            routines: listAtOrEmpty(data?.mainProcesses, 1)?.children ?? [],
        },
        {
            key: 'mainProcess3',
            title: `${t('mainProcess')} 3${hasSubCompanies ? ` (${t('notSharedToSubCompanies')})` : ''
                }`,
            type: ProcessType.MainProcess3,
            routines: listAtOrEmpty(data?.mainProcesses, 2)?.children ?? [],
        },
        {
            key: 'supportProcess',
            title: t('supportProcess'),
            type: ProcessType.SupportProcess,
            routines: listAtOrEmpty(data?.supportProcess, 0)?.children ?? [],
        },
    ].reduce(
        (acc, cur) => ({
            ...acc,
            [cur.key]: {
                title: cur.title,
                component: (
                    <ProcessArea
                        title={cur.title}
                        type={cur.type}
                        routines={cur.routines}
                        onEdit={onEdit}
                        onMove={move}
                        onNew={onNew}
                        onDelete={deleteDocument}
                        loading={loading}
                    />
                ),
            },
        }),
        {},
    )

    return (
        <Container>
            {!loading &&
                data?.governingProcess[0]?.children.length === 0 &&
                data?.mainProcesses[0]?.children.length === 0 &&
                data?.mainProcesses[1]?.children.length === 0 &&
                data?.mainProcesses[2]?.children.length === 0 &&
                data?.supportProcess[0]?.children.length === 0 && (
                    <IfIsInRole role={AdminRoles.ViqmaAdminRole}>
                        <Message success>
                            <Grid>
                                <Grid.Column width={12}>
                                    <Header size="small">{t('useButtonToImportRoutines')}</Header>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <DocumentImportModal
                                        trigger={
                                            <Button
                                                positive
                                                icon
                                                labelPosition="left"
                                                type="button"
                                            >
                                                <SemanticFeatherIcon
                                                    FeatherIcon={DownloadCloud}
                                                    width="60%"
                                                    centerIcon={true}
                                                />{' '}
                                                {t('import')}
                                            </Button>
                                        }
                                        onImportComplete={forceRefresh}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Message>
                    </IfIsInRole>
                )}

            <DocumentEditModal activeItem={activeEditItem} onDocumentSaved={onDocumentSaved} />
            <Tabs tabs={tabs} />
        </Container>
    )
}

export default DocumentStructureAdminPage
