import { AxiosError } from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { ArrowLeftCircle, Edit, Save, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Container,
    Header,
    Message,
    Placeholder,
    Segment,
} from 'semantic-ui-react'
import { formatDate } from '../../../../../actions/util'
import { getAuthedAxios } from '../../../../../auth'
import { useUserName } from '../../../../../hooks/users'
import DiffDisplay from '../../../../common/diffDisplay'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { DocumentSuggestionChange } from './types/suggestionChangesTypes'

export type ReviewState = 'idle' | 'requesting' | 'success' | 'error'

export interface ReviewSuggestedChangeDumbProps {
    data: DocumentSuggestionChange | undefined
    oldContent: string
    title: string
    reviewState?: ReviewState
    onAbort: () => void
    loading: boolean
    onDeny: () => void
    onApprove: () => void
    onEdit: (suggestion: DocumentSuggestionChange) => void
    error?: string
}

export const ReviewSuggestedChangeDumb: FunctionComponent<ReviewSuggestedChangeDumbProps> = ({
    loading,
    data,
    oldContent,
    title,
    error,
    onApprove,
    onAbort,
    onDeny,
    onEdit,
}) => {
    const { t } = useTranslation()
    const { content = '', comment = '', changedBy = '', date } = data || {}
    const userName = useUserName(changedBy)

    return (
        <>
            <Header>
                {t('reviewFor', { name: title })}

                <Button
                    floated="right"
                    content={t('edit')}
                    icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                    onClick={() => onEdit(data!)}
                    type="button"
                />
            </Header>
            <Segment>
                <Container fluid>
                    {loading ? (
                        <Placeholder fluid>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder>
                    ) : (
                        <DiffDisplay oldValue={oldContent} newValue={content} />
                    )}
                </Container>
            </Segment>
            <Segment vertical loading={loading}>
                <Message
                    header={t('changedByAndDate', { name: userName, date: formatDate(date + '') })}
                    content={comment}
                />
                <Message
                    hidden={error === undefined}
                    error
                    header={t('error')}
                    content={t(error + '')}
                />
                <Button
                    primary
                    content={t('approveSuggestion')}
                    icon={<SemanticFeatherIcon FeatherIcon={Save} />}
                    onClick={onApprove}
                    type="button"
                />

                <Button
                    negative
                    content={t('denySuggestion')}
                    icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                    onClick={onDeny}
                    type="button"
                />

                <Button
                    content={t('cancel')}
                    icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                    onClick={onAbort}
                    type="button"
                />
            </Segment>
        </>
    )
}
export interface ReviewSuggestedChangeProps {
    documentId: number
    title: string
    oldContent: string
    onAbort: () => void
    onStateInvalid(): void
    onEdit: (suggestion: DocumentSuggestionChange) => void
}
export const ReviewSuggestedChange: FunctionComponent<ReviewSuggestedChangeProps> = ({
    documentId,
    oldContent,
    onAbort,
    onStateInvalid,
    onEdit,
    title,
}) => {
    const [fetchState, setFetchState] = useState<ReviewState>('idle')
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [state, setState] = useState<DocumentSuggestionChange>()
    const fetchReview = async () => {
        //setFetchState('requesting')
        setLoading(true)
        const axios = await getAuthedAxios()
        try {
            const resp = await axios.get(
                `${window.env.REACT_APP_DOC_SERVICE}/api/Document/${documentId}/suggestion`,
            )
            if (resp.data.length) {
                setState(resp.data[resp.data.length - 1])
            }
        } catch (err) {
            // Handle Error Here
            //    setFetchState('error')
            console.error(err)
        } finally {
            setLoading(false)
        }
    }
    const onApprove = async () => {
        setLoading(true)
        const axios = await getAuthedAxios()
        if (!state?.id || !documentId) {
            setLoading(false)
            return
        }
        await axios
            .post(
                `${window.env.REACT_APP_DOC_SERVICE}/api/Document/${documentId}/suggestion/${state!.id
                }/approve`,
            )
            .then(onStateInvalid)
            .catch((err: AxiosError) => {
                console.log('err', { err })
                setFetchState('error')
                setError(err.response?.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onDeny = async () => {
        setLoading(true)
        const axios = await getAuthedAxios()
        if (!state?.id || !documentId) {
            setLoading(false)
            return
        }
        await axios
            .delete(
                `${window.env.REACT_APP_DOC_SERVICE}/api/Document/${documentId}/suggestion/${state!.id
                }`,
            )
            .then(onStateInvalid)
            .catch(err => {
                setError(err)
            })
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        fetchReview()
    }, [documentId])
    return (
        <ReviewSuggestedChangeDumb
            loading={loading}
            error={error}
            reviewState={fetchState}
            data={state}
            title={title}
            oldContent={oldContent}
            onAbort={onAbort}
            onApprove={onApprove}
            onDeny={onDeny}
            onEdit={onEdit}
        />
    )
}
export default ReviewSuggestedChange
