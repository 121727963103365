import React, { useMemo } from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams';
import * as API from '../../../../api';
import Loading from '../../../common/Loading';
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer';
import TableReact, { FooterLabel } from '../../../wrappers/TableReact';
import { ISupplierAnswerDto, ISupplierResponse } from '../../../../api/suppliers';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { getGraphColorsFactory } from '../../../../styles';
import { formatDate } from '../../../../actions/util';
import DateCell from '../../../common/cells/DateCell';
import { useLanguage } from '../../../../hooks/language';
import { enumMultiFilter, EnumMultiFilterSelect } from '../../../wrappers/TableReact/Filters/EnumMultiFilter';
import { SupplierQuestionType } from '../../../../api/types/Enum';
import { dateBetweenFilterFn, DateRangeFilter } from '../../../wrappers/TableReact/Filters/DateRangeFilter';
import { AdminRoles, isInRole } from '../../../../auth/roles';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { Info } from 'react-feather';
import { getState } from '../../../../state';

interface IExternalAssessmentListProps {
    selectedSupplier: ISupplierResponse;
    hasSupplierConcernModule: boolean;
}

const ExternalAssessmentList = ({ selectedSupplier, hasSupplierConcernModule }: IExternalAssessmentListProps) => {
    const { t } = useTranslation();
    const { state } = getState()

    const { isLoading, data } = useGetQueryByParams(true, API.suppliers.getExternalAnswers, 'externalAnswers', selectedSupplier.id);
    const {
        isLoading: isLoadingQuestions,
        data: questions,
    } = useGetQueryByParams(true, API.suppliers.getExternalQuestions, "ExternalQuestions", selectedSupplier.id)
    const language = useLanguage()
    const columnHelper = createColumnHelper<ISupplierAnswerDto>();

    const columns = useMemo(
        () => [
            columnHelper.accessor('type', {
                id: 'type',
                header: t('type') as any,
                filterFn: enumMultiFilter,
                size: 80,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | SupplierQuestionType[]) => void, value: SupplierQuestionType[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={SupplierQuestionType}
                            enumLangKey="supplierQuestionType"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`supplierQuestionType.${row.original.type}`)}</span>
            }),
            ...(
                language === "sv"
                    ? [
                        columnHelper.accessor('swedishQuestion', {
                            id: 'swedishQuestion',
                            header: t('question') as any,
                        }),
                        columnHelper.accessor('swedishComment', {
                            id: 'swedishComment',
                            header: t('subtitle') as any,
                        }),
                        columnHelper.accessor('swedishExtra', {
                            id: 'swedishExtra',
                            header: t('selfSubtitle') as any,
                        }),
                    ]
                    : [
                        columnHelper.accessor('englishQuestion', {
                            id: 'englishQuestion',
                            header: t('question') as any,
                        }),
                        columnHelper.accessor('englishComment', {
                            id: 'englishComment',
                            header: t('subtitle') as any,
                        }),
                        columnHelper.accessor('englishExtra', {
                            id: 'englishExtra',
                            header: t('selfSubtitle') as any,
                        }),
                    ]
            ),
            columnHelper.accessor('comment', {
                id: 'comment',
                header: t('comment') as any,
            }),
            columnHelper.accessor(r => r.date ? formatDate(r.date) : "", {
                id: "date",
                header: t('date') as any,
                size: 80,
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.date} />
            }),
            columnHelper.accessor('points', {
                id: 'points',
                header: t('points') as any,
                enableColumnFilter: false,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const validPoints = filtered
                        .map((f) => f.original.points)
                        .filter((point): point is number => point !== null && point !== undefined);

                    const totalCount = validPoints?.reduce((a: number, c: number) => a + c, 0);
                    const average = validPoints.length > 0 ? totalCount / validPoints.length : 0;

                    return (
                        <FooterLabel>{t('averageValue', { value: average.toFixed(2) })}</FooterLabel>
                    );
                },
            }),
        ],
        [columnHelper, t, language]
    );

    const table = useReactTable({
        data: data || [],
        columnResizeMode: 'onChange',
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    if (isLoading || !data || isLoadingQuestions || !questions) {
        return <Loading />;
    }

    if (!questions.length) {
        return <Message info
            header={t("externalQuestions")}
            content={<span>
                {t('noExternalQuestions')}
                {isInRole(state.userProfile, AdminRoles.SupplierAdminRole)
                    ? <a href="/lev/levFr.aspx">{t('toAddLink')}</a>
                    : t('contactSystemAdmin')}
            </span>}
            icon={<SemanticFeatherIcon FeatherIcon={Info} centerIcon size="32px" />} />
    }

    const processData = (data: ISupplierAnswerDto[]) => {
        const groupedData: { [key: string]: { total: number; count: number } } = {};

        data.filter(z => z.type !== 5).forEach((item) => {
            if (item.date && item.points !== null) {
                const date = formatDate(item.date)
                const typeKey = t(`supplierQuestionType.${item.type}`) || 'Unknown';
                const groupKey = `${typeKey}_${date}`; // Unique key for each type and date

                if (!groupedData[groupKey]) {
                    groupedData[groupKey] = { total: 0, count: 0 };
                }

                groupedData[groupKey].total += item.points;
                groupedData[groupKey].count += 1;
            }
        });

        // Transform the accumulated data to chart format
        const chartData: { date: string;[key: string]: number | string }[] = [];

        // Collect all unique types for ensuring all keys are present
        const allTypes = new Set<string>();

        for (const key in groupedData) {
            const [type, date] = key.split('_');
            const average = groupedData[key].total / groupedData[key].count;
            allTypes.add(type);

            // Debug: Log averages being calculated
            console.log(`Average for ${type} on ${date}: ${average}`);

            // Find or create a data entry for the current date
            let entry = chartData.find((e) => e.date === date);
            if (!entry) {
                entry = { date };
                chartData.push(entry);
            }

            entry[type] = average;
        }

        // Ensure all dates have entries for all types, even if the value is null
        chartData.forEach((entry) => {
            allTypes.forEach((type) => {
                if (!(type in entry)) {
                    entry[type] = 0; // Set to null or 0 to ensure all lines are drawn
                }
            });
        });

        // Sort chart data by date
        chartData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        return chartData;
    };

    const chartData = processData(data);
    const barColors = getGraphColorsFactory()
    return (
        <FullHeightStackingContainer>
            {!hasSupplierConcernModule && <Message info>
                {t("externalAssessmentInfo")}
            </Message>}
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(date) => formatDate(date)}
                        type="category"
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {Object.keys(chartData[0] || {})
                        .filter((key) => key !== 'date')
                        .map((typeKey) => (
                            <Line
                                key={typeKey}
                                type="monotone"
                                dataKey={typeKey}
                                name={typeKey}
                                stroke={barColors()}
                                activeDot={{ r: 8 }}
                                connectNulls
                            />
                        ))}
                </LineChart>
            </ResponsiveContainer>
            <TableReact table={table} canExport={false} overflowable />
        </FullHeightStackingContainer>
    );
};

export default ExternalAssessmentList;
