import React from 'react'
import ModuleMenu from '../../common/moduleMenu'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ImprovementsMenu: React.FunctionComponent = () => {
    const { t } = useTranslation()

    /* eslint react/jsx-key: "off" */
    return ModuleMenu({
        all: [<NavLink to={`./`}>{t('ideasImprovments')}</NavLink>, <NavLink to={`./activites`}>{t('activityImprovments')}</NavLink>],
        admin: [<NavLink to={`./admin/groups`}>Admin Förbättringsgrupper</NavLink>],

    })
}

export default ImprovementsMenu
