import React from "react"
import { Control, Controller, FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormTextArea } from "semantic-ui-react"

interface IControlTextAreaProps<T extends FieldValues = any> {
  label: string
  name: string
  disabled: boolean
  control: Control<T>
  required?: boolean
  placeholder?: string
}

const ControlTextArea = ({
  name,
  label,
  disabled,
  control,
  required = false,
  placeholder
}: IControlTextAreaProps) => {
  const { t } = useTranslation()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <FormTextArea
              label={label}
              value={value || ""}
              onChange={onChange}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              error={!!error && { content: t(`${error.message}`), }}
            />
            <div className="show-in-print">{value}</div>
          </>

        )
      }}
    />
  )
}
export default ControlTextArea
