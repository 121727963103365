import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbSectionProps } from 'semantic-ui-react'
import { SimplifiedDocumentItem } from '../../../../../hooks/docs/process'
import DocumentName from '../../common/documentName'

interface DocumentBreadcrumbsProps {
    hierarchy: SimplifiedDocumentItem[]
    documentId: number
}

const DocumentBreadcrumbs: FunctionComponent<DocumentBreadcrumbsProps> = ({
    hierarchy = [],
    documentId,
}) => {
    const [sections, setSections] = useState<BreadcrumbSectionProps[]>()

    useEffect(() => {
        if (hierarchy) {
            const isCurrentDocument = (id: number): boolean => id === +documentId

            const s: BreadcrumbSectionProps[] = hierarchy
                .map(({ id, rubrik, isPublished }) => ({
                    key: id,
                    as: !isCurrentDocument(id) ? Link : null,
                    content: <DocumentName name={rubrik} isPublished={isPublished} />,
                    to: !isCurrentDocument(id) ? `../${id}` : undefined,
                    active: isCurrentDocument(id),
                    link: !isCurrentDocument(id),
                }))
                .reverse()
            setSections(s)
        }
    }, [hierarchy, documentId])

    return <Breadcrumb size="big" icon="right angle" sections={sections} />
}

export default DocumentBreadcrumbs
