import * as API from "../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../actions/error"
import { getState } from "../../../../../state"
import { IssueHierarchyType } from "../../../../../api/issues"

interface IDestroyIssueType {
    id: number
    confirmText: string
    issueHierarchyType: IssueHierarchyType
}

export function useDestroyIssueType(refresh: () => void) {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const destroyIssueType = async (data: IDestroyIssueType) => {
        if (window.confirm(data.confirmText)) {
            switch (data.issueHierarchyType) {
                case IssueHierarchyType.DeviationType:
                    return await API.issues.destroyIssueType(data.id)
                case IssueHierarchyType.ErrorCode:
                    return await API.issues.destroyIssueErrorCode(data.id)
                case IssueHierarchyType.CauseCode:
                    return await API.issues.destroyIssueCauseCode(data.id)
            }
        }
    }

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(destroyIssueType, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t('saved'))
                refresh()
            }
        },
    })

    return {
        isDestroying,
        onDestroy,
    }
}
