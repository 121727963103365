import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';


const CompactGrid = styled(Grid)`
    margin: 1em;
    .row {
        padding-top: 0.1rem !important;
        padding-bottom: 0.1rem !important;
    }
`;

export default CompactGrid;