import React, { useEffect, useMemo, useState } from 'react'
import { AlertCircle, ArrowLeftCircle, Info, Save } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownProps,
    Form,
    FormField,
    Input,
    InputOnChangeData,
    Message,
    Modal,
} from 'semantic-ui-react'
import { AdminRoles, isInRole } from '../../../../auth/roles'
import { useDocumentAdmin } from '../../../../hooks/docs/single'
import { useUserInRoleOptions } from '../../../../hooks/users'
import { getState } from '../../../../state'
import ActionButton from '../../../common/actionButton'
import CollapsingSegment from '../../../common/collapsingSegment'
import IfIsInRole from '../../../common/ifIsInRole'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import CopyFromSelectionForm from './copyFromSelectionForm'
import EditDocumentReadOnlyOverlay from './editDocumentReadOnlyOverlay'
import { Editor } from 'tinymce/tinymce'
import { ProcessType } from './processArea'
import { IViqmaDocument, IViqmaUpdateDocument } from '../../../../api/documents'

export interface ActiveEditItem {
    documentId?: number
    parentId?: number
    type: ProcessType
    hasChildren: boolean
    parentIsUnpublished: boolean
}

export interface DocumentEditModalProps {
    activeItem?: ActiveEditItem
    onDocumentSaved: (document: IViqmaDocument, isNew: boolean) => void
}

const DocumentEditModal: React.FunctionComponent<DocumentEditModalProps> = ({
    activeItem,
    onDocumentSaved,
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const {
        state: { userProfile },
    } = getState()
    const [editData, setEditData] = useState<IViqmaUpdateDocument>()
    const [editor, setEditor] = useState<Editor>()

    const { existingDocument, loading, save } = useDocumentAdmin(activeItem?.documentId?.toString())

    const { userOptions } = useUserInRoleOptions(AdminRoles.DocumentsAdminRole, user => user.active)
    // In some cases the document might be approved by a user in another company. In that case the dropdown will be blank since that item doesn't exists
    // add that user on the fly just so that it least looks right and gives ViqmaAdmin's a chance to clear it
    const userOptionsWithActiveItemUser = useMemo(() => {
        if (
            existingDocument?.approvedBy &&
            !userOptions.find(({ value }) => existingDocument.approvedBy === value)
        ) {
            userOptions.push({
                key: existingDocument.approvedBy,
                value: existingDocument.approvedBy,
                text: `${t('userFromAnotherCompany')} ${existingDocument.approvedByUser?.name
                    ? `(${existingDocument.approvedByUser?.name})`
                    : ''
                    }`,
            })
        }

        return userOptions
    }, [userOptions, existingDocument, t])

    const isViqmaAdmin = isInRole(userProfile, AdminRoles.ViqmaAdminRole)

    const setEditFromDocument = (document: IViqmaDocument, overrideContentReadOnly?: boolean) => {
        setEditData({
            type: activeItem?.type ?? ProcessType.GoverningProcess,
            approver: document?.approvedBy,
            content: document?.content ?? '',
            name: document?.name,
            isPublished: document?.isPublished,
            parentId: activeItem?.documentId ? document.parentId : activeItem?.parentId ?? 0,
            sortIndex: document.sortIndex,
            contentReadOnly: overrideContentReadOnly ?? true,
        })
    }

    useEffect(() => {
        setOpen(!!activeItem)
    }, [activeItem])

    useEffect(() => {
        if (existingDocument && activeItem?.documentId) setEditFromDocument(existingDocument)
        else
            setEditData({
                sortIndex: undefined,
                type: activeItem?.type ?? ProcessType.GoverningProcess,
                parentId: activeItem?.parentId ?? 0,
                approver: null,
                content: '',
                isPublished: true,
                name: '',
                contentReadOnly: false,
            })
    }, [existingDocument, setEditData, activeItem])

    const onChange = (key: string, value: any) =>
        setEditData(previous => ({
            ...previous!,
            [key]: value,
        }))

    const onSave = async () => {
        if (!editData) return

        if (!editData.contentReadOnly && editor) {
            await editor.uploadImages()
            editData.content = editor.getContent()
        }

        await save(editData).then(document => {
            if (document) onDocumentSaved(document, !activeItem?.documentId)
            setOpen(false)
        })
    }

    return (
        <Modal open={open} closeOnDimmerClick={false}>
            <Modal.Header>{t('adminDocument')}</Modal.Header>
            <Modal.Content>
                {existingDocument?.underReferral && (
                    <Message
                        warning
                        icon={
                            <SemanticFeatherIcon
                                FeatherIcon={AlertCircle}
                                size="32px"
                                centerIcon={true}
                            />
                        }
                        content={t('routineIsOnRemiss')}
                    />
                )}

                <IfIsInRole role={AdminRoles.ViqmaAdminRole}>
                    <CollapsingSegment heading={t('copyFunction')}>
                        <CopyFromSelectionForm
                            onCopy={document => setEditFromDocument(document, false)}
                        />
                    </CollapsingSegment>
                </IfIsInRole>

                <Form loading={loading}>
                    <FormField
                        label={t('topic')}
                        value={editData?.name ?? ''}
                        onChange={(_: Event, d: InputOnChangeData) => onChange('name', d.value)}
                        control={Input}
                        required
                    />

                    <FormField
                        label={t('sortOrder')}
                        control={Input}
                        type="number"
                        value={editData?.sortIndex ?? ''}
                        onChange={(_: Event, d: InputOnChangeData) =>
                            onChange('sortIndex', d.value ? parseInt(d.value) : null)
                        }
                    />

                    <FormField
                        label={t('hidden')}
                        disabled={activeItem?.parentIsUnpublished ?? false}
                        checked={
                            (activeItem?.parentIsUnpublished && !activeItem?.documentId) ||
                            !(editData?.isPublished ?? false)
                        }
                        control={Checkbox}
                        onChange={(_: Event, d: CheckboxProps) =>
                            onChange('isPublished', !d.checked)
                        }
                    />

                    {activeItem?.hasChildren && !editData?.isPublished && (
                        <Message
                            info
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Info}
                                    size="32px"
                                    centerIcon={true}
                                />
                            }
                            content={t('hidingDocumentWillAffectChildren')}
                        />
                    )}

                    <FormField>
                        <EditDocumentReadOnlyOverlay
                            value={editData?.content ?? ''}
                            onChange={text => onChange('content', text)}
                            initialReadOnly={!!activeItem?.documentId}
                            onReadOnlyRemoved={() => onChange('contentReadOnly', false)}
                            onEditorInit={editor => setEditor(editor)}
                        />
                    </FormField>

                    <FormField
                        label={t('approvedBy')}
                        control={Dropdown}
                        options={userOptionsWithActiveItemUser}
                        value={editData?.approver ?? null}
                        onChange={(_: Event, d: DropdownProps) =>
                            onChange('approver', !d.value?.toString()?.length ? null : d.value)
                        }
                        selection
                        required={!isViqmaAdmin}
                        clearable={isViqmaAdmin}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {!!activeItem?.documentId && (
                    <Checkbox
                        label={t('saveAsVersion1')}
                        checked={editData?.resetVersion ?? false}
                        onChange={(_, d) => onChange('resetVersion', d.checked)}
                        style={{ float: 'left', marginTop: '10px' }}
                    />
                )}

                <ActionButton
                    action={onSave}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                    disabled={(!isViqmaAdmin && !editData?.approver) || !editData?.name}
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>

                <Button onClick={() => setOpen(false)} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DocumentEditModal
