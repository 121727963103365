import { AxiosResponse } from "axios"
import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { handleAxiosError } from "../actions/error"
import { getState } from "../state"

type Awaited<T> = T extends PromiseLike<infer U> ? U : T
type Axiosed<T> = T extends AxiosResponse<infer U> ? U : T
type AxiosFunction<T> = (...queryParams: any[]) => Promise<AxiosResponse<T, any>>

interface IHookResponse<T> {
  isLoading: boolean
  isIdle: boolean
  data?: T
  refresh: () => void
}

export const useGetQueryByParams = <T>(
  enabled: boolean,
  axiosFunction: AxiosFunction<T>,
  ...queryParams: any[]
): IHookResponse<Axiosed<Awaited<ReturnType<AxiosFunction<T>>>>> => {
  const queryClient = useQueryClient()
  const {
    dispatch,
  } = getState()
  const { isLoading, data, isIdle } = useQuery(
    queryParams,
    async () => {
      const response = await axiosFunction(queryParams[1])
      return response.data
    },
    { enabled: enabled, onError: (err: any) => handleAxiosError(dispatch)(err) }
  )

  const refresh = useCallback(() => {
    queryClient.invalidateQueries()
  }, [queryClient])

  return {
    isLoading,
    isIdle,
    data,
    refresh,
  }
}
