export const getConfig = () => ({
    authority: window.env.REACT_APP_AUTH_SERVICE,
    client_id: 'viqma-web-js',
    redirect_uri: `${window.env.REACT_APP_SELF_URL}/callback`,
    response_type: 'code',
    scope: `${window.env.REACT_APP_REQUESTED_SCOPES}`,
    post_logout_redirect_uri: `${window.env.REACT_APP_SELF_URL}`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.env.REACT_APP_SELF_URL}/callback-silent`,
})
