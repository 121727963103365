import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import { getUserFromState } from '../../../../state/helpers'
import { getState } from '../../../../state'
import { IActivityListDto, ImprovementActivityStatus } from '../../../../api/improvements'
import Loading from '../../../common/Loading'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Edit, Trash2, Bell } from 'react-feather'
import { useDestroyImprovementActivity } from './hooks/useDeleteActivity'
import { useSendActivityNotification } from './hooks/useActivityNotify'
import ProgressCell from '../../../common/cells/ProgressCell'
import { Button, Checkbox, Header, Popup } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import {
    GroupingState,
    createColumnHelper,
    ColumnDef,
    useReactTable,
    getExpandedRowModel,
    getGroupedRowModel,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    VisibilityState,
    PaginationState,
    ColumnFiltersState,
    SortingState,
    ExpandedState
} from '@tanstack/react-table'
import DateCell from '../../../common/cells/DateCell'
import TableReact from '../../../wrappers/TableReact'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { improvementActivitiesStatusRowClass } from '../../../wrappers/TableReact/RowClassHelpers'
import { formatDate } from '../../../../actions/util'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { DateRangeFilter, dateBetweenFilterFn } from '../../../wrappers/TableReact/Filters/DateRangeFilter'
import EmptyRequiredCollection from '../../../common/EmptyRequiredCollection'

interface IActivityListProps {
    improvementActivities: IActivityListDto[]
    isLoading: boolean
    refresh: () => void
    hasImprovementGroups: boolean
}

const defaultHiddenColumns: { [x: string]: boolean; } = {
    "expectedResult": false,
    "solutionProposal": false,
    "finishedAt": false,
    "evaluatedBy": false,
    "evaluatedAt": false,
    "decisionStatus": false,
    "reporterId": false,
    "authorizedUser": false,
    "decisionResult": false,
    "evaluationRequired": false,
    "evaluationResult": false,
}

const defaultStatusFilters = [
    ImprovementActivityStatus.Active,
    ImprovementActivityStatus.DecisionMade,
    ImprovementActivityStatus.Evaluate,
    ImprovementActivityStatus.PendingDecision
]
const defaultSorting = [{ id: "plannedFinishedAt", desc: false }, { id: "status", desc: false }, { id: "priority", desc: false }]
const ActivityList = ({ isLoading, improvementActivities, refresh, hasImprovementGroups }: IActivityListProps) => {
    const { t } = useTranslation()
    const { state } = getState()
    const navigate = useNavigate()
    const { isDestroying, onDestroy } = useDestroyImprovementActivity(refresh)
    const { isSending, onNotify } = useSendActivityNotification()
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('activityListVisibilityState', defaultHiddenColumns);
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('activityGroupingVisibility', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('activityPaginationState', { pageSize: 10, pageIndex: 0 });
    const [showAll, setShowAll] = useTableStorageBackedState('activityShowAllState', false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('improvementActivityFilteringState', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('improvementActivityGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('improvementActivitySortingState', defaultSorting);
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("improvementActivityExpandedState", {})

    const { isInRole: isAdmin } = useIsInRole(AdminRoles.ImprovementsAdmin)

    const columnHelper = createColumnHelper<IActivityListDto>()
    const columnsReact: ColumnDef<IActivityListDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 140,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => navigate(`./${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Popup
                            content={!getUserFromState(state)(row.original.responsibleId)?.active ? t('disabledResponsibleReminder') : t('sendReminder')}
                            trigger={
                                <span>
                                    <Button type='button' onClick={() => onNotify(row.original.id)} icon={<SemanticFeatherIcon FeatherIcon={Bell} />} disabled={!getUserFromState(state)(row.original.responsibleId)?.active} loading={isSending} />
                                </span>
                            }
                        />
                        {isAdmin && <Button
                            title={t('delete')}
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.idea.split(" ").slice(0, 5).join(" ") }), activityId: row.original.id })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor((r) => t(`improvementActivityListStatus.${r.status}`), {
                id: "status",
                header: t('status'),
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | ImprovementActivityStatus[]) => void, value: ImprovementActivityStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={ImprovementActivityStatus}
                            enumLangKey="improvementActivityListStatus"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`improvementActivityListStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor((r) => r.improvementGroup ? r.improvementGroup?.name : "", {
                id: "groupId",
                header: t('groupId'),
                cell: ({ row }) => <span>{row.original.improvementGroup?.name}</span>
            }),
            columnHelper.accessor((r) => r.priority ? r.priority : "", {
                id: "priority",
                header: t('priority'),
                size: 95,
            }),
            columnHelper.accessor("idea", {
                id: "idea",
                header: t('idea'),
                size: 600,
            }),
            columnHelper.accessor((r) => r.expectedResult ?? "", {
                id: "expectedResult",
                header: t('expectedResult'),
            }),
            columnHelper.accessor((r) => r.solutionProposal ?? "", {
                id: "solutionProposal",
                header: t('suggestion'),
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsible'),
                cell: ({ row }) => <span >{getUserFromState(state)(row.original.responsibleId)?.name}</span>
            }),
            columnHelper.accessor((r) => r.donePercentage ? `${r.donePercentage}%` : "0%", {
                id: "donePercentage",
                header: t('donePercentage'),
                enableColumnFilter: false,
                cell: ({ row }) => <ProgressCell percentage={row.original.donePercentage} />
            }),
            columnHelper.accessor(r => r.plannedFinishedAt ? formatDate(r.plannedFinishedAt) : "", {
                id: "plannedFinishedAt",
                header: t('plannedFinishedAt'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.plannedFinishedAt} />
            }),
            columnHelper.accessor((r) => r.decisionStatus ? t(`improvementDecisionStatus.${r.decisionStatus}`) : "", {
                id: "decisionStatus",
                header: t('decisionStatus'),
                cell: ({ row }) => <span>{row.original.decisionStatus !== null ? t(`improvementDecisionStatus.${row.original.decisionStatus}`) : ""}</span>
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.authorizedUser)?.name ?? "", {
                id: "authorizedUser",
                header: t('authorizedUser'),
                cell: ({ row }) => <span >{getUserFromState(state)(row.original.authorizedUser)?.name}</span>
            }),
            columnHelper.accessor((r) => r.decisionResult ?? "", {
                id: "decisionResult",
                header: t('decisionResult'),
            }),
            columnHelper.accessor("evaluationRequired", {
                id: "evaluationRequired",
                header: t('evaluationRequired'),
                cell: ({ row }) => <Checkbox checked={!!row.original.evaluationRequired} />
            }),
            columnHelper.accessor((r) => r.evaluationResult ?? "", {
                id: "evaluationResult",
                header: t('evaluationResult'),
            }),
            columnHelper.accessor((r) => r.time ? r.time.toString() : "", {
                id: "time",
                header: t('time'),
            }),
            columnHelper.accessor((r) => r.cost ? r.cost.toString() : "", {
                id: "cost",
                header: t('cost'),
            }),
            columnHelper.accessor(r => r.created ? formatDate(r.created) : "", {
                id: "created",
                header: t('reportedDate'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.created} />
            }),
            columnHelper.accessor(r => r.finishedAt ? formatDate(r.finishedAt) : "", {
                id: "finishedAt",
                header: t('finishedAt'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.finishedAt} />
            }),
            columnHelper.accessor((r) => r.evaluatedBy ? r.evaluatedBy : "", {
                id: "evaluatedBy",
                header: t('evaluatedBy'),
            }),
            columnHelper.accessor(r => r.evaluatedAt ? formatDate(r.evaluatedAt) : "", {
                id: "evaluatedAt",
                header: t('evaluatedAt'),
                cell: ({ row }) => <DateCell date={row.original.evaluatedAt} />
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.reporterId)?.name ?? "", {
                id: "reporterId",
                header: t('reporterId'),
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.reporterId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),

        ], [columnHelper, isSending, navigate, onDestroy, onNotify, state, t, isAdmin])



    const table = useReactTable({
        data: improvementActivities || [],
        columns: columnsReact.filter(x => hasImprovementGroups ? x : x.id !== "groupId"),
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [{ id: "status", value: defaultStatusFilters }],
            grouping: [],
            sorting: defaultSorting,
        },
        globalFilterFn: globalFilterFn,
        onGroupingChange: setGrouping,
        onExpandedChange: setExpanded,
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || isDestroying) {
        return <Loading />
    }
    return (
        <FullHeightStackingContainer>
            <Header dividing>{t('improvementActivities')}</Header>
            <EmptyRequiredCollection departmentsRequired={false} />
            <TableReact table={table}
                onAddCallback={() => navigate("./new")}
                rowClassRenderer={(r) => improvementActivitiesStatusRowClass(r)}
                canExport
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: defaultStatusFilters }] }} />
        </FullHeightStackingContainer>
    )
}

export default ActivityList

