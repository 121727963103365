import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Checkbox,
    CheckboxProps,
    Dropdown,
    Form,
    FormFieldProps,
    Grid,
    Input,
    Radio,
    SemanticWIDTHS,
    TextArea,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { getState } from '../../state'
import { getUserFromState, getUserOptionsFromState } from '../../state/helpers'
import { ApplicationState } from '../../state/initial'
import { LineColor } from '../../styles'
import DateInput from './dateInput'
import { FieldErrors, FieldValues } from 'react-hook-form'

export enum DynamicFieldType {
    String = 0,
    Number = 1,
    Date = 2,
    UserDropdown = 3,
    MultipleUserDropdown = 4,
    GenericDropdown = 5,
    Boolean = 6,
    Text = 7,
    RadioButtons = 8,
    None = 9,
}

interface HasValue {
    value: number | string
}

export interface DropdownItem extends HasValue {
    text: string
}

export interface DynamicField {
    value?: string | number | boolean
    subLabel?: string
    labelOverride?: string
    labelTranslateOverride?: boolean
    type: DynamicFieldType
    required?: boolean
    dropdownItems?: DropdownItem[]
    loading?: boolean
    hidden?: boolean
    clearable?: boolean
    attachedTop?: boolean
    attachedBottom?: boolean
}

export type DynamicFieldsSpecification = { [id: string]: DynamicField }
export interface DynamicFieldsProps<T extends FieldValues> {
    fields: DynamicFieldsSpecification
    onChange(name: string, value?: number | string | boolean): void
    errors?: FieldErrors<T>
    standaloneGrid?: boolean
    columns?: number
    translateFieldNames?: boolean
}

export const resolveToPrettyValue = (state: ApplicationState) => (field: DynamicField) => {
    switch (field.type) {
        case DynamicFieldType.Date:
            return field.value ? moment(field.value.toString()).format('Y-MM-DD') : ''
        case DynamicFieldType.UserDropdown:
            return getUserFromState(state)(field.value)?.name ?? ''
        case DynamicFieldType.MultipleUserDropdown:
            return (JSON.parse(field.value?.toString() ?? '[]') as string[])
                .map(id => getUserFromState(state)(id)?.name ?? '')
                .join(', ')
        case DynamicFieldType.Number:
        case DynamicFieldType.String:
        case DynamicFieldType.Text:
            return field.value?.toString() ?? ''
        case DynamicFieldType.GenericDropdown:
            return field.dropdownItems?.find(x => x.value === field.value) ?? ''
        case DynamicFieldType.Boolean:
            return field.value ? 'yes' : 'no' //TODO: Get translations
    }
}

function chunkArray<T>(array: T[], chunkSize: number) {
    const retVal = [] as T[][]
    for (let i = 0; i < array.length; i += chunkSize) {
        retVal.push(array.slice(i, i + chunkSize))
    }

    return retVal
}

const DynamicFields = <T extends FieldValues>({
    fields = {},
    standaloneGrid = false,
    columns = 2,
    onChange,
    translateFieldNames,
    errors
}: DynamicFieldsProps<T>) => {
    const { t } = useTranslation()
    const { state } = getState()

    const visibleFields = Object.entries(fields)
        .filter(([_, field]) => !field.hidden)
        .map(([name, _]) => name)

    const getRowStyles = (subFields: string[]) => {
        const isAttachedTop = subFields.some(f => fields[f]?.attachedTop)
        const isAttachedBottom = subFields.some(f => fields[f]?.attachedBottom)

        return {
            ...(isAttachedTop ? { paddingTop: 0 } : {}),
            ...(isAttachedBottom ? { paddingBottom: 0 } : {}),
        }
    }

    const fieldsJsx = chunkArray(visibleFields, columns).map((subFields, rowId) => (
        <Grid.Row columns={columns as SemanticWIDTHS} key={rowId} style={getRowStyles(subFields)}>
            {subFields.map(fieldName => {
                const dynamicField = fields[fieldName]

                const props = {
                    control: Input,
                    onChange: (_: Event, data: HasValue) => onChange(fieldName, data.value),
                    required: dynamicField.required ?? false,
                    value: dynamicField.value ?? '',
                    loading: dynamicField.loading,
                } as FormFieldProps

                if (dynamicField.type === DynamicFieldType.None) {
                    props.control = 'span'
                } else if (dynamicField.type === DynamicFieldType.Number) {
                    props.type = 'number'
                } else if (dynamicField.type === DynamicFieldType.Date) {
                    props.control = DateInput
                    props.clearable = dynamicField.clearable
                } else if (
                    dynamicField.type === DynamicFieldType.UserDropdown ||
                    dynamicField.type === DynamicFieldType.MultipleUserDropdown ||
                    dynamicField.type === DynamicFieldType.GenericDropdown
                ) {
                    const userOptions = getUserOptionsFromState(state)(
                        u => u.active,
                        dynamicField.value?.toString(),
                    )

                    props.control = Dropdown
                    props.selection = true
                    props.search = true
                    props.clearable = dynamicField.clearable

                    if (props.clearable) {
                        props.onChange = (_: Event, data: HasValue) =>
                            onChange(fieldName, data.value === '' ? undefined : data.value)
                    }

                    if (dynamicField.type === DynamicFieldType.GenericDropdown)
                        props.options = dynamicField.dropdownItems?.map(x => ({
                            key: x.value,
                            text: x.text,
                            value: x.value,
                        }))
                    else props.options = userOptions

                    if (dynamicField.type === DynamicFieldType.MultipleUserDropdown) {
                        props.multiple = true
                        props.value = JSON.parse(dynamicField.value?.toString() ?? '[]')
                        props.onChange = (_: Event, data: DropdownItem) =>
                            onChange(fieldName, JSON.stringify(data.value))
                    }
                } else if (dynamicField.type === DynamicFieldType.Boolean) {
                    props.control = Checkbox
                    delete props.value
                    props.checked = dynamicField.value ?? false
                    props.onChange = (_: Event, x: CheckboxProps) =>
                        onChange(fieldName, x.checked ?? false)
                } else if (dynamicField.type === DynamicFieldType.Text) {
                    props.control = TextArea
                } else if (dynamicField.type === DynamicFieldType.RadioButtons) {
                    props.control = RadioButtonGroup
                    props.options = dynamicField.dropdownItems
                    props.fieldName = fieldName
                    props.onChange = (value: number | string) => onChange(fieldName, value)
                }

                const label = dynamicField.labelOverride ?? fieldName

                return (
                    <Grid.Column key={fieldName}>
                        {dynamicField.subLabel &&
                            dynamicField.type === DynamicFieldType.Boolean && (
                                <SubLabelBefore>{dynamicField.subLabel}</SubLabelBefore>
                            )}

                        <Form.Field
                            error={errors?.extraFields && (errors.extraFields as Record<string, any>)[fieldName]?.value &&
                                { content: t((errors.extraFields as Record<string, any>)[fieldName].value.message), }}
                            label={
                                <>
                                    <label>
                                        {dynamicField.labelTranslateOverride ?? translateFieldNames
                                            ? t(label)
                                            : label}
                                    </label>
                                    {dynamicField.subLabel &&
                                        dynamicField.type !== DynamicFieldType.Boolean && (
                                            <SubLabel>{dynamicField.subLabel}</SubLabel>
                                        )}
                                </>
                            }
                            {...props}
                        />
                    </Grid.Column>
                )
            })}
        </Grid.Row>
    ))

    return standaloneGrid ? <Grid>{fieldsJsx}</Grid> : <>{fieldsJsx}</>
}

const SubLabelBefore = styled.label`
    line-height: 2.5;
    font-weight: bold;
`

const SubLabel = styled.label`
    margin-top: -5px !important;
    font-size: 0.8em !important;
    font-style: italic !important;
    font-weight: normal !important;
`

const RadioButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    border: 0.5px solid ${() => LineColor};
    padding: 0.5rem;
`

interface RadioButtonGroupProps {
    fieldName: string
    options: DropdownItem[]
    value: number | string
    onChange: (selectedValue: number | string) => void
}

const RadioButtonGroup: React.FunctionComponent<RadioButtonGroupProps> = ({
    fieldName,
    options,
    value,
    onChange,
}) => {
    return (
        <RadioButtonContainer>
            {options?.map((o, i) => (
                <Radio
                    key={i}
                    label={o.text}
                    name={fieldName}
                    checked={value === o.value}
                    onChange={() => onChange?.(o.value)}
                />
            ))}
        </RadioButtonContainer>
    )
}

export default DynamicFields
