import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { load, store } from "../../../../actions/persist";

function isFunction<T>(value: any): value is (prev: T) => T {
    return typeof value === 'function';
}

export default function useTableStorageBackedState<T>(
    storageKey: string,
    defaultValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
    const existingValue = load<T>(storageKey);
    const [state, setState] = useState<T>(existingValue ?? defaultValue);

    useEffect(() => {
        store(storageKey, state);
    }, [state, storageKey]);

    const setPersistedState: Dispatch<SetStateAction<T>> = (valueOrUpdater) => {
        setState(prevState => {
            return isFunction<T>(valueOrUpdater) ? valueOrUpdater(prevState) : valueOrUpdater;
        });
    };

    return [state, setPersistedState];
}
