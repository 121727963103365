import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    ResponsiveContainer,
    LineChart,
    YAxis,
    XAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    BarChart,
    Bar,
    PieChart,
    Pie,
    Cell,
} from 'recharts'
import { Dropdown, Header } from 'semantic-ui-react'
import { Measurement } from '../../../hooks/goals/measurement'
import { getGraphColorsFactory } from '../../../styles'
import FlexBox from '../../common/flexBox'

enum ChartType {
    Line,
    Bar,
    StackedBar,
    Pie,
}

export type MeasurementDisplayMap = { [key: number]: boolean }

export interface DiagramProps {
    measurements: Measurement[]
    display: MeasurementDisplayMap
}

export const useGraphData = (measurements: Measurement[], display: MeasurementDisplayMap) =>
    useMemo(() => {
        const allMeasurementValues = measurements
            .filter(x => display[x.id!])
            .flatMap(x =>
                x.measurements.map(m => ({
                    ...m,
                    measurementId: x.id?.toString() ?? '',
                })),
            )

        const uniquexValues = new Set(allMeasurementValues.map(x => x.displayKey ?? ''))
        const sortedXValues = Array.from(uniquexValues).sort()

        const series = sortedXValues.map(displayKey => {
            const values = allMeasurementValues
                .filter(x => x.displayKey === displayKey)
                .reduce((acc, cur) => ({ ...acc, [cur.measurementId]: cur.value }), {})

            return {
                displayKey,
                ...values,
            }
        })

        return {
            series,
            xValues: sortedXValues,
            dataKeys: allMeasurementValues.map(x => x.id!),
        }
    }, [measurements, display])

const useAssignedColors = (measurements: Measurement[]) =>
    useMemo(() => {
        const lineColors = getGraphColorsFactory()

        return measurements.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.id!]: lineColors(),
            }),
            {},
        ) as { [key: number]: string }
    }, [measurements])

const Diagram: React.FunctionComponent<DiagramProps> = ({ measurements, display }) => {
    const { t } = useTranslation()
    const [chartType, setChartType] = useState<ChartType>(ChartType.Line)
    const colors = useAssignedColors(measurements ?? [])
    const { series: graphData } = useGraphData(measurements, display)

    const renderLineChart = () => (
        <ResponsiveContainer height={300}>
            <LineChart data={graphData}>
                <YAxis />
                <XAxis dataKey="displayKey" />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend verticalAlign="top" height={35} />

                {measurements
                    .filter(x => display[x.id!])
                    .map(x => (
                        <Line
                            key={x.id}
                            name={x.detailedType}
                            stroke={colors[x.id!]}
                            dataKey={x.id?.toString() ?? ''}
                        />
                    ))}
            </LineChart>
        </ResponsiveContainer>
    )

    const renderBarChart = () => (
        <ResponsiveContainer height={300}>
            <BarChart data={graphData}>
                <YAxis />
                <XAxis dataKey="displayKey" />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend verticalAlign="top" height={35} />

                {measurements
                    .filter(x => display[x.id!])
                    .map(x => (
                        <Bar
                            key={x.id}
                            name={x.detailedType}
                            fill={colors[x.id!]}
                            dataKey={x.id?.toString() ?? ''}
                        />
                    ))}
            </BarChart>
        </ResponsiveContainer>
    )

    const renderStackedBarChart = () => (
        <ResponsiveContainer height={300}>
            <BarChart data={graphData}>
                <YAxis />
                <XAxis dataKey="displayKey" />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend verticalAlign="top" height={35} />

                {measurements
                    .filter(x => display[x.id!])
                    .map(x => (
                        <Bar
                            key={x.id}
                            name={x.detailedType}
                            stackId="displayKey"
                            fill={colors[x.id!]}
                            dataKey={x.id?.toString() ?? ''}
                        />
                    ))}
            </BarChart>
        </ResponsiveContainer>
    )

    const renderPieChart = () => {
        const cellColors = getGraphColorsFactory()

        return (
            <FlexBox wrap="wrap">
                {measurements
                    .filter(x => display[x.id!])
                    .map(x => (
                        <div key={x.id} style={{ flexGrow: 1 }}>
                            <Header as="h5">{x.detailedType}</Header>
                            <ResponsiveContainer height={300} width={300}>
                                <PieChart>
                                    <Tooltip />
                                    <Pie
                                        key={x.id}
                                        nameKey="displayKey"
                                        dataKey={x.id?.toString() ?? ''}
                                        data={graphData}
                                        stroke={colors[x.id!]}
                                        paddingAngle={2}
                                        label
                                    >
                                        {x.measurements.map(m => (
                                            <Cell key={m.id} fill={cellColors()} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    ))}
            </FlexBox>
        )
    }

    return (
        <>
            {chartType === ChartType.Line
                ? renderLineChart()
                : chartType === ChartType.Bar
                ? renderBarChart()
                : chartType === ChartType.StackedBar
                ? renderStackedBarChart()
                : renderPieChart()}

            <span>
                {t('displayDataAs')}
                &nbsp;
                <Dropdown
                    value={chartType}
                    onChange={(_, d) => setChartType((d.value as ChartType) ?? ChartType.Line)}
                    options={[
                        {
                            value: ChartType.Line,
                            text: t('lineDiagram'),
                        },
                        {
                            value: ChartType.Bar,
                            text: t('barDiagram'),
                        },
                        {
                            value: ChartType.StackedBar,
                            text: t('stackedBarDiagram'),
                        },
                        {
                            value: ChartType.Pie,
                            text: t('pieDiagram'),
                        },
                    ]}
                    inline
                />
            </span>
        </>
    )
}

export default Diagram
