import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow, Label } from 'semantic-ui-react';
import * as z from 'zod';
import ControlDropdown from '../../fields/ControlDropdown';
import ControlDateField from '../../fields/ControlDateField';
import ControlInput from '../../fields/ControlInput';
import ControlTextArea from '../../fields/ControlTextArea';
import { getState } from '../../../state';
import { useTranslation } from 'react-i18next';
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams';
import * as API from '../../../api'
import Loading from '../../common/Loading';
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../actions/error';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IIssueRequest, IIssueDto, IDeviationSettingUser, ModuleCollection, DeviationMethod } from '../../../api/issues';
import { mockedIssueRequest } from '../../../utils/MockedData/Issues';
import { mapIssueToRequest } from '../../../utils/Mappings/Issues';
import Files, { UploadAllPendingFilesCallback } from '../../common/files';
import { useIsInRole } from '../../../hooks/role';
import { AdminRoles } from '../../../auth/roles';
import CollapsingSegment from '../../common/collapsingSegment';
import EvaluateSection from '../common/EvaluateSection';
import CloseSection from '../common/CloseSection';
import ActivityList from '../common/ActivityList';
import ControlCheckbox from '../../fields/ControlCheckbox';
import { isInPageRenderMode } from '../../../actions/util';
import styled from 'styled-components';
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup';
import IssuePrintView from './IssuePrintView';
import { useIssueUsers } from './useIssueUsers';
import ControlExtraField from '../../fields/ControlExtraField';
import { pdf } from '@react-pdf/renderer'
import { blobToBase64 } from '../../../utils/Files';
import { getDepartmentFromState, getLocationFromState, getSupplierFromState, getUserFromState } from '../../../state/helpers';
import IssueReportPdf from './IssueReportPdf';
import moment from 'moment';
import { isArray } from 'lodash';

const StyledGridRow = styled(GridRow)`
    margin: 1em 0;
`
interface IssueFormProps {
    isNew: boolean
    responsibleSafetyUsers: IDeviationSettingUser[]
    refresh?: () => void
    selectedDeviation?: IIssueDto
}

export const deviationRequestSchema = z.object({
    type: z.number({ invalid_type_error: "required" }),
    errorCode: z.coerce.number().optional().nullable(),
    causeCode: z.coerce.number().optional().nullable(),
    topic: z.string().min(1, "required"),
    departmentId: z.number({ invalid_type_error: "required" }),
    locationId: z.coerce.number().optional().nullable(),
    responsibleId: z.string().min(1, "required"),
    supplierId: z.coerce.number().optional().nullable(),
    customerSupplier: z.string().optional().nullable(),
    doneBefore: z.date().optional().nullable(),
    description: z.string().optional().nullable(),
    actionsTaken: z.string().optional().nullable(),
    reportedBy: z.string().min(1, "required"),
    reportDate: z.date({ invalid_type_error: "required" }),
    useReview: z.boolean(),
    extraInformation1: z.string().optional().nullable(),
    extraInformation2: z.string().optional().nullable(),
    reviewDate: z.date().nullable(),
    reviewResponsibleId: z.string().nullable(),
    reviewBeforeDate: z.date().nullable(),
    reviewText: z.string().optional().nullable(),
    endedDate: z.date().optional().nullable(),
    endedBy: z.string().optional().nullable(),
    analysis: z.string().nullable(),
    resolvingAction: z.string().nullable(),
    time: z.coerce.number().nullable(),
    cost: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
    feedback: z.boolean(),
    sendEmailToResponsible: z.boolean(),
    sent: z.date().nullable(),
    replied: z.boolean().nullable(),
    supplierShouldReply: z.boolean().nullable(),
    alternativeEmail: z.string().email('invalidEmail').optional().or(z.literal('')),
    sendDeviationManagementEmail: z.boolean(),
    extraFields: z.any(),
    teamMembers: z.array(z.string()),
    deviationMethod: z.nativeEnum(DeviationMethod),
    desiredEffect: z.boolean().optional().nullable(),
    desiredEffectComment: z.string().optional().nullable(),
    sendEmailToTeamMembers: z.boolean().optional().nullable(),
    sendEightDReportToTeamMembers: z.boolean().optional().nullable()
});

const IssueForm = ({
    isNew,
    responsibleSafetyUsers,
    refresh,
    selectedDeviation,
}: IssueFormProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const printComponentRef = useRef<HTMLDivElement>(null);
    const {
        dispatch,
        state: { locations, departments, userProfile, suppliers, companyLogoPath }, state,
    } = getState()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.IssuesAdminRole)

    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()
    const { deviationResponsibleOptions, userOptions } = useIssueUsers(responsibleSafetyUsers, isNew, selectedDeviation)

    const { control, handleSubmit, watch, setValue, clearErrors, setError, getValues } = useForm<IIssueRequest>({
        resolver: zodResolver(deviationRequestSchema),
        defaultValues: selectedDeviation ? mapIssueToRequest(selectedDeviation) : mockedIssueRequest(userProfile)
    });

    const {
        isLoading: isLoadingExtra,
        data: extraFields,
    } = useGetQueryByParams(true, API.issues.getExtraFields, "deviationExtra", ModuleCollection.Deviations)

    const {
        isLoading: isLoadingTypes,
        data: types,
    } = useGetQueryByParams(true, API.issues.getDeviationTypes, "deviationTypes")

    const watchType = watch('type')
    const watchDeviationMethod = watch('deviationMethod')


    const {
        isLoading: isLoadingErrorCodes,
        data: errorCodes,
    } = useGetQueryByParams(!!watchType, API.issues.getTypeErrorCodes, "deviationErrorCodes", watchType)
    const watchErrorCode = watch('errorCode')
    const {
        isLoading: isLoadingCauseCodes,
        data: causeCodes,
    } = useGetQueryByParams(!!watchErrorCode, API.issues.getTypeErrorCauseCodes, "deviationCauseCodes", watchErrorCode)

    const {
        isLoading: isLoadingSpecialProject,
        data: specialProjects,
    } = useGetQueryByParams(true, API.projects.getAllSpecialProjects, "specialProjects")

    const {
        isLoading,
        data: files,
    } = useGetQueryByParams(!!selectedDeviation?.id, API.issues.getAllIssueFiles, "issueFiles", selectedDeviation?.id)

    const {
        isLoading: isLoadingActivities,
        data: activities,
    } = useGetQueryByParams(!!selectedDeviation?.id, API.issues.getIssueActivitiesById, "issueActivities", selectedDeviation?.id)

    const validateExtraFields = (data: {
        [key: string]: string | string[];
    }): boolean => {
        if (extraFields?.extraFields) {
            let hasError = false;
            Object.keys(extraFields?.extraFields).forEach(key => {
                if (extraFields?.extraFields[key].required && !data[key]) {
                    setError(`extraFields.${key}`, {
                        type: "required",
                        message: "required"
                    });
                    hasError = true;
                } else {
                    clearErrors(`extraFields.${key}`);
                }
            });
            return !hasError;
        }
        return true;

    };

    const createAndSendPDF = async (data: IIssueDto) => {
        try {
            const mappedActivities = activities?.map(a => ({
                id: a.id,
                activity: a.activity,
                description: a.description,
                responsibleId: getUserFromState(state)(a.responsibleId)?.name ?? "",
                doneBefore: a.doneBefore ? moment(a.doneBefore).format('Y-MM-DD') : '',
                status: t(`issueStatus.${a.status}`)
            }));

            const mappedExtraFields = extraFields && extraFields.extraFields ?
                Object.keys(extraFields?.extraFields).map(key => ({
                    label: key,
                    value: extraFields.extraFields[key].type === 4 && isArray(data.extraFields[key])
                        ? (data.extraFields[key] as string[]).map(id => getUserFromState(state)(id)?.name ?? '').join(', ') ?? ""
                        : (data.extraFields[key] as string) ?? "",
                })) : [];

            const pdfData = await pdf(
                <IssueReportPdf
                    issue={data}
                    logoPath={companyLogoPath ?? '/images/viqma-system-logo-dark.png'}
                    teamMembers={data.teamMembers.map(id => getUserFromState(state)(id)?.name ?? '').join(', ') ?? ""}
                    desiredEffect={data.desiredEffect ? t('yes') : t('no')}
                    responsible={getUserFromState(state)(data.responsibleId)?.name ?? ''}
                    reportedBy={getUserFromState(state)(data.reportedBy)?.name ?? ''}
                    evaluatedBy={getUserFromState(state)(data.reviewResponsibleId)?.name ?? ''}
                    departmentId={data.departmentId ? getDepartmentFromState(state)(data.departmentId)?.name ?? '' : ''}
                    locationId={data.locationId ? getLocationFromState(state)(data.locationId)?.name ?? '' : ''}
                    supplierId={data.supplierId ? getSupplierFromState(state)(data.supplierId)?.name ?? '' : ''}
                    activities={mappedActivities}
                    mappedExtraFields={mappedExtraFields}
                    files={files}
                    types={types}
                    errorCodes={errorCodes}
                    causeCodes={causeCodes} />
            ).toBlob();

            const base64PDF = await blobToBase64(pdfData);

            const promises = data.teamMembers.map(member => {
                const notifyData = {
                    methods: ["email"],
                    toUserId: member,
                    templateContents: {
                        templateKey: "IssueEightDReport",
                        values: {
                            title: data.topic,
                            reportDate: moment(data.reportDate).format('Y-MM-DD') ?? 'N/A',
                            ownId: data.ownId.toString(),
                        }
                    },
                    tracking: {
                        attachedId: data.id.toString(),
                        set: "IssueEightDReport"
                    },
                    attachments: [
                        {
                            Name: '8D-Report.pdf',
                            ContentType: 'application/pdf',
                            Content: base64PDF
                        }
                    ]
                };

                return API.issues.sendEightDReport(notifyData);
            });

            await toast.promise(
                Promise.all(promises),
                {
                    pending: t('8dReportPending'),
                    success: t('8dReportSuccess'),
                    error: t('8dReportError')
                }
            );
        } catch (error: any) {
            handleAxiosError(dispatch)(error)
        }
    };

    const onDeviationRequest = async (data: IIssueRequest) => {
        if (validateExtraFields(data.extraFields)) {
            if (isNew) {
                return await API.issues.create(data)
            } else {
                let result;
                if (data.feedback) {
                    result = await API.issues.edit(selectedDeviation!.id, data);
                    await API.issues.notifyIssueFeedback(selectedDeviation!.id);
                } else {
                    result = await API.issues.edit(selectedDeviation!.id, data);
                }
                return result;
            }
        }

    }

    const handleOnClose = () => {
        if (refresh) {
            refresh()
            navigate(isInPageRenderMode() ? '/issues' : '../..')
        } else {
            navigate(-1)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onDeviationRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                if (uploadAllPendingFiles) await uploadAllPendingFiles(data.data.id?.toString())
                toast.success(t("saved"))
                if (getValues("sendEightDReportToTeamMembers")) await createAndSendPDF(data.data)
                handleOnClose()
            }

        },
    })

    const onHandleNewIssueResponsible = (value: string) => {
        if (isNew) {
            setValue("reviewResponsibleId", value)
        }
        setValue("responsibleId", value)
        clearErrors("responsibleId")
    }
    const onHandleChangeIssueType = (value: number) => {
        setValue("type", value)
        setValue("errorCode", null)
        setValue("causeCode", null)
        clearErrors("type")
    }

    if (isLoadingTypes || isLoadingErrorCodes || isLoadingCauseCodes || isLoadingSpecialProject || isLoadingExtra || isLoadingActivities || isLoading) {
        return <Loading />
    }

    return (

        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            {!isNew &&
                <IssuePrintView
                    ref={printComponentRef}
                    issue={watch()}
                    issueId={selectedDeviation?.id}
                    ownId={selectedDeviation?.ownId}
                    types={types}
                    errorCodes={errorCodes}
                    causeCodes={causeCodes}
                />}

            <SaveCancelPrintButtonGroup
                location="top"
                onCancel={handleOnClose}
                disableCancel={isSaving}
                disableSave={isSaving}
                disablePrint={isNew || isSaving}
                printComponentRef={printComponentRef}
            />

            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown name={`deviationMethod`} label={t('deviationMethod')} control={control}
                            collection={[
                                { value: DeviationMethod.Pdca, text: t('deviationMethod.0') },
                                { value: DeviationMethod.EightD, text: t('deviationMethod.1') },
                            ]}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown name="reportedBy" label={t('reportedBy')} control={control} required collection={userOptions} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField name="reportDate" label={t('reportDate')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="3">
                    <GridColumn >
                        <ControlDropdown
                            name="type"
                            label={t('issueType')}
                            control={control}
                            required
                            collection={types?.map(d => ({ value: d.id, text: d.name })) ?? []}
                            disabled={isSaving}
                            overrideOnChange={(value) => onHandleChangeIssueType(value)} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDropdown name="errorCode" label={t('errorCode')} control={control} collection={errorCodes?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={!watchType || isSaving || !errorCodes?.length} clearable />
                    </GridColumn>
                    <GridColumn >
                        <ControlDropdown name="causeCode" label={t('causeCode')} control={control} collection={causeCodes?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={!watchErrorCode || isSaving || !causeCodes?.length} clearable />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown name="departmentId" label={t('departmentId')} control={control} required collection={departments.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        {locations && locations.length ? <ControlDropdown name="locationId" label={t('locationId')} control={control} collection={locations?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} clearable /> : null}
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown name="responsibleId" label={t('responsibleId')} control={control} required collection={deviationResponsibleOptions.length ? deviationResponsibleOptions : userOptions} disabled={isSaving} overrideOnChange={(value) => onHandleNewIssueResponsible(value)} />
                    </GridColumn>
                    {isNew && <GridColumn verticalAlign='bottom'>
                        <ControlCheckbox name="sendEmailToResponsible" label={t('sendEmailToResponsible')} control={control} disabled={isSaving} />
                    </GridColumn>}
                </GridRow>
                {watchDeviationMethod === DeviationMethod.EightD &&
                    <GridRow columns="2">
                        <GridColumn>
                            <Label as='a' color='green' ribbon>
                                D1
                            </Label>
                            <ControlDropdown name="teamMembers" label={t('teamMembers')} control={control} collection={userOptions} multiple disabled={isSaving} />
                        </GridColumn>
                        {isNew && <GridColumn verticalAlign='bottom'>
                            <ControlCheckbox name="sendEmailToTeamMembers" label={t('sendEmailToTeamMembers')} control={control} disabled={isSaving} />
                        </GridColumn>}
                    </GridRow>
                }
                {suppliers?.length ?
                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDropdown name="supplierId" label={t('supplierId')} control={control} collection={suppliers?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} clearable />
                        </GridColumn>
                        {
                            watch("supplierId") ?
                                <GridColumn>
                                    <ControlDropdown name="supplierShouldReply" label={t('deviationAnswer')} control={control} collection={[{ value: false, text: t('weReply') }, { value: true, text: t('supplierReply') }]} disabled={isSaving} />
                                </GridColumn>
                                : null
                        }
                    </GridRow>
                    : null}
                <GridRow columns="2">
                    {watch("supplierShouldReply") &&
                        <>
                            <GridColumn>
                                <ControlInput name="alternativeEmail" label={t('altEmail')} control={control} disabled={isSaving} explanatoryText={t('explanatoryText.altEmail')} />
                            </GridColumn>

                            <GridColumn verticalAlign='bottom'>
                                <ControlCheckbox popupText={t('sendDeviationManagementEmailChecked')} name="sendDeviationManagementEmail" label={t('sendDeviationManagementEmail')} control={control} disabled={isSaving} />
                            </GridColumn>
                        </>
                    }
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="customerSupplier" label={t('customer')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="doneBefore" label={t('doneBefore')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                {extraFields &&
                    <ControlExtraField control={control} disabled={isSaving} userOptions={userOptions} extraFields={extraFields.extraFields} />
                }

                {specialProjects && specialProjects.length ?
                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDropdown name="projectId" label={t('specialProject')} control={control} collection={specialProjects?.map(d => ({ value: d.id, text: d.title })) ?? []} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn>

                        </GridColumn>
                    </GridRow>
                    : null}

                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput name="topic" label={t('heading')} control={control} required disabled={isSaving} />
                    </GridColumn>

                </GridRow>

                <GridRow columns="1">
                    <GridColumn >
                        {watchDeviationMethod === DeviationMethod.EightD && <Label as='a' color='green' ribbon>
                            D2
                        </Label>}
                        <ControlTextArea name="description" label={t('description')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        {watchDeviationMethod === DeviationMethod.EightD && <Label as='a' color='green' ribbon>
                            D3
                        </Label>}
                        <ControlTextArea name="actionsTaken" label={t('actionsTakenInfo')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <StyledGridRow>
                    <GridColumn >
                        <Files
                            displayTitle
                            module="Issues"
                            disableDelete={!isAdmin}
                            enableDragDrop
                            mayAutoUpload={!!selectedDeviation}
                            collectionId={selectedDeviation && selectedDeviation.id.toString()}
                            onFilesAdded={selectedDeviation ? undefined : (files, uploadAllPendingFiles) => {
                                if (!files.length) return
                                setUploadAllPendingFiles(() => uploadAllPendingFiles)
                            }}
                        />
                    </GridColumn>
                </StyledGridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <CollapsingSegment heading={t('analysisAction')} defaultOpen={!isNew}>
                            <Grid stackable>
                                <GridRow columns="1">
                                    <GridColumn >
                                        {watchDeviationMethod === DeviationMethod.EightD && <Label as='a' color='green' ribbon>
                                            D4
                                        </Label>}
                                        <ControlTextArea name="analysis" label={t('analysisInfo')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>
                                <GridRow columns="1">
                                    <GridColumn >
                                        {watchDeviationMethod === DeviationMethod.EightD && <Label as='a' color='green' ribbon>
                                            D5
                                        </Label>}
                                        <ControlTextArea name="resolvingAction" label={t('resolvingActionInfo')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>
                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlInput
                                            name="time"
                                            label={t("time")}
                                            control={control}
                                            disabled={isSaving}
                                            type="number" />
                                    </GridColumn>
                                    <GridColumn >
                                        <ControlInput
                                            name="cost"
                                            label={t("cost")}
                                            control={control}
                                            disabled={isSaving}
                                            type="number" />
                                    </GridColumn>
                                </GridRow>
                                {!isNew && selectedDeviation && <GridRow >
                                    <GridColumn >
                                        <ActivityList
                                            deviationId={selectedDeviation?.id}
                                            userOptions={userOptions}
                                            localStorageKeys={{
                                                visibility: "issueActivityListVisibilityState",
                                                grouping: "issueActivityGroupingVisibility",
                                                pagination: "issueActivityPaginationState",
                                                show: "issueActivityShowAllState",
                                                columnFilters: "issueActivityColumnFiltersState",
                                                global: "issueActivityGlobalFilterState",
                                                sorting: "issueActivitySortingState",
                                                expanded: "issueActivityExpandedState"
                                            }}
                                            apiFunctions={{
                                                listKey: "issueActivities",
                                                getActivitiesById: API.issues.getIssueActivitiesById,
                                                notifyActivity: API.issues.notifyIssueActivity,
                                                editActivity: API.issues.editIssueActivity,
                                                createActivity: API.issues.createIssueActivity
                                            }}
                                            notifyClosingTranslationKey='notifyClosingIssueActivityResponsible'
                                            notifyCreationTranslationKey='notifyCreationActivityResponsible' />
                                    </GridColumn>
                                </GridRow>}
                                {watchDeviationMethod === DeviationMethod.EightD &&
                                    <>
                                        <GridRow columns="1">
                                            <GridColumn >
                                                {watchDeviationMethod === DeviationMethod.EightD && <Label as='a' color='green' ribbon>
                                                    D6
                                                </Label>}
                                                <ControlCheckbox popupText={isSaving || watch("responsibleId") !== userProfile?.sub ? t('desiredEffectPopUp') : ""} name="desiredEffect" label={t('desiredEffectInfo')} control={control} disabled={isSaving || watch("responsibleId") !== userProfile?.sub} />
                                            </GridColumn>
                                        </GridRow>
                                        <GridRow columns="1">
                                            <GridColumn >
                                                <ControlTextArea name="desiredEffectComment" label={t('desiredEffectComment')} control={control} disabled={isSaving} />
                                            </GridColumn>
                                        </GridRow>
                                    </>

                                }
                            </Grid>
                        </CollapsingSegment>

                    </GridColumn>
                </GridRow>

                {!isNew && <GridRow >
                    <GridColumn>
                        <CloseSection
                            control={control}
                            isSaving={isSaving}
                            title={t('closeDeviation')}
                            isAdmin={isAdmin}
                            isNew={isNew} />
                    </GridColumn>
                </GridRow>}

                {!isNew && watch("useReview") ? (
                    <GridRow >
                        <GridColumn>
                            <EvaluateSection control={control} isSaving={isSaving} users={userOptions} deviationMethod={watchDeviationMethod} />
                        </GridColumn>
                    </GridRow>
                ) : null}
                <GridRow columns="1">
                    <GridColumn >
                        {watchDeviationMethod === DeviationMethod.EightD && !isNew &&
                            <>
                                <Label as='a' color='green' ribbon>
                                    D8
                                </Label>

                                <ControlCheckbox name="sendEightDReportToTeamMembers" label={t('sendEightDReportToTeamMembers')} control={control} disabled={isSaving} />
                            </>
                        }
                    </GridColumn>
                </GridRow>
            </Grid>
            <SaveCancelPrintButtonGroup
                location="bottom"
                onCancel={handleOnClose}
                disableCancel={isSaving}
                disableSave={isSaving}
                disablePrint={isNew || isSaving}
                printComponentRef={printComponentRef}
            />
        </Form>
    );
};

export default IssueForm;
