import Axios from 'axios'
import jwt_decode from 'jwt-decode'
import { hasProfileCompanyIdMissmatch } from '.'
import { clearSession, loadSession, storeSession } from '../actions/persist'
import { ProfileWithRoles } from './roles'

const TOKEN_STORAGE_KEY = 'delegated-token'

export const getDelegatedToken = () =>
    Axios.post<string>(`/token?scope=${window.env.REACT_APP_REQUESTED_SCOPES}`).then(x => x.data)

export const doDelegatedLogin = async () => {
    const getStoredTokenIfValid = () => {
        const token = loadSession<string>(TOKEN_STORAGE_KEY)

        if (!token || token.toLowerCase().indexOf('html') !== -1) return null

        const decodedToken = jwt_decode<ProfileWithRoles>(token)
        if (hasExpired(decodedToken)) return null

        if (hasProfileCompanyIdMissmatch(decodedToken)) return null

        return { decodedToken, token }
    }

    const result = getStoredTokenIfValid()
    if (result) return result

    const token = await getDelegatedToken()

    if (token.toLowerCase().indexOf('html') !== -1) {
        //If not logged in the web forms page might answer with a login page....
        window.location.reload()
        return { decodedToken: null, token: '' }
    }

    storeSession(TOKEN_STORAGE_KEY, token)

    //Clear swr cache since it may have stuff belonging to a different company
    // const { cache } = useSWRConfig()
    // ;(cache as any).clear()

    return {
        decodedToken: jwt_decode<ProfileWithRoles>(token),
        token,
    }
}

export const removedStoredToken = () => {
    clearSession(TOKEN_STORAGE_KEY)
}

export const hasExpired = (profile: ProfileWithRoles) => Date.now() >= profile.exp * 1000
