import React, { useEffect } from 'react'
import { Button, Form, Grid, GridColumn, GridRow, Header, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import * as API from '../../../../../api'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import { getState } from '../../../../../state'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { IStatisticConfigurationRequest } from '../../../../../api/companies'
import { zodResolver } from '@hookform/resolvers/zod'
import { handleAxiosError } from '../../../../../actions/error'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { Save } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import ControlInput from '../../../../fields/ControlInput'
import ControlCheckbox from '../../../../fields/ControlCheckbox'
import globalCompanyInfoStartupAction from '../../../../../actions/company'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import Loading from '../../../../common/Loading'

export const statisticConfigurationRequestSchema = z.object({
    laborCost: z.coerce.number().optional().nullable(),
    deviationTypeYear: z.boolean().optional().nullable(),
    deviationTypeMonth: z.boolean().optional().nullable(),
    qualityDeviationCost: z.boolean().optional().nullable(),
    deviationsReportedInTime: z.boolean().optional().nullable(),
    remedyEffecieny: z.boolean().optional().nullable(),
    accidentTypeYearBar: z.boolean().optional().nullable(),
});

const StatisticConfiguration = () => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()

    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.companies.getStatisticsConfiguration, "statisticsConfiguration")

    const { control, handleSubmit, reset } = useForm<IStatisticConfigurationRequest>({
        resolver: zodResolver(statisticConfigurationRequestSchema),
    });

    useEffect(() => {
        if (data) {
            reset({
                laborCost: state.company?.laborCost || 0,
                deviationTypeYear: data.avvAr2 ?? false,
                deviationTypeMonth: data.avvMan ?? false,
                qualityDeviationCost: data.avvKost ?? false,
                deviationsReportedInTime: data.avvTid ?? false,
                remedyEffecieny: data.avvEffekt ?? false,
                accidentTypeYearBar: data.olyAr1 ?? false,
            });
        } else {
            reset();
        }
    }, [data, reset, state.company?.laborCost]);

    const onStatisticConfigurationRequest = async (data: IStatisticConfigurationRequest) => await API.companies.editStatisticConfiguration(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onStatisticConfigurationRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            refresh()
            globalCompanyInfoStartupAction(dispatch)
        },
    })

    if (isLoading) {
        return <Loading />
    }
    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('deviationSettings.statisticsHeader')}
                </Message.Header>
                {t('deviationSettings.statisticsMessageBody')}
            </Message>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <Grid stackable>
                    <GridRow columns={3}>
                        <GridColumn>
                            <ControlInput name="laborCost" label={t('laborCostInfo')} control={control} type="number" disabled={isSaving} />
                        </GridColumn>

                    </GridRow>
                    <Header as="h3">{t('deviationStatisticStartpage')}</Header>

                    <GridRow columns="3">
                        <GridColumn>
                            <ControlCheckbox name="deviationTypeYear" label={t('deviationTypeYear')} control={control} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn>
                            <ControlCheckbox name="deviationTypeMonth" label={t('deviationTypeMonth')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="3">
                        <GridColumn>
                            <ControlCheckbox name="qualityDeviationCost" label={t('qualityDeviationCost')} control={control} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn>
                            <ControlCheckbox name="deviationsReportedInTime" label={t('deviationsReportedInTime')} control={control} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn>
                            <ControlCheckbox name="remedyEffecieny" label={t('remedyEffecieny')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                    <Header as="h3">{t('accidentStatisticStartpage')}</Header>
                    <GridRow columns="3">
                        <GridColumn>
                            <ControlCheckbox name="accidentTypeYearBar" label={t('accidentTypeYearBar')} control={control} disabled={isSaving} />
                        </GridColumn>

                    </GridRow>
                    <GridRow >
                        <GridColumn verticalAlign='bottom'>
                            <Button
                                primary
                                floated='right'
                                content={t('save')}
                                icon={
                                    <SemanticFeatherIcon
                                        FeatherIcon={Save}
                                    />
                                }
                                type={'submit'}
                                disabled={isSaving}
                            />
                        </GridColumn>
                    </GridRow>

                </Grid>

            </Form>

        </FullHeightStackingContainer>
    )
}

export default StatisticConfiguration
