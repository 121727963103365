import useSWR, { mutate } from 'swr'
import useApi from '../useApi'

export const CourseStatusEvalulate = 'Utvärdera'
export const CourseStatusComplete = 'Utförd'

export interface Course {
    id: number
    status?: string
    name: string
    heldBy?: string
    location?: string
    scope?: string
    datePlanned?: Date
    dateCompleted?: string
}

export interface CourseEvaluation {
    review: string
    lessonsLearned: string
    satisfiedPercentage?: number
    recommend: boolean
    dateCompleted?: string
}

export const useMyCourses = () =>
    useSWR<Course[]>(`${window.env.REACT_APP_SKILL_SERVICE}/api/courses/my`)

export const useNumCoursesToEvaluate = () => {
    const { data } = useMyCourses()
    return data?.filter(x => x.status === CourseStatusEvalulate)?.length ?? 0
}

export const useCourseEvaluation = () => {
    const { post, loading, error } = useApi<Course, CourseEvaluation>(undefined)

    return {
        error,
        loading,
        evaluate: (courseId: number, evaluation: CourseEvaluation) =>
            post(
                evaluation,
                `${window.env.REACT_APP_SKILL_SERVICE}/api/courses/${courseId}/evaluate`,
            ).then(() => {
                mutate(`${window.env.REACT_APP_SKILL_SERVICE}/api/courses/my`)
            }),
    }
}
