import React from 'react'
import { Bell } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button } from 'semantic-ui-react'
import { handleAxiosError } from '../../actions/error'
import { useReminder } from '../../hooks/reminder'
import { getState } from '../../state'
import { getUserFromState } from '../../state/helpers'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import CustomConfirm from './customConfirm'

export interface ReminderButtonProps {
    template: string
    trackingSetId?: string | number
    userId?: string
    params: unknown
    useToast?: boolean
}

const ReminderButton: React.FunctionComponent<ReminderButtonProps> = ({
    template,
    trackingSetId,
    userId,
    params,
    useToast = true,
}) => {
    const { t } = useTranslation()
    const { dispatch, state } = getState()
    const user = getUserFromState(state)(userId)
    const { data, sendReminder } = useReminder(template, trackingSetId)

    return (
        <CustomConfirm
            trigger={
                <Button
                    type="button"
                    size="small"
                    icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                    disabled={!userId || !user?.active}
                    title={`${t('notificationsSend')} ${data?.count}`}
                />
            }
            onConfirm={() =>
                sendReminder(userId!, params)
                    .then(() => {
                        if (useToast) toast(t('mailHasBeenSend'), { type: 'success' })
                    })
                    .catch(handleAxiosError(dispatch))
            }
            text={t('areYouSureYouWishToSendReminder')}
        />
    )
}

export default ReminderButton
