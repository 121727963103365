import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../api'
import { IPositionHierarchyDto } from '../../../api/skills';
import ControlInput from '../../fields/ControlInput';
import FormActions from '../FormActions';
import { mockedPositionRequest } from "../../../utils/MockedData/Competence";
import { ExpandedState } from '@tanstack/react-table';
import { PositionModalType } from '../../modules/skills/admin/PositionList';
import EditDocument from '../../modules/docs/viewPage/Edit/editDocument';
import { Editor } from 'tinymce/tinymce'

export interface IPositionFormProps {
    position?: IPositionHierarchyDto
    onCancel: () => void
    refresh: () => void
    modal: PositionModalType
    selectedTableRowId?: string | null
    setExpanded?: React.Dispatch<React.SetStateAction<ExpandedState>>
    expanded?: ExpandedState
}

export const responsilbeSafetyRequestSchema = z.object({
    id: z.number(),
    name: z.string().min(1, "required"),
    description: z.string().optional().nullable(),
    parentId: z.number().nullable(),
});

const PositionForm = ({ position, onCancel, refresh, modal, selectedTableRowId, setExpanded, expanded }: IPositionFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IPositionHierarchyDto>({
        defaultValues: position ? position : mockedPositionRequest(),
        resolver: zodResolver(responsilbeSafetyRequestSchema),
    });
    const [editor, setEditor] = useState<Editor>()
    const onPositionRequest = async (data: IPositionHierarchyDto) => {
        if (modal === "newChild" && setExpanded && selectedTableRowId) {
            const castExpanded = expanded as Record<string, boolean>
            setExpanded({ ...castExpanded, ...{ [selectedTableRowId]: true } })
        }
        if (editor) {
            // this is important since it uploads the images and sets the content to the url
            // Otherwise the description gets very heavy.
            await editor.uploadImages()
            data.description = editor.getContent()
            switch (modal) {
                case "new":
                    return await API.skills.createPosition(data)
                case "newChild":
                    return await API.skills.createPosition(data)
                case "edit":
                    return await API.skills.editPosition(data)
            }
        }
    }


    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onPositionRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <FormActions disabled={isSaving} onCancel={onCancel} />
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput name="name" label={t('position')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn >
                        <Controller
                            name={"description"}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <EditDocument value={value} onChange={onChange} onEditorInit={editor => setEditor(editor)} />
                            )}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default PositionForm;