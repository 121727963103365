import React, { useState } from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Save, Check, ArrowLeftCircle, Printer } from 'react-feather'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import ReactToPrint from 'react-to-print'
import Spacer from './spacer'
import ClearFix from './clearFix'
import styled from 'styled-components'

const ButtonContainer = styled.div`
    display:flex;
    justify-content: end;
`
export interface SaveCancelPrintButtonGroupProps {
    location: 'top' | 'bottom'
    onSave?(): void | Promise<unknown>
    onCancel(): void
    disableSave?: boolean
    disableCancel?: boolean
    disablePrint?: boolean
    printComponentRef?: React.MutableRefObject<any>
    printComponent?: JSX.Element
    saveButtonType?: 'save' | 'okey'
    savePopupText?: string
}

const SaveCancelPrintButtonGroup: React.FunctionComponent<SaveCancelPrintButtonGroupProps> = ({
    location,
    onSave,
    disableSave,
    onCancel,
    disableCancel,
    disablePrint,
    printComponentRef,
    printComponent,
    saveButtonType = 'save',
    savePopupText
}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const getPrintRef = () =>
        printComponentRef ? printComponentRef.current : React.cloneElement(printComponent!).ref

    const handleSave = () => {
        if (!onSave) return

        const ret = onSave()
        if ((ret as Promise<unknown>).finally) {
            setLoading(true)
                ; (ret as Promise<unknown>).finally(() => setLoading(false))
        }
    }

    return (
        <>
            {location === 'bottom' ? <Spacer spacing="20px" /> : null}
            <ClearFix>
                <ButtonContainer>
                    <Popup
                        content={disableSave && savePopupText}
                        disabled={!disableSave || !savePopupText}
                        trigger={
                            <span>
                                <Button
                                    primary
                                    content={t(saveButtonType)}
                                    icon={
                                        <SemanticFeatherIcon
                                            FeatherIcon={saveButtonType === 'save' ? Save : Check}
                                        />
                                    }
                                    onClick={handleSave}
                                    type={onSave ? 'button' : 'submit'}
                                    disabled={disableSave || loading}
                                    loading={loading}
                                />
                            </span>
                        }
                    />
                    <Button
                        content={t('cancel')}
                        icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                        onClick={onCancel}
                        type="button"
                        disabled={disableCancel}
                    />
                    {printComponentRef || printComponent ? (
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    content={t('print')}
                                    disabled={disablePrint}
                                    icon={<SemanticFeatherIcon FeatherIcon={Printer} />}
                                    type="button"
                                />
                            )}
                            content={getPrintRef}
                            pageStyle="padding: 20px;"
                        />
                    ) : null}
                </ButtonContainer>
            </ClearFix>
            {location === 'top' ? <Spacer spacing="20px" /> : null}
        </>
    )
}

export default SaveCancelPrintButtonGroup
