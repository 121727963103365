import React from 'react'
import { Routes, Route } from 'react-router'
import { isInPageRenderMode } from '../../../actions/util'
import { Grid } from 'semantic-ui-react'
import SuppliersMenu from './SuppliersMenu'
import SuppliersList from './SuppliersList'
import Loading from '../../common/Loading'
import * as API from '../../../api'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import SupplierFormPage from './SupplierFormPage'

const SuppliersModule: React.FunctionComponent = () => {
    const { isLoading, data, refresh } = useGetQueryByParams(true, API.suppliers.getAll, "suppliersList")
    const {
        isLoading: isLoadingQuestions,
        data: questions,
    } = useGetQueryByParams(true, API.suppliers.getAllExternalQuestions, "AllExternalQuestions")

    if (isLoading || !data || isLoadingQuestions || !questions) {
        return <Loading />
    }
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                {isInPageRenderMode() && (
                    <Grid.Column tablet={16} largeScreen={4} widescreen={3}>
                        <SuppliersMenu />
                    </Grid.Column>
                )}
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route index element={<SuppliersList suppliers={data} externalQuestions={questions} refresh={refresh} />} />
                        <Route path="/detail/">
                            <Route path="new" element={<SupplierFormPage isNew refresh={refresh} />} />
                            <Route path={`:supplierId`} element={<SupplierFormPage isNew={false} refresh={refresh} />} />
                        </Route>
                    </Routes>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default SuppliersModule
