import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, DropdownProps, Form } from 'semantic-ui-react'
import { useCompanyOptions } from '../../../../hooks/company'
import { useDocumentTemplatesForCompany } from '../../../../hooks/docs/templates'
import { IViqmaDocument } from '../../../../api/documents'

export interface CopyFromSelectionFormProps {
    onCopy: (document: IViqmaDocument) => void
}

const CopyFromSelectionForm: React.FunctionComponent<CopyFromSelectionFormProps> = ({ onCopy }) => {
    const { t } = useTranslation()

    const { options: companyOptions, loading: companyOptionsLoading } = useCompanyOptions()
    const [companyId, setCompanyId] = useState('50')
    const [selectedDocumentId, setSelectedDocumentId] = useState<string>()
    const { data: templates, isValidating: loading } = useDocumentTemplatesForCompany(companyId)

    const templateOptions = templates?.map(x => ({
        key: x.id,
        text: x.name,
        value: x.id.toString(),
    }))

    const onCopyClick = () => {
        const document = templates?.find(x => x.id.toString() === selectedDocumentId)
        if (document)
            onCopy({ ...document, approvedBy: null, sortIndex: undefined, isPublished: true })
    }

    return (
        <Form>
            <Form.Group>
                <Form.Field
                    label={t('company')}
                    control={Dropdown}
                    value={companyId}
                    onChange={(_: Event, d: DropdownProps) => setCompanyId(d.value!.toString())}
                    options={companyOptions}
                    loading={companyOptionsLoading}
                    selection
                    width={4}
                />

                <Form.Field
                    label={t('routine')}
                    control={Dropdown}
                    value={selectedDocumentId}
                    onChange={(_: Event, d: DropdownProps) =>
                        setSelectedDocumentId(d.value?.toString())
                    }
                    loading={loading}
                    options={templateOptions ?? []}
                    selection
                    search
                    width={10}
                />

                <Form.Field
                    label="&nbsp;"
                    disabled={!selectedDocumentId}
                    control={Button}
                    type="button"
                    primary
                    content={t('copy')}
                    width={2}
                    floated="right"
                    onClick={onCopyClick}
                />
            </Form.Group>
        </Form>
    )
}

export default CopyFromSelectionForm
