import React from 'react'
import { TFunction } from 'i18next'
import moment from 'moment'
import { Icon, Label, Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import { SummaryDataItem, getTabOrder } from '../../actions/startpage'
import { getModule } from '../../auth/modules'
import TabPaneContents from './tabPaneContents'
import { RowInfoFunction } from './rowInfo'

export const ModuleIconImage = styled.img`
    padding-right: 10px;
    padding-left: -8px;
`

export const ModuleMenuItem = styled(Menu.Item)`
    padding-left: 5px !important;

    .label {
        margin-left: 2px !important;
    }

    @media (max-width: 1250px) {
        .text {
            display: none;
        }
    }

    @media (max-width: 780px) {
        padding-right: 0 !important;
    }

    @media (max-width: 570px) {
        .label {
            padding: 2px 5px !important;
        }
    }
`

export const StyledSematicIcon = styled(Icon)`
    float: none !important;
    margin: 0 10px 0 0 !important;
    font-size: 20px !important;
`

const getCount = (data: SummaryDataItem[]) => data.reduce((acc, cur) => acc + cur.rows.length, 0)

const getExpiredCount = (moduleId: number, data: SummaryDataItem[], getRowInfo: RowInfoFunction) =>
    data.reduce(
        (acc, cur) =>
            acc +
            cur.rows.reduce(
                (rowAcc, row) =>
                    rowAcc +
                    ((getRowInfo(cur.subTitleKey, moduleId, row)?.isNegative?.(row) ?? false) ||
                    (row.doneBefore && moment(row.doneBefore) < moment())
                        ? 1
                        : 0),
                0,
            ),
        0,
    )

export type ModuleData = {
    [id: number]: SummaryDataItem[]
}

export const buildPanes = (
    moduleData: ModuleData,
    t: TFunction,
    getRowInfo: RowInfoFunction,
    enableReminders: boolean,
) =>
    getTabOrder()
        .filter(moduleId => moduleData[moduleId])
        .map(moduleId => {
            const data = moduleData[moduleId]
            const moduleInfo = getModule(moduleId, t)

            const rowCount = getCount(data)
            const expiredCount = getExpiredCount(moduleId, data, getRowInfo)
            const nonExpiredCount = rowCount - expiredCount

            if (rowCount === 0) return undefined

            return {
                key: moduleInfo!.key,
                menuItem: (
                    <ModuleMenuItem key={moduleId}>
                        <ModuleIconImage src={`/images/moduleicons/${moduleInfo?.imageDark}`} />
                        <span className="text">{moduleInfo?.text}</span>
                        {nonExpiredCount > 0 ? (
                            <Label color="black">{nonExpiredCount}</Label>
                        ) : null}
                        {expiredCount > 0 ? <Label color="red">{expiredCount}</Label> : null}
                    </ModuleMenuItem>
                ),
                render: () => (
                    <TabPaneContents
                        moduleInfo={moduleInfo!}
                        moduleId={moduleId}
                        data={data}
                        enableReminders={enableReminders}
                    />
                ),
            }
        })
        .filter(x => x !== undefined)
