import React, { useMemo, useState } from 'react'
import { Button, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Edit, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import { IKeyFigureDto, ISupplierResponse } from '../../../../../api/suppliers'
import { useDeleteKeyFigure } from './useDeleteKeyFigure'
import KeyFigureForm from '../KeyFigureForm'

interface IKeyFigureListProps {
    selectedSupplier: ISupplierResponse
    hasSupplierConcernModule: boolean
}
const KeyFigureList = ({ selectedSupplier, hasSupplierConcernModule }: IKeyFigureListProps) => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.suppliers.getKeyFigures, "keyFigureList", selectedSupplier.id)

    const [showAddModal, setShowAddModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [selecteKeyFigure, setSelecteKeyFigure] = useState<IKeyFigureDto | null>(null);
    const { isDestroying, onDestroy } = useDeleteKeyFigure(refresh)
    const onCancel = () => {
        setOpen(false)
        setSelecteKeyFigure(null)
    }

    const handleSetSelecteKeyFigure = (keyFigure: IKeyFigureDto) => {
        setOpen(true)
        setSelecteKeyFigure(keyFigure)
    }

    const columnHelper = createColumnHelper<IKeyFigureDto>()
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: hasSupplierConcernModule ? 45 : 95,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' disabled={isDestroying} onClick={() => handleSetSelecteKeyFigure(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        {!hasSupplierConcernModule && <Button title={t('delete')} disabled={isDestroying} onClick={() => onDestroy({
                            confirmText: t('confirm.deleteValue', { value: row.original.keyFigure }),
                            id: row.original.id
                        })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}

                    </>
            }),
            columnHelper.accessor("keyFigure", {
                id: "keyFigure",
                header: t('keyFigure') as any,
            }),
            columnHelper.accessor("value", {
                id: "value",
                header: t('value') as any,
            }),
            columnHelper.accessor("unit", {
                id: "unit",
                header: t('unit') as any,
            }),
            columnHelper.accessor("year", {
                id: "year",
                header: t('year') as any,
            }),

        ], [columnHelper, t, isDestroying, onDestroy, hasSupplierConcernModule])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Message info>
                {t('supplierKeyFigureInfo')}
            </Message>

            <TableReact table={table}
                onAddCallback={hasSupplierConcernModule ? undefined : () => setShowAddModal(true)}
                canExport={false}
                overflowable />

            <ModalWrapper title={t('addValue', { value: t('responsible&safety').toLowerCase() })} open={showAddModal} onClose={() => setShowAddModal(false)} size='small'>
                <KeyFigureForm onCancel={() => setShowAddModal(false)} refresh={refresh} isNew supplierId={selectedSupplier.id} />
            </ModalWrapper>

            {selecteKeyFigure && <ModalWrapper title={t('editValue', { value: selecteKeyFigure.keyFigure })} open={open} onClose={onCancel} size='small'>
                <KeyFigureForm onCancel={onCancel} refresh={refresh} isNew={false} supplierId={selectedSupplier.id} selectedKeyFigure={selecteKeyFigure} />
            </ModalWrapper>}

        </FullHeightStackingContainer>
    )
}

export default KeyFigureList
