import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupplierResponse } from '../../../api/suppliers';
import { Container, Tab, TabPane } from 'semantic-ui-react';
import SupplierContactInformationForm from './SupplierContactInformationForm';
import SupplierDeviationList from './SupplierDeviationList';
import KeyFigureList from './KeyFigure/KeyFigureList';
import ActionPlanList from './ActionPlan/ActionPlanList';
import InternalAssessmentList from './InternalAssessment/InternalAssessmentList';
import ExternalAssessmentList from './ExternalAssessmentList';
import SupplierFiles from './SupplierFiles';
import { useIsInRole } from '../../../hooks/role';
import { AdminRoles } from '../../../auth/roles';

interface ISupplierFormProps {
    isNew: boolean
    refresh: () => void
    selectedSupplier?: ISupplierResponse,
    hasSupplierConcernModule: boolean
    hasDeviationModule: boolean

}

const SupplierForm = ({ refresh, isNew, selectedSupplier, hasSupplierConcernModule, hasDeviationModule }: ISupplierFormProps) => {
    const { t } = useTranslation()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.SupplierAdminRole)
    const showTab = !isNew && selectedSupplier && isAdmin
    const panes = useMemo(() => [
        { menuItem: t("contactInformation"), render: () => <TabPane><SupplierContactInformationForm hasSupplierConcernModule={hasSupplierConcernModule} isNew={isNew} refresh={refresh} selectedSupplier={selectedSupplier} /></TabPane> },
        showTab ? { menuItem: t("internalFiles"), render: () => <TabPane><SupplierFiles hasSupplierConcernModule={hasSupplierConcernModule} fileCollection="supplier-internal" selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("externalFiles"), render: () => <TabPane><SupplierFiles hasSupplierConcernModule={hasSupplierConcernModule} fileCollection='supplier-external' selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("keyFigure"), render: () => <TabPane><KeyFigureList hasSupplierConcernModule={hasSupplierConcernModule} selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("externalResponse"), render: () => <TabPane><ExternalAssessmentList hasSupplierConcernModule={hasSupplierConcernModule} selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab ? { menuItem: t("internalAssessment"), render: () => <TabPane><InternalAssessmentList hasSupplierConcernModule={hasSupplierConcernModule} selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab && !hasSupplierConcernModule ? { menuItem: t("actionPlan"), render: () => <TabPane><ActionPlanList selectedSupplier={selectedSupplier} /></TabPane> } : {},
        showTab && hasDeviationModule ? { menuItem: t("supplierDeviations"), render: () => <TabPane><SupplierDeviationList selectedSupplier={selectedSupplier} /></TabPane> } : {},
    ], [t, refresh, selectedSupplier, isNew, hasSupplierConcernModule, showTab, hasDeviationModule])

    return <Container><Tab panes={panes} /></Container>
};

export default SupplierForm;
