import React, { FunctionComponent, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    ModalActions,
    ModalContent,
    ModalHeader,
    Dropdown,
    DropdownProps,
} from 'semantic-ui-react'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal'
import { useLawListing } from '../../../../../hooks/laws/listing'
import { Save, ArrowLeftCircle } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'

export interface LawConnectModalProps {
    trigger: ReactNode
    onLawChosen?(lawId: number): void
}

const LawConnectModal: FunctionComponent<LawConnectModalProps> = ({ trigger, onLawChosen }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState<
        string | number | boolean | (string | number | boolean)[] | undefined
    >()
    const { data, isValidating } = useLawListing()
    const handleChange = (
        e: React.SyntheticEvent<HTMLElement, Event>,
        { value }: DropdownProps,
    ) => {
        setValue(value)
    }

    const options = data?.map(a => ({
        key: a.id,
        value: a.id,
        text: `${a.designation} |  ${a.name}`,
    }))

    return (
        <Modal
            onClose={() => {
                setOpen(false)
                setValue(undefined)
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
            size="small"
        >
            <ModalHeader>{t('addLawConnection')}</ModalHeader>
            <ModalContent>
                <Dropdown
                    fluid
                    scrolling
                    search
                    placeholder={t('connection')}
                    loading={isValidating}
                    options={options}
                    onChange={handleChange}
                    value={value}
                />
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!value}
                    onClick={() => {
                        onLawChosen && value && onLawChosen(+value)
                        setOpen(false)
                    }}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </Button>
                <Button onClick={() => setOpen(false)} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default LawConnectModal
