import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import {
    ConnectedRiskType,
    RiskItemDto,
    useConnectedRisks,
} from '../../../../../hooks/risks/useConnectedRisks'
import { TFunction } from 'i18next'
import DocumentSectionModule from '../DocumentSectionModule'
import ModuleIcon from '../../../../icons/ModuleIcon'
import { MODULES } from '../../../../../auth/modules'

export interface RisksTableProps {
    connectedId: number
    type: ConnectedRiskType
}

export const RisksTableSectionModule: FunctionComponent<RisksTableProps> = props => {
    const { t } = useTranslation()
    const { data, loading } = useConnectedRisks(props.connectedId, props.type)
    return data?.length ? (
        <DocumentSectionModule
            title={t('risksOppertunities')}
            icon={<ModuleIcon moduleId={MODULES.RISKS} dark={true} size={32} />}
            hideInPrint={!data.length}
        >
            <RisksTableDumb risks={data} loading={loading} />
        </DocumentSectionModule>
    ) : null
}

const RisksTable: FunctionComponent<RisksTableProps> = props => {
    const { data, loading } = useConnectedRisks(props.connectedId, props.type)
    return <RisksTableDumb risks={data} loading={loading} />
}

export interface RisksTableDumbProps {
    risks: RiskItemDto[]
    loading: boolean
}
export const RisksTableDumb: FunctionComponent<RisksTableDumbProps> = ({ risks }) => {
    const { t } = useTranslation()
    return (
        <Table style={{ marginBottom: 0 }} basic="very">
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>{t('activityProcessService')}</TableHeaderCell>
                    <TableHeaderCell>{t('risksOppertunity')}</TableHeaderCell>
                    <TableHeaderCell>{t('impact')}</TableHeaderCell>
                    <TableHeaderCell collapsing>{t('worth')}</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {risks.map((risk, index) => (
                    <RisksTableRow key={`${risk.id}${index}`} risk={risk} t={t} />
                ))}
            </TableBody>
        </Table>
    )
}

export interface RisksTableRowProps {
    risk: RiskItemDto
    t: TFunction
}
export const RisksTableRow: FunctionComponent<RisksTableRowProps> = ({ risk }) => {
    const { documentId, apt, impact, aspect, degree } = risk
    return (
        <TableRow>
            <TableCell>
                <a href={`/lagar/aspekter.aspx?rutinId=${documentId}`}>{apt}</a>
            </TableCell>
            <TableCell>{aspect}</TableCell>
            <TableCell>{impact}</TableCell>
            <TableCell>{degree}</TableCell>
        </TableRow>
    )
}

export default RisksTable
