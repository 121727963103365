import React from 'react'
import { Routes, Route } from 'react-router'
import DocumentViewPage from './viewPage'
import DocumentIndexPage from './indexPage'
import DocumentStructureAdminPage from './structureAdminPage'
import DocumentReadConfirmationAdminPage from './readConfirmationAdminPage'
import DocumentExternalDocumentsPage from './externalDocumentsPage'
import { isInPageRenderMode } from '../../../actions/util'
import ProcessMap from '../../start/process'
import { Segment } from 'semantic-ui-react'
import DocumentTemplatePage from './templatePage'
import { useMobileMode } from '../../../hooks/mobileMode'

const DocsModule: React.FunctionComponent = () => {
    const { isInMobileMode } = useMobileMode()

    return (
        <>
            {isInPageRenderMode() && !isInMobileMode && (
                <Segment>
                    <ProcessMap />
                </Segment>
            )}

            <Routes>
                <Route index element={<DocumentIndexPage />} />

                <Route
                    path={`admin/read-confirmation`}
                    element={<DocumentReadConfirmationAdminPage />}
                />

                <Route path={`admin/structure`} element={<DocumentStructureAdminPage />} />

                <Route path={`external`} element={<DocumentExternalDocumentsPage />} />

                <Route path={`template/:templateId`} element={<DocumentTemplatePage />} />
                <Route path={`template`} element={<DocumentTemplatePage />} />

                <Route path={`:documentId`} element={<DocumentViewPage />} />
            </Routes>
        </>
    )
}

export default DocsModule
