import React, { createContext, useContext, useState } from 'react'
import { isMobile } from 'mobile-device-detect'

interface MobileModeContextReturnType {
    isInMobileMode: boolean
    setMobileMode: (value: boolean) => void
}

export const MobileModeContext = createContext({})

export const MobileModeProvider: React.FunctionComponent = ({ children }) => {
    const [isInMobileMode, setMobileMode] = useState(isMobile)

    return (
        <MobileModeContext.Provider value={{ isInMobileMode, setMobileMode }}>
            {children}
        </MobileModeContext.Provider>
    )
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useMobileMode = () => useContext(MobileModeContext) as MobileModeContextReturnType
