import { IProjectActivityDto, IProjectRequest } from "../../../api/projects";
import { ModuleStatus } from "../../../api/types/Enum";

export const mockedProjectRequest: IProjectRequest = {
    description: "",
    goal: "",
    implementation: "",
    activitiesIsChecklist: false,
    useReview: false,
    reviewDate: null,
    reviewResponsibleId: null,
    reviewText: "",
    endedDate: null,
    endedBy: "",
    ownId: 0,
    status: ModuleStatus.Active,
    topic: "",
    responsibleId: null,
    departmentId: null,
    startDate: null,
    endDate: null,
    cost: null,
    time: null,
    extraFields: null,
    activities: [],
}

export const mockedProjectActivity: IProjectActivityDto = {
    id: "",
    topic: "",
    description: "",
    cost: null,
    time: null,
    doneBefore: null,
    dateDone: null,
    doneBy: "",
    status: 1,
    responsibleId: "",
    sendAsAppointment: false
}
