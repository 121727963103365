import React, { FunctionComponent } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const FileDropTargetBox = styled.div<{ $isOver: boolean; $canDrop: boolean }>`
    display: ${props => (props.$canDrop ? 'flex' : 'none')};
    background-color: ${props => (props.$isOver ? '#EEEEEE' : '#DDDDDD')};
    opacity: 0.9;
    width: 100%;
    height: 100%;
    z-index: 100;
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
`

export interface FileDropTargetProps {
    onFileDropped: (item: any[]) => void
}

const FileDropTarget: FunctionComponent<FileDropTargetProps> = ({ onFileDropped }) => {
    const { t } = useTranslation()

    const [{ canDrop, isOver }, dragRef] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            onFileDropped(
                (((monitor.getItem() as any)?.files as File[]) ?? []).filter(f => f.size > 0),
            )
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    return (
        <FileDropTargetBox $canDrop={canDrop} $isOver={isOver} ref={dragRef}>
            {t('dropFileHere')}
        </FileDropTargetBox>
    )
}

export default FileDropTarget
