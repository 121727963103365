import fileDownload from 'js-file-download'
import { Action } from '../../../../reducer'
import { getAuthedAxios } from '../../../../auth'
import { handleAxiosError } from '../../../../actions/error'
import { Column, Row } from '@tanstack/react-table'

export enum ExportReportType {
    excel = 0,
    pdf = 1
}

interface RowValue {
    values: any[];
    subRows: RowValue[];
}

function processSubRows(subRows: Row<any>[], columns: Column<any, unknown>[]): RowValue[] {
    return subRows.map((subRow: Row<any>) => {
        const subRowValues = columns.map(c => subRow.getValue(c.id) || "");
        let nestedSubRows: RowValue[] = [];

        if (subRow.subRows && subRow.subRows.length > 0) {
            nestedSubRows = processSubRows(subRow.subRows, columns);
        }

        return { values: subRowValues, subRows: nestedSubRows };
    });
}

const generateReportRows = (rows: Row<any>[], columns: Column<any, unknown>[]) => {
    const isGrouped = columns.some(column => column.getIsGrouped());
    const slicedColumns = columns.slice(0, columns.length - 1);
    const headers = slicedColumns.map(c => c.columnDef.header);

    if (isGrouped) {
        const rowValues = rows.map(row => {
            const values = Array(columns.length - 1).fill("");
            values[0] = row.groupingValue;

            const subRows = processSubRows(row.subRows, slicedColumns);

            return { values, subRows };
        });

        return {
            headers,
            rowValues
        };
    } else {
        const rowValues = rows.map(row => {
            const values = slicedColumns.map(c => row.getValue(c.id) || "");
            return { values };
        });

        return {
            headers,
            rowValues
        };
    }
}

export const generateReport =
    (dispatch: React.Dispatch<Action>) =>
        (
            reportType: ExportReportType,
            fileName: string,
            rows: Row<any>[],
            trackedColumns: Column<any, unknown>[],
        ) => {
            const reportRows = generateReportRows(rows, trackedColumns)
            return getAuthedAxios().then(axios =>
                axios
                    .post(
                        `${window.env.REACT_APP_REPORT_SERVICE}/api/Report`,
                        {
                            name: fileName,
                            type: reportType,
                            columns: reportRows.headers,
                            rows: reportRows.rowValues,
                        },
                        {
                            responseType: 'blob',
                        },
                    )
                    .then(response => {
                        const contentDisposition = response.headers['content-disposition'] as string
                        const fileName = contentDisposition
                            .split(';')
                            .filter(x => x.indexOf('filename') !== -1)[0]
                            .split('=')[1]

                        fileDownload(response.data, fileName)
                    })
                    .catch(handleAxiosError(dispatch)),
            )
        }
