import React from 'react'
import { Routes, Route } from 'react-router'

const RisksModule: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path={'/'} element={<h1>RisksModule</h1>} />
        </Routes>
    )
}

export default RisksModule
