import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Table,
    TableBody,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import {
    DocumentLawConnection,
    useLawDocumentConnection,
} from '../../../../../hooks/laws/documentConnection'
import LawsTableRow from './LawsTableRow'
import LawInfoBox from './LawInfoBox'
import LawConnectModal from './LawConnectModal'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Plus } from 'react-feather'
import ModuleIcon from '../../../../icons/ModuleIcon'
import DocumentSectionModule from '../DocumentSectionModule'
import { MODULES } from '../../../../../auth/modules'

export interface LawListItem {
    id: string
    designation: string
    name: string
}
export interface LawRowItem {
    id: string
    designation: string
    name: string
}
export interface LawsTableProps {
    documentId: number
    isAdmin: boolean
}
const LawsTable: FunctionComponent<LawsTableProps> = props => {
    const { t } = useTranslation()
    const { connectedLaws, loading, createConnection, removeConnection } = useLawDocumentConnection(
        props.documentId,
    )

    if (!props.isAdmin && !connectedLaws?.length) return null

    return (
        <DocumentSectionModule
            title={t('lawRequirement')}
            icon={<ModuleIcon moduleId={MODULES.LAWS} dark={true} size={32} />}
            hideInPrint={!connectedLaws.length}
            attachTop={props.isAdmin}
        >
            <LawsTableDumb
                lawConnections={connectedLaws || []}
                loadingLaws={loading}
                isAdmin={props.isAdmin}
                deleteConnection={removeConnection}
                createConnection={createConnection}
            />
        </DocumentSectionModule>
    )
}

export interface LawsTableDumbProps {
    lawConnections: DocumentLawConnection[]
    loadingLaws: boolean
    isAdmin: boolean
    deleteConnection?(id: number): void
    createConnection?(id: number): void
}
export const LawsTableDumb: FunctionComponent<LawsTableDumbProps> = ({
    lawConnections,
    isAdmin,
    deleteConnection,
    createConnection,
}) => {
    const { t } = useTranslation()
    const [selectedLaw, setSelectedLaw] = useState<DocumentLawConnection>()
    return (
        <>
            <LawInfoBox lawConnection={selectedLaw} onClose={() => setSelectedLaw(undefined)} />
            <Table style={{ marginBottom: 0 }} basic="very">
                <TableHeader>
                    {isAdmin && (
                        <TableRow>
                            <TableHeaderCell />
                            <TableHeaderCell colSpan="4" textAlign="right">
                                <LawConnectModal
                                    onLawChosen={createConnection}
                                    trigger={
                                        <Button icon labelPosition="left" primary type="button">
                                            <SemanticFeatherIcon
                                                FeatherIcon={Plus}
                                                size="60%"
                                                centerIcon={true}
                                            />{' '}
                                            {t('new')}
                                        </Button>
                                    }
                                />
                            </TableHeaderCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell>{t('designation')}</TableHeaderCell>
                        <TableHeaderCell>{t('name')}</TableHeaderCell>
                        <TableHeaderCell collapsing className="hide-in-print">
                            {isAdmin && <> {t('removeConnection')}</>}
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {lawConnections.map(lawConnection => (
                        <LawsTableRow
                            onInfoClicked={() =>
                                selectedLaw?.id === lawConnection.id
                                    ? setSelectedLaw(undefined)
                                    : setSelectedLaw(lawConnection)
                            }
                            isInfoOpen={selectedLaw?.id === lawConnection.id}
                            t={t}
                            key={`${lawConnection.id}`}
                            law={lawConnection}
                            isAdmin={isAdmin}
                            onClickRemoveConnection={() =>
                                deleteConnection && deleteConnection(lawConnection.id)
                            }
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default LawsTable
