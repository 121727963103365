import { Action } from '../reducer'
import { getAuthedAxios } from '../auth'
import { handleAxiosError } from './error'

const globalCompanyInfoStartupAction = (dispatch: React.Dispatch<Action>) =>
    getAuthedAxios().then(axios =>
        Promise.all([
            axios
                .get(window.env.REACT_APP_COMPANY_SERVICE + '/api/Department/my')
                .then(
                    x => dispatch({ type: 'departmentsLoaded', departments: x.data }),
                    handleAxiosError(dispatch),
                ),

            axios
                .get(`${window.env.REACT_APP_COMPANY_SERVICE}/api/Location`)
                .then(
                    x => dispatch({ type: 'locationsLoaded', locations: x.data }),
                    handleAxiosError(dispatch),
                ),

            axios.get(window.env.REACT_APP_COMPANY_SERVICE + '/api/Company/my').then(
                x =>
                    dispatch({
                        type: 'companyLoaded',
                        company: x.data.company,
                        concern: x.data.concern,
                        hasSubCompanies: x.data.hasSubCompanies,
                    }),
                handleAxiosError(dispatch),
            ),

            axios.get(window.env.REACT_APP_COMPANY_SERVICE + '/api/Company/my/logo').then(
                x => dispatch({ type: 'companyLogoPathLoaded', path: x.data.path }),
                () => { },
            ), //Ignore errors if there are no logo

            axios
                .get(window.env.REACT_APP_AUTH_SERVICE + '/api/account/Info/users')
                .then(
                    x => dispatch({ type: 'usersLoaded', users: x.data }),
                    handleAxiosError(dispatch),
                ),

            axios
                .get(`${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/simple`)
                .then(
                    x => dispatch({ type: 'suppliersLoaded', suppliers: x.data }),
                    handleAxiosError(dispatch),
                ),
        ]),
    )

export default globalCompanyInfoStartupAction
