import { t, TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, Edit, Plus, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { useNavigate, useParams } from 'react-router'
import {
    Button,
    Dimmer,
    Divider,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Header,
    Loader,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import { SystematicTaskDto } from '../../hooks/systematicTasks/forDocument'
import {
    MobileSystematicTaskDto,
    useMobileSystematicTask,
    useMobileSystematicTasks,
} from '../../hooks/systematicTasks/mobileSystematicTasks'
import useApi from '../../hooks/useApi'
import SaveCancelPrintButtonGroup from '../common/saveCancelPrintButtonGroup'
import { UserInRoleDropdown } from '../common/userDropdown'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import EditFieldModal from './EditFieldModal'
import EditDocument from '../modules/docs/viewPage/Edit/editDocument'

export enum MobileSystematicTaskFieldDataType {
    String = 0,
    Number = 1,
    Date = 2,
    MultipleUserDropdown = 3,
    CheckboxWithComment = 4,
}

export interface FieldDataItem {
    id?: number
    order: number
    type: MobileSystematicTaskFieldDataType
    required: boolean
    label: string
    value?: string | number | boolean | string[] | number[] | Date
    checked?: boolean
}

export interface FieldTypeOption {
    key: MobileSystematicTaskFieldDataType
    value: MobileSystematicTaskFieldDataType
    text: string
}

const getFieldTypeOptions = (t: TFunction): FieldTypeOption[] => [
    {
        key: MobileSystematicTaskFieldDataType.String,
        value: MobileSystematicTaskFieldDataType.String,
        text: t('string'),
    },
    {
        key: MobileSystematicTaskFieldDataType.Number,
        value: MobileSystematicTaskFieldDataType.Number,
        text: t('number'),
    },
    {
        key: MobileSystematicTaskFieldDataType.Date,
        value: MobileSystematicTaskFieldDataType.Date,
        text: t('date'),
    },

    {
        key: MobileSystematicTaskFieldDataType.MultipleUserDropdown,
        value: MobileSystematicTaskFieldDataType.MultipleUserDropdown,
        text: t('userDropdown'),
    },
    {
        key: MobileSystematicTaskFieldDataType.CheckboxWithComment,
        value: MobileSystematicTaskFieldDataType.CheckboxWithComment,
        text: t('checkboxWithComment'),
    },
]

interface FieldTableProps {
    fields: FieldDataItem[]
    onFieldsChange: (fields: FieldDataItem[]) => void
}

const FieldsTable = ({ fields, onFieldsChange }: FieldTableProps) => {
    const [editField, setEditField] = useState<FieldDataItem | null>(null)
    const [isEditFieldOpen, setIsEditFieldOpen] = useState(false)

    const { t } = useTranslation()

    const fieldTypeOptions = getFieldTypeOptions(t)

    const onAddFieldClick = () => {
        setEditField(null)
        setIsEditFieldOpen(true)
    }
    const onEditClick = (field: FieldDataItem) => {
        setEditField(field)
        setIsEditFieldOpen(true)
    }

    const onDeleteClick = (field: FieldDataItem) => {
        const index = fields.findIndex(x => x.id === field.id)
        if (index === -1) {
            return
        }

        const newFields = [...fields]
        newFields.splice(index, 1)

        newFields.forEach((x, i) => (x.order = i))

        onFieldsChange(newFields)
    }
    const onReorderClick = (field: FieldDataItem, direction: number) => {
        const index = fields.findIndex(x => x.id === field.id)
        if (index === -1) {
            return
        }

        const newFields = [...fields]
        const newIndex = index + direction
        if (newIndex < 0 || newIndex >= newFields.length) {
            return
        }

        const temp = newFields[index]
        newFields[index] = newFields[newIndex]
        newFields[newIndex] = temp

        newFields.forEach((x, i) => (x.order = i))

        onFieldsChange(newFields)
    }

    const onEditFieldSave = (field: FieldDataItem) => {
        const index = fields.findIndex(x => x.id === field.id)

        let newFields = [...fields]

        if (index === -1) {
            field.id = Math.max(...fields.map(x => x.id || 0), 0) + 1
            newFields = [...fields, field]
        } else {
            newFields[index] = field
        }

        newFields.forEach((x, i) => (x.order = i))

        onFieldsChange(newFields)
    }

    return (
        <>
            <EditFieldModal
                open={isEditFieldOpen}
                field={editField}
                onClose={() => setIsEditFieldOpen(false)}
                onSave={onEditFieldSave}
                fieldTypeOptions={fieldTypeOptions}
            />

            <Button
                onClick={() => onAddFieldClick()}
                icon
                labelPosition="left"
                primary
                type="button"
            >
                <SemanticFeatherIcon FeatherIcon={Plus} size="60%" centerIcon={true} />{' '}
                {t('addField')}
            </Button>
            <Table style={{ marginBottom: 0 }} basic="very">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell />
                        <TableHeaderCell>{t('fieldName')}</TableHeaderCell>
                        <TableHeaderCell>{t('fieldType')}</TableHeaderCell>
                        <TableHeaderCell>{t('required')}</TableHeaderCell>
                        <TableHeaderCell />

                        <TableHeaderCell />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {fields?.map(field => (
                        <TableRow key={field.id}>
                            <TableCell>
                                <Button
                                    size="small"
                                    type="button"
                                    icon={<SemanticFeatherIcon FeatherIcon={ChevronUp} />}
                                    onClick={() => onReorderClick(field, -1)}
                                    disabled={field.order === 0}
                                />
                                <Button
                                    size="small"
                                    type="button"
                                    icon={<SemanticFeatherIcon FeatherIcon={ChevronDown} />}
                                    onClick={() => onReorderClick(field, 1)}
                                    disabled={field.order === fields.length - 1}
                                />
                            </TableCell>
                            <TableCell>{field.label}</TableCell>
                            <TableCell>
                                {fieldTypeOptions.find(f => f.key === field.type)?.text ?? ''}
                            </TableCell>
                            <TableCell>{field.required ? t('yes') : t('no')}</TableCell>

                            <TableCell>
                                <Button
                                    floated="right"
                                    size="small"
                                    type="button"
                                    icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                    onClick={() => onDeleteClick(field)}
                                />
                                <Button
                                    floated="right"
                                    size="small"
                                    type="button"
                                    icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                    onClick={() => onEditClick(field)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

const EditMobileSystematicTask = () => {
    const [responsibleId, setResponsibleId] = useState('')
    const [selectedSysTask, setSelectedSysTask] = useState(0)
    const [description, setDescription] = useState('')
    const [fields, setFields] = useState<FieldDataItem[]>([])

    const [sysTaskOptions, setSysTaskOptions] = useState<DropdownItemProps[]>([])

    const navigate = useNavigate()

    const { id } = useParams<{ id: string }>()

    const { createSystematicTask, mobileSystematicTasks } = useMobileSystematicTasks()

    const { task, updateSystematicTask } = useMobileSystematicTask(id ? parseInt(id) : 0)

    const { data: systematicTasks } = useApi<SystematicTaskDto[]>(
        `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/tasks`,
    )

    useEffect(() => {
        const existingSysTaskIds =
            mobileSystematicTasks?.map(mst => mst.sysId).filter(id => id !== task?.sysId) ?? []

        const sysTaskOptions =
            systematicTasks
                ?.map(st => ({
                    key: st.sysId,
                    text: st.topic,
                    value: st.sysId,
                }))
                .filter(
                    (value, index, options) =>
                        index === options.findIndex(t => t.key === value.key) &&
                        !existingSysTaskIds.includes(value.key),
                )
                .sort((a, b) => a.text.localeCompare(b.text, ['sv', 'en'])) ?? []

        setSysTaskOptions(sysTaskOptions)
    }, [systematicTasks, mobileSystematicTasks, task])

    useEffect(() => {
        if (id && task) {
            setFields(JSON.parse(task.fieldData))
            setResponsibleId(task.responsibleId)
            setSelectedSysTask(task.sysId)
            setDescription(task.description)
        }
    }, [id, task])

    const onSaveTask = async () => {
        const task: MobileSystematicTaskDto = {
            id: id ? parseInt(id) : 0,
            responsibleId: responsibleId,
            sysId: selectedSysTask,
            description,
            fieldData: JSON.stringify(fields),
        }

        id ? await updateSystematicTask(task) : await createSystematicTask(task)

        navigate(-1)
    }

    const isNotValid = !responsibleId || !selectedSysTask || !description || fields.length === 0

    return (
        <div style={{ width: '100%' }}>
            <Dimmer active={false}>
                <Loader />
            </Dimmer>

            <Header
                content={
                    id ? t('editMobileSystematicTasksHeader') : t('newMobileSystematicTasksHeader')
                }
            />

            <Form>
                <SaveCancelPrintButtonGroup
                    location="top"
                    onCancel={function (): void {
                        navigate(-1)
                    }}
                    disableSave={isNotValid}
                    onSave={onSaveTask}
                />

                <Form.Group widths="equal">
                    <Form.Field>
                        <label>{t('linkToSystematicTask')}</label>
                        <Dropdown
                            options={sysTaskOptions}
                            value={selectedSysTask}
                            onChange={(_, d) => setSelectedSysTask(d.value as number)}
                            selection
                        />
                    </Form.Field>

                    <Form.Field
                        required
                        label={t('responsibleForTemplate')}
                        control={UserInRoleDropdown}
                        role="sysA"
                        selection
                        search
                        value={responsibleId}
                        onChange={(_: Event, data: DropdownProps) =>
                            setResponsibleId(data.value as string)
                        }
                    />
                </Form.Group>

                <EditDocument value={description} onChange={text => setDescription(text)} />

                <Header content={t('fieldsForMobileForm')} />
                <Divider />

                <FieldsTable fields={fields} onFieldsChange={fields => setFields(fields)} />
            </Form>
        </div>
    )
}

export default EditMobileSystematicTask
