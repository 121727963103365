import { isArray, isEmpty } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR, { SWRConfiguration } from 'swr'
import { getObjectDifference } from './getObjectDifference'
import { defaultFetcher } from '../../SWRConfigurationProvider'
import { Method } from 'axios'
// T is the response type
// K is the request type which defaults to T
export function useCrud<T, K = T>(
    fetchUrl: string | undefined,
    collectionUrl: string,
    key: keyof T,
    fetchOptions?: SWRConfiguration,
    updateMethod?: Method,
) {
    const [loading, setIsLoading] = useState(true)
    const loadingTimeout = () => {
        setIsLoading(false)
    }
    const fetch = useCallback(async (url: string) => {
        const response: T[] = await defaultFetcher({ url })
        return response as T[]
    }, [])

    const { data, error, isValidating, mutate } = useSWR(fetchUrl, fetch, { ...fetchOptions })
    useEffect(() => {
        if (isValidating) {
            setIsLoading(true)
            return
        }
        setTimeout(loadingTimeout, 0)
    }, [isValidating])

    const create = useCallback(
        async (newObject: K, shouldRevalidate: boolean = false, forceRefetch: boolean = true) => {
            setIsLoading(true)
            const response = await defaultFetcher({
                url: collectionUrl,
                method: 'POST',
                data: newObject,
                responseType: 'json',
            }).finally(loadingTimeout)

            const result = response as T
            if (forceRefetch) {
                mutate?.()
            } else {
                if (data && mutate) {
                    let newData = data
                    if (isArray(data)) {
                        newData = data.concat(result)
                        await mutate(newData, shouldRevalidate)
                    }
                }
            }
            return result
        },
        [collectionUrl, data, mutate],
    )
    const createMultiple = useCallback(
        async (newObjects: K[], shouldRevalidate: boolean = false) => {
            setIsLoading(true)
            const response = await defaultFetcher({
                url: collectionUrl,
                method: 'POST',
                data: newObjects,
            }).finally(loadingTimeout)

            const result = response as T[]
            if (data && mutate) {
                await mutate([...data, ...result], shouldRevalidate)
            }
            return result
        },
        [collectionUrl, data, mutate],
    )
    const remove = useCallback(
        async (id: string | unknown, shouldRevalidate: boolean = false) => {
            setIsLoading(true)
            const response = await defaultFetcher({
                url: `${collectionUrl}/${id}`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'text/json',
                },
            }).finally(loadingTimeout)

            const result = response as T
            if (data && mutate) {
                if (isArray(result)) {
                    const updatedObjects = [...data].filter(current => {
                        const isDeleted = result.find(result => result[key] === current[key])
                        return !isDeleted
                    })
                    await mutate(result.length === 0 ? [] : updatedObjects, shouldRevalidate)
                } else {
                    const deletedIndex = data.findIndex(
                        (object: any) => object[key] === result[key],
                    )
                    if (deletedIndex >= 0) {
                        const updatedObjects = [...data]
                        updatedObjects.splice(deletedIndex, 1)
                        await mutate(updatedObjects, shouldRevalidate)
                    }
                }
            }
            return result
        },
        [collectionUrl, data, key, mutate],
    )
    const removeMultiple = useCallback(
        async (ids: number[], shouldRevalidate: boolean = false) => {
            setIsLoading(true)
            const response = await defaultFetcher({
                url: `${collectionUrl}?${ids.map(a => `id=${a}&`)}`,
                method: 'DELETE',
                data: ids,
            }).finally(loadingTimeout)

            const results = response as T[]
            if (data && mutate) {
                const updatedObjects = [...data].filter(current => {
                    const isDeleted = results.find(result => result[key] === current[key])
                    return !isDeleted
                })
                await mutate(updatedObjects, shouldRevalidate)
                return results
            }
        },
        [collectionUrl, data, key, mutate],
    )
    const update = useCallback(
        async (
            id: string | unknown,
            updatedObject: T,
            shouldRevalidate: boolean = false,
            forceRefetch: boolean = true,
        ): Promise<T> => {
            const currentObjectIndex = data.findIndex(
                (object: any) => object[key] === updatedObject[key],
            )
            const currentObject = data[currentObjectIndex]
            const diff = currentObject ? getObjectDifference(currentObject, updatedObject) : null

            if (!diff) {
                throw new Error('Update Failed')
            }

            if (isEmpty(diff)) {
                return currentObject
            }

            setIsLoading(true)
            const response = await defaultFetcher({
                url: `${collectionUrl}/${id}`,
                method: updateMethod ?? 'PUT',
                data: { ...diff, id: updatedObject[key] },
            }).finally(loadingTimeout)

            if (forceRefetch) {
                mutate?.()
            } else {
                if (data && mutate) {
                    const updatedObjects = [...data]
                    updatedObjects.splice(currentObjectIndex, 1, response)
                    await mutate(updatedObjects, shouldRevalidate)
                }
            }
            return response as T
        },
        [collectionUrl, data, mutate, key],
    )

    const updateMultiple = useCallback(
        async (ids: number[], updatedObjects: T[], shouldRevalidate = false): Promise<T[]> => {
            const currentObjects = data.filter((object: any) =>
                updatedObjects.find(updated => object[key] === updated[key]),
            )

            if (!currentObjects || currentObjects <= 0) {
                throw new Error('Update Failed')
            }

            const diffs = currentObjects.map((currentObject: any) => {
                const updatedObject = updatedObjects.find(
                    updated => updated[key] === currentObject[key],
                )
                return {
                    ...getObjectDifference(currentObject, updatedObject),
                    id: updatedObject && updatedObject[key],
                }
            })

            if (diffs.length <= 0) {
                return currentObjects
            }

            setIsLoading(true)
            const response = await defaultFetcher({
                url: `${collectionUrl}?${ids.map(a => `id=${a}&`)}`,
                method: updateMethod ?? 'PATCH',
                data: { ...diffs },
            }).finally(loadingTimeout)

            if (data && mutate) {
                const updatedObjects = [...data].map(current => {
                    if (current[key] === response[key]) {
                        return response
                    }
                    return current
                })
                await mutate(updatedObjects, shouldRevalidate)
            }
            return response as T[]
        },
        [collectionUrl, data, mutate, key],
    )

    const memoizedData = useMemo(() => (!isEmpty(data) ? data : []), [data])

    return {
        create,
        createMultiple,
        fetch: { data: memoizedData, error, loading, mutate },
        remove,
        removeMultiple,
        update,
        updateMultiple,
        loading,
    }
}
