import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import EditDocument, { EditDocumentProps } from '../viewPage/Edit/editDocument'

const OuterContainer = styled.div`
    position: relative;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: pink;
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(100, 100, 100, 0.8);
    color: white;
    font-weight: bold;
    font-size: 1.4em;
    text-shadow: 1px 1px 2px black;
`

export interface EditDocumentReadOnlyOverlayProps extends EditDocumentProps {
    initialReadOnly?: boolean
    onReadOnlyRemoved?: () => void
}

const EditDocumentReadOnlyOverlay: React.FunctionComponent<
    EditDocumentReadOnlyOverlayProps
> = props => {
    const { t } = useTranslation()
    const [readOnly, setReadOnly] = useState(props.initialReadOnly ?? true)

    const handleClick = () => {
        setReadOnly(false)
        props.onReadOnlyRemoved?.()
    }

    return (
        <OuterContainer>
            {readOnly && (
                <Overlay onClick={handleClick}>{t('editorIsReadOnlyClickToActivate')}</Overlay>
            )}
            <EditDocument {...props} readOnly={readOnly} />
        </OuterContainer>
    )
}

export default EditDocumentReadOnlyOverlay
