import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../../api'
import Loading from '../../../../../common/Loading'
import CompetenceUserForm from '../../../../../forms/CompetenceUserForm'

const EditUserFormPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userId } = useParams<{ userId: string }>()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(!!userId, API.skills.getCompetenceUser, "selectedCompetenceUser", userId)

    if (isLoading || !data) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{t('editValue', { value: data.name })}</Header>
            <CompetenceUserForm onCancel={() => navigate(-1)} refresh={refresh} user={data} />
        </Container>
    )
}

export default EditUserFormPage
