import React, { useMemo } from 'react'
import * as API from '../../../../../../api'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams'
import Loading from '../../../../../common/Loading'
import { useTranslation } from 'react-i18next'
import { buildEffeciencyTitleKey, transformToStatisticsYearMonthRow } from '../../../../../../utils/Mappings/Issues'
import { Grid, GridRow, Header, Message } from 'semantic-ui-react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ICostDeviation } from '../../../../../../api/issues'
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import TableReact, { FooterLabel } from '../../../../../wrappers/TableReact'
import CustomBarLabel from '../../CustomBarLabel'

interface IQualityCostStatisticProps {
    selectedYears: number[]
    selectedStats: number[]
    selectedMonths: number[]
}
const QualityCostStatistic = ({ selectedYears, selectedStats, selectedMonths }: IQualityCostStatisticProps) => {
    const { t } = useTranslation()
    const {
        isLoading: isLoadingCost,
        data: costData,
    } = useGetQueryByParams(selectedStats.includes(5), API.issues.postIssuesCostFilteredStats, "issuesCost", { selectedYears, selectedMonths })
    const hasMonth = selectedMonths.length

    const columnHelper = createColumnHelper<ICostDeviation>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            ...(
                hasMonth
                    ? [columnHelper.accessor('month', {
                        id: 'month',
                        header: t('month') as any,

                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('laborCost', {
                id: 'laborCost',
                header: t('laborCost') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.laborCost)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
            columnHelper.accessor('materialCost', {
                id: 'materialCost',
                header: t('materialCost') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.materialCost)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t, hasMonth])

    const employmentTable = useReactTable({
        data: costData || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoadingCost || !costData) {
        return <Loading />
    }

    const mappedData = {
        costBarData: transformToStatisticsYearMonthRow(
            costData ? costData : [],
            x => buildEffeciencyTitleKey(x, selectedYears, selectedMonths, t),
            x => ({
                materialCost: x.materialCost,
                laborCost: x.laborCost
            })
        ),
    };


    return (
        <>
            {(mappedData?.costBarData?.rows?.length ?? 0) > 0 ? (
                <>
                    <GridRow >
                        <Grid.Column>
                            <Header as='h3' textAlign='center'>{t('qualityDeviationCost')}</Header>
                        </Grid.Column>
                    </GridRow>
                    <GridRow >
                        <Grid.Column>
                            <Message info>
                                {t('qualityDeviationCostStatsInfo')}
                            </Message>
                        </Grid.Column>
                    </GridRow>
                    <GridRow >

                        <Grid.Column >
                            <TableReact table={employmentTable}
                                canExport
                                overflowable />
                        </Grid.Column>
                    </GridRow>
                    <GridRow >

                        <Grid.Column>
                            {(mappedData?.costBarData?.rows?.length ?? 0) > 0 ? (
                                <ResponsiveContainer height={500}
                                >
                                    <BarChart data={mappedData?.costBarData?.rows}>
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <CartesianGrid vertical={false} />
                                        <Legend layout="horizontal" />
                                        <Bar dataKey="materialCost" isAnimationActive={false} name={t('materialCost')} stackId="a" fill="#3377A1">
                                            <LabelList dataKey="materialCost" content={CustomBarLabel} />
                                        </Bar>
                                        <Bar dataKey="laborCost" isAnimationActive={false} name={t('laborCost')} stackId="a" fill="#FF33D1">
                                            <LabelList dataKey="laborCost" content={CustomBarLabel} />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            ) : null}
                        </Grid.Column>
                    </GridRow></>) : null}
        </>
    )
}

export default QualityCostStatistic