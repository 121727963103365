import React from 'react'
import { ArrowDown } from 'react-feather'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { isInPageRenderMode } from '../../../actions/util'
import { ProcessHierarchyItem } from '../../../hooks/docs/process'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import {
    GOVERNING_PROCESS_BACKGROUND_COLOR,
    MAIN_PROCESS_BACKGROUND_COLOR,
    SUPPORT_PROCESS_BACKGROUND_COLOR,
    TEXT_COLOR_NORMAL,
} from './colors'

const FlowItemContainer = styled.div<{
    backgroundColor: string
    isInherited: boolean
    canHaveArrow: boolean
}>`
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 5px;
    background-color: ${props => props.backgroundColor};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        margin: 10px;
    }

    @media (max-width: 800px) {
        font-size: 12px !important;

        a {
            font-size: 12px !important;
        }
    }

    &:last-child {
        margin-right: 0;
        ${props =>
            props.canHaveArrow
                ? `
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `
                : ''}
    }
`

const UnstyledLink = styled.a<{ isBold: boolean }>`
    font-weight: ${props => (props.isBold ? 'bold' : 'normal')} !important;
    color: ${TEXT_COLOR_NORMAL} !important;

    &:hover {
        color: black;
    }
`
const NavigtationModalButton = styled(Button)`
    font-weight: bold !important;
    background-color: #9bb2c4 !important;
`

export interface FlowItemProps {
    type: 'governing' | 'main' | 'support'
    item: ProcessHierarchyItem
    isInherited: boolean
    setProcess: (process: ProcessHierarchyItem) => void
}

export const getDocumentLinkStartFromStartpage = (documentId: number) =>
    isInPageRenderMode() ? `/docs/${documentId}` : `/alla/dokument.aspx#/${documentId}`

const FlowItem: React.FunctionComponent<FlowItemProps> = ({
    type,
    item,
    isInherited,
    setProcess,
}) => {
    const typeToColor = {
        governing: GOVERNING_PROCESS_BACKGROUND_COLOR,
        main: MAIN_PROCESS_BACKGROUND_COLOR,
        support: SUPPORT_PROCESS_BACKGROUND_COLOR,
    }

    return (
        <FlowItemContainer
            backgroundColor={typeToColor[type]}
            isInherited={isInherited}
            canHaveArrow={type === 'main'}
        >
            <UnstyledLink
                href={getDocumentLinkStartFromStartpage(item.item?.id ?? -1)}
                isBold={type === 'main'}
            >
                {item.item?.rubrik}
            </UnstyledLink>

            {item.children?.filter(c => c.item?.isPublished).length > 0 ? (
                <div style={{ paddingRight: '5px' }}>
                    <NavigtationModalButton
                        icon={<SemanticFeatherIcon FeatherIcon={ArrowDown} />}
                        floated="right"
                        onClick={() => setProcess({ ...item })}
                        compact
                        type="button"
                        size="small"
                    />
                </div>
            ) : null}
        </FlowItemContainer>
    )
}

export default FlowItem
