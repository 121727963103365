import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, List, Modal } from 'semantic-ui-react'
import { DetailedPosition } from '../../../../hooks/skills'

export interface PositionInfoModalProps {
    position?: DetailedPosition
}

const PositionInfoModal: React.FunctionComponent<PositionInfoModalProps> = ({ position }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        setIsOpen(!!position)
    }, [position])

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} closeIcon>
            <Modal.Header>{position?.name}</Modal.Header>
            <Modal.Content>
                <Header>{t('description')}</Header>
                <p dangerouslySetInnerHTML={{ __html: position?.description ?? '' }} />

                {!!position?.requiredSkills.length && (
                    <>
                        <Header sub>{t('requiredSkills')}</Header>
                        <List>
                            {position.requiredSkills.map(x => (
                                <List.Item key={x.id}>{x.name}</List.Item>
                            ))}
                        </List>
                    </>
                )}
            </Modal.Content>
        </Modal>
    )
}

export default PositionInfoModal
