import React from 'react'
import { useDrag } from 'react-dnd'
import styled from 'styled-components'
import { DropRowType } from '.'
import { Goal } from '../../../hooks/goals'

export interface GoalDragType {
    id: number
    parentId?: number
}

export interface DragableGoalRowProps {
    goal: Goal
    hasChildren: boolean
}

const RowGoal = styled.div<{ $canDrag: boolean; $inset: boolean }>`
    cursor: ${props => (props.$canDrag ? 'move' : 'not-allowed')};
    margin-left: ${props => (props.$inset ? '15px' : '0')};
`

const DragableGoalRow: React.FunctionComponent<DragableGoalRowProps> = ({ goal, hasChildren }) => {
    const [collectedProps, drag] = useDrag(() => ({
        type: DropRowType,
        item: { id: goal.id, parentId: goal.parentId },
        canDrag: !!goal.parentId || !hasChildren,
        collect: monitor => ({
            canDrag: !!monitor.canDrag(),
        }),
    }))

    return (
        <RowGoal ref={drag} $canDrag={collectedProps.canDrag} $inset={!!goal.parentId}>
            {goal.title}
        </RowGoal>
    )
}

export default DragableGoalRow
