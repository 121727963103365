import React from 'react'
import styled from 'styled-components'
import { BackgroundColor, LineColor, RowIndicatedBackgroundColor } from '../../styles'

const RatingScrollContainer = styled.div`
    width: calc(100vw - 2em);
    display: flex;
    justify-content: center;
`

const RatingContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    *:after {
        display: block;
    }
`

const RatingBall = styled.button<{ $isChecked: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    margin-bottom: 10px;
    border: 1px solid ${LineColor};
    border-radius: 100%;
    background-color: ${props => (props.$isChecked ? BackgroundColor : 'white')};
    color: ${props => (props.$isChecked ? 'white' : 'grey')};
    cursor: pointer;
    user-select: none;

    transition: background-color 0.1s ease-in;

    &:hover {
        background-color: ${props =>
            props.$isChecked ? BackgroundColor : RowIndicatedBackgroundColor};
    }
`

const Separator = styled.div`
    position: relative;
    top: calc(-1.5em + 1px);
    margin: 0 0.5em 10px 0.5em;
    border-bottom: 2px solid ${LineColor};
    width: 1.5em;
`

export interface CustomRatingProps {
    maxRating?: number
    minRating?: number
    rating?: number
    onChange?: (rating: number) => void
}

const CustomRating: React.FunctionComponent<CustomRatingProps> = ({
    minRating = 1,
    maxRating = 5,
    rating,
    onChange,
}) => {
    const numItems = maxRating - minRating + 1

    return (
        <RatingScrollContainer>
            <RatingContainer>
                {[...new Array(numItems)].map((_, i) => (
                    <React.Fragment key={i}>
                        <RatingBall
                            $isChecked={rating === minRating + i}
                            onClick={() => onChange?.(minRating + i)}
                        >
                            {minRating + i}
                        </RatingBall>
                        {i < numItems - 1 && <Separator />}
                    </React.Fragment>
                ))}
            </RatingContainer>
        </RatingScrollContainer>
    )
}

export default CustomRating
