import React from 'react'
import { ChemicalPictogram } from '../../hooks/chemicals'
import FlexBox from '../common/flexBox'

export interface PictogramSymbolsProps {
    pictogram?: ChemicalPictogram
    imageSize?: string
}

const PictogramSymbols: React.FunctionComponent<PictogramSymbolsProps> = ({
    pictogram,
    imageSize = '40px',
}) => {
    if (!pictogram) return null

    return (
        <FlexBox gap="5px">
            {pictogram?.toxic && (
                <img src="/images/pliktogram/giftig.png" alt="toxic" width={imageSize} />
            )}
            {pictogram?.corrosive && (
                <img src="/images/pliktogram/fratande.png" alt="corrosive" width={imageSize} />
            )}
            {pictogram?.healthRisk && (
                <img src="/images/pliktogram/halsofarlig.png" alt="healthRisk" width={imageSize} />
            )}
            {pictogram?.dangerous && (
                <img src="/images/pliktogram/skadlig.png" alt="dangerous" width={imageSize} />
            )}
            {pictogram?.environmentalRisk && (
                <img
                    src="/images/pliktogram/miljofarlig.png"
                    alt="environmentalRisk"
                    width={imageSize}
                />
            )}
            {pictogram?.explosive && (
                <img src="/images/pliktogram/explosiv.png" alt="explosive" width={imageSize} />
            )}
            {pictogram?.flammable && (
                <img src="/images/pliktogram/brandfarlig.png" alt="flammable" width={imageSize} />
            )}
            {pictogram?.oxidizing && (
                <img src="/images/pliktogram/oxiderande.png" alt="oxidizing" width={imageSize} />
            )}
            {pictogram?.gasUnderPressure && (
                <img
                    src="/images/pliktogram/gasUnderTryck.png"
                    alt="gasUnderPressure"
                    width={imageSize}
                />
            )}
        </FlexBox>
    )
}

export default PictogramSymbols
