import useSWR from 'swr'

export type LawListing = {
    id: number
    name: string
    designation: string
}

export const useLawListing = () =>
    useSWR<LawListing[], any>(`${window.env.REACT_APP_LAW_SERVICE}/api/laws`)
