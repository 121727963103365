import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SurveyPage from '../surveys/surveyPage'
import App from './app'
import AppLoader from './appLoader'
import AdminModule from '../admin'
import SupplierDeviationFormPage from '../modules/external/SupplierDeviationFormPage'

const Routing: React.FunctionComponent = () => (
    <Routes>
        <Route
            path="/surveys/do/:surveyGuid"
            element={<SurveyPage />}
        />
        <Route
            path="/external/supplier/:sId/deviations/:deviationId"
            element={<SupplierDeviationFormPage />}
        />
        <Route
            path="/admin/*"
            element={
                <AppLoader>
                    <AdminModule />
                </AppLoader>
            }
        />
        <Route
            path="*"
            element={
                <AppLoader>
                    <App />
                </AppLoader>
            }
        />
    </Routes>
)

export default Routing
