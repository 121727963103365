import React, { useState } from 'react';
import { Segment, Header, Form, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { IProjectTemplateDto } from '../../../api/projects';

export interface ApplyProjectTemplateBoxProps {
    templates?: IProjectTemplateDto[];
    onTemplateApplied(template?: IProjectTemplateDto): void;
}

const ApplyProjectTemplateBox: React.FunctionComponent<ApplyProjectTemplateBoxProps> = ({
    templates,
    onTemplateApplied
}) => {
    const { t } = useTranslation();
    const [selectedTemplate, setSelectedTemplate] = useState();

    if (!templates || !templates.length)
        return null;

    const templateOptions = templates.map(d => ({ key: d.id, text: d.name, value: d.id }));

    const onSubmit = () => {
        if (selectedTemplate) {
            const template = templates.find(t => t.id === selectedTemplate);
            onTemplateApplied(template);
        }
    }

    return (
        <Segment >
            <Header content={t('useProjectTemplate')}
                subheader={t('projectTemplateCanDo')} />

            <Form onSubmit={onSubmit}>
                <Form.Group inline>
                    <Form.Field label={t('template')}
                        inline
                        control={Dropdown}
                        options={templateOptions}
                        value={selectedTemplate}
                        onChange={(_: Event, data: any) => setSelectedTemplate(data.value)}
                        selection clearable />

                    <Form.Button primary
                        type='submit'
                        content={t('apply')} />
                </Form.Group>
            </Form>
        </Segment>
    );
}

export default ApplyProjectTemplateBox;