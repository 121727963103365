import { useEffect, useState } from 'react'
import { AxiosRequestConfig } from 'axios'
import { getAuthedAxios } from '../auth'
import { useSWRConfig } from 'swr'
import { getState } from '../state'
import { handleAxiosError } from '../actions/error'

export type UseApiOptions<T> = {
    defaultValue?: T
    mutateDataFetched?: (data: T) => T
    setDataFromPost?: boolean
    clearSwrCacheOnBackendMutate?: boolean
    dispatchErrors?: boolean
    suppressFetch?: boolean
}

function useApi<TFetch = any, TPost = TFetch>(
    url: string | undefined,
    options: UseApiOptions<TFetch> | undefined = undefined,
) {
    const { cache } = useSWRConfig()
    const { dispatch } = getState()
    const [data, setData] = useState<TFetch | undefined>(options?.defaultValue)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>()
    const [refetchCount, setRefetchCount] = useState(0)

    useEffect(() => {
        if (!url || options?.suppressFetch) return

        setLoading(true)

        getAuthedAxios().then(axios =>
            axios
                .get(url)
                .then(x => {
                    const data = options?.mutateDataFetched?.(x.data) ?? x.data
                    setData(data)
                    setError(null)
                })
                .catch(x => {
                    setError(x)
                    throw x
                })
                .finally(() => setLoading(false)),
        )
    }, [url, refetchCount])

    useEffect(() => {
        if (options?.dispatchErrors && error) handleAxiosError(dispatch)(error)
    }, [error])

    const post = <TReturn = TFetch, TPostInner = TPost>(
        body: TPostInner,
        overrideUrl?: string,
        overrideSetDataFromPost?: boolean,
        config?: AxiosRequestConfig<TPostInner>,
    ) => {
        setLoading(true)

        return getAuthedAxios().then(axios =>
            axios
                .post<TReturn>(overrideUrl ?? url!, body, config)
                .then(x => {
                    setError(null)

                    if (overrideSetDataFromPost ?? options?.setDataFromPost) mutate(x.data as any)
                    if (options?.clearSwrCacheOnBackendMutate) cache.delete(url)

                    return x.data
                })
                .catch(x => {
                    setError(x)
                    throw x
                })
                .finally(() => setLoading(false)),
        )
    }

    const doDelete = <TReturn = unknown>(overrideUrl?: string, setDataFromResponse?: boolean) => {
        setLoading(true)

        return getAuthedAxios().then(axios =>
            axios
                .delete<TReturn>(overrideUrl ?? url!)
                .then(x => {
                    setError(null)

                    if (setDataFromResponse) mutate(x.data as any)
                    if (options?.clearSwrCacheOnBackendMutate) cache.delete(url)

                    return x.data
                })
                .catch(x => {
                    setError(x)
                    throw x
                })
                .finally(() => setLoading(false)),
        )
    }

    const mutate = (x?: ((previousData?: TFetch) => TFetch | undefined) | TFetch) => {
        const externallyMutatedData =
            typeof x === 'function' ? (x as (previousData: TFetch | undefined) => TFetch)(data) : x

        if (externallyMutatedData)
            setData(options?.mutateDataFetched?.(externallyMutatedData) ?? externallyMutatedData)
        else setData(externallyMutatedData)
    }

    const triggerRefetch = () => setRefetchCount(x => x + 1)

    return { data, loading, error, post, doDelete, mutate, setLoading, triggerRefetch }
}

export default useApi
