import React, { useState } from 'react'
import { Grid, Header, Divider, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Spacer from '../../common/spacer'
import CompactGrid from '../../common/compactGrid'
import { FileListing, guessIconFromName } from '../../common/files'
import { getAuthedAxios } from '../../../auth'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import PrintLabelValue from '../../wrappers/PrintWrapper/PrintLabelValue'

export interface PrintViewProps {
    project: any
}

const ProjectPrintView: React.FunctionComponent<PrintViewProps> = ({ project }) => {
    const [files, setFiles] = useState<FileListing[]>([])

    getAuthedAxios().then(axios => {
        axios
            .get(`${window.env.REACT_APP_FILE_SERVICE}/api/File/Projects/${project.id}`)
            .then(x => setFiles(x.data))
    })

    return <ProjectPrintViewDumb project={project} files={files} />
}

const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    img {
        max-width: 200px;
        margin-right: 20px;
    }
`

export interface PrintViewDumbProps {
    project: any
    files: FileListing[]
}

export const ProjectPrintViewDumb: React.FunctionComponent<PrintViewDumbProps> = ({
    project,
    files,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <HeaderRow>
                <img src="images/viqma-system-logo-dark.png" alt="logo" />
                <Header content={t('project')} subheader={project.topic} style={{ margin: 0 }} />
            </HeaderRow>
            <CompactGrid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="vertical"
                            label={t('responsible')}
                            value={project.responsible}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="vertical"
                            label={t('department')}
                            value={project.department}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Spacer spacing="5px" />
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('projectStart')}
                            value={moment(project.startDate).format('Y-MM-DD')}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('projectEnd')}
                            value={moment(project.endDate).format('Y-MM-DD')}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('description')}
                            value={project.description}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('projectGoal')}
                            value={project.goal}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('implementation')}
                            value={project.implementation}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('cost')}
                            value={project.cost}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            orientation="horizontal"
                            label={t('time')}
                            value={project.time}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header size="medium" content={t('activity')} />
                        <Table compact unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('responsible')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('description')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('doneBefore')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {project.activities.map((a: any) => (
                                    <Table.Row key={a.id}>
                                        <Table.Cell>{a.responsible}</Table.Cell>
                                        <Table.Cell>{a.description}</Table.Cell>
                                        <Table.Cell>
                                            {moment(a.doneBefore).format('Y-MM-DD')}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header size="medium" content={t('files')} />
                        <Table compact unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('filename')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {files &&
                                    files.map((x: FileListing) => (
                                        <Table.Row key={x.id}>
                                            <Table.Cell>
                                                <SemanticFeatherIcon
                                                    FeatherIcon={guessIconFromName(x.name)}
                                                />
                                                {x.name}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </CompactGrid>
        </>
    )
}

export default ProjectPrintView
