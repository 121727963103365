import * as API from "../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../actions/error"
import { getState } from "../../../../../state"

interface IDestroyExtrafield {
    id: number | null
    key: string
    confirmText: string
}

export function useDeleteExtraField(refresh: () => void) {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const destroyUserSetting = async (data: IDestroyExtrafield) => {
        if (data.id !== null) {
            if (window.confirm(data.confirmText)) {
                return await API.issues.destroyExtraField(data.id, data.key)
            }
        }

    }

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(destroyUserSetting, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t('saved'))
                refresh()
            }
        },
    })

    return {
        isDestroying,
        onDestroy,
    }
}
