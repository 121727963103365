import { FilterFn } from '@tanstack/react-table';
import React from 'react';
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import FlexBox from '../../../../common/flexBox';
import { endOfDay, startOfDay } from 'date-fns';

const FilterInput = styled.input`
  width: 100%;
  height: 2.4375em;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  padding: 16.5px 14px;
  border-radius: 4px;
  border-style: inset;
  border: 1px solid hsl(210deg 3% 68% / 90%);
`

export const dateBetweenFilterFn: FilterFn<any> = (row, columnId, value) => {
    const date = row?.getValue(columnId) as string;
    const [start, end] = value;
    if ((start || end) && !date) return false;
    if (start && !end) {
        return new Date(date).getTime() >= new Date(start).getTime();
    } else if (!start && end) {
        return new Date(date).getTime() <= new Date(end).getTime();
    } else if (start && end) {
        return new Date(date).getTime() >= new Date(start).getTime() && new Date(date).getTime() <= new Date(end).getTime();
    } else return true;
};


interface IDateRangeFilterProps {
    setFilterValue: (updater: any) => void
    value: Date[]
}

export function DateRangeFilter({ setFilterValue, value }: IDateRangeFilterProps) {
    const { i18n, t } = useTranslation();
    const languageParts = i18n.language.split('-')
    const handleDateChange = (date: Date | null, isStartDate: boolean) => {
        let newStart = value && value[0] ? value[0] : null;
        let newEnd = value && value[1] ? value[1] : null;

        if (isStartDate) {
            newStart = date ? startOfDay(date) : date;
        } else {
            newEnd = date ? endOfDay(date) : date;
        }

        // Update the filter value
        setFilterValue([newStart, newEnd]);
    };

    return (
        <FlexBox direction='column' gap='4px'>
            <DatePicker
                selected={value && value[0] ? typeof value[0] === 'string' || value[0] instanceof String ? new Date(value[0]) : value[0] : null}
                onChange={(date) => handleDateChange(date, true)}
                isClearable
                dateFormat="yyyy-MM-dd"
                showYearDropdown
                customInput={<FilterInput />}
                autoComplete="off"
                locale={languageParts[0]}
                todayButton={t('today')}
            />
            <DatePicker
                selected={value && value[1] ? typeof value[1] === 'string' || value[1] instanceof String ? new Date(value[1]) : value[1] : null}
                onChange={(date) => handleDateChange(date, false)}
                isClearable
                dateFormat="yyyy-MM-dd"
                showYearDropdown
                customInput={<FilterInput />}
                autoComplete="off"
                locale={languageParts[0]}
                todayButton={t('today')}
            />
        </FlexBox>
    )
} 