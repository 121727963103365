import axios from 'axios'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { getState } from '../../state'

export interface AnswerSubmissionDto {
    questionId: number
    answer: number
    comment?: string
    weight?: number
}

export interface SurveySubmissionDto {
    name?: string
    email?: string
    company?: string
    departmentId?: number
    answers: AnswerSubmissionDto[]
}

export enum QuestionType {
    Grading = 0,
    YesNoDontKnow = 1,
    FreeText = 2,
    MultipleChoice = 3,
}

export interface SurveyQuestion {
    id: number
    type: QuestionType
    text: string
    addDontKnow: boolean
    addCommentBox: boolean
    addWeightingSelection: boolean
    comment: string
    choices?: { value: string; text: string }[]
}

export interface Survey {
    id: number
    imageUrl: string
    startText: string
    endText: string
    questions: SurveyQuestion[]
    maxRating: number
    yesText: string
    noText: string
    weightingText: string
    dontKnowText: string
    commentText: string
    companyText: string
    nameText: string
    emailText: string
    showDepartmentSelection?: boolean
    departments?: Record<number, string>
}

export enum SurveyLoadingState {
    Loading,
    Loaded,
}

export const useSurvey = (surveyGuid?: string) => {
    const [survey, setSurvey] = useState<Survey>()
    const [loadingState, setLoadingState] = useState<SurveyLoadingState>(SurveyLoadingState.Loading)
    const [error, setError] = useState()

    const { dispatch } = getState()

    useEffect(() => {
        const getSurvey = () =>
            new Promise<Survey>(resolve =>
                axios
                    .get(`${window.env.REACT_APP_SURVEY_SERVICE}/api/Survey/${surveyGuid}`)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(err => setError(err)),
            )

        if (!surveyGuid) return

        getSurvey().then(survey => {
            setSurvey(survey)
            setLoadingState(SurveyLoadingState.Loaded)
        })
    }, [surveyGuid, dispatch])

    const submitAnswers = (surveyGuid: string, answers: SurveySubmissionDto) =>
        axios
            .post(`${window.env.REACT_APP_SURVEY_SERVICE}/api/Survey/${surveyGuid}`, answers)
            .catch(err => setError(err))

    return {
        survey,
        error,
        loadingState,
        submitAnswers,
    }
}

export interface SurveyListing {
    id: number
    name: string
}

export const useSurveys = () =>
    useSWR<SurveyListing[]>(`${window.env.REACT_APP_SURVEY_SERVICE}/api/Survey`)
