import { TFunction } from "react-i18next";
import { IActivityDto, IIssueRequest, IIssueDto, IAccidentDto, AccidentWithCreationOptionsDto, IDeviationStatisticsResponse, IAccidentStatisticsResponse, IssueTypeHierarchy } from "../../../api/issues";
import { getLocationFromState } from "../../../state/helpers";
import { ApplicationState } from "../../../state/initial";

export const mapIssueToRequest = (issue: IIssueDto): IIssueRequest => ({
    type: issue.type,
    errorCode: issue.errorCode,
    causeCode: issue.causeCode,
    topic: issue.topic,
    departmentId: issue.departmentId,
    locationId: issue.locationId,
    responsibleId: issue.responsibleId,
    supplierId: issue.supplierId,
    customerSupplier: issue.customerSupplier,
    doneBefore: issue.doneBefore ? new Date(issue.doneBefore) : null,
    description: issue.description,
    actionsTaken: issue.actionsTaken,
    reportedBy: issue.reportedBy,
    reportDate: issue.reportDate ? new Date(issue.reportDate) : null,
    extraInformation1: issue.extraInformation1,
    extraInformation2: issue.extraInformation2,
    useReview: issue.useReview ?? true,
    reviewDate: issue.reviewDate ? new Date(issue.reviewDate) : null,
    reviewBeforeDate: issue.reviewBeforeDate ? new Date(issue.reviewBeforeDate) : null,
    reviewResponsibleId: issue.reviewResponsibleId,
    reviewText: issue.reviewText,
    endedDate: issue.endedDate ? new Date(issue.endedDate) : null,
    endedBy: issue.endedBy,
    analysis: issue.analysis,
    resolvingAction: issue.resolvingAction,
    time: issue.time,
    cost: issue.cost,
    feedback: false,
    sendEmailToResponsible: false,
    sendDeviationManagementEmail: false,
    sent: issue.sent ? new Date(issue.sent) : null,
    replied: issue.replied,
    supplierShouldReply: issue.supplierShouldReply,
    alternativeEmail: "",
    extraFields: issue.extraFields,
    teamMembers: issue.teamMembers,
    desiredEffect: issue.desiredEffect,
    desiredEffectComment: issue.desiredEffectComment,
    deviationMethod: issue.deviationMethod,
    sendEmailToTeamMembers: false,
    sendEightDReportToTeamMembers: false
});

export const mapIssueActivity = (activity: IActivityDto): IActivityDto => ({
    id: activity.id,
    activity: activity.activity,
    description: activity.description,
    responsibleId: activity.responsibleId,
    doneBefore: activity.doneBefore ? new Date(activity.doneBefore) : null,
    status: activity.status,
    doneDate: activity.doneDate ? new Date(activity.doneDate) : null,
    doneBy: activity.doneBy,
    closingNotifyResponsible: false,
    creationNotifyResponsible: false
})

export const mapAccidentToRequest = (accident: IAccidentDto): AccidentWithCreationOptionsDto => ({
    id: accident.id,
    status: accident.status,
    companyId: accident.companyId,
    ownId: accident.ownId,
    type: accident.type,
    isReported: accident.isReported,
    responsibleId: accident.responsibleId,
    safetyOfficerId: accident.safetyOfficerId,
    departmentId: accident.departmentId,
    reportedBy: accident.reportedBy,
    reportDate: accident.reportDate ? new Date(accident.reportDate) : null,
    extentOfOutcome: accident.extentOfOutcome,
    accidentDate: accident.accidentDate ? new Date(accident.accidentDate) : null,
    accidentTime: accident.accidentTime,
    accidentName: accident.accidentName,
    employmentType: accident.employmentType,
    employmentYear: accident.employmentYear,
    whatHappened: accident.whatHappened,
    location: accident.location,
    task: accident.task,
    factor: accident.factor,
    differencesFromNormal: accident.differencesFromNormal,
    conditionalEffects: accident.conditionalEffects,
    locationHadImpact: accident.locationHadImpact,
    equipmentHadImpact: accident.equipmentHadImpact,
    workEnvironmentHadImpact: accident.workEnvironmentHadImpact,
    preventiveActionsHadImpact: accident.preventiveActionsHadImpact,
    companyClimateHadImpact: accident.companyClimateHadImpact,
    educationHadImpact: accident.educationHadImpact,
    otherCausesHadImpact: accident.otherCausesHadImpact,
    estimatedAbsenceTime: accident.estimatedAbsenceTime,
    description: accident.description,
    actionsTaken: accident.actionsTaken,
    analysis: accident.analysis,
    resolvingAction: accident.resolvingAction,
    doneBefore: accident.doneBefore ? new Date(accident.doneBefore) : null,
    endedDate: accident.endedDate ? new Date(accident.endedDate) : null,
    reviewDate: accident.reviewDate ? new Date(accident.reviewDate) : null,
    reviewBeforeDate: accident.reviewBeforeDate ? new Date(accident.reviewBeforeDate) : null,
    useReview: accident.useReview,
    endedBy: accident.endedBy,
    reviewText: accident.reviewText,
    reviewResponsibleId: accident.reviewResponsibleId,
    projectId: accident.projectId,
    customerSupplier: accident.customerSupplier,
    locationId: accident.locationId,
    sendEmailToResponsible: false,
    sendEmailToSafetyOfficer: false,
    feedback: false,
    extraFields: accident.extraFields
})
export interface StatisticsRows {
    rows: any[]
    dataColumns: string[]
}
export const transformToStatisticsYearMonthRow = (
    data: any[],
    keyFunc: (item: any) => string,
    valueFunc: ((item: any) => any) | null,
): StatisticsRows => {
    const dataColumns = new Set<string>();
    const mergedObject = data.reduce((acc, cur) => {
        const key = keyFunc(cur);
        const value = valueFunc?.(cur) ?? { ...cur };

        const item = acc[key] ?? { name: key };
        Object.entries(value).forEach(([property, value]) => {
            dataColumns.add(property);
            item[property] = (item[property] ?? 0) + (value as number);
        });

        return {
            ...acc,
            [key]: item,
        };
    }, {});

    return { rows: Object.values(mergedObject), dataColumns: Array.from(dataColumns) };
};

export const buildIssueTitleKey = (cur: IDeviationStatisticsResponse,
    selectedYears: number[],
    selectedMonths: number[],
    t: TFunction,
) => {
    const keyParts: string[] = [];

    if (selectedYears.length) {
        keyParts.push(cur.year.toString());
    }
    if (selectedMonths.length && cur.month) {
        keyParts.push(`${cur.month}`);
    }
    return keyParts.join("-");
};

export const buildAccidentTitleKey = (cur: IAccidentStatisticsResponse,
    selectedYears: number[],
    selectedMonths: number[],
    selectedDepartments: number[],
    selectedLocations: number[],
    state: ApplicationState,
    t: TFunction,
) => {
    const keyParts: string[] = [];

    if (selectedYears.length) {
        keyParts.push(cur.year.toString());
    }
    if (selectedMonths.length && cur.month) {
        keyParts.push(`${cur.month}`);
    }
    if (selectedDepartments.length) {
        keyParts.push(cur.department);
    }
    if (selectedLocations.length) {
        const locationName = getLocationFromState(state)(cur.locationId)?.name ?? "";
        keyParts.push(locationName);
    }

    return keyParts.join("-");
};

export const buildIssueGroupTitleKey = (
    cur: IDeviationStatisticsResponse,
    types: IssueTypeHierarchy[],
    selectedYears: number[],
    selectedMonths: number[],
    selectedDepartments: number[],
    selectedLocations: number[],
    selectedTypes: number[],
    selectedErrorCodes: number[],
    selectedCauseCodes: number[],
    state: ApplicationState,
    t: TFunction,) => {
    const keyParts: string[] = [];

    if (selectedYears.length && !selectedMonths.length && !selectedDepartments.length && !selectedLocations.length && !selectedTypes.length) {
        keyParts.push(cur.year.toString());
    }

    if (selectedMonths.length && !selectedDepartments.length && !selectedLocations.length && !selectedTypes.length && cur.month) {
        keyParts.push(`${cur.month}`);
    }

    if (selectedDepartments.length && !selectedLocations.length && !selectedTypes.length) {
        keyParts.push(cur.department);
    }

    if (selectedLocations.length && !selectedTypes.length) {
        const locationName = getLocationFromState(state)(cur.locationId)?.name ?? "";
        keyParts.push(locationName);
    }
    if (selectedTypes.length) {
        keyParts.push(cur.reason);
    }
    if (selectedErrorCodes.length) {
        const findErrorCode = findItemById(types, cur.errorCode);
        keyParts.push(findErrorCode?.name ?? "");
    }
    if (selectedTypes.length && selectedErrorCodes.length && selectedCauseCodes) {
        const item = findItemById(types, cur.causeCode);
        keyParts.push(item?.name ?? "");
    }

    return keyParts.join("-");
};

const findItemById = (items: IssueTypeHierarchy[], id: number): IssueTypeHierarchy | null => {
    for (const item of items) {
        if (item.id === id) {
            return item;
        }
        if (item.subRows && item.subRows.length > 0) {
            const foundItem = findItemById(item.subRows, id);
            if (foundItem) {
                return foundItem;
            }
        }
    }
    return null;
};
export const buildEffeciencyTitleKey = (cur: IDeviationStatisticsResponse,
    selectedYears: number[],
    selectedMonths: number[],
    t: TFunction,
) => {
    const keyParts: string[] = [];

    if (selectedYears.length) {
        keyParts.push(cur.year.toString());
    }

    if (selectedMonths.length) {
        cur.month && keyParts.push(`${cur.month}`);
    }
    return keyParts.join("-");
};

export const mergeSubRows = (items: IssueTypeHierarchy[], selectedIds: number[]): IssueTypeHierarchy[] => {
    const selectedItems = items.filter(item => selectedIds.includes(item.id));
    const mergedSubRows: IssueTypeHierarchy[] = [];

    selectedItems.forEach(item => {
        if (item.subRows && item.subRows.length > 0) {
            mergedSubRows.push(...item.subRows);
        }
    });

    return mergedSubRows;
};
