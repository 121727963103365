import React from 'react'
import styled from 'styled-components'

const TextSpan = styled.span`
    display: block;
    white-space: pre-line;
    line-break: normal;
`

export interface TextDisplayProps extends React.HTMLAttributes<HTMLSpanElement> {
    text?: string
    maxLength?: number
}

const TextDisplay: React.FunctionComponent<TextDisplayProps> = ({ text, maxLength, ...rest }) => {
    text =
        maxLength && text && text?.length > maxLength
            ? `${text.substring(0, maxLength - 3)}...`
            : text

    return <TextSpan {...rest}>{text}</TextSpan>
}

export default TextDisplay
