import React, { useImperativeHandle, useRef } from 'react'
import { forwardRef } from 'react'
import {
    ConnectDragSource,
    ConnectDropTarget,
    DragSource,
    DragSourceConnector,
    DragSourceMonitor,
    DropTarget,
    DropTargetConnector,
    DropTargetMonitor,
} from 'react-dnd'
import { ChevronDown, ChevronRight, Edit, Plus, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Popup, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate } from '../../../../actions/util'
import { ProcessHierarchyItem } from '../../../../hooks/docs/process'
import { RowIndicatedBackgroundColor } from '../../../../styles'
import CustomConfirm from '../../../common/customConfirm'
import UserNameDisplay from '../../../common/UserNameDisplay'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import DocumentName from '../common/documentName'

const LevelAdjustedTableCell = styled(Table.Cell) <{
    $level: number
}>`
    ${props =>
        props.$level === 0
            ? ''
            : `
            padding-left: ${props.$level * 35}px !important;
        `}
`

export const RowDragItemType = 'document-row'

export interface RowDragObject {
    id: number
}

export interface DragableDocumentRowProps {
    level: number
    item: ProcessHierarchyItem
    isExpanded: boolean
    hasChildren: boolean
    onExpandChange: (isExpanded: boolean) => void
    onEdit: (item: ProcessHierarchyItem) => void
    onNew: (parentId: number) => void
    onMove: (targetId: number, movedId: number) => void
    onDelete: (documentId: number) => void
    isDragging: boolean
    isOver: boolean
    connectDragSource: ConnectDragSource
    connectDropTarget: ConnectDropTarget
}

interface RowInstance {
    getNode(): HTMLTableRowElement | null
}

const DragableDocumentRow = forwardRef<HTMLTableRowElement, DragableDocumentRowProps>(function Card(
    {
        level,
        item,
        isExpanded,
        hasChildren,
        onExpandChange,
        onEdit,
        onNew,
        onDelete,
        isDragging,
        isOver,
        connectDragSource,
        connectDropTarget,
    },
    ref,
) {
    const { t } = useTranslation()

    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)

    useImperativeHandle<any, RowInstance>(ref, () => ({
        getNode: () => elementRef.current,
    }))

    const opacity = isDragging ? 0 : 1
    const backgroundColor = isOver ? RowIndicatedBackgroundColor : 'inherit'

    return (
        <tr ref={elementRef} style={{ opacity, backgroundColor }}>
            <Table.Cell collapsing>
                {hasChildren && (
                    <Button
                        icon={
                            <SemanticFeatherIcon
                                FeatherIcon={isExpanded ? ChevronDown : ChevronRight}
                                style={{ marginTop: '-2px' }}
                            />
                        }
                        type="button"
                        onClick={() => onExpandChange(!isExpanded)}
                        size="tiny"
                        compact
                    />
                )}
            </Table.Cell>
            <LevelAdjustedTableCell $level={level}>
                <Link to={`../${item.item?.id}`}>
                    <DocumentName
                        name={item.item?.rubrik ?? ''}
                        isPublished={item.item?.isPublished ?? false}
                    />
                </Link>
            </LevelAdjustedTableCell>
            <Table.Cell collapsing textAlign="center">
                {item.item?.sortOrder && item.item.sortOrder !== 0 ? item.item.sortOrder : ''}
            </Table.Cell>
            <Table.Cell collapsing>{formatDate(item.item?.lastChanged)}</Table.Cell>
            <Table.Cell collapsing textAlign="center">
                {item.item?.version}
            </Table.Cell>
            <Table.Cell collapsing>
                <UserNameDisplay
                    userId={item.item?.approvedBy ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            </Table.Cell>
            <Table.Cell collapsing>
                {!isDragging && (
                    <>
                        <Button
                            size="mini"
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Plus}
                                    style={{ marginTop: '-2px', marginLeft: '-1px' }}
                                />
                            }
                            onClick={() => onNew(item.item?.id ?? -1)}
                            primary
                            type="button"
                        />
                        <Button
                            size="mini"
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Edit}
                                    style={{ marginTop: '-3px' }}
                                />
                            }
                            onClick={() => onEdit(item)}
                            type="button"
                        />

                        {hasChildren ? (
                            <Popup
                                content={t('cantDeleteRoutineWithChildren')}
                                trigger={
                                    <span>
                                        <Button
                                            size="mini"
                                            disabled={true}
                                            icon={
                                                <SemanticFeatherIcon
                                                    FeatherIcon={Trash2}
                                                    style={{
                                                        marginTop: '-3px',
                                                        marginLeft: '-1px',
                                                    }}
                                                />
                                            }
                                            negative
                                            type="button"
                                        />
                                    </span>
                                }
                            />
                        ) : (
                            <CustomConfirm
                                text={t('sureYouWantToDeleteDocument')}
                                trigger={
                                    <Button
                                        size="mini"
                                        icon={
                                            <SemanticFeatherIcon
                                                FeatherIcon={Trash2}
                                                style={{ marginTop: '-3px', marginLeft: '-1px' }}
                                            />
                                        }
                                        negative
                                        type="button"
                                    />
                                }
                                onConfirm={() => onDelete(item.item?.id ?? -1)}
                            />
                        )}
                    </>
                )}
            </Table.Cell>
        </tr>
    )
})

export default DropTarget(
    RowDragItemType,
    {
        canDrop: (
            props: DragableDocumentRowProps,
            monitor: DropTargetMonitor<RowDragObject, DragableDocumentRowProps>,
        ) => props.item.item?.id !== monitor.getItem().id,
        drop: (
            props: DragableDocumentRowProps,
            monitor: DropTargetMonitor<RowDragObject, DragableDocumentRowProps>,
        ) => {
            if (!props.item.item) return

            props.onMove(props.item.item.id, monitor.getItem().id)
            return undefined
        },
    },
    (connect: DropTargetConnector, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    }),
)(
    DragSource(
        RowDragItemType,
        {
            beginDrag: (props: DragableDocumentRowProps) => ({
                id: props.item.item?.id,
            }),
        },
        (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )(DragableDocumentRow),
)
