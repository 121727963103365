import React, { FunctionComponent } from 'react'
import { Icon, IconProps } from 'react-feather'
import styled from 'styled-components'

const StyledSemanticIcon = styled.i<{ centerIcon: boolean, fontSize: string, hasCross: boolean }>`
    vertical-align: top;
    ${props =>
        props.fontSize
            ? `
            font-size: ${props.fontSize}em !important;
        `
            : ''}
    ${props =>
        props.centerIcon
            ? `
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        `
            : ''}
    ${props => props.hasCross && `
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 2px; 
            background-color: black;
            transform: rotate(125deg);
        }
    `}
`
export interface SemanticFeatherIconProps extends IconProps {
    FeatherIcon: Icon | string
    centerIcon?: boolean
    fontSize?: string
    hasCross?: boolean
}
const SemanticFeatherIcon: FunctionComponent<SemanticFeatherIconProps> = ({
    size = 16,
    FeatherIcon,
    color,
    centerIcon = false,
    fontSize = "",
    hasCross = false,
    ...rest
}) => {
    return (
        <StyledSemanticIcon className="icon" centerIcon={centerIcon} fontSize={fontSize} hasCross={hasCross}>
            <FeatherIcon size={size} color={color} {...rest} />
        </StyledSemanticIcon>
    )
}

export default SemanticFeatherIcon
