import { Table, Row } from "@tanstack/react-table"
import React, { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { Input } from "semantic-ui-react"

export const globalFilterFn = (row: Row<any>, columnId: string, filterValue: string) => {
    const safeValue = (() => {
        const value = row.getValue<any>(columnId);
        return typeof value === 'number' ? String(value) : value;
    })();

    return safeValue?.toLowerCase().includes(filterValue.toLowerCase());
}

interface IGlobalFilterProps {
    table: Table<any>
}

const GlobalFilter = ({ table }: IGlobalFilterProps) => {
    const { t } = useTranslation()

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        table.setGlobalFilter(value);
    };

    return (
        <Input
            placeholder={t('search')}
            value={table.getState().globalFilter || ""}
            onChange={handleSearch}
        />
    )
}
export default GlobalFilter
