import React, { useState, useEffect } from 'react'
import { Section, ColumnSpec } from './interfaces'
import { Table, Segment, Checkbox } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ApplicationState } from '../../state/initial'
import { getState } from '../../state'
import { handleAxiosError } from '../../actions/error'
import { getUserFromState, getDepartmentFromState } from '../../state/helpers'
import { getAuthedAxios } from '../../auth'
import { formatDate } from '../../actions/util'

//TODO: Refactor to use hooks
const retrieveData = (section: Section, state: ApplicationState): Promise<any[]> =>
    new Promise((resolve, reject) => {
        getAuthedAxios().then(axios =>
            axios
                .get(
                    `${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects?to=${
                        section.filters['dateFrom']?.value ?? ''
                    }&from=${section.filters['dateTo']?.value ?? ''}`,
                )
                .then(x => {
                    resolve(
                        x.data.map((p: any) => ({
                            ...p,
                            startDate: formatDate(p.startDate),
                            endDate: formatDate(p.endDate),
                            responsible: getUserFromState(state)(p.responsibleId)?.name,
                            department: getDepartmentFromState(state)(p.departmentId)?.name,
                        })),
                    )
                })
                .catch(reject),
        )
    })

const replaceKeyIfNecessary = (key: string) => {
    if (key.endsWith('Id')) return key.substr(0, key.length - 2)

    return key
}

const resolveValueIfNecessary = (state: ApplicationState) => (key: string, value: any) => {
    if (key === 'responsibleId') {
        return getUserFromState(state)(value, true)?.name ?? ''
    } else if (key === 'departmentId') {
        return getDepartmentFromState(state)(value)?.name ?? ''
    }

    return value
}

const SectionResultTable: React.FunctionComponent<{ section: Section }> = ({ section }) => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any[]>()
    const [columns, setColumns] = useState<ColumnSpec[]>([])

    useEffect(() => {
        retrieveData(section, state)
            .then(x => {
                if (x.length > 0) {
                    setColumns(
                        Object.entries(x[0])
                            .filter(
                                ([_, value]) =>
                                    typeof value == 'string' || typeof value == 'number',
                            )
                            .map(([key, _]) => ({ key, hidden: false })),
                    )
                } else {
                    setColumns([])
                }

                setData(x)
            })
            .catch(handleAxiosError(dispatch))
            .finally(() => setLoading(false))
    }, [])

    const setHidden = (columnKey: string, isHidden: boolean) => {
        setColumns(columns => {
            const column = columns.find(x => x.key === columnKey)

            if (column) column.hidden = isHidden

            return [...columns]
        })
    }

    return (
        <Segment basic loading={loading} style={{ minHeight: '50px', padding: 0 }}>
            {data && (
                <>
                    {data.length > 0 ? (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns!
                                        .filter(x => !x.hidden)
                                        .map(x => (
                                            <Table.HeaderCell key={`column-header-${x.key}`}>
                                                {replaceKeyIfNecessary(t(x.key))}
                                                <Checkbox
                                                    defaultChecked={true}
                                                    onChange={() => setHidden(x.key, true)}
                                                />
                                            </Table.HeaderCell>
                                        ))}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data.map((r, i) => (
                                    <Table.Row key={i}>
                                        {columns!
                                            .filter(x => !x.hidden)
                                            .map(x => (
                                                <Table.Cell key={`row-${i}-column-${x.key}`}>
                                                    {resolveValueIfNecessary(state)(
                                                        x.key,
                                                        r[x.key],
                                                    )}
                                                </Table.Cell>
                                            ))}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : (
                        <h2>{t('noData')}</h2>
                    )}
                </>
            )}
        </Segment>
    )
}

export default SectionResultTable
