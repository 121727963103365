import React from "react"
import { Control, Controller, FieldValues } from "react-hook-form"
import EisenhowerMatrix from "./EisenhowerMatrix"

interface IControlPriorityMatrixProps<T extends FieldValues = any> {
    name: string
    control: Control<T>
    showHeader: boolean
}

const ControlPriorityMatrix = ({
    name,
    control,
    showHeader
}: IControlPriorityMatrixProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <EisenhowerMatrix
                        selectedQuadrant={value}
                        onSelectQuadrant={onChange}
                        showHeader={showHeader}
                    />
                )
            }}
        />
    )
}
export default ControlPriorityMatrix
