import React, { useEffect, useState } from 'react'
import { Edit } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import {
    Button,
    Form,
    Input,
    InputOnChangeData,
    Modal,
    Pagination,
    Segment,
    Table,
} from 'semantic-ui-react'
import {
    Measurement,
    MeasurementData,
    MeasurementType,
    PeriodType,
} from '../../../hooks/goals/measurement'
import CollapsingSegment from '../../common/collapsingSegment'
import FlexBox from '../../common/flexBox'
import InlineConfirmDelete from '../../common/inlineConfirmDelete'
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import MeasurementValueRow from './measurementValueRow'

export interface EditMeasurementModalProps {
    currentMeasurement?: Measurement
    periodType: PeriodType
    measurementType: MeasurementType
    open: boolean
    onClose: () => void
    update: (measurementId: number, measurement: Measurement) => Promise<Measurement>
    updateValue: (
        measurementId: number,
        measurementDataId: number,
        measurementData: MeasurementData,
    ) => Promise<MeasurementData>
    deleteValue: (measurementId: number, measurementDataId: number) => Promise<void>
}

const EditMeasurementModal: React.FunctionComponent<EditMeasurementModalProps> = ({
    currentMeasurement,
    periodType,
    measurementType,
    open,
    onClose,
    update,
    updateValue,
    deleteValue,
}) => {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [editMeasurement, setEditMeasurement] = useState<Measurement | undefined>(
        currentMeasurement,
    )

    const itemsPerPage = 8
    const numPages = Math.ceil((currentMeasurement?.measurements.length ?? 0) / itemsPerPage)

    useEffect(() => {
        if (!currentMeasurement) return

        setEditMeasurement({ ...currentMeasurement })
        setCurrentPage(0)
    }, [currentMeasurement])

    const handleChange = (key: string, value: unknown) => {
        if (!editMeasurement) return
        setEditMeasurement({ ...editMeasurement, [key]: value })
    }

    return (
        <Modal
            open={open}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            closeIcon
            onClose={onClose}
            size="small"
        >
            <Modal.Header>{t('editMeasurement')}</Modal.Header>
            <Modal.Content>
                {measurementType !== MeasurementType.Standard && (
                    <Segment>
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Field
                                    required
                                    control={Input}
                                    name="type"
                                    value={editMeasurement?.type ?? ''}
                                    label={t('type')}
                                    onChange={(_: Event, d: InputOnChangeData) =>
                                        handleChange('type', d.value)
                                    }
                                />

                                <Form.Field
                                    required
                                    control={Input}
                                    name="detailedType"
                                    value={editMeasurement?.detailedType ?? ''}
                                    label={t('detailedType')}
                                    onChange={(_: Event, d: InputOnChangeData) =>
                                        handleChange('detailedType', d.value)
                                    }
                                />

                                <Form.Field
                                    required
                                    control={Input}
                                    name="unit"
                                    value={editMeasurement?.unit ?? ''}
                                    label={t('unit')}
                                    onChange={(_: Event, d: InputOnChangeData) =>
                                        handleChange('unit', d.value)
                                    }
                                />
                            </Form.Group>

                            {!!currentMeasurement?.id && (
                                <SaveCancelPrintButtonGroup
                                    location="bottom"
                                    onSave={() =>
                                        update(currentMeasurement.id!, editMeasurement!).then(() =>
                                            toast(`${t('saved')}!`, { type: 'success' }),
                                        )
                                    }
                                    onCancel={onClose}
                                />
                            )}
                        </Form>
                    </Segment>
                )}
                <CollapsingSegment
                    heading={t('measurementValues')}
                    defaultOpen={measurementType === MeasurementType.Standard}
                >
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('year')}</Table.HeaderCell>
                                {periodType !== PeriodType.Yearly && (
                                    <Table.HeaderCell>
                                        {periodType === PeriodType.Monthly ? t('month') : t('week')}
                                    </Table.HeaderCell>
                                )}
                                <Table.HeaderCell>{t('value')}</Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {currentMeasurement?.measurements
                                ?.slice(
                                    currentPage * itemsPerPage,
                                    (currentPage + 1) * itemsPerPage,
                                )
                                .map(x => (
                                    <MeasurementValueRow
                                        key={x.id}
                                        measurementValue={x}
                                        onSave={d =>
                                            updateValue(currentMeasurement.id!, x.id!, d).then(() =>
                                                toast(`${t('saved')}!`, { type: 'success' }),
                                            )
                                        }
                                        periodType={periodType}
                                        onDelete={(id: number) =>
                                            deleteValue(id, currentMeasurement.id!)
                                        }
                                    />
                                ))}

                            {numPages > 1 && (
                                <Pagination
                                    totalPages={numPages}
                                    activePage={currentPage + 1}
                                    onPageChange={(_, d) =>
                                        setCurrentPage(
                                            parseInt(d.activePage?.toString() ?? '1') - 1,
                                        )
                                    }
                                />
                            )}
                        </Table.Body>
                    </Table>
                </CollapsingSegment>
            </Modal.Content>
        </Modal>
    )
}

export default EditMeasurementModal
