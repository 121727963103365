import { getAuthedAxios } from '../../../auth'
import { ReportExportSettings } from './interfaces'
import fileDownload from 'js-file-download'
import { handleAxiosError } from '../../../actions/error'
import { Action } from '../../../reducer'

const generateReportRows = (rows: any[], includeColumns: any[]) => {
    const processGroup = (index: number, currentList: any[], isTop: boolean) => {
        do {
            if (rows[index]?.__metaData?.isGroup) {
                if (currentList.length > 0 && !isTop) {
                    return { currentList, index }
                }

                const result = processGroup(index + 1, [], false)
                currentList.push({
                    values: includeColumns.map(x =>
                        rows[index].__metaData.columnGroupName === x.key ? rows[index].name : '',
                    ),
                    subRows: result.currentList,
                })

                if (!isTop) {
                    index = result.index
                    return { currentList, index }
                }

                index = result.index - 1
            } else {
                currentList.push({
                    values: includeColumns.map(x => rows[index][x.key] ?? ''),
                })
            }
        } while (++index < rows.length)

        return { currentList, index }
    }

    return processGroup(0, [], true).currentList
}

export const generateReport =
    (dispatch: React.Dispatch<Action>) =>
    (
        reportType: number,
        reportSettings: ReportExportSettings,
        rows: any[],
        trackedColumns: any[],
    ) => {
        const includeColumns = trackedColumns.filter(x => !(x as any).exludeFromReport)
        const reportRows = rows.length > 0 ? generateReportRows(rows, includeColumns) : []

        return getAuthedAxios().then(axios =>
            axios
                .post(
                    `${window.env.REACT_APP_REPORT_SERVICE}/api/Report`,
                    {
                        name: reportSettings?.name,
                        type: reportType,
                        columns: includeColumns.map(x => x.name),
                        rows: reportRows,
                    },
                    {
                        responseType: 'blob',
                    },
                )
                .then(response => {
                    const contentDisposition = response.headers['content-disposition'] as string
                    const fileName = contentDisposition
                        .split(';')
                        .filter(x => x.indexOf('filename') !== -1)[0]
                        .split('=')[1]

                    fileDownload(response.data, fileName)
                })
                .catch(handleAxiosError(dispatch)),
        )
    }
