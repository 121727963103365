import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { News } from '../../hooks/start/news';

const NewsSegment = styled(Segment)`
    padding: 0 !important;
    margin: 0 !important;
`;

const PageHeader = styled(Header)`
    i.icon {
        font-size: 50px !important;
    }
`;

const NewsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    img {
        max-width: 600px;
        padding: 20px;
    }

    @media (max-width: 1300px){
        img {
            max-width: 500px;
        }
    }

    @media (max-width: 1100px){
        img {
            max-width: 300px;
        }
    }

    @media (max-width: 900px){
        img {
            max-width: 200px;
        }
    }

    @media (max-width: 600px){
        img {
            display: none;
        }
    }
`;

const ReadingPart = styled.div`
    flex-grow: 1;
`;

const NewsHeader = styled.div`
    img {
        margin:0 auto;
        display: block;
        max-height: 100px;
    }

    .date {
        color: gray;
    }

    h1 {
        margin: 0 0 0.5em 0;
    }
`;

const NewsContent = styled.div`
    white-space: pre-line;
`;

const NewsView: React.FunctionComponent<{ news: News[] | undefined, newsLoading: boolean }> = ({
    news,
    newsLoading
}) => {
    const { t } = useTranslation();

    const renderNews = (news: News) =>
        <Segment key={news.id} basic>
            <NewsContainer>
                <ReadingPart>
                    <NewsHeader>
                        <span className='date'>{moment(news.date).format('yyyy-MM-DD')}</span>
                        <h1>
                            {news.topic}
                        </h1>
                    </NewsHeader>
                    <NewsContent>
                        {news.content}

                        {news.link &&
                            <p>
                                <a href={news.link}>{news.link}</a>
                            </p>
                        }
                    </NewsContent>
                </ReadingPart>

                {news.imageUrl &&
                    <a href={news.imageUrl}>
                        <img src={news.imageUrl} />
                    </a>
                }
            </NewsContainer>
        </Segment>

    return (
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
            <PageHeader icon='newspaper outline' content={t('news')} />
            <NewsSegment loading={newsLoading} basic>
                {news?.map(n =>
                    renderNews(n)
                )}

                {news?.length === 0 ?
                    <Header sub textAlign='center' content={t('noNews')} />
                    : null}
            </NewsSegment>

        </div>
    );
}

export default NewsView;