import React, { useMemo, useState, useEffect } from 'react'
import { Edit, Plus, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Divider, Table } from 'semantic-ui-react'
import { AdminRoles } from '../../../auth/roles'
import {
    Measurement,
    MeasurementData,
    MeasurementType,
    PeriodType,
} from '../../../hooks/goals/measurement'
import { useIsInRole } from '../../../hooks/role'
import CustomConfirm from '../../common/customConfirm'
import FlexBox from '../../common/flexBox'
import Spacer from '../../common/spacer'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import AddMeasurementValueModal from '../common/addMeasurementValueModal'
import CreateMeasurementModal from '../common/createMeasurementModal'
import Diagram, { MeasurementDisplayMap } from './diagram'
import EditMeasurementModal from './editMeasurementModal'
import GenerateReportButton from './generateReportButton'

export interface PeriodTabProps {
    measurements?: Measurement[]
    periodType: PeriodType
    measurementType: MeasurementType
    error: any
    create: (measurement: Measurement) => Promise<Measurement>
    addValue: (measurementId: number, measurementData: MeasurementData) => Promise<MeasurementData>
    update: (measurementId: number, measurement: Measurement) => Promise<Measurement>
    updateValue: (
        measurementId: number,
        measurementDataId: number,
        measurementData: MeasurementData,
    ) => Promise<MeasurementData>
    deleteMeasurement: (measurementId: number) => Promise<void>
    deleteValue: (measurementId: number, measurementDataId: number) => Promise<void>
}

const groupBy = <T extends unknown>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } => {
    return arr.reduce((storage, item) => {
        const objKey = keys.map(key => `${item[key]}`).join(':')
        if (storage[objKey]) {
            storage[objKey].push(item)
        } else {
            storage[objKey] = [item]
        }
        return storage
    }, {} as { [key: string]: T[] })
}

const PeriodTab: React.FunctionComponent<PeriodTabProps> = ({
    measurements,
    periodType,
    measurementType,
    error,
    create,
    update,
    deleteMeasurement,
    addValue,
    updateValue,
    deleteValue,
}) => {
    const { t } = useTranslation()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.GoalsAdminRole)
    const [currentEditMeasurementId, setCurrentEditMeasurementId] = useState<number>()
    const [displayMeasurements, setDisplayMeasurements] = useState<MeasurementDisplayMap>({})
    const [open, setOpen] = useState<boolean>(false)

    const groupedMeasurements = groupBy(measurements ?? [], ['type'])

    useEffect(() => {
        if (!measurements?.length) return
        setDisplayMeasurements(x => ({ ...x, [measurements[0].id!]: true }))
    }, [measurements])

    const currentEditMeasurment = useMemo(
        () => measurements?.find(x => x.id === currentEditMeasurementId),
        [currentEditMeasurementId, measurements],
    )

    return (
        <>
            <EditMeasurementModal
                open={open}
                onClose={() => setOpen(false)}
                currentMeasurement={currentEditMeasurment}
                periodType={periodType}
                measurementType={measurementType}
                update={update}
                updateValue={updateValue}
                deleteValue={deleteValue}
            />

            <Spacer />

            <Diagram measurements={measurements ?? []} display={displayMeasurements} />

            <Divider />

            {measurementType !== MeasurementType.Standard && isAdmin && (
                <FlexBox justifyContent="end">
                    <CreateMeasurementModal
                        error={error}
                        trigger={
                            <Button
                                onClick={() => { }}
                                type="button"
                                primary
                                icon
                                labelPosition="left"
                            >
                                <SemanticFeatherIcon
                                    FeatherIcon={Plus}
                                    size={'60%'}
                                    centerIcon={true}
                                />{' '}
                                {t('addNew')}
                            </Button>
                        }
                        onCreate={create}
                        prefilledPeriodId={periodType}
                        requireFirstValueCreated={false}
                    />
                </FlexBox>
            )}
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Checkbox
                                checked={!measurements?.some(m => !displayMeasurements[m.id!])}
                                onChange={(_, d) =>
                                    setDisplayMeasurements(
                                        measurements?.reduce(
                                            (acc, cur) => ({ ...acc, [cur.id!]: d.checked }),
                                            {},
                                        ) ?? {},
                                    )
                                }
                            />
                        </Table.HeaderCell>
                        {measurementType !== MeasurementType.Standard && (
                            <Table.HeaderCell>{t('type')}</Table.HeaderCell>
                        )}
                        <Table.HeaderCell>{t('detailedType')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('unit')}</Table.HeaderCell>
                        {isAdmin && <Table.HeaderCell />}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {Object.entries(groupedMeasurements).map(([type, subMeasurements]) =>
                        subMeasurements.map((x, i) => (
                            <React.Fragment key={x.id}>
                                <Table.Row>
                                    <Table.Cell collapsing>
                                        <Checkbox
                                            checked={displayMeasurements[x.id!] ?? false}
                                            onChange={(_, d) =>
                                                setDisplayMeasurements(z => ({
                                                    ...z,
                                                    [x.id!]: d.checked ?? false,
                                                }))
                                            }
                                        />
                                    </Table.Cell>
                                    {measurementType !== MeasurementType.Standard && (
                                        <Table.Cell collapsing>{i === 0 ? type : ''}</Table.Cell>
                                    )}
                                    <Table.Cell>{x.detailedType}</Table.Cell>
                                    <Table.Cell collapsing>{x.unit}</Table.Cell>
                                    {isAdmin && (
                                        <Table.Cell collapsing>
                                            <FlexBox justifyContent="end">
                                                <AddMeasurementValueModal
                                                    error={error}
                                                    periodType={
                                                        periodType ??
                                                        x.periodId ??
                                                        PeriodType.Yearly
                                                    }
                                                    onCreate={data => addValue(x.id!, data)}
                                                    trigger={
                                                        <Button
                                                            icon={
                                                                <SemanticFeatherIcon
                                                                    FeatherIcon={Plus}
                                                                    size="16px"
                                                                />
                                                            }
                                                            onClick={() => { }}
                                                            primary
                                                            type="button"
                                                        />
                                                    }
                                                />

                                                <Button
                                                    icon={
                                                        <SemanticFeatherIcon FeatherIcon={Edit} />
                                                    }
                                                    onClick={() => {
                                                        setCurrentEditMeasurementId(x.id)
                                                        setOpen(true)
                                                    }}
                                                    type="button"
                                                />

                                                {measurementType !== MeasurementType.Standard && (
                                                    <CustomConfirm
                                                        onConfirm={() => deleteMeasurement(x.id!)}
                                                        trigger={
                                                            <Button
                                                                icon={
                                                                    <SemanticFeatherIcon
                                                                        FeatherIcon={Trash2}
                                                                    />
                                                                }
                                                                negative
                                                            />
                                                        }
                                                    />
                                                )}
                                            </FlexBox>
                                        </Table.Cell>
                                    )}
                                </Table.Row>
                                {!!x.measurements.length && (
                                    <Table.Row>
                                        <Table.Cell colSpan="5">
                                            <Table
                                                basic="very"
                                                compact="very"
                                                size="small"
                                                singleLine
                                                attached="top"
                                                collapsing
                                                celled
                                            >
                                                <Table.Header>
                                                    <Table.Row>
                                                        {x.measurements.map((m, i) => (
                                                            <Table.Cell key={i}>
                                                                {m.displayKey}
                                                            </Table.Cell>
                                                        ))}
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    <Table.Row>
                                                        {x.measurements.map((m, i) => (
                                                            <Table.Cell key={i}>
                                                                {m.value}
                                                            </Table.Cell>
                                                        ))}
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </React.Fragment>
                        )),
                    )}
                </Table.Body>
            </Table>

            <GenerateReportButton measurements={measurements ?? []} include={displayMeasurements} />
        </>
    )
}

export default PeriodTab
