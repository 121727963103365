import { isInRole } from '../auth/roles'
import { getState } from '../state'

export const useIsInRole = (roleName: string) => {
    const {
        state: { userProfile },
    } = getState()

    return {
        roles: userProfile?.role,
        isInRole: isInRole(userProfile, roleName),
    }
}
