import { pdf } from '@react-pdf/renderer'
import { getAuthedAxios } from '../../auth'
import {
    FieldDataItem,
    MobileSystematicTaskFieldDataType,
} from '../../components/systematicTasks/EditMobileSystematicTask'
import { getState } from '../../state'
import useApi from '../useApi'
export type FileInfoDto = {
    id: number
    sysId: number
    fileName: string
    moduleName: string
    collectionId: string
    companyId: number
}

export const useMobileSystematicTasksPdf = () => {
    const { state } = getState()

    const {
        data: fileInfo,
        loading,
        post: addFileLink,
    } = useApi<FileInfoDto>(
        `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/mobile-tasks/add-file-link`,
        {
            suppressFetch: true,
        },
    )

    const parseFieldsForPdf = (fields: FieldDataItem[]) => {
        let parsedFields: FieldDataItem[] = []

        fields.forEach(f => {
            if (f.type === MobileSystematicTaskFieldDataType.MultipleUserDropdown) {
                const value = f.value as string[]

                const users = value?.map(id => {
                    const user = state.users.find(u => u.id === id)
                    return user?.name || ''
                })

                parsedFields = [...parsedFields, { ...f, value: users }]
            } else {
                parsedFields = [...parsedFields, f]
            }
        })

        return parsedFields
    }

    const getPdf = async (document: React.ReactElement) => {
        try {
            return await pdf(document).toBlob()
        } catch (error) {
            throw error
        }
    }

    const uploadPdf = async (
        pdfData: Blob,
        fileName: string,
        collectionId: string,
        sysId: number,
    ) => {
        const uploadUrl = `${window.env.REACT_APP_WEBFORMS_URL}/fileUpload.aspx`

        const axios = await getAuthedAxios()

        const formData = new FormData()

        formData.append('file', pdfData, fileName)

        const module = 'sys'

        formData.append('module', module)
        formData.append('collectionId', collectionId)

        try {
            const uploadResult = await axios.post(uploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: progressEvent => {
                    const percentageComplete = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    )
                    console.log('percentageComplete', percentageComplete)
                },
            })

            const fileInfo: FileInfoDto = {
                id: uploadResult.data.id,
                sysId,
                fileName,
                moduleName: module,
                collectionId,
                companyId: 0,
            }

            await addFileLink(fileInfo)

            return fileInfo
        } catch (error) {
            throw error
        }
    }

    return {
        getPdf,
        uploadPdf,
        parseFieldsForPdf,
    }
}
