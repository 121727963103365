import React from 'react'
import styled from 'styled-components'
import { ProcessHierarchyItem } from '../../../hooks/docs/process'
import { ArrowRight } from './arrows'
import FlowItem from './flowItem'

const OuterFlowContainer = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
`

const FlowContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    justify-content: center;
`

export interface ProcessFlowProps {
    items: ProcessHierarchyItem[]
    type: 'governing' | 'main' | 'support'
    setProcess: (process: ProcessHierarchyItem) => void
}

const ProcessFlow: React.FunctionComponent<ProcessFlowProps> = ({ items, type, setProcess }) => {
    const renderFlow = (x: ProcessHierarchyItem) => {
        if (!x.children.filter(c => c.item?.isPublished).length) return null

        return (
            <OuterFlowContainer key={`outerFlowContainer-${x.children[0].item?.id}`}>
                <FlowContainer>
                    <>
                        {x.children
                            .filter(x => x.item?.isPublished)
                            .map((child, i) => (
                                <FlowItem
                                    key={`child-${i}`}
                                    type={type}
                                    item={child}
                                    isInherited={x.isInherited}
                                    setProcess={setProcess}
                                />
                            ))}
                    </>
                </FlowContainer>
                {type === 'main' && (
                    <div style={{ padding: '2px 0' }}>
                        <ArrowRight style={{ height: '100%', width: '20px' }} />
                    </div>
                )}
            </OuterFlowContainer>
        )
    }

    const processesWithContent = items
        .filter(x => x.children.length > 0)
        .sort(a => {
            if (type !== 'main') return 0
            return a.isInherited ? -1 : 1
        })

    return <>{processesWithContent.map(renderFlow)}</>
}

export default ProcessFlow
