import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Modal } from 'semantic-ui-react'
import { formatDate } from '../../actions/util'
import { ChemicalRisk } from '../../hooks/chemicals'
import SafetySymbols from './safetySymbols'
import styled from 'styled-components'

export interface RiskAnalysisInfoModalProps {
    risk?: ChemicalRisk
}

const hasProtectiveGear = (risk?: ChemicalRisk) => (
    risk?.breathingProtection ||
    risk?.face ||
    risk?.glasses ||
    risk?.gloves ||
    risk?.clothing ||
    risk?.shoes
)

const RiskAnalysisInfoModal: React.FunctionComponent<RiskAnalysisInfoModalProps> = ({ risk }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        setIsOpen(!!risk)
    }, [risk])

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} closeIcon size="fullscreen">
            <Modal.Header>&nbsp;</Modal.Header>
            <Modal.Content>
                <InfoSection
                    title={t('riskInhale')}
                    comment={risk?.breathingComment}
                    blocks={[
                        {
                            title: t('dangerLevelWhenExposured'),
                            conditionalTexts: [
                                [risk?.breathing1 ?? false, t('dangerLevel1')],
                                [risk?.breathing2 ?? false, t('dangerLevel2')],
                                [risk?.breathing3 ?? false, t('dangerLevel3')],
                            ]
                        },
                        {
                            title: t('subjectInAir'),
                            conditionalTexts: [
                                [risk?.breathing4 ?? false, t('airLevel1')],
                                [risk?.breathing5 ?? false, t('airLevel2')],
                                [risk?.breathing6 ?? false, t('airLevel3')],
                            ]
                        }
                    ]}
                />

                <InfoSection
                    title={t('riskSkinExposure')}
                    comment={risk?.skinContactComment}
                    blocks={[
                        {
                            title: t('dangerLevelWhenExposured'),
                            conditionalTexts: [
                                [risk?.skinContact1 ?? false, t('otherDangerLevel1')],
                                [risk?.skinContact2 ?? false, t('otherDangerLevel2')],
                                [risk?.skinContact3 ?? false, t('otherDangerLevel3')],
                                [risk?.skinContact4 ?? false, t('otherDangerLevel4')],
                                [risk?.skinContact5 ?? false, t('otherDangerLevel5')],
                            ]
                        }
                    ]}
                />

                <InfoSection
                    title={t('riskSwallows')}
                    comment={risk?.ingestionComment}
                    blocks={[
                        {
                            title: t('dangerToPutInMouth'),
                            conditionalTexts: [
                                [risk?.ingestion1 ?? false, t('dangerMouthLevel1')],
                                [risk?.ingestion2 ?? false, t('dangerMouthLevel2')],
                                [risk?.ingestion3 ?? false, t('dangerMouthLevel3')],
                            ]
                        }
                    ]}
                />

                <InfoSection
                    title={t('fireAndExplosionRisks')}
                    comment={risk?.fireComment}
                    blocks={[
                        {
                            title: t('levelOfFireAndExplosion'),
                            conditionalTexts: [
                                [risk?.fire1 ?? false, t('fireExplosionLevel1')],
                                [risk?.fire2 ?? false, t('fireExplosionLevel2')],
                                [risk?.fire3 ?? false, t('fireExplosionLevel3')],
                            ]
                        }
                    ]}
                />

                <InfoSection
                    title={t('addNewRiskText45')}
                    comment={risk?.accidentalLeakComment}
                    blocks={[
                        {
                            title: t('areThereAnyInstructions'),
                            conditionalTexts: [
                                [!!risk?.accidentalLeak || !!risk?.accidentalLeakComment, risk?.accidentalLeak ? t('yesText') : t('noText')],
                            ]
                        }
                    ]}
                />

                <InfoSection
                    title={t('addNewRiskText74')}
                    comment={risk?.riskComment}
                    blocks={[
                        {
                            title: t('addNewRiskText76'),
                            conditionalTexts: [[!!risk?.risk1 || !!risk?.risk2 || !!risk?.riskComment, risk?.risk1 ? t('yesText') : t('noText')]]
                        },
                        {
                            title: t('addNewRiskText77'),
                            conditionalTexts: [[!!risk?.risk2 || !!risk?.risk1 || !!risk?.riskComment, risk?.risk2 ? t('yesText') : t('noText')]]
                        }
                    ]}
                />

                <InfoSection
                    title={t('addNewRiskText50')}
                    comment={risk?.otherComment}
                    blocks={[
                        {
                            title: t('otherInfoAboutFaror'),
                            conditionalTexts: [
                                [risk?.other1 ?? false, t('subjectContactWithOther')],
                                [risk?.other2 ?? false, t('harmfulForSomeMaterials')],
                            ]
                        }
                    ]}
                />

                <InfoSection
                    title={t('totalRiskAssessment')}
                    blocks={[
                        {
                            conditionalTexts: [[true, t(`${risk?.totalRiskAssessment}`) ?? '']]
                        }
                    ]}
                />

                <InfoSection
                    title={t('possibleSafeGuards')}
                    forceShow={hasProtectiveGear(risk)}
                    blocks={[
                        {
                            conditionalTexts: [
                                [risk?.emergencyEquipment ?? false, t('emergencyEquipmentText')],
                            ]
                        }
                    ]}
                />
                <SafetySymbols risk={risk} imageSize="32px" />
                <InfoSection
                    blocks={[
                        {
                            title: t('specificDisposalText'),
                            asList: true,
                            conditionalTexts: [
                                [risk?.harmfulWaste ?? false, t('classedAsHarmfulWaste')],
                                [risk?.harmfulGoods ?? false, t('classedAsHarmfulGoods')]
                            ]
                        }
                    ]}
                />

                <InfoSection
                    blocks={[
                        {
                            title: t('addNewRiskText71'),
                            conditionalTexts: [[!!risk?.substancesFormed, risk?.substancesFormed ?? '']]
                        }
                    ]}
                />

                <InfoSection
                    blocks={[
                        {
                            title: t('riskAssesmentDate'),
                            conditionalTexts: [[!!risk?.date, formatDate(risk?.date ?? '')]]
                        }
                    ]}
                />

                <InfoSection
                    comment={risk?.finalComment}
                    blocks={[
                        {
                            title: t('addNewRiskText73'),
                            conditionalTexts: [[!!risk?.performedBy, risk?.performedBy ?? '']]
                        }
                    ]}
                />
            </Modal.Content>
        </Modal>
    )
}



interface InfoBlockProps {
    title?: string
    conditionalTexts: Array<[boolean, string]>
    asList?: boolean
}

const BlockListItem = styled.p`
    margin-bottom: 0;
`

const InfoBlock: React.FunctionComponent<InfoBlockProps> = ({
    title,
    conditionalTexts,
    asList
}) => {

    const ItemElement = asList ? BlockListItem : 'p' as keyof JSX.IntrinsicElements

    return (
        <>
            {title && <Header sub>{title}</Header>}

            {conditionalTexts
                .filter(([display, _]) => display)
                .map(([_, text], i) => (
                    <ItemElement key={i}>{text}</ItemElement>
                ))}
        </>
    )
}

interface InfoSectionProps {
    title?: string
    comment?: string
    blocks: Array<InfoBlockProps>
    forceShow?: boolean
}

const InfoSection: React.FunctionComponent<InfoSectionProps> = ({
    title,
    comment,
    blocks,
    forceShow
}) => {
    const { t } = useTranslation()

    const blockProps = blocks
        .filter(block => block.conditionalTexts.some(([display, _]) => display))

    if (!forceShow && !blockProps.length && !comment) return null;

    return (
        <>
            {title && <Header>{title}</Header>}

            {blockProps.map((props, i) => (
                <InfoBlock
                    key={i}
                    title={props.title}
                    asList={props.asList}
                    conditionalTexts={props.conditionalTexts}
                />
            ))}

            {comment && (
                <>
                    <Header sub>{t('comment')}</Header>
                    <p>{comment}</p>
                </>
            )}
        </>
    )
}

export default RiskAnalysisInfoModal
