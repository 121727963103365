import React, { useMemo } from 'react'
import { CheckSquare, Square } from 'react-feather'
import styled from 'styled-components'
import { BackgroundColor, LineColor, RowIndicatedBackgroundColor } from '../../../../styles'
import { QuestionTypeProps } from './questionTypeProps'

const SelectionButton = styled.button<{ $isSelected: boolean }>`
    border: 1px solid ${LineColor};
    border-radius: 5px;
    padding: 1em 0.5em;
    cursor: pointer;
    background-color: ${props => (props.$isSelected ? BackgroundColor : 'white')};
    color: ${props => (props.$isSelected ? 'white' : 'grey')};

    &:hover {
        background-color: ${props =>
            props.$isSelected ? BackgroundColor : RowIndicatedBackgroundColor};
    }

    display: flex;
    align-items: center;
    gap: 1.5em;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: stretch;
    margin: 0 auto;
    width: 80%;
    max-width: 450px;
`

const MultipleChoiceQuestion: React.FunctionComponent<QuestionTypeProps> = ({
    question,
    currentComment,
    onAnswer,
}) => {
    const selectedValues: string[] = useMemo(
        () => JSON.parse(currentComment ? currentComment : '[]') ?? [],
        [currentComment],
    )

    const onSelect = (value: string) => {
        const newSelectedValues = selectedValues.includes(value)
            ? selectedValues.filter(v => v !== value)
            : [...selectedValues, value]

        onAnswer(0, JSON.stringify(newSelectedValues))
    }

    return (
        <ButtonContainer>
            {question.choices?.map(x => (
                <SelectionButton
                    key={x.value}
                    $isSelected={selectedValues.includes(x.value)}
                    onClick={() => onSelect(x.value)}
                >
                    {selectedValues.includes(x.value) ? <CheckSquare /> : <Square />}
                    {x.text}
                </SelectionButton>
            ))}
        </ButtonContainer>
    )
}

export default MultipleChoiceQuestion
