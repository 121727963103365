import { get, post, put, destroy } from "../restClient";

export interface IPositionDto {
    id: number;
    name: string;
}

export interface IPositionHierarchyDto {
    id: number;
    name: string;
    description: string;
    parentId: number | null;
    subRows: IPositionHierarchyDto[];
}

export interface ICompetenceUserPositionDto extends IPositionDto {
    isRegular: boolean;
}

export interface ICompetenceUserPositionRequest {
    isRegular: boolean;
}
export interface ICompetenceUserPositionBatchRequest {
    positionIds: number[];
    isRegular: boolean;
}
export interface ICompetenceUserSkillBatchRequest {
    skillIds: number[];
    validFrom: Date | null;
    validTo: Date | null;
    competenceLevel: number | null;
}
export interface ISkillsDto {
    id: number;
    name: string;
}

export interface IUserSkillsDto extends ISkillsDto {
    validFrom: Date | null;
    validTo: Date | null;
    lastChanged: Date | null;
    competenceLevel: number | null;
}
export interface ISkillsListViewDto extends ISkillsDto {
    positions: ISkillsListViewDto[];
}

export interface IUserListViewDto {
    id: string;
    name: string;
    departmentId: number | null;
    locationId: number | null;
    positions: IPositionDto[];
    skills: IUserSkillsDto[];
}
export interface ICompetenceUserBatchRequest {
    userIds: string[];
    departmentId: number | null;
    locationId: number | null;
    positionIds: number[];
    skillIds: number[];
    validFrom: Date | null;
    validTo: Date | null;
    isRegular: boolean
    competenceLevel: number | null;
}
export interface ICompetenceUserRequest {
    id: string;
    departmentId: number | null;
    locationId: number | null;
    positionIds: number[];
    skillIds: number[];
}
export interface ICompanyPositionsDto {
    companyPositions: IPositionDto[];
    concernPositions: IPositionDto[];

}
export interface IConnectPositionSkillRequest {
    id: number;
    positionIds: number[];
}
export interface ICompetenceUserDto {
    id: string;
    name: string;
    departmentId: number | null;
    locationId: number | null;
    positions: ICompetenceUserPositionDto[];
    skills: IUserSkillsDto[];
}
export interface IChangePositionHierarchyDto {
    id: number
    parentId: number | null
}
export const getPositions = async () => {
    const data = await get<ICompanyPositionsDto>(`${window.env.REACT_APP_SKILL_SERVICE}/api/Position/company`);
    if (!data?.data) return data;
    const collator = new Intl.Collator('sv');
    data.data.companyPositions.sort((a, b) => collator.compare(a.name, b.name));
    data.data.concernPositions.sort((a, b) => collator.compare(a.name, b.name));

    return data;
};

export const getPositionHierarchy = () => get<IPositionHierarchyDto[]>(`${window.env.REACT_APP_SKILL_SERVICE}/api/Position/company/hierarchy`)
export const getSkillsListView = async () => {
    const data = await get<ISkillsListViewDto[]>(`${window.env.REACT_APP_SKILL_SERVICE}/api/Skills/company`);
    if (!data?.data) return data;
    const collator = new Intl.Collator('sv');
    data.data.sort((a, b) => collator.compare(a.name, b.name));
    return data;
};
export const createSkill = (data: ISkillsDto) => post(`${window.env.REACT_APP_SKILL_SERVICE}/api/Skills`, data)

export const editSkill = (id: number, data: ISkillsDto) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/Skills/${id}`, data)

export const destroySkill = (id: number) => destroy(`${window.env.REACT_APP_SKILL_SERVICE}/api/Skills/${id}`)

export const editSkillPositionConnect = (data: IConnectPositionSkillRequest) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/Skills/position/connect`, data)

export const destroySkillPositionConnect = (skillId: number, positionId: number) => destroy(`${window.env.REACT_APP_SKILL_SERVICE}/api/Skills/${skillId}/Position/${positionId}`)

export const createPosition = (data: IPositionHierarchyDto) => post(`${window.env.REACT_APP_SKILL_SERVICE}/api/Position`, data)

export const editPosition = (data: IPositionHierarchyDto) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/Position`, data)

export const changePositionHierarchy = (data: IChangePositionHierarchyDto) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/Position/change/hierarchy`, data)

export const destroyPosition = (id: number) => destroy(`${window.env.REACT_APP_SKILL_SERVICE}/api/Position/${id}`)

export const getUsersListView = () => get<IUserListViewDto[]>(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/company`)

export const getCompetenceUser = (id: string) => get<ICompetenceUserDto>(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/${id}`)

export const destroyUserSkill = (userId: string, skillId: number) => destroy(`${window.env.REACT_APP_SKILL_SERVICE}/api/user/${userId}/Skills/${skillId}`)

export const destroyUserPosition = (userId: string, positionId: number) => destroy(`${window.env.REACT_APP_SKILL_SERVICE}/api/user/${userId}/Position/${positionId}`)

export const editCompetenceUserPosition = (userId: string, positionId: number, data: ICompetenceUserPositionRequest) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/${userId}/Position/${positionId}`, data)

export const batchCompetenceUserPosition = (userId: string, data: ICompetenceUserPositionBatchRequest) => post(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/${userId}/Position/batch`, data)

export const editCompetenceUserSkill = (userId: string, skillId: number, data: IUserSkillsDto) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/${userId}/Skill/${skillId}`, data)

export const batchCompetenceUserSkill = (userId: string, data: ICompetenceUserSkillBatchRequest) => post(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/${userId}/Skill/batch`, data)

export const editCompetenceUser = (data: ICompetenceUserRequest) => put(`${window.env.REACT_APP_SKILL_SERVICE}/api/User`, data)

export const competenceUserBatch = (data: ICompetenceUserBatchRequest) => post(`${window.env.REACT_APP_SKILL_SERVICE}/api/User/batch`, data)



