import useSWR from 'swr'

export type SystematicTaskDto = {
    id: number
    sysId: number
    topic: string
    date: string
    responsibleId: string
}

export const useSystematicTasksForDocument = (documentId: string) =>
    useSWR<SystematicTaskDto[], any>(
        `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/tasks/document/${documentId}`,
    )
