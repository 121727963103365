import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Loader, Progress } from 'semantic-ui-react'
import { getState } from '../../state'

import userStartupActions from '../../actions/user'
import globalCompanyInfoStartupAction from '../../actions/company'
import { isInPageRenderMode } from '../../actions/util'
import { useTranslation } from 'react-i18next'
import configureFileAccessCookie from '../../actions/fileAccessCookie'
import skillsStartupActions from '../../actions/skills'

const AppLoaderContentContainer = styled.div`
    background-color: #5c6c79;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 50px;
`

const ModuleLoadedContentContainer = styled(AppLoaderContentContainer)`
    background-color: transparent;
`

const AppLoaderContentContainerInner = styled.div`
    max-width: 500px;
    text-align: center;

    img {
        width: 90%;
    }
`

export const AppLoaderContent = () => (
    <AppLoaderContentContainer>
        <AppLoaderContentContainerInner>
            <img src="/images/viqma-system-logo.png" alt="VIQMA System" />
            <Progress active value={100} total={100} />
        </AppLoaderContentContainerInner>
    </AppLoaderContentContainer>
)

export const ModuleLoaderContent = () => {
    const { t } = useTranslation()

    return (
        <ModuleLoadedContentContainer>
            <Loader active indeterminate content={t('loading')} />
        </ModuleLoadedContentContainer>
    )
}

const AppLoader: React.FunctionComponent = ({ children }) => {
    const { dispatch, state } = getState()
    const [isCompleted, setIsCompleted] = useState(false)

    useEffect(() => {
        userStartupActions(dispatch)
            .then(modules =>
                Promise.all([
                    globalCompanyInfoStartupAction(dispatch),
                    configureFileAccessCookie(dispatch),
                    skillsStartupActions(dispatch, modules),
                ]),
            )
            .then(() => setIsCompleted(true))
    }, [dispatch])
    return isCompleted ? (
        <>{children}</>
    ) : isInPageRenderMode() ? (
        <AppLoaderContent />
    ) : (
        <ModuleLoaderContent />
    )
}

export default AppLoader
