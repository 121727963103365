import React, { ReactNode } from "react"
import styled from "styled-components"
import FlexBox from "../../../common/flexBox"
import CompanyLogo from "../../../common/companyLogo"
import { Header } from "semantic-ui-react"

const InfoBox = styled.div`
    margin-left: 30px;
`

interface IPrintHeaderProps {
    title: string
    fallbackToViqmaLogo: boolean
    headerContent?: ReactNode
}
const PrintHeader = ({ title, headerContent, fallbackToViqmaLogo }: IPrintHeaderProps) => {
    return (
        <div className="show-in-print">
            <FlexBox justifyContent="space-between">
                <CompanyLogo maxWidth="150px" fallbackToViqmaLogo={fallbackToViqmaLogo} />

                {headerContent && <InfoBox>
                    {headerContent}
                </InfoBox>}
            </FlexBox>

            <Header as="h1" textAlign="center" style={{ marginBottom: "1rem" }}>
                {title}
            </Header>
        </div>
    )
}
export default PrintHeader