export interface DateFilterParams {
    dateFrom?: string
    dateTo?: string
}

export const stringifyDateFilter = (filter?: DateFilterParams) => {
    const formatedFrom = filter?.dateFrom ? `dateFrom=${filter.dateFrom}` : undefined
    const formatedTo = filter?.dateTo ? `dateTo=${filter.dateTo}` : undefined

    const formatedParams = [formatedFrom, formatedTo]
    return formatedParams.filter(x => x).join('&')
}
