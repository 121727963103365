import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Loading from '../../common/Loading'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import * as API from '../../../api'
import ProjectForm from '../../forms/ProjectForm'

interface IProjectDetailsPageProps {
    isNew: boolean
    refreshProjectList: () => void
}

const ProjectDetailsPage = ({ isNew, refreshProjectList }: IProjectDetailsPageProps) => {
    const { t } = useTranslation()
    const { projectId } = useParams<{ projectId: string }>()
    const {
        isLoading,
        data: selectedProject,
        refresh
    } = useGetQueryByParams(!!projectId, API.projects.getById, "selectedProject", projectId)

    const {
        isLoading: isLoadingTemplates,
        data: templates,
    } = useGetQueryByParams(true, API.projects.getAllTemplates, "projectTemplates")

    if (isLoading || isLoadingTemplates) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing>{t('project')}</Header>

            <ProjectForm
                project={selectedProject}
                templates={templates}
                isNew={isNew}
                refresh={refreshProjectList}
            />
        </Container>
    )
}

export default ProjectDetailsPage
