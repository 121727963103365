import { useCrud } from '../crud/use-crud'

export interface Suggestion {
    id?: number
    groupId?: number
    idea: string
    expectedResult: string
    suggestion: string
    reportedBy?: string
}

export const useSuggestion = (suggestionId?: number) =>
    useCrud<Suggestion>(
        suggestionId
            ? `${window.env.REACT_APP_IMPROVMENT_SERVICE}/api/suggestion/${suggestionId}`
            : undefined,
        `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/suggestion`,
        'id',
    )
