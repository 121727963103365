import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Header, Message } from 'semantic-ui-react'
import { useReadConfirmation } from '../../../../hooks/docs/readConfirmation'
import { Check } from 'react-feather'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'

export interface ReadConfirmationAlertProps {
    documentId: string
}

const ReadConfirmationAlert: React.FunctionComponent<ReadConfirmationAlertProps> = ({
    documentId,
}) => {
    const { hasPendingReadConfirmation, markViewed, loading } = useReadConfirmation(documentId)

    const { t } = useTranslation()

    if (!hasPendingReadConfirmation) return null

    return (
        <Message success>
            <Grid>
                <Grid.Column width={12}>
                    <Header size="small">{t('confirmDocumentRead')}</Header>
                </Grid.Column>

                <Grid.Column width={4}>
                    <Button
                        positive
                        icon
                        labelPosition="left"
                        loading={loading}
                        onClick={markViewed}
                        type="button"
                    >
                        <SemanticFeatherIcon FeatherIcon={Check} width="60%" centerIcon={true} />{' '}
                        {t('markAsViewed')}
                    </Button>
                </Grid.Column>
            </Grid>
        </Message>
    )
}

export default ReadConfirmationAlert
