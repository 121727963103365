import React from 'react'
import { Routes, Route } from 'react-router'
import SurveyAdminDetails from './adminDetails'
import SurveyAdminListPage from './adminList'
import SurveyResultPage from './resultPage'

const SurveysModule: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path={'/'} element={<SurveyResultPage />} />
            <Route path={'/admin'} element={<SurveyAdminListPage />} />
            <Route path={'/admin/{id}'} element={<SurveyAdminDetails />} />
        </Routes>
    )
}

export default SurveysModule
