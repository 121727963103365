import React from 'react'
import { Routes, Route } from 'react-router'
import { Grid } from 'semantic-ui-react'
import ImprovementsMenu from './improvementsMenu'
import { isInPageRenderMode } from '../../../actions/util'
import IdeaList from './IdeaList'
import ActivityList from './ActivityList'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import * as API from '../../../api'
import Loading from '../../common/Loading'
import IdeaFormPage from './IdeaFormPage'
import ActivityFormPage from './ActivityFormPage'

const ImprovementsModule = () => {
    const { isLoading: isLoadingActivities, data: improvementActivities, refresh: refreshActivities } = useGetQueryByParams(true, API.improvements.getAllActivities, "activitiesList")
    const { isLoading: isLoadingIdeas, data: improvementIdeas, refresh: refreshIdeas } = useGetQueryByParams(true, API.improvements.getAllIdeas, "ideasList")
    const { isLoading: isLoadingGroups, data: improvementGroups } = useGetQueryByParams(true, API.improvements.getImprovementGroups, "improvementGroups")

    const handleRefreshImprovements = () => {
        refreshIdeas()
        refreshActivities()
    }

    if (isLoadingGroups || isLoadingActivities || isLoadingIdeas || !improvementActivities || !improvementIdeas || !improvementGroups) {
        return <Loading />
    }
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                {isInPageRenderMode() && (
                    <Grid.Column tablet={16} largeScreen={4} widescreen={3}>
                        <ImprovementsMenu />
                    </Grid.Column>
                )}
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route index element={<IdeaList improvementIdeas={improvementIdeas} isLoading={isLoadingIdeas} refresh={handleRefreshImprovements} hasImprovementGroups={improvementGroups.length > 0} />} />
                        <Route path="/detail/">
                            <Route path="new" element={<IdeaFormPage refresh={refreshIdeas} isNew improvementGroups={improvementGroups} />} />
                            <Route path={`:ideaId`} element={<IdeaFormPage refresh={refreshIdeas} isNew={false} improvementGroups={improvementGroups} />} />
                        </Route>
                        <Route path="/activites/">
                            <Route index element={<ActivityList improvementActivities={improvementActivities} isLoading={isLoadingActivities} refresh={refreshActivities} hasImprovementGroups={improvementGroups.length > 0} />} />
                            <Route path="new" element={<ActivityFormPage refresh={refreshActivities} isNew improvementGroups={improvementGroups} />} />
                            <Route path={`:activityId`} element={<ActivityFormPage refresh={refreshActivities} isNew={false} improvementGroups={improvementGroups} />} />
                        </Route>
                    </Routes>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ImprovementsModule
