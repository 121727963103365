import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Grid, Header, Label, List } from 'semantic-ui-react'
import { DocumentListing } from '../../../../hooks/docs/listing'
import DocumentName from '../common/documentName'

const groupByFirstLetter = (documents: DocumentListing[]) =>
    documents.reduce<{ [id: string]: DocumentListing[] }>((acc, cur) => {
        const firstLetter = cur.name.toLowerCase()[0]

        return {
            ...acc,
            [firstLetter]: [...(acc[firstLetter] ?? []), cur],
        }
    }, {})

export interface DocumentIndexProps {
    documents: DocumentListing[]
    companyName?: string
    isConcernDocuments?: boolean
}

const DocumentIndex: React.FunctionComponent<DocumentIndexProps> = ({
    documents,
    companyName,
    isConcernDocuments,
}) => {
    const { t } = useTranslation()

    const groupedDocuments = groupByFirstLetter(documents)
    const orderedKeys = Object.keys(groupedDocuments).sort()

    return (
        <>
            <Header
                content={
                    companyName ?? (isConcernDocuments ? t('concernDocuments') : t('documents'))
                }
            />

            <Grid doubling stackable columns="4">
                {orderedKeys.map(letter => (
                    <Grid.Column key={letter}>
                        <Label
                            content={letter.toUpperCase()}
                            color={isConcernDocuments ? 'teal' : undefined}
                        />

                        <List>
                            {groupedDocuments[letter].map(document => (
                                <List.Item key={document.id}>
                                    <Link to={`./${document.id}`}>
                                        <DocumentName
                                            name={document.name}
                                            isPublished={document.isPublished}
                                        />
                                    </Link>
                                </List.Item>
                            ))}
                        </List>
                    </Grid.Column>
                ))}
            </Grid>
        </>
    )
}

export default DocumentIndex
