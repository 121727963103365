import { TFunction } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Icon, SemanticCOLORS, TableCell } from 'semantic-ui-react'
import { formatDate } from '../../../actions/util'
import { buildLinkToFile } from '../../../hooks/files'
import InfoTableRow from '../infoTableRow'
import InlineConfirmDelete from '../inlineConfirmDelete'
import { guessIconFromName } from './common'
import { FileListing } from './types'

export interface FileRowProp {
    t: TFunction
    module: string
    canDeleteFiles: () => boolean
    onFileDeleted?(): void
    readOnly: boolean
    file: FileListing
    includeToken: boolean
}

const FileRow: FunctionComponent<FileRowProp> = ({
    t,
    module,
    canDeleteFiles,
    file,
    onFileDeleted,
    readOnly,
    includeToken,
}) => {
    return (
        <InfoTableRow $isInfo={file.isInheritedFromConcern}>
            <TableCell collapsing>
                <FileIcon file={file} />
            </TableCell>
            <TableCell>
                <FileLink
                    module={module}
                    file={file}
                    includeToken={file.isInheritedFromConcern || includeToken}
                />
            </TableCell>
            <TableCell>{formatDate(file.created)}</TableCell>

            {file.extraFields &&
                Object.entries(file.extraFields).map(([key, value]) => (
                    <TableCell key={key}>{value}</TableCell>
                ))}

            <TableCell textAlign="right">
                {!readOnly && !file.isInheritedFromConcern && (canDeleteFiles?.() ?? false) && (
                    <InlineConfirmDelete onConfirm={() => onFileDeleted?.()} />
                )}
            </TableCell>
        </InfoTableRow>
    )
}

export const FileLink: FunctionComponent<{
    module: string
    file: FileListing
    includeToken?: boolean
}> = ({ module, file, includeToken = true }) => (
    <a
        href={buildLinkToFile(module, file.id, includeToken ? file.downloadToken : undefined)}
        target="_blank"
        rel="noreferrer"
    >
        {file.name}
    </a>
)

export const FileIcon: FunctionComponent<{ file: FileListing; color?: SemanticCOLORS }> = ({
    file,
    color,
}) => <Icon name={guessIconFromName(file.name)} size="large" color={color} />

export default FileRow
