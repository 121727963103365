import React from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { useUserInRoleOptions, useUserOptions } from '../../hooks/users'
import ErrorBox from './errorBox'

export interface UserDropdownProps extends DropdownProps {
    active?: boolean
}

export const UserDropdown: React.FunctionComponent<UserDropdownProps> = ({
    active = true,
    value,
    concern,
    ...dropdownProps
}) => {
    const userOptions = useUserOptions(u => u.active === active, value?.toString())

    return <Dropdown value={value} {...dropdownProps} options={userOptions} />
}

export interface UserInRoleDropdownProps extends DropdownProps {
    active?: boolean
    role: string
    concern?: boolean
}

export const UserInRoleDropdown: React.FunctionComponent<UserInRoleDropdownProps> = ({
    active = true,
    role,
    concern,
    ...dropdownProps
}) => {
    const { userOptions, loading, error } = useUserInRoleOptions(
        role,
        u => u.active === active,
        concern ?? false,
    )

    return (
        <>
            <ErrorBox error={error} />
            <Dropdown {...dropdownProps} loading={loading} options={userOptions} />
        </>
    )
}
