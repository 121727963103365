import * as API from "../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../actions/error"
import { getState } from "../../../../state"
import { IProjectActivityDto, IProjectRequest } from "../../../../api/projects"

export interface IProjectActivityNotificationRequest {
    methods: string[];
    toUserId: string;
    templateContents: TemplateContent;
    tracking: Tracking;
}

interface TemplateContent {
    templateKey: string;
    values: TemplateValues;
}

interface TemplateValues {
    activity: string;
    date: string;
    projectTitle: string
    projectOwnId: string
}

interface Tracking {
    attachedId: string;
    set: string;
}

export function useNotifyProjectActivity() {
    const { t } = useTranslation()
    const { dispatch } = getState()
    const sendNotification = async (data: { activity: IProjectActivityDto, project: IProjectRequest }) => {
        const notifyData: IProjectActivityNotificationRequest = {
            methods: ["email"],
            toUserId: data.activity.responsibleId,
            templateContents: {
                templateKey: "ProjectActivityReminder",
                values: {
                    activity: data.activity.topic,
                    date: data.activity.doneBefore?.toLocaleDateString() ?? 'N/A',
                    projectTitle: data.project.topic,
                    projectOwnId: data.project.ownId.toString()
                }
            },
            tracking: {
                attachedId: data.activity.id!,
                set: "ProjectActivityReminder"
            }
        }
        return await API.projects.notifyProjectActivity(notifyData)
    }

    const { isLoading: isSending, mutate: onNotify } = useMutation(sendNotification, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })

    return {
        isSending,
        onNotify,
    }
}