export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = (reader.result as string).split(',')[1];
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const guessIconFromName = (filename: string) => {
    const parts = filename.split('.')
    const extension = parts[parts.length - 1].toLowerCase()

    if (extension.indexOf('doc') !== -1 || extension.indexOf('odt') !== -1)
        return 'file word outline'
    else if (extension.indexOf('ppt') !== -1 || extension.indexOf('odp') !== -1)
        return 'file powerpoint outline'
    else if (extension.indexOf('xls') !== -1 || extension.indexOf('odc') !== -1)
        return 'file excel outline'
    else if (extension.indexOf('jpg') !== -1 || extension.indexOf('jpeg') !== -1 || extension.indexOf('png') !== -1)
        return 'file image outline'
    else if (extension.indexOf('pdf') !== -1) return 'file pdf outline'
    else if (extension.indexOf('eml') !== -1 || extension.indexOf('msg') !== -1)
        return 'mail outline'
    else return 'file outline'
}