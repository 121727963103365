import React, { useState } from 'react'
import { ArrowLeftCircle, Save } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Dropdown, DropdownProps, Form, Modal, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import { useDocumentImport } from '../../../../hooks/docs/import'
import { useDocumentTemplatesForCompany } from '../../../../hooks/docs/templates'
import ActionButton from '../../../common/actionButton'
import FlexBox from '../../../common/flexBox'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'

const ItemContainer = styled.div`
    width: 200px;
    padding: 3px;
    margin-right: 5px;
`

interface DocumentImportProps {
    checkedItems: { [key: string]: boolean }
    setCheckedItems: (item: { [key: string]: boolean }) => void
}

const DocumentImport: React.FunctionComponent<DocumentImportProps> = ({
    checkedItems,
    setCheckedItems,
}) => {
    const { t } = useTranslation()
    const [companyId, setCompanyId] = useState('50')
    const { data: templates, isValidating: loading } = useDocumentTemplatesForCompany(companyId)

    const selectAll = () => {
        if (!templates) return
        setCheckedItems(templates.reduce((acc, cur) => ({ ...acc, [cur.id]: true }), {}))
    }

    const selectNone = () => {
        setCheckedItems({})
    }

    return (
        <Form>
            <FlexBox justifyContent="space-between" alignItems="end">
                <Form.Field
                    label={t('company')}
                    control={Dropdown}
                    value={companyId}
                    onChange={(_: Event, d: DropdownProps) => setCompanyId(d.value!.toString())}
                    options={[
                        {
                            key: '50',
                            text: 'Viqma Mall',
                            value: '50',
                        },
                        {
                            key: '172',
                            text: 'Viqma Mall ENG',
                            value: '172',
                        },
                    ]}
                    selection
                    width={4}
                />

                <div>
                    <Button type="button" content="Markera alla" onClick={selectAll} />
                    <Button type="button" content="Markera ingen" onClick={selectNone} />
                </div>
            </FlexBox>
            <hr />
            <Segment basic loading={loading}>
                <FlexBox wrap="wrap">
                    {templates?.map(x => (
                        <ItemContainer key={x.id}>
                            <Checkbox
                                label={x.name}
                                checked={checkedItems[x.id] ?? false}
                                onChange={(_, d) =>
                                    setCheckedItems({
                                        ...checkedItems,
                                        [x.id]: !(checkedItems[x.id] ?? false),
                                    })
                                }
                            />
                        </ItemContainer>
                    ))}
                </FlexBox>
            </Segment>
        </Form>
    )
}

export interface DocumentImportModalProps {
    trigger: React.ReactNode
    onImportComplete: () => void
}

const DocumentImportModal: React.FunctionComponent<DocumentImportModalProps> = ({
    trigger,
    onImportComplete,
}) => {
    const { t } = useTranslation()
    const importDocuments = useDocumentImport()
    const [open, setOpen] = useState(false)
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({})

    const onSave = () => {
        const checkedDocuments = Object.entries(checkedItems ?? {})
            .filter(([_, checked]) => checked)
            .map(([id, _]) => parseInt(id))

        return importDocuments(checkedDocuments).then(() => {
            setCheckedItems({})
            setOpen(false)
            onImportComplete()
        })
    }

    return (
        <Modal
            trigger={trigger}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
        >
            <Modal.Header>Importera dokument</Modal.Header>
            <Modal.Content>
                <DocumentImport checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
            </Modal.Content>
            <Modal.Actions>
                <ActionButton
                    action={onSave}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                    disabled={Object.values(checkedItems ?? {}).filter(x => x).length === 0}
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>

                <Button onClick={() => setOpen(false)} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DocumentImportModal
