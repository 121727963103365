import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header, Segment } from 'semantic-ui-react'
import { DocumentLawConnection } from '../../../../../hooks/laws/documentConnection'
import { X } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import Spacer from '../../../../common/spacer'

export interface LawInfoBoxProps {
    lawConnection?: DocumentLawConnection
    onClose(): void
}
const LawInfoBox: FunctionComponent<LawInfoBoxProps> = ({ lawConnection, onClose }) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        setOpen(!!lawConnection)
    }, [lawConnection])

    if (!open) return null

    return (
        <>
            <Segment>
                <Button onClick={onClose} floated="right" icon circular type="button">
                    <SemanticFeatherIcon FeatherIcon={X} centerIcon={true} />
                </Button>

                <Header>
                    {lawConnection?.denomination} {lawConnection?.name}
                </Header>

                <ModalSectionDescription title={t('type')} text={lawConnection?.lawType} />
                <ModalSectionDescription
                    title={t('lawUpdatedTo')}
                    text={lawConnection?.lawUpdatedTo}
                />
                <ModalSectionDescription
                    title={t('description')}
                    text={lawConnection?.lawDetails}
                />
                {lawConnection?.lawUrl && (
                    <>
                        <Header size="medium">{t('link')}</Header>
                        <a target="_blank" rel="noopener noreferrer" href={lawConnection.lawUrl}>
                            {lawConnection.denomination}
                        </a>
                    </>
                )}
                <ModalSectionDescription title={t('affectedBy')} text={lawConnection?.affectedBy} />
            </Segment>

            <Spacer spacing="40px" />
        </>
    )
}
const ModalSectionDescription = ({
    title,
    text,
}: {
    title: string
    text?: string
    link?: string
}) =>
    text ? (
        <>
            <Header size="medium">{title}</Header>
            <p style={{ whiteSpace: 'break-spaces' }}>{text}</p>
        </>
    ) : null

export default LawInfoBox
