import React from 'react'
import { Grid, Header, Divider, Table, Icon, Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CompactGrid from '../../../common/compactGrid'
import { FileListing, guessIconFromName } from '../../../common/files'
import * as API from '../../../../api'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import Loading from '../../../common/Loading'
import { DeviationMethod, IActivityDto, IIssueRequest, ModuleCollection } from '../../../../api/issues'
import { getState } from '../../../../state'
import FlexBox from '../../../common/flexBox'
import CompanyLogo from '../../../common/companyLogo'
import { getDepartmentFromState, getLocationFromState, getSupplierFromState, getUserFromState } from '../../../../state/helpers'
import Spacer from '../../../common/spacer'
import PrintLabelValue from '../../../wrappers/PrintWrapper/PrintLabelValue'
import { isArray } from 'lodash'

export interface IIssuePrintViewProps {
    issue: IIssueRequest
    ownId?: number
    issueId?: number
    types?: API.issues.IssueType[]
    errorCodes?: API.issues.IssueType[]
    causeCodes?: API.issues.IssueType[]
}

const IssuePrintView = React.forwardRef<HTMLDivElement, IIssuePrintViewProps>(({ issue, ownId, issueId, types, errorCodes, causeCodes }, ref) => {
    const {
        isLoading,
        data,
    } = useGetQueryByParams(!!issueId, API.issues.getAllIssueFiles, "issueFiles", issueId)

    const {
        isLoading: isLoadingActivities,
        data: activities,
    } = useGetQueryByParams(!!issueId, API.issues.getIssueActivitiesById, "issueActivities", issueId)

    const {
        isLoading: isLoadingExtra,
        data: extraFieldTemplate,
    } = useGetQueryByParams(true, API.issues.getExtraFields, "deviationExtra", ModuleCollection.Deviations)

    if (isLoading || !data || isLoadingActivities || !activities || isLoadingExtra) return <Loading />

    return <div ref={ref} className='show-in-print'>
        <IssuePrintViewDumb issue={issue} files={data} ownId={ownId} activities={activities} types={types} errorCodes={errorCodes} causeCodes={causeCodes} extraFieldTemplate={extraFieldTemplate} />
    </div>

})

export interface PrintViewDumbProps {
    issue: IIssueRequest
    files: FileListing[]
    activities: IActivityDto[]
    extraFieldTemplate?: API.issues.IExtraFieldTemplate
    ownId?: number
    types?: API.issues.IssueType[]
    errorCodes?: API.issues.IssueType[]
    causeCodes?: API.issues.IssueType[]
}

export const IssuePrintViewDumb: React.FunctionComponent<PrintViewDumbProps> = ({
    issue,
    files,
    activities,
    ownId,
    types,
    errorCodes,
    causeCodes,
    extraFieldTemplate
}) => {
    const { t } = useTranslation()
    const { state } = getState()
    return (
        <>
            <FlexBox justifyContent="space-between" style={{ margin: "1em 1em 5em 1em" }}>
                <CompanyLogo maxWidth="150px" />
                <div>
                    <PrintLabelValue
                        label={t('reportDate')}
                        value={moment(issue.reportDate).format('Y-MM-DD')}
                    />
                    <PrintLabelValue
                        label={t('reportedBy')}
                        value={getUserFromState(state)(issue.reportedBy)?.name ?? ""}
                    />
                </div>
            </FlexBox>

            <CompactGrid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('issue')}
                            value={issue.topic}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={"Id"}
                            value={ownId?.toString() ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('responsible')}
                            value={getUserFromState(state)(issue.responsibleId)?.name ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('issueType')}
                            value={types?.find(x => x.id === issue.type)?.name ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                {errorCodes && errorCodes.length ? <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('errorCode')}
                            value={errorCodes?.find(x => x.id === issue.errorCode)?.name ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row> : null}
                {causeCodes && causeCodes.length ? <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('causeCode')}
                            value={causeCodes?.find(x => x.id === issue.causeCode)?.name ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row> : null}
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('departmentId')}
                            value={issue.departmentId ? getDepartmentFromState(state)(issue.departmentId)?.name ?? "" : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                {state.locations?.length ? <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('locationId')}
                            value={issue.locationId ? getLocationFromState(state)(issue.locationId)?.name ?? "" : ""}
                        />
                    </Grid.Column>
                </Grid.Row> : null}
                {issue.deviationMethod === DeviationMethod.EightD ? <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('teamMembers')}
                            value={issue.teamMembers.map(id => getUserFromState(state)(id)?.name ?? '').join(', ') ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row> : null}
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('supplierId')}
                            value={issue.supplierId ? getSupplierFromState(state)(issue.supplierId)?.name ?? "" : ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('customer')}
                            value={issue.customerSupplier ?? ""}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <PrintLabelValue
                            label={t('doneBefore')}
                            value={issue.doneBefore ? moment(issue.doneBefore).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                {extraFieldTemplate && extraFieldTemplate.extraFields &&
                    Object.keys(extraFieldTemplate.extraFields).map((key) => {
                        return (
                            <Grid.Row columns="1" key={key}>
                                <Grid.Column>
                                    {extraFieldTemplate.extraFields[key].type === 4 && isArray(issue.extraFields[key]) ?
                                        <PrintLabelValue
                                            label={key}
                                            value={(issue.extraFields[key] as string[]).map(id => getUserFromState(state)(id)?.name ?? '').join(', ') ?? ""}
                                        />

                                        : <PrintLabelValue
                                            label={key}
                                            value={(issue.extraFields[key] as string) ?? ""}
                                        />}
                                </Grid.Column>
                            </Grid.Row>


                        )
                    })
                }

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('description')}
                            value={issue.description ?? ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('actionsTaken')}
                            value={issue.actionsTaken ?? ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Divider />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('analysis')}
                            value={issue.analysis ?? ""}
                        />
                    </Grid.Column>

                    <Grid.Column>
                        <PrintLabelValue
                            label={t('resolvingAction')}
                            value={issue.resolvingAction ?? ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('time')}
                            value={issue.time?.toString() ?? ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('cost')}
                            value={issue.cost?.toString() ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1} className={activities?.length === 0 ? "hide-in-print" : ''}>
                    <Grid.Column>
                        <Header size="medium" content={t('activity')} />
                        <Table compact unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('responsible')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('activity')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('doneBefore')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {activities.map((a: IActivityDto) => (
                                    <Table.Row key={a.id}>
                                        <Table.Cell>{t(`issueStatus.${a.status}`)}</Table.Cell>
                                        <Table.Cell>{getUserFromState(state)(a.responsibleId)?.name ?? ""}</Table.Cell>
                                        <Table.Cell>{a.activity}</Table.Cell>
                                        <Table.Cell>
                                            {a.doneBefore ? moment(a.doneBefore).format('Y-MM-DD') : ""}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Divider />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className={files?.length === 0 ? "hide-in-print" : ''}>
                    <Grid.Column>
                        <Header size="medium" content={t('files')} />
                        <Table compact unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('filename')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {files &&
                                    files.map((x: FileListing) => (
                                        <Table.Row key={x.id}>
                                            <Table.Cell>
                                                <Icon
                                                    name={guessIconFromName(x.name)}
                                                />
                                                {x.name}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Spacer />
                    </Grid.Column>
                    {issue.deviationMethod === DeviationMethod.EightD ? <Grid.Row columns={1}>
                        <Grid.Column>
                            <PrintLabelValue
                                label={t('desiredEffect')}
                                value={issue.desiredEffect ? t('yes') : t('no')}
                            />
                        </Grid.Column>
                    </Grid.Row> : null}
                    {issue.deviationMethod === DeviationMethod.EightD ? <Grid.Row columns={1}>
                        <Grid.Column>
                            <PrintLabelValue
                                label={t('desiredEffectComment')}
                                value={issue.desiredEffectComment}
                            />
                        </Grid.Column>
                    </Grid.Row> : null}
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('endedBy')}
                            value={issue.endedBy ?? ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('endedDate')}
                            value={issue.endedDate ? moment(issue.endedDate).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('reviewBeforeDate')}
                            value={issue.reviewBeforeDate ? moment(issue.reviewBeforeDate).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('reviewText')}
                            value={issue.reviewText}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('evaluatedBy')}
                            value={getUserFromState(state)(issue.reviewResponsibleId)?.name ?? ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('evaluatedAt')}
                            value={issue.reviewDate ? moment(issue.reviewDate).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Label basic>{t('thisIsACopyCheckOriginal')}</Label>
                    </Grid.Column>
                </Grid.Row>
            </CompactGrid>
        </>
    )
}

export default IssuePrintView
