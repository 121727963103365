import React from 'react'
import ModuleMenu from '../../../common/moduleMenu'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SuppliersMenu: React.FunctionComponent = () => {
    const { t } = useTranslation()

    /* eslint react/jsx-key: "off" */
    return ModuleMenu({
        all: [<NavLink to={`./`}>{t('suppliers')}</NavLink>],
    })
}

export default SuppliersMenu
