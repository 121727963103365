import * as z from 'zod';
import { IDeviationSettingUser } from '../../../api/issues';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Button, Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon';
import { ArrowLeftCircle, Save } from 'react-feather';
import ControlCheckbox from '../../fields/ControlCheckbox';
import * as API from '../../../api'

export interface IResponsibleSafetyFormProps {
    selectedUser: IDeviationSettingUser
    onCancel: () => void
    refresh: () => void

}

export const responsilbeSafetyRequestSchema = z.object({
    id: z.number(),
    userId: z.string().min(1, "required"),
    companyId: z.number(),
    deviationResponsible: z.boolean().nullable(),
    accidentResponsible: z.boolean().nullable(),
    safetyOfficer: z.boolean().nullable(),
});

const ResponsibleSafetyForm = ({ selectedUser, onCancel, refresh }: IResponsibleSafetyFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IDeviationSettingUser>({
        defaultValues: selectedUser,
        resolver: zodResolver(responsilbeSafetyRequestSchema),
    });

    const editUser = async (data: IDeviationSettingUser) => await API.issues.editUserSettings(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(editUser, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form noValidate >
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlCheckbox
                            name="deviationResponsible"
                            label={t('deviationResponsible')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlCheckbox
                            name="accidentResponsible"
                            label={t('accidentResponsible')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlCheckbox
                            name="safetyOfficer"
                            label={t('safetyOfficer')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow>
                    <GridColumn>
                        <Button
                            floated="right"
                            content={t('cancel')}
                            icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                            onClick={onCancel}
                            type="button"
                            disabled={isSaving}
                        />

                        <Button
                            primary
                            floated="right"
                            content={t('save')}
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Save}
                                />
                            }
                            onClick={handleSubmit(data => onSubmit(data))}
                            type={'button'}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default ResponsibleSafetyForm;