import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Table } from 'semantic-ui-react'
import { isInPageRenderMode } from '../../../../actions/util'
import { useDocumentListing } from '../../../../hooks/docs/listing'
import { useAllExternalDocuments } from '../../../../hooks/externalDocuments/document'
import { useSort } from '../../../../hooks/sort'
import InfoTableRow from '../../../common/infoTableRow'

const getDocumentLink = (id: number) => (isInPageRenderMode() ? `/docs/${id}` : `../${id}`)

const DocumentExternalDocumentsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { data: externalDocuments } = useAllExternalDocuments()
    const { data: documentListing } = useDocumentListing()

    const documents = [
        ...(documentListing?.concernDocuments ?? []),
        ...(documentListing?.companyDocuments ?? []),
    ]
    const externalDocumentsWithResolvedRoutine = externalDocuments?.map(x => ({
        ...x,
        documentName: documents?.find(d => d.id.toString() === x.documentId?.toString())?.name,
    }))

    const {
        sortedRows: sortedDocuments,
        sortBy,
        sortProp,
        sortDirection,
    } = useSort(externalDocumentsWithResolvedRoutine)

    return (
        <Container>
            <Table sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            onClick={() => sortBy('accountingDocument')}
                            sorted={sortProp === 'accountingDocument' ? sortDirection : undefined}
                        >
                            {t('archiveReportedDocs')}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            onClick={() => sortBy('archivingSite')}
                            sorted={sortProp === 'archivingSite' ? sortDirection : undefined}
                        >
                            {t('archiveSite')}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            onClick={() => sortBy('archivingTime')}
                            sorted={sortProp === 'archivingTime' ? sortDirection : undefined}
                        >
                            {t('archiveTime')}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            onClick={() => sortBy('documentName')}
                            sorted={sortProp === 'documentName' ? sortDirection : undefined}
                        >
                            {t('routine')}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedDocuments?.map(x => (
                        <InfoTableRow $isInfo={x.isInheritedFromConcern} key={x.id}>
                            <Table.Cell>{x.accountingDocument}</Table.Cell>
                            <Table.Cell>{x.archivingSite}</Table.Cell>
                            <Table.Cell>{x.archivingTime}</Table.Cell>
                            <Table.Cell>
                                <Link to={getDocumentLink(x.documentId ?? 0)}>
                                    {x.documentName ?? t('routine')}
                                </Link>
                            </Table.Cell>
                        </InfoTableRow>
                    ))}
                </Table.Body>
            </Table>
        </Container>
    )
}

export default DocumentExternalDocumentsPage
