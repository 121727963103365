import React, { useState, useEffect } from 'react'
import { DynamicField, DynamicFieldType } from '../common/dynamicFields'
import EditView from '../common/editView'
import { getState } from '../../state'
import { useTranslation } from 'react-i18next'
import { getAuthedAxios } from '../../auth'
import { handleAxiosError } from '../../actions/error'

const AdminDepartmentsPage: React.FunctionComponent = () => {
    const { dispatch } = getState()
    const { t } = useTranslation()
    const [departments, setDepartments] = useState<any[]>()

    const displayProps = ['name'].map(x => t(x))
    const editProps: { [id: string]: DynamicField } = {
        name: {
            required: true,
            type: DynamicFieldType.String,
            value: '',
        },
    }

    useEffect(() => {
        getAuthedAxios().then(axios =>
            axios
                .get(`${window.env.REACT_APP_COMPANY_SERVICE}/api/department/my`)
                .then(x => setDepartments(x.data))
                .catch(handleAxiosError(dispatch)),
        )
    }, [])

    const onSave = (item: any, isNew: boolean) => {
        return new Promise<void>(resolve => resolve())
    }

    const onDelete = (item: any) => {}

    return (
        <EditView
            displayProps={displayProps}
            editProps={editProps}
            items={departments ?? []}
            onSave={onSave}
            onDelete={onDelete}
        />
    )
}

export default AdminDepartmentsPage
