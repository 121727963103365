
import React from 'react'
import { IssueTypeHierarchy } from '../../../api/issues'
import { getState } from '../../../state'
import { Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { Info } from 'react-feather'
import { AdminRoles, isInRole } from '../../../auth/roles'
import styled from 'styled-components'

const MarginDiv = styled.div`
    margin-bottom: 1em;
`
interface IEmptyRequiredCollectionProps {
    types?: IssueTypeHierarchy[]
    departmentsRequired?: boolean
}

const EmptyRequiredCollection = ({ types, departmentsRequired = true }: IEmptyRequiredCollectionProps) => {
    const { state } = getState()
    const { t } = useTranslation()
    return (
        <MarginDiv>
            {types && !types.length &&
                <Message info
                    header={t("issueType")}
                    content={<span>
                        {t('noIssueTypes')}
                        {isInRole(state.userProfile, AdminRoles.IssuesAdminRole)
                            ? <a href="/avvikelse/avv.aspx#/admin/settings">{t('toAddLink')}</a>
                            : t('contactSystemAdmin')}
                    </span>}
                    icon={<SemanticFeatherIcon FeatherIcon={Info} centerIcon size="32px" />} />

            }
            {departmentsRequired && !state.departments.length &&
                <Message info
                    header={t("depGroup")}
                    content={<span>
                        {t('noDepartments')}
                        {isInRole(state.userProfile, AdminRoles.SystemAdminRole)
                            ? <a href="/SA/avdGrupp.aspx">{t('toAddLink')}</a>
                            : t('contactSystemAdmin')}
                    </span>}
                    icon={<SemanticFeatherIcon FeatherIcon={Info} centerIcon size="32px" />} />

            }
            {!state.users.length &&
                <Message info
                    header={t("users")}
                    content={<span>
                        {t('noUsers')}
                        {isInRole(state.userProfile, AdminRoles.SystemAdminRole)
                            ? <a href="/SA/addUser.aspx">{t('toAddLink')}</a>
                            : t('contactSystemAdmin')}
                    </span>}
                    icon={<SemanticFeatherIcon FeatherIcon={Info} centerIcon size="32px" />} />

            }
        </MarginDiv>
    )
}

export default EmptyRequiredCollection
