import React from "react"
import { SEPERATOR_ARROW_COLOR, FLOW_ARROW_COLOR } from "./colors"

export const ArrowRight = ({ style }: { style: React.CSSProperties }) =>
    <svg viewBox="0 0 20 40" preserveAspectRatio="none" style={style}>
        <polygon points="0,0 20,20 0,40" fill={FLOW_ARROW_COLOR} />
    </svg>

export const ArrowUp = () =>
    <svg viewBox="0 0 500 25" preserveAspectRatio="none" style={{ maxHeight: '30px', width: '100%' }}>
        <polygon points="0,25 250,0 500,25" fill={SEPERATOR_ARROW_COLOR} />
    </svg>

export const ArrowDown = () =>
    <svg viewBox="0 0 500 25" preserveAspectRatio="none" style={{ maxHeight: '30px', width: '100%' }}>
        <polygon points="0,0 250,25 500,0" fill={SEPERATOR_ARROW_COLOR} />
    </svg>