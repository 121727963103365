import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FullHeightStackingContainer from '../../../common/fullHeightStackingContainer'
import { getUserFromState } from '../../../../state/helpers'
import { getState } from '../../../../state'
import { IIdeaListDto, ImprovementIdeaDropdownStatus, ImprovementStatus } from '../../../../api/improvements'
import Loading from '../../../common/Loading'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Edit, Trash2 } from 'react-feather'
import { useDestroyImprovementIdea } from './hooks/useDeleteIdea'
import { Button, Header } from 'semantic-ui-react'
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    getFilteredRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    ColumnDef,
    GroupingState,
    getExpandedRowModel,
    getGroupedRowModel,
    VisibilityState,
    PaginationState,
    ColumnFiltersState,
    SortingState,
    ExpandedState
} from '@tanstack/react-table'
import TableReact from '../../../wrappers/TableReact'
import DateCell from '../../../common/cells/DateCell'
import { useNavigate } from 'react-router-dom'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { improvementIdeaStatusRowClass } from '../../../wrappers/TableReact/RowClassHelpers'
import { formatDate } from '../../../../actions/util'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { useIsInRole } from '../../../../hooks/role'
import { AdminRoles } from '../../../../auth/roles'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import { DateRangeFilter, dateBetweenFilterFn } from '../../../wrappers/TableReact/Filters/DateRangeFilter'
import EmptyRequiredCollection from '../../../common/EmptyRequiredCollection'

interface IIdeaListProps {
    improvementIdeas: IIdeaListDto[]
    isLoading: boolean
    hasImprovementGroups: boolean
    refresh: () => void
}
const defaultSorting = [{ id: "created", desc: true }]
const IdeaList = ({ improvementIdeas, isLoading, refresh, hasImprovementGroups }: IIdeaListProps) => {
    const { t } = useTranslation()
    const { state } = getState()
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("ideaExpandedState", {})

    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('ideaColumnVisibility', {});
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>('ideaGroupingState', []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('ideaPaginationState', { pageSize: 10, pageIndex: 0 });
    const [showAll, setShowAll] = useTableStorageBackedState('ideaShowAllState', false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('improvementIdeaFilteringState', []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState('improvementIdeaGlobalFilterState', "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('improvementIdeaSortingState', defaultSorting);

    const { isInRole: isAdmin } = useIsInRole(AdminRoles.ImprovementsAdmin)
    const navigate = useNavigate()
    const { isDestroying, onDestroy } = useDestroyImprovementIdea(refresh)
    const columnHelper = createColumnHelper<IIdeaListDto>()
    const columnsReact: ColumnDef<IIdeaListDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableGrouping: false,
                size: 75,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => navigate(`./detail/${row.original.id}`)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        {isAdmin && <Button
                            title={t('delete')}
                            type='button'
                            color='red'
                            onClick={() => onDestroy({ confirmText: t('confirm.deleteValue', { value: row.original.idea.split(" ").slice(0, 5).join(" ") }), ideaId: row.original.id })}
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />}
                    </>
            }),
            columnHelper.accessor((r) => t(`improvementIdeaListStatus.${r.status}`), {
                id: "status",
                header: t('status'),
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | ImprovementIdeaDropdownStatus[]) => void, value: ImprovementIdeaDropdownStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={ImprovementIdeaDropdownStatus}
                            enumLangKey="improvementIdeaListStatus"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`improvementIdeaListStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor((r) => r.improvementGroup ? r.improvementGroup?.name : "", {
                id: "groupId",
                header: t('groupId'),
                cell: ({ row }) => <span>{row.original.improvementGroup?.name}</span>
            }),
            columnHelper.accessor("idea", {
                id: "idea",
                header: t('idea'),
                size: 300,
            }),
            columnHelper.accessor((r) => r.expectedResult ?? "", {
                id: "expectedResult",
                header: t('expectedResult'),
                size: 300,
            }),
            columnHelper.accessor((r) => r.solutionProposal ?? "", {
                id: "solutionProposal",
                header: t('suggestion'),
                size: 300,
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.reporterId)?.name ?? "", {
                id: "reporterId",
                header: t('reporterId'),
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.reporterId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor(r => r.created ? formatDate(r.created) : "", {
                id: "created",
                header: t('reportedDate'),
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.created} />
            }),

        ], [columnHelper, navigate, onDestroy, state, t, isAdmin])

    const table = useReactTable({
        data: improvementIdeas || [],
        columns: columnsReact.filter(x => hasImprovementGroups ? x : x.id !== "groupId"),
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [{ id: "status", value: [ImprovementStatus.Standby, ImprovementStatus.New] }],
            grouping: [],
            sorting: defaultSorting,
        },
        globalFilterFn: globalFilterFn,
        onGroupingChange: setGrouping,
        onExpandedChange: setExpanded,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        onPaginationChange: setPagination
    })
    if (isLoading || isDestroying) {
        return <Loading />
    }
    return (
        <FullHeightStackingContainer>
            <Header dividing>{t('improvementIdeas')}</Header>
            <EmptyRequiredCollection departmentsRequired={false} />
            <TableReact
                table={table}
                rowClassRenderer={(r) => improvementIdeaStatusRowClass(r)}
                onAddCallback={() => navigate("./detail/new")}
                canExport
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: [ImprovementStatus.Standby, ImprovementStatus.New] }] }}
            />
        </FullHeightStackingContainer>
    )
}

export default IdeaList
