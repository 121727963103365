import React, { useState, useEffect } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    Form,
    Header,
    Input,
    InputOnChangeData,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Segment,
} from 'semantic-ui-react'
import { Measurement, MeasurementData, PeriodType } from '../../../hooks/goals/measurement'
import ActionButton from '../../common/actionButton'
import ErrorBox from '../../common/errorBox'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { createYearOptions, useMonthOptions, createWeekOptions } from '../goalDetails/options'

export interface CreateMeasurementModalProps {
    error: any
    trigger: React.ReactNode
    onCreate: (data: Measurement) => Promise<unknown>
    requireFirstValueCreated: boolean
    prefilledPeriodId?: PeriodType
}

const initalData = (prefilledPeriodId?: PeriodType): Measurement => ({
    type: '',
    detailedType: '',
    unit: '',
    measurements: [],
    periodId: prefilledPeriodId,
})

const initalMeasurementValueData: MeasurementData = {
    value: 0,
    year: new Date().getFullYear(),
}

const CreateMeasurementModal: React.FunctionComponent<CreateMeasurementModalProps> = ({
    error,
    trigger,
    onCreate,
    requireFirstValueCreated,
    prefilledPeriodId,
}) => {
    const { t } = useTranslation()
    const monthOptions = useMonthOptions()
    const [open, setOpen] = useState(false)
    const [useFirstValue, setUseFirstValue] = useState(false)

    const [newMeasurement, setNewMeasurement] = useState<Measurement>({
        ...initalData(prefilledPeriodId),
    })
    const [newMeasurementValue, setNewMeasurementValue] = useState<MeasurementData>({
        ...initalMeasurementValueData,
    })

    useEffect(() => {
        if (!prefilledPeriodId) return

        setNewMeasurement(x => ({ ...x, periodId: prefilledPeriodId }))
    }, [prefilledPeriodId])

    const onSave = () => {
        if (requireFirstValueCreated || useFirstValue)
            return onCreate({ ...newMeasurement, measurements: [{ ...newMeasurementValue }] })
        return onCreate({ ...newMeasurement })
    }

    const handleChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        { name, value }: InputOnChangeData,
    ) => setNewMeasurement({ ...newMeasurement, [name]: value })

    const handleMeasurementValueChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        { name, value }: InputOnChangeData,
    ) => setNewMeasurementValue({ ...newMeasurementValue, [name]: value })

    const onClose = () => {
        setNewMeasurement({ ...initalData(prefilledPeriodId) })
        setNewMeasurementValue({ ...initalMeasurementValueData })
        setOpen(false)
    }

    const isValidInput =
        newMeasurement.type &&
        newMeasurement.detailedType &&
        newMeasurement.periodId &&
        newMeasurement.unit &&
        (((newMeasurement.periodId === PeriodType.Yearly || newMeasurementValue.timeReference) &&
            newMeasurementValue.year &&
            newMeasurementValue.value) ||
            !requireFirstValueCreated)

    return (
        <Modal onClose={onClose} onOpen={() => setOpen(true)} open={open} trigger={trigger}>
            <ModalHeader>{t('newMeasurement')}</ModalHeader>
            <ModalContent>
                <ErrorBox error={error} />

                <Form>
                    <Form.Group widths={2}>
                        <Form.Field
                            required
                            control={Input}
                            name="type"
                            value={newMeasurement.type ?? ''}
                            label={t('type')}
                            onChange={handleChange}
                        />

                        <Form.Field
                            required
                            control={Input}
                            name="detailedType"
                            value={newMeasurement.detailedType ?? ''}
                            label={t('detailedType')}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group widths={2}>
                        <Form.Field
                            required
                            control={Input}
                            name="unit"
                            value={newMeasurement.unit ?? ''}
                            label={t('unit')}
                            onChange={handleChange}
                        />

                        <Form.Field
                            required
                            control={Dropdown}
                            name="periodId"
                            value={newMeasurement?.periodId ?? ''}
                            label={t('period')}
                            onChange={handleChange}
                            options={[
                                { value: PeriodType.Yearly, text: t('yearly') },
                                { value: PeriodType.Monthly, text: t('monthly') },
                                { value: PeriodType.Weekly, text: t('weekly') },
                            ]}
                            selection
                        />
                    </Form.Group>

                    <Segment>
                        <Header content={t('firstMeasurement')} />

                        {!requireFirstValueCreated && (
                            <Form.Field
                                control={Checkbox}
                                label={t('useFirstValue')}
                                checked={useFirstValue}
                                onChange={(_: Event, data: CheckboxProps) =>
                                    setUseFirstValue(data.checked ?? false)
                                }
                            />
                        )}

                        <Form.Group widths={'equal'}>
                            <Form.Field
                                required={requireFirstValueCreated || useFirstValue}
                                control={Dropdown}
                                name="year"
                                value={newMeasurementValue.year ?? ''}
                                label={t('year')}
                                onChange={handleMeasurementValueChange}
                                options={createYearOptions()}
                                selection
                                disabled={!requireFirstValueCreated && !useFirstValue}
                            />

                            {newMeasurement?.periodId !== PeriodType.Yearly &&
                                (newMeasurement?.periodId === PeriodType.Monthly ? (
                                    <Form.Field
                                        required={requireFirstValueCreated || useFirstValue}
                                        control={Dropdown}
                                        name="timeReference"
                                        value={newMeasurementValue.timeReference ?? ''}
                                        label={t('month')}
                                        onChange={handleMeasurementValueChange}
                                        options={monthOptions}
                                        selection
                                        disabled={!requireFirstValueCreated && !useFirstValue}
                                    />
                                ) : (
                                    <Form.Field
                                        required={requireFirstValueCreated || useFirstValue}
                                        control={Dropdown}
                                        name="timeReference"
                                        value={newMeasurementValue.timeReference ?? ''}
                                        label={t('week')}
                                        onChange={handleMeasurementValueChange}
                                        options={createWeekOptions()}
                                        selection
                                        disabled={!requireFirstValueCreated && !useFirstValue}
                                    />
                                ))}
                        </Form.Group>

                        <Form.Field
                            required={requireFirstValueCreated || useFirstValue}
                            type="number"
                            control={Input}
                            name="value"
                            value={newMeasurementValue.value ?? ''}
                            label={t('value')}
                            onChange={handleMeasurementValueChange}
                            disabled={!requireFirstValueCreated && !useFirstValue}
                        />
                    </Segment>
                </Form>
            </ModalContent>
            <ModalActions>
                <ActionButton
                    disabled={!isValidInput}
                    action={onSave}
                    postActionCallback={onClose}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>
                <Button onClick={onClose} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default CreateMeasurementModal
