import useSWR from "swr";

export interface News {
    id: any;
    topic: string;
    date: string;
    content: string;
    link?: string;
    imageUrl?: string;
}

export const useNews = () =>
    useSWR<News[], any>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/News`);