import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useReactToPrint } from 'react-to-print'
import AddSectionDialog from './addSectionDialog'
import { Section } from './interfaces'
import { Header, Button } from 'semantic-ui-react'
import SectionResultTable from './sectionResultTable'
import Spacer from '../common/spacer'
import { useTranslation } from 'react-i18next'
import ClearFix from '../common/clearFix'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import { Printer } from 'react-feather'

const PaperContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 90vw;
    padding: 20px;
    border: 2px solid darkgray;
    flex-grow: 1;
`

const DataPrintModule: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const [sections, setSections] = useState<Section[]>()
    const printRef = useRef<HTMLDivElement>(null)
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: 'padding: 20px;',
    })

    const onAddSection = (section: Section) => {
        setSections(sections => [...(sections ?? []), section])
    }

    return (
        <>
            <div>
                <Button
                    floated="right"
                    content={t('print')}
                    icon={<SemanticFeatherIcon FeatherIcon={Printer} />}
                    type="button"
                    onClick={handlePrint}
                />
                <ClearFix />
            </div>

            <Spacer />
            <PaperContainer>
                <div ref={printRef}>
                    {sections?.map(s => (
                        <div key={s.id}>
                            <Header content={s.module} />
                            <SectionResultTable section={s} />
                        </div>
                    ))}

                    <Spacer />
                    <AddSectionDialog onAddSection={onAddSection} />
                </div>
            </PaperContainer>
        </>
    )
}

export default DataPrintModule
