import React, { useEffect, useState } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalHeader,
    ModalContent,
    Form,
    ModalActions,
    Button,
    Input,
    InputOnChangeData,
    DropdownProps,
    TextArea,
    Divider,
} from 'semantic-ui-react'
import { GoalActivity } from '../../../hooks/goals/activity'
import DateInput, { DateOnChangeData } from '../../common/dateInput'
import { UserDropdown } from '../../common/userDropdown'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'

export interface ActivitiesModalProps {
    selectedActivity?: GoalActivity
    onSave?(activity: GoalActivity): void
}

const ActivitiesModal: React.FunctionComponent<ActivitiesModalProps> = ({
    selectedActivity,
    onSave,
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [newActivity, setNewActivity] = useState<GoalActivity>({})

    useEffect(() => {
        if (!selectedActivity) return

        setNewActivity(selectedActivity ?? {})
        setOpen(true)
    }, [selectedActivity])

    const onClose = () => {
        setNewActivity({})
        setOpen(false)
    }

    return (
        <Modal onClose={onClose} onOpen={() => setOpen(true)} open={open}>
            <ModalHeader>{newActivity.id ? t('updateActivity') : t('addActivity')}</ModalHeader>
            <ModalContent>
                <Form>
                    <Form.Field
                        required
                        control={Input}
                        label={t('activity')}
                        value={newActivity.activity ?? ''}
                        onChange={(_: Event, data: InputOnChangeData) =>
                            setNewActivity(d => ({ ...d, activity: data.value }))
                        }
                    />

                    <Form.Group widths={2}>
                        <Form.Field
                            control={UserDropdown}
                            selection
                            search
                            required
                            label={t('responsible')}
                            value={newActivity.responsibleId ?? ''}
                            onChange={(_: Event, data: DropdownProps) =>
                                setNewActivity(d => ({
                                    ...d,
                                    responsibleId: data.value?.toString(),
                                }))
                            }
                        />
                        <Form.Field
                            control={DateInput}
                            label={t('doneBefore')}
                            value={newActivity.doneBefore ?? ''}
                            onChange={(_: Event, data: DateOnChangeData) =>
                                setNewActivity(d => ({ ...d, doneBefore: data.value?.toString() }))
                            }
                        />
                    </Form.Group>

                    <Form.Field
                        control={TextArea}
                        label={t('description')}
                        value={newActivity.description ?? ''}
                        onChange={(_: Event, data: InputOnChangeData) =>
                            setNewActivity(d => ({ ...d, description: data.value }))
                        }
                    />

                    <Form.Group widths={2}>
                        <Form.Field
                            control={Input}
                            type="number"
                            label={t('cost')}
                            value={newActivity.cost ?? ''}
                            onChange={(_: Event, data: InputOnChangeData) =>
                                setNewActivity(d => ({ ...d, cost: parseInt(data.value ?? '0') }))
                            }
                        />
                        <Form.Field
                            control={Input}
                            type="number"
                            label={t('time')}
                            value={newActivity.time ?? ''}
                            onChange={(_: Event, data: InputOnChangeData) =>
                                setNewActivity(d => ({ ...d, time: parseFloat(data.value ?? '0') }))
                            }
                        />
                    </Form.Group>

                    {!!newActivity.id && (
                        <>
                            <Divider />

                            <Form.Group widths={2}>
                                <Form.Field
                                    control={Input}
                                    label={t('doneBy')}
                                    value={newActivity.doneBy ?? ''}
                                    onChange={(_: Event, data: InputOnChangeData) =>
                                        setNewActivity(d => ({
                                            ...d,
                                            doneBy: data.value?.toString(),
                                        }))
                                    }
                                />
                                <Form.Field
                                    control={DateInput}
                                    label={t('doneDate')}
                                    value={newActivity.doneDate ?? ''}
                                    onChange={(_: Event, data: DateOnChangeData) =>
                                        setNewActivity(d => ({
                                            ...d,
                                            doneDate: data.value?.toString(),
                                        }))
                                    }
                                />
                            </Form.Group>
                        </>
                    )}
                </Form>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!newActivity.responsibleId || !newActivity.activity}
                    onClick={() => {
                        onClose()
                        onSave?.(newActivity)
                    }}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </Button>
                <Button onClick={onClose} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default ActivitiesModal
