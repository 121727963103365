import React from 'react'
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'
import { isInPageRenderMode, isInThePast } from '../../actions/util'
import { MODULES } from '../../auth/modules'
import { PendingCourseNotification, ToolCalibrationNotification } from './tabPreambleComponents'
import { RowInfo, RowInfoBasic } from './rowInfo'
import DocumentName from '../modules/docs/common/documentName'

export const getStartPageRowInfo =
    () =>
        (subTitleKey: string, moduleId: number, row: any): RowInfoBasic => {
            const rowInfo: RowInfo = {
                [MODULES.DOCS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/docs/${row.linkId}`
                        : `/alla/dokument.aspx#/${row.linkId}`,
                    linkProperty: 'topic',
                    reminderUserField: 'responsibleId',
                    displayRowIfNoUserSet: true,
                    hideRowIfUserIsInactive: true,
                    overrideHeaderString: {
                        topic: 'routine',
                    },
                    overrides: {
                        documentsUpdatedThisMonth: {
                            hiddenColumns: ["isPublished"],
                            overrideCellInnerContent: {
                                topic: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.topic} />
                                ),
                            }
                        },
                        concernDocumentsUpdatedThisMonth: {
                            hiddenColumns: ["isPublished"],
                            overrideCellInnerContent: {
                                topic: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.topic} />
                                ),
                            }
                        },
                        acknowledgeReadingDocuments: {
                            reminderTemplate: 'AcknowledgeReadingDocuments',
                            reminderTrackingSet: 'AcknowledgeReadingDocuments',
                            linkProperty: 'routine',
                            hiddenColumns: ["isPublished"],
                            displayRowIfNoUserSet: false,
                            isNegative: _ => true,
                        },
                        routineRemiss: {
                            linkProperty: 'routine',
                            userIdField: ['responsibleId', 'sentById'],
                            isNegative: _ => true,
                            hiddenColumns: ["isPublished"],
                            overrideCellInnerContent: {
                                routine: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.routine} />
                                ),
                            }
                        },
                        filesAddedThisMonth: {
                            linkProperty: 'routine',
                            hiddenColumns: ["isPublished"],
                            overrideHeaderString: {
                                name: 'file',
                            },
                            overrideCellInnerContent: {
                                routine: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.routine} />
                                ),
                            }
                        },
                        concernFilesAddedThisMonth: {
                            hiddenColumns: ["isPublished"],
                            linkProperty: 'routine',
                            overrideHeaderString: {
                                name: 'file',
                            },
                            overrideCellInnerContent: {
                                routine: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.routine} />
                                ),
                            }
                        },
                        updatedTemplateDocuments: {
                            hiddenColumns: ["isPublished"],
                            linkUrl: isInPageRenderMode()
                                ? `/docs/template/${row.linkId}`
                                : `/alla/dokument.aspx#/template/${row.linkId}`,
                            linkProperty: 'topic',
                            overrideCellInnerContent: {
                                topic: row => (
                                    <DocumentName isPublished={row.isPublished} name={row.topic} />
                                ),
                            }
                        },
                        updatedTemplateFiles: {
                            linkProperty: 'file',
                            linkUrl: `${window.env.REACT_APP_FILE_SERVICE}/api/File/${row.linkId}?t=${row.downloadToken}`,
                            bypassRouter: true,
                            hiddenColumns: ['downloadToken', 'isPublished'],
                        },
                    },
                },
                [MODULES.SKILLS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/skills/detail/${row.linkId}`
                        : `/kompetens/Anvandare.aspx?id=${row.responsible}`,
                    linkProperty: 'responsible',
                    hideRowIfUserIsInactive: true,
                    overrides: {
                        courseReviews: {
                            isNegative: _ => true,
                            overrideRenderComponent: () => <PendingCourseNotification />,
                            width: '16',
                            displayRowIfNoUserSet: true,
                        },
                        educationLiftetime: {
                            linkUrl: `/kompetens/Anvandare.aspx?id=${row.name}`,
                            userIdField: ['nameId'],
                            linkProperty: 'name',
                        },
                    },
                },
                [MODULES.ISSUES]: {
                    linkUrl: isInPageRenderMode()
                        ? `/issues/detail/${row.linkId}`
                        : `/avvikelse/avv.aspx#/detail/${row.linkId}`,
                    linkProperty: 'topic',
                    reminderTemplate: row =>
                        row.status === 'active' ? 'IssueTaskReminder' : 'IssueEvaluationReminder',
                    reminderUserField: 'responsibleId',
                    reminderTrackingSet: row =>
                        row.status === 'active' ? 'IssueTaskReminder' : 'IssueEvaluationReminder',
                    translateColumns: ['status'],
                    overrides: {
                        deviationTeamMembers: {
                            translateColumns: ['status'],
                            reminderUserField: 'teamMemberId',
                            displayRowIfNoUserSet: false,
                            userIdField: ['teamMemberId'],
                            overrideHeaderString: {
                                teamMember: 'teamMemberId',
                            },
                        },
                        deviationsSkog: {
                            linkUrl: `/avvikelse/Skogavv.aspx?id=${row.linkId}`,
                        },
                        deviationsCertex: {
                            linkUrl: `/avvikelseCertex/cerAvv.aspx?id=${row.linkId}`,
                        },
                        deviationActivities: {
                            reminderTemplate: 'IssueActivityReminder',
                            reminderTrackingSet: 'IssueActivityReminder',
                        },
                        deviationActivitiesSkog: {
                            linkUrl: `/avvikelse/Skogavv.aspx?id=${row.linkId}`,
                            reminderTemplate: 'IssueActivityReminder',
                            reminderTrackingSet: 'IssueActivityReminder',
                        },
                        deviationActivitiesCertex: {
                            linkUrl: `/avvikelseCertex/cerAvv.aspx?id=${row.linkId}`,
                            reminderTemplate: 'IssueActivityReminder',
                            reminderTrackingSet: 'IssueActivityReminder',
                        },
                        evaluationDeviationssSkog: {
                            linkUrl: `/avvikelse/Skogavv.aspx?id=${row.linkId}`,
                            reminderTemplate: 'IssueEvaluationReminder',
                            reminderTrackingSet: 'IssueEvaluationReminder',
                        },
                        evaluationDeviationsCertex: {
                            linkUrl: `/avvikelseCertex/cerAvv.aspx?id=${row.linkId}`,
                            reminderTemplate: 'IssueEvaluationReminder',
                            reminderTrackingSet: 'IssueEvaluationReminder',
                        },
                        accidentIncident: {
                            linkUrl: isInPageRenderMode()
                                ? `/issues/accidents/${row.linkId}`
                                : `/avvikelse/avv.aspx#/accidents/${row.linkId}`,
                            translateColumns: ['status'],
                            displayRowIfNoUserSet: true,
                            userIdField: ['responsibleId'],
                            reminderTemplate: row =>
                                row.status === 'active'
                                    ? 'IssueAccidentReminder'
                                    : 'IssueAccidentEvaluationReminder',
                            reminderTrackingSet: row =>
                                row.status === 'active'
                                    ? 'IssueAccidentReminder'
                                    : 'IssueAccidentEvaluationReminder',
                        },
                        accidentActivities: {
                            linkUrl: isInPageRenderMode()
                                ? `/issues/accidents/${row.linkId}`
                                : `/avvikelse/avv.aspx#/accidents/${row.linkId}`,
                            reminderTemplate: 'IssueAccidentActivityReminder',
                            reminderTrackingSet: 'IssueAccidentActivityReminder',
                        },
                        accidentSafetyOfficer: {
                            linkUrl: isInPageRenderMode()
                                ? `/issues/accidents/${row.linkId}`
                                : `/avvikelse/avv.aspx#/accidents/${row.linkId}`,
                            translateColumns: ['status'],
                            reminderUserField: 'safetyOfficerId',
                            displayRowIfNoUserSet: false,
                            userIdField: ['responsibleId', 'safetyOfficerId'],
                            reminderTemplate: "IssueAccidentSafetyOfficerReminder",
                            reminderTrackingSet: "IssueAccidentSafetyOfficerReminder",
                        },
                    },
                },
                [MODULES.PROJECTS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/projects/detail/${row.linkId}`
                        : `/projekt/projekt.aspx#/detail/${row.linkId}`,
                    linkProperty: 'topic',
                    reminderUserField: 'responsibleId',
                    displayRowIfNoUserSet: true,
                    reminderParameterFactory: x => ({
                        ...x,
                        projectTitle: row.topic,
                        projectOwnId: row.ownId,
                        id: row.linkId,
                        date: row.doneBefore,
                    }),
                    overrides: {
                        projects: {
                            reminderTemplate: 'ProjectReminder',
                            reminderTrackingSet: 'ProjectReminder',
                        },
                        projectActivity: {
                            reminderTemplate: 'ProjectActivityReminder',
                            reminderTrackingSet: 'ProjectActivityReminder',
                        },
                        nordProjectActivity: {
                            linkUrl: `/projektNordschakt/NordPro.aspx?id=${row.linkId}`,
                            reminderTemplate: 'ProjectActivityReminderCustom',
                            reminderTrackingSet: 'NordProjectActivityReminder',
                        },
                        viqmaProjectActivity: {
                            linkUrl: `/projektViqma/ViqPro.aspx?id=${row.linkId}`,
                            linkProperty: 'customer',
                            reminderTemplate: 'ViqmaProjectActivityReminder',
                            reminderTrackingSet: 'ViqmaProjectActivityReminder',
                        },
                        ostProjectActivity: {
                            linkUrl: `/projektOsterlings/OstPro.aspx?id=${row.linkId}`,
                            reminderTemplate: 'ProjectActivityReminderCustom',
                            reminderTrackingSet: 'OstProjectActivityReminder',
                        },
                        specProjectActivity: {
                            linkUrl: `/projektSpecial/specPro.aspx?id=${row.linkId}`,
                            reminderTemplate: 'ProjectActivityReminderCustom',
                            reminderTrackingSet: 'SpecProjectActivityReminder',
                        },
                        viqmaProspekts: {
                            linkUrl: `/projektViqma/prospekt.aspx?id=${row.linkId}`,
                            linkProperty: 'customer',
                            hiddenColumns: ['contactComplete', 'demoComplete', 'reviewComplete'],
                            isNegative: row =>
                                (!row.contactComplete && isInThePast(row.contactDate)) ||
                                (!row.demoComplete && isInThePast(row.demoDate)) ||
                                (!row.reviewComplete && isInThePast(row.reviewDate)),
                        },
                    },
                },
                [MODULES.SYSTEMATIC_TASKS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/systematicTasks/detail/${row.linkId}`
                        : `/revisioner/systematiskaUppg.aspx?id=${row.linkId}`,
                    linkProperty: 'topic',
                    reminderUserField: 'responsibleId',
                    overrides: {
                        systematicTasks: {
                            reminderTemplate: 'SystematicTaskReminder',
                            reminderTrackingSet: 'SystematicTaskReminder',
                            isNegative: row => isInThePast(row.date),
                        },
                        invitedParticpants: {
                            reminderTemplate: undefined,
                            displayRowIfNoUserSet: true,
                            isNegative: row => isInThePast(row.date),
                            overrideHeaderString: {
                                responsible: 'particpants',
                            },
                        },
                        activities: {
                            reminderTemplate: 'SystematicTaskIssueEvaluationReminder',
                            reminderTrackingSet: 'SystematicTaskIssueEvaluationReminder',
                            linkUrl: `/revisioner/systematiskaUppg.aspx?id=${row.linkId}&selectTab=2`,
                        },
                        systematicTasksIssues: {
                            translateColumns: ['status'],
                            linkUrl: `/revisioner/systematiskaUppg.aspx?id=${row.linkId}&selectTab=1`,
                            reminderTemplate: (x) =>
                                x.status === 'active'
                                    ? 'SystematicTaskIssueReminder'
                                    : 'SystematicTaskIssueEvaluationReminder',
                            reminderTrackingSet: (x) =>
                                x.status === 'active'
                                    ? 'SystematicTaskIssueReminder'
                                    : 'SystematicTaskIssueEvaluationReminder',
                        },
                    },
                },
                [MODULES.IMPROVEMENTS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/improvements/activites/${row.linkId}`
                        : `/forbattring/forbIdee.aspx#/activites/${row.linkId}`,
                    linkProperty: 'topic',
                    reminderTemplate: '',
                    reminderUserField: 'responsibleId',
                    reminderTrackingSet: '',
                    displayRowIfNoUserSet: true,
                    overrides: {
                        activityImprovments: {
                            reminderTemplate: 'ImprovementActivityReminder',
                            reminderTrackingSet: 'ImprovementActivityReminder',
                            isNegative: row => isInThePast(row.date),
                            translateColumns: ['status'],
                        },
                    },
                },
                [MODULES.OBJECTS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/object/${row.linkId}`
                        : `/objekt/objekt.aspx.aspx?id=${row.topic}`,
                    linkProperty: 'topic',
                    reminderTemplate: '',
                    reminderUserField: 'performedById',
                    reminderTrackingSet: '',
                    overrides: {
                        activityTools: {
                            linkUrl: `/objekt/matdon.aspx?id=${row.linkId}`,
                            linkProperty: 'activity',
                            reminderTemplate: 'ToolCalibrationReminder',
                            reminderTrackingSet: 'ToolCalibrationReminder',
                        },
                        tasks: {
                            linkUrl: `/objekt/arenden.aspx?id=${row.linkId}`,
                            linkProperty: 'objekt',
                            reminderTemplate: 'ObjectActivityReminder',
                            reminderTrackingSet: 'ObjectActivityReminder',
                            userIdField: ['performedById'],
                            overrideTitle: 'objectTasks',
                        },
                        riskGoalsPlanes: {
                            linkUrl: `/objekt/risker.aspx?id=${row.linkId}`,
                            translateColumns: ['status'],
                            reminderTemplate: row =>
                                row.status === 'active'
                                    ? 'RiskActionPlanReminder'
                                    : 'RiskActionPlanEvaluationReminder',
                            reminderTrackingSet: row =>
                                row.status === 'active'
                                    ? 'RiskActionPlanReminder'
                                    : 'RiskActionPlanEvaluationReminder',
                        },
                        toolCalibration: {
                            isNegative: _ => true,
                            overrideRenderComponent: () => <ToolCalibrationNotification />,
                            width: '16',
                            displayRowIfNoUserSet: true,
                        },
                    },
                },
                [MODULES.RISKS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/laws/${row.linkId}`
                        : `/lagar/aspekter.aspx?id=${row.linkId}`,
                    linkProperty: 'topic',
                    reminderTemplate: '',
                    reminderUserField: 'responsibleId',
                    reminderTrackingSet: '',
                    overrides: {
                        riskActionPlans: {
                            translateColumns: ['status'],
                            reminderTemplate: row =>
                                row.status === 'active'
                                    ? 'RiskOpportunityActionPlanReminder'
                                    : 'RiskOpportunityActionEvaluationPlanReminder',
                            reminderTrackingSet: row =>
                                row.status === 'active'
                                    ? 'RiskOpportunityActionPlanReminder'
                                    : 'RiskOpportunityActionEvaluationPlanReminder',
                        },
                        emergency: {
                            linkUrl: `/lagar/nodlage.aspx?id=${row.linkId}`,
                            translateColumns: ['status'],
                            reminderTemplate: 'EmergencyActionPlanReminder',
                            reminderTrackingSet: 'EmergencyActionPlanReminder',
                        },
                    },
                },
                [MODULES.GOALS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/goals/${row.linkId}`
                        : `/mal/malModul.aspx#/${row.linkId}`,
                    linkProperty: 'topic',
                    reminderTemplate: '',
                    reminderUserField: 'responsibleId',
                    reminderTrackingSet: '',
                    overrides: {
                        goals: {
                            reminderTemplate: 'GoalReminder',
                            reminderTrackingSet: 'GoalReminder',
                        },
                        goalActivities: {
                            reminderTemplate: 'GoalActivityReminder',
                            reminderTrackingSet: 'GoalActivityReminder',
                        },
                    },
                },
                [MODULES.LAWS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/laws/${row.linkId}`
                        : `/lagar/laglista.aspx?id=${row.linkId}`,
                    linkProperty: 'denomination',
                    displayRowIfNoUserSet: true,
                    isNegative: row => isInThePast(row.applicableFrom),
                    translateColumns: ['status'],
                },
                [MODULES.CHEMICALS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/chemical/${row.linkId}`
                        : `/kemi/kemi.aspx?id=${row.linkId}&planId=${row.planId}`,
                    linkProperty: 'topic',
                    reminderTemplate: 'ChemicalActionPlanReminder',
                    reminderUserField: 'responsibleId',
                    reminderTrackingSet: 'ChemicalActionPlanReminder',
                    translateColumns: ['status'],
                    hiddenColumns: ['planId'],
                },
                [MODULES.SUPPLIERS]: {
                    linkUrl: isInPageRenderMode()
                        ? `/suppliers/detail/${row.linkId}`
                        : `/lev/lev.aspx#/detail/${row.linkId}`,
                    linkProperty: 'supplier',
                    reminderTemplate: '',
                    reminderUserField: 'responsibleId',
                    reminderTrackingSet: '',
                    overrides: {
                        newSuppliers: {
                            linkUrl: `/lev/api_lev.aspx?id=${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                        },
                        supplierActions: {
                            linkUrl: isInPageRenderMode()
                                ? `/suppliers/detail/${row.linkId}`
                                : `/lev/lev.aspx#/detail/${row.linkId}`,
                            linkProperty: 'supplier',
                            translateColumns: ['status'],
                            reminderTemplate: 'SupplierActionPlanReminder',
                            reminderTrackingSet: 'SupplierActionPlanReminder',
                        },
                        supplierActionsApi: {
                            linkUrl: `/lev/api_lev_intern.aspx?id=${row.linkId}`,
                            linkProperty: 'supplier',
                            translateColumns: ['status'],
                            reminderTemplate: 'SupplierActionPlanReminder',
                            reminderTrackingSet: 'SupplierActionPlanReminder',
                        },
                        supplierCertificate: {
                            linkUrl: isInPageRenderMode()
                                ? `/suppliers/detail/${row.linkId}`
                                : `/lev/lev.aspx#/detail/${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                            hideColumnIfNoValuesAreSet: true,
                            isNegative: row =>
                                isInThePast(row.certKv) ||
                                isInThePast(row.certMi) ||
                                isInThePast(row.certAr) ||
                                isInThePast(row.verCSR) ||
                                isInThePast(row.certOv) ||
                                isInThePast(row.iso3834) ||
                                isInThePast(row.iso1090),
                        },
                        supplierCertificateApi: {
                            linkUrl: `/lev/api_lev_intern.aspx?id=${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                            hideColumnIfNoValuesAreSet: true,
                            isNegative: row =>
                                isInThePast(row.certKv) ||
                                isInThePast(row.certMi) ||
                                isInThePast(row.certAr) ||
                                isInThePast(row.verCSR) ||
                                isInThePast(row.certOv) ||
                                isInThePast(row.iso3834) ||
                                isInThePast(row.iso1090),
                        },
                        supplierNotAnswered: {
                            linkUrl: isInPageRenderMode()
                                ? `/suppliers/detail/${row.linkId}`
                                : `/lev/lev.aspx#/detail/${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                        },
                        supplierNotAnsweredApi: {
                            linkUrl: `/lev/api_lev_intern.aspx?id=${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                        },
                        supplierEvaluated: {
                            linkUrl: isInPageRenderMode()
                                ? `/suppliers/detail/${row.linkId}`
                                : `/lev/lev.aspx#/detail/${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                        },
                        supplierEvaluatedApi: {
                            linkUrl: `/lev/api_lev_intern.aspx?id=${row.linkId}`,
                            linkProperty: 'supplier',
                            displayRowIfNoUserSet: true,
                        },
                        supplierDeliveryStatistic: {
                            linkProperty: undefined,
                            displayRowIfNoUserSet: true,
                        },
                    },
                },
            }

            const defaults = {
                hiddenColumns: [],
                userIdField: ['responsibleId'],
                width: '8' as SemanticWIDTHS,
            }

            const info = rowInfo[moduleId]

            if (info?.overrides?.[subTitleKey])
                return { ...defaults, ...info, ...info.overrides[subTitleKey] }
            else return { ...defaults, ...info }
        }
