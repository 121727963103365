import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { BackgroundColor, LineColor, RowIndicatedBackgroundColor } from '../../styles'

const BackgroundControlledButton = styled.button<{ $isSelected: boolean }>`
    border: 1px solid ${LineColor};
    border-radius: 5px;
    padding: 1em 2em;
    cursor: pointer;
    background-color: ${props => (props.$isSelected ? BackgroundColor : 'white')};
    color: ${props => (props.$isSelected ? 'white' : 'grey')};

    &:hover {
        background-color: ${props =>
            props.$isSelected ? BackgroundColor : RowIndicatedBackgroundColor};
    }
`

export interface SelectedButtonProps {
    selected?: boolean
    onChange?: (selected: boolean) => void
    children?: ReactNode
}

const SelectedButton: React.FunctionComponent<SelectedButtonProps> = ({
    selected = false,
    onChange,
    children,
}) => {
    return (
        <BackgroundControlledButton $isSelected={selected} onClick={() => onChange?.(!selected)}>
            {children}
        </BackgroundControlledButton>
    )
}

export default SelectedButton
