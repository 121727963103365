import React, { FunctionComponent } from 'react'
import { Loader } from 'semantic-ui-react'
import styled from 'styled-components'

export interface LoadingContainerProps {
    loading?: boolean
    conditional?: boolean
}
interface LoadingProps {
    loading?: boolean
}

const StyledLoader = styled.div<LoadingProps>`
    display: ${({ loading }) => (loading ? 'none' : '')};
`

const LoadingContainer: FunctionComponent<LoadingContainerProps> = ({ loading, children, conditional = false }) => {
    if (conditional && loading) return <Loader active />
    if (conditional && !loading) return <>{children}</>
    return <StyledLoader>{children}</StyledLoader>
}

export default LoadingContainer
