import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../api'
import { IPositionHierarchyDto, ISkillsListViewDto } from '../../../api/skills';
import ControlInput from '../../fields/ControlInput';
import FormActions from '../FormActions';

export interface ISkillFormProps {
    skill?: ISkillsListViewDto
    onCancel: () => void
    refresh: () => void
}

export const skillRequestSchema = z.object({
    id: z.number(),
    name: z.string().min(1, "required"),
});

const SkillForm = ({ skill, onCancel, refresh }: ISkillFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IPositionHierarchyDto>({
        defaultValues: skill ? skill : { id: -1, name: "" },
        resolver: zodResolver(skillRequestSchema),
    });

    const onSkillRequest = async (data: IPositionHierarchyDto) => {
        if (skill) {
            return await API.skills.editSkill(data.id, data)
        }
        return await API.skills.createSkill(data)
    }


    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onSkillRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput name="name" label={t('skill')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default SkillForm;