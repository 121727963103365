import React, { useState } from 'react'
import { AlertTriangle, ArrowDownRight, Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardContent, Dimmer, Header, List, Loader, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import { isInThePast } from '../../actions/util'
import { ObjectCase, useDueObjectCases } from '../../hooks/objects/cases'
import DateDisplay from '../common/dateDisplay'
import FlexBox from '../common/flexBox'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import HandleCaseModal from './handleCaseModal'

const CaseHeader = styled(CardContent)`
    .header {
        margin: 0;
        padding: 0;
    }
`

const ObjectCasesPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { data: objectCases, isValidating: objectCasesLoading } = useDueObjectCases()

    const [selectedObjectCase, setSelectedObjectCase] = useState<ObjectCase>()

    return (
        <>
            <HandleCaseModal currentCase={selectedObjectCase} />

            <Dimmer active={objectCasesLoading && !objectCases?.length}>
                <Loader />
            </Dimmer>

            {!objectCasesLoading && !objectCases?.length && (
                <Message
                    info
                    icon={<SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />}
                    content={t('noCases')}
                />
            )}

            {objectCases?.map(x => (
                <Card key={x.id} fluid color={isInThePast(x.doneBefore) ? 'red' : undefined}>
                    <CaseHeader>
                        <FlexBox justifyContent="space-between" alignItems="center">
                            <DateDisplay date={x.doneBefore} />
                            <Button
                                type="button"
                                primary
                                onClick={() => setSelectedObjectCase({ ...x })}
                            >
                                {t('perform')}
                            </Button>
                        </FlexBox>
                        <Header
                            icon={
                                x.priority === 3 ? (
                                    <SemanticFeatherIcon
                                        color="red"
                                        size="32px"
                                        FeatherIcon={AlertTriangle}
                                    />
                                ) : x.priority === 1 ? (
                                    <SemanticFeatherIcon size="32px" FeatherIcon={ArrowDownRight} />
                                ) : undefined
                            }
                            content={x.title}
                            subheader={`${x.objectNumber}: ${x.objectName}`}
                        />
                    </CaseHeader>
                    <CardContent>
                        <List>
                            <List.Item>
                                <List.Description>{t('category')}</List.Description>
                                {x.category}
                            </List.Item>
                            <List.Item>
                                <List.Description>{t('estimatedTime')}</List.Description>
                                {x.estimatedTime}
                            </List.Item>
                        </List>
                    </CardContent>
                </Card>
            ))}
        </>
    )
}

export default ObjectCasesPage
