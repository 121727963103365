import { TFunction } from "i18next";
import { DynamicField, DynamicFieldType } from "../common/dynamicFields";

const filterParams: {[module: string]: {[id:string]: DynamicField}} = {
    'projects': {
        'dateFrom': {
            value: '',
            type: DynamicFieldType.Date,
            required: true
        },
        'dateTo': {
            value: '',
            type: DynamicFieldType.Date,
            required: true
        }
    }
};

export const getFilterParams = (moduleKey: string): {[id:string]: DynamicField} => {
    return filterParams[moduleKey] ?? {};
}