import React, { useState } from 'react'
import { ArrowLeftCircle, Trash2 } from 'react-feather'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import { useTranslation } from 'react-i18next'

const ButtonContainer = styled.div``

export interface InlineConfirmDeleteProps {
    deleteButtonContent?: string | React.ReactNode
    cancelButtonContent?: string | React.ReactNode
    onCancel?: () => void
    onConfirm: () => void
}

const InlineConfirmDelete: React.FunctionComponent<InlineConfirmDeleteProps> = ({
    deleteButtonContent,
    cancelButtonContent,
    onCancel,
    onConfirm,
}) => {
    const [requestConfirm, setRequestConfirm] = useState(false)
    const { t } = useTranslation()
    return requestConfirm ? (
        <ButtonContainer>
            <Button
                content={deleteButtonContent}
                icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                negative
                title={t('delete')}
                onClick={() => {
                    setRequestConfirm(false)
                    onConfirm?.()
                }}
            />
            <Button
                content={cancelButtonContent}
                icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                title={t('cancel')}
                onClick={() => {
                    setRequestConfirm(false)
                    onCancel?.()
                }}
            />
        </ButtonContainer>
    ) : (
        <Button
            content={deleteButtonContent}
            title={t('delete')}
            icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
            onClick={() => setRequestConfirm(true)}
        />
    )
}

export default InlineConfirmDelete
