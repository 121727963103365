import { getAuthedAxios } from '../auth'
import { MODULES } from '../auth/modules'
import { Action } from '../reducer'
import { handleAxiosError } from './error'

const skillsStartupActions = (dispatch: React.Dispatch<Action>, modules: number[]) => {
    if (modules.indexOf(MODULES.SKILLS) === -1) return new Promise<void[]>(resolve => resolve([]))

    return getAuthedAxios().then(axios =>
        Promise.all([
            axios.get(window.env.REACT_APP_SKILL_SERVICE + '/api/Position/company').then(
                x =>
                    dispatch({
                        type: 'positionsLoaded',
                        companyPositions: x.data?.companyPositions ?? [],
                        concernPositions: x.data?.concernPositions ?? [],
                    }),
                handleAxiosError(dispatch),
            ),
        ]),
    )
}

export default skillsStartupActions
