import React from 'react'
import styled from 'styled-components'

export const ButtonContainer = styled.div`
    justify-self: flex-end;
    margin-top: auto;
    display: flex;
    justify-content: space-around;
`
const PoweredByContainer = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    font-family: Calibri;
    font-size: x-small;
    margin:2em 0;
    img {
        width: 130px;
    }
`
const SurveyContainer = styled.div`
    display: flex;
    position: relative; 
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    min-height: 100vh; 
    padding: 0 10px 90px 10px;
`

const HeaderImage = styled.img`
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
    margin: 1em auto 3em auto;
`

export interface SurveyBaseProps {
    imageUrl: string
}

const SurveyBase: React.FunctionComponent<SurveyBaseProps> = ({ imageUrl, children }) => {
    const finalImageUrl = imageUrl.startsWith('http')
        ? imageUrl
        : `${window.env.REACT_APP_WEBFORMS_URL}/kundundersokning/sidhuvud/${imageUrl}`

    return (
        <SurveyContainer>
            <HeaderImage src={finalImageUrl} />
            {children}
            <PoweredByContainer>
                <img src="/images/viqma-system-logo-dark.png" alt="VIQMA System" />
                Powered by VIQMA System AB
            </PoweredByContainer>
        </SurveyContainer>
    )
}

export default SurveyBase
