import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
    @media print {
        .ui.form textarea {
            display: none;
        }
        
        input, select, textarea, .ui.selection.dropdown {
            border: none !important;
            outline: none !important;
            padding:0 !important;
        }
        ::placeholder {
            color: transparent !important;
        }
        button, .button {
            display: none !important;
        }

        .ui.selection.dropdown>.dropdown.icon, .ui.form .required.field>label:after {
            display: none !important;
        }
        
        .show-in-print {
            display: block !important;
        }

        .hide-in-print{
            display: none !important;
        }

        a {
            color: black;
        }
    }

    .show-in-print {
        display: none;
    }

    .hidden {
        display: none !important;
    }

    .rdg {
        border-radius: 4px;

        .progress-cell {
            margin:1em 0 2.5em !important;
        }
    }
    .tr-negative {
            background-color: #fff6f6;
            color: #9f3a38;
    }

    .tr-positive {
            background-color: #fcfff5;
            color: #2c662d;
    }

    .tr-warning{
        background: #fffaf3;
        color: #573a08;
    }

    .tr-info{
        background-color: #f8ffff;
        color: #276f86;
    }

    
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker {
        font-size: 1em;
    }
    .react-datepicker__header {
        padding-top: 0.8em;
        border:none;
    }
    .react-datepicker__month {
        margin: 0.4em 1em;
    }
    .react-datepicker__day-name, .react-datepicker__day {
        width: 1.9em;
        line-height: 1.9em;
        margin: 0.166em;
    }
    .react-datepicker__current-month {
     font-size: 1em;
    }
    .react-datepicker__navigation {
        top: 0.5em;
        line-height: 1.7em;
    }

    .react-datepicker__today-button {
        background: #fff;
        text-align:right;
        color: #216ba5;
        margin: 0.4em 1em;
        border:none;
    }
    .react-datepicker__year-option:first-child,
    .react-datepicker__year-option:last-child {
        height: 2em;
        :hover {
            background-color: initial; 
            }
    }
    
    .react-datepicker__navigation--years-upcoming {
        transform: rotate(-45deg);
        border-color: #ccc;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        height: 9px;
        width: 9px;
    }

    .react-datepicker__navigation--years-previous {
        transform: rotate(135deg);
        border-color: #ccc;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        height: 9px;
        width: 9px;
    }

    .ui.message {
        background: rgb(249, 250, 251) !important;
    }

    .ui.modal > .close {
        color: black !important;
    }

    .ui.modals.inverted.dimmer {
        background-color: transparent;
    }

    .ui.ribbon.label {
    margin-bottom: 0.5em;
    }
    
    .clickable {
        cursor: pointer;
    }

    @media only screen and (min-width: 992px){
        .ui.modal > .close {
            right: 1rem;
            top: 1.0535rem;
        }
    }

    label[type=button] {
        -webkit-appearance: none !important;
    }

    /* * {
        outline: 1px solid red;
    } */

    .ui[class*="very basic"].table:not(.sortable):not(.striped) td:first-child, .ui[class*="very basic"].table:not(.sortable):not(.striped) th:first-child {
        padding-left: 0.3em;
        padding-right: 0.3em;
    }

    .ui.celled.grid {
        box-shadow: none;

        >.row {
            box-shadow: none;
        }
    }

    i.flag:not(.icon) {
        margin: unset;
    }
`

export default GlobalStyles
