import { TFunction } from 'i18next'
import { getAuthedAxios } from '../../auth'
import { MODULES } from '../../auth/modules'
import { RowInfoBasic, RowInfoFunction } from '../../components/start/rowInfo'
import { createUserNameDisplay, getUserFromState } from '../../state/helpers'
import { ApplicationState } from '../../state/initial'

export const getTabOrder = () => [
    MODULES.DOCS,
    MODULES.SKILLS,
    MODULES.ISSUES,
    MODULES.PROJECTS,
    MODULES.SYSTEMATIC_TASKS,
    MODULES.IMPROVEMENTS,
    MODULES.OBJECTS,
    MODULES.RISKS,
    MODULES.GOALS,
    MODULES.LAWS,
    MODULES.CHEMICALS,
    MODULES.SUPPLIERS,
]

export interface SummaryDataItem {
    subTitleKey: string
    rows: Record<string, string>[]
}

const filterRow = (state: ApplicationState, rowInfo: RowInfoBasic, row: Record<string, string>) => {
    if (!rowInfo.displayRowIfNoUserSet && rowInfo.userIdField?.some(field => !row[field]))
        return false

    if (rowInfo.hideRowIfUserIsInactive) {
        return (
            rowInfo.userIdField?.some(field => {
                const user = getUserFromState(state)(row[field])

                if (!rowInfo.displayRowIfNoUserSet && !user) return false

                return user?.active ?? true
            }) ?? true
        )
    }

    return true
}

export const getModuleSummary =
    (state: ApplicationState, t: TFunction, dataUri: string, getRowInfo: RowInfoFunction) =>
    (key: number) =>
        new Promise<SummaryDataItem[]>((resolve, reject) => {
            getAuthedAxios().then(axios =>
                axios
                    .get(dataUri)
                    .then(response => {
                        resolve(
                            response.data.map((section: SummaryDataItem) => {
                                const rowInfo = getRowInfo(section.subTitleKey, key, {})

                                return {
                                    ...section,
                                    rows: section.rows
                                        .filter(row => filterRow(state, rowInfo, row))
                                        .map(x => {
                                            const userIdFields = rowInfo.userIdField ?? [
                                                'responsibleId',
                                            ]

                                            const entries = Object.entries(x)

                                            userIdFields.forEach(userIdField => {
                                                const userNameField = userIdField.substring(
                                                    0,
                                                    userIdField.length - 2,
                                                )

                                                const userIdFieldIndex = entries.findIndex(
                                                    ([key]) => key === userIdField,
                                                )
                                                if (userIdFieldIndex !== -1) {
                                                    const newEntry = [
                                                        userNameField,
                                                        createUserNameDisplay(
                                                            state,
                                                            t,
                                                        )(x[userIdField] as string),
                                                    ] as [string, string]

                                                    entries.splice(
                                                        userIdFieldIndex + 1,
                                                        0,
                                                        newEntry,
                                                    )
                                                }
                                            })

                                            return Object.fromEntries(entries)
                                        }),
                                }
                            }),
                        )
                    })
                    .catch(reject),
            )
        })
