import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { ProcessHierarchyItem } from '../../../hooks/docs/process'
import { BackgroundColor } from '../../../styles'
import ProcessHierarchyDrawers from './processHierarchyDrawers'

const ColoredModalHeader = styled(Modal.Header)`
    background-color: ${BackgroundColor} !important;
    color: #efefef !important;
`

export interface ProcessHierarchyPopupProps {
    process?: ProcessHierarchyItem
}

const ProcessHierarchyPopup: React.FunctionComponent<ProcessHierarchyPopupProps> = ({
    process,
}) => {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(false)
    }, [location.pathname])

    useEffect(() => {
        if (!process) {
            onClose()
            return
        }

        setIsOpen(true)
    }, [process])

    const onClose = () => {
        setIsOpen(false)
    }

    /* Dimmer inverted will be overriden in globalStyles. We dont want any dimmer effect at all */
    /* Close icon will be overriden in globalStyles. Because we dont want dimmer, we need to keep it inside the modal on large screens*/
    return (
        <Modal open={isOpen} onClose={onClose} size="small" closeIcon dimmer="inverted">
            <ColoredModalHeader>
                <img src="/images/moduleicons/dokumentRutiner.svg" alt="" width="30" />
            </ColoredModalHeader>
            <Modal.Content scrolling>
                <ProcessHierarchyDrawers process={process} />
            </Modal.Content>
        </Modal>
    )
}

export default ProcessHierarchyPopup
