import styled from "styled-components"
import React from "react"
import { Control, Controller, FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Radio } from "semantic-ui-react"
import { IDropdownOption } from "../ControlDropdown"

const CustomLabel = styled.label`
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none
`
const RadioButtonContainer = styled.div<{ direction: string }>`
    display: flex;
    flex-direction: ${p => p.direction};
    gap: 0.4rem;
    border: 0.5px solid rgba(34, 36, 38, 0.178);
    padding: 0.5rem;
    justify-content: space-between;
    .ui.radio.checkbox label{
    padding-left: 1.2em;
    }
`

interface ControlRadioGroupProps<T extends FieldValues = any> {
    label: string
    name: string
    disabled: boolean
    control: Control<T>
    options: IDropdownOption[]
    direction?: "column" | "row" | "row-reverse"
}

const ControlRadioGroup: React.FunctionComponent<ControlRadioGroupProps> = ({
    label,
    name,
    options,
    control,
    disabled,
    direction = "column"
}) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                    <CustomLabel >{label}</CustomLabel>
                    <RadioButtonContainer direction={direction}>
                        {options.map((o, i) => (
                            <Radio
                                key={i}
                                error={!!error}
                                value={value}
                                label={t(`${o.text}`)}
                                name={name}
                                checked={value === o.value}
                                onChange={() => onChange(o.value)}
                                disabled={disabled}
                            />
                        ))}
                    </RadioButtonContainer>
                    {!!error &&
                        <div className="ui pointing above prompt label">
                            {t(`${error.message}`)}
                        </div>}
                </>
            )
            }
        />

    )
}
export default ControlRadioGroup