import React, { useEffect, useState } from 'react'
import { AnswerSubmissionDto, Survey, SurveySubmissionDto } from '../../../hooks/surveys/survey'
import SurveyDone from './surveyDone'
import SurveyInfoPrompt, { SurveyInfo } from './surveyInfoPrompt'
import { SurveyQuestionContainer } from './surveyQuestionContainer'
import SurveyWelcome from './surveyWelcome'

enum SurveyState {
    Welcome,
    EnterInfo,
    Questions,
    Finished,
}

export type SurveyAnswers = { [id: number]: AnswerSubmissionDto }

export interface SurveyMainProps {
    surveyGuid: string
    survey: Survey
    onQuestionsAnswered(submissionDto: SurveySubmissionDto): Promise<unknown>
}

const saveToLocalStorage = (
    surveyGuid: string,
    answers: SurveyAnswers,
    surveyinfo?: SurveyInfo,
) => {
    const json = JSON.stringify({ answers, surveyinfo })
    localStorage.setItem(`survey-${surveyGuid}`, json)
}

const tryLoadFromLocalStorage = (
    surveyGuid: string,
): { answers?: SurveyAnswers; surveyInfo?: SurveyInfo } => {
    const json = localStorage.getItem(`survey-${surveyGuid}`)
    if (!json) return {}

    return JSON.parse(json)
}

const SurveyMain: React.FunctionComponent<SurveyMainProps> = ({
    surveyGuid,
    survey,
    onQuestionsAnswered,
}) => {
    const [currentState, setCurrentState] = useState(SurveyState.Welcome)
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [answers, setAnswers] = useState<SurveyAnswers>({})
    const [surveyInfo, setSurveyInfo] = useState<SurveyInfo>()

    useEffect(() => {
        const { answers: existingAnswers, surveyInfo: existingInfo } =
            tryLoadFromLocalStorage(surveyGuid)

        if (existingAnswers) setAnswers(existingAnswers)
        if (existingInfo) setSurveyInfo(existingInfo)
    }, [surveyGuid])

    const saveAnswer = (answer: AnswerSubmissionDto, currentQuestion: number) => {
        const newAnswers = { ...answers, [currentQuestion]: answer }
        setAnswers(newAnswers)

        saveToLocalStorage(surveyGuid, newAnswers, surveyInfo)
    }

    const onStart = () => {
        setCurrentQuestion(0)
        setCurrentState(SurveyState.EnterInfo)
    }

    const onPrev = (answer: AnswerSubmissionDto) => {
        if (currentQuestion === 0) {
            setCurrentState(SurveyState.EnterInfo)
        } else {
            saveAnswer(answer, currentQuestion)
            setCurrentQuestion(currentQuestion - 1)
        }
    }

    const onNext = (answer: AnswerSubmissionDto) => {
        saveAnswer(answer, currentQuestion)
        setCurrentQuestion(currentQuestion + 1)
    }

    const onFinish = (answer: AnswerSubmissionDto) => {
        onNext(answer)
        return onQuestionsAnswered({
            answers: [...Object.values(answers), answer],
            ...surveyInfo,
        }).then(() => {
            setCurrentState(SurveyState.Finished)
            localStorage.removeItem(`survey-${surveyGuid}`)
        })
    }

    const onInfoEntered = () => {
        saveToLocalStorage(surveyGuid, answers, surveyInfo)
        setCurrentState(SurveyState.Questions)
    }

    if (currentState === SurveyState.Welcome) {
        return (
            <SurveyWelcome
                imageUrl={survey.imageUrl}
                content={survey.startText}
                onStart={onStart}
            />
        )
    } else if (currentState === SurveyState.EnterInfo) {
        return (
            <SurveyInfoPrompt
                survey={survey}
                currentInfo={surveyInfo}
                setInfo={setSurveyInfo}
                onDone={onInfoEntered}
            />
        )
    } else if (currentState === SurveyState.Finished) {
        return <SurveyDone imageUrl={survey.imageUrl} content={survey.endText} />
    } else if (currentState === SurveyState.Questions && survey.questions[currentQuestion]) {
        return (
            <SurveyQuestionContainer
                survey={survey}
                currentQuestion={currentQuestion}
                question={survey.questions[currentQuestion]}
                existingAnswer={answers[currentQuestion]}
                onNext={currentQuestion < survey.questions.length - 1 ? onNext : undefined}
                onPrev={onPrev}
                onFinish={currentQuestion === survey.questions.length - 1 ? onFinish : undefined}
            />
        )
    } else if (currentState === SurveyState.Questions && survey.questions.length === 0) {
        return <SurveyDone imageUrl={survey.imageUrl} content={'No active questions to answer.'} />
    } else {
        return <></>
    }
}

export default SurveyMain
