import React from 'react'
import styled from 'styled-components'
import { Message, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export interface ErrorProps {
    message: string
    topic?: string
    standAlone?: boolean
}

interface ErrorContainerProps {
    standAlone?: boolean
}

const ErrorContainer = styled.div<ErrorContainerProps>`
    ${props =>
        props.standAlone
            ? `
        margin: 20px auto 0 auto;
        max-width: 500px;
    `
            : ``}
`

const Error: React.FunctionComponent<ErrorProps> = ({ topic, message, standAlone }) => {
    const { t } = useTranslation()

    const messageString = typeof message === 'string' ? message : JSON.stringify(message)

    return (
        <ErrorContainer standAlone={standAlone}>
            <Message error={true}>
                <>
                    {topic ? <Header content={t(topic)} /> : null}
                    {t(messageString)}
                </>
            </Message>
        </ErrorContainer>
    )
}

export default Error
