import React from "react"
import { formatDate } from "../../../../actions/util"
import { Popup } from "semantic-ui-react"
import styled from "styled-components"

const StyledDateSpan = styled.span<{ error: boolean }>`
    color: ${p => p.error && "red"};
`
interface IDateCellProps {
    date: string | Date | null
    error?: boolean
}

const DateCell = ({ date, error = false }: IDateCellProps) => {
    const dateContent = date && date !== "0001-01-01T00:00:00Z" ? formatDate(date) : ""
    return (
        <Popup
            content={dateContent}
            trigger={
                <StyledDateSpan error={error}>
                    {dateContent}
                </StyledDateSpan>
            }
        />

    )
}
export default DateCell
