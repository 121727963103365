import React from 'react'
import EditView from '../common/editView'
import { DynamicField, DynamicFieldType } from '../common/dynamicFields'
import { getState } from '../../state'

const AdminUsersPage: React.FunctionComponent = () => {
    const {
        state: { allUsers: items },
    } = getState()

    const displayProps = ['name', 'username', 'email', 'phone', 'language']
    const editProps: { [id: string]: DynamicField } = {
        name: {
            required: true,
            type: DynamicFieldType.String,
            value: '',
        },
        username: {
            required: true,
            type: DynamicFieldType.String,
            value: '',
        },
        email: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        phone: {
            required: true,
            type: DynamicFieldType.String,
            value: '',
        },
        language: {
            required: true,
            type: DynamicFieldType.String,
            value: '',
        },
    }

    const onSave = (item: any, isNew: boolean) => {
        return new Promise<void>(resolve => resolve())
    }

    const onDelete = (item: any) => {}

    return (
        <EditView
            displayProps={displayProps}
            editProps={editProps}
            items={items}
            onSave={onSave}
            onDelete={onDelete}
        />
    )
}

export default AdminUsersPage
