import { IUserListViewDto, ICompetenceUserRequest, IUserSkillsDto } from "../../../api/skills";

export const mapUserToCompetenceUserRequest = (data: IUserListViewDto): ICompetenceUserRequest => ({
    id: data.id,
    departmentId: data.departmentId,
    locationId: data.locationId,
    positionIds: data.positions?.map(d => d.id) ?? [],
    skillIds: data.skills?.map(d => d.id) ?? [],
});

export const mapUserSkillRequest = (data: IUserSkillsDto): IUserSkillsDto => ({
    id: data.id,
    name: data.name,
    validFrom: data.validFrom ? new Date(data.validFrom) : null,
    validTo: data.validTo ? new Date(data.validTo) : null,
    lastChanged: data.lastChanged ? new Date(data.lastChanged) : null,
    competenceLevel: data.competenceLevel
});

