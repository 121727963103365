import React, { useState, useEffect, FunctionComponent } from 'react'
import { DynamicField, DynamicFieldType } from '../common/dynamicFields'
import EditView from '../common/editView'
import { getAuthedAxios } from '../../auth'
import { handleAxiosError } from '../../actions/error'
import { getState } from '../../state'
import { useTranslation } from 'react-i18next'

const AdminCompaniesPage: FunctionComponent = () => {
    const { dispatch } = getState()
    const { t } = useTranslation()
    const [companies, setCompanies] = useState<any[]>()

    const displayProps = ['name']
    const editProps: { [id: string]: DynamicField } = {
        name: {
            required: true,
            type: DynamicFieldType.String,
            value: '',
        },
        contact: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        email: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        fax: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        phoneNumber: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        mobile: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        switchboard: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        visitingAddress: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        webpage: {
            required: false,
            type: DynamicFieldType.String,
            value: '',
        },
        country: {
            required: false,
            type: DynamicFieldType.GenericDropdown,
            value: '',
            dropdownItems: [
                { value: 'sweden', text: t('sweden') },
                { value: 'england', text: t('england') },
            ],
        },
        displayRelatives: {
            required: false,
            type: DynamicFieldType.Boolean,
            value: '',
        },
        isInactive: {
            required: false,
            type: DynamicFieldType.Boolean,
            value: '',
        },
    }

    useEffect(() => {
        getAuthedAxios().then(axios =>
            axios
                .get(`${window.env.REACT_APP_COMPANY_SERVICE}/api/company`)
                .then(x => setCompanies(x.data))
                .catch(handleAxiosError(dispatch)),
        )
    }, [dispatch])

    const onSave = (item: any, isNew: boolean) => {
        return new Promise(resolve => resolve(() => {}))
    }

    const onDelete = (item: any) => {}

    return (
        <EditView
            displayProps={displayProps}
            editProps={editProps}
            items={companies ?? []}
            onSave={onSave}
            onDelete={onDelete}
        />
    )
}

export default AdminCompaniesPage
