import Oidc from 'oidc-client'
import axios, { AxiosInstance } from 'axios'
import { getConfig } from './config'
import { isDevelopment, isInPageRenderMode } from '../actions/util'
import { doDelegatedLogin, removedStoredToken } from './delegated'

let _mgr: Oidc.UserManager
const getMgr = () => {
    if (!_mgr) {
        Oidc.Log.logger = console
        Oidc.Log.level = isDevelopment() ? Oidc.Log.DEBUG : Oidc.Log.WARN

        const baseConfig = getConfig()
        const dynamicConfig = getDynamicConfig()

        _mgr = new Oidc.UserManager({ ...baseConfig, ...dynamicConfig })
    }

    return _mgr
}

export const getUser = () => getMgr().getUser()

const getDynamicConfig = () => {
    const appCompanyId = (window as any).companyId

    if (appCompanyId) {
        console.debug('Setting requested companyId')

        return {
            acr_values: `tenant:requestedCompanyId=${appCompanyId}`,
        }
    } else return {}
}

export const login = () => getMgr().signinRedirect()

export const loginSilent = () => getMgr().signinSilent()

export const logout = () => {
    if (isInPageRenderMode()) return getMgr().signoutRedirect()
    else window.location.href = '/logout.aspx'
}

export const handleCallback = () => {
    if (window.location.pathname === '/callback') {
        return new Oidc.UserManager({ response_mode: 'query', automaticSilentRenew: true })
            .signinRedirectCallback()
            .then(function () {
                window.location.href = '/'
            })
    } else if (window.location.pathname === '/callback-silent') {
        return new Oidc.UserManager({ response_mode: 'query', automaticSilentRenew: true })
            .signinSilentCallback()
            .then(function () {
                window.location.href = '/'
            })
    } else {
        return new Promise<void>(resolve => resolve())
    }
}

export const hasProfileCompanyIdMissmatch = (profile: Oidc.Profile) => {
    const appCompanyId = (window as any).companyId

    if (!appCompanyId) return false

    return profile.CompanyId !== appCompanyId
}

export const getAuthedAxios = () => {
    return new Promise<AxiosInstance>((resolve, reject) => {
        if (isInPageRenderMode()) {
            getUser().then(user => {
                const createAxiosFromUser = (user: Oidc.User) => {
                    const appCompanyId = (window as any).companyId
                    const params = appCompanyId ? { companyId: appCompanyId } : {}

                    const instance = axios.create({
                        params,
                    })

                    instance.defaults.headers.common.authorization = `Bearer ${user?.access_token}`

                    resolve(instance)
                }

                if (!user) {
                    reject()
                    return
                }

                if (user?.expired) loginSilent().then(user => createAxiosFromUser(user))
                //TODO: Fix this, the idea is the client should request a new token under the correct CompanyId.
                // However it doesn't seem that the acr_values are persisted long enough for the token endpoint
                // to actuelly return a correct token. Right now we are faking it by appending a &companyId=xxx
                // to every API request. The API's will then validate the id and return the correct data.
                // It works but it is not right and leads to this app living in "denial" about which company it actually represents
                //
                // else if (hasProfileCompanyIdMissmatch(user!.profile))
                //     login();
                else createAxiosFromUser(user!)
            })
        } else {
            doDelegatedLogin()
                .then(x => {
                    const instance = axios.create()
                    instance.defaults.headers.common.authorization = `Bearer ${x.token}`
                    resolve(instance)
                })
                .catch(reject)
        }
    })
}

export const registerLogoutHandler = () => {
    if (isInPageRenderMode()) return

    const logoutLink = document.getElementById('ctl00_logga1_LoginStatus1')
    logoutLink?.addEventListener('click', () => {
        const searchString = 'oidc.'

        const removeAuthInfoFromStorage = (storage: any) => {
            for (let i = 0; i < storage.length; i++) {
                const key = storage.key(i)
                if (key?.indexOf(searchString) !== -1) storage.removeItem(key!)
            }
        }

        removeAuthInfoFromStorage(sessionStorage)
        removeAuthInfoFromStorage(localStorage)

        removedStoredToken()
    })
}
