import React from 'react'
import { Header } from 'semantic-ui-react'
import styled from 'styled-components'

export interface IPrintLabelValueProps {
    label: string
    value: string
    orientation?: 'horizontal' | 'vertical'
}

const GroupContainer = styled.div<{ orientation: string }>`
    display: flex;
    span {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    }
    
    ${props => props.orientation === 'horizontal'
        ? `
        flex-direction: row;

        span {
            margin-top: -3px;
            margin-left: 5px;
        }`
        : `flex-direction: column;`
    }
`

const PrintLabelValue = ({ label, value, orientation = "horizontal" }: IPrintLabelValueProps) => {
    return (
        <GroupContainer orientation={orientation}>
            <Header size="small" content={`${label}:`} />
            <span>{value}</span>
        </GroupContainer>
    )
}

export default PrintLabelValue
