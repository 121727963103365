import * as API from "../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../actions/error"
import { getState } from "../../../../../state"
import { IssueStatus } from "../../../../../api/types/Enum"

interface INotifyIssue {
    id: number
    status: IssueStatus
}

export function useDeviationReminder() {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const onIssueRemind = async (data: INotifyIssue) => data.status === IssueStatus.Evaluate
        ? await API.issues.notifyIssueEvaluateReminder(data.id)
        : await API.issues.notifyIssueReminder(data.id)

    const { isLoading: isSending, mutate: onNotify } = useMutation(onIssueRemind, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })

    return {
        isSending,
        onNotify,
    }
}