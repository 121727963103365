import { useEffect } from 'react'
import { useCrud } from '../crud/use-crud'

export type ConnectedRiskType = 'document' | 'goal'

export type RiskItemDto = {
    id: number
    companyId: number
    documentId: number
    aspect: string
    impact: string
    apt: string
    degree: string
}

const getFetchPathForType = (connectedId: number, type: ConnectedRiskType) =>
    type === 'document'
        ? `${window.env.REACT_APP_RISK_SERVICE}/api/Risks/document/${connectedId}`
        : `${window.env.REACT_APP_RISK_SERVICE}/api/Risks/goal/${connectedId}`

export const useConnectedRisks = (connectedId: number, type: ConnectedRiskType) => {
    const { fetch } = useCrud<RiskItemDto>(
        getFetchPathForType(connectedId, type),
        `${window.env.REACT_APP_RISK_SERVICE}/api/risk`,
        'id',
    )
    const data: RiskItemDto[] = fetch.data || []
    useEffect(() => {
        if (fetch.error) {
            console.log('ERROR :D: D ', fetch.error)
        }
    }, [fetch.error])
    return {
        loading: fetch.loading,
        data,
    }
}
