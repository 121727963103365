import React from 'react'
import { Progress } from 'semantic-ui-react';

export interface ProgressCellProps {
    percentage: number
}

const ProgressCell = ({ percentage }: ProgressCellProps) => {
    return (
        <Progress
            color={percentage >= 80 ? 'green' : 'grey'}
            progress="percent"
            percent={percentage}
            className='progress-cell'

        />
    )
}

export default ProgressCell
