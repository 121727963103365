import React, { useEffect } from 'react'
import { Button, Form, Grid, GridColumn, GridRow, Message } from 'semantic-ui-react'
import Loading from '../../../../common/Loading'
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import * as API from '../../../../../api'
import { useForm } from 'react-hook-form';
import { IAccidentAuthoritySetting } from '../../../../../api/issues';
import ControlInput from '../../../../fields/ControlInput';
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon';
import { Save } from 'react-feather';
import { getState } from '../../../../../state';
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../../../actions/error';
import { toast } from 'react-toastify';
import ControlTextArea from '../../../../fields/ControlTextArea';

const accidentInfoSchema = z.object({
    id: z.number(),
    companyId: z.number(),
    message: z.string().optional(),
    link: z.union([z.string().url("invalidUrl"), z.literal('')]).optional(),
});

const AccidentAuthority = () => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()

    const {
        isLoading,
        data: accidentAuthority,
        refresh
    } = useGetQueryByParams(true, API.issues.getAccidentAuthoritySetting, "accidentAuthoritySettings")

    const { control, handleSubmit, reset } = useForm<IAccidentAuthoritySetting>({
        resolver: zodResolver(accidentInfoSchema)
    });

    useEffect(() => {
        if (accidentAuthority) {
            reset(accidentAuthority);
        } else {
            reset({ id: 0, message: "", link: "", companyId: 0 });
        }
    }, [accidentAuthority, reset]);

    const onAccidentAuthorityRequest = async (data: IAccidentAuthoritySetting) => {
        if (accidentAuthority?.id) {
            return await API.issues.editAccidentAuthority(accidentAuthority.id, data);
        } else {
            return await API.issues.createAccidentAuthority(data);
        }
    };

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onAccidentAuthorityRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            refresh()
        },
    })


    if (isLoading) {
        return <Loading />
    }

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <Message info>
                            <Message.Header>
                                {t('deviationSettings.incidentMessageHeader')}
                            </Message.Header>
                            {t('deviationSettings.incidentMessageBody')}
                        </Message>
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlTextArea name="message" label={t('reportingMessage')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput name="link" label={t('reportingLink')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1" textAlign='right'>
                    <GridColumn >
                        <Button
                            type="submit" primary disabled={isSaving}
                            icon
                            labelPosition="left">
                            <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />
                            {t('save')}
                        </Button>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
}

export default AccidentAuthority
