import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { logout } from '../../auth'
import { getState } from '../../state'
import { BackgroundColor } from '../../styles'
import i18n from '../../i18n'
import { Button, Flag, Icon } from 'semantic-ui-react'
import { useMobileMode } from '../../hooks/mobileMode'

const MobileMenuBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
    span {
        margin: 5px 0 0 5px;
    }
`

const MenuBarButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

const MobilePageOverlay = styled.div`
    box-sizing: content-box;
    position: absolute;
    background: white;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* overflow: hidden;
    overscroll-behavior: none;
    overscroll-behavior-block: contain; */
`

const Header = styled.div`
    background-color: ${BackgroundColor};
    color: white;
    padding-bottom: 10px;
`

const Logo = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 30px;
    background-color: white;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    padding: 5px;

    img {
        max-width: 150px;
    }
`

const ContentContainer = styled.div`
    padding: 10px;
    box-sizing: border-box;
    flex-grow: 1;
`

const Footer = styled.div`
    background-color: ${BackgroundColor};
    color: white;
    flex-basis: 45px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;

    img {
        height: 30px;
    }
`

const MobilePageContainer: React.FunctionComponent = ({ children }) => {
    const {
        state: { userProfile, companyLogoPath, company },
    } = getState()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { setMobileMode } = useMobileMode()
    const handleToggleMobileMode = () => {
        navigate('/')
        setMobileMode(false)
    }
    return (
        <MobilePageOverlay>
            <Header>
                <MobileMenuBar>
                    <span>
                        {t('signedInAs')}: {userProfile?.name ?? userProfile?.email}
                    </span>

                    <MenuBarButtonContainer>
                        {i18n.language === 'en' ? (
                            <Button
                                type='button'
                                icon={<Flag name="gb" />}
                                onClick={() => i18n.changeLanguage('sv')}
                            />
                        ) : (
                            <Button
                                type='button'
                                icon={<Flag name="sweden" />}
                                onClick={() => i18n.changeLanguage('en')}
                            />
                        )}
                        <Button
                            type='button'
                            icon="computer"
                            onClick={handleToggleMobileMode}
                        />
                        <Button
                            type='button'
                            icon="sign out"
                            onClick={logout}
                        />
                    </MenuBarButtonContainer>
                </MobileMenuBar>

                <Logo>
                    <Link to="/">
                        <img src={companyLogoPath} alt={`Logo for ${company?.name}`} />
                    </Link>
                </Logo>
            </Header>

            <ContentContainer>
                <Outlet />
                {children}
            </ContentContainer>

            <Footer>
                <img src="/images/viqma-system-logo.png" alt="Viqma logo" />

                <span>Powered by Viqma System</span>
            </Footer>
        </MobilePageOverlay>
    )
}

export default MobilePageContainer
