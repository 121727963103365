import React from 'react'
import { Button, Form, FormDropdown, FormInput, FormTextArea, Grid, GridColumn, GridRow } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import * as API from '../../../api'
import { IPublicSupplierDeviationResponse, ISupplierDeviationRequest } from '../../../api/suppliers'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { Save } from 'react-feather'
import ControlTextArea from '../../fields/ControlTextArea'
import { useMutation } from 'react-query'
import { handleAxiosError } from '../../../actions/error'
import { toast } from 'react-toastify'
import { getState } from '../../../state'
import { useForm } from 'react-hook-form'
import { formatDate } from '../../../actions/util'
import styled from 'styled-components'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import BackchannelFiles from '../../common/backchannelFiles'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import Loading from '../../common/Loading'

const CustomDisabledGrid = styled(Grid)`
    .disabled.field {
    opacity: 0.8 !important;
        >label{
        opacity: 0.8 !important;
        }
        .ui.disabled.input{
            opacity: 0.8 !important;
        }
            .ui.disabled.dropdown{
            opacity: 0.8 !important;
            }
    }
`
const StyledGridColumn = styled(GridColumn)`
    margin: 1em 0 !important;
`
interface ISupplierDeviationFormProps {
    response: IPublicSupplierDeviationResponse
    refresh: () => void
}
export const supplierDeviationRequestSchema = z.object({
    analysis: z.string().min(1, "required"),
    resolvingAction: z.string().min(1, "required"),
    companyId: z.coerce.number().min(1, { message: "required" }),

});

const SupplierDeviationForm = ({ response, refresh }: ISupplierDeviationFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<ISupplierDeviationRequest>({
        defaultValues: {
            analysis: response.deviation.analysis,
            resolvingAction: response.deviation.resolvingAction,
            companyId: response.company.id
        },
        resolver: zodResolver(supplierDeviationRequestSchema),
    });

    const {
        isLoading: isLoadingFiles,
        data: files
    } = useGetQueryByParams(true, API.suppliers.getSupplierDeviationFiles, "supplierDeviation", { module: "Issues", deviationId: response.deviation.id, companyId: response.company.id, concernId: response.company.concernId })

    const editUser = async (data: ISupplierDeviationRequest) => await API.suppliers.editSupplierDeviation(data, response.supplier.id, response.deviation.id)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(editUser, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            refresh()
        },
    })
    if (isLoadingFiles || !files) {
        return <Loading />
    }

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <CustomDisabledGrid stackable>
                <GridRow columns="2">
                    <GridColumn>
                        <FormInput
                            label={t("supplier")}
                            value={response.supplier.name}
                            disabled
                        />
                    </GridColumn>
                    <GridColumn>
                        <FormInput
                            label={t("ownId")}
                            value={response.deviation.ownId}
                            disabled
                        />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <GridColumn>
                            <FormInput
                                label={t("topic")}
                                value={response.deviation.topic}
                                disabled
                            />
                        </GridColumn>
                    </GridColumn>
                    <GridColumn>
                        <GridColumn>
                            <FormInput
                                label={t("reportDate")}
                                value={response.deviation.reportDate ? formatDate(response.deviation.reportDate) : ""}
                                disabled
                                style={{ opacity: ".8" }}
                            />
                        </GridColumn>
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    {response.deviation.extraFields ? Object.keys(response.deviation.extraFields).map(key => {
                        const value = response.deviation.extraFields[key];
                        if (Array.isArray(value)) {
                            return <StyledGridColumn key={key}><FormDropdown label={key} value={value} multiple disabled options={value.map(d => ({ value: d, text: d })) ?? []} /></StyledGridColumn>;
                        }
                        return <StyledGridColumn key={key}><FormInput
                            label={key}
                            value={value || ''}
                            disabled
                        /></StyledGridColumn>;
                    }) : null}
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <FormTextArea
                            style={{ pointerEvents: "auto" }}
                            label={t("description")}
                            value={response.deviation.description}
                            disabled
                        />
                    </GridColumn>
                    <GridColumn>
                        <FormTextArea
                            style={{ pointerEvents: "auto" }}
                            label={t("actionsTakenInfo")}
                            value={response.deviation.actionsTaken}
                            disabled
                        />
                    </GridColumn>
                </GridRow>
                {files.length > 0 ? <GridRow columns="1">
                    <GridColumn>
                        <BackchannelFiles files={files} module='Issues' disabled={isSaving} />
                    </GridColumn>
                </GridRow> : null}
                <GridRow columns="2">
                    <GridColumn>
                        <ControlTextArea name="analysis" label={t('analysis')} control={control} disabled={isSaving} required />
                    </GridColumn>
                    <GridColumn>
                        <ControlTextArea name="resolvingAction" label={t('resolvingAction')} control={control} disabled={isSaving} required />
                    </GridColumn>
                </GridRow>

                <GridRow>
                    <GridColumn>

                        <Button
                            primary
                            floated="right"
                            content={t('save')}
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Save}
                                />
                            }
                            type={'submit'}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </CustomDisabledGrid>
        </Form>
    )
}

export default SupplierDeviationForm
