import { useTranslation } from 'react-i18next'
import { getState } from '../state'
import { createUserNameDisplay, getUserFromState, getUserOptionsFromState, getUserOptionsFromStateOverride } from '../state/helpers'
import { User } from '../state/initial'
import useApi from './useApi'
import { TFunction } from 'i18next'

export const useUserOptions = (
    predicate: (user: User) => boolean = _ => true,
    spliceInUserId?: string,
) => {
    const { state } = getState()
    return getUserOptionsFromState(state)(predicate, spliceInUserId)
}

export const useUserOptionsOverride = (
    t: TFunction,
    predicate: (user: User) => boolean = _ => true,
    spliceInUserId?: string,
    includeVirtual?: boolean
) => {
    const { state } = getState()
    return getUserOptionsFromStateOverride(state)(t, predicate, spliceInUserId, includeVirtual)
}

export const useUserInRoleOptions = (
    roleName: string,
    predicate: (user: User) => boolean = _ => true,
    concern: boolean = false,
) => {
    const { data, loading, error } = useApi<User[]>(
        `${window.env.REACT_APP_AUTH_SERVICE}/api/account/Info/users?role=${roleName}&concern=${concern}`,
    )

    const userOptions = (data ?? [])
        .filter(predicate)
        .map(d => ({
            key: d.id,
            text: d.name,
            value: d.id,
        }))
        .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))

    return {
        loading,
        error,
        userOptions,
    }
}

export const useUserWithConcernLookup = (userId: string, possibleConcernUser: boolean) => {
    const ownCompanyUser = useUser(userId)

    const performConcernLookup = possibleConcernUser && !ownCompanyUser && userId

    const { data, loading, error } = useApi<User>(
        performConcernLookup
            ? `${window.env.REACT_APP_AUTH_SERVICE}/api/account/Info/users/concern/${userId}`
            : undefined,
    )

    return {
        user: performConcernLookup ? data : ownCompanyUser,
        loading: performConcernLookup ? loading : false,
        error,
    }
}

export const useUser = (userId: string, includeVirtual = false) => {
    const { state } = getState()
    return getUserFromState(state)(userId, includeVirtual)
}

export const useUserName = (userId: string) => {
    const { state } = getState()
    const { t } = useTranslation()
    const userName = createUserNameDisplay(state, t)(userId)
    return userName
}
