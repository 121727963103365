import useSWR from 'swr'
import { applyFilters } from '../../filtering/filter'
import useApi from '../useApi'
import { Decision } from './decisions'
import { useGoalMeasurement } from './measurement'

export interface Goal {
    id?: number
    parentId?: number
    responsibleId?: string
    departmentId?: number
    locationId?: number
    title?: string
    economy?: boolean
    workingEnvironment?: boolean
    quality?: boolean
    social?: boolean
    startDate?: string
    latestDate?: string
    description?: string
    activities?: string
    reviewDate?: string
    reviewBy?: string
    reviewResult?: string
    status?: number
    endedDate?: string
    endedBy?: string
    done?: boolean
    decisions?: Decision[]
    targetValue?: number
    connectedMeasurement?: ConnectedMeasurementInfo
    initialValueId?: number
    isKpi?: boolean
    numActivities?: number
    hasOpenChildren?: boolean
}

export interface ConnectedMeasurementInfo {
    id: number
    isStandard: boolean
}

export interface GoalWithMeasurementInfo extends Goal {
    initialValue?: number
    currentValue?: number
    unit?: string
}

export const useGoal = (goalId?: string) => {
    const { mutate: mutateGoals } = useGoals()
    const { mutate: mutateMeasurements } = useGoalMeasurement(goalId)

    const { data, post, loading, error } = useApi<GoalWithMeasurementInfo, Goal>(
        goalId ? `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${goalId}` : undefined,
        {
            setDataFromPost: true,
        },
    )

    return {
        data,
        loading,
        error,
        save: (goal: Goal, parentId?: string) =>
            goal.id
                ? post(goal, `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${goal.id}`).then(
                      goal => {
                          mutateGoals()
                          mutateMeasurements()
                          return goal
                      },
                  )
                : post({ ...goal, parentId }, `${window.env.REACT_APP_GOAL_SERVICE}/api/goal`).then(
                      goal => {
                          mutateGoals()
                          mutateMeasurements()
                          return goal
                      },
                  ),
    }
}

export const useGoals = (filters?: string[]) => {
    const { data, isValidating, mutate, error } = useSWR<GoalWithMeasurementInfo[]>(
        applyFilters(`${window.env.REACT_APP_GOAL_SERVICE}/api/goal`, filters),
    )

    const { post, error: postError, loading: postLoading, doDelete } = useApi(undefined)

    return {
        data,
        isValidating,
        mutate,
        error: error ?? postError,
        postLoading,
        move: (id: number, newParentid?: number) =>
            post(
                { parentId: newParentid },
                `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${id}/move`,
            ).then(() => mutate()),
        deleteGoal: (id: number) =>
            doDelete(`${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${id}`).then(() => mutate()),
    }
}
