import { getAuthedAxios } from '../auth'

export type SuggestDocumentChangeDto = {
    content: string
    approver: string
    comment: string
}
export const suggestChange = async (documentId: string, suggestion: SuggestDocumentChangeDto) => {
    const axios = await getAuthedAxios()

    await axios.post(`${window.env.REACT_APP_DOC_SERVICE}/api/${documentId}/suggestion`, {
        suggestion,
    })
}

export const updateResponsiblePositions = async (
    documentId: number,
    responsiblePositions: number[],
) => {
    const axios = await getAuthedAxios()
    await axios.post(
        `${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}/responsible`,
        responsiblePositions,
    )
}
