import React, { useEffect, useState } from 'react'
import {
    Form,
    Header,
    Loader,
    Modal,
    ModalContent,
    ModalHeader,
    Placeholder,
    Popup,
} from 'semantic-ui-react'
import { DocumentListing } from '../../../../hooks/docs/listing'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Info } from 'react-feather'
import { useDocumentVersion, useDocumentVersions } from '../../../../hooks/docs/versions'
import DiffDisplay from '../../../common/diffDisplay'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { formatDate } from '../../../../actions/util'
import { IViqmaDocument } from '../../../../api/documents'

export interface DocumentVersionModalProps {
    currentDocument: IViqmaDocument
}

const versionsToOptions =
    (t: TFunction) => (versions: DocumentListing[], currentDocumentVersion: number) =>
        versions.map(v => ({
            id: v.id,
            value: v.version!,
            text: `${t('version')} ${v.version}${v.version === currentDocumentVersion
                ? ` (${t('latest')})`
                : ` (${formatDate(v.lastChanged)})`
                }`,
        }))

const DocumentVersionModal: React.FunctionComponent<DocumentVersionModalProps> = ({
    currentDocument,
}) => {
    const { t } = useTranslation()

    const { data: versions, isValidating: versionsLoading } = useDocumentVersions(
        currentDocument.id,
    )

    const [fromVersion, setFromVersion] = useState<number>()
    const [toVersion, setToVersion] = useState<number>()

    const { data: fromDocument, isValidating: fromLoading } = useDocumentVersion(
        currentDocument.id,
        versionsLoading ? undefined : fromVersion,
    )
    const { data: toDocument, isValidating: toLoading } = useDocumentVersion(
        currentDocument.id,
        versionsLoading ? undefined : toVersion,
    )

    useEffect(() => {
        setFromVersion(
            versions && versions?.length >= 2
                ? versions![versions!.length - 2].version!
                : undefined,
        )
        setToVersion(
            versions && versions?.length > 0 ? versions![versions!.length - 1].version! : undefined,
        )
    }, [versions])

    if (versionsLoading) return <Loader active={true} inline size="tiny" />

    if (!versions?.length) return null

    return (
        <Modal
            trigger={<span><Popup
                content={t('compareAndDisplayVersions')}
                trigger={

                    <SemanticFeatherIcon FeatherIcon={Info} cursor="pointer" />

                }
            /></span>}
            size="large"
            closeIcon
        >
            <ModalHeader>
                <Header>{t('compareAndDisplayVersions')}</Header>

                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            fluid
                            label={t('compareFrom')}
                            options={versionsToOptions(t)(versions, currentDocument.version)}
                            value={fromVersion}
                            onChange={(_, data) => setFromVersion(data.value as number)}
                        />

                        <Form.Select
                            fluid
                            label={t('compareTo')}
                            options={versionsToOptions(t)(versions, currentDocument.version)}
                            value={toVersion}
                            onChange={(_, data) => setToVersion(data.value as number)}
                        />
                    </Form.Group>
                </Form>
            </ModalHeader>
            <ModalContent scrolling>
                {fromLoading || toLoading ? (
                    <Placeholder fluid>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>
                ) : (
                    <DiffDisplay
                        oldValue={fromDocument?.content ?? ''}
                        newValue={toDocument?.content ?? ''}
                    />
                )}
            </ModalContent>
        </Modal>
    )
}

export default DocumentVersionModal
