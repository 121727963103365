import React from 'react'
import FlexBox from '../../../common/flexBox'
import SelectedButton from '../../../common/selectedButton'
import { QuestionTypeProps } from './questionTypeProps'

const YesNoDontKnowQuestion: React.FunctionComponent<QuestionTypeProps> = ({
    survey,
    question,
    currentAnswer,
    onAnswer,
}) => {
    const { yesText, noText, dontKnowText } = survey

    return (
        <FlexBox gap="2em" justifyContent="center">
            <SelectedButton selected={currentAnswer === 0} onChange={() => onAnswer(0)}>
                {yesText}
            </SelectedButton>

            <SelectedButton selected={currentAnswer === 1} onChange={() => onAnswer(1)}>
                {noText}
            </SelectedButton>

            {question.addDontKnow && (
                <SelectedButton selected={currentAnswer === 2} onChange={() => onAnswer(2)}>
                    {dontKnowText}
                </SelectedButton>
            )}
        </FlexBox>
    )
}

export default YesNoDontKnowQuestion
