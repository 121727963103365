import React from 'react'
import { Input } from 'semantic-ui-react'
import DatePicker, { registerLocale } from 'react-datepicker'
import moment, { isDate } from 'moment'
import { useTranslation } from 'react-i18next'

import en from 'date-fns/locale/en-US'
import sv from 'date-fns/locale/sv'

registerLocale('en', en)
registerLocale('sv', sv)

export interface DateOnChangeData {
    value: string | null
    moment: moment.Moment | null
}

export interface DateInputProps {
    value?: Date | string
    required?: boolean
    clearable?: boolean
    readOnly?: boolean
    onChange?(event: React.ChangeEvent, data: DateOnChangeData): void
}

const getDate = (value?: Date | string) => {
    if (!value) return null
    if (isDate(value)) return value as Date

    try {
        return new Date(value)
    } catch {
        return null
    }
}

const DateInput: React.FunctionComponent<DateInputProps> = ({ value, onChange, required, clearable, readOnly }) => {
    const { i18n } = useTranslation()

    const languageParts = i18n.language.split('-')

    const selectedDate = getDate(value)

    const handleOnChange = (date: Date, e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange) return
        if (!date) onChange(e, { moment: null, value: null })

        const newDate = moment(date)
        if (newDate.isValid()) onChange(e, { moment: newDate, value: newDate.format('Y-MM-DD') })
    }

    const isClearable = clearable === null || clearable === undefined ? true : clearable;

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleOnChange}
            required={required}
            locale={languageParts[0]}
            isClearable={readOnly ? false : isClearable}
            dateFormat="yyyy-MM-dd"
            showWeekNumbers={true}
            showMonthDropdown
            showYearDropdown
            readOnly={readOnly}
            customInput={<Input />}
            autoComplete="off"
        />
    )
}

export default DateInput
