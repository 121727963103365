import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import SurveyBase, { ButtonContainer } from './surveyBase'

const WelcomeContainer = styled.div`
    div {
        margin-bottom: 2em;
    }
`

export interface SurveyWelcomeProps {
    imageUrl: string
    content: string
    onStart(): void
}

const SurveyWelcome: React.FunctionComponent<SurveyWelcomeProps> = ({
    imageUrl,
    content,
    onStart,
}) => {
    const { t } = useTranslation()

    return (
        <SurveyBase imageUrl={imageUrl}>
            <WelcomeContainer>
                <div dangerouslySetInnerHTML={{ __html: content }} />

                <ButtonContainer>
                    <Button
                        content={t('start')}
                        size="large"
                        icon="play"
                        onClick={onStart}
                    />
                </ButtonContainer>
            </WelcomeContainer>
        </SurveyBase>
    )
}

export default SurveyWelcome
