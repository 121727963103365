import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
    Button,
    Checkbox,
    CheckboxProps,
    Dimmer,
    Divider,
    Dropdown,
    Form,
    Header,
    Input,
    InputOnChangeData,
    Loader,
    Message,
    TextArea,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate } from '../../actions/util'
import { useMobileSystematicTask } from '../../hooks/systematicTasks/mobileSystematicTasks'
import { useMobileSystematicTasksPdf } from '../../hooks/systematicTasks/useMobileSystematicTasksPdf'
import { getState } from '../../state'
import { getUserOptionsFromState } from '../../state/helpers'
import DateDisplay from '../common/dateDisplay'
import DateInput from '../common/dateInput'
import { FieldDataItem, MobileSystematicTaskFieldDataType } from './EditMobileSystematicTask'
import MobileSystematicTaskActivityModal from './MobileSystematicTaskActivityModal'
import MobileSystematicTaskPdf, { PdfDocumentData } from './MobileSystematicTaskPdf'

const InputWrapper = styled.div`
    margin-bottom: 2rem;
`

const DescriptionHtml = styled.div`
    margin-bottom: 2rem;
    max-width: 90%;

    li {
        margin-bottom: 0.5rem;
    }
`

const FormWrapper = styled.div`
    margin-bottom: 2rem;
    padding-right: 1rem;
`

interface FieldProps {
    field: FieldDataItem
    onChange: (field: FieldDataItem) => void
    onNewActivityClick?: () => void
}

const CheckBoxWhitCommentField = ({ field, onChange, onNewActivityClick }: FieldProps) => {
    const { t } = useTranslation()

    const onCheckBoxChange = (checked: boolean) => {
        onChange({ ...field, checked })
    }

    const onFieldChange = (value: string) => {
        onChange({ ...field, value })
    }

    return (
        <>
            <Form.Field
                label={
                    <>
                        <label>{field.label}</label>
                    </>
                }
                onChange={(_: Event, { checked }: CheckboxProps) => onCheckBoxChange(checked!)}
                control={Checkbox}
                required={field.required}
            />

            {onNewActivityClick && (
                <Button
                    size="mini"
                    onClick={() => onNewActivityClick()}
                    style={{ marginBottom: 10 }}
                >
                    {t('addActivity')}
                </Button>
            )}

            <Form.Field
                label={
                    <>
                        <label>{t('conditionalEffects')}</label>
                    </>
                }
                value={field.value}
                onChange={(_: Event, { value }: InputOnChangeData) => onFieldChange(value)}
                control={TextArea}
            />
        </>
    )
}

const TextField = ({ field, onChange }: FieldProps) => {
    return (
        <>
            <Form.Field
                label={
                    <>
                        <label>{field.label}</label>
                    </>
                }
                onChange={(_: Event, { value }: InputOnChangeData) => onChange({ ...field, value })}
                control={Input}
                required={field.required}
            />
        </>
    )
}
const NumberField = ({ field, onChange }: FieldProps) => {
    return (
        <>
            <Form.Field
                label={
                    <>
                        <label>{field.label}</label>
                    </>
                }
                onChange={(_: Event, { value }: InputOnChangeData) => onChange({ ...field, value })}
                control={Input}
                required={field.required}
                type="number"
            />
        </>
    )
}

const UserSelectField = ({ field, onChange }: FieldProps) => {
    const { state } = getState()

    const userOptions = getUserOptionsFromState(state)(u => u.active, field.value?.toString())

    return (
        <>
            <Form.Field
                label={
                    <>
                        <label>{field.label}</label>
                    </>
                }
                control={Dropdown}
                onChange={(_: Event, { value }: InputOnChangeData) => onChange({ ...field, value })}
                selection
                search
                multiple
                options={userOptions}
                required={field.required}
            />
        </>
    )
}

const DateField = ({ field, onChange }: FieldProps) => {
    return (
        <>
            <Form.Field
                label={
                    <>
                        <label>{field.label}</label>
                    </>
                }
                control={DateInput}
                onChange={(_: Event, { value }: InputOnChangeData) => onChange({ ...field, value })}
                value={field.value}
                required={field.required}
            />
        </>
    )
}

const MobileSystematicTaskForm = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const { task, closeSystematicTask } = useMobileSystematicTask(id ? parseInt(id) : 0)

    const [activityModalField, setActivityModalField] = useState<FieldDataItem | null>(null)

    const [error, setError] = useState<string | null>(null)
    const [fieldData, setFieldData] = useState<FieldDataItem[]>([])

    const { getPdf, uploadPdf, parseFieldsForPdf } = useMobileSystematicTasksPdf()

    const [isValid, setIsValid] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (task) {
            const fieldData: FieldDataItem[] = task?.fieldData ? JSON.parse(task?.fieldData) : []
            setFieldData(fieldData)
        }
        setIsSaving(false)
    }, [task])

    const validate = (fieldData: FieldDataItem[]) => {
        setError(null)

        const valid = fieldData.every(f => {
            if (
                f.required &&
                f.type === MobileSystematicTaskFieldDataType.CheckboxWithComment &&
                !f.checked &&
                !f.value
            ) {
                return false
            }

            if (f.required && f.type === MobileSystematicTaskFieldDataType.String && !f.value) {
                return false
            }
            if (f.required && f.type === MobileSystematicTaskFieldDataType.Number && !f.value) {
                return false
            }

            if (
                f.required &&
                f.type === MobileSystematicTaskFieldDataType.MultipleUserDropdown &&
                (f.value as string[])?.length === 0
            ) {
                return false
            }

            if (f.required && f.type === MobileSystematicTaskFieldDataType.Date && !f.value) {
                return false
            }

            return true
        })
        setIsValid(valid)
    }

    const onFieldChange = (field: FieldDataItem) => {
        const index = fieldData.findIndex(f => f.id === field.id)
        if (index >= 0) {
            fieldData[index] = field
        }

        validate(fieldData)

        setFieldData([...fieldData])
    }

    const descriptionHtml = task?.description.replace(/<img/g, '<img style="max-width: 100%;" ')

    const getPdfDocument = () => {
        const parsedFields = parseFieldsForPdf(fieldData)

        const data: PdfDocumentData = {
            title: task?.sysTitle || '',
            responsibleId: task?.sysResponsibleId || '',
            date: task?.sysDate || '',
            description: task?.description || '',
            fields: parsedFields,
        }

        const document = <MobileSystematicTaskPdf data={data} />

        return document
    }

    const onSaveClick = async () => {
        if (!task) {
            return
        }

        if (!isValid) {
            setError(t('fillInAllRequiredFields'))
            return
        }

        setIsSaving(true)

        // Create pdf
        const pdfData = await getPdf(getPdfDocument())
        const title = task?.sysTitle
        const fileName = `${title}-${formatDate(task.sysDate)}.pdf`

        // open pdf in new tab
        /*         const file = new Blob([pdfData], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)

        window.open(fileURL) */

        try {
            const result = await uploadPdf(
                pdfData,
                fileName,
                task.id ? task?.id.toString() : '',
                task.sysId || 0,
            )

            console.log('pdf uploaded', result)
        } catch (error) {
            console.log('error', error)
            setError(t('error'))
        }

        await closeSystematicTask(task.sysId || 0)

        setIsSaving(false)

        navigate(-1)
    }

    const onNewActivityClick = (fieldId?: number) => {
        const field = fieldData.find(f => f.id === fieldId) || null
        setActivityModalField(field)
    }

    return (
        <>
            <MobileSystematicTaskActivityModal
                field={activityModalField}
                sysId={task?.sysId || 0}
                sysPlanId={task?.sysPlanId || 0}
                onClose={() => setActivityModalField(null)}
            />
            <div style={{ width: '100%' }}>
                <Dimmer active={isSaving}>
                    <Loader />
                </Dimmer>
                <Header
                    content={
                        <>
                            {task?.sysTitle}
                            <br />
                            <DateDisplay date={task?.sysDate} />
                        </>
                    }
                />

                {activityModalField && activityModalField?.value}

                <div style={{ marginBottom: 10 }}></div>
                <DescriptionHtml dangerouslySetInnerHTML={{ __html: descriptionHtml || '' }} />
                <Divider />

                <FormWrapper>
                    {' '}
                    <Form>
                        {fieldData.map(field => (
                            <InputWrapper key={field.id}>
                                {field.type ===
                                    MobileSystematicTaskFieldDataType.CheckboxWithComment && (
                                        <CheckBoxWhitCommentField
                                            field={field}
                                            onNewActivityClick={() => onNewActivityClick(field.id)}
                                            onChange={onFieldChange}
                                        />
                                    )}

                                {field.type === MobileSystematicTaskFieldDataType.String && (
                                    <TextField field={field} onChange={onFieldChange} />
                                )}
                                {field.type === MobileSystematicTaskFieldDataType.Number && (
                                    <NumberField field={field} onChange={onFieldChange} />
                                )}

                                {field.type ===
                                    MobileSystematicTaskFieldDataType.MultipleUserDropdown && (
                                        <UserSelectField field={field} onChange={onFieldChange} />
                                    )}
                                {field.type === MobileSystematicTaskFieldDataType.Date && (
                                    <>
                                        <DateField field={field} onChange={onFieldChange} />
                                    </>
                                )}
                                <Divider />
                            </InputWrapper>
                        ))}
                        <Message visible={!!error} error>
                            {error}
                        </Message>

                        <Button
                            size="large"
                            disabled={!isSaving && !isValid}
                            fluid
                            type="submit"
                            primary
                            onClick={onSaveClick}
                        >
                            {t('save')}
                        </Button>
                    </Form>
                </FormWrapper>
            </div>
        </>
    )
}

export default MobileSystematicTaskForm
