import React from 'react'
import { useTranslation } from 'react-i18next'
import { Measurement } from '../../../hooks/goals/measurement'
import { getState } from '../../../state'
import ActionButton from '../../common/actionButton'
import { generateReport } from '../../common/grid/actions'
import { MeasurementDisplayMap, useGraphData } from './diagram'

export interface GenerateReportButtonProps {
    measurements: Measurement[]
    include: MeasurementDisplayMap
}

const GenerateReportButton: React.FunctionComponent<GenerateReportButtonProps> = ({
    measurements,
    include,
}) => {
    const { t, i18n } = useTranslation()
    const { dispatch } = getState()

    const { xValues } = useGraphData(measurements, include)

    return (
        <ActionButton
            icon="file excel outline"
            action={() =>
                generateReport(dispatch)(
                    0,
                    {
                        excel: true,
                        name: `${t('measurement')}-${new Date().toLocaleDateString(
                            i18n.language,
                        )}.xlsx`,
                    },
                    measurements
                        .filter(x => include[x.id!])
                        .map(x => ({
                            name: x.detailedType,
                            ...xValues.reduce(
                                (acc, cur) => ({
                                    ...acc,
                                    [cur]:
                                        x.measurements.find(m => m.displayKey === cur)?.value ?? '',
                                }),
                                {},
                            ),
                        })),
                    ['name', ...xValues].map(x => ({ name: x, key: x })),
                )
            }
        />
    )
}

export default GenerateReportButton
