import { useTranslation } from "react-i18next"
import { getState } from "../../../state"
import { useMemo } from "react";
import { IDeviationSettingUser, IIssueDto } from "../../../api/issues";
import { useUserOptionsOverride } from "../../../hooks/users";
import { getUserFromState } from "../../../state/helpers";

export function useIssueUsers(responsibleSafetyUsers: IDeviationSettingUser[], isNew: boolean, selectedDeviation?: IIssueDto) {
    const { t } = useTranslation()
    const { state } = getState()

    const filteredUserOptions = useUserOptionsOverride(t, isNew ? u => u.active : undefined)
    const userOptions = useMemo(() => {
        if (
            selectedDeviation?.reportedBy &&
            !filteredUserOptions.find(({ value }) => selectedDeviation.reportedBy === value)
        ) {
            filteredUserOptions.push({
                key: selectedDeviation.reportedBy,
                value: selectedDeviation.reportedBy,
                text: `${t('userFromAnotherCompany')}`,
            })
        } else if (state.userProfile?.sub && !filteredUserOptions.find(({ value }) => value === state.userProfile?.sub)) {
            filteredUserOptions.push({
                key: state.userProfile?.sub,
                value: state.userProfile?.sub,
                text: `${t('userFromAnotherCompany')}`,
            })
        }

        return filteredUserOptions
    }, [filteredUserOptions, selectedDeviation, t, state.userProfile])

    const filteredResponsibles = responsibleSafetyUsers.filter(userData => userData.deviationResponsible).map(d => ({ value: d.userId, text: getUserFromState(state)(d.userId)?.name ?? "" }));
    const deviationResponsibleOptions = useMemo(() => {
        const responsibleId = selectedDeviation?.responsibleId
        const companyUserOption = filteredUserOptions.find(({ value }) => responsibleId === value)
        if (
            responsibleId &&
            !filteredResponsibles.find(({ value }) => responsibleId === value) &&
            companyUserOption &&
            filteredResponsibles.length
        ) {
            filteredResponsibles.push({
                value: responsibleId,
                text: companyUserOption.text,
            })
        } else if (state.userProfile?.sub && !filteredUserOptions.find(({ value }) => value === state.userProfile?.sub)) {
            filteredResponsibles.push({
                value: state.userProfile?.sub,
                text: `${t('userFromAnotherCompany')}`,
            })
        }

        return filteredResponsibles
    }, [filteredResponsibles, selectedDeviation, t, state.userProfile, filteredUserOptions])

    return {
        userOptions,
        deviationResponsibleOptions,
    }
}