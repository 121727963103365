import React, { useState } from 'react'
import { ProcessDefinition, ProcessHierarchyItem, useProcesses } from '../../../hooks/docs/process'
import Spacer from '../../common/spacer'
import ProcessFlow from './processFlow'
import ProcessHierarchyPopup from './processHierarchyPopup'

export interface ProcessMapProps {
    processes?: ProcessDefinition
}

export const ProcessMapDumb: React.FunctionComponent<ProcessMapProps> = ({ processes }) => {
    const [selectedProcess, setSelectedProcess] = useState<ProcessHierarchyItem>()
    const setProcess = (process: ProcessHierarchyItem) => setSelectedProcess(process)

    if (!processes) return null

    return (
        <>
            <ProcessHierarchyPopup process={selectedProcess} />
            <ProcessFlow
                items={processes?.governingProcess ?? []}
                type="governing"
                setProcess={setProcess}
            />

            <Spacer direction="horizontal" spacing="25px" />

            <div>
                <ProcessFlow
                    items={processes?.mainProcesses ?? []}
                    type="main"
                    setProcess={setProcess}
                />
            </div>

            <Spacer direction="horizontal" spacing="25px" />

            <ProcessFlow
                items={processes?.supportProcess ?? []}
                type="support"
                setProcess={setProcess}
            />
        </>
    )
}

const ProcessMap: React.FunctionComponent = () => {
    const { data: processes } = useProcesses()
    return <ProcessMapDumb processes={processes} />
}

export default ProcessMap
