import React, { useState } from 'react'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Button, Card, Checkbox, Dimmer, Header, Loader } from 'semantic-ui-react'
import { AdminRoles } from '../../auth/roles'
import { useGoals } from '../../hooks/goals'
import { useIsInRole } from '../../hooks/role'
import { DateFilterParams, stringifyDateFilter } from '../../filtering/dateFilter'
import { DateFilterControl } from '../common/dateFilter'
import FlexBox from '../common/flexBox'
import Spacer from '../common/spacer'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import GoalCard from './goalCard'
import RestructureModal from './restructureModal'
import { IsClosedFilterParams, stringifyIsClosedFilter } from '../../filtering/isClosedFilter'
import EmptyRequiredCollection from '../common/EmptyRequiredCollection'

const GoalsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.GoalsAdminRole)

    const [dateFilter, setDateFilter] = useState<DateFilterParams>({})
    const [isClosedFilter, setIsClosedFilter] = useState<IsClosedFilterParams>()

    const {
        data: goals,
        isValidating: goalsLoading,
        error,
        move,
        postLoading,
        deleteGoal,
    } = useGoals([stringifyDateFilter(dateFilter), stringifyIsClosedFilter({ isClosed: true })])

    return (
        <div style={{ width: '100%' }}>
            <Dimmer active={goalsLoading && !goals?.length}>
                <Loader />
            </Dimmer>

            <Header dividing content={t('goalsHeader')} />
            <EmptyRequiredCollection />

            <FlexBox justifyContent="space-between" alignItems="center">
                <FlexBox direction="column">
                    <DateFilterControl
                        onChange={(from, to) => setDateFilter({ dateFrom: from, dateTo: to })}
                        title={t('endDate')}
                    />

                    <Checkbox
                        label={t('alsoShowClosed')}
                        checked={isClosedFilter?.isClosed ?? false}
                        onChange={(_, data) =>
                            setIsClosedFilter({ isClosed: data.checked ?? false })
                        }
                    />
                </FlexBox>

                {isAdmin && (
                    <FlexBox>
                        <Button
                            onClick={() => navigate('new')}
                            type="button"
                            primary
                            icon
                            labelPosition="left"
                        >
                            <SemanticFeatherIcon
                                FeatherIcon={Plus}
                                size={'60%'}
                                centerIcon={true}
                            />{' '}
                            {t('addNew')}
                        </Button>

                        <RestructureModal
                            goals={goals ?? []}
                            error={error}
                            showClosed={isClosedFilter?.isClosed ?? false}
                            onMovedTo={(movedId, newParent) => move(movedId, newParent)}
                            loading={postLoading}
                            trigger={<Button type="button">{t('restructure')}</Button>}
                        />
                    </FlexBox>
                )}
            </FlexBox>

            <Spacer spacing="30px" />

            <Card.Group itemsPerRow={3} stackable doubling>
                {goals
                    ?.filter(x => !x.parentId && (x.status === 1 || isClosedFilter?.isClosed))
                    .map(x => {
                        const children = goals.filter(g => g.parentId === x.id)

                        return (
                            <GoalCard
                                goals={[
                                    x,
                                    ...children.filter(
                                        x => x.status === 1 || isClosedFilter?.isClosed,
                                    ),
                                ]}
                                hasChildren={!!children.length}
                                key={x.id}
                                onAddSubGoal={() => navigate(`new/${x.id}`)}
                                onDelete={deleteGoal}
                            />
                        )
                    })}
            </Card.Group>
        </div>
    )
}

export default GoalsPage
