import React from 'react'
import { ArrowLeftCircle, Save } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'

interface IFormActionsProps {
    onCancel: () => void
    disabled: boolean
}

const StyledActions = styled.div`
        display: flex;
        justify-content: flex-end;
        margin: 2em 0;
`
const FormActions = ({ onCancel, disabled }: IFormActionsProps) => {
    const { t } = useTranslation()
    return <StyledActions>
        <Button
            type="submit" primary disabled={disabled}
            icon
            labelPosition="left"
        >
            <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon />{' '}
            {t('save')}
        </Button>
        <Button type='button' onClick={onCancel} disabled={disabled} icon labelPosition="left">
            <SemanticFeatherIcon
                FeatherIcon={ArrowLeftCircle}
                size={'60%'}
                centerIcon

            />
            {t('cancel')}
        </Button>

    </StyledActions>
}

export default FormActions
