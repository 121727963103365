import * as z from 'zod';
import { IEditExtraFieldRequest, IExtraFieldTemplate } from '../../../api/issues';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Button, Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon';
import { ArrowLeftCircle, Save } from 'react-feather';
import ControlCheckbox from '../../fields/ControlCheckbox';
import * as API from '../../../api'
import ControlDropdown from '../../fields/ControlDropdown';
import { DynamicFieldType } from '../../common/dynamicFields';
import ControlInput from '../../fields/ControlInput';
import { IExtrafieldItem } from '../../modules/issues/ModuleSettingsPage/ExtraFieldList';
import { forbiddenChars } from '../../../utils/Validation';

export interface IExtraFieldFormProps {
    selectedField: IExtrafieldItem
    extraFieldTemplate: IExtraFieldTemplate
    onCancel: () => void
    refresh: () => void

}

export const extraFieldRequestSchema = z.object({
    key: z.string()
        .min(1, "required")
        .refine(value => !forbiddenChars.test(value), {
            message: "fieldNameError",
        }),
    type: z.coerce.number().min(0, { message: "required" }),
    required: z.boolean()
});

const ExtraFieldForm = ({ selectedField, extraFieldTemplate, onCancel, refresh }: IExtraFieldFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IExtrafieldItem>({
        defaultValues: selectedField,
        resolver: zodResolver(extraFieldRequestSchema),
    });

    const editExtraField = async (data: IExtrafieldItem) => {
        const objectToSave: IEditExtraFieldRequest = {
            moduleCollection: extraFieldTemplate.moduleCollection,
            oldKey: selectedField.key,
            newKey: { [data.key]: { required: data.required, type: data.type } }
        };

        return await API.issues.editNewExtra(extraFieldTemplate.id, objectToSave)
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(editExtraField, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                toast.success(t("saved"))
                onCancel()
                refresh()
            }

        },
    })

    return (
        <Form noValidate >
            <Grid stackable>
                <GridRow >
                    <GridColumn >
                        <ControlInput name={`key`} label={t('fieldNameNoChar')} control={control} required disabled={isSaving} />

                    </GridColumn>
                </GridRow>
                <GridRow columns="2">

                    <GridColumn>
                        <ControlDropdown name={`type`} label={t('fieldType')} control={control}
                            collection={[
                                { value: DynamicFieldType.String, text: t('string') },
                                { value: DynamicFieldType.MultipleUserDropdown, text: t('multipleUserDropdown') },
                            ]}
                            disabled />
                    </GridColumn>
                    <GridColumn verticalAlign="bottom">
                        <ControlCheckbox
                            name="required"
                            label={t('required')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Button
                            floated="right"
                            content={t('cancel')}
                            icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                            onClick={onCancel}
                            type="button"
                            disabled={isSaving}
                        />

                        <Button
                            primary
                            floated="right"
                            content={t('save')}
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Save}
                                />
                            }
                            onClick={handleSubmit(data => onSubmit(data))}
                            type={'button'}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default ExtraFieldForm;