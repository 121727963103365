import React from 'react'
import { Progress } from 'semantic-ui-react'
import styled from 'styled-components'
import { GoalWithMeasurementInfo } from '../../hooks/goals'
import Spacer from '../common/spacer'

export interface GoalProgressProps {
    goal?: GoalWithMeasurementInfo
}

const KpiContainer = styled.div`
    height: 1em;
    border: 1px solid rgba(1, 1, 1, 0.4);
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 1em 0 2.5em;
    padding: 0;
`

const BadValue = styled.div`
    color: red;
    flex-grow: 1;
    text-align: center; ;
`

const Center = styled.div`
    width: 5px;
    margin-left: -2.5px;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.5);
`

const GoodValue = styled.div`
    color: green;
    flex-grow: 1;
    text-align: center; ;
`

const GoalProgress: React.FunctionComponent<GoalProgressProps> = ({ goal }) => {
    if (
        goal?.currentValue === undefined ||
        goal?.currentValue === null ||
        goal?.targetValue === undefined ||
        goal?.targetValue === null ||
        goal?.initialValue === undefined ||
        goal?.initialValue === null
    )
        return <Spacer />

    const renderKpi = () => {
        const difference = goal.currentValue! - goal.targetValue!

        return (
            <KpiContainer>
                <BadValue>{difference < 0 ? `${difference}` : ''}</BadValue>
                <Center />
                <GoodValue>{difference >= 0 ? `+${difference}` : ''}</GoodValue>
            </KpiContainer>
        )
    }

    const renderProgress = () => {
        const getDecreasingProgress = () => {
            if (goal.currentValue! >= goal.initialValue!) return 0

            const range = goal.targetValue! - goal.initialValue!
            const progressMade = goal.initialValue! - goal.currentValue!
            const progressPercentage = Math.abs(Math.round((progressMade / range) * 100.0))

            return Math.min(progressPercentage, 100)
        }

        const getIncreasingProcess = () => {
            if (goal.currentValue! < goal.initialValue!) return 0

            return Math.min(
                Math.round(
                    Math.abs(
                        (goal.currentValue! - goal.initialValue!) /
                            (goal.targetValue! - goal.initialValue!),
                    ) * 100.0,
                ),
                100,
            )
        }

        const currentProgress =
            goal.targetValue! < goal.initialValue!
                ? getDecreasingProgress()
                : getIncreasingProcess()

        return (
            <Progress
                color={currentProgress >= 80 ? 'green' : 'blue'}
                size="small"
                progress="percent"
                percent={currentProgress}
            />
        )
    }

    return goal.isKpi ? renderKpi() : renderProgress()
}

export default GoalProgress
