import { FilterFn } from "@tanstack/react-table"
import React from "react"
import { useTranslation } from "react-i18next"
import { Dropdown } from "semantic-ui-react"

/* Important */
// columnId must match key on original object
export const enumMultiFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (Array.isArray(value) && value.length) {
    return value.includes(row.original[columnId])
  }
  return true
}

interface IEnumMultiFilterSelectProps<T = any> {
  setFilterValue: (updater: string | T[]) => void
  value: T[]
  Enum: T
  enumLangKey: string
}

export function EnumMultiFilterSelect({ setFilterValue, value, Enum, enumLangKey }: IEnumMultiFilterSelectProps) {
  const { t } = useTranslation()
  return (
    <Dropdown
      fluid
      multiple
      selection
      search
      options={Object.values(Enum).filter(value => typeof value === 'number').map((item: any) => ({ text: t(`${enumLangKey}.${item}`), value: item }))}
      value={value || []}
      onChange={(e, { value }) => setFilterValue(value as any)}
    />
  )
} 
