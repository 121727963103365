import { getState } from '../state'

export const usePositionOptions = () => {
    const {
        state: { companyPositions },
    } = getState()

    return companyPositions
        .map(d => ({
            key: d.id,
            text: d.name,
            value: d.id,
        }))
        .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1))
}
