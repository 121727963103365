import React from "react"
import { Control, Controller, FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormCheckbox, Popup } from "semantic-ui-react"
import styled from "styled-components"

interface IControlCheckboxProps<T extends FieldValues = any> {
    label: string
    name: string
    disabled: boolean
    control: Control<T>
    popupText?: string
    required?: boolean
    upperLabel?: string
}

const CustomUpperLabel = styled.label<{ required: boolean }>`
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
    ${props => props.required && `
    :after {
display: inline-block;
    vertical-align: top;
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
    };
    `}
    
`
const ControlCheckbox = ({
    name,
    label,
    disabled,
    control,
    popupText,
    required = false,
    upperLabel = ""
}: IControlCheckboxProps) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Popup
                    content={popupText}
                    disabled={!popupText}
                    trigger={
                        <span>
                            {upperLabel && <CustomUpperLabel required={required}>{upperLabel}</CustomUpperLabel>}
                            <FormCheckbox
                                error={!!error && { content: t(`${error.message}`), }}
                                label={label}
                                required={required}
                                checked={!!value} onChange={(e, d) => onChange(d.checked)} disabled={disabled}
                            />
                        </span>
                    }
                />

            )
            }
        />
    )
}
export default ControlCheckbox
