import { useTranslation } from 'react-i18next'

export const useLanguage = () => {
    const { i18n } = useTranslation()

    const rawLocale = (window as any).language ?? i18n.language
    if (!rawLocale) return 'sv'

    const trimmedLanguage = rawLocale.split('-')[0]
    return trimmedLanguage
}
