import { FileListing } from "../../components/common/files";
import { IProjectActivityNotificationRequest } from "../../components/forms/ProjectForm/ProjectActivityList/useNotifyProjectActivity";
import { get, post, destroy } from "../restClient";
import { ModuleStatus } from "../types/Enum";

export enum ProjectActivityStatus {
    Active = 1,
    Closed = 4
}

export enum ProjectActivityCheckListStatus {
    Active = 1,
    Closed = 4,
    Archived = 9
}

export interface IProjectActivityDto {
    id?: string;
    topic: string; // Required in C#
    description: string | null;
    cost: number | null; // Nullable in C#
    time: number | null; // Nullable in C# 
    doneBefore: Date | null; // Nullable in C#
    dateDone: Date | null; // Nullable in C#
    doneBy: string | null;
    status: number | null; // Nullable in C#
    responsibleId: any; // Required in C#
    sendAsAppointment: boolean;
}

export interface IExtraFieldDto {
    value: string;
    type: number;
    required: boolean;
}

export interface IProjectBase {
    ownId: number;
    status: ModuleStatus;
    topic: string; // Required in C#
    responsibleId: any; // Required in C#
    departmentId: number | null; // Nullable in C#
    startDate: Date | null; // Nullable in C#
    endDate: Date | null; // Nullable in C#
    cost: number | null; // Nullable in C#
    time: number | null; // Nullable in C# 
    extraFields: { [key: string]: IExtraFieldDto } | null;
    activities: IProjectActivityDto[];
}

export interface IProjectDto extends IProjectBase {
    id: string;
}

export interface IProjectDetailsDto extends IProjectDto {
    description: string;
    goal: string;
    implementation: string;
    activitiesIsChecklist: boolean;
    useReview: boolean;
    reviewDate: Date | null; // Nullable in C#
    reviewResponsibleId: any | null; // Nullable in C#
    reviewText: string;
    endedDate: Date | null; // Nullable in C#
    endedBy: string;
}

export interface IProjectRequest extends IProjectBase {
    description: string;
    goal: string;
    implementation: string;
    activitiesIsChecklist: boolean;
    useReview: boolean;
    reviewDate: Date | null; // Nullable in C#
    reviewResponsibleId: any | null; // Nullable in C#
    reviewText: string;
    endedDate: Date | null; // Nullable in C#
    endedBy: string;
}


export interface IProjectActivityTemplateDto {
    id: string;
    topic: string; // Required with no empty strings allowed
    description: string | null; // Nullable in C#
}

export interface IProjectTemplateDto {
    id: string;
    name: string; // Required with no empty strings allowed
    companyId: any | null; // Object type, // Nullable in C#
    extraFields: { [key: string]: IExtraFieldDto }; // Required, Dictionary type
    activities: IProjectActivityTemplateDto[]; // Required
    activitiesIsChecklist: boolean; // Required
}

export interface GenericCustomProject {
    id: number
    title: string
}
export const getAll = (from: string, to: string) => get<IProjectDto[]>(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects?to=${to ?? ''}&from=${from ?? ''}`)

export const getById = (id: string) => get<IProjectDetailsDto>(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects/${id}`)

export const create = (data: IProjectRequest) => post(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects`, data)

export const update = (id: string, data: IProjectRequest) => post(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects/${id}`, data)

export const deleteProject = (id: string) => destroy(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects/${id}`)

export const notify = (id: string) => post(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Projects/${id}/notify`)

export const getAllTemplates = () => get<IProjectTemplateDto[]>(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template`)

export const getTemplateById = (id: string) => get<IProjectTemplateDto>(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template/${id}`)

export const createTemplate = (data: IProjectTemplateDto) => post(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template`, data)

export const updateTemplate = (id: string, data: IProjectTemplateDto) => post(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template/${id}`, data)

export const deleteTemplate = (id: string) => destroy(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template/${id}`)

export const notifyProjectActivity = (data: IProjectActivityNotificationRequest) => post(`${window.env.REACT_APP_NOTIFICATION_SERVICE}/api/Notification`, data)

export const getAllSpecialProjects = () => get<GenericCustomProject[]>(`${window.env.REACT_APP_PROJECT_SERVICE}/api/SpecialProject`)

export const getAllProjectFiles = (projectId: string) => get<FileListing[]>(`${window.env.REACT_APP_FILE_SERVICE}/api/File/Projects/${projectId}`)


