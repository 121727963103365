import React from 'react'
import { getState } from '../../../../state';
import { getUserFromState } from '../../../../state/helpers';

export interface MultiUserCellProps {
    users: string[]
}

const MultiUserCell = ({ users }: MultiUserCellProps) => {
    const { state } = getState()
    return <span>{users.map(id => getUserFromState(state)(id)?.name ?? '').join(', ')}</span>
}

export default MultiUserCell
