import { useCrud } from '../crud/use-crud'

export interface Decision {
    id?: number
    decision?: string
    opportunity?: string
    date?: string
}

export const useDecisions = (goalId: string) => {
    const { fetch, create, remove, update } = useCrud<Decision, Decision>(
        `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${goalId}/decision`,
        `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${goalId}/decision`,
        'id',
        undefined,
        'POST',
    )

    return {
        loading: fetch.loading,
        remove: (id: number) => remove(id),
        data: (fetch.data || []) as Decision[],
        error: fetch.error,
        save: (decision: Decision) =>
            decision.id
                ? update(decision.id, decision, false, true)
                : create(decision, false, true),
    }
}
