import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Icon, Label } from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate } from '../../../actions/util'
import { MODULES, hasModule } from '../../../auth/modules'
import { useNumDueObjectCases } from '../../../hooks/objects/cases'
import { useNumCoursesToEvaluate } from '../../../hooks/skills/course'
import { useNews } from '../../../hooks/start/news'
import { useMobileSystematicTasksByUser } from '../../../hooks/systematicTasks/mobileSystematicTasks'
import { getState } from '../../../state'
import { BackgroundColor } from '../../../styles'
import MobilePageContainer from '../../global/mobilePageContainer'
import ModuleIcon from '../../icons/ModuleIcon'
import CollapsingNewsRenderer from './collapsingNewsRenderer'

const LinkGroupBoxContainer = styled.div`
    background-color: ${BackgroundColor};
    box-sizing: content-box;
    border-radius: 5px;
    margin-bottom: 10px;
`

const LinkGroupBoxHeader = styled.div`
    color: white;
    font-size: 1.4em;
    padding: 1rem 0.7rem;
    gap: 0.3rem;
    display: flex;
    align-items: center;
`

const LinkGroupBoxContent = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;

    a,
    > div {
        border: 1px solid #d1d1d1;
        background-color: white;
        display: block;
        color: black;
        padding: 10px;
        font-weight: bold;
        margin-bottom: 2px;
    }

    a:last-child {
        margin-bottom: 0;
    }

    a:hover,
    a:active {
        text-decoration: underline;
        background-color: lighten(${BackgroundColor}, 20%);
    }
`

const LinkTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const LinkDate = styled.div<{ isDue?: boolean }>`
    font-size: 0.8em;
    color: ${props => (props.isDue ? 'red' : 'grey')};
`

const StartPage = () => {
    const { t } = useTranslation()
    const { state } = getState()
    const { data: news } = useNews()

    const numCoursesToEvaluate = useNumCoursesToEvaluate()
    const numDueObjectCases = useNumDueObjectCases()

    const { mobileSystematicTasksByUser } = useMobileSystematicTasksByUser()

    return (
        <MobilePageContainer>
            {!!news?.length && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <Icon name="newspaper outline" />
                        {t('news')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <CollapsingNewsRenderer news={news} />
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.SKILLS) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon moduleId={MODULES.SKILLS} size="32px" centerIcon={true} />
                        {t('skills')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <Link to="/mobile/skills/me">
                            {t('mySkills')}
                            {numCoursesToEvaluate > 0 && (
                                <Label color="red" style={{ marginLeft: '5px' }}>
                                    {numCoursesToEvaluate}
                                </Label>
                            )}
                        </Link>
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.SYSTEMATIC_TASKS) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon
                            moduleId={MODULES.SYSTEMATIC_TASKS}
                            size="32px"
                            centerIcon={true}
                        />
                        {t('systematicTasks')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        {mobileSystematicTasksByUser?.map(task => (
                            <Link key={task.id} to={`/mobile/systematic-tasks/${task.id}`}>
                                <LinkTitle>
                                    <div>{task.sysTitle}</div>
                                    <LinkDate
                                        isDue={
                                            moment(task.sysDate).diff(
                                                moment().format('YYYY-MM-DD'),
                                            ) <= 0
                                        }
                                    >
                                        {formatDate(task.sysDate)}
                                    </LinkDate>
                                </LinkTitle>
                            </Link>
                        ))}
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.ISSUES) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon moduleId={MODULES.ISSUES} size="32px" centerIcon={true} />
                        {t('issues')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <Link to="/mobile/issues/add">{t('addNewIssue')}</Link>
                        <Link to="/mobile/accident/add">{t('addNewAccident')}</Link>
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.OBJECTS) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon moduleId={MODULES.OBJECTS} size="32px" centerIcon={true} />
                        {t('objects')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <Link to="/mobile/objects/cases">
                            {t('myTasks')}
                            {numDueObjectCases > 0 && (
                                <Label color="red" style={{ marginLeft: '5px' }}>
                                    {numDueObjectCases}
                                </Label>
                            )}
                        </Link>
                        <Link to="/mobile/objects/cases/new">{t('newTask')}</Link>
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.IMPROVEMENTS) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon moduleId={MODULES.IMPROVEMENTS} size="32px" centerIcon={true} />
                        {t('improvements')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <Link to="/mobile/improvement/suggestion/add">{t('new')}</Link>
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.CHEMICALS) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon moduleId={MODULES.CHEMICALS} size="32px" centerIcon={true} />
                        {t('chemicals')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <Link to="/mobile/chemicals">{t('chemicals')}</Link>
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}

            {hasModule(state)(MODULES.DOCS) && (
                <LinkGroupBoxContainer>
                    <LinkGroupBoxHeader>
                        <ModuleIcon moduleId={MODULES.DOCS} size="32px" centerIcon={true} />
                        {t('docs')}
                    </LinkGroupBoxHeader>

                    <LinkGroupBoxContent>
                        <Link to="/mobile/docs">{t('docs')}</Link>
                    </LinkGroupBoxContent>
                </LinkGroupBoxContainer>
            )}
        </MobilePageContainer>
    )
}

export default StartPage
