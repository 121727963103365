import React, { FunctionComponent, ReactNode } from 'react'
import { Header, HeaderContent, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

interface DocumentSectionModuleProps {
    title: string
    icon?: ReactNode
    attachTop?: boolean
    hideInPrint?: boolean
}

const SectionSegment = styled(Segment)`
    margin-top: 2em !important;
    padding-bottom: 0 !important;

    ${props =>
        props.$attachTop
            ? `
            @media (min-width: 600px) {
                table {
                    margin-top: -45px !important;

                    thead tr:first-child th {
                        border-bottom: none !important;
                    }
                }
            }
    `
            : ``}
`

const DocumentSectionModule: FunctionComponent<DocumentSectionModuleProps> = ({
    children,
    title,
    icon,
    attachTop,
    hideInPrint,
}) => {
    return (
        <SectionSegment
            basic
            className={hideInPrint ? 'hide-in-print' : undefined}
            $attachTop={attachTop}
        >
            <Segment vertical>
                <Header as="h3">
                    {icon}
                    <HeaderContent>{title}</HeaderContent>
                </Header>
            </Segment>
            {children}
        </SectionSegment>
    )
}
export default DocumentSectionModule
