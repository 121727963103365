import React, { useState } from "react"
import { Segment, Grid, GridColumn, List, ListItem, ListHeader, ListDescription, Loader, Container } from "semantic-ui-react"
import { formatDate } from "../../../../../actions/util"
import { hasModule, MODULES } from "../../../../../auth/modules"
import { useResponsiblePosition } from "../../../../../hooks/docs/responsiblePosition"
import { getState } from "../../../../../state"
import { getPositionFromState } from "../../../../../state/helpers"
import UserNameDisplay from "../../../../common/UserNameDisplay"
import ErrorBox from "../../../../common/errorBox"
import CreateSuggestedChange from "../../viewPage/Edit/createSuggestedChange"
import ReviewSuggestedChange from "../../viewPage/Edit/reviewSuggestedChange"
import { DocumentSuggestionChange } from "../../viewPage/Edit/types/suggestionChangesTypes"
import DocumentVersionModal from "../../viewPage/documentVersionModal"
import ReadConfirmationAlert from "../../viewPage/readConfirmationAlert"
import ResponsibleSelector from "../../viewPage/responsibleSelector"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { IViqmaDocument } from "../../../../../api/documents"

export type DocumentInfoState = 'show' | 'edit' | 'review'

interface DocumentInfoProps {
    document: IViqmaDocument
    infoContainerState: DocumentInfoState
    isAdmin: boolean
    onStateChanged?(state: DocumentInfoState): void
    onStateInvalid(): void
}

const DocumentContainer = styled(Container)`
    img {
        max-width: 100% !important;
        height: auto !important;
    }
`
const InfoContainer = styled(GridColumn)`
    word-wrap: break-word;
    overflow-y: auto;
`
const CustomListHeader = styled(ListHeader)`
    margin-bottom: 4px !important;
`
const CustomList = styled(List)`
    padding: 0 !important;
`
const DocumentInfo = ({
    document,
    infoContainerState,
    isAdmin,
    onStateChanged,
    onStateInvalid,
}: DocumentInfoProps) => {
    const { state } = getState()
    const { t } = useTranslation()
    const {
        companyPositions,
        concernPositions,
        update: updatePositions,
        loading: positionsLoading,
        error: positionUpdateError,
    } = useResponsiblePosition(document.id)

    const resolvedCompanyPositions = companyPositions.map(getPositionFromState(state))
    const resolvedConcernPositions = concernPositions.map(getPositionFromState(state))
    const [editingSuggestion, setEditingSuggestion] = useState<DocumentSuggestionChange>()

    const renderInfo = (state: DocumentInfoState) => {
        switch (state) {
            case 'show':
                return (
                    <>
                        <DocumentContainer
                            fluid
                            dangerouslySetInnerHTML={{ __html: document.content }}
                        />
                        <ReadConfirmationAlert documentId={document.id} />
                    </>
                )
            case 'review':
                return (
                    <ReviewSuggestedChange
                        documentId={+document.id}
                        title={document.name}
                        oldContent={document.content ?? ''}
                        onAbort={() => onStateChanged?.('show')}
                        onEdit={(suggestion: DocumentSuggestionChange) => {
                            setEditingSuggestion(suggestion)
                            onStateChanged?.('edit')
                        }}
                        onStateInvalid={onStateInvalid}
                    />
                )
            case 'edit':
                return (
                    <CreateSuggestedChange
                        documentId={document.id}
                        defaultContent={
                            (editingSuggestion ? editingSuggestion.content : document.content) ?? ''
                        }
                        title={document.name}
                        onAbort={() => onStateChanged?.('show')}
                        editingSuggestionId={editingSuggestion?.id}
                        onStateInvalid={onStateInvalid}
                        isAdmin={isAdmin}
                        isConcernDocument={document.isConcernDocument}
                    />
                )
            default:
                return null
        }
    }
    return (
        <Segment vertical>
            <Grid stackable divided>
                <InfoContainer width={13}>{renderInfo(infoContainerState)}</InfoContainer>
                <GridColumn width={3} className="hide-in-print">
                    <List>
                        {document.version && (
                            <ListItem>
                                <CustomListHeader>
                                    {t('version')}&nbsp;&nbsp;
                                    <DocumentVersionModal currentDocument={document} />
                                </CustomListHeader>
                                <ListDescription>{document.version}</ListDescription>
                            </ListItem>
                        )}
                        {document.lastChanged && (
                            <ListItem>
                                <CustomListHeader>{t('date')}</CustomListHeader>
                                <ListDescription>
                                    {formatDate(document.lastChanged)}
                                </ListDescription>
                            </ListItem>
                        )}
                        {document.approvedByUser?.name && (
                            <ListItem>
                                <CustomListHeader>{t('approvedBy')}</CustomListHeader>
                                <ListDescription>
                                    <UserNameDisplay
                                        user={document.approvedByUser}
                                        userId={document.approvedBy ?? undefined}
                                    />
                                </ListDescription>
                            </ListItem>
                        )}
                        {resolvedCompanyPositions && hasModule(state)(MODULES.SKILLS) && (
                            <>
                                {!!resolvedConcernPositions?.length && (
                                    <ListItem>
                                        <CustomListHeader>
                                            {t('responsiblePositionConcern')}&nbsp;&nbsp;
                                        </CustomListHeader>
                                        <ListDescription>
                                            <List>
                                                {resolvedConcernPositions.map(x => (
                                                    <ListItem key={x?.id}>{x?.name}</ListItem>
                                                ))}
                                            </List>
                                        </ListDescription>
                                    </ListItem>
                                )}

                                <ListItem>
                                    <CustomListHeader>
                                        {positionUpdateError && (
                                            <ErrorBox error={positionUpdateError} />
                                        )}
                                        {t('responsiblePosition')}&nbsp;&nbsp;
                                        {positionsLoading ? (
                                            <Loader active={true} inline size="tiny" />
                                        ) : (
                                            isAdmin && (
                                                <ResponsibleSelector
                                                    value={companyPositions}
                                                    onChange={updatePositions}
                                                />
                                            )
                                        )}
                                    </CustomListHeader>
                                    <ListDescription>
                                        <CustomList>
                                            {resolvedCompanyPositions.map(x => (
                                                <ListItem key={x?.id}>{x?.name}</ListItem>
                                            ))}
                                        </CustomList>
                                    </ListDescription>
                                </ListItem>
                            </>
                        )}
                    </List>
                </GridColumn>
            </Grid>
        </Segment>
    )
}
export default DocumentInfo