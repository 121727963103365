import React from 'react';
import { RowRendererProps, Row, SimpleCellFormatter } from 'react-data-grid';
import GroupHeadingRowRenderer from './groupHeadingRowRenderer';

export interface RenderSelectingRowRendererProps extends RowRendererProps<any> {
    innerRenderer: (props: any) => any
}

const RenderSelectingRowRenderer: React.FunctionComponent<RenderSelectingRowRendererProps> = (props) => {
    const { row } = props;

    const filteredProps = Object.fromEntries(
        Object.entries(props).filter(([key,_]) => key !== 'innerRenderer')
    ) as any;
        
    if (row.__metaData?.isGroup) {
        return <GroupHeadingRowRenderer {...props} />;
    } else if (row.__metaData?.isGroupSummary) {
        const columns = props.viewportColumns.map(c => (
            {
                ...c,
                formatter: (props:any) => <SimpleCellFormatter {...props} /> 
            }
        ));

        return <Row className="rdg-summary-row" {...filteredProps} viewportColumns={columns}  />;
    } else {
        return React.createElement(props.innerRenderer, filteredProps);
    }
}

export default RenderSelectingRowRenderer;