import React from 'react'
import { Button, ButtonProps } from 'semantic-ui-react'
import styled from 'styled-components'

const LinkButtonElement = styled(Button)`
    display: inline-block !important;
    padding: 0 5px !important;
    margin: 0 !important;
    border: none !important;
    background-color: transparent !important;
    color: #4183c4 !important;
    cursor: pointer !important;
    user-select: none !important;

    :hover {
        color: #1e70bf !important;
    }
`

const LinkButton: React.FunctionComponent<ButtonProps> = props => {
    return <LinkButtonElement {...props} />
}

export default LinkButton
