import React from "react"
import { Control, FieldValues } from "react-hook-form"
import { GridColumn, GridRow } from "semantic-ui-react"
import ControlInput from "../ControlInput"
import ControlDropdown from "../ControlDropdown"
import { IExtraField } from "../../../api/issues"
import styled from "styled-components"

const StyledGridColumn = styled(GridColumn)`
    margin: 1em 0 !important;
`
interface IControlExtraFieldProps<T extends FieldValues = any> {
    disabled: boolean
    control: Control<T>
    extraFields: {
        [key: string]: IExtraField;
    }
    userOptions: {
        key: string;
        text: string;
        value: string;
    }[]
}

const ControlExtraField = ({
    disabled,
    control,
    extraFields,
    userOptions
}: IControlExtraFieldProps) => {
    return (
        <GridRow columns="2">
            {Object.keys(extraFields).map((key) => {
                return (<StyledGridColumn key={key}>
                    {extraFields[key].type === 4 ?
                        <ControlDropdown name={`extraFields.${key}`} required={extraFields[key].required} multiple label={key} control={control} collection={userOptions} disabled={disabled} />
                        : <ControlInput name={`extraFields.${key}`} required={extraFields[key].required} label={key} control={control} disabled={disabled} />}
                </StyledGridColumn>
                )
            })}
        </GridRow>
    )
}
export default ControlExtraField