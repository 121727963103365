import { IActivityListDto, IIdeaListDto, IImprovementActivityRequest, IImprovementGroupDto, IImprovementGroupRequest, IImprovementIdeaRequest } from "../../../api/improvements";

export const mapIdeaListDtoToImprovementIdeaRequest = (idea: IIdeaListDto): IImprovementIdeaRequest => ({
    status: idea.status,
    groupId: idea.improvementGroup ? idea.improvementGroup.id : null,
    idea: idea.idea,
    expectedResult: idea.expectedResult,
    solutionProposal: idea.solutionProposal,
    reporterId: idea.reporterId,
    created: new Date(idea.created),
    responsibleId: null,
    plannedFinishedAt: null,
    priority: null
});

export const mapImprovementGroupDtoToImprovementGroupRequest = (group: IImprovementGroupDto): IImprovementGroupRequest => ({
    name: group.name,
});

export const mapToImprovementActivityRequest = (activity: IActivityListDto): IImprovementActivityRequest => ({
    status: activity.status,
    groupId: activity.improvementGroup ? activity.improvementGroup.id : null,
    idea: activity.idea,
    expectedResult: activity.expectedResult,
    solutionProposal: activity.solutionProposal,
    reporterId: activity.reporterId,
    created: new Date(activity.created),
    responsibleId: activity.responsibleId,
    plannedFinishedAt: activity.plannedFinishedAt ? new Date(activity.plannedFinishedAt) : null,
    donePercentage: activity.donePercentage,
    decisionStatus: activity.decisionStatus ?? 0,
    decisionResult: activity.decisionResult,
    authorizedUser: activity.authorizedUser,
    time: activity.time,
    cost: activity.cost,
    finishedAt: activity.finishedAt ? new Date(activity.finishedAt) : null,
    evaluationRequired: activity.evaluationRequired,
    priority: activity.priority,
    evaluationResult: activity.evaluationResult,
    evaluatedBy: activity.evaluatedBy,
    evaluatedAt: activity.evaluatedAt ? new Date(activity.evaluatedAt) : null,
});