import React, { useMemo, useState } from 'react'
import { Button, Form, Grid, GridColumn, GridRow, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import { createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Info, Save, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact, { FooterLabel } from '../../../../wrappers/TableReact'
import { getState } from '../../../../../state'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import { IInternalAssessmentDto, ISupplierResponse } from '../../../../../api/suppliers'
import { useDeleteInternalAssesment } from './useDeleteInternalAssesment'
import { formatDate } from '../../../../../actions/util'
import DateCell from '../../../../common/cells/DateCell'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../../wrappers/TableReact/Filters/EnumMultiFilter'
import { SupplierQuestionType } from '../../../../../api/types/Enum'
import { useLanguage } from '../../../../../hooks/language'
import { dateBetweenFilterFn, DateRangeFilter } from '../../../../wrappers/TableReact/Filters/DateRangeFilter'
import CreateInternalAssessmentForm from '../CreateInternalAssessmentForm'
import { getGraphColorsFactory } from '../../../../../styles'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import ControlDropdown from '../../../../fields/ControlDropdown'
import { useMutation } from 'react-query'
import { handleAxiosError } from '../../../../../actions/error'
import { toast } from 'react-toastify'
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { AdminRoles, isInRole } from '../../../../../auth/roles'
import styled from 'styled-components'
export const CustomResponsiveContainer = styled(ResponsiveContainer)`
  margin: 1em 0;
`

export const intervalRequestSchema = z.object({
    interval: z.coerce.number().optional().nullable(),
});

interface IInternalAssessmentListProps {
    selectedSupplier: ISupplierResponse
    hasSupplierConcernModule: boolean
}
const InternalAssessmentList = ({ selectedSupplier, hasSupplierConcernModule }: IInternalAssessmentListProps) => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.suppliers.getInternalAssessment, "InternalAssessmentList", selectedSupplier.id)
    const {
        isLoading: isLoadingQuestions,
        data: questions,
    } = useGetQueryByParams(true, API.suppliers.getInternalQuestions, "InternalQuestions", selectedSupplier.id)

    const language = useLanguage()
    const [showAddModal, setShowAddModal] = useState(false);
    const { isDestroying, onDestroy } = useDeleteInternalAssesment(refresh)

    const columnHelper = createColumnHelper<IInternalAssessmentDto>()
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: 45,
                minSize: 45,
                cell: ({ row }) => {
                    return hasSupplierConcernModule ? null : <Button title={t('delete')} disabled={isDestroying} onClick={() => onDestroy({
                        confirmText: t('confirm.deleteValue', { value: row.original.swedishQuestion }),
                        supplierid: selectedSupplier.id,
                        questionId: row.original.id
                    })}
                        type='button'
                        color='red'
                        icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />
                }

            }),
            columnHelper.accessor('type', {
                id: 'type',
                header: t('type') as any,
                filterFn: enumMultiFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | SupplierQuestionType[]) => void, value: SupplierQuestionType[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={SupplierQuestionType}
                            enumLangKey="supplierQuestionType"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`supplierQuestionType.${row.original.type}`)}</span>
            }),
            ...(
                language === "sv"
                    ? [
                        columnHelper.accessor('swedishQuestion', {
                            id: 'swedishQuestion',
                            header: t('question') as any,
                        }),
                    ]
                    : [
                        columnHelper.accessor('englishQuestion', {
                            id: 'englishQuestion',
                            header: t('question') as any,
                        }),
                    ]
            ),
            columnHelper.accessor('comment', {
                id: 'comment',
                header: t('comment') as any,
            }),
            columnHelper.accessor(r => r.date ? formatDate(r.date) : "", {
                id: "date",
                header: t('date') as any,
                filterFn: dateBetweenFilterFn,
                meta: {
                    filterComponent: (setFilterValue: (updater: any) => void, value: Date[]) => (
                        <DateRangeFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                },
                cell: ({ row }) => <DateCell date={row.original.date} />
            }),
            columnHelper.accessor('points', {
                id: 'points',
                header: t('points') as any,
                enableColumnFilter: false,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const validPoints = filtered
                        .map((f) => f.original.points)
                        .filter((point): point is number => point !== null && point !== undefined);

                    const totalCount = validPoints?.reduce((a: number, c: number) => a + c, 0);
                    const average = validPoints.length > 0 ? totalCount / validPoints.length : 0;

                    return (
                        <FooterLabel>{t('averageValue', { value: average.toFixed(2) })}</FooterLabel>
                    );
                },
            }),
        ], [columnHelper, t, isDestroying, onDestroy, language, selectedSupplier.id, hasSupplierConcernModule])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: columns,
        enableGlobalFilter: false,
        enableGrouping: false,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })
    const { control, handleSubmit } = useForm<{ interval: number }>({
        defaultValues: { interval: selectedSupplier.interval ?? 1 },
        resolver: zodResolver(intervalRequestSchema),
    });

    const editInterval = async (data: { interval: number }) => await API.suppliers.editSupplierInterval(selectedSupplier.id, data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(editInterval, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                toast.success(t("saved"))
                refresh()
            }

        },
    })

    if (isLoading || !data || isLoadingQuestions || !questions) {
        return <Loading />
    }

    const processData = (data: IInternalAssessmentDto[]) => {
        const groupedData: { [key: string]: { total: number; count: number } } = {};

        data.filter(z => z.type !== 5).forEach((item) => {
            if (item.date && item.points !== null) {
                const date = formatDate(item.date)
                const typeKey = t(`supplierQuestionType.${item.type}`) || 'Unknown';
                const groupKey = `${typeKey}_${date}`; // Unique key for each type and date

                if (!groupedData[groupKey]) {
                    groupedData[groupKey] = { total: 0, count: 0 };
                }

                groupedData[groupKey].total += item.points;
                groupedData[groupKey].count += 1;
            }
        });

        // Transform the accumulated data to chart format
        const chartData: { date: string;[key: string]: number | string }[] = [];

        // Collect all unique types for ensuring all keys are present
        const allTypes = new Set<string>();

        for (const key in groupedData) {
            const [type, date] = key.split('_');
            const average = groupedData[key].total / groupedData[key].count;
            allTypes.add(type);

            // Find or create a data entry for the current date
            let entry = chartData.find((e) => e.date === date);
            if (!entry) {
                entry = { date };
                chartData.push(entry);
            }

            entry[type] = average;
        }

        // Ensure all dates have entries for all types, even if the value is null
        chartData.forEach((entry) => {
            allTypes.forEach((type) => {
                if (!(type in entry)) {
                    entry[type] = 0; // Set to null or 0 to ensure all lines are drawn
                }
            });
        });

        // Sort chart data by date
        chartData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        return chartData;
    };

    const chartData = processData(data);
    const barColors = getGraphColorsFactory()

    if (!questions.length) {
        return <Message info
            header={t("internalQuestions")}
            content={<span>
                {t('noInternalQuestions')}
                {isInRole(state.userProfile, AdminRoles.SupplierAdminRole)
                    ? <a href="/lev/levFr.aspx">{t('toAddLink')}</a>
                    : t('contactSystemAdmin')}
            </span>}
            icon={<SemanticFeatherIcon FeatherIcon={Info} centerIcon size="32px" />} />
    }
    return (
        <FullHeightStackingContainer>
            <Form noValidate >
                <Grid stackable>
                    <GridRow columns={2}>
                        <GridColumn>
                            <ControlDropdown explanatoryText={t("supplierIntervalInfo")} name="interval" label={t('interval')} control={control}
                                collection={[
                                    { value: 1, text: "1" },
                                    { value: 2, text: "2" },
                                    { value: 3, text: "3" },
                                    { value: 4, text: "4" },
                                    { value: 5, text: "5" }
                                ]}
                                disabled={isSaving}
                                clearable />
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn>
                            {!hasSupplierConcernModule && <Button
                                primary
                                floated="right"
                                content={t('save')}
                                icon={
                                    <SemanticFeatherIcon
                                        FeatherIcon={Save}
                                    />
                                }
                                onClick={handleSubmit(data => onSubmit(data))}
                                type={'button'}
                                disabled={isSaving}
                            />}
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Form>

            {!hasSupplierConcernModule && <Message info>
                {t("internalAssessmentInfo")}
            </Message>}
            <CustomResponsiveContainer width="100%" height={400}>
                <LineChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(date) => formatDate(date)}
                        type="category"
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {Object.keys(chartData[0] || {})
                        .filter((key) => key !== 'date')
                        .map((typeKey) => (
                            <Line
                                key={typeKey}
                                type="monotone"
                                dataKey={typeKey}
                                name={typeKey}
                                stroke={barColors()}
                                activeDot={{ r: 8 }}
                                connectNulls
                            />
                        ))}
                </LineChart>
            </CustomResponsiveContainer>

            <TableReact table={table}
                onAddCallback={hasSupplierConcernModule ? undefined : () => setShowAddModal(true)}
                canExport={false}
                overrideOnAddTitle={t('addValue', { value: t('internalAssessment').toLowerCase() })}
                overflowable />

            <ModalWrapper title={t('addValue', { value: t('internalAssessment').toLowerCase() })} open={showAddModal} onClose={() => setShowAddModal(false)} >
                <CreateInternalAssessmentForm onCancel={() => setShowAddModal(false)} refresh={refresh} supplierId={selectedSupplier.id} questions={questions} />
            </ModalWrapper>

        </FullHeightStackingContainer>
    )
}

export default InternalAssessmentList
