import { Company } from '../state/initial'
import useApi from './useApi'

export const useCompanies = () =>
    useApi<Company[]>(`${window.env.REACT_APP_COMPANY_SERVICE}/api/company`)

export const useCompanyOptions = () => {
    const { data, loading } = useCompanies()

    return {
        loading,
        options:
            data?.map(c => ({
                key: c.id,
                text: c.name,
                value: c.id,
            })) ?? [],
    }
}
