import { get, post, destroy, put } from "../restClient";

export enum ImprovementStatus {
    Active = 0,
    PendingDecision = 1,
    DecisionMade = 2,
    Evaluate = 3,
    Closed = 4,
    New = 5,
    Standby = 6,
}

export enum ImprovementIdeaDropdownStatus {
    Active = 0,
    Closed = 4,
    New = 5,
    Standby = 6,
}

export enum ImprovementActivityStatus {
    Active = 0,
    PendingDecision = 1,
    DecisionMade = 2,
    Evaluate = 3,
    Closed = 4,
}

export enum ImprovementDecisiontatus {
    MadeByResponsibleOrGroup = 0,
    MadeByOther = 1,
    IsDecidedByOther = 2,
}

export interface IImprovementGroupDto {
    id: number
    name: string
}

export interface IIdeaListDto {
    id: number
    status: number
    improvementGroup: IImprovementGroupDto | null
    idea: string
    expectedResult: string | null
    solutionProposal: string | null
    reporterId: string
    created: Date
}

export interface IActivityListDto {
    id: number
    status: number
    improvementGroup: IImprovementGroupDto | null
    idea: string
    expectedResult: string
    solutionProposal: string
    reporterId: string
    created: Date
    decisionStatus: number
    decisionResult: string
    authorizedUser: string | null
    donePercentage: number
    time: number | null
    cost: number | null
    finishedAt: Date | null
    responsibleId: string
    priority: number
    evaluationRequired?: boolean | null
    plannedFinishedAt: Date | null
    evaluationResult: string | null
    evaluatedBy: string | null
    evaluatedAt: Date | null
}

export interface IImprovementIdeaRequest {
    status?: number
    groupId?: number | null
    idea: string;
    expectedResult: string | null
    solutionProposal: string | null
    reporterId?: string
    created?: Date
    responsibleId?: string | null
    plannedFinishedAt?: Date | null
    priority?: number | null
}

export interface IImprovementGroupRequest {
    name: string;
}

export interface IImprovementActivityRequest {
    status: number
    groupId?: number | null;
    idea: string;
    expectedResult: string | null
    solutionProposal: string | null
    reporterId: string;
    created: Date
    responsibleId: string;
    plannedFinishedAt: Date | null
    donePercentage: number
    decisionStatus: number
    decisionResult: string | null
    authorizedUser: string | null
    time: number | null
    cost: number | null
    finishedAt: Date | null
    evaluationRequired?: boolean | null
    priority: number | null
    evaluationResult: string | null
    evaluatedBy: string | null
    evaluatedAt: Date | null
}

export interface IAuthorizedUserNotifyRequest {
    authorizedUser: string
    idea: string
    expectedResult?: string | null
    solutionProposal?: string | null
}

export const getAllIdeas = () => get<IIdeaListDto[]>(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/ideas`)

export const getIdeaById = (id: number) => get<IIdeaListDto>(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/ideas/${id}`)

export const createIdea = (data: IImprovementIdeaRequest) => post(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/ideas`, data)

export const editIdea = (id: number, data: IImprovementIdeaRequest) => put(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/ideas/${id}`, data)

export const activateIdea = (id: number, data: IImprovementIdeaRequest) => post(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/ideas/activate/${id}`, data)

export const deleteIdea = (id: number) => destroy(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/ideas/${id}`)

export const getAllActivities = () => get<IActivityListDto[]>(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities`)

export const getActivityById = (id: number) => get<IActivityListDto>(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities/${id}`)

export const createActivity = (data: IImprovementActivityRequest) => post(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities`, data)

export const editActivity = (id: number, data: IImprovementActivityRequest) => put(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities/${id}`, data)

export const notifyActivity = (id: number) => post(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities/${id}/notify`)

export const notifyAuthorized = (data: IAuthorizedUserNotifyRequest) => post(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities/authorized/notify`, data)

export const deleteActivity = (id: number) => destroy(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Activities/${id}`)

export const getImprovementGroups = () => get<IImprovementGroupDto[]>(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/improvementgroup`)

export const createGroup = (data: IImprovementGroupRequest) => post(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/improvementgroup`, data)

export const editGroup = (id: string, data: IImprovementGroupRequest) => put(`${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/improvementgroup/${id}`, data)

