import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../api'
import { ICompetenceUserBatchRequest } from '../../../api/skills';
import FormActions from '../FormActions';
import ControlDropdown, { IDropdownOption } from '../../fields/ControlDropdown';
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams';
import Loading from '../../common/Loading';
import { useUserOptionsOverride } from '../../../hooks/users';
import ControlCheckbox from '../../fields/ControlCheckbox';
import ControlDateField from '../../fields/ControlDateField';
import ControlInput from '../../fields/ControlInput';

export interface ICompetenceUserBatchFormProps {
    onCancel: () => void
    refresh: () => void
}

export const competenceUserBatchRequestSchema = z.object({
    userIds: z.array(z.string()).min(1, "required"),
    departmentId: z.number().optional().nullable(),
    locationId: z.number().optional().nullable(),
    positionIds: z.array(z.number()),
    skillIds: z.array(z.number()),
    validFrom: z.date().optional().nullable(),
    validTo: z.date().optional().nullable(),
    isRegular: z.boolean(),
    competenceLevel: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
});

const CompetenceUserBatchForm = ({ onCancel, refresh }: ICompetenceUserBatchFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
        state: { locations, departments },
    } = getState()
    const { control, handleSubmit, setValue, getValues } = useForm<ICompetenceUserBatchRequest>({
        resolver: zodResolver(competenceUserBatchRequestSchema),
    });
    const userOptions = useUserOptionsOverride(t, u => u.active, "", true)
    const {
        isLoading: isLoadingPositions,
        data: positions,
    } = useGetQueryByParams(true, API.skills.getPositions, "competenceUserposition")

    const {
        isLoading: isLoadingSkills,
        data: skills,
    } = useGetQueryByParams(true, API.skills.getSkillsListView, "skillList")

    const onUserRequest = async (data: ICompetenceUserBatchRequest) => await API.skills.competenceUserBatch(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onUserRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })
    const selectAllOption: IDropdownOption = { value: "-1", text: t('selectAll') }
    const userSelectAllOptions = [selectAllOption, ...userOptions]
    if (isLoadingPositions || !positions || isLoadingSkills || !skills) {
        return <Loading />
    }
    const handleUserDropdownChange = (
        value: string[]
    ) => {
        if (value.includes("-1")) {
            if (getValues("userIds")?.length === userOptions.length - 1) {
                setValue("userIds", []);
            } else {
                setValue("userIds", userOptions.slice(1).map(option => option.value));
            }
        } else {
            setValue("userIds", value);
        }
    };
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <FormActions disabled={isSaving} onCancel={onCancel} />

            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlDropdown
                            name="userIds"
                            overrideOnChange={(value) => handleUserDropdownChange(value)}
                            label={t('users')}
                            popupText={t('addMultiValue', { value: t('users').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            clearable
                            required
                            collection={userSelectAllOptions} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown name="departmentId" label={t('departmentId')} control={control} collection={departments.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        {locations && locations.length ? <ControlDropdown name="locationId" label={t('locationId')} control={control} collection={locations?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} clearable /> : null}
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown
                            name="positionIds"
                            label={t('skillPositions')}
                            popupText={t('addMultiValue', { value: t('skillPositions').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            collection={positions.companyPositions.map(d => ({ value: d.id, text: d.name })) ?? []} />
                    </GridColumn>
                    <GridColumn verticalAlign='middle'>
                        <ControlCheckbox name="isRegular" label={t('isRegular')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown
                            name="skillIds"
                            label={t('skills')}
                            popupText={t('addMultiValue', { value: t('skills').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            collection={skills.map(d => ({ value: d.id, text: d.name })) ?? []} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput type='number' name="competenceLevel" label={t('competenceLevel')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn >
                        <ControlDateField name="validFrom" label={t('validFrom')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="validTo" label={t('validTo')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default CompetenceUserBatchForm;