import React, { FunctionComponent } from 'react'
import { Button, Table, TableBody, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react'
import { Plus } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import {
    useExternalDocuments,
    ExternalDocument,
} from '../../../../../hooks/externalDocuments/document'
import ExternalDocumentRow from './ExternalDocumentTableRow'
import ExternalDocumentModal from './ExternalDocumentModal'
import DocumentSectionModule from '../DocumentSectionModule'

interface ExternalDocumentTableProps {
    documentId: string
    isAdmin: boolean
}

const ExternalDocumentsTable: FunctionComponent<ExternalDocumentTableProps> = ({
    documentId,
    isAdmin,
}) => {
    const { t } = useTranslation()
    const { data, loading, create, remove, update } = useExternalDocuments(+documentId)

    if (!isAdmin && !data?.length) return null

    return (
        <DocumentSectionModule
            title={t('documents stored in other location')}
            hideInPrint={!data.length}
            attachTop={isAdmin}
        >
            <ExternalDocumentTableDumb
                data={data || []}
                create={create}
                loading={loading}
                isAdmin={isAdmin}
                remove={remove}
                update={update}
                t={t}
            />
        </DocumentSectionModule>
    )
}

interface ExternalDocumentTableDumbProps {
    t: TFunction
    loading: boolean
    data: ExternalDocument[]
    isAdmin: boolean
    create(document: ExternalDocument): void
    remove(id?: number): void
    update(document: ExternalDocument): void
}

const ExternalDocumentTableDumb: FunctionComponent<ExternalDocumentTableDumbProps> = ({
    t,
    data = [],
    remove,
    update,
    isAdmin,
    loading,
    create,
}) => {
    return (
        <Table style={{ marginBottom: 0 }} basic="very">
            <TableHeader>
                {isAdmin && (
                    <TableRow>
                        <TableHeaderCell />
                        <TableHeaderCell colSpan="4" textAlign="right">
                            <ExternalDocumentModal
                                onCreate={ed => {
                                    create(ed)
                                }}
                                trigger={
                                    <Button icon labelPosition="left" primary type="button">
                                        <SemanticFeatherIcon
                                            FeatherIcon={Plus}
                                            size={'60%'}
                                            centerIcon={true}
                                        />{' '}
                                        {t('new')}
                                    </Button>
                                }
                            />
                        </TableHeaderCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableHeaderCell>{t('documents')}</TableHeaderCell>
                    <TableHeaderCell>{t('archiveSite')}</TableHeaderCell>
                    <TableHeaderCell>{t('archiveTime')}</TableHeaderCell>
                    <TableHeaderCell></TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {loading
                    ? null
                    : data.map((d, index) => (
                        <ExternalDocumentRow
                            t={t}
                            key={`${d.id}${index}`}
                            externalDocument={d}
                            isAdmin={isAdmin}
                            onRemove={() => remove(d.id)}
                            onUpdate={d => {
                                update(d)
                            }}
                        />
                    ))}
            </TableBody>
        </Table>
    )
}

export default ExternalDocumentsTable
