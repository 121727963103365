import { formatDate } from './../../actions/util'
import { getAuthedAxios } from './../../auth/index'
/**
 *   public int Id { get; set; }
        public DateTime? Created { get; set; }
        public DateTime? Updated { get; set; }
        public int? SysId { get; set; }
        public string Description { get; set; }
        public string ResponsibleId { get; set; }
        public string FieldData { get; set; }
 */

import { useEffect, useState } from 'react'
import { getState } from '../../state'
import useApi from '../useApi'

export type MobileSystematicTaskDto = {
    id?: number
    companyId?: number
    created?: string
    updated?: string
    sysId: number
    sysPlanId?: number
    description: string
    responsibleId: string
    sysResponsibleId?: string
    fieldData: string
    sysDate?: string
    sysTitle?: string
}
export type MobileSystematicTaskListItemDto = {
    id?: number
    created?: string
    updated?: string
    sysTitle: string
    responsibleId: string
    responsible?: string
    sysResponsibleId: string
    sysId: number
    sysDate: string
}

export const useMobileSystematicTasksByUser = () => {
    const { state } = getState()
    const { users } = state
    const [mobileSystematicTasksByUser, setMobileSystematicTasksByUser] = useState<
        MobileSystematicTaskListItemDto[] | undefined
    >(undefined)

    const { data: tasks, loading } = useApi<MobileSystematicTaskListItemDto[]>(
        `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/mobile-tasks/my`,
    )

    useEffect(() => {
        const mobileSystematicTasks =
            tasks?.map(task => ({
                ...task,
                responsible: users.find(user => user.id === task.responsibleId)?.name,
                created: formatDate(task.created),
                updated: formatDate(task.updated),
            })) ?? []

        setMobileSystematicTasksByUser(mobileSystematicTasks)
    }, [tasks, users])

    return {
        loading,
        mobileSystematicTasksByUser,
    }
}

export const useMobileSystematicTasks = () => {
    const { state } = getState()
    const { users } = state
    const [mobileSystematicTasks, setMobileSystematicTasks] = useState<
        MobileSystematicTaskListItemDto[] | undefined
    >(undefined)

    const {
        data: tasks,
        loading,
        post,
        doDelete,
        triggerRefetch,
    } = useApi<MobileSystematicTaskListItemDto[]>(
        `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/mobile-tasks`,
    )

    useEffect(() => {
        const mobileSystematicTasks =
            tasks?.map(task => ({
                ...task,
                responsible: users.find(user => user.id === task.responsibleId)?.name,
                created: formatDate(task.created),
                updated: formatDate(task.updated),
            })) ?? []

        setMobileSystematicTasks(mobileSystematicTasks)
    }, [tasks, users])

    return {
        loading,
        mobileSystematicTasks,
        createSystematicTask: (task: MobileSystematicTaskDto) =>
            post(task).then(() => triggerRefetch()),
        deleteSystematicTaskTemplate: (id: number) =>
            doDelete(`${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/mobile-tasks/${id}`).then(
                () => triggerRefetch(),
            ),
    }
}

export const useMobileSystematicTask = (taskId: number) => {
    const {
        data: task,
        loading,
        post: update,
    } = useApi<MobileSystematicTaskDto>(
        `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/mobile-tasks/${taskId}`,
        {
            suppressFetch: !taskId,
        },
    )

    const closeSystematicTask = async (sysId: number) => {
        const axios = await getAuthedAxios()

        const url = `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/tasks/close/${sysId}`

        const response = await axios.post(url)

        return response.data
    }

    return {
        task,
        loading,
        closeSystematicTask,
        updateSystematicTask: (task: MobileSystematicTaskDto) => update(task),
    }
}
