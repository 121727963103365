import React from 'react'
import { handleAxiosError } from '../../../actions/error'
import { useTranslation } from 'react-i18next'
import { getState } from '../../../state'
import {
    Table,
    Button,
    Container,
    Segment,
    Header,
    Grid,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { Plus } from 'react-feather'
import InlineConfirmDelete from '../../common/inlineConfirmDelete'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import * as API from '../../../api'
import { useMutation } from 'react-query'
import Loading from '../../common/Loading'

const AdminTemplateListPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { dispatch } = getState()
    const {
        isLoading: isLoadingTemplate,
        data: templates,
        refresh
    } = useGetQueryByParams(true, API.projects.getAllTemplates, "projectTemplates")

    const destroyTemplate = async (templateId: string) => await API.projects.deleteTemplate(templateId)

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(destroyTemplate, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            refresh()
        },
    })

    if (isLoadingTemplate || !templates) {
        return <Loading />
    }

    return (
        <Container>
            <Header dividing>{t('projectTemplateList')}</Header>

            <Segment>
                <Grid>
                    <Grid.Column width={13}>
                        <Header as="h4">{t('projectTemplateAdminIntroText')}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={3}>
                        <Button
                            as={Link}
                            to={`new`}
                            type="button"
                            primary
                            icon
                            labelPosition="left"
                            disabled={isDestroying}
                        >
                            <SemanticFeatherIcon
                                FeatherIcon={Plus}
                                size={'60%'}
                                centerIcon={true}
                            />
                            {t('addNew')}
                        </Button>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Table>
                <TableHeader>
                    <TableRow>
                        <Table.HeaderCell>{t('template')}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {templates.map(t => (
                        <TableRow key={t.id}>
                            <TableCell>
                                <Link to={t.id}>{t.name}</Link>
                            </TableCell>
                            <TableCell textAlign="right">
                                <InlineConfirmDelete onConfirm={() => onDestroy(t.id)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    )
}

export default AdminTemplateListPage
