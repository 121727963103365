import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Flag, Header, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import SupplierDeviationForm from '../../../forms/SupplierDeviationForm'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { AlertOctagon, Info } from 'react-feather'
import styled from 'styled-components'
import { BackgroundColor } from '../../../../styles'
import i18n from '../../../../i18n'
import { isInPageRenderMode } from '../../../../actions/util'


const Head = styled.div`
    background-color: ${BackgroundColor};
    color: white;
    padding-bottom: 10px;
`
const MenuBar = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 0.5em;
    button {
        margin: 10px !important;
    }
`

const Logo = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 30px;
    background-color: white;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    padding: 5px;

    img {
        max-width: 150px;
    }
`

const SupplierDeviationFormPage = () => {
    const { t } = useTranslation()
    const { sId, companyId, deviationId } = useParams<{ sId: string, companyId: string, deviationId: string }>()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(!!sId && !!deviationId, API.suppliers.getPublicSupplierDeviation, "supplierDeviation", { sId, companyId, deviationId })


    useEffect(() => {
        switch (data?.supplier.language) {
            case "swe":
                i18n.changeLanguage("sv")
                break;
            case "eng":
                i18n.changeLanguage("en")
                break;
            default:
                i18n.changeLanguage("sv")
                break;
        }
    }, [data])
    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <Container>
            <Head>
                <MenuBar>
                    {i18n.language === 'en' ? (
                        <Button
                            type='button'
                            icon={<Flag name="gb" />}
                            onClick={() => i18n.changeLanguage('sv')}
                        />
                    ) : (
                        <Button
                            type='button'
                            icon={<Flag name="sweden" />}
                            onClick={() => i18n.changeLanguage('en')}
                        />
                    )}

                </MenuBar>

                <Logo>
                    <img src={isInPageRenderMode() ? `https://test.viqmasystem.se/loggor/${data.company.logo}` : `https://viqmasystem.se/loggor/${data.company.logo}`} alt={`Logo for ${data.company?.name}`} />
                </Logo>
            </Head>
            <Header>{t('deviationmanagement')}</Header>
            <Message
                color={data.deviation.replied ? "orange" : "blue"}
                content={data.deviation.replied ? t('supplierDeviationReplied', { value: data.company.name }) : t('supplierDeviationInfo', { value: data.company.name })}
                icon={<SemanticFeatherIcon FeatherIcon={data.deviation.replied ? AlertOctagon : Info} centerIcon size="32px" />} />
            {data.deviation.replied ? null : <SupplierDeviationForm response={data} refresh={refresh} />}
        </Container>
    )
}

export default SupplierDeviationFormPage
