import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { List, ListDescription, ListHeader, ListItem } from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate } from '../../../../actions/util'
import { hasModule, MODULES } from '../../../../auth/modules'
import { useResponsiblePosition } from '../../../../hooks/docs/responsiblePosition'
import { useUserWithConcernLookup } from '../../../../hooks/users'
import { getState } from '../../../../state'
import { getPositionFromState } from '../../../../state/helpers'
import { IViqmaDocument } from '../../../../api/documents'

const InfoBox = styled.div`
    margin-left: 30px;
`
const CustomListHeader = styled(ListHeader)`
    margin-bottom: 4px !important;
`
const CustomList = styled(List)`
    padding: 0 !important;
`
export interface PrintViewHeaderProps {
    document: IViqmaDocument
}

const PrintViewHeader: FunctionComponent<PrintViewHeaderProps> = ({ document }) => {
    const { t } = useTranslation()
    const { state } = getState()

    const { user: approveUser } = useUserWithConcernLookup(
        document.approvedBy ?? '',
        document.isConcernDocument,
    )

    const { companyPositions, concernPositions } = useResponsiblePosition(document.id)
    const resolvedCompanyPositions = companyPositions.map(getPositionFromState(state))
    const resolvedConcernPositions = concernPositions.map(getPositionFromState(state))

    return (
        <div className="show-in-print">
            <InfoBox>
                <List>
                    {document.isConcernDocument && (
                        <ListItem>
                            <CustomListHeader>{t('concern')}</CustomListHeader>
                            <ListDescription>{state.concern?.name}</ListDescription>
                        </ListItem>
                    )}
                    {document.version && (
                        <ListItem>
                            <CustomListHeader>{t('version')}</CustomListHeader>
                            <ListDescription>{document.version}</ListDescription>
                        </ListItem>
                    )}
                    {document.lastChanged && (
                        <ListItem>
                            <CustomListHeader>{t('date')}</CustomListHeader>
                            <ListDescription>
                                {formatDate(document.lastChanged)}
                            </ListDescription>
                        </ListItem>
                    )}
                    {approveUser?.name && (
                        <ListItem>
                            <CustomListHeader>{t('approvedBy')}</CustomListHeader>
                            <ListDescription>{approveUser.name}</ListDescription>
                        </ListItem>
                    )}

                    {!!resolvedConcernPositions?.length && (
                        <ListItem>
                            <CustomListHeader>{t('responsiblePositionConcern')}</CustomListHeader>
                            <ListDescription>
                                <CustomList>
                                    {resolvedConcernPositions.map(x => (
                                        <ListItem key={x?.id}>{x?.name}</ListItem>
                                    ))}
                                </CustomList>
                            </ListDescription>
                        </ListItem>
                    )}

                    {resolvedCompanyPositions && hasModule(state)(MODULES.SKILLS) && (
                        <ListItem>
                            <CustomListHeader>{t('responsiblePosition')}</CustomListHeader>
                            <ListDescription>
                                <CustomList>
                                    {resolvedCompanyPositions.map(x => (
                                        <ListItem key={x?.id}>{x?.name}</ListItem>
                                    ))}
                                </CustomList>
                            </ListDescription>
                        </ListItem>
                    )}
                </List>
            </InfoBox>
        </div>
    )
}

export default PrintViewHeader
