import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../api'
import { ICompetenceUserDto, ICompetenceUserRequest } from '../../../api/skills';
import FormActions from '../FormActions';
import ControlDropdown from '../../fields/ControlDropdown';
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams';
import Loading from '../../common/Loading';
import { mapUserToCompetenceUserRequest } from '../../../utils/Mappings/Competence';
import Files from '../../common/files';
import UserSkillList from './UserSkillList';
import UserPositionList from './UserPositionList';
import DocumentSectionModule from '../../modules/docs/viewPage/DocumentSectionModule';

export interface ICompetenceUserFormProps {
    user: ICompetenceUserDto
    onCancel: () => void
    refresh: () => void
}

export const competenceUserRequestSchema = z.object({
    id: z.string().min(1, "required"),
    departmentId: z.coerce.number().optional().nullable(),
    locationId: z.coerce.number().optional().nullable(),
    positionIds: z.array(z.number()),
    skillIds: z.array(z.number()),

});

const CompetenceUserForm = ({ user, onCancel, refresh }: ICompetenceUserFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
        state: { locations, departments },
    } = getState()
    const { control, handleSubmit } = useForm<ICompetenceUserRequest>({
        defaultValues: mapUserToCompetenceUserRequest(user),
        resolver: zodResolver(competenceUserRequestSchema),
    });

    const {
        isLoading: isLoadingSkills,
        data: skills,
    } = useGetQueryByParams(true, API.skills.getSkillsListView, "skillList")

    const onUserRequest = async (data: ICompetenceUserRequest) => await API.skills.editCompetenceUser(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onUserRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    if (isLoadingSkills || !skills) {
        return <Loading />
    }

    return (
        <>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <FormActions disabled={isSaving} onCancel={onCancel} />
                <Grid stackable>
                    <GridRow columns="2">
                        <GridColumn >
                            <ControlDropdown name="departmentId" label={t('departmentId')} control={control} required collection={departments.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} clearable />
                        </GridColumn>
                        <GridColumn >
                            {locations && locations.length ? <ControlDropdown name="locationId" label={t('locationId')} control={control} collection={locations?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} clearable /> : null}
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Form>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn>
                        <UserPositionList userPositions={user.positions} userId={user.id} refresh={refresh} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <UserSkillList userSkills={user.skills} userId={user.id} refresh={refresh} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <DocumentSectionModule title={t('files')} attachTop>
                            <Files
                                mayAutoUpload
                                module="CompetenceUser"
                                enableDragDrop
                                collectionId={user.id}
                            />
                        </DocumentSectionModule>

                    </GridColumn>
                </GridRow>
            </Grid>
        </>

    );
};

export default CompetenceUserForm;