import React from "react"
import { Controller, FieldValues, Control } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormDropdown, Popup } from "semantic-ui-react"
import styled from "styled-components"
import SemanticFeatherIcon from "../../icons/SemanticFeatherIcon"
import { HelpCircle } from "react-feather"
import FlexBox from "../../common/flexBox"
const CustomInputLabel = styled.label<{ required: boolean }>`
    display: block;
    margin: 0 0 0.28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
    ${props => props.required && `
    :after {
display: inline-block;
    vertical-align: top;
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
    };
    `}
    
`
export interface IDropdownOption {
    value: string | number | boolean
    text: string
}

interface IControlDropdownProps<U extends IDropdownOption, T extends FieldValues = any> {
    name: string
    control: Control<T>
    collection: U[]
    disabled: boolean
    label: string
    overrideOnChange?: (value: any) => void
    className?: string
    required?: boolean
    popupText?: string
    clearable?: boolean
    multiple?: boolean
    explanatoryText?: string
}

const ControlDropdown = <U extends IDropdownOption>({
    name,
    control,
    collection,
    disabled,
    label,
    overrideOnChange,
    className,
    required = false,
    popupText = '',
    clearable = false,
    multiple = false,
    explanatoryText
}: IControlDropdownProps<U>) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Popup
                    content={popupText}
                    disabled={!popupText}
                    trigger={
                        <span>
                            <FormDropdown
                                label={explanatoryText ? <FlexBox gap="6px"><CustomInputLabel required={required}>{label}</CustomInputLabel><Popup
                                    content={explanatoryText}
                                    trigger={
                                        <SemanticFeatherIcon FeatherIcon={HelpCircle} size="32px" centerIcon />

                                    } /></FlexBox> : <label>{label}</label>}
                                fluid
                                required={required}
                                selection
                                value={value}
                                disabled={disabled}
                                options={collection}
                                clearable={clearable}
                                multiple={multiple}
                                search
                                onChange={(e, { value }) => overrideOnChange ? overrideOnChange(value) : onChange(value)}
                                error={!!error && { content: t(`${error.message}`), }}
                                className={className}
                            />
                        </span>
                    }
                />
            )}
        />
    )
}
export default ControlDropdown