import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LineColor } from '../../../styles'
import TabHandle from './tabHandle'

const TabsContainer = styled.div`
    display: flex;
    border-bottom: 1px solid ${LineColor};
    padding-left: 20px;
`

const TabContentContainer = styled.div`
    padding-top: 5px;
`

export interface Tab {
    title: string
    component: React.ReactNode
}

export interface TabsProps {
    tabs: { [key: string]: Tab }
}

const Tabs: React.FunctionComponent<TabsProps> = ({ tabs }) => {
    const [currentTab, setCurrentTab] = useState<string>()

    useEffect(() => {
        if (currentTab) return

        const keys = Object.keys(tabs ?? {})
        if (keys.length === 0) return

        setCurrentTab(keys[0])
    }, [tabs, currentTab, setCurrentTab])

    const renderTabs = () => (
        <TabsContainer>
            {Object.entries(tabs).map(([key, tab]) => (
                <TabHandle
                    key={key}
                    title={tab.title}
                    isSelected={currentTab === key}
                    onSelected={() => setCurrentTab(key)}
                />
            ))}
        </TabsContainer>
    )

    return (
        <>
            {renderTabs()}
            <TabContentContainer>{currentTab && tabs[currentTab]?.component}</TabContentContainer>
        </>
    )
}

export default Tabs
