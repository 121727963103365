type Dictionary<T> = { [key: string]: T };

export function dictionaryToArray<T>(dict: Dictionary<T>): Array<{ key: string } & T> {
    return Object.entries(dict).map(([key, values]) => ({
        key,
        ...values
    }));
}

/* eslint-disable no-useless-escape */
export const sanitizeKeyName = (keyName: string) => {
    return keyName
        .replace(/\./g, '')   // Replace dots
        .replace(/[\[\]]/g, '') // Replace brackets
        .replace(/[\/\\]/g, '') // Replace forward and backslashes
        .replace(/[:;,&_]/g, '') // Replace colon, semicolon, comma, ampersand
        .replace(/['"`]/g, '') // Replace quotes and backticks
}