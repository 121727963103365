import React, { useState, useEffect, useMemo } from 'react'
import ReactDataGrid, { Row, SortDirection, RowsUpdateEvent, DataGridProps } from 'react-data-grid'
import GridToolbar from './toolbar'
import { AutoSizer } from 'react-virtualized'
import { getState } from '../../../state'
import styled from 'styled-components'
import ActionButton from '../actionButton'
import { getPageUniqueStorageKey, load, store } from '../../../actions/persist'
import { ViqmaDataGridProps } from './interfaces'
import { generateReport } from './actions'
import { DraggableHeaderRenderer, ColumnDragObject } from './dragableHeaderRenderer'
import * as Data from './data'
import RenderSelectingRowRenderer from './renderSelectingRowRenderer'
import GridContainer from './gridContainer'
import { Input } from 'semantic-ui-react'

const SimpleTextFilter: React.FunctionComponent<any> = ({ value, onChange }) => (
    <Input fluid value={value ?? ''} onChange={(_, d) => onChange(d.value)} />
)

const defaultColumnProperties = {
    editable: false,
    draggable: true,
    resizable: true,
    sortable: true,
    groupable: true,
    filterRenderer: SimpleTextFilter,
}

const sortRows =
    (initialRows: any[], sortColumn: string, sortDirection: string) => (rows: any[]) => {
        const comparer = (a: any, b: any) => {
            if (sortDirection === 'ASC') {
                return (a[sortColumn] ?? '') > (b[sortColumn] ?? '') ? 1 : -1
            } else {
                return (a[sortColumn] ?? '') < (b[sortColumn] ?? '') ? 1 : -1
            }
        }

        return sortDirection === 'NONE' || sortColumn === ''
            ? initialRows
            : [...rows].sort(comparer)
    }

const BottomButtonsContainer = styled.div`
    margin-top: 5px;
`

const DataGrid: React.FunctionComponent<ViqmaDataGridProps> = ({
    columns,
    rows,
    displayFilters,
    enableFiltersInitial,
    displayAddButton,
    displayToolbar,
    onAddCallback,
    onRowClick,
    rowRenderer,
    extraFilter,
    loading,
    toolbarControls,
    autoResize,
    stretchHeight,
    onRowUpdated,
    onSummaryRowUpdate,
    reportSettings,
    displayColumnHiding,
    defaultSortColumn,
    defaultSortDirection,
}) => {
    const [groupBy, setGroupBy] = useState<ColumnDragObject[]>([])
    const [groupedRows, setGroupedRows] = useState<any[]>([])
    const [filteredRows, setFilteredRows] = useState<any[]>([])
    const [filters, setFilters] = useState(extraFilter ?? {})
    const [enableFilters, setEnableFilters] = useState(enableFiltersInitial ?? false)
    const [sortDirection, setSortDirection] = useState<SortDirection>(
        defaultSortDirection ?? 'NONE',
    )
    const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn ?? '')
    const [summaryRows, setSummaryRows] = useState<any[]>([])
    const [trackedColumns, setTrackedColumns] = useState<any[]>([])
    const { dispatch } = getState()

    useEffect(() => {
        setSortColumn(defaultSortColumn ?? '')
        setSortDirection(defaultSortDirection ?? 'NONE')
    }, [defaultSortColumn, defaultSortDirection])

    useEffect(() => {
        const newFilteredRows = rows.filter(row => {
            return !Object.entries<string>(filters ?? {}).some(([key, filterTerm]) => {
                const value = row[key]
                return value
                    ? value.toString().toLowerCase().indexOf(filterTerm.toLowerCase()) === -1
                    : true
            })
        })

        setFilteredRows(sortRows(newFilteredRows, sortColumn, sortDirection)(newFilteredRows))
    }, [filters, rows, sortColumn, sortDirection])

    useEffect(() => {
        setFilters(extraFilter)
    }, [extraFilter])

    useEffect(() => {
        if (onSummaryRowUpdate) {
            const dataRows: any[] = []
            const summaryRows: any[] = []
            let lastHeaderRow: any = null

            const calculateSummaryRowIfApplicable = (i: number) => {
                if (dataRows.length > 0) {
                    summaryRows.push({
                        rowIdX: i,
                        __metaData: {
                            isGroupSummary: true,
                        },
                        ...onSummaryRowUpdate(dataRows),
                    })

                    if (lastHeaderRow !== null)
                        lastHeaderRow.__metaData.childCount = dataRows.length

                    dataRows.length = 0
                }
            }

            groupedRows.forEach((row, i) => {
                if (row.__metaData?.isGroup) {
                    calculateSummaryRowIfApplicable(i)
                    lastHeaderRow = row
                } else {
                    dataRows.push(row)
                }
            })

            calculateSummaryRowIfApplicable(groupedRows.length)
            setSummaryRows(summaryRows)
        }
    }, [groupedRows, onSummaryRowUpdate])

    useEffect(() => {
        if (!groupBy.length) {
            setGroupedRows(filteredRows)
        } else {
            const groupByKeys = groupBy.map(x => x.key)
            const groupedRows = Data.Selectors.getRows({ rows: filteredRows, groupBy: groupByKeys })
            setGroupedRows(groupedRows)
        }
    }, [filteredRows, groupBy])

    useEffect(() => {
        const mergedColumns = columns.map(c => ({ ...defaultColumnProperties, ...c }))

        const storageKey = getPageUniqueStorageKey('hiddenColumns')
        let hiddenColumns = load<{ [id: string]: boolean }>(storageKey) ?? {}
        if (Array.isArray(hiddenColumns)) hiddenColumns = {}

        mergedColumns.forEach(c => {
            ;(c as any).hidden = hiddenColumns[c.key] ?? c.defaultHidden ?? false
        })

        setTrackedColumns([...mergedColumns])
    }, [columns])

    const onColumnHiddenChanged = (key: string, hidden: boolean) => {
        const storageKey = getPageUniqueStorageKey('hiddenColumns')

        setTrackedColumns(columns => {
            const column = columns.find(x => x.key === key)
            column.hidden = hidden

            const hiddenColumns = load<{ [id: string]: boolean }>(storageKey) ?? {}
            hiddenColumns[key] = hidden
            store(storageKey, hiddenColumns)

            return [...columns]
        })
    }

    const onGridSort = (sortColumn: string, sortDirection: any) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)

        setFilteredRows(sortRows(filteredRows, sortColumn, sortDirection))
    }

    const onFilteringToggle = () => {
        setEnableFilters(!enableFilters)
    }

    const preparedColumns = useMemo(
        () =>
            trackedColumns
                .filter(c => !c.hidden)
                .map(c =>
                    c.groupable && c.id !== 'id'
                        ? {
                              ...c,
                              headerRenderer: (props: any) => (
                                  <DraggableHeaderRenderer {...props} {...c} />
                              ),
                          }
                        : c,
                ),
        [trackedColumns],
    )

    const mergeRows = () => {
        if (summaryRows?.length > 1) {
            const mergedRows = []

            for (let i = 0; i <= groupedRows.length; i++) {
                const summaryRow = summaryRows.find(x => x.rowIdX === i)
                if (summaryRow) mergedRows.push(summaryRow)

                if (i < groupedRows.length) mergedRows.push(groupedRows[i])
            }

            return mergedRows
        }

        return groupedRows
    }

    const mergedRows = mergeRows()

    const dataGridProps: DataGridProps<any, any> = {
        columns: preparedColumns,
        rows: mergedRows,
        onSort: onGridSort,
        enableFilters,
        onFiltersChange: (filters: any) => setFilters(filters),
        enableCellAutoFocus: false,
        onRowClick: preparedColumns.filter(c => c.editable).length ? undefined : onRowClick,
        rowRenderer: (props: any) => (
            <RenderSelectingRowRenderer {...props} innerRenderer={rowRenderer ?? Row} />
        ),
        sortDirection,
        sortColumn,
        filters,
        summaryRows: summaryRows?.length > 1 ? [] : summaryRows,
        onRowsUpdate: (event: RowsUpdateEvent<any>) => {
            const originalRowNumber = rows.findIndex(x => x === mergedRows[event.fromRow])
            if (originalRowNumber !== -1)
                onRowUpdated?.(originalRowNumber, event.updated, mergedRows[event.fromRow])
            else onRowUpdated?.(event.fromRow, event.updated, mergedRows[event.fromRow])
        },
        rowHeight:50,
    }

    if (stretchHeight) {
        dataGridProps.height =
            (dataGridProps.rowHeight ?? 35) * (1 + mergedRows.length) +
            (dataGridProps.headerFiltersHeight ?? 45)
    }

    return (
        <GridContainer loading={loading} autoResize={autoResize}>
            {displayToolbar && (
                <GridToolbar
                    displayAddButton={displayAddButton}
                    displayFilters={displayFilters}
                    displayColumnHiding={displayColumnHiding}
                    onAddCallback={onAddCallback}
                    onFilteringToggled={onFilteringToggle}
                    controls={toolbarControls}
                    onGroupByChanged={groupBy => setGroupBy(groupBy)}
                    onColumnHiddenChanged={onColumnHiddenChanged}
                    trackedColumns={trackedColumns}
                />
            )}

            {autoResize ? (
                <div style={{ flex: 1 }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <ReactDataGrid {...dataGridProps} height={height} width={width} />
                        )}
                    </AutoSizer>
                </div>
            ) : (
                <ReactDataGrid {...dataGridProps} />
            )}

            <BottomButtonsContainer>
                {reportSettings?.excel && (
                    <ActionButton
                        icon="file excel outline"
                        action={() =>
                            generateReport(dispatch)(0, reportSettings, groupedRows, trackedColumns)
                        }
                    />
                )}
                {reportSettings?.pdf && (
                    <ActionButton
                        icon="file pdf outline"
                        action={() =>
                            generateReport(dispatch)(1, reportSettings, groupedRows, trackedColumns)
                        }
                    />
                )}
            </BottomButtonsContainer>
        </GridContainer>
    )
}

export default DataGrid
