import React, { ReactNode } from "react"
import PrintHeader from "./PrintHeader"
import styled from "styled-components"
import { Label } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
const FooterWrapper = styled.div`
    margin-top: 5em;
`
interface IPrintWrapperProps {
    children: ReactNode
    title: string
    fallbackToViqmaLogo?: boolean
    headerContent?: ReactNode
}

const PrintWrapper = ({ children, title, headerContent, fallbackToViqmaLogo = false }: IPrintWrapperProps) => {
    const { t } = useTranslation()

    return (
        <div >
            <PrintHeader title={title} fallbackToViqmaLogo={fallbackToViqmaLogo} headerContent={headerContent} />
            {children}
            <FooterWrapper className="show-in-print">
                <Label basic>{t('thisIsACopyCheckOriginal')}</Label>
            </FooterWrapper>
        </div>
    )
}

export default PrintWrapper