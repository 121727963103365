import useApi from '../useApi'
import { IViqmaDocument, IViqmaUpdateDocument } from '../../api/documents'

export const useDocumentAdmin = (documentId?: string) => {
    const { data, loading, post } = useApi<IViqmaDocument, IViqmaUpdateDocument>(
        documentId ? `${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}` : undefined,
        {
            dispatchErrors: true,
        },
    )

    return {
        loading,
        existingDocument: data,
        save: (document: IViqmaUpdateDocument) =>
            documentId
                ? post(document)
                : post(document, `${window.env.REACT_APP_DOC_SERVICE}/api/document/`),
    }
}
