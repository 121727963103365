import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getModule } from '../../auth/modules'

const IconContainer = styled.i<{ centerIcon: boolean }>`
    vertical-align: top;

    ${props =>
        props.centerIcon
            ? `
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        `
            : ''}
`

export interface ModuleIconProps {
    moduleId: number
    size?: string | number
    dark?: boolean
    centerIcon?: boolean
}

const ModuleIcon: React.FunctionComponent<ModuleIconProps> = ({
    moduleId,
    size = 16,
    dark = false,
    centerIcon = false,
}) => {
    const module = getModule(moduleId, () => '')
    if (!module) return null

    return (
        <IconContainer className="icon" centerIcon={centerIcon}>
            <img
                src={`/images/moduleicons/${dark ? module.imageDark : module.image}`}
                aria-hidden="true"
                style={{
                    width: typeof size == 'number' ? `${size}px` : size,
                }}
            />
        </IconContainer>
    )
}

export default ModuleIcon
