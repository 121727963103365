import React from "react";

const CustomBarLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    return (
        <text
            x={x + width / 2}
            y={y + height / 2}
            fill="#000"
            textAnchor="middle"
            dominantBaseline="middle"
        >
            {value ? value : ""}
        </text>
    );
};
export default CustomBarLabel