import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab } from 'semantic-ui-react'
import { handleAxiosError } from '../../actions/error'
import { getModuleSummary, SummaryDataItem, getTabOrder } from '../../actions/startpage'
import { hasModule } from '../../auth/modules'
import { useNews } from '../../hooks/start/news'
import { getState } from '../../state'
import NewsView from './newsView'
import StatisticsView from './statisticsView'
import { useLanguage } from '../../hooks/language'
import { getSummaryDataUri } from './dataUris'
import { buildPanes, ModuleMenuItem, StyledSematicIcon } from './pane'
import { useRowInfoFunction } from './rowInfo'

export interface StartPageTabsProps {
    responsibleId?: string
    timePeriod?: number
}

const StartPageTabs: React.FunctionComponent<StartPageTabsProps> = ({
    responsibleId,
    timePeriod,
}) => {
    const { t } = useTranslation()
    const language = useLanguage()
    const { state, dispatch } = getState()
    const [moduleData, setModuleData] = useState<{ [id: number]: SummaryDataItem[] }>({})
    const { data: news, isValidating: newsLoading } = useNews()
    const getRowInfo = useRowInfoFunction()

    useEffect(() => {
        if (responsibleId === undefined) return

        function fildt() {
            getTabOrder()
                .filter(moduleId => hasModule(state)(moduleId))
                .forEach(moduleId => {
                    getModuleSummary(
                        state,
                        t,
                        getSummaryDataUri(responsibleId, timePeriod, language)[moduleId],
                        getRowInfo,
                    )(moduleId)
                        .then(value =>
                            setModuleData(moduleData => ({ ...moduleData, [moduleId]: value })),
                        )
                        .catch(handleAxiosError(dispatch))
                })
        }
        fildt()
    }, [responsibleId, timePeriod])

    const enableReminders = !responsibleId

    const panes = buildPanes(moduleData, t, getRowInfo, enableReminders)

    if (!news || news.length > 0) {
        panes.splice(0, 0, {
            key: 'news',
            menuItem: (
                <ModuleMenuItem key="news">
                    <StyledSematicIcon name="newspaper outline" />{' '}
                    <span className="text">{t('news')}</span>
                </ModuleMenuItem>
            ),
            render: () => <NewsView news={news} newsLoading={newsLoading} />,
        })
    }

    panes.push({
        key: 'statistics',
        menuItem: (
            <ModuleMenuItem key="statistics">
                <StyledSematicIcon name="line graph" />{' '}
                <span className="text">{t('statistics')}</span>
            </ModuleMenuItem>
        ),
        render: () => <StatisticsView />,
    })

    return <Tab panes={panes as any} menu={{ fluid: true, vertical: true, tabular: true }} />
}

export default StartPageTabs
