import { IProjectDetailsDto, IProjectRequest } from "../../../api/projects";

export const mapProjectDates = (project: IProjectDetailsDto): IProjectRequest => ({
    ...project,
    reviewDate: project.reviewDate ? new Date(project.reviewDate) : null,
    endedDate: project.endedDate ? new Date(project.endedDate) : null,
    startDate: project.startDate ? new Date(project.startDate) : null,
    endDate: project.endDate ? new Date(project.endDate) : null,
    activities: project.activities.map(activity => ({
        ...activity,
        doneBefore: activity.doneBefore ? new Date(activity.doneBefore) : null,
        dateDone: activity.dateDone ? new Date(activity.dateDone) : null,
    })),
});