import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Form, Grid, GridColumn, GridRow, Label } from 'semantic-ui-react';
import * as z from 'zod';
import ControlInput from '../../fields/ControlInput';
import { getState } from '../../../state';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api'
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../actions/error';
import { toast } from 'react-toastify';

import FormActions from '../FormActions';
import ControlCheckbox from '../../fields/ControlCheckbox';
import { Plus } from 'react-feather';
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon';
import { IExtraFieldTemplate, ModuleCollection } from '../../../api/issues';
import { dictionaryToArray } from '../../../utils/Mappings/Objects';
import ControlDropdown from '../../fields/ControlDropdown';
import { DynamicFieldType } from '../../common/dynamicFields';
import { forbiddenChars } from '../../../utils/Validation';

interface BatchExtraFieldFormProps {
    onCancel: () => void
    refresh: () => void
    moduleCollection: ModuleCollection
    extraFieldTemplate?: IExtraFieldTemplate
}
export interface IBatchExtraFieldFormTypes {
    moduleCollection: ModuleCollection
    extraFields: {
        type: number;
        required: boolean;
        key: string;
    }[]
}
const extraFieldRequestSchema = z.object({
    moduleCollection: z.nativeEnum(ModuleCollection),
    extraFields: z.array(z.object({
        key: z.string()
            .min(1, "required")
            .refine(value => !forbiddenChars.test(value), {
                message: "fieldNameError",
            }),
        type: z.coerce.number(),
        required: z.boolean()
    }))
});

const BatchExtraFieldForm = ({
    onCancel,
    refresh,
    moduleCollection,
    extraFieldTemplate,
}: BatchExtraFieldFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit, formState, setError } = useForm<IBatchExtraFieldFormTypes>({
        resolver: zodResolver(extraFieldRequestSchema),
        defaultValues: { extraFields: [], moduleCollection: moduleCollection }
    });

    const { fields, append } = useFieldArray({
        control,
        name: "extraFields",
    })
    const validateUniqueKeys = (data: IBatchExtraFieldFormTypes) => {
        const keyMap = new Map();
        let isValid = true;
        const originalExtraFields = extraFieldTemplate ? dictionaryToArray(extraFieldTemplate.extraFields) : [];
        const extraFields = [...data.extraFields, ...originalExtraFields]
        extraFields.forEach((field) => {
            if (keyMap.has(field.key)) {
                setError(`root`, {
                    type: "duplicate",
                    message: "keyMustBeUnique"
                });
                isValid = false;
            } else {
                keyMap.set(field.key, true);
            }
        });

        return isValid;
    };

    const onExtraFieldRequest = async (data: IBatchExtraFieldFormTypes) => {
        if (validateUniqueKeys(data)) {
            const objectToSave = {
                moduleCollection: moduleCollection,
                extraFields: data.extraFields.reduce((acc: any, { key, ...rest }) => {
                    acc[key] = rest;
                    return acc;
                }, {}),
            };
            if (extraFieldTemplate) {
                return await API.issues.batchEditNewExtra(extraFieldTemplate.id, objectToSave)
            }
            return await API.issues.createExtraFields(objectToSave)
        }

    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onExtraFieldRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t("saved"))
                refresh()
                onCancel()
            }

        },
    })

    return (

        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow >
                    <GridColumn>
                        <Button content={t('new')}
                            icon={<SemanticFeatherIcon FeatherIcon={Plus} />}
                            primary onClick={() => append({ key: "", type: 0, required: false })} />
                    </GridColumn>
                </GridRow>

                {formState.errors.root &&
                    <GridRow >
                        <GridColumn>
                            <Label basic color='red'>
                                {t(`${formState.errors.root.message}`)}
                            </Label>
                        </GridColumn>
                    </GridRow>
                }
                {fields.map((field, index) => (
                    <React.Fragment key={field.id} >

                        <GridRow >
                            <GridColumn >
                                <ControlInput name={`extraFields.${index}.key`} label={t('fieldNameNoChar')} control={control} required disabled={isSaving} />
                            </GridColumn>
                        </GridRow>

                        <GridRow columns="2" >
                            <GridColumn>
                                <ControlDropdown name={`extraFields.${index}.type`} label={t('fieldType')} control={control}
                                    collection={[
                                        { value: DynamicFieldType.String, text: t('string') },
                                        { value: DynamicFieldType.MultipleUserDropdown, text: t('multipleUserDropdown') },
                                    ]}
                                    disabled={isSaving} />
                            </GridColumn>
                            <GridColumn verticalAlign="middle">
                                <ControlCheckbox
                                    name={`extraFields.${index}.required`}
                                    label={t('required')}
                                    control={control}
                                    disabled={isSaving} />
                            </GridColumn>
                        </GridRow>
                        <Divider />
                    </React.Fragment>

                ))}
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default BatchExtraFieldForm;
