import { TFunction } from "react-i18next"

export const BackgroundColor = '#5C6C79'
export const OnBackgroundActiveLinkColor = '#384249'

export const RowIndicatedBackgroundColor = 'rgb(217, 243, 243)'
export const LineColor = 'rgba(34, 36, 38, 0.178)'

export const handleAccidentGraphColors = (x: string, t: TFunction) => {
    switch (x) {
        case t('accidentReason.0'):
        case "0":
            return '#DA253E';
        case t('accidentReason.1'):
        case "1":
            return '#FFC300';
        case t('accidentReason.2'):
        case "2":
            return '#3EA5C1'
        default:
            return '#53AC56';
    }
}

export const getGraphColorsFactory = () => {
    const colors = [
        '#3EA5C1',
        '#DA253E',
        '#53AC56',
        '#D89C00',
        '#E1621E',
        '#B93A39',
        '#B2DBE6',
        '#F0A8B2',
        '#FF5733',
        '#C70039',
        '#900C3F',
        '#581845',
        '#FFC300',
        '#DAF7A6',
        '#FF33FF',
        '#33FF57',
        '#3357FF',
        '#57FF33',
        '#5733FF',
        '#33FFC7',
        '#C733FF',
        '#FFBD33',
        '#FF33A1',
        '#33FFA1',
        '#33A1FF',
        '#A1FF33',
        '#A133FF',
        '#FF3377',
        '#77FF33',
        '#3377FF',
        '#FF7733',
        '#33FF77',
        '#7733FF',
        '#FF33D1',
        '#D1FF33',
        '#33D1FF',
        '#FF77A1',
        '#A1FF77',
        '#3377A1',
        '#A177FF',
        '#FF8833',
        '#3388FF',
        '#88FF33',
        '#FF3388',
        '#88FF88',
        '#8833FF',
        '#FF88A1',
        '#A1FF88',
        '#A188FF',
        '#33FF88',
        '#88A1FF',
    ]

    let i = 0
    return () => colors[i++ % colors.length]
}
