import { useTranslation } from 'react-i18next'

export const createYearOptions = () => {
    const startYear = new Date().getFullYear()

    return Array(10)
        .fill(0)
        .map((_, i) => ({
            key: i + 1,
            value: startYear - i,
            text: `${startYear - i}`,
        }))
}

export const useMonthOptions = () => {
    const { i18n } = useTranslation()

    return Array(12)
        .fill(0)
        .map((_, i) => ({
            value: `m${(i + 1).toString().padStart(2, '0')}`,
            text: new Date(1, i, 1).toLocaleString(i18n.language, { month: 'long' }),
        }))
}

export const createWeekOptions = () => {
    return Array(53)
        .fill(0)
        .map((_, i) => ({
            key: i + 1,
            value: `v${(i + 1).toString().padStart(2, '0')}`,
            text: `v${(i + 1).toString().padStart(2, '0')}`,
        }))
}
