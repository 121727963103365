import React, { useState } from 'react'
import { Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalHeader,
    ModalContent,
    List,
    Loader,
    Dimmer,
    Table,
    Message,
} from 'semantic-ui-react'
import { Goal } from '../../../hooks/goals'
import ErrorBox from '../../common/errorBox'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import DragableGoalRow from './dragableGoalRow'
import GoalGroup from './goalGroup'
import MoveToRootDropTarget from './moveToRootDropTarget'

export const DropRowType = 'GoalRow'

export interface RestructureModalProps {
    goals: Goal[]
    showClosed: boolean
    error: any
    onMovedTo: (idMoved: number, newParent?: number) => void
    loading?: boolean
    trigger: React.ReactNode
}

const RestructureModal: React.FunctionComponent<RestructureModalProps> = ({
    goals,
    showClosed,
    onMovedTo,
    loading,
    trigger,
    error,
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={trigger}
            closeIcon
            size="small"
        >
            <ModalHeader>{t('restructureGoals')}</ModalHeader>
            <ModalContent scrolling>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>

                <Message
                    info
                    icon={<SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />}
                    content={t('restructureGoalsInfoText')}
                />

                <ErrorBox error={error} />

                <MoveToRootDropTarget onMovedTo={id => onMovedTo(id, undefined)} />

                <Table striped>
                    <Table.Body>
                        {goals
                            .filter(g => !g.parentId && (showClosed || g.status === 1))
                            .map(parent => (
                                <Table.Row key={parent.id}>
                                    <Table.Cell>
                                        <GoalGroup
                                            groupId={parent.id!}
                                            onMovedTo={id => onMovedTo(id, parent.id!)}
                                        >
                                            <List.List>
                                                <List.Item>
                                                    <DragableGoalRow
                                                        goal={parent}
                                                        hasChildren={
                                                            !!goals.filter(
                                                                g =>
                                                                    g.parentId === parent.id &&
                                                                    (showClosed || g.status === 1),
                                                            ).length
                                                        }
                                                    />
                                                </List.Item>

                                                <List.Item>
                                                    <List>
                                                        {goals
                                                            .filter(
                                                                g =>
                                                                    g.parentId === parent.id &&
                                                                    (showClosed || g.status === 1),
                                                            )
                                                            .map(child => (
                                                                <List.Item key={child.id}>
                                                                    <DragableGoalRow
                                                                        goal={child}
                                                                        hasChildren={false}
                                                                    />
                                                                </List.Item>
                                                            ))}
                                                    </List>
                                                </List.Item>
                                            </List.List>
                                        </GoalGroup>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </ModalContent>
        </Modal>
    )
}

export default RestructureModal
