import React, { FunctionComponent, ReactNode, useState } from 'react'
import { ArrowLeftCircle, Save } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Form,
    InputOnChangeData,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from 'semantic-ui-react'
import { ExternalDocument } from '../../../../../hooks/externalDocuments/document'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'

export interface ExternalDocumentModalProps {
    trigger: ReactNode
    onCreate?(lawId: ExternalDocument): void
}

const initalData = {
    accountingDocument: '',
    archivingTime: '',
    archivingSite: '',
}

const ExternalDocumentModal: FunctionComponent<ExternalDocumentModalProps> = ({
    trigger,
    onCreate,
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [newExternalDocument, setNewExternalDocument] = useState<ExternalDocument>({
        ...initalData,
    })

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        { name, value }: InputOnChangeData,
    ) => setNewExternalDocument({ ...newExternalDocument, [name]: value })

    const { accountingDocument, archivingTime, archivingSite } = newExternalDocument

    const onClose = () => {
        setNewExternalDocument({ ...initalData })
        setOpen(false)
    }

    return (
        <Modal onClose={onClose} onOpen={() => setOpen(true)} open={open} trigger={trigger}>
            <ModalHeader>{t('addExternalDocument')}</ModalHeader>
            <ModalContent>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Input
                            required
                            fluid
                            value={accountingDocument}
                            label={t('documents')}
                            onChange={handleChange}
                            name="accountingDocument"
                        />
                        <Form.Input
                            required
                            fluid
                            value={archivingSite}
                            label={t('archiveSite')}
                            name="archivingSite"
                            onChange={handleChange}
                        />
                        <Form.Input
                            required
                            fluid
                            value={archivingTime}
                            label={t('archiveTime')}
                            name="archivingTime"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Form>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={!accountingDocument || !archivingTime || !archivingSite}
                    onClick={() => {
                        onClose()
                        onCreate && onCreate(newExternalDocument)
                    }}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </Button>
                <Button onClick={onClose} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default ExternalDocumentModal
