import { FileListing } from '../../components/common/files/types'
import useApi from '../useApi'

export interface FileListingWithDeleted extends FileListing {
    deleted?: string
}

export const useFilesAdmin = (module?: string, companyId?: unknown) => {
    const { data, loading, error, post, triggerRefetch } = useApi<FileListingWithDeleted[]>(
        module && companyId
            ? `${window.env.REACT_APP_FILE_SERVICE}/api/File/Admin/${module}/${companyId}`
            : undefined,
    )

    const unDeleteFile = (fileId: string) =>
        post<void, undefined>(
            undefined,
            `${window.env.REACT_APP_FILE_SERVICE}/api/File/Admin/${fileId}/undelete`,
            false,
        ).then(triggerRefetch)

    return {
        files: data,
        unDeleteFile,
        loading,
        error,
    }
}
