import useSWR from 'swr'
import useApi from '../useApi'

export interface Measurement {
    id?: number
    unit: string
    type: string
    detailedType: string
    periodId?: PeriodType
    measurements: MeasurementData[]
}

export interface MeasurementData {
    id?: number
    timeReference?: string
    year: number
    value: number
    displayKey?: string
}

export enum MeasurementType {
    Standard = 0,
    Custom = 1,
}

export enum PeriodType {
    Yearly = 1,
    Monthly = 2,
    Weekly = 3,
}

export const useGoalMeasurement = (goalId?: string) => {
    const { post, error } = useApi<Measurement, MeasurementData>(undefined, {
        setDataFromPost: false,
    })

    const { data, mutate } = useSWR<Measurement>(
        goalId ? `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/goal/${goalId}` : undefined,
    )

    return {
        data,
        mutate,
        error,
        create: (measurementId: number, data: MeasurementData) =>
            post(
                data,
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${measurementId}/value`,
            ).then(x => {
                mutate()
                return x
            }),
    }
}

export const useMeasurements = (periodType?: PeriodType) => {
    const { data, loading, post, mutate, error, doDelete, triggerRefetch } = useApi<
        Measurement[],
        Measurement
    >(
        `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${
            periodType ? `?period=${periodType}` : ''
        }`,
    )

    return {
        data,
        loading,
        error,
        create: (measurement: Measurement) =>
            post<Measurement>(measurement).then(x => {
                mutate(previousData => [...(previousData ?? []), x])
                return x
            }),
        update: (measurementId: number, measurement: Measurement) =>
            post<Measurement>(
                measurement,
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${measurementId}`,
                false,
            ).then(x => {
                triggerRefetch()
                return x
            }),
        deleteMeasurement: (measurementId: number) =>
            doDelete(`${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${measurementId}`).then(
                () => triggerRefetch(),
            ),
        addValue: (measurementId: number, measurementData: MeasurementData) =>
            post<MeasurementData, MeasurementData>(
                measurementData,
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${measurementId}/value`,
                false,
            ).then(x => {
                triggerRefetch()
                return x
            }),
        updateValue: (
            measurementId: number,
            measurementDataId: number,
            measurementData: MeasurementData,
        ) =>
            post<MeasurementData, MeasurementData>(
                measurementData,
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${measurementId}/value/${measurementDataId}`,
                false,
            ).then(x => {
                triggerRefetch()
                return x
            }),
        deleteValue: (measurementId: number, measurementDataId: number) =>
            doDelete(
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/${measurementId}/value/${measurementDataId}`,
            ).then(() => triggerRefetch()),
    }
}

export const useStandardMeasurements = (periodType?: PeriodType) => {
    const { data, loading, post, mutate, error, doDelete, triggerRefetch } = useApi<
        Measurement[],
        Measurement
    >(
        `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/standard/${
            periodType ? `?period=${periodType}` : ''
        }`,
    )

    return {
        data,
        loading,
        error,
        create: (measurement: Measurement) =>
            post<Measurement>(measurement).then(x => {
                mutate(previousData => [...(previousData ?? []), x])
                return x
            }),
        addValue: (measurementId: number, measurementData: MeasurementData) =>
            post<MeasurementData, MeasurementData>(
                measurementData,
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/standard/${measurementId}/value`,
                false,
            ).then(x => {
                triggerRefetch()
                return x
            }),
        updateValue: (
            measurementId: number,
            measurementDataId: number,
            measurementData: MeasurementData,
        ) =>
            post<MeasurementData, MeasurementData>(
                measurementData,
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/standard/${measurementId}/value/${measurementDataId}`,
                false,
            ).then(x => {
                triggerRefetch()
                return x
            }),
        deleteValue: (measurementId: number, measurementDataId: number) =>
            doDelete(
                `${window.env.REACT_APP_GOAL_SERVICE}/api/measurement/standard/${measurementId}/value/${measurementDataId}`,
            ).then(() => triggerRefetch()),
    }
}
