import React from 'react'
import { Divider, Form, TextArea } from 'semantic-ui-react'
import { QuestionTypeProps } from './questionTypeProps'
import SelectedButton from '../../../common/selectedButton'
import FlexBox from '../../../common/flexBox'

const FreeTextQuestion: React.FunctionComponent<QuestionTypeProps> = ({
    survey,
    question: { addDontKnow },
    currentComment,
    currentAnswer,
    onAnswer,
}) => {
    const { dontKnowText } = survey
    const isRequired = !(addDontKnow && currentAnswer === 0 && !currentComment)

    return (
        <>
            <Form.Field>
                <TextArea
                    value={currentComment ?? ''}
                    required={isRequired}
                    onChange={(_, d) => onAnswer(0, d.value?.toString() ?? '')}
                    rows="10"
                />
            </Form.Field>
            {addDontKnow && (
                <FlexBox alignItems='center' direction='column'>
                    <Divider />

                    <SelectedButton selected={currentAnswer === 0 && !currentComment} onChange={() => onAnswer(0, '')}>
                        {dontKnowText}
                    </SelectedButton>
                </FlexBox>
            )}
        </>
    )
}

export default FreeTextQuestion
