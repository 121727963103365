import React, { useState } from 'react'
import { Edit, Save, Trash2, X } from 'react-feather'
import { Button, Dropdown, Input, Table } from 'semantic-ui-react'
import { MeasurementData, PeriodType } from '../../../hooks/goals/measurement'
import ActionButton from '../../common/actionButton'
import CustomConfirm from '../../common/customConfirm'
import FlexBox from '../../common/flexBox'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { createWeekOptions, useMonthOptions } from '../goalDetails/options'

export interface MeasurementValueRowProps {
    measurementValue: MeasurementData
    onSave: (data: MeasurementData) => Promise<unknown>
    onDelete: (id: number) => Promise<unknown>
    periodType: PeriodType
}

const MeasurementValueRow: React.FunctionComponent<MeasurementValueRowProps> = ({
    measurementValue,
    periodType,
    onSave,
    onDelete,
}) => {
    const monthOptions = useMonthOptions()
    const weekOptions = createWeekOptions()

    const [editingCopy, setEditingCopy] = useState<MeasurementData>()

    const handleChange = (key: string, value: any) => {
        if (!editingCopy) return
        setEditingCopy({ ...editingCopy, [key]: value })
    }

    return (
        <Table.Row key={measurementValue.id}>
            <Table.Cell>
                {!!editingCopy ? (
                    <Input
                        fluid
                        type="number"
                        value={editingCopy.year ?? ''}
                        onChange={(_, d) => handleChange('year', d.value)}
                    />
                ) : (
                    measurementValue.year
                )}
            </Table.Cell>
            {periodType !== PeriodType.Yearly && (
                <Table.Cell>
                    {!!editingCopy ? (
                        <Dropdown
                            fluid
                            value={editingCopy.timeReference ?? ''}
                            onChange={(_, d) => handleChange('timeReference', d.value)}
                            options={periodType === PeriodType.Monthly ? monthOptions : weekOptions}
                            selection
                        />
                    ) : (
                        measurementValue.timeReference
                    )}
                </Table.Cell>
            )}
            <Table.Cell>
                {!!editingCopy ? (
                    <Input
                        fluid
                        type="number"
                        value={editingCopy.value ?? ''}
                        onChange={(_, d) => handleChange('value', d.value)}
                    />
                ) : (
                    measurementValue.value
                )}
            </Table.Cell>
            <Table.Cell collapsing>
                <FlexBox justifyContent="end">
                    {editingCopy ? (
                        <>
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={X} />}
                                onClick={() => setEditingCopy(undefined)}
                                type="button"
                            />
                            <ActionButton
                                icon={<SemanticFeatherIcon FeatherIcon={Save} />}
                                action={() => onSave(editingCopy)}
                                postActionCallback={() => setEditingCopy(undefined)}
                                primary
                                type="button"
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                onClick={() => setEditingCopy({ ...measurementValue })}
                                type="button"
                            />

                            <CustomConfirm
                                onConfirm={() => onDelete(measurementValue.id!)}
                                trigger={
                                    <Button
                                        icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                        negative
                                    />
                                }
                            />
                        </>
                    )}
                </FlexBox>
            </Table.Cell>
        </Table.Row>
    )
}

export default MeasurementValueRow
