import React, { useState, SyntheticEvent, useEffect } from 'react'
import { Message, Dropdown, Form, Radio, CheckboxProps, DropdownProps } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { getState } from '../../state'
import StartPageTabs from './startPageTabs'
import { useUserOptions } from '../../hooks/users'
import styled from 'styled-components'
import ProcessMap from './process'
import { hasModule, MODULES } from '../../auth/modules'
import { RowInfoProvider } from './rowInfo'
import { getStartPageRowInfo } from './startPageRowInfo'

const ONE_MONTH = 1
const THREE_MONTH = ONE_MONTH * 3
const SIX_MONTH = ONE_MONTH * 6
const ONE_YEAR = ONE_MONTH * 12

export const FormGroupWithoutBottomMargin = styled(Form.Group)`
    margin-bottom: 0 !important;
`

const selectedUserToResponsibleId = (id: string | undefined) => {
    if (!id || id !== 'all') return id

    return ''
}

const StartPage = () => {
    const { t } = useTranslation()
    const { state } = getState()
    const { users, userProfile } = state

    const [period, setPeriod] = useState<number>(ONE_MONTH)
    const [selectedUser, setSelectedUser] = useState<string>()
    const userOptions = useUserOptions(u => u.active)

    const onPeriodChange = (e: SyntheticEvent, { value }: CheckboxProps) =>
        setPeriod(value as number)

    useEffect(() => {
        if (userOptions.find(u => u.value === userProfile?.sub))
            setSelectedUser(userProfile?.sub ?? '')
        else setSelectedUser('all')
    }, [users, userProfile])

    userOptions.splice(0, 0, { key: 'all', value: 'all', text: t('all') })

    return (
        <>
            {hasModule(state)(MODULES.DOCS) ? <ProcessMap /> : null}

            {hasModule(state)(MODULES.START) ? (
                <>
                    <Message>
                        <Form>
                            <FormGroupWithoutBottomMargin inline>
                                <Form.Field>
                                    <Dropdown
                                        options={userOptions}
                                        value={selectedUser}
                                        onChange={(e: SyntheticEvent, { value }: DropdownProps) =>
                                            setSelectedUser(value as string)
                                        }
                                        selection
                                        search
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label={`1 ${t('month')}`}
                                        value={ONE_MONTH}
                                        name="period"
                                        checked={period === ONE_MONTH}
                                        onChange={onPeriodChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label={`3 ${t('month')}`}
                                        value={THREE_MONTH}
                                        name="period"
                                        checked={period === THREE_MONTH}
                                        onChange={onPeriodChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label={`6 ${t('month')}`}
                                        value={SIX_MONTH}
                                        name="period"
                                        checked={period === SIX_MONTH}
                                        onChange={onPeriodChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label={`1 ${t('year')}`}
                                        value={ONE_YEAR}
                                        name="period"
                                        checked={period === ONE_YEAR}
                                        onChange={onPeriodChange}
                                    />
                                </Form.Field>
                            </FormGroupWithoutBottomMargin>
                        </Form>
                    </Message>

                    <RowInfoProvider getRowInfo={getStartPageRowInfo()}>
                        <StartPageTabs
                            responsibleId={selectedUserToResponsibleId(selectedUser)}
                            timePeriod={period}
                        />
                    </RowInfoProvider>
                </>
            ) : null}
        </>
    )
}

export default StartPage
