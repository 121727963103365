import { Action } from '../reducer'
import Axios, { AxiosError } from 'axios'
import { Error } from '../state/initial'

export const decodeErrorObject = (error: any) => {
    let topic, message

    if (!error)
        return {
            topic: 'Error',
            message: 'An unknown error has occurred',
        }

    console.log({ ...error })

    if (error.response) {
        const { response } = error
        topic =
            response?.statusText && response?.statusText !== ''
                ? response.statusText
                : response?.status.toString() ?? ''
    } else {
        topic = ''
    }

    if (error?.response?.data?.message) {
        message = error?.response?.data?.message
    } else if (error?.response?.data && typeof error.response.data === 'string') {
        message = error.response.data
    } else if (error?.response?.data?.errors) {
        message = Object.entries(error.response!.data.errors).reduce(
            (acc: string, [_, value]) =>
                acc + (value as string[]).reduce((acc, cur) => (acc === '' ? cur : `, ${cur}`), ''),
            '',
        )
    } else if (error?.message) {
        message = error?.message
    } else if (error.response) {
        message = JSON.stringify(error.response)
    } else {
        message = JSON.stringify(error)
    }

    return {
        topic,
        message,
    }
}

export const handleAxiosError =
    (dispatch: React.Dispatch<Action>) => (axiosErrorResponse: AxiosError) => {
        const { topic, message } = decodeErrorObject(axiosErrorResponse)

        dispatch({
            type: 'gotError',
            error: {
                topic,
                message,
            },
        })
    }

export const displayError =
    (dispatch: React.Dispatch<Action>) => (topic: string, message: string) => {
        console.error(topic, message)
        publishError(topic, message)

        dispatch({
            type: 'gotError',
            error: {
                topic,
                message,
            },
        })
    }

export const clearError = (dispatch: React.Dispatch<Action>) => (error: Error) => {
    dispatch({
        type: 'clearError',
        error,
    })
}

export const publishError = (...errorArgs: any[]) => {
    const data = errorArgs.reduce((acc, cur, curIndex) => ({ ...acc, [curIndex]: cur }), {})

    Axios.post(`${window.env.REACT_APP_COMPANY_SERVICE}/api/error`, {
        data,
    })
}
