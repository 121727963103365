import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate } from '../../../actions/util'
import { News } from '../../../hooks/start/news'
import { LineColor } from '../../../styles'

const NewsContainer = styled.div``

const NewsContainerInner = styled.div`
    &:not(:last-child) {
        margin-bottom: 20px;
        border-bottom: 1px solid ${LineColor};
    }

    &:not(:first-child) {
        margin-top: -5px;
    }

    pre {
        font-family: unset;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    button {
        display: block !important;
        margin: 10px auto 0 auto !important;
    }
`

const NewsImage = styled.img`
    max-width: 100%;
`

export interface CollapsingNewsRendererProps {
    news?: News[]
}

const CollapsingNewsRenderer: React.FunctionComponent<CollapsingNewsRendererProps> = ({ news }) => {
    return (
        <NewsContainer>
            {news?.map(x => (
                <CollapsingNewsRendererInner key={x.id} news={x} />
            ))}
        </NewsContainer>
    )
}

const CollapsingNewsRendererInner: React.FunctionComponent<{ news: News }> = ({ news }) => {
    const maxLength = 100
    const { t } = useTranslation()
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

    return (
        <NewsContainerInner>
            <Header content={news.topic} subheader={formatDate(news.date)} />

            {news.content?.length > maxLength && isCollapsed ? (
                <>
                    <pre>{news.content.substring(0, maxLength)}</pre>
                    <Button
                        type="button"
                        onClick={() => setIsCollapsed(false)}
                        primary
                        content={`${t('readMore')}...`}
                    />
                </>
            ) : (
                <>
                    <pre>{news.content}</pre>

                    {news.imageUrl && (
                        <p>
                            <NewsImage src={news.imageUrl} alt={news.imageUrl} />
                        </p>
                    )}
                    {news.link && (
                        <p>
                            <a href={news.link}>{news.link}</a>
                        </p>
                    )}
                    {news.content?.length > maxLength && (
                        <Button
                            type="button"
                            onClick={() => setIsCollapsed(true)}
                            primary
                            content={`${t('readLess')}...`}
                        />
                    )}
                </>
            )}
        </NewsContainerInner>
    )
}

export default CollapsingNewsRenderer
