import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'
import { Header, Form, Divider, Container, Checkbox, Input } from 'semantic-ui-react'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import { ArrowDown, ArrowUp, XCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup'
import { TFunction } from 'i18next'
import DataGrid from '../../common/grid'
import { DynamicFieldType } from '../../common/dynamicFields'
import { Editor, EditorProps } from 'react-data-grid'
import { YesNoFormatter, SingleButtonFormatter } from '../../common/grid/formatters'
import styled from 'styled-components'
import { getAuthedAxios } from '../../../auth'
import { handleAxiosError } from '../../../actions/error'
import { getState } from '../../../state'
import { useParams, useNavigate } from 'react-router'
import { useQueryClient } from 'react-query'
import { sanitizeKeyName } from '../../../utils/Mappings/Objects'

const getDynamicFieldTypeOptions = (t: TFunction) => [
    { key: DynamicFieldType.String, value: DynamicFieldType.String, text: t('string') },
    { key: DynamicFieldType.Number, value: DynamicFieldType.Number, text: t('number') },
    { key: DynamicFieldType.Date, value: DynamicFieldType.Date, text: t('date') },
    {
        key: DynamicFieldType.UserDropdown,
        value: DynamicFieldType.UserDropdown,
        text: t('userDropdown'),
    },
    {
        key: DynamicFieldType.MultipleUserDropdown,
        value: DynamicFieldType.MultipleUserDropdown,
        text: t('multipleUserDropdown'),
    },
]

const DynamicFieldTypeEditor: React.FunctionComponent<EditorProps<DynamicFieldType>> = forwardRef(
    function ({ value, column, onCommit }, ref) {
        const { t } = useTranslation()
        const selectRef = useRef<HTMLSelectElement>(null)

        useImperativeHandle(ref, () => ({
            getInputNode: () => {
                selectRef.current?.focus()
                return selectRef.current
            },
            getValue: () => ({
                [column.key]: parseInt(selectRef.current!.value) as DynamicFieldType,
            }),
        }))

        return (
            <select
                className="rdg-select-editor"
                defaultValue={value ?? 0}
                ref={selectRef}
                onBlur={onCommit}
            >
                {getDynamicFieldTypeOptions(t).map(o => (
                    <option key={o.key} value={o.value}>
                        {o.text}
                    </option>
                ))}
            </select>
        )
    },
)

const DynamicFieldTypeFormatter = ({ row, column }: any) => {
    const value = row[column.key]
    const { t } = useTranslation()

    return (
        // eslint-disable-next-line eqeqeq
        <>{getDynamicFieldTypeOptions(t).find(x => x.key === value)?.text ?? ''}</>
    )
}

class BooleanEditor
    extends React.Component<EditorProps<boolean>>
    implements Editor<{ [key: string]: boolean }> {
    private readonly input = React.createRef<HTMLInputElement>()

    getInputNode() {
        return this.input.current
    }

    getValue() {
        return {
            [this.props.column.key]: this.input.current?.checked ?? false,
        }
    }

    render() {
        const CheckboxEditorContainer = styled.div`
            width: 100%;
            height: 100%;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        `

        const CheckboxEditor = styled.input`
            transform: scale(1.4);
        `

        return (
            <CheckboxEditorContainer>
                <CheckboxEditor
                    type="checkbox"
                    ref={this.input}
                    defaultChecked={this.props.value}
                    onBlur={this.props.onCommit}
                />
            </CheckboxEditorContainer>
        )
    }
}

const getActivityTemplateColumns = (
    t: TFunction,
    onDelete: (props: any) => void,
    activitiesIsChecklist: boolean,
    onReorder: (row: any, direction: number) => void,
) => {
    const columns = [
        { name: t('activity'), key: 'topic', editable: true, sortable: !activitiesIsChecklist },
        {
            name: t('description'),
            key: 'description',
            editable: true,
            sortable: !activitiesIsChecklist,
        },
        {
            name: '',
            key: 'id',
            width: 0,
            formatter: (props: any) => (
                <SingleButtonFormatter
                    {...props}
                    icon={<SemanticFeatherIcon FeatherIcon={XCircle} />}
                    onClick={onDelete}
                />
            ),
            sortable: !activitiesIsChecklist,
        },
    ]

    if (activitiesIsChecklist)
        return [
            {
                name: '',
                key: 'order_up_btn',
                sortable: false,
                width: 0,
                formatter: (props: any) => (
                    <SingleButtonFormatter
                        {...props}
                        icon={<SemanticFeatherIcon FeatherIcon={ArrowUp} />}
                        onClick={() => onReorder(props.row, -1)}
                    />
                ),
            },
            {
                name: '',
                key: 'order_down_btn',
                sortable: false,
                width: 0,
                formatter: (props: any) => (
                    <SingleButtonFormatter
                        {...props}
                        icon={<SemanticFeatherIcon FeatherIcon={ArrowDown} />}
                        onClick={() => onReorder(props.row, +1)}
                    />
                ),
            },
            ...columns,
        ]
    else return columns
}

const getExtraFieldsColumns = (t: TFunction, onDelete: (props: any) => void) => [
    { name: t('fieldNameNoChar'), key: 'name', editable: true },
    {
        name: t('fieldType'),
        key: 'type',
        editable: true,
        editor: DynamicFieldTypeEditor,
        formatter: (props: any) => <DynamicFieldTypeFormatter {...props} />,
    },
    {
        name: t('required'),
        key: 'required',
        width: 100,
        editable: true,
        editor: BooleanEditor,
        formatter: (props: any) => <YesNoFormatter {...props} />,
    },
    {
        name: '',
        key: 'id',
        width: 0,
        formatter: (props: any) => (
            <SingleButtonFormatter
                {...props}
                icon={<SemanticFeatherIcon FeatherIcon={XCircle} />}
                onClick={onDelete}
            />
        ),
    },
]

const AdminTemplateDetailsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { dispatch } = getState()
    let { templateId } = useParams<{ templateId: string }>()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState<string>('')
    const [activities, setActivities] = useState<any[]>([])
    const [extraFields, setExtraFields] = useState<any[]>([])
    const [activitiesIsChecklist, setActivitiesIsChecklist] = useState<boolean>(false)
    const queryClient = useQueryClient()
    if (templateId === 'new') templateId = undefined

    useEffect(() => {
        if (templateId && templateId !== 'new') {
            getAuthedAxios().then(axios =>
                axios
                    .get(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template/${templateId}`)
                    .then(response => {
                        setActivitiesIsChecklist(response.data.activitiesIsChecklist)
                        setActivities(
                            response.data.activities.map((x: any, i: number) => ({ ...x, id: i })),
                        )

                        const extraFields = Object.entries(response.data.extraFields).reduce<any[]>(
                            (acc, [key, value]) => [
                                ...acc,
                                { ...(value as any), id: acc.length, name: key },
                            ],
                            [],
                        )

                        setExtraFields(extraFields)
                        setName(response.data.name)
                    })
                    .catch(handleAxiosError(dispatch))
                    .finally(() => setLoading(false)),
            )
        } else {
            setLoading(false)
        }
    }, [templateId, dispatch])

    const onActivityRowUpdate = (rowId: number, changedData: any, row: any) => {
        setActivities(activities =>
            activities.map(a => {
                if (a.id !== row.id) return a

                return { ...a, ...changedData }
            }),
        )
    }

    const onExtraFieldRowUpdate = (rowId: number, changedData: any, row: any) => {
        setExtraFields(extraFields =>
            extraFields.map(x => {
                if (x.id !== row.id) return x;

                const updatedData = changedData.hasOwnProperty('name') ?
                    { ...changedData, name: sanitizeKeyName(changedData.name) } :
                    changedData;

                return { ...x, ...updatedData };
            }),
        );
    }

    const onActivityDelete = ({ row }: any) => {
        setActivities((activities: any) => activities.filter((a: any) => a.id !== row.id))
    }

    const onExtraFieldDelete = ({ row }: any) => {
        setExtraFields((extraFields: any) => extraFields.filter((x: any) => x.id !== row.id))
    }

    const onCancel = () => {
        queryClient.invalidateQueries()
        navigate(-1)
    }

    const onSave = () => {
        const templateToSave = {
            id: templateId,
            name,
            activitiesIsChecklist,
            activities: activities.map(a => {
                delete a.id
                return a
            }),
            extraFields: extraFields.reduce(
                (acc: any, cur: any) => ({ ...acc, [cur.name]: cur }),
                {},
            ),
        }

        getAuthedAxios().then(axios => {
            if (templateToSave.id) {
                axios
                    .post(
                        `${window.env.REACT_APP_PROJECT_SERVICE}/api/Template/${templateToSave.id}`,
                        templateToSave,
                    )
                    .then(onCancel)
                    .catch(handleAxiosError(dispatch))
            } else {
                axios
                    .post(`${window.env.REACT_APP_PROJECT_SERVICE}/api/Template`, templateToSave)
                    .then(response => {
                        onCancel()
                    })
                    .catch(handleAxiosError(dispatch))
            }
        })
    }

    const onRowReorder = (row: any, direction: number) => {
        const currentIndex = activities.findIndex(a => a === row)

        if (
            !(currentIndex === 0 && direction < 0) &&
            !(currentIndex === activities.length - 1 && direction > 0)
        ) {
            const newIndex = currentIndex + direction

            setActivities(activities => {
                const backup = activities[newIndex]
                activities[newIndex] = row
                activities[currentIndex] = backup

                return [...activities]
            })
        }
    }

    return (
        <Container>
            <Header dividing>{t('projectTemplate')}</Header>

            <Form onSubmit={onSave} loading={loading}>
                <SaveCancelPrintButtonGroup
                    disableSave={loading}
                    onCancel={onCancel}
                    disableCancel={loading}
                    location="top"
                />

                <Form.Field
                    label={t('name')}
                    control={Input}
                    value={name}
                    required
                    onChange={(_: any, data: any) => setName(data.value)}
                />

                <Header content={t('project')} />
                <Divider />

                <Checkbox
                    toggle
                    label={t('useActivitiesAsChecklist')}
                    checked={activitiesIsChecklist}
                    onChange={(_, d) => setActivitiesIsChecklist(d.checked ?? false)}
                />

                <Header content={t('extraFields')} />
                <Divider />

                <DataGrid
                    columns={getExtraFieldsColumns(t, onExtraFieldDelete)}
                    rows={extraFields}
                    onRowUpdated={onExtraFieldRowUpdate}
                    displayToolbar={true}
                    displayAddButton={true}
                    displayFilters={false}
                    onAddCallback={() =>
                        setExtraFields((extraFields: any) => [
                            ...extraFields,
                            { id: extraFields.length },
                        ])
                    }
                />

                <Header content={t('activity')} />
                <Divider />

                <DataGrid
                    columns={getActivityTemplateColumns(
                        t,
                        onActivityDelete,
                        activitiesIsChecklist,
                        onRowReorder,
                    )}
                    rows={activities}
                    onRowUpdated={onActivityRowUpdate}
                    displayToolbar={true}
                    displayAddButton={true}
                    displayFilters={false}
                    onAddCallback={() =>
                        setActivities((activities: any) => [
                            ...activities,
                            { id: activities.length },
                        ])
                    }
                />
            </Form>
        </Container>
    )
}

export default AdminTemplateDetailsPage
