import React, { ReactElement, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Header, Segment, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { ProcessHierarchyItem } from '../../../../hooks/docs/process'
import { RowIndicatedBackgroundColor } from '../../../../styles'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import DragableDocumentRow, { RowDragItemType, RowDragObject } from './dragableDocumentRow'

const HeaderDropTarget = styled.div<{
    $isOver: boolean
}>`
    border-radius: 5px;
    background-color: ${RowIndicatedBackgroundColor};
    opacity: ${props => (props.$isOver ? '1' : '0.5')};
    padding: 8px;
`

export enum ProcessType {
    GoverningProcess = 1,
    MainProcess = 2,
    SupportProcess = 3,
    MainProcess2 = 4,
    MainProcess3 = 5,
}

export interface ProcessAreaProps {
    title: string
    type: ProcessType
    routines: ProcessHierarchyItem[]
    onEdit: (item: ProcessHierarchyItem, parentIsUnpublished: boolean) => void
    onMove: (parentId: number, type: ProcessType, movedId: number) => void
    onNew: (type: ProcessType, parentId: number, parentIsUnpublished: boolean) => void
    onDelete: (documentId: number) => void
    loading?: boolean
}

const ProcessArea: React.FunctionComponent<ProcessAreaProps> = ({
    title,
    type,
    routines,
    onEdit,
    onMove,
    onNew,
    onDelete,
    loading = false,
}) => {
    const { t } = useTranslation()
    const [expandedNodes, setExpandedNodes] = useState<{ [key: number]: boolean }>({})

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: RowDragItemType,
        drop(dragType: RowDragObject) {
            onMove(0, type, dragType.id)
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })

    const myRoutines = routines.filter(x => x.item?.typ === type)

    const renderChildren = (
        children: ProcessHierarchyItem[] | undefined,
        level: number,
        parent?: ProcessHierarchyItem,
    ): ReactElement => (
        <>
            {children?.map(x => (
                <React.Fragment key={`fragment-${x.item?.id}`}>
                    <DragableDocumentRow
                        key={x.item?.id}
                        level={level}
                        item={x}
                        onEdit={item => onEdit(item, !(parent?.item?.isPublished ?? true))}
                        onMove={(targetId, moveId) => onMove(targetId, type, moveId)}
                        onNew={parentId => {
                            if (!x.item) return

                            onNew(type, parentId, !(x?.item?.isPublished ?? true))
                            setExpandedNodes(previous => ({ ...previous, [x.item!.id]: true }))
                        }}
                        onDelete={onDelete}
                        hasChildren={x.children.length > 0}
                        isExpanded={expandedNodes[x.item?.id ?? -1] ?? false}
                        onExpandChange={expanded => {
                            if (!x.item) return
                            setExpandedNodes(previous => ({ ...previous, [x.item!.id]: expanded }))
                        }}
                    />
                    {(expandedNodes[x.item?.id ?? -1] ?? false) &&
                        renderChildren(x.children, level + 1, x)}
                </React.Fragment>
            ))}
        </>
    )

    return (
        <Segment basic loading={loading}>
            <Header content={title} />

            <Table compact>
                <Table.Header>
                    <Table.Row>
                        {canDrop ? (
                            <Table.HeaderCell colSpan="6">
                                <HeaderDropTarget $isOver={isOver} ref={drop}>
                                    {t('dropHereToMoveToRoot')}
                                </HeaderDropTarget>
                            </Table.HeaderCell>
                        ) : (
                            <>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>{t('topic')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('sorting')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('version')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('approvedBy')}</Table.HeaderCell>
                            </>
                        )}
                        <Table.HeaderCell textAlign="right">
                            <Button
                                icon
                                labelPosition="left"
                                primary
                                type="button"
                                onClick={() => onNew(type, 0, false)}
                            >
                                <SemanticFeatherIcon
                                    FeatherIcon={Plus}
                                    size={'60%'}
                                    centerIcon={true}
                                />{' '}
                                {t('new')}
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{renderChildren(myRoutines, 0)}</Table.Body>
            </Table>
        </Segment>
    )
}

export default ProcessArea
