import React from 'react'
import { Archive, Bell, Info, Trash2, UserPlus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Table, Button, Message } from 'semantic-ui-react'
import { handleAxiosError } from '../../../../actions/error'
import { sendReminder } from '../../../../actions/reminder'
import { formatDate } from '../../../../actions/util'
import { ReadConfirmationCaseDto, ReadConfirmationDto } from '../../../../hooks/docs/readConfirmation'
import { getState } from '../../../../state'
import CustomConfirm from '../../../common/customConfirm'
import FlexBox from '../../../common/flexBox'
import UserNameDisplay from '../../../common/UserNameDisplay'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import CreateReadConfirmationModal from './createReadConfirmationModal'

export interface ReadConfirmationsTableProps {
    readConfirmationCase?: ReadConfirmationCaseDto
    addPositions: (caseId: number, newPositions: number[]) => Promise<void>
    setActive: (caseId: number, isActive: boolean) => Promise<void>
    removeUser: (caseId: number, userId: string) => Promise<void>
}

const ReadConfirmationsTable: React.FunctionComponent<ReadConfirmationsTableProps> = ({
    readConfirmationCase,
    addPositions,
    setActive,
    removeUser,
}) => {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const onSendReminder = (
        confirmation: ReadConfirmationDto,
        confirmationCase: ReadConfirmationCaseDto,
    ) => {
        sendReminder(
            confirmation.userId,
            {
                templateKey: 'AcknowledgeReadingDocuments',
                values: {
                    routine: confirmationCase.documentName,
                },
            },
            {
                attachedId: confirmation.id.toString(),
                set: 'AcknowledgeReadingDocuments',
            },
        )
            .then(() => {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            })
            .catch(handleAxiosError(dispatch))
    }

    if (!readConfirmationCase)
        return (
            <Message
                info
                icon={<SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />}
                content={t('selectReadConfirmationCase')}
            />
        )

    return (
        <>
            {readConfirmationCase?.active && (
                <FlexBox justifyContent="end">
                    <CustomConfirm
                        trigger={
                            <Button type="button" icon labelPosition="left">
                                <SemanticFeatherIcon
                                    FeatherIcon={Archive}
                                    size={'60%'}
                                    centerIcon={true}
                                />{' '}
                                {t('close')}
                            </Button>
                        }
                        onConfirm={() => setActive(readConfirmationCase.id, false)}
                    />
                    <CreateReadConfirmationModal
                        trigger={
                            <Button icon labelPosition="left" primary type="button">
                                <SemanticFeatherIcon
                                    FeatherIcon={UserPlus}
                                    size={'60%'}
                                    centerIcon={true}
                                />{' '}
                                {t('add')}
                            </Button>
                        }
                        title={t('addResponsiblePositions')}
                        onSave={x => addPositions(readConfirmationCase.id, x.positionIds)}
                        requireDocumentId={false}
                    />
                </FlexBox>
            )}

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('user')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('acknowledged')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {readConfirmationCase?.confirmations.map(x => (
                        <Table.Row key={x.id} positive={x.confirmed}>
                            <Table.Cell>
                                <UserNameDisplay userId={x.userId} />
                            </Table.Cell>
                            <Table.Cell>{x.confirmed ? t('yes') : t('no')}</Table.Cell>
                            <Table.Cell>{formatDate(x.dateConfirmed ?? '')}</Table.Cell>
                            <Table.Cell textAlign="right" collapsing>
                                {!x.confirmed && (
                                    <>
                                        <CustomConfirm
                                            trigger={
                                                <Button
                                                    type="button"
                                                    size="small"
                                                    icon={
                                                        <SemanticFeatherIcon FeatherIcon={Bell} />
                                                    }
                                                />
                                            }
                                            onConfirm={() =>
                                                onSendReminder(x, readConfirmationCase)
                                            }
                                            text={t('areYouSureYouWishToSendReminder')}
                                        />
                                        <CustomConfirm
                                            trigger={
                                                <Button
                                                    type="button"
                                                    size="small"
                                                    icon={
                                                        <SemanticFeatherIcon FeatherIcon={Trash2} />
                                                    }
                                                    negative
                                                />
                                            }
                                            onConfirm={() =>
                                                removeUser(readConfirmationCase.id, x.userId)
                                            }
                                        />
                                    </>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    )
}

export default ReadConfirmationsTable
