import { getAuthedAxios } from '../auth'

export interface HistoryCount {
    count: number
}

export interface TrackingInformation {
    set: string
    attachedId: string
}

export interface Template {
    templateKey: string
    values?: any
}

export const sendReminder = async (
    userId: string,
    template: Template,
    tracking: TrackingInformation | null = null,
) => {
    const axios = await getAuthedAxios()
    await axios.post(`${window.env.REACT_APP_NOTIFICATION_SERVICE}/api/Notification`, {
        methods: ['email'],
        toUserId: userId,
        templateContents: template,
        tracking,
    })
}
