import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { useMobileMode } from '../../../hooks/mobileMode'
import { LineColor } from '../../../styles'

const MobileSwitchTopBar = styled.div`
    display: none;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        background-color: white;
        border-bottom: 1px solid ${LineColor};
        color: black;
        padding: 5px;

        p {
            font-size: 1.1em;
            margin: 0;
        }
    }
`

const MobileSwitchBar: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { isInMobileMode, setMobileMode } = useMobileMode()

    if (isInMobileMode) return null

    return (
        <MobileSwitchTopBar>
            <p>{t('screenIsNarrow')}</p>
            <Button size="tiny" content={t('switchToMobile')} onClick={() => setMobileMode(true)} />
        </MobileSwitchTopBar>
    )
}

export default MobileSwitchBar
