import { FileListing, PendingFile } from './types'

export function addFile(collection: FileListing[], file: FileListing): FileListing[] {
    return [...collection, file]
}

export function updateFile(collection: FileListing[], file: FileListing): FileListing[] {
    return collection.map(a => (a.name === file.name ? Object.assign({}, file) : a))
}

export function removeFile(collection: FileListing[], file: FileListing): FileListing[] {
    return collection.filter(f => f.id !== file.id)
}

export function addPendingFiles(
    collection: PendingFile[],
    pendingFiles: PendingFile[],
): PendingFile[] {
    return [...collection, ...pendingFiles]
}

export function removePendingFile(
    collection: PendingFile[],
    pendingFile: PendingFile,
): PendingFile[] {
    return collection.filter(pf => pf.file.name !== pendingFile.file.name)
}

export function updatePendingFile(
    collection: PendingFile[],
    pendingFile: PendingFile,
): PendingFile[] {
    return collection.map(pf =>
        pf.file === pendingFile.file ? Object.assign({}, pendingFile) : pf,
    )
}
