import React from 'react'
import { NavLink } from 'react-router-dom'
import { List, Header } from 'semantic-ui-react'

const AdminMenu: React.FunctionComponent = () => {
    return (
        <>
            <Header>System</Header>
            <List>
                <List.Item>
                    <List.Icon name="user" />
                    <List.Content>
                        <NavLink to={`./users`}>Administrera användare</NavLink>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="shield alternate" />
                    <List.Content>
                        <NavLink to={`./rights`}>Rättighetsmatris</NavLink>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="sitemap" />
                    <List.Content>
                        <NavLink to={`./departments`}>Avdelningar / Grupp</NavLink>
                    </List.Content>
                </List.Item>
            </List>

            <Header>Viqma</Header>
            <List>
                <List.Item>
                    <List.Icon name="braille" />
                    <List.Content>
                        <NavLink to={`./companies`}>Administrera företag</NavLink>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="file" />
                    <List.Content>
                        <NavLink to={`./files`}>Filer</NavLink>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="law" />
                    <List.Content>
                        <NavLink to={`./laws`}>Lagar</NavLink>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="question" />
                    <List.Content>
                        <NavLink to={`./supplier-questions`}>Lev. frågor</NavLink>
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name="newspaper outline" />
                    <List.Content>
                        <NavLink to={`./news`}>Nyheter</NavLink>
                    </List.Content>
                </List.Item>
            </List>
        </>
    )
}

export default AdminMenu
