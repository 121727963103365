import * as API from "../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../actions/error"
import { getState } from "../../../../../state"

interface IDestroyActivity {
    projectId: string
    confirmText: string
}

export function useDeleteProject(refresh: () => void) {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const destroyProject = async (data: IDestroyActivity) => {
        if (window.confirm(data.confirmText)) {
            return await API.projects.deleteProject(data.projectId)
        }
    }

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(destroyProject, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t('saved'))
                refresh()
            }
        },
    })

    return {
        isDestroying,
        onDestroy,
    }
}
