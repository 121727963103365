import React, { useState } from 'react';
import { Grid, GridColumn, GridRow, Message } from 'semantic-ui-react';
import Files, { UploadAllPendingFilesCallback } from '../../../common/files';
import { ISupplierResponse } from '../../../../api/suppliers';
import { useTranslation } from 'react-i18next';

interface ISupplierFilesProps {
    selectedSupplier?: ISupplierResponse
    fileCollection: "supplier-internal" | "supplier-external"
    hasSupplierConcernModule: boolean
}

const SupplierFiles = ({ fileCollection, selectedSupplier, hasSupplierConcernModule }: ISupplierFilesProps) => {
    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()
    const { t } = useTranslation()
    const findInfoMessage = () => {
        switch (fileCollection) {
            case "supplier-internal":
                return t("internalFilesMessageBody")
            case "supplier-external":
                return t("externalFilesMessageBody")
            default:
                return ""
        }
    }
    return (
        <Grid stackable>
            <GridRow columns={1}>
                <GridColumn >
                    <Message info>
                        {findInfoMessage()}
                    </Message>
                </GridColumn>
            </GridRow>
            <GridRow columns={1}>
                <GridColumn >
                    <Files
                        displayTitle
                        module={fileCollection}
                        mayAutoUpload={!!selectedSupplier}
                        readOnly={hasSupplierConcernModule}
                        collectionId={selectedSupplier && selectedSupplier.id.toString()}
                        onFilesAdded={selectedSupplier ? undefined : (files, uploadAllPendingFiles) => {
                            if (!files.length) return
                            setUploadAllPendingFiles(() => uploadAllPendingFiles)
                        }}
                    />
                </GridColumn>
            </GridRow>

        </Grid>
    );
};

export default SupplierFiles;
