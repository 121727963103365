import React from 'react'
import { Edit, Plus, Printer } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import {
    Button,
    Dimmer,
    Grid,
    Header,
    Loader,
    Segment,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import { getAuthedAxios } from '../../auth'
import {
    MobileSystematicTaskDto,
    useMobileSystematicTasks,
} from '../../hooks/systematicTasks/mobileSystematicTasks'
import { useMobileSystematicTasksPdf } from '../../hooks/systematicTasks/useMobileSystematicTasksPdf'
import FlexBox from '../common/flexBox'
import InlineConfirmDelete from '../common/inlineConfirmDelete'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import MobileSystematicTaskPdf, { PdfDocumentData } from './MobileSystematicTaskPdf'

const TaskTable = () => {
    const navigate = useNavigate()

    const { mobileSystematicTasks, deleteSystematicTaskTemplate } = useMobileSystematicTasks()

    const { getPdf, parseFieldsForPdf } = useMobileSystematicTasksPdf()

    const { t } = useTranslation()

    const onDeleteClick = (id: number) => {
        deleteSystematicTaskTemplate(id)
    }

    const getTask = async (taskId: number) => {
        const url = `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/mobile-tasks/${taskId}`

        const authAxios = await getAuthedAxios()

        const response = await authAxios.get<MobileSystematicTaskDto>(url)

        const task = response.data

        return task
    }

    const getPdfDocument = async (taskId: number) => {
        const task = await getTask(taskId || 0)

        const fieldData = JSON.parse(task.fieldData || '[]')

        const parsedFields = parseFieldsForPdf(fieldData)

        const data: PdfDocumentData = {
            title: task?.sysTitle || '',
            responsibleId: task?.sysResponsibleId || '',
            date: task?.sysDate || '',
            description: task?.description || '',
            fields: parsedFields,
        }

        const document = <MobileSystematicTaskPdf data={data} />

        return document
    }

    const onPrintClick = async (taskId: number) => {
        console.log('print', taskId)

        const pdfData = await getPdf(await getPdfDocument(taskId))

        const blob = new Blob([pdfData], { type: 'application/pdf' })

        const url = URL.createObjectURL(blob)

        window.open(url, '_blank')
    }

    return (
        <>
            <Table style={{ marginBottom: 0 }} basic="very">
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell />
                        <TableHeaderCell colSpan="4" textAlign="right"></TableHeaderCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>{t('systematicTask')}</TableHeaderCell>
                        <TableHeaderCell>{t('responsibleForTemplate')}</TableHeaderCell>
                        <TableHeaderCell>{t('date')}</TableHeaderCell>
                        <TableHeaderCell>{t('lastUpdated')}</TableHeaderCell>
                        <TableHeaderCell />
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {mobileSystematicTasks?.map(task => (
                        <TableRow key={task.id}>
                            <TableCell>{task.sysTitle}</TableCell>
                            <TableCell>{task.responsible}</TableCell>
                            <TableCell>{task.created}</TableCell>
                            <TableCell>{task.updated}</TableCell>
                            <TableCell textAlign="right">
                                <FlexBox justifyContent="flex-end">
                                    <Button
                                        type="button"
                                        icon={<SemanticFeatherIcon FeatherIcon={Printer} />}
                                        onClick={() => onPrintClick(task.id || 0)}
                                    />

                                    <Button
                                        type="button"
                                        icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                        onClick={() => navigate(`edit/${task.id}`)}
                                    />
                                    <InlineConfirmDelete
                                        onConfirm={() => onDeleteClick(task.id || 0)}
                                    />
                                </FlexBox>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

const MobileSystematicTasksList = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div style={{ width: '100%' }}>
            <Dimmer active={false}>
                <Loader />
            </Dimmer>
            <Header content={t('mobileSystematicTasksHeader')} />

            <Segment>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={13}>
                            <p>{t('mobileSystematicTasksIntroText1')}</p>
                            <p>{t('mobileSystematicTasksIntroText2')}</p>
                        </Grid.Column>
                        <Grid.Column textAlign="right" width={3}>
                            <Button
                                onClick={() => navigate('new')}
                                icon
                                labelPosition="left"
                                primary
                                type="button"
                            >
                                <SemanticFeatherIcon
                                    FeatherIcon={Plus}
                                    size="60%"
                                    centerIcon={true}
                                />{' '}
                                {t('addNew')}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <TaskTable />
        </div>
    )
}

export default MobileSystematicTasksList
