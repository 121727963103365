import { FilterFn } from "@tanstack/react-table"
import React from "react"
import { Dropdown } from "semantic-ui-react"
import { IDropdownOption } from "../../../../fields/ControlDropdown"

/* Important */
// columnId must match key on original object
export const multiFilter: FilterFn<any> = (row, columnId, value) => {
    if (Array.isArray(value) && value.length) {
        return value.includes(row.original[columnId])
    }
    return true
}

interface IMultiDropdownFilterProps<T = IDropdownOption> {
    setFilterValue: (updater: string) => void
    value: (string | number | boolean)[] | undefined
    options: T[]
}

export function MultiDropdownFilter({ setFilterValue, value, options }: IMultiDropdownFilterProps) {
    return (
        <Dropdown
            fluid
            multiple
            selection
            search
            options={options}
            value={value || []}
            onChange={(e, { value }) => setFilterValue(value as any)}
        />
    )
} 
