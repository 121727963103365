import { HistoryCount } from '../actions/reminder'
import useApi from './useApi'

export const useReminder = (template: string, trackingSetId?: string | number) => {
    const { data, post, mutate } = useApi<HistoryCount>(
        trackingSetId
            ? `${window.env.REACT_APP_NOTIFICATION_SERVICE}/api/History/count/${template}/${trackingSetId}`
            : undefined,
        {
            setDataFromPost: false,
        },
    )

    return {
        data,
        sendReminder: <T>(userId: string, params: T) =>
            post(
                {
                    methods: ['email'],
                    toUserId: userId,
                    templateContents: {
                        templateKey: template,
                        values: params,
                    },
                    tracking: trackingSetId
                        ? {
                              set: template,
                              trackingSetId: trackingSetId.toString(),
                          }
                        : null,
                },
                `${window.env.REACT_APP_NOTIFICATION_SERVICE}/api/Notification`,
            ).then(() => {
                mutate(x => {
                    if (!x) return undefined

                    return {
                        ...x,
                        count: x.count + 1,
                    }
                })
            }),
    }
}
