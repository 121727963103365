import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { IActivityListDto, IImprovementActivityRequest, IImprovementGroupDto, ImprovementDecisiontatus } from "../../../api/improvements";
import { toast } from "react-toastify";
import * as API from '../../../api'
import { useUserOptionsOverride } from "../../../hooks/users";
import ControlDropdown from "../../fields/ControlDropdown";
import { useTranslation } from "react-i18next";
import ControlDateField from "../../fields/ControlDateField";
import { mapToImprovementActivityRequest } from "../../../utils/Mappings/Improvements";
import { mockedImprovementActivityRequest } from "../../../utils/MockedData/Improvements";
import ControlInput from "../../fields/ControlInput";
import ControlCheckbox from "../../fields/ControlCheckbox";
import ControlPriorityMatrix from "../../fields/ControlPriorityMatrix";
import { getState } from "../../../state";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from 'zod';
import ControlTextArea from "../../fields/ControlTextArea";
import { Button, Form, Grid, GridColumn, GridRow, Header } from "semantic-ui-react";
import Files, { UploadAllPendingFilesCallback } from "../../common/files";
import CollapsingSegment from "../../common/collapsingSegment";
import { useSendAuthorizedNotify } from "./useSendAuthorizedNotify";
import { useNavigate } from "react-router-dom";
import { useIsInRole } from "../../../hooks/role";
import { AdminRoles } from "../../../auth/roles";
import { handleAxiosError } from "../../../actions/error";
import SaveCancelPrintButtonGroup from "../../common/saveCancelPrintButtonGroup";
import PrintWrapper from "../../wrappers/PrintWrapper";

interface ImprovementActivityFormProps {
    refresh: () => void
    onCancel: () => void
    improvementGroups: IImprovementGroupDto[]
    isNew: boolean
    selectedActivity?: IActivityListDto
}


const activitySchema = z.object({
    status: z.coerce.number(),
    idea: z.string().min(1, { message: "required" }),
    groupId: z.coerce.number().optional().nullable(),
    expectedResult: z.string().optional().nullable(),
    solutionProposal: z.string().optional().nullable(),
    reporterId: z.string().min(1, { message: "required" }),
    created: z.date({ invalid_type_error: "required" }),
    responsibleId: z.string({ invalid_type_error: "required" }).min(1, { message: "required" }),
    plannedFinishedAt: z.date().optional().nullable(),
    priority: z.number().optional().nullable(),
    donePercentage: z.coerce.number().optional(),
    decisionStatus: z.coerce.number(),
    decisionResult: z.string().optional().nullable(),
    authorizedUser: z.string().nullable(),
    finishedAt: z.date().optional().nullable(),
    time: z.coerce.number().nullable(),
    cost: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
    evaluationRequired: z.boolean().optional().nullable(),
    evaluationResult: z.string().nullable(),
    evaluatedBy: z.string().nullable(),
    evaluatedAt: z.date().nullable(),
}).refine((data) => {
    if ([1, 2].includes(data.decisionStatus)) {
        return data.authorizedUser !== null && data.authorizedUser.trim().length > 0;
    }
    return true;
}, { message: "required", path: ["authorizedUser"] });

const ImprovementActivityForm = ({ refresh, onCancel, improvementGroups, isNew, selectedActivity }: ImprovementActivityFormProps) => {
    const {
        dispatch,
        state: { userProfile },
    } = getState()
    const printComponentRef = useRef()
    const { control, handleSubmit, reset, setValue, clearErrors, watch, getValues } = useForm<IImprovementActivityRequest>({
        resolver: zodResolver(activitySchema),
        defaultValues: selectedActivity ? mapToImprovementActivityRequest(selectedActivity) : mockedImprovementActivityRequest(userProfile)
    });
    const navigate = useNavigate()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.ImprovementsAdmin)

    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()
    const { t } = useTranslation()
    const userOptions = useUserOptionsOverride(t, isNew ? u => u.active : undefined)
    const { isSending, onNotifyAuthorized } = useSendAuthorizedNotify();
    const userOptionsWithActiveItemUser = useMemo(() => {
        if (
            selectedActivity?.reporterId &&
            !userOptions.find(({ value }) => selectedActivity.reporterId === value)
        ) {
            userOptions.push({
                key: selectedActivity.reporterId,
                value: selectedActivity.reporterId,
                text: `${t('userFromAnotherCompany')}`,
            })
        } else if (userProfile?.sub && !userOptions.find(({ value }) => value === userProfile?.sub)) {
            userOptions.push({
                key: userProfile?.sub,
                value: userProfile?.sub,
                text: `${t('userFromAnotherCompany')}`,
            })
        }

        return userOptions
    }, [userOptions, selectedActivity, t, userProfile])
    const addIdea = async (data: IImprovementActivityRequest) => isNew ? await API.improvements.createActivity(data) : API.improvements.editActivity(selectedActivity!.id, data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(addIdea, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            refresh()
            navigate(-1)
        },
    })

    const handleDropdownOnChange = (key: keyof IImprovementActivityRequest, value: any) => {
        setValue(key, value)
        clearErrors(key)
    }

    useEffect(() => {
        return () => reset()
    }, [reset])
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} ref={printComponentRef} noValidate>
            <SaveCancelPrintButtonGroup
                location="top"
                onCancel={onCancel}
                printComponentRef={printComponentRef}
            />
            <PrintWrapper title={t('improvementActivity')}>
                <Grid stackable>
                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDropdown
                                required
                                name="reporterId"
                                label={t('reportedBy')}
                                control={control}
                                disabled={isSaving}
                                overrideOnChange={(value) => handleDropdownOnChange("reporterId", value)}
                                collection={userOptionsWithActiveItemUser} />
                        </GridColumn>
                        <GridColumn >
                            <ControlDateField
                                name="created"
                                label={t('reportedDate')}
                                control={control}
                                disabled={isSaving}
                                required />
                        </GridColumn>
                    </GridRow>
                    <GridRow columns="2">
                        <GridColumn>
                            {improvementGroups.length ?
                                <ControlDropdown
                                    name="groupId"
                                    label={t("group")}
                                    control={control}
                                    disabled={isSaving}
                                    overrideOnChange={(value) => setValue("groupId", value)}
                                    collection={improvementGroups.map(d => ({ value: d.id, text: d.name }))} />
                                : null}
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn>
                            <CollapsingSegment heading={t('priority')}>
                                <ControlPriorityMatrix name="priority" control={control} showHeader={false} />
                            </CollapsingSegment>
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t('idea')} name="idea" control={control} disabled={isSaving} required />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t('expectedResult')} name="expectedResult" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t('suggestion')} name="solutionProposal" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <Files
                                displayTitle
                                module="ImprovementActivities"
                                disableDelete={!isAdmin}
                                enableDragDrop
                                mayAutoUpload={!!selectedActivity}
                                collectionId={selectedActivity && selectedActivity.id.toString()}
                                onFilesAdded={selectedActivity ? undefined : (files, uploadAllPendingFiles) => {
                                    if (!files.length) return
                                    setUploadAllPendingFiles(() => uploadAllPendingFiles)
                                }}
                            />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <Header dividing>{t('improvementDecision')}</Header>
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDropdown
                                name="decisionStatus"
                                label={t("decisionStatus")}
                                control={control}
                                popupText={!isAdmin ? t('adminFieldDisable') : ""}
                                disabled={isSaving || !isAdmin}
                                overrideOnChange={(value) => setValue("decisionStatus", value)}
                                collection={Object.values(ImprovementDecisiontatus)
                                    .filter(value => typeof value === 'number')
                                    .map(d => ({ value: d, text: t(`improvementDecisionStatus.${d}`) }))} />
                        </GridColumn>
                        <GridColumn >
                            {[1, 2].includes(watch("decisionStatus")) ? <ControlDropdown
                                required
                                name="authorizedUser"
                                label={t('authorizedUser')}
                                popupText={!isAdmin ? t('adminFieldDisable') : ""}
                                disabled={isSaving || !isAdmin}
                                control={control}
                                overrideOnChange={(value) => handleDropdownOnChange("authorizedUser", value)}
                                collection={userOptions} /> : null}
                        </GridColumn>
                    </GridRow>
                    <GridRow >
                        <GridColumn>
                            {watch("decisionStatus") === 1 ? <Button content={t('notifyAuthorizedUser')} primary
                                disabled={isSaving || !isAdmin || !watch("authorizedUser")} loading={isSending} type="button" onClick={() => onNotifyAuthorized(
                                    {
                                        authorizedUser: getValues("authorizedUser")!,
                                        idea: getValues("idea"),
                                        expectedResult: getValues("expectedResult"),
                                        solutionProposal: getValues("solutionProposal")
                                    })} /> : null}
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn>
                            <Header dividing>{t('improvementActivity')}</Header>
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDropdown
                                required
                                name="responsibleId"
                                label={t('responsible')}
                                control={control}
                                disabled={isSaving}
                                overrideOnChange={(value) => handleDropdownOnChange("responsibleId", value)}
                                collection={userOptions} />
                        </GridColumn>
                        <GridColumn >
                            <ControlDateField
                                name="plannedFinishedAt"
                                label={t("plannedFinishedAt")}
                                control={control}
                                disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="2">
                        <GridColumn>
                            <ControlInput
                                name="donePercentage"
                                label={t("donePercentage")}
                                control={control}
                                disabled={isSaving || [3, 4].includes(watch("status"))}
                                type="number" />
                        </GridColumn>
                    </GridRow>

                    <GridRow>
                        <GridColumn >
                            <ControlTextArea label={t("decisionResult")} name="decisionResult" control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    <GridRow columns="2">
                        <GridColumn>
                            <ControlInput
                                name="time"
                                label={t("time")}
                                control={control}
                                disabled={isSaving}
                                type="number" />
                        </GridColumn>
                        <GridColumn >
                            <ControlInput
                                name="cost"
                                label={t("cost")}
                                control={control}
                                disabled={isSaving}
                                type="number" />
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn>
                            <Header dividing>{t('closeImprovementActivity')}</Header>
                        </GridColumn>
                    </GridRow>

                    <GridRow >
                        <GridColumn>
                            <ControlCheckbox
                                label={t("evaluationRequired")}
                                name="evaluationRequired"
                                control={control}
                                popupText={!isAdmin ? t('adminFieldDisable') : ""}
                                disabled={isSaving || !isAdmin}
                            />
                        </GridColumn>
                    </GridRow>
                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDateField
                                name="finishedAt"
                                label={t("finishedAt")}
                                control={control}
                                disabled={isSaving} />
                        </GridColumn>
                    </GridRow>

                    {watch("evaluationRequired") &&
                        <>
                            <GridRow >
                                <GridColumn>
                                    <Header dividing>{t('evaluation')}</Header>
                                </GridColumn>
                            </GridRow>

                            <GridRow >
                                <GridColumn>
                                    <ControlTextArea label={t("evaluationResult")} name="evaluationResult" control={control} disabled={isSaving} />
                                </GridColumn>
                            </GridRow>

                            <GridRow columns="2">
                                <GridColumn>
                                    <ControlInput
                                        name="evaluatedBy"
                                        label={t('evaluatedBy')}
                                        control={control}
                                        disabled={isSaving}
                                    />
                                </GridColumn>
                                <GridColumn>
                                    <ControlDateField
                                        name="evaluatedAt"
                                        label={t("evaluatedAt")}
                                        control={control}
                                        disabled={isSaving} />
                                </GridColumn>
                            </GridRow>
                        </>}
                </Grid>
            </PrintWrapper>
            <SaveCancelPrintButtonGroup
                location="bottom"
                onCancel={onCancel}
                printComponentRef={printComponentRef}
            />
        </Form>
    );
};

export default ImprovementActivityForm;
