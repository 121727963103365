import React, { useState, useEffect } from 'react'
import { Table, Button, Segment, Form, TableRow, TableCell } from 'semantic-ui-react'
import { Plus, Trash2, Edit } from 'react-feather'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import DynamicFields, { DynamicField } from './dynamicFields'
import SaveCancelPrintButtonGroup from './saveCancelPrintButtonGroup'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ViewContentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 1200px) {
        flex-direction: column-reverse;
    }
`

const TableContainer = styled.div`
    flex-basis: 70%;
    flex-shrink: 0;

    @media (max-width: 1200px) {
        flex-basis: 0;
        flex-grow: 1;
    }
`

const EditorContainer = styled(Segment)`
    height: 100%;
    flex-grow: 1;
    margin-top: 0 !important;
    margin-left: 5px !important;

    @media (max-width: 1200px) {
        margin-bottom: 5px !important;
        height: auto;
        flex-grow: 0;
        min-height: 50px;
    }
`

export interface EditViewProps {
    items: any[]
    loading?: boolean
    onSave: (item: any, isNew: boolean) => Promise<any>
    onDelete: (item: any) => void
    displayProps: string[]
    editProps: { [id: string]: DynamicField }
}

const EditView: React.FunctionComponent<EditViewProps> = ({
    items,
    loading = false,
    onSave,
    onDelete,
    displayProps,
    editProps,
}) => {
    const { t } = useTranslation()
    const [originalEditItem, setOriginalEditItem] = useState<any>()
    const [selectedEditItem, setSelectedEditItem] = useState<{ [id: string]: DynamicField }>()

    const onCancel = () => {
        setOriginalEditItem(undefined)
        setSelectedEditItem({ ...editProps })
        console.log('Cancell')
    }

    const onEditItem = (item: any) => {
        setOriginalEditItem(item)
        setSelectedEditItem(
            Object.fromEntries(
                Object.entries(editProps).map(([key, field]) => {
                    field.value = item[key]
                    return [key, field]
                }),
            ),
        )
    }

    const onDeleteItem = (item: any) => {
        onDelete(item)
    }

    const onSelectedEditItemChange = (name: string, value: any) => {
        setSelectedEditItem(item => {
            item![name].value = value
            return { ...item }
        })
    }

    const itemIsValid = () =>
        !Object.values(selectedEditItem!)
            .filter(x => x.required)
            .some(x => !x.value)

    const onSelectedEditItemSave = () => {
        onSave(
            Object.entries(selectedEditItem!).reduce(
                (acc, [key, value]) => (acc[key] = value.value),
                originalEditItem ?? {},
            ),
            originalEditItem === undefined,
        ).then(() => onCancel())
    }

    useEffect(onCancel, [])

    return (
        <ViewContentContainer>
            <TableContainer>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            {displayProps.map(x => (
                                <Table.HeaderCell key={`header-${x}`}>{t(x)}</Table.HeaderCell>
                            ))}

                            <Table.HeaderCell textAlign="right">
                                <Button
                                    content={t('new')}
                                    icon={<SemanticFeatherIcon FeatherIcon={Plus} />}
                                    onClick={onCancel}
                                    primary
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items &&
                            items.map((item, i) => (
                                <TableRow key={`row-${item.id ?? i}`}>
                                    {displayProps.map(x => (
                                        <TableCell key={`row-${item.id ?? i}-column-${x}`}>
                                            {item[x]}
                                        </TableCell>
                                    ))}

                                    <TableCell textAlign="right">
                                        <>
                                            <Button
                                                icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                                onClick={() => onEditItem(item)}
                                            />

                                            <Button
                                                icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                                negative
                                                onClick={() => onDeleteItem(item)}
                                            />
                                        </>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </Table.Body>
                </Table>
            </TableContainer>
            <EditorContainer>
                {selectedEditItem && (
                    <>
                        <SaveCancelPrintButtonGroup
                            onCancel={onCancel}
                            onSave={onSelectedEditItemSave}
                            location="top"
                            disableSave={!itemIsValid()}
                        />

                        <Form>
                            <DynamicFields
                                fields={selectedEditItem}
                                onChange={onSelectedEditItemChange}
                                standaloneGrid={true}
                                columns={1}
                                translateFieldNames={true}
                            />
                        </Form>
                    </>
                )}
            </EditorContainer>
        </ViewContentContainer>
    )
}

export default EditView
