import useSWR from 'swr'
import { defaultFetcher } from '../../SWRConfigurationProvider'
import { useTranslation } from 'react-i18next'

export interface StatisticsRows {
    rows: any[]
    dataColumns: string[]
}

export const transformToStatisticsRow = (
    data: any[],
    keyFunc: (item: any) => string,
    valueFunc: ((item: any) => any) | null,
): StatisticsRows => {
    const dataColumns = new Set<string>()
    const mergedObject = data.reduce((acc, cur) => {
        const key = keyFunc(cur)
        const value = valueFunc?.(cur) ?? { ...cur }

        const item = acc[key] ?? { name: key }
        Object.entries(value).forEach(([property, value]) => {
            dataColumns.add(property)
            item[property] = property === "averageDaysUntilResolve" ? item[property] ?? 0 + (value as number) + 10 : item[property] ?? 0 + (value as number)
        })

        return {
            ...acc,
            [key]: item,
        }
    }, {})

    return { rows: Object.values(mergedObject), dataColumns: Array.from(dataColumns) }
}

export const useIssuesPerMonth = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable ? null : `${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/month`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => `${x.year} | ${t(`month.${x.month}`)}`,
                x => ({ [x.reason]: x.count }),
            )
        },
        { revalidateOnFocus: false },
    )
}

export const useIssuesPerYear = (enable?: boolean) =>
    useSWR(
        !enable ? null : `${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/year`,
        async url => {
            const data = await defaultFetcher({ url })
            return {
                pieData: Object.values(
                    data.reduce(
                        (acc: any, cur: any) => ({
                            ...acc,
                            [cur.year]: [...(acc[cur.year] ?? []), cur],
                        }),
                        {},
                    ),
                ) as any[],
                barData: transformToStatisticsRow(
                    data,
                    x => x.year,
                    x => ({ [x.reason]: x.count }),
                ),
            }
        },
        { revalidateOnFocus: false },
    )

export const useIssueEffeciencyPerMonth = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable
            ? null
            : `${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/effeciency/month`,
        async url => {
            const data = await defaultFetcher({ url })
            const mappedData = {
                inTimePieData: data ? Object.values(
                    data?.reduce(
                        (acc: any, cur: any) => ({
                            ...acc,
                            [cur.year]: [...(acc[cur.year] ?? []), cur],
                        }),
                        {},
                    ),
                ) as any[] : [],
                effeciencyBarData: transformToStatisticsRow(
                    data ? data : [],
                    x => `${x.year} | ${t(`month.${x.month}`)}`,
                    x => ({ [t(`averageDaysUntilResolve`)]: x.averageDaysUntilResolve }),
                ),
            };
            return mappedData
        },
        { revalidateOnFocus: false },
    )
}

export const useAccidentsPerYear = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable
            ? null
            : `${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/accidents/year`,
        async url => {
            const data = await defaultFetcher({ url })

            return {
                pieData: Object.values(
                    data.reduce(
                        (acc: any, cur: any) => ({
                            ...acc,
                            [cur.year]: [...(acc[cur.year] ?? []), cur],
                        }),
                        {},
                    ),
                ) as any[],
                barData: transformToStatisticsRow(
                    data,
                    x => x.year,
                    x => ({ [t(`accidentReason.${x.reason}`)]: x.count }),
                ),
            }
        },
        { revalidateOnFocus: false },
    )
}

export const useIssueCostPerMonth = (enable?: boolean) =>
    useSWR(
        !enable ? null : `${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/cost/month`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => `${x.year}`,
                x => ({
                    materialCost: x.materialCost,
                    laborCost: x.laborCost
                })
            )
        },
        { revalidateOnFocus: false },
    )

export const useIdeasPerMonth = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable ? null : `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Statistics/ideas/month`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => `${x.year} | ${t(`month.${x.month}`)}`,
                x => ({ [t('count')]: x.count }),
            )
        },
        { revalidateOnFocus: false },
    )
}

export const useIdeasPerYear = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable ? null : `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Statistics/ideas/year`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => `${x.year}`,
                x => ({ [t('count')]: x.count }),
            )
        },
        { revalidateOnFocus: false },
    )
}

export const useImprovementEffeciencyPerMonth = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable
            ? null
            : `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Statistics/improvements/effeciency/month`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => `${x.year} | ${t(`month.${x.month}`)}`,
                x => ({ [t(`averageDaysUntilResolve`)]: x.averageDaysUntilResolve }),)
        },
        { revalidateOnFocus: false },
    )
}

export const useImprovementsPerYear = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable
            ? null
            : `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Statistics/improvements/year`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => x.year,
                x => ({ [t('count')]: x.count }),
            )
        },
        { revalidateOnFocus: false },
    )
}

export const useImprovementsPerMonth = (enable?: boolean) => {
    const { t } = useTranslation()
    return useSWR(
        !enable
            ? null
            : `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/Statistics/improvements/month`,
        async url => {
            const data = await defaultFetcher({ url })
            return transformToStatisticsRow(
                data,
                x => `${x.year} | ${t(`month.${x.month}`)}`,
                x => ({ [t('count')]: x.count }),
            )
        },
        { revalidateOnFocus: false },
    )
}

export const useStatisticsConfiguration = () =>
    useSWR(`${window.env.REACT_APP_COMPANY_SERVICE}/api/StatisticsConfiguration`, {
        revalidateOnFocus: false,
    })
