import { MODULES } from '../../auth/modules'

export const getSummaryDataUri = (responsibleId?: string, timePeriod?: number, language?: string) =>
({
    [MODULES.DOCS]: `${window.env.REACT_APP_DOC_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&language=${language}`,
    [MODULES.SKILLS]: `${window.env.REACT_APP_SKILL_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.ISSUES]: `${window.env.REACT_APP_ISSUE_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.PROJECTS]: `${window.env.REACT_APP_PROJECT_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.SYSTEMATIC_TASKS]: `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE
        }/api/summary?responsibleId=${responsibleId ?? ''}&timePeriod=${timePeriod}`,
    [MODULES.IMPROVEMENTS]: `${window.env.REACT_APP_IMPROVEMENT_SERVICE
        }/api/summary?responsibleId=${responsibleId ?? ''}&timePeriod=${timePeriod}`,
    [MODULES.OBJECTS]: `${window.env.REACT_APP_OBJECT_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.RISKS]: `${window.env.REACT_APP_RISK_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.GOALS]: `${window.env.REACT_APP_GOAL_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.LAWS]: `${window.env.REACT_APP_LAW_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }`,
    [MODULES.CHEMICALS]: `${window.env.REACT_APP_CHEMICAL_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
    [MODULES.SUPPLIERS]: `${window.env.REACT_APP_COMPANY_SERVICE}/api/summary?responsibleId=${responsibleId ?? ''
        }&timePeriod=${timePeriod}`,
} as Record<string, string>)

export const getSearchDataUri = (searchPhrase: string, responsiblePositionId?: number) =>
({
    [MODULES.DOCS]: `${window.env.REACT_APP_DOC_SERVICE
        }/api/search?searchPhrase=${searchPhrase}${responsiblePositionId ? `&responsiblePositionId=${responsiblePositionId}` : ''
        }
        `,
    [MODULES.SKILLS]: `${window.env.REACT_APP_SKILL_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.ISSUES]: `${window.env.REACT_APP_ISSUE_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.PROJECTS]: `${window.env.REACT_APP_PROJECT_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.SYSTEMATIC_TASKS]: `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.IMPROVEMENTS]: `${window.env.REACT_APP_IMPROVEMENT_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.OBJECTS]: `${window.env.REACT_APP_OBJECT_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.RISKS]: `${window.env.REACT_APP_RISK_SERVICE}/api/Risks/search?searchPhrase=${searchPhrase}`,
    [MODULES.GOALS]: `${window.env.REACT_APP_GOAL_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
    [MODULES.SUPPLIERS]: `${window.env.REACT_APP_COMPANY_SERVICE}/api/supplier/files/search?searchPhrase=${searchPhrase}`,
    [MODULES.CHEMICALS]: `${window.env.REACT_APP_CHEMICAL_SERVICE}/api/search?searchPhrase=${searchPhrase}`,
} as Record<string, string>)
