import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Form } from 'semantic-ui-react'
import DateInput from './dateInput'

export interface DateFilterControlProps {
    title: string
    from?: string
    to?: string
    onChange: (from: string, to: string) => void
}

export const DateFilterControl: React.FunctionComponent<DateFilterControlProps> = ({
    title,
    from,
    to,
    onChange,
}) => {
    const { t } = useTranslation()
    const [currentFrom, setFrom] = useState(from ?? '')
    const [currentTo, setTo] = useState(to ?? '')

    const fromLabel = title ? `${title} - ${t('dateFrom')}` : t('dateFrom')

    useEffect(() => {
        setFrom(from ?? '')
        setTo(to ?? '')
    }, [from, to])

    return (
        <Form>
            <Form.Group widths="equal" style={{ maxWidth: '700px' }}>
                <Form.Field
                    label={fromLabel}
                    control={DateInput}
                    value={currentFrom}
                    onChange={(e: Event, d: any) => {
                        setFrom(d.value)
                        onChange(d.value, currentTo)
                    }}
                />

                <Form.Field
                    label={t('dateTo')}
                    control={DateInput}
                    value={currentTo}
                    onChange={(e: Event, d: any) => {
                        setTo(d.value)
                        onChange(currentFrom, d.value)
                    }}
                />
            </Form.Group>
        </Form>
    )
}
