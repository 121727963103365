import React, { FunctionComponent } from 'react'
import { Icon, SemanticCOLORS, TableCell } from 'semantic-ui-react'
import { formatDate } from '../../../actions/util'
import { buildLinkToFile } from '../../../hooks/files'
import InfoTableRow from '../infoTableRow'
import { guessIconFromName } from '../../../utils/Files'
import { FileListing } from '../files/types'
import styled from 'styled-components'
const StyledLink = styled.a<{ disabled: boolean }>`
    ${p => p.disabled && `pointer-events: none;
    color: rgba(40, 40, 40, .3);`}
    `
export interface BackchannelFileRowProp {
    module: string
    file: FileListing
    includeToken: boolean
    disabled: boolean
}

const BackchannelFileRow: FunctionComponent<BackchannelFileRowProp> = ({
    module,
    file,
    includeToken,
    disabled
}) => {
    return (
        <InfoTableRow $isInfo={file.isInheritedFromConcern}>
            <TableCell collapsing disabled={disabled}>
                <FileIcon file={file} />
            </TableCell>
            <TableCell disabled={disabled}>
                <FileLink
                    module={module}
                    file={file}
                    disabled={disabled}
                    includeToken={file.isInheritedFromConcern || includeToken}
                />
            </TableCell>
            <TableCell disabled={disabled}>{formatDate(file.created)}</TableCell>

            {file.extraFields &&
                Object.entries(file.extraFields).map(([key, value]) => (
                    <TableCell key={key} disabled={disabled}>{value}</TableCell>
                ))}
        </InfoTableRow>
    )
}

export const FileLink: FunctionComponent<{
    module: string
    file: FileListing
    disabled: boolean
    includeToken?: boolean
}> = ({ module, file, disabled, includeToken = true }) => {

    return (
        <StyledLink
            href={buildLinkToFile(module, file.id, includeToken ? file.downloadToken : undefined)}
            target="_blank"
            rel="noreferrer"
            disabled={disabled}
        >
            {file.name}
        </StyledLink>
    )
}

export const FileIcon: FunctionComponent<{ file: FileListing; color?: SemanticCOLORS }> = ({
    file,
    color,
}) => <Icon name={guessIconFromName(file.name)} size="large" color={color} />

export default BackchannelFileRow
