import { IActionPlanDto, IInternalAssessmentDto, ISupplierResponse } from "../../../api/suppliers";

export const mapSupplierToRequest = (supplier: ISupplierResponse): ISupplierResponse => ({
    id: supplier.id,
    name: supplier.name,
    group: supplier.group,
    active: supplier.active,
    language: supplier.language,
    service: supplier.service,
    quality: supplier.quality,
    environment: supplier.environment,
    workEnvironment: supplier.workEnvironment,
    csr: supplier.csr,
    loadBearingStructureWeldingCertificate: supplier.loadBearingStructureWeldingCertificate,
    qualityCertificationExpires: supplier.qualityCertificationExpires ? new Date(supplier.qualityCertificationExpires) : null,
    environmentCertificationExpires: supplier.environmentCertificationExpires ? new Date(supplier.environmentCertificationExpires) : null,
    workEnvironmentCertificationExpires: supplier.workEnvironmentCertificationExpires ? new Date(supplier.workEnvironmentCertificationExpires) : null,
    csrCertificationExpires: supplier.csrCertificationExpires ? new Date(supplier.csrCertificationExpires) : null,
    otherCertificationExpires: supplier.otherCertificationExpires ? new Date(supplier.otherCertificationExpires) : null,
    email: supplier.email,
    interval: supplier.interval,
    contact: supplier.contact,
    position: supplier.position,
    zipCode: supplier.zipCode,
    organizationNumber: supplier.organizationNumber,
    location: supplier.location,
    telephoneNumber: supplier.telephoneNumber,
    country: supplier.country,
    webpage: supplier.webpage,
    description: supplier.description,
    comment: supplier.comment,
    address: supplier.address,
    sId: supplier.sId
});

export const mapActionPlanToRequest = (actionPlan: IActionPlanDto): IActionPlanDto => ({
    id: actionPlan.id,
    status: actionPlan.status,
    companyId: actionPlan.companyId,
    supplierId: actionPlan.supplierId,
    responsibleId: actionPlan.responsibleId,
    topic: actionPlan.topic,
    description: actionPlan.description,
    useReview: actionPlan.useReview || false,
    doneBefore: actionPlan.doneBefore ? new Date(actionPlan.doneBefore) : null,
    reviewDate: actionPlan.reviewDate ? new Date(actionPlan.reviewDate) : null,
    endedDate: actionPlan.endedDate ? new Date(actionPlan.endedDate) : null,
    reviewResponsibleId: actionPlan.reviewResponsibleId,
    reviewText: actionPlan.reviewText,
    endedBy: actionPlan.endedBy
});

export const mapInternalAssessmentToRequest = (internalAssessment: IInternalAssessmentDto): IInternalAssessmentDto => ({
    id: internalAssessment.id,
    type: internalAssessment.type,
    companyId: internalAssessment.companyId,
    supplierId: internalAssessment.supplierId,
    questionId: internalAssessment.questionId,
    swedishQuestion: internalAssessment.swedishQuestion,
    englishQuestion: internalAssessment.englishQuestion,
    comment: internalAssessment.comment,
    points: internalAssessment.points,
    date: internalAssessment.date ? new Date(internalAssessment.date) : null
});