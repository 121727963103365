import { useEffect } from 'react'
import { useCrud } from '../crud/use-crud'

export type DocumentLawConnection = {
    id: number
    name: string
    denomination: string
    companyId: number
    lawId: number
    lawUpdatedTo: string
    lawDetails: string
    lawUrl: string
    affectedBy: string
    evaluated: string
    lawType: string
    lawTypeId: number
    isInheritedFromConcern: boolean
}
export type DocumentLawConnectionRequest = {
    documentId: number
    lawId: number
}

export const useLawDocumentConnection = (documentId: number) => {
    const { fetch, create, remove } = useCrud<DocumentLawConnection, DocumentLawConnectionRequest>(
        documentId === 0
            ? undefined
            : `${window.env.REACT_APP_LAW_SERVICE}/api/DocumentConnection?documentId=${documentId}`,
        `${window.env.REACT_APP_LAW_SERVICE}/api/DocumentConnection`,
        'id',
    )

    const data: DocumentLawConnection[] = fetch.data || []
    useEffect(() => {
        if (fetch.error) {
            console.log('ERROR :D: D ', fetch.error)
        }
    }, [fetch.error])

    return {
        loading: fetch.loading,
        removeConnection: (id: number) => remove(id),
        createConnection: (lawId: number) => create({ documentId, lawId }),
        connectedLaws: data,
    }
}
