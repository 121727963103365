import React, { FunctionComponent } from 'react'
import { useMobileMode } from '../../../hooks/mobileMode'
import { BackchannelFileTable } from './backchannelFileTable'
import { FileListing } from '../files/types'

export interface IBackchannelFilesProps {
    files: FileListing[]
    module: string
    disabled: boolean
    includeToken?: boolean
}

const BackchannelFiles: FunctionComponent<IBackchannelFilesProps> = ({
    files,
    module,
    disabled,
    includeToken = true,
}) => {
    const { isInMobileMode } = useMobileMode()
    return (
        <div className={files?.length === 0 ? "hide-in-print" : ''}>

            <BackchannelFileTable
                module={module}
                files={files ?? []}
                isInMobileMode={isInMobileMode}
                includeToken={includeToken}
                disabled={disabled}
            />
        </div>
    )
}

export default BackchannelFiles
