import useSWR from 'swr'
import { isInThePast } from '../../actions/util'
import useApi from '../useApi'

export interface BasicObjectCase {
    id?: number
    title: string
    objectId?: number
    categoryId?: number
    priority?: number
    doneBefore?: string
    comment?: string
    doneBy?: string
    externalActor?: string
    sendNotificationToDoneBy?: boolean
    reportedBy?: string
    result?: string
}

export interface ObjectCase extends BasicObjectCase {
    objectName: string
    objectNumber?: string
    category: string
    estimatedTime?: number
    actualTime?: number
    externalActorTime?: number
    costPerHour?: number
    materialCost?: number
    externalActorCostPerHour?: number
    closed?: boolean
}

export const useDueObjectCases = () =>
    useSWR<ObjectCase[]>(`${window.env.REACT_APP_OBJECT_SERVICE}/api/object/case/due`)

export const useAdminObjectCase = (caseId?: number) => {
    const { mutate } = useDueObjectCases()
    const { loading, error, post } = useApi<ObjectCase>(
        caseId ? `${window.env.REACT_APP_OBJECT_SERVICE}/api/object/case/${caseId}` : undefined,
        {
            suppressFetch: true,
        },
    )

    return {
        loading,
        error,
        update: (objectCase: ObjectCase) =>
            post(objectCase).then(() => {
                mutate()
            }),
        create: (objectCase: BasicObjectCase) =>
            post(objectCase, `${window.env.REACT_APP_OBJECT_SERVICE}/api/object/case`, false),
    }
}

export const useNumDueObjectCases = () => {
    const { data } = useDueObjectCases()
    return data?.filter(x => isInThePast(x.doneBefore))?.length ?? 0
}
