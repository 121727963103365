import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../../../api'
import { IUserSkillsDto } from '../../../../../api/skills';
import FormActions from '../../../FormActions';
import { mapUserSkillRequest } from '../../../../../utils/Mappings/Competence';
import ControlDateField from '../../../../fields/ControlDateField';
import ControlInput from '../../../../fields/ControlInput';

export interface IUserSkillFormProps {
    userId: string
    skill: IUserSkillsDto
    onCancel: () => void
    refresh: () => void
}

export const userSkillRequestSchema = z.object({
    validFrom: z.date().nullable(),
    validTo: z.date().nullable(),
    competenceLevel: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
});

const UserSkillForm = ({ userId, skill, onCancel, refresh }: IUserSkillFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IUserSkillsDto>({
        defaultValues: mapUserSkillRequest(skill),
        resolver: zodResolver(userSkillRequestSchema),
    });

    const onPositionRequest = async (data: IUserSkillsDto) => {
        return await API.skills.editCompetenceUserSkill(userId, skill.id, data)
    }


    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onPositionRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn >
                        <ControlDateField name="validFrom" label={t('validFrom')} control={control} required disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="validTo" label={t('validTo')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput type='number' name="competenceLevel" label={t('competenceLevel')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default UserSkillForm;