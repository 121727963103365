import React from 'react'
import { CheckSquare, Square } from 'react-feather'
import { updateResponsiblePositions } from '../../actions/document'
import { handleAxiosError } from '../../actions/error'
import { AdminRoles, isInRole } from '../../auth/roles'
import { getState } from '../../state'
import ResponsibleSelector from '../modules/docs/viewPage/responsibleSelector'
import { getPositionFromState } from '../../state/helpers'

export interface DocumentResponsiblePositionCellProps {
    documentId: number
    currentPositions?: number[]
    onUpdate?: (newValues: number[]) => void
}

const DocumentResponsiblePositionCell: React.FunctionComponent<
    DocumentResponsiblePositionCellProps
> = ({ documentId, currentPositions, onUpdate }) => {
    const {
        state,
        dispatch,
    } = getState()
    const isAdmin = isInRole(state.userProfile, AdminRoles.DocumentsAdminRole)

    const handleChange = (value: number[]) =>
        updateResponsiblePositions(documentId, value)
            .then(() => onUpdate?.(value))
            .catch(handleAxiosError(dispatch))

    const findPosition = currentPositions?.some(x => getPositionFromState(state)(x))
    return (
        <>
            {findPosition ? <CheckSquare /> : <Square />
            }
            {isAdmin && <ResponsibleSelector value={currentPositions} onChange={handleChange} />}
        </>
    )
}

export default DocumentResponsiblePositionCell
