import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import SupplierForm from '../../../forms/SupplierForm'
import { hasModule, hasSupplierConcernModule, MODULES } from '../../../../auth/modules'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Info } from 'react-feather'
import { getState } from '../../../../state'

interface ISupplierFormPageProps {
    refresh: () => void
    isNew: boolean
}

const SupplierFormPage = ({ refresh, isNew }: ISupplierFormPageProps) => {
    const { t } = useTranslation()
    const { supplierId } = useParams<{ supplierId: string }>()
    const { state } = getState()
    const {
        isLoading,
        data: selectedSupplier,
    } = useGetQueryByParams(!!supplierId, API.suppliers.getById, "selectedSupplier", supplierId)
    const hasSupplierConcern = hasSupplierConcernModule()
    const hasDeviationModule = hasModule(state)(MODULES.ISSUES)
    if (isLoading) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('supplier').toLowerCase() }) : t('adminSuppliers')}</Header>
            {hasSupplierConcern && <Message info icon>
                <SemanticFeatherIcon FeatherIcon={Info} size="32px" centerIcon={true} />
                {t('hasSupplierConcernMessageBody')} {hasDeviationModule && t('hasSupplierConcernAndDevationsMessageBody')}
            </Message>}
            <SupplierForm refresh={refresh} isNew={isNew} selectedSupplier={selectedSupplier} hasSupplierConcernModule={hasSupplierConcern} hasDeviationModule={hasDeviationModule} />
        </Container>
    )
}

export default SupplierFormPage
