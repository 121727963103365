import React from "react"
import CollapsingSegment from "../../../common/collapsingSegment"
import { Grid, GridColumn, GridRow, Label } from "semantic-ui-react"
import ControlTextArea from "../../../fields/ControlTextArea"
import ControlDropdown, { IDropdownOption } from "../../../fields/ControlDropdown"
import ControlDateField from "../../../fields/ControlDateField"
import { useTranslation } from "react-i18next"
import { Control, FieldValues } from "react-hook-form"
import { DeviationMethod } from "../../../../api/issues"

interface IEvaluateSectionProps<T extends FieldValues = any> {
    control: Control<T>
    isSaving: boolean
    users: IDropdownOption[]
    deviationMethod?: DeviationMethod
    reviewBeforeDate?: boolean
}

const EvaluateSection = ({ control, isSaving, users, deviationMethod = DeviationMethod.Pdca, reviewBeforeDate = true }: IEvaluateSectionProps) => {
    const { t } = useTranslation()
    return (
        <CollapsingSegment heading={t('evaluation')} defaultOpen={deviationMethod === DeviationMethod.EightD}>
            <Grid stackable>
                {reviewBeforeDate && <Grid.Row columns="2">
                    <Grid.Column>
                        <ControlDateField
                            name="reviewBeforeDate"
                            label={t('reviewBeforeDate')}
                            control={control}
                            disabled={isSaving} />
                    </Grid.Column>
                </Grid.Row>}
                <Grid.Row columns="1">
                    <Grid.Column>
                        {deviationMethod === DeviationMethod.EightD && <Label as='a' color='green' ribbon>
                            D7
                        </Label>}
                        <ControlTextArea label={t('evaluationResult')} name="reviewText" control={control} disabled={isSaving} />
                    </Grid.Column>
                </Grid.Row>
                <GridRow columns={2}>
                    <GridColumn >
                        <ControlDropdown
                            name="reviewResponsibleId"
                            label={t("reviewResponsibleId")}
                            control={control}
                            disabled={isSaving}
                            collection={users} />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField
                            name="reviewDate"
                            label={t('reviewDate')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
        </CollapsingSegment>
    )
}
export default EvaluateSection