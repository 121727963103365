import React from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DropRowType } from '.'
import { RowIndicatedBackgroundColor } from '../../../styles'
import { GoalDragType } from './dragableGoalRow'

export interface MoveToRootDropTargetProps {
    onMovedTo: (idMoved: number) => void
}

const MoveToRootDropTargetElement = styled.div<{ $isOver: boolean }>`
    color: black;
    background-color: ${RowIndicatedBackgroundColor};
    opacity: ${props => (props.$isOver ? 1 : 0.5)};
    padding: 5px;
    user-select: none;
`

const MoveToRootDropTarget: React.FunctionComponent<MoveToRootDropTargetProps> = ({
    onMovedTo,
}) => {
    const { t } = useTranslation()

    const [{ isOver }, drop] = useDrop<GoalDragType, unknown, { isOver: boolean }>(() => ({
        accept: DropRowType,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
        drop: item => onMovedTo(item.id),
    }))

    return (
        <MoveToRootDropTargetElement ref={drop} $isOver={isOver}>
            {t('dropHereToMoveToRoot')}
        </MoveToRootDropTargetElement>
    )
}

export default MoveToRootDropTarget
