import React from 'react'
import { EyeOff } from 'react-feather'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'

export interface DocumentNameProps {
    name: string
    isPublished: boolean
}

const DocumentName: React.FunctionComponent<DocumentNameProps> = ({ name, isPublished }) =>
    isPublished ? (
        <>{name}</>
    ) : (
        <>
            {name} <SemanticFeatherIcon FeatherIcon={EyeOff} />
        </>
    )

export default DocumentName
