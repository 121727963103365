import React from 'react'
import styled from 'styled-components'

const FlexBoxInner = styled.div<FlexBoxProps>`
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    flex-grow: ${props => props.grow};
    flex-shrink: ${props => props.shrink};
    flex-wrap: ${props => props.wrap};
    gap: ${props => props.gap};
`

export interface FlexBoxProps {
    direction?: 'column' | 'row'
    justifyContent?:
        | 'center'
        | 'start'
        | 'end'
        | 'flex-start'
        | 'flex-end'
        | 'normal'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'stretch'
    alignItems?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'normal'
    grow?: number
    shrink?: number
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
    gap?: string
    style?: any
}

const FlexBox: React.FunctionComponent<FlexBoxProps> = ({ children, ...rest }) => {
    const boxProps = {
        direction: 'row',
        justifyContent: 'normal',
        alignItems: 'normal',
        grow: 0,
        shrink: 1,
        wrap: 'nowrap',
        gap: 'normal',
        ...rest,
    } as any

    return <FlexBoxInner {...boxProps}>{children}</FlexBoxInner>
}

export default FlexBox
