import React from 'react'
import { Routes, Route } from 'react-router'
import { isInPageRenderMode } from '../../../actions/util'
import { Grid } from 'semantic-ui-react'
import SupplierDeviationFormPage from './SupplierDeviationFormPage'

const ExternalModule: React.FunctionComponent = () => {
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route path={`:sId/company/:companyId/deviations/:deviationId`} element={<SupplierDeviationFormPage />} />
                    </Routes>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ExternalModule
