import React, { useMemo, useState } from 'react'
import { Button, Checkbox, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import { ColumnFiltersState, PaginationState, SortingState, VisibilityState, createColumnHelper, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { IDeviationSettingUser } from '../../../../../api/issues'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Edit, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { BooleanFilter, booleanFilter } from '../../../../wrappers/TableReact/Filters/BooleanFilter'
import { getUserFromState } from '../../../../../state/helpers'
import { getState } from '../../../../../state'
import UserNameDisplay from '../../../../common/UserNameDisplay'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import ResponsibleSafetyForm from '../../../../forms/ResponsibleSafetyForm'
import ResponsibleSafetyBatchForm from '../../../../forms/ResponsibleSafetyBatchForm'
import { useDeleteUserSetting } from './useDeleteUserSetting'

const ResponsibleSafetyUserList = () => {
    const { t } = useTranslation()
    const { state } = getState()
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.issues.getUserSettings, "deviationUserSettings")

    const [showAddModal, setShowAddModal] = useState(false);
    const { isDestroying, onDestroy } = useDeleteUserSetting(refresh)
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IDeviationSettingUser | null>(null);

    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('issueSettingUserListVisibilityState', {});
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('issueSettingUserListPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('issueSettingUserListFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('issueSettingUserListSortingState', []);

    const onCancel = () => {
        setOpen(false)
        setSelectedUser(null)
    }

    const handleSetSelectedUser = (user: IDeviationSettingUser) => {
        setOpen(true)
        setSelectedUser(user)
    }

    const columnHelper = createColumnHelper<IDeviationSettingUser>()
    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.accessor((r) => getUserFromState(state)(r.userId)?.name ?? "", {
                id: "userId",
                header: t('name') as any,
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.userId}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor("deviationResponsible", {
                id: "deviationResponsible",
                header: t('deviationResponsible') as any,
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox readOnly checked={!!row.original.deviationResponsible} />
            }),
            columnHelper.accessor("accidentResponsible", {
                id: "accidentResponsible",
                header: t('accidentResponsible') as any,
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox readOnly checked={!!row.original.accidentResponsible} />
            }),
            columnHelper.accessor("safetyOfficer", {
                id: "safetyOfficer",
                header: t('safetyOfficer') as any,
                filterFn: booleanFilter,
                meta: {
                    filterComponent: (setFilterValue: (updater: boolean) => void, value: boolean) => (
                        <BooleanFilter
                            setFilterValue={setFilterValue}
                            value={value}
                        />
                    ),
                    checkbox: true
                },
                cell: ({ row }) => <Checkbox readOnly checked={!!row.original.safetyOfficer} />
            }),
            columnHelper.display({
                id: "actions",
                enableHiding: false,
                size: 65,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' disabled={isDestroying} onClick={() => handleSetSelectedUser(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Button title={t('delete')} disabled={isDestroying} onClick={() => onDestroy({
                            confirmText: t('confirm.deleteValue', { value: getUserFromState(state)(row.original.userId)?.name }),
                            id: row.original.id
                        })}
                            type='button'
                            color='red'
                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />

                    </>
            }),
        ], [columnHelper, t, state, isDestroying, onDestroy])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        state: {
            columnVisibility,
            pagination,
            columnFilters,
            sorting
        },
        enableGlobalFilter: false,
        enableGrouping: false,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('deviationSettings.userResposibleMessageHeader')}
                </Message.Header>
                {t('deviationSettings.userResposibleMessageBody')}
            </Message>

            <TableReact table={table}
                onAddCallback={() => setShowAddModal(true)}
                canExport={false}
                overflowable />

            <ModalWrapper title={t('addValue', { value: t('responsible&safety').toLowerCase() })} open={showAddModal} onClose={() => setShowAddModal(false)} size='small'>
                <ResponsibleSafetyBatchForm onCancel={() => setShowAddModal(false)} refresh={refresh} />
            </ModalWrapper>

            {selectedUser && <ModalWrapper title={t('editValue', { value: getUserFromState(state)(selectedUser.userId)?.name })} open={open} onClose={onCancel} size='small'>
                <ResponsibleSafetyForm onCancel={onCancel} refresh={refresh} selectedUser={selectedUser} />
            </ModalWrapper>}

        </FullHeightStackingContainer>
    )
}

export default ResponsibleSafetyUserList
