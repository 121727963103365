import useSWR from 'swr'

export interface ObjectDto {
    id: number
    name: string
    objectNumber?: string
}

export interface ObjectCategory {
    id: number
    name: string
}

export const useObjects = () =>
    useSWR<ObjectDto[]>(`${window.env.REACT_APP_OBJECT_SERVICE}/api/object`)

export const useObjectCategories = () =>
    useSWR<ObjectCategory[]>(`${window.env.REACT_APP_OBJECT_SERVICE}/api/object/category`)
