import React from 'react'
import { Modal, Grid, Checkbox, Segment, Header, Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import Spacer from '../spacer'
import { Settings } from 'react-feather'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'

interface GridSettingsProps {
    columns: any[]
    onColumnHiddenChanged: (key: string, hidden: boolean) => void
}

const GridSettings: React.FunctionComponent<GridSettingsProps> = ({
    columns,
    onColumnHiddenChanged,
}) => {
    const { t } = useTranslation()

    return (
        <Modal
            trigger={
                <Button
                    icon={<SemanticFeatherIcon FeatherIcon={Settings} />}
                    floated="right"
                    type="button"
                />
            }
            closeIcon
        >
            <Modal.Header>{t('settings')}</Modal.Header>
            <Modal.Content>
                <Segment>
                    <Header>{t('visibleColumns')}</Header>
                    <Spacer />
                    <Grid columns={3} stackable>
                        {columns.map(c => (
                            <Grid.Column
                                key={c.key}
                                style={{ paddingTop: '2px', paddingBottom: '2px' }}
                            >
                                <Checkbox
                                    toggle
                                    label={c.name}
                                    checked={!c.hidden}
                                    onChange={(_, data) =>
                                        onColumnHiddenChanged(c.key, !data.checked)
                                    }
                                />
                            </Grid.Column>
                        ))}
                    </Grid>
                    <Spacer />
                </Segment>
            </Modal.Content>
        </Modal>
    )
}

export default GridSettings
