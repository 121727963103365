import React from 'react'
import { ChemicalRisk } from '../../hooks/chemicals'
import FlexBox from '../common/flexBox'

export interface SafetySymbolsProps {
    risk?: ChemicalRisk
    imageSize?: string
}

const SafetySymbols: React.FunctionComponent<SafetySymbolsProps> = ({
    risk,
    imageSize = '40px',
}) => {
    if (!risk) return null

    return (
        <FlexBox gap="5px">
            {risk?.breathingProtection && (
                <img
                    src="/images/skydd/Andningsskydd.png"
                    alt="breating-protection"
                    width={imageSize}
                />
            )}
            {risk?.face && (
                <img src="/images/skydd/Ansiktsskydd.png" alt="face-protection" width={imageSize} />
            )}
            {risk?.glasses && (
                <img
                    src="/images/skydd/Skyddsglasogon.png"
                    alt="protective-glasses"
                    width={imageSize}
                />
            )}
            {risk?.gloves && (
                <img
                    src="/images/skydd/Skyddshandskar.png"
                    alt="protective-gloves"
                    width={imageSize}
                />
            )}
            {risk?.clothing && (
                <img
                    src="/images/skydd/Skyddsklader.png"
                    alt="protective-clothing"
                    width={imageSize}
                />
            )}
            {risk?.shoes && (
                <img src="/images/skydd/Skyddsskor.png" alt="protective-shoes" width={imageSize} />
            )}
        </FlexBox>
    )
}

export default SafetySymbols
