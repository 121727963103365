import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../../../api'
import { ICompetenceUserSkillBatchRequest, IUserSkillsDto } from '../../../../../api/skills';
import FormActions from '../../../FormActions';
import ControlDropdown from '../../../../fields/ControlDropdown';
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import Loading from '../../../../common/Loading';
import ControlDateField from '../../../../fields/ControlDateField';
import ControlInput from '../../../../fields/ControlInput';

export interface IUserSkillBatchFormProps {
    userId: string
    userSkills: IUserSkillsDto[]
    onCancel: () => void
    refresh: () => void
}

export const userSkillBatchRequestSchema = z.object({
    validFrom: z.date().nullable(),
    validTo: z.date().nullable(),
    skillIds: z.array(z.number()).min(1, "required"),
    competenceLevel: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
});

const UserSkillBatchForm = ({ userId, userSkills, onCancel, refresh }: IUserSkillBatchFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<ICompetenceUserSkillBatchRequest>({
        defaultValues: { skillIds: [], validFrom: null, validTo: null, competenceLevel: null },
        resolver: zodResolver(userSkillBatchRequestSchema),
    });
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.skills.getSkillsListView, "competenceUserSkill")

    const onPositionRequest = async (data: ICompetenceUserSkillBatchRequest) => await API.skills.batchCompetenceUserSkill(userId, data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onPositionRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })
    if (isLoading || !data) {
        return <Loading />
    }
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown
                            name="skillIds"
                            label={t('skills')}
                            popupText={t('addMultiValue', { value: t('skills').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            required
                            collection={data.filter(x => !userSkills.some(p => p.id === x.id)).map(d => ({ value: d.id, text: d.name })) ?? []} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput type='number' name="competenceLevel" label={t('competenceLevel')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn >
                        <ControlDateField name="validFrom" label={t('validFrom')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="validTo" label={t('validTo')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default UserSkillBatchForm;