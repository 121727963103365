import React from 'react';
import { getState } from '../../state';
import { Modal, Message } from 'semantic-ui-react';
import { clearError } from '../../actions/error';

const MessageHost: React.FunctionComponent = () => {
    const { state: {errors}, dispatch } = getState(); 

    return (
        <Modal open={errors.length > 0} closeOnDimmerClick={false}>
            <Modal.Content>
                {errors.map((e,i) => 
                    <Message negative key={i} onDismiss={() => clearError(dispatch)(e)}>
                        <Message.Header>{e.topic}</Message.Header>
                        <p>{e.message}</p>
                    </Message>
                )}
            </Modal.Content>
        </Modal>
    );
}

export default MessageHost;