import React, { useState, useEffect } from 'react';
import { Modal, Grid, Form, Divider, Button, GridRow, GridColumn, FormCheckbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftCircle, Save } from 'react-feather';
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon';
import ControlInput from '../../fields/ControlInput';
import { Control, Controller, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { IProjectRequest } from '../../../api/projects';
import ControlDropdown from '../../fields/ControlDropdown';
import ControlDateField from '../../fields/ControlDateField';
import ControlTextArea from '../../fields/ControlTextArea';
import ControlCheckbox from '../../fields/ControlCheckbox';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
export interface ProjectActivityDialogProps {
    activityIdx: number
    disabled: boolean
    isNewActivity: boolean
    trigger: UseFormTrigger<IProjectRequest>
    onSave: () => Promise<void>
    handleRemove: (idx: number, isNewActivity: boolean) => void
    control: Control<IProjectRequest>
    activitiesIsChecklist: boolean
    setValue: UseFormSetValue<IProjectRequest>
    userOptions: { key: string, text: string, value: string }[]
}

export const ProjectActivityDialog: React.FunctionComponent<ProjectActivityDialogProps> = ({
    activityIdx,
    disabled,
    isNewActivity,
    trigger,
    handleRemove,
    onSave,
    control,
    activitiesIsChecklist,
    setValue,
    userOptions
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(activityIdx !== null);
    }, [activityIdx]);

    const debouncedValidation = debounce(async (name: "responsibleId" | "topic") => {
        await trigger(`activities.${activityIdx}.${name}`);
    }, 300); // Adjust the delay (in milliseconds) as needed


    const handleOnSave = async () => {
        const isValid = await trigger(`activities.${activityIdx}`)
        if (isValid) {
            if (isNewActivity) {
                setValue(`activities.${activityIdx}.id`, uuidv4()) //temporary id before save
            }
            setOpen(false)
            await onSave()
        }
    }

    const handleOnClose = () => {
        setOpen(false)
        handleRemove(activityIdx, isNewActivity)
    }
    return (
        <Modal open={open} closeOnDimmerClick={false} >
            <Modal.Header>
                {t('activity')}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Grid stackable>
                        {activitiesIsChecklist &&
                            <GridRow columns={2}>
                                <GridColumn>
                                    <Controller
                                        name={`activities.${activityIdx}.status` as any}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <FormCheckbox
                                                error={!!error}
                                                label={t('done')}
                                                checked={!(value & 0x01)}
                                                onChange={(e, d) => onChange(d.checked ? 4 : 1)}
                                                disabled={disabled}
                                                toggle
                                            />
                                        )
                                        }
                                    />
                                </GridColumn>

                                <GridColumn>
                                    <Controller
                                        name={`activities.${activityIdx}.status` as any}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <FormCheckbox
                                                error={!!error}
                                                label={t('inactive')}
                                                checked={!!(value & 0x08)}
                                                onChange={(e, d) => onChange(d.checked ? (value | 0x08) : (value & ~0x08))}
                                                disabled={disabled}
                                                toggle
                                            />
                                        )
                                        }
                                    />
                                </GridColumn>
                            </GridRow>
                        }
                        <GridRow>
                            <GridColumn>
                                <ControlInput
                                    control={control}
                                    disabled={disabled}
                                    label={t('activity')}
                                    required
                                    overrideOnChange={(value) => {
                                        setValue(`activities.${activityIdx}.topic`, value)
                                        debouncedValidation("topic");
                                    }}
                                    name={`activities.${activityIdx}.topic`}
                                />
                            </GridColumn>
                        </GridRow>

                        <GridRow columns="2">
                            <GridColumn>
                                <ControlDropdown
                                    name={`activities.${activityIdx}.responsibleId`}
                                    label={t("responsible")}
                                    control={control}
                                    disabled={disabled}
                                    required
                                    overrideOnChange={(value) => {
                                        setValue(`activities.${activityIdx}.responsibleId`, value)
                                        debouncedValidation("responsibleId");
                                    }}
                                    collection={userOptions} />
                            </GridColumn>
                            <GridColumn>
                                <ControlDateField
                                    name={`activities.${activityIdx}.doneBefore`}
                                    label={t('doneBefore')}
                                    control={control}
                                    disabled={disabled} />
                            </GridColumn>
                        </GridRow>
                        {isNewActivity ?
                            <GridRow>
                                <GridColumn>
                                    <ControlCheckbox control={control} disabled={disabled} label={t('sendAsAppointment')} name='sendAsAppointment' />
                                </GridColumn>
                            </GridRow>
                            : null}
                        <GridRow>
                            <GridColumn>
                                <ControlTextArea label={t('description')} name={`activities.${activityIdx}.description`} control={control} disabled={disabled} />
                            </GridColumn>
                        </GridRow>
                        <GridRow columns="2" className={activitiesIsChecklist ? 'hidden' : ''}>
                            <GridColumn>
                                <ControlInput
                                    name={`activities.${activityIdx}.cost`}
                                    control={control}
                                    disabled={disabled}
                                    label={t('cost')}
                                    overrideOnChange={(value) => {
                                        setValue(`activities.${activityIdx}.cost`, value ? value : null)
                                    }}
                                    type='number'
                                />
                            </GridColumn>
                            <GridColumn>
                                <ControlInput
                                    name={`activities.${activityIdx}.time`}
                                    control={control}
                                    disabled={disabled}
                                    label={t('time')}
                                    overrideOnChange={(value) => {
                                        setValue(`activities.${activityIdx}.time`, value ? value : null)
                                    }}
                                    type='number'
                                />
                            </GridColumn>
                        </GridRow>
                        <GridRow className={activitiesIsChecklist ? 'hidden' : ''}>
                            <GridColumn>
                                <Divider />
                            </GridColumn>
                        </GridRow>
                        <GridRow columns="2" className={activitiesIsChecklist ? 'hidden' : ''}>
                            <GridColumn>
                                <ControlInput
                                    control={control}
                                    disabled={disabled}
                                    label={t('doneBy')}
                                    name={`activities.${activityIdx}.doneBy`}
                                />
                            </GridColumn>
                            <GridColumn>
                                <ControlDateField
                                    name={`activities.${activityIdx}.dateDone`}
                                    label={t('doneDate')}
                                    control={control}
                                    disabled={disabled} />

                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                                <Button
                                    floated="right"
                                    content={t('cancel')}
                                    icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                                    onClick={handleOnClose}
                                    type="button"
                                    disabled={disabled}
                                />
                                <Button
                                    primary
                                    floated="right"
                                    content={t('save')}
                                    icon={
                                        <SemanticFeatherIcon
                                            FeatherIcon={Save}
                                        />
                                    }
                                    onClick={handleOnSave}
                                    type={'button'}
                                    disabled={disabled}
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Form>
            </Modal.Content>
        </Modal>
    );
}