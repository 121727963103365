import React, { useEffect, useState } from 'react'
import { ArrowLeftCircle, Save } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { Button, Form, Modal } from 'semantic-ui-react'
import { SystematicTaskActivity } from '../../hooks/systematicTasks/activity'
import useApi from '../../hooks/useApi'
import { getState } from '../../state'
import ActionButton from '../common/actionButton'
import DynamicFields, {
    DynamicFieldType,
    DynamicFieldsSpecification,
} from '../common/dynamicFields'
import ErrorBox from '../common/errorBox'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import { FieldDataItem } from './EditMobileSystematicTask'

export interface MobileSystematicTaskActivityModalProps {
    field: FieldDataItem | null
    sysId: number
    sysPlanId: number
    onClose: () => void
}

const MobileSystematicTaskActivityModal = ({
    field,
    sysId,
    sysPlanId,
    onClose,
}: MobileSystematicTaskActivityModalProps) => {
    const { t } = useTranslation()
    const [error, setError] = useState<string | undefined>(undefined)
    const departments = getState().state.departments

    const [activity, setActivity] = useState<SystematicTaskActivity>({
        title: '',
        date: new Date().toISOString(),
        dueDate: new Date().toISOString(),
        groupId: 0,
        responsibleId: '',
        description: '',
    })

    const { post } = useApi<SystematicTaskActivity>(
        activity ? `${window.env.REACT_APP_SYSTEMATICTASK_SERVICE}/api/activities` : undefined,
        {
            suppressFetch: true,
        },
    )

    useEffect(() => {
        if (field) {
            setActivity({
                sysId,
                title: field.label,
                date: new Date().toISOString(),
                dueDate: new Date().toISOString(),
                groupId: 0,
                sysPlanId,
                type: 4, // TODO: Create enum for activity types
                responsibleId: '',
                description: (field?.value as string) || '',
            })
        }
    }, [field, sysId, sysPlanId])

    const onSaveClick = async () => {
        if (
            !activity.title ||
            !activity.description ||
            !activity.responsibleId ||
            !activity.groupId
        ) {
            setError(t('fillInAllRequiredFields'))
            return
        }

        setError(undefined)
        await post(activity)

        onClose()
    }

    const fields: DynamicFieldsSpecification = {
        title: {
            type: DynamicFieldType.String,
            labelOverride: 'topic',
            required: true,
            value: activity?.title,
        },
        responsibleId: {
            type: DynamicFieldType.UserDropdown,
            labelOverride: 'responsible',
            required: true,
            value: activity?.responsibleId,
        },
        groupId: {
            type: DynamicFieldType.GenericDropdown,
            labelOverride: 'department',
            required: true,
            value: activity?.groupId,
            dropdownItems: departments.map(d => ({ key: d.id, value: d.id, text: d.name })),
        },
        dueDate: {
            type: DynamicFieldType.Date,
            required: false,
            value: activity?.dueDate,
            labelOverride: 'dueDate',
        },
        description: {
            type: DynamicFieldType.Text,
            labelOverride: 'description',
            required: false,
            value: activity?.description,
        },
    }

    return (
        <Modal open={field !== null} size="small">
            <Modal.Header>{t('addActivity')}</Modal.Header>
            <Modal.Content>
                <Form>
                    <DynamicFields
                        fields={fields}
                        onChange={(name, value) =>
                            setActivity(activity => ({ ...activity, [name]: value }))
                        }
                        translateFieldNames={true}
                        standaloneGrid={true}
                        columns={1}
                    />
                </Form>

                <ErrorBox error={error} />
            </Modal.Content>
            <Modal.Actions>
                <ActionButton
                    action={() => onSaveClick()}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                    disabled={false}
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>

                <Button onClick={() => onClose()} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default MobileSystematicTaskActivityModal
