import React from 'react'
import { Routes, Route } from 'react-router'
import styled from 'styled-components'
import { BackgroundColor } from '../../styles'
import AdminMenu from './adminMenu'
import AdminUsersPage from './adminUsersPage'
import AdminCompaniesPage from './adminCompaniesPage'
import AdminDepartmentsPage from './adminDepartmentsPage'
import AdminFilesPage from './adminFilesPages'
import { isInPageRenderMode } from '../../actions/util'
import { getState } from '../../state'
import { isInRole, AdminRoles } from '../../auth/roles'

const AdminModuleRoot = styled.div`
    height: 100%;
    display: flex;
    flex-grow: 1;
`

const AdminMenuContainer = styled.div`
    background-color: ${BackgroundColor};
    flex-basis: 20%;
    max-width: 350px;
    padding: 30px 20px;
    flex-shrink: 0;

    a {
        color: lightgray;
        font-size: 1.2em;
    }

    a:hover,
    a.active {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
`

const AdminModuleContent = styled.div`
    padding: 10px;
    flex-grow: 1;
`

const AdminModule: React.FunctionComponent = () => {
    const {
        state: { userProfile },
    } = getState()

    if (!isInRole(userProfile, AdminRoles.ViqmaAdminRole)) return <>Bad permissions</>

    return (
        <AdminModuleRoot>
            {isInPageRenderMode() && (
                <AdminMenuContainer>
                    <AdminMenu />
                </AdminMenuContainer>
            )}
            <AdminModuleContent>
                <Routes>
                    <Route
                        path={'/'}
                        element={<h1>Admin</h1>}
                    />
                    <Route
                        path={`/files`}
                        element={<AdminFilesPage />}
                    />
                    <Route
                        path={`/users`}
                        element={<AdminUsersPage />}
                    />
                    <Route
                        path={`/rights`}
                        element={<h1>Rights</h1>}
                    />
                    <Route
                        path={`/departments`}
                        element={<AdminDepartmentsPage />}
                    />
                    <Route
                        path={`/companies`}
                        element={<AdminCompaniesPage />}
                    />
                    <Route
                        path={`/laws`}
                        element={<h1>Laws</h1>}
                    />
                    <Route
                        path={`/supplier-questions`}
                        element={<h1>Supplier questions</h1>}
                    />
                    <Route
                        path={`/news`}
                        element={<h1>News</h1>}
                    />
                </Routes>
            </AdminModuleContent>
        </AdminModuleRoot>
    )
}

export default AdminModule
