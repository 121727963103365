import React from 'react'
import { useParams } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'
import { SurveyLoadingState, SurveySubmissionDto, useSurvey } from '../../../hooks/surveys/survey'
import ErrorBox from '../../common/errorBox'
import SurveyMain from './surveyMain'

const SurveyPage: React.FunctionComponent = () => {
    const { surveyGuid } = useParams()
    const { survey, error, submitAnswers, loadingState } = useSurvey(surveyGuid)

    const onQuestionsAnswered = (submissionDto: SurveySubmissionDto) =>
        submitAnswers(surveyGuid!, submissionDto)

    switch (loadingState) {
        case SurveyLoadingState.Loaded:
            return (
                <>
                    <ErrorBox error={error} />
                    <SurveyMain
                        surveyGuid={surveyGuid!}
                        survey={survey!}
                        onQuestionsAnswered={onQuestionsAnswered}
                    />
                </>
            )
        default:
            return (
                <Dimmer active inverted>
                    <Loader content="Undersökning laddas" />
                </Dimmer>
            )
    }
}

export default SurveyPage
