import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Header,
    Table,
    TableBody,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import BackchannelFileRow from './backchannelFileRow'
import { FileListing } from '../files/types'
import styled from 'styled-components'

const StyledTableHeaderCell = styled(TableHeaderCell) <{ disabled: boolean }>`
    ${p => p.disabled && `pointer-events: none !important;
    color: rgba(40, 40, 40, .3) !important;`}
    `
export interface BackchannelFileTableProps {
    module: string
    files: FileListing[]
    disabled: boolean
    isInMobileMode?: boolean
    includeToken?: boolean
}

export const BackchannelFileTable: FunctionComponent<BackchannelFileTableProps> = ({
    module,
    files,
    disabled,
    isInMobileMode,
    includeToken,
}) => {
    const { t } = useTranslation()
    const extraFields = Object.keys(files?.find(x => x.extraFields)?.extraFields ?? {})
    return (
        <>
            <Header disabled={disabled}>{t('files')}</Header>

            <Table style={{ marginBottom: isInMobileMode ? 20 : 0 }} basic="very" unstackable>
                <TableHeader>
                    <TableRow>
                        <StyledTableHeaderCell disabled={disabled}>{t('fileType')}</StyledTableHeaderCell>
                        <StyledTableHeaderCell disabled={disabled}>{t('filename')}</StyledTableHeaderCell>
                        <StyledTableHeaderCell disabled={disabled}>{t('lastUpdated')}</StyledTableHeaderCell>

                        {extraFields?.map(key => (
                            <Table.HeaderCell key={key}>{t(key)}</Table.HeaderCell>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {files?.map(file => (
                        <BackchannelFileRow
                            key={file.name}
                            module={module}
                            file={file}
                            includeToken={includeToken ?? false}
                            disabled={disabled}
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    )
}
