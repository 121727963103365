import { useCallback, useState } from 'react'

export const useDebouncedState = <T = undefined>(
    initialValue: T,
    timeout = 1000,
): [T, (value: T) => void] => {
    const [internalState, setInternalState] = useState<T>(initialValue)
    const [pendingTimeout, setPendingTimeout] = useState<NodeJS.Timeout>()

    const setValue = useCallback(
        (value: T) => {
            if (pendingTimeout) clearTimeout(pendingTimeout)
            setPendingTimeout(setTimeout(() => setInternalState(value), timeout))
        },
        [pendingTimeout, timeout],
    )

    return [internalState, setValue]
}
