import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { SimplifiedDocumentItem } from '../../../../hooks/docs/process'
import DocumentName from '../common/documentName'

const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5px 0 0 0;

    a {
        display: inline-block;
    }

    a:after {
        content: '|';
        color: black;
        padding: 0 5px;
    }

    a:last-child:after {
        display: none;
    }
`

export interface DocumentChildrenListProps {
    children: SimplifiedDocumentItem[]
}

const DocumentChildrenList: React.FunctionComponent<DocumentChildrenListProps> = ({ children }) => {
    return (
        <ListContainer>
            {children.map(document => (
                <Link key={document.id} to={`../${document.id}`}>
                    <DocumentName name={document.rubrik} isPublished={document.isPublished} />
                </Link>
            ))}
        </ListContainer>
    )
}

export default DocumentChildrenList
