import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Form } from 'semantic-ui-react'
import { handleAxiosError } from '../../../actions/error'
import { hasModule } from '../../../auth/modules'
import { useImprovementGroups } from '../../../hooks/improvments/group'
import { Suggestion, useSuggestion } from '../../../hooks/improvments/suggestion'
import { useMobileMode } from '../../../hooks/mobileMode'
import { getState } from '../../../state'
import DynamicFields, {
    DynamicFieldsSpecification,
    DynamicFieldType,
} from '../../common/dynamicFields'
import Files, { UploadAllPendingFilesCallback } from '../../common/files'
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup'

const AddImprovementSuggestion: React.FunctionComponent = () => {
    const navigate = useNavigate()
    const { isInMobileMode } = useMobileMode()
    const { state, dispatch } = getState()

    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()

    const [suggestion, setSuggestion] = useState<Suggestion>({
        expectedResult: '',
        idea: '',
        suggestion: '',
        reportedBy: state.userProfile?.sub,
    })

    const { data: improvementGroups } = useImprovementGroups()
    const { create: createSuggestion, loading } = useSuggestion()

    const onCreate = async () => {
        try {
            const newSuggestion = await createSuggestion(suggestion)
            if (uploadAllPendingFiles) await uploadAllPendingFiles(newSuggestion.id?.toString())

            navigate(-1)
        } catch (error: any) {
            handleAxiosError(dispatch)(error)
        }
    }

    const improvementGroupsSupported = hasModule(state)(15) && !!improvementGroups?.length

    const isSuggestionValid = () =>
        (!improvementGroupsSupported || suggestion?.groupId) &&
        (suggestion.idea || suggestion.expectedResult || suggestion.suggestion)

    const fields: DynamicFieldsSpecification = {
        groupId: {
            required: true,
            type: DynamicFieldType.GenericDropdown,
            value: suggestion?.groupId,
            dropdownItems: improvementGroups?.map(d => ({ value: d.id, text: d.name })) ?? [],
            hidden: !improvementGroupsSupported,
        },
        idea: {
            required: false,
            type: DynamicFieldType.Text,
            value: suggestion?.idea ?? '',
        },
        expectedResult: {
            required: false,
            type: DynamicFieldType.Text,
            value: suggestion?.expectedResult ?? '',
        },
        suggestion: {
            required: false,
            type: DynamicFieldType.Text,
            value: suggestion?.suggestion ?? '',
        },
    }

    return (
        <>
            <Form>
                <DynamicFields
                    fields={fields}
                    onChange={(name, value) =>
                        setSuggestion(oldSuggestion => ({ ...oldSuggestion, [name]: value }))
                    }
                    translateFieldNames={true}
                    standaloneGrid={true}
                    columns={isInMobileMode ? 1 : 2}
                />
            </Form>

            <Files
                displayTitle
                mayAutoUpload={false}
                module="ImprovementIdeas"
                enableDragDrop={false}
                onFilesAdded={(files, uploadAllPendingFiles) => {
                    if (!files.length) return
                    setUploadAllPendingFiles(() => uploadAllPendingFiles)
                }}
            />

            <SaveCancelPrintButtonGroup
                location="bottom"
                onCancel={() => navigate(-1)}
                onSave={onCreate}
                disableSave={!isSuggestionValid() || loading}
            />
        </>
    )
}

export default AddImprovementSuggestion
