import * as z from 'zod';
import { IResponsilbeSafetyBatchRequest } from '../../../api/issues';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Button, Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../state';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon';
import { ArrowLeftCircle, Save } from 'react-feather';
import ControlCheckbox from '../../fields/ControlCheckbox';
import * as API from '../../../api'
import ControlDropdown from '../../fields/ControlDropdown';
import { useUserOptionsOverride } from '../../../hooks/users';

export interface IResponsibleSafetyBatchFormProps {
    onCancel: () => void
    refresh: () => void

}

export const responsilbeSafetyBatchRequestSchema = z.object({
    userIds: z.string().array().nonempty("required"),
    deviationResponsible: z.boolean(),
    accidentResponsible: z.boolean(),
    safetyOfficer: z.boolean(),
});

const ResponsibleSafetyBatchForm = ({ onCancel, refresh }: IResponsibleSafetyBatchFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
        state
    } = getState()
    const { control, handleSubmit } = useForm<IResponsilbeSafetyBatchRequest>({
        defaultValues: { userIds: [], deviationResponsible: false, accidentResponsible: false, safetyOfficer: false },
        resolver: zodResolver(responsilbeSafetyBatchRequestSchema),
    });
    const userOptions = useUserOptionsOverride(t, u => u.active)
    console.log(state.allUsers, "state.allUsers")
    console.log(state.users, "state.users")
    const batchAddUser = async (data: IResponsilbeSafetyBatchRequest) => await API.issues.batchAddUserSettings(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(batchAddUser, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form noValidate >
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown
                            name="userIds"
                            label={t('users')}
                            popupText={t('addMultiValue', { value: t('users').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            collection={userOptions} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="1">
                    <GridColumn>
                        <ControlCheckbox
                            name="deviationResponsible"
                            label={t('deviationResponsible')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlCheckbox
                            name="accidentResponsible"
                            label={t('accidentResponsible')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        <ControlCheckbox
                            name="safetyOfficer"
                            label={t('safetyOfficer')}
                            control={control}
                            disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow>
                    <GridColumn>
                        <Button
                            floated="right"
                            content={t('cancel')}
                            icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                            onClick={onCancel}
                            type="button"
                            disabled={isSaving}
                        />

                        <Button
                            primary
                            floated="right"
                            content={t('save')}
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Save}
                                />
                            }
                            onClick={handleSubmit(data => onSubmit(data))}
                            type={'button'}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default ResponsibleSafetyBatchForm;