import React, { useEffect } from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'
import { LineColor } from '../../../styles'
import { RowDragItemType } from '../../modules/docs/structureAdminPage/dragableDocumentRow'

const TabHandleInner = styled.span<{
    $isSelected: boolean
}>`
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    ${props =>
        props.$isSelected
            ? `
        font-weight: bold;
        border-top: 1px solid ${LineColor};
        border-left:1px solid ${LineColor};
        border-right:1px solid ${LineColor};
    `
            : ''}
`

export interface TabHandleProps {
    title: string
    isSelected: boolean
    onSelected: () => void
}

const TabHandle: React.FunctionComponent<TabHandleProps> = ({ title, isSelected, onSelected }) => {
    const [collected, ref] = useDrop({
        accept: RowDragItemType,
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
    })

    useEffect(() => {
        if (collected.isOver) onSelected()
    }, [collected?.isOver, onSelected])

    return (
        <div ref={ref}>
            <TabHandleInner $isSelected={isSelected} onClick={onSelected}>
                {title}
            </TabHandleInner>
        </div>
    )
}

export default TabHandle
