import React, { useMemo } from 'react'
import * as API from '../../../../../../api'
import { useGetQueryByParams } from '../../../../../../hooks/useGetQueryByParams'
import { getGraphColorsFactory } from '../../../../../../styles'
import Loading from '../../../../../common/Loading'
import { useTranslation } from 'react-i18next'
import { buildAccidentTitleKey, transformToStatisticsYearMonthRow } from '../../../../../../utils/Mappings/Issues'
import { Grid, GridRow, Header, Message } from 'semantic-ui-react'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { IAccidentStatisticEmploymentResponse } from '../../../../../../api/issues'
import { createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getGroupedRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import TableReact, { FooterLabel } from '../../../../../wrappers/TableReact'
import CustomBarLabel from '../../CustomBarLabel'
import { getState } from '../../../../../../state'
import { getLocationFromState } from '../../../../../../state/helpers'
import { LocationDisplay } from '../../../../../common/globalStateDisplay'
import { multiFilter, MultiDropdownFilter } from '../../../../../wrappers/TableReact/Filters/MultiDropdownFilter'

interface IEmploymentTypeStatisticProps {
    selectedYears: number[]
    selectedMonths: number[]
    selectedDepartments: number[]
    selectedLocations: number[]
}
const EmploymentTypeStatistic = ({ selectedYears,
    selectedMonths,
    selectedDepartments,
    selectedLocations, }: IEmploymentTypeStatisticProps) => {
    const barColors = getGraphColorsFactory()
    const { t } = useTranslation()
    const { state } = getState()
    const {
        isLoading: isLoadingEmployment,
        data: employmentFormStats,
    } = useGetQueryByParams(true, API.issues.postAccidentsEmploymentFormFilteredStats, "employmentFormStats", { selectedYears, selectedMonths, selectedDepartments, selectedLocations })
    const hasMonth = selectedMonths.length
    const hasDepartment = selectedDepartments.length
    const hasLocations = state.locations?.length && selectedLocations.length
    const columnHelper = createColumnHelper<IAccidentStatisticEmploymentResponse>()

    const employmentTypeColumns = useMemo(
        () => [
            columnHelper.accessor('year', {
                id: 'year',
                header: t('year') as any,
            }),
            columnHelper.accessor('employmentType', {
                id: 'employmentType',
                header: t('employmentType') as any,
                cell: (info) => <span>{t(`employmentType.${info.getValue()}`)}</span>
            }),
            ...(
                hasMonth
                    ? [columnHelper.accessor('month', {
                        id: 'month',
                        header: t('month') as any,

                    }),
                    ]
                    : []
            ),
            ...(
                hasDepartment
                    ? [columnHelper.accessor('department', {
                        id: 'department',
                        header: t('departmentId') as any,
                    }),
                    ]
                    : []
            ),
            ...(
                hasLocations
                    ? [columnHelper.accessor((r) => r.locationId ? getLocationFromState(state)(r.locationId)?.name : t('notSpecified'), {
                        id: "locationId",
                        header: `${t('locationId')}`,
                        filterFn: multiFilter,
                        meta: {
                            filterComponent: (setFilterValue: (updater: string) => void, value: string[]) => (
                                <MultiDropdownFilter
                                    setFilterValue={setFilterValue}
                                    value={value}
                                    options={state.locations?.map(d => ({ value: d.id, text: d.name })) ?? []}
                                />
                            ),
                        },
                        cell: ({ row }) => <LocationDisplay locationId={row.original.locationId} />
                    }),
                    ]
                    : []
            ),
            columnHelper.accessor('count', {
                id: 'count',
                header: t('count') as any,
                footer: (props) => {
                    const filtered = props.table.getFilteredRowModel().rows
                    if (filtered.length === 0) {
                        return null
                    }
                    const totalCount = filtered
                        .map((f) => f.original.count)
                        .reduce((a: number, c: number) => a + c, 0)
                    return (
                        <FooterLabel>{t('totalValue', { value: totalCount })}</FooterLabel>
                    )
                },
            }),
        ], [columnHelper, t, hasMonth, hasDepartment, hasLocations, state])

    const employmentTable = useReactTable({
        data: employmentFormStats || [],
        columnResizeMode: "onChange",
        columns: employmentTypeColumns,
        enableGlobalFilter: false,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoadingEmployment || !employmentFormStats) {
        return <Loading />
    }

    const mappedData = {
        barEmploymentFormData: transformToStatisticsYearMonthRow(
            employmentFormStats ? employmentFormStats : [],
            x => buildAccidentTitleKey(x, selectedYears, selectedMonths, selectedDepartments, selectedLocations, state, t),
            x => ({ [`${t(`employmentType.${x.employmentType}`)}`]: x.count }),
        ),
    };


    return (
        <>
            {(mappedData?.barEmploymentFormData?.rows?.length ?? 0) > 0 ? (
                <>
                    <GridRow >
                        <Grid.Column>
                            <Header as='h3' textAlign='center'>{t('accidentEmploymentType')}</Header>
                        </Grid.Column>
                    </GridRow>
                    <GridRow >
                        <Grid.Column>
                            <Message info>
                                {t('accidentEmploymentTypeStatsInfo')}
                            </Message>
                        </Grid.Column>
                    </GridRow>
                    <GridRow >

                        <Grid.Column >
                            <TableReact table={employmentTable}
                                canExport
                                overflowable />
                        </Grid.Column>
                    </GridRow>
                    <GridRow >

                        <Grid.Column>
                            <ResponsiveContainer height={500}
                            >
                                <BarChart data={mappedData?.barEmploymentFormData?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />
                                    <Legend layout="horizontal" />
                                    {mappedData?.barEmploymentFormData?.dataColumns?.map(x => (
                                        <Bar key={x} stackId="0" isAnimationActive={false} dataKey={x} fill={barColors()}>
                                            <LabelList dataKey={x} content={CustomBarLabel} />
                                        </Bar>
                                    ))}

                                </BarChart>
                            </ResponsiveContainer>
                        </Grid.Column>
                    </GridRow></>) : null}
        </>
    )
}

export default EmploymentTypeStatistic