import React, { useCallback, useMemo, useState } from 'react'
import { Button, Checkbox, Message, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ColumnFiltersState, ExpandedState, PaginationState, SortingState, VisibilityState, createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { IssueHierarchyType, IssueTypeHierarchy } from '../../../../../api/issues'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { ChevronDown, ChevronRight, Edit, Plus, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import IssueTypeForm from '../../../../forms/IssueTypeForm'
import { useDestroyIssueType } from './useDestroyIssueType'
interface IIssueTypeListModalState {
    newType: boolean
    editType: boolean
    newError: boolean
    editError: boolean
    newCause: boolean
    editCause: boolean
}

type ModalType = "newType" | "editType" | "newError" | "editError" | "newCause" | "editCause"
interface IIssueTypeListProps {
    types: IssueTypeHierarchy[]
    refresh: () => void
}
const IssueTypeList = ({ types, refresh }: IIssueTypeListProps) => {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("issueSettingExpandedState", {})
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('issueSettingVisibilityState', {});
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('issueSettingPaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('issueSettingFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('issueSettingSortingState', []);

    const [selectedHierarchyItem, setSelectedHierarchyItem] = useState<IssueTypeHierarchy | null>(null);
    const [selectedTableRowId, setSelectedTableRowId] = useState<string | null>(null);

    const [modalState, setModalState] = useState<IIssueTypeListModalState>({
        newType: false,
        editType: false,
        newError: false,
        editError: false,
        newCause: false,
        editCause: false,
    });
    const { isDestroying, onDestroy } = useDestroyIssueType(refresh)

    const handleShowNewModal = useCallback((data: IssueTypeHierarchy | null, tableRowId: string) => {
        if (tableRowId) setSelectedTableRowId(tableRowId)
        if (data === null) {
            setModalState({ ...modalState, "newType": true })
        } else {
            switch (data.issueHierarchyType) {
                case IssueHierarchyType.DeviationType:
                    setModalState({ ...modalState, "newError": true })
                    setSelectedHierarchyItem(data)
                    break;
                case IssueHierarchyType.ErrorCode:
                    setModalState({ ...modalState, "newCause": true })
                    setSelectedHierarchyItem(data)
                    break;
                default:
                    setModalState({ ...modalState })
                    setSelectedHierarchyItem(null)
                    break;
            }
        }
    }, [modalState])

    const handleShowEditModal = useCallback((data: IssueTypeHierarchy) => {
        switch (data.issueHierarchyType) {
            case IssueHierarchyType.DeviationType:
                setModalState({ ...modalState, "editType": true })
                setSelectedHierarchyItem(data)
                break;
            case IssueHierarchyType.ErrorCode:
                setModalState({ ...modalState, "editError": true })
                setSelectedHierarchyItem(data)
                break;
            case IssueHierarchyType.CauseCode:
                setModalState({ ...modalState, "editCause": true })
                setSelectedHierarchyItem(data)
                break;
            default:
                setModalState({ ...modalState })
                setSelectedHierarchyItem(null)
                break;
        }
        setSelectedHierarchyItem(data)
    }, [modalState])

    const handleCloseModal = useCallback((modal: ModalType) => {
        setModalState({ ...modalState, [modal]: false })
        setSelectedHierarchyItem(null)
        setSelectedTableRowId(null)
    }, [modalState])

    const columnHelper = createColumnHelper<IssueTypeHierarchy>()
    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.display({
                id: "expandable",
                size: 45,
                enableHiding: false,
                cell: ({ row }) =>
                    <>
                        {row.getCanExpand() && (
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={row.getIsExpanded() ? ChevronDown : ChevronRight} />}
                                type="button"
                                onClick={row.getToggleExpandedHandler()}
                            />
                        )}
                    </>
            }),
            columnHelper.accessor("name", {
                id: "name",
                header: t('name') as any,
                size: 250,
                cell: ({ row, getValue }) => (
                    <div style={{ paddingLeft: `${row.depth * 4}rem`, }}>
                        {row.getCanExpand() ? (
                            <>

                                {getValue()} ({row.subRows.length})
                            </>
                        ) : getValue()}

                    </div>
                ),
            }),
            columnHelper.accessor("description", {
                id: "description",
                size: 300,
                header: t('description') as any,
            }),
            columnHelper.accessor("issueHierarchyType", {
                id: "issueHierarchyType",
                header: t('issueHierarchyType') as any,
                enableSorting: false,
                enableColumnFilter: false,
                size: 100,
                cell: ({ getValue }) => <span>{t(`issueHierarchyType.${getValue()}`)}</span>,
            }),
            columnHelper.accessor("isUsed", {
                id: "isUsed",
                header: t('isUsedDeviation') as any,
                enableColumnFilter: false,
                meta: { checkbox: true },
                cell: ({ row }) => <Checkbox checked={!!row.original.isUsed} />
            }),
            columnHelper.display({
                id: "actions",
                size: 140,
                enableHiding: false,
                cell: ({ row }) => {
                    const disableDelete = isDestroying || row.original.isUsed || row.original.subRows?.length > 0
                    return (
                        <>
                            {row.original.issueHierarchyType !== IssueHierarchyType.CauseCode ? (
                                <Button
                                    title={t('addNew')}
                                    onClick={() => handleShowNewModal(row.original, row.id)}
                                    disabled={isDestroying}
                                    type="button"
                                    primary
                                    icon={<SemanticFeatherIcon FeatherIcon={Plus} />} />

                            )
                                : null}
                            <Button title={t('edit')} type='button' onClick={() => handleShowEditModal(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                            <Popup
                                content={disableDelete ? t('popupInfo.deleteDeviationType') : t('delete')}
                                trigger={
                                    <span>
                                        <Button
                                            disabled={disableDelete}
                                            onClick={() => onDestroy({
                                                confirmText: t('confirm.deleteValue', { value: row.original.name }),
                                                id: row.original.id, issueHierarchyType: row.original.issueHierarchyType
                                            })}
                                            type='button'
                                            color='red'
                                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />
                                    </span>
                                }
                            />
                        </>
                    )
                }
            }),
        ], [columnHelper, t, handleShowEditModal, handleShowNewModal, isDestroying, onDestroy])

    const table = useReactTable({
        data: types || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        state: {
            columnVisibility,
            expanded,
            pagination,
            columnFilters,
            sorting
        },
        enableGlobalFilter: false,
        enableGrouping: false,
        onExpandedChange: setExpanded,
        getSubRows: row => row.subRows,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('deviationSettings.typeMessageHeader')}
                </Message.Header>
                {t('deviationSettings.typeMessageBody')}
            </Message>

            <TableReact table={table}
                onAddCallback={() => handleShowNewModal(null, "")}
                canExport={false}
                overflowable
                hideGroupRowValue={false}
                noRightBorder />

            {selectedHierarchyItem === null && <ModalWrapper title={t('addValue', { value: t('issueType').toLowerCase() })} open={modalState.newType} onClose={() => handleCloseModal("newType")} size='small'>
                <IssueTypeForm
                    isNew
                    onCancel={() => handleCloseModal("newType")}
                    refresh={refresh}
                    issueHierarchyType={IssueHierarchyType.DeviationType}
                    selectedHierarchyItem={selectedHierarchyItem} />
            </ModalWrapper>}

            {selectedHierarchyItem && <ModalWrapper title={t('addValue', { value: t('errorCode').toLowerCase() })} open={modalState.newError} onClose={() => handleCloseModal("newError")} size='small'>
                <IssueTypeForm
                    isNew
                    onCancel={() => handleCloseModal("newError")}
                    refresh={refresh}
                    issueHierarchyType={IssueHierarchyType.ErrorCode}
                    selectedHierarchyItem={selectedHierarchyItem}
                    setExpanded={setExpanded}
                    selectedTableRowId={selectedTableRowId}
                    expanded={expanded} />
            </ModalWrapper>}

            {selectedHierarchyItem && <ModalWrapper title={t('addValue', { value: t('causeCode').toLowerCase() })} open={modalState.newCause} onClose={() => handleCloseModal("newCause")} size='small'>
                <IssueTypeForm
                    isNew
                    onCancel={() => handleCloseModal("newCause")}
                    refresh={refresh}
                    issueHierarchyType={IssueHierarchyType.CauseCode}
                    selectedHierarchyItem={selectedHierarchyItem}
                    setExpanded={setExpanded}
                    selectedTableRowId={selectedTableRowId}
                    expanded={expanded} />
            </ModalWrapper>}

            {selectedHierarchyItem && <ModalWrapper title={t('editValue', { value: selectedHierarchyItem?.name })} open={modalState.editType} onClose={() => handleCloseModal("editType")} size='small'>
                <IssueTypeForm
                    isNew={false}
                    onCancel={() => handleCloseModal("editType")}
                    refresh={refresh}
                    issueHierarchyType={IssueHierarchyType.DeviationType}
                    selectedHierarchyItem={selectedHierarchyItem} />
            </ModalWrapper>}
            {selectedHierarchyItem && <ModalWrapper title={t('editValue', { value: selectedHierarchyItem?.name })} open={modalState.editError} onClose={() => handleCloseModal("editError")} size='small'>
                <IssueTypeForm
                    isNew={false}
                    onCancel={() => handleCloseModal("editError")}
                    refresh={refresh}
                    issueHierarchyType={IssueHierarchyType.ErrorCode}
                    selectedHierarchyItem={selectedHierarchyItem} />
            </ModalWrapper>}
            {selectedHierarchyItem && <ModalWrapper title={t('editValue', { value: selectedHierarchyItem?.name })} open={modalState.editCause} onClose={() => handleCloseModal("editCause")} size='small'>
                <IssueTypeForm
                    isNew={false}
                    onCancel={() => handleCloseModal("editCause")}
                    refresh={refresh}
                    issueHierarchyType={IssueHierarchyType.CauseCode}
                    selectedHierarchyItem={selectedHierarchyItem} />
            </ModalWrapper>}
        </FullHeightStackingContainer>
    )
}

export default IssueTypeList
