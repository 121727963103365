import { IImprovementActivityRequest, IImprovementGroupRequest, IImprovementIdeaRequest } from "../../../api/improvements";
import { ProfileWithRoles } from "../../../auth/roles";

export const mockedImprovementIdeaRequest = (userProfile: ProfileWithRoles | undefined): IImprovementIdeaRequest => ({
    status: 5,
    groupId: null,
    idea: "",
    expectedResult: "",
    solutionProposal: "",
    reporterId: userProfile?.sub ?? '',
    created: new Date(),
    responsibleId: null,
    plannedFinishedAt: null,
    priority: null
});

export const mockedImprovementActivityRequest = (userProfile: ProfileWithRoles | undefined): IImprovementActivityRequest => ({
    status: 0,
    groupId: null,
    idea: "",
    expectedResult: "",
    solutionProposal: "",
    reporterId: userProfile?.sub ?? '',
    created: new Date(),
    responsibleId: "",
    plannedFinishedAt: null,
    donePercentage: 0,
    decisionStatus: 0,
    decisionResult: "",
    authorizedUser: null,
    time: 0,
    cost: 0,
    finishedAt: null,
    evaluationRequired: true,
    priority: null,
    evaluationResult: "",
    evaluatedBy: "",
    evaluatedAt: null
});

export const mockedImprovementGroupRequest: IImprovementGroupRequest = {
    name: "",
}