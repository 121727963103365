import React, { FunctionComponent, useEffect, useState } from 'react'
import { TFunction } from 'i18next'
import { Edit, Trash2, ArrowLeftCircle, Save } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Button, Input, TableCell } from 'semantic-ui-react'
import { ExternalDocument } from '../../../../../hooks/externalDocuments/document'
import InfoTableRow from '../../../../common/infoTableRow'
import { Link } from 'react-router-dom'

interface ExternalDocumentRowProps {
    t: TFunction
    externalDocument: ExternalDocument
    isAdmin: boolean
    onRemove(): void
    onUpdate(doc: ExternalDocument): void
}
const ExternalDocumentRow: FunctionComponent<ExternalDocumentRowProps> = ({
    t,
    externalDocument,
    isAdmin,
    onRemove,
    onUpdate,
}) => {
    const defaultData = externalDocument
    const [isDeleting, setIsDeleting] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [doc, setDoc] = useState(externalDocument)
    const { accountingDocument, archivingSite, archivingTime, isInheritedFromConcern } = doc
    useEffect(() => {
        if (!isEditing) {
            setDoc(defaultData)
        }
    }, [isEditing])
    return (
        <InfoTableRow $isInfo={isInheritedFromConcern}>
            {isEditing ? (
                <TableCell>
                    <Input
                        value={accountingDocument}
                        onChange={(e, data) => setDoc({ ...doc, accountingDocument: data.value })}
                    />
                </TableCell>
            ) : (
                <TableCell>
                    <Link to="../external">{accountingDocument}</Link>
                </TableCell>
            )}
            {isEditing ? (
                <TableCell>
                    <Input
                        value={archivingSite}
                        onChange={(e, data) => setDoc({ ...doc, archivingSite: data.value })}
                    />
                </TableCell>
            ) : (
                <TableCell>{archivingSite}</TableCell>
            )}
            {isEditing ? (
                <TableCell>
                    <Input
                        value={archivingTime}
                        onChange={(e, data) => setDoc({ ...doc, archivingTime: data.value })}
                    />
                </TableCell>
            ) : (
                <TableCell>{archivingTime}</TableCell>
            )}
            <TableCell textAlign="right">
                {isAdmin && !isInheritedFromConcern && (
                    <>
                        {isDeleting ? (
                            <>
                                <Button
                                    icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                    negative
                                    onClick={onRemove}
                                    type="button"
                                />
                                <Button
                                    icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                                    onClick={() => setIsDeleting(false)}
                                    type="button"
                                />
                            </>
                        ) : isEditing ? (
                            <>
                                <Button
                                    icon={<SemanticFeatherIcon FeatherIcon={Save} />}
                                    primary
                                    onClick={() => {
                                        setIsEditing(false)
                                        onUpdate({ ...doc })
                                    }}
                                    type="button"
                                />
                                <Button
                                    icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                                    onClick={() => setIsEditing(false)}
                                    type="button"
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                    onClick={() => setIsEditing(true)}
                                    type="button"
                                />
                                <Button
                                    icon={<SemanticFeatherIcon FeatherIcon={Trash2} />}
                                    onClick={() => setIsDeleting(true)}
                                    type="button"
                                />
                            </>
                        )}
                    </>
                )}
            </TableCell>
        </InfoTableRow>
    )
}
export default ExternalDocumentRow
