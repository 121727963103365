import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Area,
    AreaChart,
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { Goal } from '../../../hooks/goals'
import { Measurement, MeasurementData } from '../../../hooks/goals/measurement'

export interface TopDiagramProps {
    goal?: Goal
    measurement?: Measurement
}

interface AxisLabelProps {
    axisType: 'yAxis' | 'xAxis'
    x?: number
    y?: number
    width?: number
    height?: number
    stroke?: string
}

const TopDiagram: React.FunctionComponent<TopDiagramProps> = ({ goal, measurement }) => {
    const { t } = useTranslation()

    const renderNormal = () => {
        const maxDataValue = measurement?.measurements
            ? Math.max(...measurement.measurements.map(i => i.value))
            : 0

        const yAxisDomain =
            goal?.targetValue && goal.targetValue > maxDataValue
                ? [0, goal.targetValue + goal.targetValue * 0.1]
                : ([0, 'auto'] as [number, 'auto'])

        return (
            <LineChart data={measurement?.measurements ?? []} margin={{ right: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="displayKey"
                    minTickGap={-100}
                    dx={15}
                    dy={20}
                    angle={45}
                    height={60}
                />
                <Tooltip formatter={(value: string) => [value, t('measurement')]} />
                <YAxis
                    domain={yAxisDomain}
                    label={{ value: measurement?.unit, angle: -90, position: 'insideLeft' }}
                />
                {goal?.targetValue && (
                    <ReferenceLine
                        y={goal?.targetValue}
                        label={<Label dy={-10}>{t('targetValue').toString()}</Label>}
                        stroke="green"
                    />
                )}
                <Line dataKey="value" type="monotone" stroke="#3EA5C1" />
            </LineChart>
        )
    }

    const gradientOffset = (measurements: MeasurementData[]) => {
        const dataMax = Math.max(...measurements?.map(i => i.value))
        const dataMin = Math.min(...measurements?.map(i => i.value))

        if (dataMax <= 0) {
            return 0
        }
        if (dataMin >= 0) {
            return 1
        }

        return dataMax / (dataMax - dataMin)
    }

    const renderKpi = () => {
        if (!goal?.targetValue) return <></>

        const values = (measurement?.measurements ?? []).map(x => ({
            ...x,
            value: x.value - goal.targetValue!,
        }))
        const off = gradientOffset(values)

        return (
            <AreaChart data={values} margin={{ right: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="displayKey"
                    minTickGap={-100}
                    dx={15}
                    dy={20}
                    angle={45}
                    height={60}
                />
                <YAxis label={{ value: measurement?.unit, angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <defs>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                        <stop offset={off} stopColor="green" stopOpacity={1} />
                        <stop offset={off} stopColor="red" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <Area type="monotone" dataKey="value" stroke="#000" fill="url(#splitColor)" />
            </AreaChart>
        )
    }

    return (
        <ResponsiveContainer height={300}>
            {goal?.isKpi ? renderKpi() : renderNormal()}
        </ResponsiveContainer>
    )
}

export default TopDiagram
