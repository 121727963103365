import styled from 'styled-components';


const ClearFix = styled.div`
    clear:both;

    &:after {
        display: block;
        content: '';
        clear:both;
    }
`;

export default ClearFix;