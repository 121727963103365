import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Dimmer, Loader, Tab } from 'semantic-ui-react'
import {
    MeasurementType,
    PeriodType,
    useMeasurements,
    useStandardMeasurements,
} from '../../../hooks/goals/measurement'
import PeriodTab from './periodTab'

const MeasurementsPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const {
        loading,
        data,
        error,
        create,
        update,
        deleteMeasurement,
        addValue,
        updateValue,
        deleteValue,
    } = useMeasurements()

    const {
        loading: standardDataLoading,
        data: standardData,
        error: standardError,
        create: createStandard,
        addValue: addStandardValue,
        updateValue: updateStandardValue,
        deleteValue: deleteStandardValue,
    } = useStandardMeasurements()

    const tabFor = useCallback(
        (measurementType: MeasurementType, periodType: PeriodType) => (
            <PeriodTab
                measurements={
                    measurementType === MeasurementType.Custom
                        ? data?.filter(x => x.periodId === periodType)
                        : standardData?.filter(x => x.periodId === periodType)
                }
                periodType={periodType}
                measurementType={measurementType}
                error={error ?? standardError}
                create={measurementType === MeasurementType.Custom ? create : createStandard}
                addValue={measurementType === MeasurementType.Custom ? addValue : addStandardValue}
                update={update}
                updateValue={
                    measurementType === MeasurementType.Custom ? updateValue : updateStandardValue
                }
                deleteMeasurement={deleteMeasurement}
                deleteValue={
                    measurementType === MeasurementType.Custom ? deleteValue : deleteStandardValue
                }
            />
        ),
        [
            data,
            standardData,
            create,
            createStandard,
            error,
            standardError,
            update,
            deleteMeasurement,
            addValue,
            updateValue,
            deleteValue,
            addStandardValue,
            updateStandardValue,
            deleteStandardValue,
        ],
    )

    return (
        <Container>
            <Dimmer active={loading || standardDataLoading}>
                <Loader />
            </Dimmer>

            <Tab
                panes={[
                    {
                        menuItem: `${t('standard')} (${t('yearly')})`,
                        render: () => tabFor(MeasurementType.Standard, PeriodType.Yearly),
                    },
                    {
                        menuItem: `${t('standard')} (${t('monthly')})`,
                        render: () => tabFor(MeasurementType.Standard, PeriodType.Monthly),
                    },
                    {
                        menuItem: t('yearly'),
                        render: () => tabFor(MeasurementType.Custom, PeriodType.Yearly),
                    },
                    {
                        menuItem: t('monthly'),
                        render: () => tabFor(MeasurementType.Custom, PeriodType.Monthly),
                    },
                    {
                        menuItem: t('weekly'),
                        render: () => tabFor(MeasurementType.Custom, PeriodType.Weekly),
                    },
                ]}
            />
        </Container>
    )
}

export default MeasurementsPage
