import React, { useEffect, useState } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Form, Modal } from 'semantic-ui-react'
import { useMobileMode } from '../../hooks/mobileMode'
import { ObjectCase, useAdminObjectCase } from '../../hooks/objects/cases'
import ActionButton from '../common/actionButton'
import CollapsingSegment from '../common/collapsingSegment'
import DynamicFields, {
    DynamicFieldsSpecification,
    DynamicFieldType,
} from '../common/dynamicFields'
import ErrorBox from '../common/errorBox'
import Files from '../common/files'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'

export interface HandleCaseModalProps {
    currentCase?: ObjectCase
}

const getDefault = (caseId: number) =>
    ({
        id: caseId,
    } as ObjectCase)

const HandleCaseModal: React.FunctionComponent<HandleCaseModalProps> = ({ currentCase }) => {
    const { t } = useTranslation()
    const { isInMobileMode } = useMobileMode()

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [objectCase, setObjectCase] = useState<ObjectCase>(getDefault(0))
    const { error, update } = useAdminObjectCase(objectCase.id)

    useEffect(() => {
        setIsOpen(!!currentCase)

        if (currentCase)
            setObjectCase({ actualTime: 0, materialCost: 0, ...currentCase, closed: true })
    }, [currentCase])

    const fields: DynamicFieldsSpecification = {
        doneBy: {
            type: DynamicFieldType.UserDropdown,
            required: true,
            value: objectCase?.doneBy,
        },

        comment: {
            labelOverride: 'instruction',
            type: DynamicFieldType.Text,
            required: false,
            value: objectCase?.comment,
        },

        actualTime: {
            type: DynamicFieldType.Number,
            required: true,
            value: objectCase?.actualTime,
        },

        materialCost: {
            type: DynamicFieldType.Number,
            required: false,
            value: objectCase?.materialCost,
        },

        result: {
            type: DynamicFieldType.Text,
            required: false,
            value: objectCase?.result,
        },
    }

    const externalActorFields: DynamicFieldsSpecification = {
        externalActor: {
            type: DynamicFieldType.String,
            required: false,
            value: objectCase?.externalActor,
        },

        externalActorTime: {
            type: DynamicFieldType.Number,
            required: false,
            value: objectCase?.externalActorTime,
        },

        externalActorCostPerHour: {
            type: DynamicFieldType.Number,
            required: false,
            value: objectCase?.externalActorCostPerHour,
        },
    }

    const isValid = objectCase?.doneBy && objectCase?.actualTime !== undefined

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
            closeOnDimmerClick={false}
            size={isInMobileMode ? 'fullscreen' : 'large'}
        >
            <Modal.Header>{t('handleCase')}</Modal.Header>
            <Modal.Content>
                <Form>
                    <DynamicFields
                        fields={fields}
                        onChange={(name, value) =>
                            setObjectCase(oldObjectCase => ({ ...oldObjectCase, [name]: value }))
                        }
                        translateFieldNames={true}
                        standaloneGrid={true}
                        columns={isInMobileMode ? 1 : 2}
                    />

                    <CollapsingSegment heading={t('externalActor')}>
                        <DynamicFields
                            fields={externalActorFields}
                            onChange={(name, value) =>
                                setObjectCase(oldObjectCase => ({
                                    ...oldObjectCase,
                                    [name]: value,
                                }))
                            }
                            translateFieldNames={true}
                            standaloneGrid={true}
                            columns={isInMobileMode ? 1 : 2}
                        />
                    </CollapsingSegment>
                </Form>

                <Files
                    displayTitle={true}
                    module="objects-case"
                    mayAutoUpload={true}
                    collectionId={objectCase?.id?.toString()}
                />

                <ErrorBox error={error} />
            </Modal.Content>
            <Modal.Actions>
                <ActionButton
                    action={() => update(objectCase)}
                    postActionCallback={() => setIsOpen(false)}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                    disabled={!isValid}
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('perform')}
                </ActionButton>

                <Button onClick={() => setIsOpen(false)} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default HandleCaseModal
