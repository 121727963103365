import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    LabelList,
    Legend,
    Pie,
    PieChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { Grid, Header, Loader, SemanticWIDTHS } from 'semantic-ui-react'
import styled from 'styled-components'
import {
    useAccidentsPerYear,
    useIdeasPerMonth,
    useIdeasPerYear,
    useImprovementEffeciencyPerMonth,
    useImprovementsPerMonth,
    useImprovementsPerYear,
    useIssueCostPerMonth,
    useIssueEffeciencyPerMonth,
    useIssuesPerMonth,
    useIssuesPerYear,
    useStatisticsConfiguration,
} from '../../hooks/start/statistics'
import { getGraphColorsFactory, handleAccidentGraphColors } from '../../styles'
import CustomBarLabel from '../modules/issues/IssueStatisticPage/CustomBarLabel'

const ChartHeader = styled.p`
    font-size: 1.2em;
`

const PageHeader = styled(Header)`
    i.icon {
        font-size: 50px !important;
    }
`

const ChartContainer: React.FunctionComponent<{
    title: string
    width?: SemanticWIDTHS
    isLoading: boolean
}> = ({ title, width = '8', children, isLoading }) => (
    <Grid.Column width={width}>
        <ChartHeader>{title}</ChartHeader>
        {isLoading ? (
            <p>Loading...</p>
        ) : (
            <ResponsiveContainer height={350}>{children as any}</ResponsiveContainer>
        )}
    </Grid.Column>
)

const StatisticsView: React.FunctionComponent = () => {
    const { t } = useTranslation()

    const { data: statisticsConfiguration, isValidating: statisticsConfigurationLoading } =
        useStatisticsConfiguration()
    const { data: issuesPerMonth, isValidating: issuesPerMonthLoading } = useIssuesPerMonth(
        statisticsConfiguration?.avvMan,
    )
    const { data: issuesPerYear, isValidating: issuesPerYearLoading } = useIssuesPerYear(true)
    const { data: issueEffeciencyPerMonth, isValidating: issueEffeciencyPerMonthLoading } =
        useIssueEffeciencyPerMonth(statisticsConfiguration?.avvEffekt || statisticsConfiguration?.avvTid)
    const { data: accidentsPerYear, isValidating: accidentsPerYearLoading } =
        useAccidentsPerYear(true)
    const { data: issueCostPerMonth, isValidating: issueCostPerMonthLoading } =
        useIssueCostPerMonth(statisticsConfiguration?.avvKost)

    const { data: ideasPerYear, isValidating: ideasPerYearLoading } = useIdeasPerYear(
        statisticsConfiguration?.forbIdeAr,
    )
    const { data: ideasPerMonth, isValidating: ideasPerMonthLoading } = useIdeasPerMonth(
        statisticsConfiguration?.forbIdeMan,
    )
    const { data: improvementsPerYear, isValidating: improvementsPerYearLoading } =
        useImprovementsPerYear(statisticsConfiguration?.forbAktAr)
    const { data: improvementsPerMonth, isValidating: improvementsPerMonthLoading } =
        useImprovementsPerMonth(statisticsConfiguration?.forbAktMan)
    const {
        data: improvementEffeciencyPerMonth,
        isValidating: improvementEffeciencyPerMonthLoading,
    } = useImprovementEffeciencyPerMonth(statisticsConfiguration?.forbEffekt)

    const barColors = getGraphColorsFactory()
    return (
        <>
            <PageHeader icon="line graph" content={t('statistics')} />

            {statisticsConfigurationLoading ? (
                <Loader indeterminate active />
            ) : (
                <Grid columns={2} doubling stackable>
                    {statisticsConfiguration?.avvAr2 &&
                        (issuesPerYear?.pieData?.length ?? 0) > 0 ? (
                        <Grid.Column width="16">
                            <ChartHeader>{t('deviationsPerYear')}</ChartHeader>
                            <Grid stackable >
                                {issuesPerYear?.pieData?.map((yearGroup: any[]) => (
                                    <ChartContainer
                                        key={yearGroup[0].year}
                                        title={yearGroup[0].year}
                                        isLoading={false}
                                        width="16"
                                    >
                                        <PieChart>
                                            <Tooltip />
                                            <Pie
                                                data={yearGroup}
                                                nameKey="reason"
                                                dataKey="count"
                                                label={x => `${x.reason} (${x.count})`}
                                                isAnimationActive={false}
                                            >
                                                {yearGroup.map((_: any, index: number) => (
                                                    <Cell key={index} fill={barColors()} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ChartContainer>
                                ))}
                            </Grid>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.avvAr1 &&
                        (issuesPerYear?.barData?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('deviationsPerYear')}
                                isLoading={issuesPerYearLoading}
                            >
                                <BarChart data={issuesPerYear?.barData?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {issuesPerYear?.barData?.dataColumns?.map(x => (
                                        <Bar key={x} stackId="0" dataKey={x} fill={barColors()} />
                                    ))}

                                    <Legend layout="horizontal" />
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.avvMan && (issuesPerMonth?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('deviationsPerMonth')}
                                isLoading={issuesPerMonthLoading}
                            >
                                <BarChart data={issuesPerMonth?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {issuesPerMonth?.dataColumns.map(x => (
                                        <Bar key={x} stackId="0" dataKey={x} isAnimationActive={false} fill={barColors()} ><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}

                                    <Legend layout="horizontal" />
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}
                    {statisticsConfiguration?.avvTid &&
                        (issueEffeciencyPerMonth?.inTimePieData?.length ?? 0) > 0 ? (
                        <Grid.Column width="16">
                            <ChartHeader>{t('deviationsReportedInTime')}</ChartHeader>
                            <Grid stackable >
                                {issueEffeciencyPerMonth?.inTimePieData?.map((yearMonthGroup, index) => {
                                    const transformedData = yearMonthGroup.map((item: any) => {
                                        const total = item.count;
                                        const handledInTimePercentage = total > 0 ? Math.round((item.handledInTime / total) * 100) : 0;
                                        const handledToLatePercentage = total > 0 ? Math.round((item.handledToLate / total) * 100) : 0;

                                        return [
                                            { name: t('handledInTime'), value: handledInTimePercentage },
                                            { name: t('handledToLate'), value: handledToLatePercentage }
                                        ];
                                    });
                                    const key = `${issueEffeciencyPerMonth?.inTimePieData[index][0].year}`;
                                    return (
                                        <ChartContainer
                                            key={key}
                                            title={`${key}`}
                                            isLoading={false}
                                            width="16"
                                        >
                                            <PieChart>
                                                <Tooltip />
                                                <Pie
                                                    data={transformedData[0]}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    label={({ name, value }) => `${name} (${value}%)`}
                                                    isAnimationActive={false}
                                                >
                                                    {transformedData[0].map((_: any, index: number) => (
                                                        <Cell key={index} fill={barColors()} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ChartContainer>
                                    )
                                })}
                            </Grid>
                        </Grid.Column>
                    ) : null}
                    {statisticsConfiguration?.avvEffekt &&
                        (issueEffeciencyPerMonth?.effeciencyBarData.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('effiencyPerMonth')}
                                isLoading={issueEffeciencyPerMonthLoading}
                            >

                                <BarChart data={issueEffeciencyPerMonth?.effeciencyBarData.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />
                                    <Legend layout="horizontal" />
                                    <ReferenceLine y={0} stroke="#000" />
                                    {issueEffeciencyPerMonth?.effeciencyBarData.dataColumns.map(x => (
                                        <Bar key={x} stackId="0" dataKey={x} isAnimationActive={false} fill={barColors()}><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}

                                </BarChart>


                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.avvKost &&
                        (issueCostPerMonth?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('issueCostPerMonth')}
                                isLoading={issueCostPerMonthLoading}
                            >
                                <BarChart data={issueCostPerMonth?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />
                                    <Legend layout="horizontal" />
                                    <Bar dataKey="materialCost" name={t('materialCost')} isAnimationActive={false} stackId="a" fill="#3377A1">
                                        <LabelList dataKey="materialCost" content={CustomBarLabel} />
                                    </Bar>
                                    <Bar dataKey="laborCost" name={t('laborCost')} isAnimationActive={false} stackId="a" fill="#FF33D1">
                                        <LabelList dataKey="laborCost" content={CustomBarLabel} />
                                    </Bar>
                                </BarChart>

                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.olyAr1 &&
                        (accidentsPerYear?.barData?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('accidentsPerYear')}
                                isLoading={accidentsPerYearLoading}
                            >
                                <BarChart data={accidentsPerYear?.barData?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {accidentsPerYear?.barData?.dataColumns.map(x => (
                                        <Bar key={x} isAnimationActive={false} stackId="0" dataKey={x} fill={handleAccidentGraphColors(x, t)} ><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}

                                    <Legend layout="horizontal" />
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.forbIdeAr && (ideasPerYear?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('improvementIdeasPerYear')}
                                isLoading={ideasPerYearLoading}
                            >
                                <BarChart data={ideasPerYear?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {ideasPerYear?.dataColumns.map(x => (
                                        <Bar key={x} isAnimationActive={false} stackId="0" dataKey={x} fill={barColors()} ><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.forbIdeMan &&
                        (ideasPerMonth?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('improvementIdeasPerMonth')}
                                isLoading={ideasPerMonthLoading}
                            >
                                <BarChart data={ideasPerMonth?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {ideasPerMonth?.dataColumns.map(x => (
                                        <Bar key={x} isAnimationActive={false} stackId="0" dataKey={x} fill={barColors()} ><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.forbAktAr &&
                        (improvementsPerYear?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('improvementsPerYear')}
                                isLoading={improvementsPerYearLoading}
                            >
                                <BarChart data={improvementsPerYear?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {improvementsPerYear?.dataColumns.map(x => (
                                        <Bar key={x} isAnimationActive={false} stackId="0" dataKey={x} fill={barColors()} ><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.forbAktMan &&
                        (improvementsPerMonth?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('improvementsPerMonth')}
                                isLoading={improvementsPerMonthLoading}
                            >
                                <BarChart data={improvementsPerMonth?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />

                                    {improvementsPerMonth?.dataColumns.map(x => (
                                        <Bar key={x} stackId="0" isAnimationActive={false} dataKey={x} fill={barColors()} ><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}
                                </BarChart>
                            </ChartContainer>
                        </Grid.Column>
                    ) : null}

                    {statisticsConfiguration?.forbEffekt &&
                        (improvementEffeciencyPerMonth?.rows?.length ?? 0) > 0 ? (
                        <Grid.Column >
                            <ChartContainer
                                title={t('improvementEffecieny')}
                                isLoading={improvementEffeciencyPerMonthLoading}
                            >
                                <BarChart data={improvementEffeciencyPerMonth?.rows}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <CartesianGrid vertical={false} />
                                    <Legend layout="horizontal" />
                                    <ReferenceLine y={0} stroke="#000" />
                                    {improvementEffeciencyPerMonth?.dataColumns.map(x => (
                                        <Bar key={x} stackId="0" isAnimationActive={false} dataKey={x} fill={barColors()}><LabelList dataKey={x} content={CustomBarLabel} /></Bar>
                                    ))}

                                </BarChart>

                            </ChartContainer>
                        </Grid.Column>
                    ) : null}
                </Grid>
            )}
        </>
    )
}

export default StatisticsView
