import React from 'react'
import { isInRole } from '../../auth/roles'
import { getState } from '../../state'

export interface IfIsInRoleProps {
    role: string
}

const IfIsInRole: React.FunctionComponent<IfIsInRoleProps> = ({ role, children }) => {
    const {
        state: { userProfile },
    } = getState()

    return isInRole(userProfile, role) ? <>{children}</> : null
}

export default IfIsInRole
