import React from "react";
import { useTranslation } from "react-i18next";
import { Message } from "semantic-ui-react";

export const PendingCourseNotification: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Message info>
            <Message.Header>
                {t('courseReviewWaiting')}
            </Message.Header>
            <Message.Content>
                <a href="/kompetens/AnvKomUtb.aspx">{t('goCompetence')}</a>
            </Message.Content>
        </Message>
    );
}

export const ToolCalibrationNotification: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Message info>
            <Message.Header>
                {t('calibrationWaiting')}
            </Message.Header>
            <Message.Content>
                <a href="/objekt/matdon.aspx">{t('goCalibration')}</a>
            </Message.Content>
        </Message>
    );

}