
import { ProcessType } from "../../components/modules/docs/structureAdminPage/processArea"
import { get, post, destroy, put } from "../restClient";

export interface IViqmaDocument {
    id: string
    name: string
    version: number
    content: string
    approvedBy: string | null
    approvedByUser?: { name: string; active: boolean }
    lastChanged: string
    underReferral: boolean
    isConcernDocument: boolean
    isPublished: boolean
    parentId: number
    sortIndex?: number
}

export interface IViqmaUpdateDocument {
    content: string
    name: string
    type: ProcessType
    parentId: number
    sortIndex?: number
    isPublished: boolean
    approver: string | null
    resetVersion?: boolean
    contentReadOnly: boolean
}

export const getIdeaById = (id: number) => get<IViqmaDocument>(`${window.env.REACT_APP_DOC_SERVICE}/api/document/${id}`)
