import { useEffect, useState } from 'react'
import useSWR from 'swr'
import useApi from '../useApi'
import { ProcessType } from '../../components/modules/docs/structureAdminPage/processArea'

export type SimplifiedDocumentItem = {
    rubrik: string
    mammaId?: number
    id: number
    typ: number
    version: number
    lastChanged: string
    forId: number
    isPublished: boolean
    sortOrder?: number | null
    approvedBy: string | null
}

export type ProcessHierarchyItem = {
    item: SimplifiedDocumentItem | null
    isInherited: boolean
    children: ProcessHierarchyItem[]
}

export type ProcessDefinition = {
    governingProcess: ProcessHierarchyItem[]
    mainProcesses: ProcessHierarchyItem[]
    supportProcess: ProcessHierarchyItem[]
}
export interface Breadcrumbs {
    [key: string]: ProcessHierarchyItem
}
export interface BreadcrumbsData {
    hierarchy: SimplifiedDocumentItem[]
    children: SimplifiedDocumentItem[]
}

export const useProcesses = () =>
    useSWR<ProcessDefinition, any>(`${window.env.REACT_APP_DOC_SERVICE}/api/process`)

export const useBreadcrumbs = (documentId: number) => {
    const { data } = useProcesses()
    const [crumbsData, setCrumbsData] = useState<BreadcrumbsData>()
    const [breadcrumbs, setBreadCrumbs] = useState<Breadcrumbs>()
    useEffect(() => {
        if (data) {
            const breadcrumbDictionary: Breadcrumbs = {}
            const a = [...data.governingProcess, ...data.mainProcesses, ...data.supportProcess]
            const loopChildren = (b: ProcessHierarchyItem) => {
                if (b.item) breadcrumbDictionary[b.item.id] = b
                if (b.children) b.children.forEach(ch => loopChildren(ch))
            }
            a.forEach(ch => loopChildren(ch))
            setBreadCrumbs(breadcrumbDictionary)
        }
    }, [data])
    const fillArrayWithHierarchyStartingFromCurrentDocument = (
        start: ProcessHierarchyItem,
        arr: SimplifiedDocumentItem[],
        breadcrumbs: Breadcrumbs,
    ) => {
        if (start?.item) {
            arr.push(start.item)
            if (breadcrumbs && start.item.mammaId)
                fillArrayWithHierarchyStartingFromCurrentDocument(
                    breadcrumbs[start.item.mammaId],
                    arr,
                    breadcrumbs,
                )
        }
    }
    useEffect(() => {
        if (breadcrumbs) {
            const start = breadcrumbs[documentId]
            const hierarchy: SimplifiedDocumentItem[] = []
            fillArrayWithHierarchyStartingFromCurrentDocument(start, hierarchy, breadcrumbs)
            setCrumbsData({
                children: start?.children?.filter(ch => ch.item)?.map(ch => ch.item!) ?? [],
                hierarchy,
            })
        }
    }, [breadcrumbs, documentId])

    return crumbsData
}

interface MoveDocumentDto {
    parentId: number
    type: number
}

export const useDocumentStructureAdmin = () => {
    const fetchUrl = `${window.env.REACT_APP_DOC_SERVICE}/api/process?includeConcern=false`
    const [url, setUrl] = useState<string | undefined>(fetchUrl)
    const { data, post, loading, doDelete } = useApi<ProcessDefinition>(url, {
        clearSwrCacheOnBackendMutate: true,
        dispatchErrors: true,
    })

    const forceRefresh = () => {
        setUrl(undefined)
        setUrl(fetchUrl)
    }

    return {
        data,
        loading,
        forceRefresh,
        move: (parentId: number, type: ProcessType, moveId: number) =>
            post<ProcessDefinition, MoveDocumentDto>(
                {
                    parentId,
                    type,
                },
                `${window.env.REACT_APP_DOC_SERVICE}/api/process/move/${moveId}`,
                true,
            ),
        deleteDocument: (documentId: number) =>
            doDelete(`${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}`).then(
                forceRefresh,
            ),
    }
}
