import React from "react"
import { Checkbox, Header } from "semantic-ui-react"
import styled from "styled-components"

export interface IPrintCheckboxProps {
    label: string
    value: boolean
    orientation?: 'horizontal' | 'vertical'
}

const GroupContainer = styled.div<{ orientation: string }>`
    display: flex;
    ${props =>
        props.orientation === 'horizontal'
            ? `
        flex-direction: row;

        .checkbox {
            margin-top: -3px;
            margin-left: 5px;
        }
    `
            : `
        flex-direction: column;
    `}
`

const PrintCheckbox = ({
    label,
    value,
    orientation = "horizontal"
}: IPrintCheckboxProps) => {
    return (
        <GroupContainer orientation={orientation}>
            <Header size="small" content={`${label}:`} />
            <Checkbox checked={value} />
        </GroupContainer>

    )
}
export default PrintCheckbox
