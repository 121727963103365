import React from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'
import { DropRowType } from '.'
import { RowIndicatedBackgroundColor } from '../../../styles'
import { GoalDragType } from './dragableGoalRow'

export interface GoalGroupProps {
    groupId: number
    onMovedTo: (idMoved: number) => void
}

const GoalGroupContainer = styled.div<{ $isOver: boolean }>`
    background-color: ${props => (props.$isOver ? RowIndicatedBackgroundColor : 'transparent')};
`

const GoalGroup: React.FunctionComponent<GoalGroupProps> = ({ groupId, onMovedTo, children }) => {
    const [{ isOver }, drop] = useDrop<GoalDragType, unknown, { isOver: boolean }>(() => ({
        accept: DropRowType,
        collect: monitor => ({
            isOver: monitor.isOver() && monitor.canDrop(),
        }),
        canDrop: item => item.parentId !== groupId,
        drop: item => onMovedTo(item.id),
    }))

    return (
        <GoalGroupContainer ref={drop} $isOver={isOver}>
            {children}
        </GoalGroupContainer>
    )
}

export default GoalGroup
