import React from 'react';
import { Segment, Grid, Header, List } from 'semantic-ui-react';
import styled from 'styled-components';

const MenuSegment = styled(Segment)`
    margin-bottom: 20px !important;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;

    a {
        color: black;

        &:hover, &.active{
            text-decoration: underline;
        }
    }
`;

const SubMenuContainer = styled.div`
    flex-basis: auto;
    margin-right: 50px;

    @media(min-width: 1200px), (max-width: 600px){
        flex-basis: 100%;
        margin-bottom: 20px;
        margin-right: 0;
    }
`; 

const MenuListItem = styled(List.Item)`
    padding-left: 5px !important;
`;

export interface ModuleMenuProps {
    all?: JSX.Element[];
    admin?: JSX.Element[];
    systemAdmin?: JSX.Element[];
}

const ModuleMenu: React.FunctionComponent<ModuleMenuProps> = ({
    all,
    admin,
    systemAdmin,
}) => {
    const renderMenu = (title: string, elements?: JSX.Element[]) => 
        elements && <>
                <Header size="medium">{title}</Header>
                <List>
                    {elements.map((x,i) => 
                        <MenuListItem key={i}>
                            {x}
                        </MenuListItem>
                    )}
                </List>
        </>

    return(
        <MenuSegment secondary>
            {all && 
                <SubMenuContainer>
                    {renderMenu('Alla', all)}
                </SubMenuContainer>
            }

            {admin && 
                <SubMenuContainer>
                    {renderMenu('Admin', admin)}
                </SubMenuContainer>
            }

            {systemAdmin && 
                <SubMenuContainer>
                    {renderMenu('Systemadmin', systemAdmin)}
                </SubMenuContainer>
            }
        </MenuSegment>
    );
};

export default ModuleMenu;