import React, { useState, useEffect } from 'react'
import { Button, Header, Form, TextArea, Transition, TextAreaProps } from 'semantic-ui-react'
import styled from 'styled-components'
import {
    AnswerSubmissionDto,
    QuestionType,
    Survey,
    SurveyQuestion,
} from '../../../hooks/surveys/survey'
import { BackgroundColor } from '../../../styles'
import ActionButton from '../../common/actionButton'
import CustomRating from '../../common/customRating'
import FlexBox from '../../common/flexBox'
import Spacer from '../../common/spacer'
import FreeTextQuestion from './questionTypes/freeTextQuestion'
import MultipleChoiceQuestion from './questionTypes/multipleChoiceQuestion'
import { QuestionTypeProps } from './questionTypes/questionTypeProps'
import RatingQuestion from './questionTypes/ratingQuestion'
import YesNoDontKnowQuestion from './questionTypes/yesNoDontKnowQuestion'
import SurveyBase from './surveyBase'

const StatusFooter = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: ${BackgroundColor};
    color: white;
    font-size: 1rem;
    padding: 5px 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-items: center;
    
    button:first-child {
        grid-column: 1;
        justify-self: start;
    }

    span {
        grid-column: 2;
        text-align: center;
    }

    

    button:last-child {
        grid-column: 3;
        justify-self: end;
    }
`

/* &&&& for higher specificity than semantic ui styles */
const QuestionHeader = styled(Header)`
    white-space: pre-wrap;

    &&&& .sub.header{
        margin-top: 2rem;
    }
`

const questionTypeToComponent: Record<QuestionType, React.FC<QuestionTypeProps>> = {
    [QuestionType.Grading]: RatingQuestion,
    [QuestionType.YesNoDontKnow]: YesNoDontKnowQuestion,
    [QuestionType.FreeText]: FreeTextQuestion,
    [QuestionType.MultipleChoice]: MultipleChoiceQuestion,
}

export interface SurveyQuestionProps {
    currentQuestion: number
    survey: Survey
    question: SurveyQuestion
    existingAnswer?: AnswerSubmissionDto
    onPrev?(answer: AnswerSubmissionDto): void
    onNext?(answer: AnswerSubmissionDto): void
    onFinish?(answer: AnswerSubmissionDto): Promise<unknown>
}

export const SurveyQuestionContainer: React.FunctionComponent<SurveyQuestionProps> = ({
    question,
    currentQuestion,
    survey,
    existingAnswer,
    onNext,
    onPrev,
    onFinish,
}) => {
    const { imageUrl, commentText, weightingText } = survey
    const [answerSubmission, setAnswerSubmission] = useState<AnswerSubmissionDto>(
        existingAnswer ?? { answer: -1, comment: '', questionId: question.id },
    )

    useEffect(
        () =>
            setAnswerSubmission(
                existingAnswer ?? { answer: -1, comment: '', questionId: question.id },
            ),
        [existingAnswer, question],
    )

    const isValid =
        answerSubmission?.answer >= 0 &&
        (!question.addWeightingSelection || answerSubmission.weight)

    return (
        <SurveyBase imageUrl={imageUrl}>
            <Form error={true}>
                <QuestionHeader size="small" subheader={question?.comment} content={question.text} />

                {questionTypeToComponent[question.type]({
                    question,
                    survey,
                    currentAnswer: answerSubmission.answer,
                    currentComment: answerSubmission.comment,
                    onAnswer: (value, comment?) => setAnswerSubmission({ ...answerSubmission, answer: value, comment: comment ?? answerSubmission.comment }),
                })}

                <div>
                    {question.addCommentBox && question.type !== QuestionType.FreeText && (
                        <>
                            <Spacer spacing="20px" />
                            <Form.Field
                                label={commentText}
                                control={TextArea}
                                value={answerSubmission.comment ?? ''}
                                onChange={(_: Event, d: TextAreaProps) =>
                                    setAnswerSubmission({
                                        ...answerSubmission,
                                        comment: d.value?.toString() ?? '',
                                    })
                                }
                            />
                        </>
                    )}

                    {question.addWeightingSelection && (
                        <Transition
                            visible={
                                answerSubmission?.answer && answerSubmission?.answer >= 0 ? true : false
                            }
                            animation="fade"
                            duration={{ show: 500, hide: 0 }}>
                            <>
                                <Spacer spacing="20px" />
                                <Form.Field>
                                    <FlexBox alignItems="center" direction="column" gap="0.5em">
                                        <label>
                                            {weightingText ??
                                                'How important is this question for you?'}
                                        </label>
                                        <CustomRating
                                            rating={answerSubmission.weight}
                                            onChange={weight =>
                                                setAnswerSubmission({ ...answerSubmission, weight })
                                            }
                                        />
                                    </FlexBox>
                                </Form.Field>
                            </>
                        </Transition>
                    )}

                </div>
            </Form>
            <StatusFooter>
                {onPrev && (
                    <Button
                        content="Previous"
                        icon="backward"
                        labelPosition='left'
                        onClick={() => onPrev(answerSubmission)}
                    />
                )}
                <span>
                    Question {currentQuestion + 1} of {survey.questions.length}
                </span>

                {onNext && (
                    <Button
                        content="Next"
                        icon="forward"
                        labelPosition='right'
                        onClick={() => onNext(answerSubmission)}
                        disabled={!isValid}
                    />
                )}
                {onFinish && (
                    <ActionButton
                        action={() => onFinish(answerSubmission)}
                        content="Finish"
                        icon="flag checkered"
                        disabled={!isValid}
                    />
                )}
            </StatusFooter>
        </SurveyBase>
    )
}
