import { handleAxiosError } from '../../actions/error'
import { getAuthedAxios } from '../../auth'
import { getState } from '../../state'

export const useDocumentImport = () => {
    const { dispatch } = getState()

    return async (documentIds: number[]) => {
        const axios = await getAuthedAxios()

        return axios
            .post(`${window.env.REACT_APP_DOC_SERVICE}/api/document/template/copy`, {
                documentIds,
            })
            .catch(handleAxiosError(dispatch))
    }
}
