import React, { useState } from 'react'
import { Edit, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Table } from 'semantic-ui-react'
import styled from 'styled-components'
import { formatDate, isInThePast } from '../../../actions/util'
import { AdminRoles } from '../../../auth/roles'
import { GoalActivity, useGoalActivities } from '../../../hooks/goals/activity'
import { useIsInRole } from '../../../hooks/role'
import DateDisplay from '../../common/dateDisplay'
import FlexBox from '../../common/flexBox'
import ReminderButton from '../../common/reminderButton'
import TextDisplay from '../../common/textDisplay'
import UserNameDisplay from '../../common/UserNameDisplay'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import ActivitiesModal from './actvitiesModal'

const UnborderedCell = styled(Table.Cell)`
    border-top: none !important;
`

const ColoredRow = styled(Table.Row)`
    background-color: ${props => (props.$isEven ? '#FAFAFB' : undefined)};
`

const resolveStatus = (status?: number) => {
    switch (status) {
        case 1:
            return 'active'
        case 3:
            return 'closed'
        default:
            return ''
    }
}

export interface ActivitiesTableProps {
    goalId: string
    isClosed: boolean
}

const ActivitiesTable: React.FunctionComponent<ActivitiesTableProps> = ({ goalId, isClosed }) => {
    const { t } = useTranslation()
    const { save, data } = useGoalActivities(goalId)
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.GoalsAdminRole)

    const [selectedActivity, setSelectedActivity] = useState<GoalActivity>()

    return (
        <Table basic="very">
            <ActivitiesModal onSave={save} selectedActivity={selectedActivity} />
            <Table.Header>
                {isAdmin && (
                    <Table.Row>
                        <Table.HeaderCell colSpan="7" textAlign="right">

                            <Button
                                icon
                                labelPosition="left"
                                primary
                                type="button"
                                onClick={() => setSelectedActivity({})}
                            >
                                <SemanticFeatherIcon
                                    FeatherIcon={Plus}
                                    size={'60%'}
                                    centerIcon={true}
                                />{' '}
                                {t('new')}
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                )}
                <Table.Row>
                    <Table.HeaderCell>{t('status')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('activity')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('responsible')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('cost')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('time')}</Table.HeaderCell>
                    <Table.HeaderCell collapsing>{t('doneBefore')}</Table.HeaderCell>
                    <Table.HeaderCell collapsing />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data?.map((x, i) => (
                    <React.Fragment key={x.id}>
                        <ColoredRow
                            negative={!isClosed && x.status !== 3 && isInThePast(x.doneBefore)}
                            positive={x.status === 3}
                            $isEven={i % 2 === 0}
                        >
                            <Table.Cell collapsing verticalAlign="top">
                                {t(resolveStatus(x.status))}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                                <TextDisplay text={x.activity} />
                            </Table.Cell>
                            <Table.Cell verticalAlign="top" collapsing>
                                <UserNameDisplay userId={x.responsibleId} />
                            </Table.Cell>
                            <Table.Cell verticalAlign="top" collapsing>
                                {x.cost}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top" collapsing>
                                {x.time}
                            </Table.Cell>
                            <Table.Cell verticalAlign="top">
                                <DateDisplay date={x.doneBefore} checkForPassed={false} />
                            </Table.Cell>
                            <Table.Cell verticalAlign="top" collapsing>
                                <FlexBox justifyContent="end">
                                    <Button
                                        icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                        onClick={() => setSelectedActivity({ ...x })}
                                        type="button"
                                    />
                                    <ReminderButton
                                        template="GoalActivityReminder"
                                        trackingSetId={x.id}
                                        userId={x.responsibleId}
                                        params={{
                                            activity: x.activity,
                                            doneBefore: formatDate(x.doneBefore),
                                        }}
                                    />
                                </FlexBox>
                            </Table.Cell>
                        </ColoredRow>
                        {x.description && x.status === 1 && (
                            <ColoredRow
                                negative={!isClosed && isInThePast(x.doneBefore)}
                                $isEven={i % 2 === 0}
                            >
                                <UnborderedCell colSpan="7">
                                    <TextDisplay
                                        text={x.description}
                                        style={{ marginLeft: '0.5rem', marginTop: '-1rem' }}
                                    />
                                </UnborderedCell>
                            </ColoredRow>
                        )}
                    </React.Fragment>
                ))}
            </Table.Body>
        </Table>
    )
}

export default ActivitiesTable
