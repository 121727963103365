import React, { useState } from 'react'
import { Edit, Plus } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Table } from 'semantic-ui-react'
import { formatDate } from '../../../actions/util'
import { AdminRoles } from '../../../auth/roles'
import { Decision, useDecisions } from '../../../hooks/goals/decisions'
import { useIsInRole } from '../../../hooks/role'
import FlexBox from '../../common/flexBox'
import TextDisplay from '../../common/textDisplay'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'
import DecisionsModal from './decisionsModal'

export interface DecisionsTableProps {
    goalId: string
}

const DecisionsTable: React.FunctionComponent<DecisionsTableProps> = ({ goalId }) => {
    const { t } = useTranslation()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.GoalsAdminRole)
    const { data, save } = useDecisions(goalId)
    const [selectedDecision, setSelectedDecision] = useState<Decision>()

    return (
        <Table basic="very">
            <Table.Header>
                {isAdmin && (
                    <Table.Row>
                        <Table.HeaderCell colSpan="4" textAlign="right">
                            <DecisionsModal onSave={save} selectedDecision={selectedDecision} />

                            <Button
                                icon
                                labelPosition="left"
                                primary
                                type="button"
                                onClick={() => setSelectedDecision({})}
                            >
                                <SemanticFeatherIcon
                                    FeatherIcon={Plus}
                                    size={'60%'}
                                    centerIcon={true}
                                />{' '}
                                {t('new')}
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                )}
                <Table.Row>
                    <Table.HeaderCell>{t('decision')}</Table.HeaderCell>
                    <Table.HeaderCell collapsing>{t('occasion')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data?.map(x => (
                    <Table.Row key={x.id}>
                        <Table.Cell verticalAlign="top">
                            <TextDisplay text={x.decision} />
                        </Table.Cell>
                        <Table.Cell collapsing verticalAlign="top">
                            {x.opportunity}
                        </Table.Cell>
                        <Table.Cell collapsing verticalAlign="top">
                            {formatDate(x.date)}
                        </Table.Cell>
                        <Table.Cell collapsing verticalAlign="top">
                            {isAdmin && (
                                <FlexBox justifyContent="end">
                                    <Button
                                        icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                        onClick={() => setSelectedDecision({ ...x })}
                                        type="button"
                                    />
                                </FlexBox>
                            )}
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}

export default DecisionsTable
