import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import { Filter, Plus, XCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { ColumnDragObject } from './dragableHeaderRenderer'
import GridSettings from './settings'
import { Button } from 'semantic-ui-react'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'

const ToolbarContainer = styled.div`
    width: 100%;
    min-height: 100px;
`

const ToolbarControlsContainer = styled.div`
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;

    * {
        margin-right: 2px;
    }
`

const DropTargetOverlay = styled.div`
    padding: 10px;
    width: 100%;
    height: 40px;
    background-color: #dddddd;
    color: white;
    margin-top:1em;
`

interface GridToolbarProps {
    displayAddButton?: boolean,
    displayFilters?: boolean,
    displayColumnHiding?: boolean
    trackedColumns: any[]
    onAddCallback?: () => void,
    onFilteringToggled: () => void,
    onGroupByChanged: (groupBy: ColumnDragObject[]) => void,
    onColumnHiddenChanged: (key: string, hidden: boolean) => void
    controls?: JSX.Element[]
}

const GridToolbar: React.FunctionComponent<GridToolbarProps> = ({
    displayAddButton,
    displayFilters,
    displayColumnHiding,
    trackedColumns,
    onAddCallback,
    onFilteringToggled,
    onGroupByChanged,
    onColumnHiddenChanged,
    controls,
}) => {
    const { t } = useTranslation()
    const [groupBy, setGroupBy] = useState<ColumnDragObject[]>([])

    useEffect(() => onGroupByChanged(groupBy), [groupBy])

    const [{ canDrop }, drop] = useDrop({
        accept: 'COLUMN_DRAG',
        drop(dragType: ColumnDragObject) {
            setGroupBy(groupBy => [...groupBy, dragType])
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })

    const removeGroupBy = (key: string) => {
        setGroupBy(groupBy => groupBy.filter(x => x.key !== key))
    }

    return (
        <ToolbarContainer ref={drop}>


            {displayAddButton && (
                <Button
                    onClick={() => onAddCallback?.()}
                    type="button"
                    primary
                    icon
                    labelPosition="left"
                >
                    <SemanticFeatherIcon FeatherIcon={Plus} size={'60%'} centerIcon={true} />{' '}
                    {t('addNew')}
                </Button>
            )}

            <ToolbarControlsContainer>
                {controls ? controls.map((x, i) => React.cloneElement(x, { key: i })) : null}

                {groupBy.map(x => (
                    <Button
                        icon
                        size="tiny"
                        onClick={() => removeGroupBy(x.key)}
                        key={`groupby-${x.key}`}
                    >
                        {x.name}
                        <SemanticFeatherIcon FeatherIcon={XCircle} style={{ marginTop: '-2px' }} />
                    </Button>

                ))}
            </ToolbarControlsContainer>

            {displayFilters && (
                <Button
                    onClick={onFilteringToggled}
                    floated="right"
                    icon={<SemanticFeatherIcon FeatherIcon={Filter} />}
                    content={t('filter')}
                    type="button"
                />
            )}
            {displayColumnHiding && (
                <GridSettings
                    columns={trackedColumns}
                    onColumnHiddenChanged={onColumnHiddenChanged}
                />
            )}
            <DropTargetOverlay>{t('dropToGroupBy')}</DropTargetOverlay>
        </ToolbarContainer>
    )
}

export default GridToolbar
