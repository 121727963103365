import styled from 'styled-components';

type SpacingDirection = 'horizontal' | 'vertical'

export interface SpacerProps {
    spacing?: string,
    direction?: SpacingDirection
}

const Spacer = styled.div<SpacerProps>`
    padding:0;
    margin:0;
    height: ${props => !props.direction || props.direction === 'horizontal' ? (props.spacing ?? '10px') : '0'};
    width: ${props => props.direction === 'vertical' ? (props.spacing ?? '10px') : '0'};
`;

export default Spacer;