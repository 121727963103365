import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IIssueDto } from '../../../../api/issues';
import { FileListing } from '../../../common/files';
import * as API from '../../../../api'

const styles = StyleSheet.create({
    page: {
        fontSize: 12,
        padding: 30,
    },
    flexRowSection: {
        marginBottom: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    flexColumnSection: {
        display: "flex",
        flexDirection: "column",

    },
    section: {
        marginBottom: 10,
    },
    header: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    table: {
        display: "flex",
        width: 'auto',
        margin: '10px 0',
    },
    text: {
        marginBottom: 5,
    },
    tableRow: {
        flexDirection: 'row',
    },
    fileTableCol: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableCol: {
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
    },
    copy: {
        border: '1px',
        padding: 10,
        fontSize: 10,
        borderColor: "#E9E9E9"
    },
    image: {
        maxWidth: 150,
        maxHeight: 100,
    }
});
export interface IssueReportPdfProps {
    issue: IIssueDto
    logoPath: string
    responsible: string
    reportedBy: string
    departmentId: string
    locationId: string
    teamMembers: string
    evaluatedBy: string
    desiredEffect: string
    supplierId: string
    activities?: any[]
    files?: FileListing[]
    types?: API.issues.IssueType[]
    errorCodes?: API.issues.IssueType[]
    causeCodes?: API.issues.IssueType[]
    mappedExtraFields: {
        label: string;
        value: string;
    }[]
}
const IssueReportPdf = ({ issue, logoPath, responsible, reportedBy, activities, files, departmentId, locationId, supplierId, types, errorCodes, causeCodes, mappedExtraFields, teamMembers, desiredEffect, evaluatedBy }: IssueReportPdfProps) => {
    const { t } = useTranslation();
    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.flexRowSection}>
                    <Image style={styles.image} src={logoPath} />
                    <View style={styles.flexColumnSection}>
                        <Text style={styles.header}>{t('reportDate')}: {moment(issue.reportDate).format('Y-MM-DD')}</Text>
                        <Text style={styles.header}>{t('reportedBy')}: {reportedBy}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <Text style={styles.text}>{t('issue')}: {issue.topic}</Text>
                    <Text style={styles.text}>Id: {issue.ownId?.toString() ?? ''}</Text>
                    <Text style={styles.text}>{t('responsible')}: {responsible}</Text>
                    <Text style={styles.text}>{t('issueType')}: {types?.find(x => x.id === issue.type)?.name ?? ''}</Text>
                    {errorCodes && errorCodes.length ? <Text style={styles.text}>{t('errorCode')}: {errorCodes?.find(x => x.id === issue.errorCode)?.name ?? ''}</Text> : null}
                    {causeCodes && causeCodes.length ? <Text style={styles.text}>{t('causeCode')}: {causeCodes?.find(x => x.id === issue.causeCode)?.name ?? ''}</Text> : null}
                    <Text style={styles.text}>{t('departmentId')}: {departmentId}</Text>
                    <Text style={styles.text}>{t('locationId')}: {locationId}</Text>
                    <Text style={styles.text}>{t('teamMembers')}: {teamMembers}</Text>
                    <Text style={styles.text}>{t('supplierId')}: {supplierId}</Text>
                    <Text style={styles.text}>{t('customer')}: {issue.customerSupplier ?? ''}</Text>
                    <Text style={styles.text}>{t('doneBefore')}: {issue.doneBefore ? moment(issue.doneBefore).format('Y-MM-DD') : ''}</Text>
                    {mappedExtraFields.map(x => {
                        return (
                            <Text key={x.label} style={styles.text}>{x.label}: {x.value}</Text>
                        )
                    })}

                    <Text style={styles.text}>{t('description')}: {issue.description ?? ''}</Text>
                    <Text style={styles.text}>{t('actionsTaken')}: {issue.actionsTaken ?? ''}</Text>
                    <Text style={styles.text}>{t('analysis')}: {issue.analysis ?? ''}</Text>
                    <Text style={styles.text}>{t('resolvingAction')}: {issue.resolvingAction ?? ''}</Text>
                    <Text style={styles.text}>{t('time')}: {issue.time?.toString() ?? ''}</Text>
                    <Text style={styles.text}>{t('cost')}: {issue.cost?.toString() ?? ''}</Text>
                </View>

                {activities && activities.length ? <View style={styles.section}>
                    <Text style={styles.header}>{t('activity')}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{t('status')}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{t('responsible')}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{t('activity')}</Text></View>
                            <View style={styles.tableCol}><Text style={styles.tableCell}>{t('doneBefore')}</Text></View>
                        </View>
                        {activities.map((a) => (
                            <View style={styles.tableRow} key={a.id}>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{a.status}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{a.responsibleId}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{a.activity}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{a.doneBefore ? moment(a.doneBefore).format('Y-MM-DD') : ''}</Text></View>
                            </View>
                        ))}
                    </View>
                </View> : null}

                {files && files.length ? <View style={styles.section}>
                    <Text style={styles.header}>{t('files')}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.fileTableCol}><Text style={styles.tableCell}>{t('filename')}</Text></View>
                        </View>
                        {files.map((x) => (
                            <View style={styles.tableRow} key={x.id}>
                                <View style={styles.fileTableCol}><Text style={styles.tableCell}>{x.name}</Text></View>
                            </View>
                        ))}
                    </View>
                </View> : null}

                <View style={styles.section}>
                    <Text style={styles.text}>{t('desiredEffect')}: {desiredEffect ?? ''}</Text>
                    <Text style={styles.text}>{t('desiredEffectComment')}: {issue.desiredEffectComment ?? ''}</Text>

                    <Text style={styles.text}>{t('endedBy')}: {issue.endedBy ?? ''}</Text>
                    <Text style={styles.text}>{t('endedDate')}: {issue.endedDate ? moment(issue.endedDate).format('Y-MM-DD') : ''}</Text>
                    <Text style={styles.text}>{t('reviewBeforeDate')}: {issue.reviewBeforeDate ? moment(issue.reviewBeforeDate).format('Y-MM-DD') : ''}</Text>
                    <Text style={styles.text}>{t('reviewText')}: {issue.reviewText}</Text>
                    <Text style={styles.text}>{t('evaluatedBy')}: {evaluatedBy}</Text>
                    <Text style={styles.text}>{t('evaluatedAt')}: {issue.reviewDate ? moment(issue.reviewDate).format('Y-MM-DD') : ''}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.copy}>{t('thisIsACopyCheckOriginal')}</Text>
                </View>
            </Page>
        </Document>
    );
};
export default IssueReportPdf;

