import React from 'react'
import ModuleMenu from '../../common/moduleMenu'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isInRole, AdminRoles } from '../../../auth/roles'
import { getState } from '../../../state'

const ProjectMenu: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const {
        state: { userProfile },
    } = getState()

    if (isInRole(userProfile, AdminRoles.ProjectsAdminRole)) {
    }

    /* eslint react/jsx-key: "off" */
    return ModuleMenu({
        all: [<NavLink to={`./`}>{t('projects')}</NavLink>, <NavLink to={`./help`}>Hjälp</NavLink>],
        admin: isInRole(userProfile, AdminRoles.ProjectsAdminRole)
            ? [
                <NavLink to={`./admin/templates`}>Admin projektmallar</NavLink>,
                <NavLink to={`./templates/help`}>Hjälp projektmaller</NavLink>,
            ]
            : undefined,
        systemAdmin: isInRole(userProfile, AdminRoles.ViqmaAdminRole)
            ? [<NavLink to={`./admin/projects`}>Systemadmin - Projekt</NavLink>]
            : undefined,
    })
}

export default ProjectMenu
