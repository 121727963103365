import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Button, Header, Form } from 'semantic-ui-react'
import { Plus } from 'react-feather'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import { ModuleInfo, getModule } from '../../auth/modules'
import { useTranslation } from 'react-i18next'
import { getState } from '../../state'
import { Section } from './interfaces'
import ClearFix from '../common/clearFix'
import { getFilterParams } from './filters'
import DynamicFields, { DynamicField } from '../common/dynamicFields'

const ModuleButtonDiv = styled.div`
    width: 85px;
    height: 85px;
    padding: 10px;
    border: 1px solid #8da9bf;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 5px 0 0;
    background-color: #5c6c79;
    color: white;
    font-size: 0.8em;

    img {
        width: 35px;
        margin-bottom: 5px;
    }

    &:hover {
        cursor: pointer;
        background-color: #8da9bf;
    }
`

const ModuleSelectButton: React.FunctionComponent<{
    module: ModuleInfo
    onClick: (module: ModuleInfo) => void
}> = ({ module, onClick }) => {
    return (
        <ModuleButtonDiv onClick={() => onClick(module)}>
            <img src={`/images/moduleicons/${module.image}`} />

            {module.text}
        </ModuleButtonDiv>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export interface AddSectionDialogProps {
    onAddSection: (section: Section) => void
}

const AddSectionDialog: React.FunctionComponent<AddSectionDialogProps> = ({ onAddSection }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedModule, setSelectedModule] = useState<ModuleInfo>()
    const [filters, setFilters] = useState<{ [id: string]: DynamicField }>({})
    const {
        state: { modules },
    } = getState()

    const resolvedModules = modules
        ?.filter(mId => mId !== 1)
        .map(mId => getModule(mId, t))
        .filter(x => x !== null)

    const onOpen = () => {
        setIsOpen(true)
    }

    const onModuleSelected = (module: ModuleInfo) => {
        setSelectedModule(module)
        setFilters(getFilterParams(module.key))
    }

    const onFilterChange = (name: string, value: any) => {
        setFilters(filters => {
            filters[name].value = value
            return { ...filters }
        })
    }

    const addSection = () => {
        const section = {
            id: `${selectedModule!.key}-${new Date().getMilliseconds()}`,
            module: selectedModule!.key,
            filters,
        }

        onAddSection(section)
        setIsOpen(false)
    }

    return (
        <>
            <Button icon={<SemanticFeatherIcon FeatherIcon={Plus} />} onClick={onOpen} />
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <Modal.Header>{t('add')}</Modal.Header>
                <Modal.Content>
                    <Header content={t('module')} />
                    <ButtonContainer>
                        {resolvedModules?.map(m => (
                            <ModuleSelectButton
                                key={m!.key}
                                module={m!}
                                onClick={() => onModuleSelected(m!)}
                            />
                        ))}
                    </ButtonContainer>

                    {selectedModule && (
                        <>
                            <Header content={`${t('filter')} - ${selectedModule.text}`} />
                            <Form>
                                <DynamicFields
                                    columns={2}
                                    standaloneGrid={true}
                                    fields={filters}
                                    onChange={onFilterChange}
                                    translateFieldNames={true}
                                />
                            </Form>
                        </>
                    )}
                </Modal.Content>
                <Modal.Actions>
                    {selectedModule && (
                        <>
                            <Button
                                onClick={addSection}
                                content={t('addSection')}
                                floated="right"
                            />
                            <ClearFix />
                        </>
                    )}
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default AddSectionDialog
