import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import Loading from '../../../common/Loading'
import AccidentForm from '../../../forms/AccidentForm'
import { IDeviationSettingUser } from '../../../../api/issues'

interface IAccidentFormPageProps {
    refresh: () => void
    isNew: boolean
    responsibleSafetyUsers: IDeviationSettingUser[]
}

const AccidentFormPage = ({ refresh, isNew, responsibleSafetyUsers }: IAccidentFormPageProps) => {
    const { t } = useTranslation()
    const { accidentId } = useParams<{ accidentId: string }>()
    const {
        isLoading,
        data: selectedAccident,
    } = useGetQueryByParams(!!accidentId, API.issues.getAccidentById, "selectedAccident", accidentId)

    if (isLoading) {
        return <Loading />
    }
    return (
        <Container>
            <Header dividing>{isNew ? t('addValue', { value: t('accidentIncidentRisk').toLowerCase() }) : t('editValueOwnId', { value: t('accidentIncidentRisk').toLowerCase(), ownId: selectedAccident?.ownId })}</Header>
            <AccidentForm refresh={refresh} isNew={isNew} selectedAccident={selectedAccident} responsibleSafetyUsers={responsibleSafetyUsers} />
        </Container>
    )
}

export default AccidentFormPage
