import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Tab, TabPane } from 'semantic-ui-react'
import DeviationStatistics from './DeviationStatistics'
import AccidentStatistics from './AccidentStatistics'

const IssueStatisticPage = () => {
    const { t } = useTranslation()
    const panes = useMemo(() => [
        { menuItem: t('valueStatistics', { value: t('issues') }), render: () => <TabPane><DeviationStatistics /></TabPane> },
        { menuItem: t('valueStatistics', { value: t('accidentIncidentRisk') }), render: () => <TabPane><AccidentStatistics /></TabPane> },
    ], [t])
    return <><Header dividing icon="line graph" content={t('statistics')} /><Tab panes={panes} /></>
}

export default IssueStatisticPage
