import { Action } from '../reducer'
import { getUser, getAuthedAxios, login, loginSilent } from '../auth'
import { handleAxiosError, displayError } from './error'
import { ProfileWithRoles } from '../auth/roles'
import { isInPageRenderMode } from './util'
import { doDelegatedLogin } from '../auth/delegated'

const getUsermodules = (dispatch: React.Dispatch<Action>): Promise<number[]> =>
    getAuthedAxios().then(axios =>
        axios.get(window.env.REACT_APP_AUTH_SERVICE + '/api/account/info/modules').then(x => {
            dispatch({ type: 'userModulesLoaded', modules: x.data })

            return x.data
        }, handleAxiosError(dispatch)),
    )

export const populateStateFromProfileOrLogin = (dispatch: React.Dispatch<Action>) => {
    if (isInPageRenderMode()) {
        return getUser().then(user => {
            if (user && !user.expired) {
                dispatch({ type: 'userProfileLoaded', profile: user!.profile as ProfileWithRoles })
                return
            }

            return login().catch((reason: any) =>
                displayError(dispatch)(reason.toString(), 'Login failed'),
            )
        })
    } else {
        return new Promise<void>((resolve, reject) => {
            doDelegatedLogin()
                .then(x => {
                    if (x.decodedToken) {
                        dispatch({ type: 'userProfileLoaded', profile: x.decodedToken })
                        resolve()
                    } else {
                        reject()
                    }
                })
                .catch(reject)
        })
    }
}

const userStartupActions = (dispatch: React.Dispatch<Action>): Promise<number[]> =>
    populateStateFromProfileOrLogin(dispatch).then(() => getUsermodules(dispatch))

export default userStartupActions
