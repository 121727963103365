import * as API from "../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../actions/error"
import { getState } from "../../../../../state"

interface IDestroyIssue {
    id: number
    confirmText: string
}

export function useDeleteDeviation(refresh: () => void) {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const destroyissue = async (data: IDestroyIssue) => {
        if (window.confirm(data.confirmText)) {
            return await API.issues.destroyIssue(data.id)
        }
    }

    const { isLoading: isDestroying, mutate: onDestroy } = useMutation(destroyissue, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast.success(t('saved'))
                refresh()
            }
        },
    })

    return {
        isDestroying,
        onDestroy,
    }
}
