import React, { useState } from 'react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { Button, Segment, SegmentProps } from 'semantic-ui-react'
import styled from 'styled-components'
import SemanticFeatherIcon from '../icons/SemanticFeatherIcon'
import FlexBox from './flexBox'

const HeaderText = styled.span`
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
    user-select: none;
`
const ContentContainer = styled.div`
    margin-top: 20px;
`

export interface CollapsingSegmentProps extends SegmentProps {
    heading: string
    defaultOpen?: boolean
}

const CollapsingSegment: React.FunctionComponent<CollapsingSegmentProps> = ({
    heading,
    defaultOpen = false,
    children,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)

    return (
        <Segment {...rest} className={!isOpen ? "hide-in-print" : ''}>
            <FlexBox alignItems="center">
                <Button
                    icon={
                        <SemanticFeatherIcon
                            FeatherIcon={isOpen ? ChevronDown : ChevronRight}
                            style={{ marginTop: '-2px' }}
                        />
                    }
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    size="tiny"
                    compact
                />

                <HeaderText onClick={() => setIsOpen(!isOpen)}>{heading}</HeaderText>
            </FlexBox>

            {isOpen && <ContentContainer>{children}</ContentContainer>}
        </Segment>
    )
}

export default CollapsingSegment
