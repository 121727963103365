import { Row } from '@tanstack/react-table'
import React from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export interface MoveToRootDropTargetProps<T = any> {
    onDropRow: (draggedRow: Row<T>) => void
}

const MoveToRootDropTargetElement = styled.div<{ $isOver: boolean }>`
    padding: 15px;
    width: 100%;
    height: 50px;
    background-color: #dddddd;
    color: black;
    border-radius:4px;
    opacity: ${props => (props.$isOver ? 0.5 : 1)};
    margin: 1em 0;
`

const MoveToRootDropTarget: React.FunctionComponent<MoveToRootDropTargetProps> = ({
    onDropRow,
}) => {
    const { t } = useTranslation()

    const [{ isOver }, dropRef]: any = useDrop({
        accept: 'row',
        drop: (draggedRow: Row<any>) => {
            onDropRow(draggedRow)
        },
    });
    return (
        <MoveToRootDropTargetElement ref={dropRef} $isOver={isOver}>
            {t('dropHereToMoveToRoot')}
        </MoveToRootDropTargetElement>
    )
}

export default MoveToRootDropTarget
