import React, { useCallback, useState } from 'react'
import { Edit, Plus, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Table } from 'semantic-ui-react'
import FlexBox from '../../../common/flexBox'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { ICompetenceUserPositionDto } from '../../../../api/skills'
import { useDeleteUserPosition } from './useDeleteUserPosition'
import ModalWrapper from '../../../wrappers/ModalWrapper'
import RegularForm from './RegularForm'
import UserPositionBatchForm from './UserPositionBatchForm'
import DocumentSectionModule from '../../../modules/docs/viewPage/DocumentSectionModule'

interface IUserPositionListModalState {
    new: boolean
    edit: boolean
}

export type UserPositionType = "new" | "edit"
export interface IUserPositionListProps {
    userId: string
    userPositions: ICompetenceUserPositionDto[]
    refresh: () => void
}

const UserPositionList = ({ userId, userPositions, refresh }: IUserPositionListProps) => {
    const { t } = useTranslation()

    const { isDestroying, onDestroy } = useDeleteUserPosition(refresh)
    const [selectedUserPosition, setSelectedUserPosition] = useState<ICompetenceUserPositionDto | null>(null)
    const [modalState, setModalState] = useState<IUserPositionListModalState>({
        new: false,
        edit: false,
    });

    const handleShowModal = useCallback((data: ICompetenceUserPositionDto | null, modal: UserPositionType) => {
        setModalState({ ...modalState, [modal]: true })
        setSelectedUserPosition(data)
    }, [modalState])

    const onCancel = (modal: UserPositionType) => {
        setModalState({ ...modalState, [modal]: false })
        setSelectedUserPosition(null)
    }

    return (
        <div style={{ marginTop: "2em" }}>
            <DocumentSectionModule title={t('skillPositions')} attachTop={true}>
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="7" textAlign="right">
                                <Button
                                    icon
                                    labelPosition="left"
                                    primary
                                    type="button"
                                    onClick={() => handleShowModal(null, "new")}
                                >
                                    <SemanticFeatherIcon
                                        FeatherIcon={Plus}
                                        size={'60%'}
                                        centerIcon={true}
                                    />{' '}
                                    {t('new')}
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>{t('isRegular')}</Table.HeaderCell>
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {userPositions?.map((x, i) => (
                            <Table.Row key={x.id}>
                                <Table.Cell verticalAlign="top">
                                    {x.name}
                                </Table.Cell>
                                <Table.Cell verticalAlign="top" textAlign='center'>
                                    <Checkbox checked={x.isRegular} />
                                </Table.Cell>
                                <Table.Cell verticalAlign="top" collapsing>
                                    <FlexBox justifyContent="end">
                                        <Button
                                            icon={<SemanticFeatherIcon FeatherIcon={Edit} />}
                                            onClick={() => handleShowModal(x, "edit")}
                                            type="button"
                                        />
                                        <Button
                                            icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />}
                                            onClick={() => onDestroy({
                                                confirmText: t('confirm.deleteValue', { value: x.name }),
                                                userId: userId,
                                                positionId: x.id
                                            })}
                                            color='red'
                                            disabled={isDestroying}
                                            type="button"
                                        />
                                    </FlexBox>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </DocumentSectionModule>

            <ModalWrapper title={t('addValue', { value: t('position').toLowerCase() })} open={modalState.new} onClose={() => onCancel("new")} size='small'>
                <UserPositionBatchForm onCancel={() => onCancel("new")} refresh={refresh} userId={userId} userPositions={userPositions} />
            </ModalWrapper>


            {selectedUserPosition &&
                <ModalWrapper title={t('editValue', { value: t('position').toLowerCase() })} open={modalState.edit} onClose={() => onCancel("edit")} size='tiny'>
                    <RegularForm userId={userId} position={selectedUserPosition} refresh={refresh} onCancel={() => onCancel("edit")} />
                </ModalWrapper>}
        </div>
    )
}

export default UserPositionList
