import { Table, Column } from "@tanstack/react-table"
import React from "react"
import styled from "styled-components"
/* eslint-disable @typescript-eslint/no-unused-vars */
declare module "@tanstack/table-core" {
  export interface ColumnMeta<TData extends unknown, TValue> {
    filterComponent?: (setFilterValue: any, value: any) => any
    checkbox?: boolean
  }
}
/* eslint-disable @typescript-eslint/no-unused-vars */
interface ISimpleFilterProps {
  column: Column<any, any>
  table: Table<any>
}

const FilterInput = styled.input`
  width: 100%;
  height: 2.4375em;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  padding: 16.5px 14px;
  border-radius: 4px;
  border-style: inset;
  border: 1px solid hsl(210deg 3% 68% / 90%);
`

const SimpleFilter = ({ column }: ISimpleFilterProps) => {
  const columnFilterValue = column.getFilterValue()
  return (
    <FilterInput
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e: any) => column.setFilterValue(e.target.value)}
      list={column.id + "list"}
    />
  )
}
export default SimpleFilter
