import React from 'react'
import { Grid, Header, Table, Icon, Label, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import PrintLabelValue from '../../../wrappers/PrintWrapper/PrintLabelValue'
import moment from 'moment'
import CompactGrid from '../../../common/compactGrid'
import { FileListing, guessIconFromName } from '../../../common/files'
import * as API from '../../../../api'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import Loading from '../../../common/Loading'
import { IActivityDto, AccidentWithCreationOptionsDto } from '../../../../api/issues'
import { getState } from '../../../../state'
import FlexBox from '../../../common/flexBox'
import CompanyLogo from '../../../common/companyLogo'
import { getDepartmentFromState, getLocationFromState, getUserFromState } from '../../../../state/helpers'
import Spacer from '../../../common/spacer'
import PrintCheckbox from '../../../wrappers/PrintWrapper/PrintCheckbox'

export interface IAccidentPrintViewProps {
    accident: AccidentWithCreationOptionsDto
    isAccident: boolean
    ownId?: number
    accidentId?: number
}

const AccidentPrintView = React.forwardRef<HTMLDivElement, IAccidentPrintViewProps>(({ accident, isAccident, ownId, accidentId }, ref) => {
    const {
        isLoading,
        data,
    } = useGetQueryByParams(!!accidentId, API.issues.getAllAccidentFiles, "accidentPrintFiles", accidentId)

    const {
        isLoading: isLoadingActivities,
        data: activities,
    } = useGetQueryByParams(!!accidentId, API.issues.getAccidentActivitiesById, "accidentPrintActivities", accidentId)

    if (isLoading || !data || isLoadingActivities || !activities) return <Loading />

    return <div ref={ref} className='show-in-print'>
        <AccidentPrintViewDumb accident={accident} isAccident={isAccident} files={data} ownId={ownId} activities={activities} />
    </div>

})

export interface PrintViewDumbProps {
    accident: AccidentWithCreationOptionsDto
    isAccident: boolean
    files: FileListing[]
    activities: IActivityDto[]
    ownId?: number
}

export const AccidentPrintViewDumb: React.FunctionComponent<PrintViewDumbProps> = ({
    accident,
    isAccident,
    files,
    activities,
    ownId
}) => {
    const { t } = useTranslation()
    const { state } = getState()
    return (
        <>
            <FlexBox justifyContent="space-between" style={{ margin: "1em 1em 5em 1em" }}>
                <CompanyLogo maxWidth="150px" />
                <div>
                    <PrintLabelValue
                        label={t('reportDate')}
                        value={moment(accident.reportDate).format('Y-MM-DD')}
                    />
                    <PrintLabelValue
                        label={t('reportedBy')}
                        value={getUserFromState(state)(accident.reportedBy)?.name ?? ""}
                    />
                </div>
            </FlexBox>

            <CompactGrid>
                <Grid.Row columns="1">
                    <Grid.Column>
                        <PrintCheckbox
                            label={t('incidentIsReported')}
                            value={accident.isReported}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t(`accidentReason.${accident.type}`)}
                            value={accident.extentOfOutcome ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={"Id"}
                            value={ownId?.toString() ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('responsible')}
                            value={getUserFromState(state)(accident.responsibleId)?.name ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('type')}
                            value={t(`accidentReason.${accident.type}`)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('departmentId')}
                            value={accident.departmentId ? getDepartmentFromState(state)(accident.departmentId)?.name ?? "" : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                {state.locations?.length ? <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('locationId')}
                            value={accident.locationId ? getLocationFromState(state)(accident.locationId)?.name ?? "" : ""}
                        />
                    </Grid.Column>
                </Grid.Row> : null}
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('customer')}
                            value={accident.customerSupplier ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('doneBefore')}
                            value={accident.doneBefore ? moment(accident.doneBefore).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('description')}
                            value={accident.description ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('actionsTaken')}
                            value={accident.actionsTaken ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Spacer />
                    </Grid.Column>
                </Grid.Row>

                {isAccident && <Grid.Row columns="1">
                    <Grid.Column >
                        <Segment raised padded>
                            <Grid stackable>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('estimatedAbsenceTime')}
                                            value={accident.estimatedAbsenceTime !== null ? t(`absenceTimeType.${accident.estimatedAbsenceTime}`) : ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('accidentDate')}
                                            value={accident.accidentDate ? moment(accident.accidentDate).format('Y-MM-DD') : ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('accidentTime')}
                                            value={accident.accidentTime ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('optionalNameOfTheInjuredPerson')}
                                            value={accident.accidentName ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('employmentYear')}
                                            value={accident.employmentYear?.toString() ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('employmentType')}
                                            value={accident.employmentType !== null ? t(`employmentType.${accident.employmentType}`) : ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('whatHappened')}
                                            value={accident.whatHappened !== null ? t(`causeOfAccident.${accident.whatHappened}`) : ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('location')}
                                            value={accident.location ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('task')}
                                            value={accident.task ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('factor')}
                                            value={accident.factor ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintLabelValue
                                            label={t('differencesFromNormal')}
                                            value={accident.differencesFromNormal ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <h3>{t('incidentConditions')}</h3>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintCheckbox
                                            label={t('locationHadImpact')}
                                            value={accident.locationHadImpact}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintCheckbox
                                            label={t('equipmentHadImpact')}
                                            value={accident.equipmentHadImpact}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintCheckbox
                                            label={t('workEnvironmentHadImpact')}
                                            value={accident.workEnvironmentHadImpact}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintCheckbox
                                            label={t('preventiveActionsHadImpact')}
                                            value={accident.preventiveActionsHadImpact}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintCheckbox
                                            label={t('companyClimateHadImpact')}
                                            value={accident.companyClimateHadImpact}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column >
                                        <PrintCheckbox
                                            label={t('educationHadImpact')}
                                            value={accident.educationHadImpact}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns="1">
                                    <Grid.Column>
                                        <PrintCheckbox
                                            label={t('otherCausesHadImpact')}
                                            value={accident.otherCausesHadImpact}
                                        />
                                    </Grid.Column>

                                </Grid.Row>
                                <Grid.Row columns="1">
                                    <Grid.Column>
                                        <PrintLabelValue
                                            label={t('conditionalEffects')}
                                            value={accident.conditionalEffects ?? ""}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>}

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Spacer spacing='20px' />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('analysis')}
                            value={accident.analysis ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('resolvingAction')}
                            value={accident.resolvingAction ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className={activities?.length === 0 ? "hide-in-print" : ''}>
                    <Grid.Column>
                        <Header size="medium" content={t('activity')} />
                        <Table compact unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('status')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('responsible')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('activity')}</Table.HeaderCell>
                                    <Table.HeaderCell>{t('doneBefore')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {activities.map((a: IActivityDto) => (
                                    <Table.Row key={a.id}>
                                        <Table.Cell>{t(`issueStatus.${a.status}`)}</Table.Cell>
                                        <Table.Cell>{getUserFromState(state)(a.responsibleId)?.name ?? ""}</Table.Cell>
                                        <Table.Cell>{a.activity}</Table.Cell>
                                        <Table.Cell>
                                            {a.doneBefore ? moment(a.doneBefore).format('Y-MM-DD') : ""}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Spacer />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} className={files?.length === 0 ? "hide-in-print" : ''}>
                    <Grid.Column>
                        <Header size="medium" content={t('files')} />
                        <Table compact unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t('filename')}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {files &&
                                    files.map((x: FileListing) => (
                                        <Table.Row key={x.id}>
                                            <Table.Cell>
                                                <Icon name={guessIconFromName(x.name)} />
                                                {x.name}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Spacer />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('endedBy')}
                            value={accident.endedBy ?? ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('endedDate')}
                            value={accident.endedDate ? moment(accident.endedDate).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <PrintLabelValue
                            label={t('reviewBeforeDate')}
                            value={accident.reviewBeforeDate ? moment(accident.reviewBeforeDate).format('Y-MM-DD') : ""}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Label basic>{t('thisIsACopyCheckOriginal')}</Label>
                    </Grid.Column>
                </Grid.Row>
            </CompactGrid>
        </>
    )
}

export default AccidentPrintView
