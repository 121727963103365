import * as z from 'zod';
import { IActivityDto } from '../../../../api/issues';
import ControlDropdown, { IDropdownOption } from '../../../fields/ControlDropdown';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Button, Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import ControlInput from '../../../fields/ControlInput';
import ControlTextArea from '../../../fields/ControlTextArea';
import ControlDateField from '../../../fields/ControlDateField';
import { useTranslation } from 'react-i18next';
import { mockedActivity } from '../../../../utils/MockedData/Issues';
import { getState } from '../../../../state';
import { handleAxiosError } from '../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon';
import { ArrowLeftCircle, Save } from 'react-feather';
import { mapIssueActivity } from '../../../../utils/Mappings/Issues';
import { AxiosResponse } from 'axios';
import ControlCheckbox from '../../../fields/ControlCheckbox';

export interface IActivityFormApiFunctions {
    editActivity: (issueId: number, activityId: number, data: IActivityDto) => Promise<AxiosResponse<unknown, any>>
    createActivity: (id: number, data: IActivityDto) => Promise<AxiosResponse<unknown, any>>
}
export interface IActivityFormProps {
    userOptions: IDropdownOption[];
    onCancel: () => void
    issueId: number
    selectedActivity: IActivityDto | null
    notifyClosingTranslationKey: string
    notifyCreationTranslationKey: string
    refresh: () => void
    apiFunctions: IActivityFormApiFunctions
}

export const activityRequestSchema = z.object({
    activity: z.string().min(1, "required"),
    responsibleId: z.string().min(1, "required"),
    description: z.string().nullable(),
    doneBefore: z.date().nullable(),
    doneDate: z.date().nullable(),
    status: z.coerce.number().nullable(),
    doneBy: z.string().nullable(),
    closingNotifyResponsible: z.boolean(),
    creationNotifyResponsible: z.boolean()

});

const ActivityForm = ({ userOptions, onCancel, issueId, selectedActivity, refresh, apiFunctions, notifyClosingTranslationKey, notifyCreationTranslationKey }: IActivityFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit, watch } = useForm<IActivityDto>({
        defaultValues: selectedActivity ? mapIssueActivity(selectedActivity) : mockedActivity,
        resolver: zodResolver(activityRequestSchema),
    });

    const addActivity = async (data: IActivityDto) => {
        if (selectedActivity) {
            return await apiFunctions.editActivity(issueId, selectedActivity.id, data)
        } else {
            return await apiFunctions.createActivity(issueId, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(addActivity, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form noValidate >
            <Grid stackable>
                <GridRow>
                    <GridColumn>
                        <ControlInput
                            name={'activity'}
                            control={control}
                            disabled={false}
                            label={t('activity')}
                            required
                        />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown
                            name={`responsibleId`}
                            label={t("responsible")}
                            control={control}
                            disabled={false}
                            required
                            collection={userOptions} />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField
                            name={`doneBefore`}
                            label={t('doneBefore')}
                            control={control}
                            disabled={false} />
                    </GridColumn>
                </GridRow>
                {!selectedActivity && <GridRow columns="1">
                    <GridRow columns="1">
                        <GridColumn >
                            <ControlCheckbox
                                name="creationNotifyResponsible"
                                label={t(`${notifyCreationTranslationKey}`)}
                                control={control}
                                disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                </GridRow>}
                <GridRow>
                    <GridColumn>
                        <ControlTextArea label={t('description')} name={`description`} control={control} disabled={false} />
                    </GridColumn>
                </GridRow>



                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput
                            control={control}
                            disabled={false}
                            label={t('doneBy')}
                            name={`doneBy`}
                        />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField
                            name={`doneDate`}
                            label={t('doneDate')}
                            control={control}
                            disabled={false} />

                    </GridColumn>
                </GridRow>
                {selectedActivity && <GridRow columns="1">
                    <GridRow columns="1">
                        <GridColumn >
                            <ControlCheckbox
                                name="closingNotifyResponsible"
                                label={t(`${notifyClosingTranslationKey}`)}
                                control={control}
                                disabled={isSaving || (!watch("doneBy") || !watch("doneDate"))} />
                        </GridColumn>
                    </GridRow>
                </GridRow>}
                <GridRow>
                    <GridColumn>
                        <Button
                            floated="right"
                            content={t('cancel')}
                            icon={<SemanticFeatherIcon FeatherIcon={ArrowLeftCircle} />}
                            onClick={onCancel}
                            type="button"
                            disabled={isSaving}
                        />

                        <Button
                            primary
                            floated="right"
                            content={t('save')}
                            icon={
                                <SemanticFeatherIcon
                                    FeatherIcon={Save}
                                />
                            }
                            onClick={handleSubmit(data => onSubmit(data))}
                            type={'button'}
                            disabled={isSaving}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default ActivityForm;