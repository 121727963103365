import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../../../api'
import { ICompetenceUserPositionDto, ICompetenceUserPositionRequest } from '../../../../../api/skills';
import FormActions from '../../../FormActions';
import ControlCheckbox from '../../../../fields/ControlCheckbox';

export interface IRegularFormProps {
    userId: string
    position: ICompetenceUserPositionDto
    onCancel: () => void
    refresh: () => void
}

export const reserveRequestSchema = z.object({
    isRegular: z.boolean(),
});

const RegularForm = ({ userId, position, onCancel, refresh }: IRegularFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<ICompetenceUserPositionRequest>({
        defaultValues: { isRegular: position.isRegular },
        resolver: zodResolver(reserveRequestSchema),
    });

    const onPositionRequest = async (data: ICompetenceUserPositionRequest) => {
        return await API.skills.editCompetenceUserPosition(userId, position.id, data)
    }


    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onPositionRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlCheckbox name="isRegular" label={t('isRegular')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default RegularForm;