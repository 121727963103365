import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, DropdownProps, Form, Input, InputOnChangeData } from 'semantic-ui-react'
import { Survey } from '../../../hooks/surveys/survey'
import Spacer from '../../common/spacer'
import SurveyBase, { ButtonContainer } from './surveyBase'

export interface SurveyInfo {
    name?: string
    email?: string
    company?: string
    departmentId?: number
}

export interface SurveyInfoPromptProps {
    survey: Survey
    currentInfo?: SurveyInfo
    setInfo: (info: SurveyInfo) => void
    onDone: () => void
}

const SurveyInfoPrompt: React.FunctionComponent<SurveyInfoPromptProps> = ({
    survey,
    currentInfo,
    setInfo,
    onDone,
}) => {
    const { t } = useTranslation()

    const departmentOptions = useMemo(
        () =>
            Object.entries(survey.departments ?? {}).map(([id, name]) => ({
                key: id,
                value: id,
                text: name,
            })),
        [survey.departments],
    )
    const isValidDepartment = survey.showDepartmentSelection ? (currentInfo?.departmentId ?? -1) > -1 : true

    return (
        <SurveyBase imageUrl={survey.imageUrl}>
            <div>
                <Form>
                {survey.showDepartmentSelection ? (
                    <Form.Field
                        label="Department/Group"
                        control={Dropdown}
                        value={currentInfo?.departmentId ?? -1}
                        onChange={(_: Event, d: DropdownProps) =>
                            setInfo({ ...currentInfo, departmentId: d.value as number })
                        }
                        options={departmentOptions}
                        selection
                    />
                ) : (
                    <>                
                        <Form.Field
                            label={survey.nameText ?? t('name')}
                            control={Input}
                            value={currentInfo?.name ?? ''}
                            onChange={(_: Event, d: InputOnChangeData) =>
                                setInfo({ ...currentInfo, name: d.value })
                            }
                        />

                        {/* <Form.Field
                            label={survey.emailText ?? t('email')}
                            control={Input}
                            value={currentInfo?.email ?? ''}
                            onChange={(_: Event, d: InputOnChangeData) =>
                                setInfo({ ...currentInfo, email: d.value })
                            }
                            type="email"
                        /> */}

                        <Form.Field
                            label={survey.companyText ?? t('company')}
                            control={Input}
                            value={currentInfo?.company ?? ''}
                            onChange={(_: Event, d: InputOnChangeData) =>
                                setInfo({ ...currentInfo, company: d.value })
                            }
                        />
                    </>
                )}
                </Form>
                <Spacer />
                <ButtonContainer>
                    <Button 
                        content="Next" 
                        size="large" 
                        icon="forward" 
                        onClick={onDone}
                        disabled={!isValidDepartment} />
                </ButtonContainer>
            </div>
        </SurveyBase>
    )
}

export default SurveyInfoPrompt
