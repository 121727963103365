import React from 'react'
import { useTranslation } from 'react-i18next'
import { getState } from '../../state'
import { createUserNameDisplay, createUserNameDisplayFromUser } from '../../state/helpers'

export interface UserNameDisplayProps {
    userId?: string
    user?: { active: boolean; name: string }
    fallbackValue?: string
    fallbackToDisplayUserId?: boolean
}

const UserNameDisplay: React.FunctionComponent<UserNameDisplayProps> = ({
    userId,
    user,
    fallbackValue,
    fallbackToDisplayUserId = true,
}) => {
    const { t } = useTranslation()
    const { state } = getState()

    if (user) return <>{createUserNameDisplayFromUser(t)(user, userId)}</>
    if (userId)
        return (
            <>
                {createUserNameDisplay(state, t)(
                    userId,
                    fallbackValue ?? (fallbackToDisplayUserId ? userId : ''),
                )}
            </>
        )

    return <></>
}

export default UserNameDisplay
