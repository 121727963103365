import useSWR from 'swr'

export type DocumentListing = {
    id: string
    name: string
    version?: number
    isPublished: boolean
    lastChanged: string
}

export type DocumentIndex = {
    companyDocuments: DocumentListing[]
    concernDocuments: DocumentListing[]
}

export const useDocumentListing = () =>
    useSWR<DocumentIndex, any>(`${window.env.REACT_APP_DOC_SERVICE}/api/document`)

export const useDocumentListingOptions = () => {
    const { data: documents } = useDocumentListing()

    const companyAndConcernDocuments = [
        ...(documents?.companyDocuments ?? []),
        ...(documents?.concernDocuments ?? []),
    ]

    companyAndConcernDocuments.sort((a, b) => a.name.localeCompare(b.name, 'sv'))

    return companyAndConcernDocuments?.filter(x => x.isPublished).map(x => ({
        key: x.id,
        text: `${x.name} (v${x.version})`,
        value: x.id,
    }))
}
