import { useEffect } from 'react'
import { useCrud } from '../crud/use-crud'
import useApi from '../useApi'

export type ExternalDocument = {
    id?: number
    documentId?: number
    companyId?: number
    accountingDocument: string
    archivingSite: string
    archivingTime: string
    isInheritedFromConcern?: boolean
}

export const useAllExternalDocuments = () =>
    useApi<ExternalDocument[]>(`${window.env.REACT_APP_DOC_SERVICE}/api/externalDocuments`)

export const useExternalDocuments = (documentId: number) => {
    const { fetch, create, remove, update } = useCrud<ExternalDocument, ExternalDocument>(
        `${window.env.REACT_APP_DOC_SERVICE}/api/externalDocuments?documentId=${documentId}`,
        `${window.env.REACT_APP_DOC_SERVICE}/api/externalDocuments`,
        'id',
    )

    const data: ExternalDocument[] = fetch.data || []
    useEffect(() => {
        if (fetch.error) {
            console.log('ERROR :D: D ', fetch.error)
        }
    }, [fetch.error])

    return {
        loading: fetch.loading,
        create: (externalDocument: ExternalDocument) => create({ ...externalDocument, documentId }),
        remove: (id: number) => remove(id),
        update: (externalDocument: ExternalDocument) => {
            if (externalDocument.id)
                update('' + externalDocument.id, { ...externalDocument, documentId })
        },
        data,
    }
}
