import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    ButtonProps,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import {
    SystematicTaskDto,
    useSystematicTasksForDocument,
} from '../../../../hooks/systematicTasks/forDocument'
import { useUserName } from '../../../../hooks/users'
import { useFormattedDate } from '../../../../hooks/useFormattedDate'
import DocumentSectionModule from './DocumentSectionModule'
import ModuleIcon from '../../../icons/ModuleIcon'
import { MODULES } from '../../../../auth/modules'
import { Bell } from 'react-feather'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { sendReminder } from '../../../../actions/reminder'
import { getState } from '../../../../state'
import { handleAxiosError } from '../../../../actions/error'
import { toast } from 'react-toastify'
import { isInThePast } from '../../../../actions/util'

export interface SystematicTasksTableProps {
    documentId: string
    isAdmin: boolean
}
export interface SystematicTasksTableDumbProps {
    tasks: SystematicTaskDto[] | undefined
    isAdmin: boolean
    onRemindClick?(systematicTask: SystematicTaskDto): void
}

export const SystematicTasksTableDumb: FunctionComponent<SystematicTasksTableDumbProps> = ({
    tasks,
    isAdmin,
    onRemindClick,
}) => {
    const { t } = useTranslation()
    return (
        <Table style={{ marginBottom: 0 }} basic="very">
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>{t('ownId')}</TableHeaderCell>
                    <TableHeaderCell>{t('topic')}</TableHeaderCell>
                    <TableHeaderCell>{t('responsible')}</TableHeaderCell>
                    <TableHeaderCell>{t('date')}</TableHeaderCell>
                    {isAdmin && (
                        <TableHeaderCell collapsing className="hide-in-print">
                            {t('remindResponsible')}
                        </TableHeaderCell>
                    )}
                </TableRow>
            </TableHeader>
            <TableBody>
                {tasks?.map(task => (
                    <SystematicTaskRow
                        key={task.id}
                        systematicTask={task}
                        onRemindClick={onRemindClick}
                        isAdmin={isAdmin}
                    />
                ))}
            </TableBody>
        </Table>
    )
}
interface SystematicTaskRowProps {
    systematicTask: SystematicTaskDto
    isAdmin: boolean
    onRemindClick?(systematicTask: SystematicTaskDto): void
}
const SystematicTaskRow: FunctionComponent<SystematicTaskRowProps> = ({
    systematicTask,
    isAdmin,
    onRemindClick,
}) => {
    const { date, id, topic, responsibleId } = systematicTask

    const userName = useUserName(responsibleId)
    const formattedDate = useFormattedDate(date)

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
        event.preventDefault()
        onRemindClick?.(systematicTask)
    }

    return (
        <TableRow negative={isInThePast(date)}>
            <TableCell>{id}</TableCell>
            <TableCell>
                <a href={`/revisioner/systematiskaUppg.aspx?id=${topic}`}>{topic}</a>
            </TableCell>
            <TableCell>{userName}</TableCell>
            <TableCell>{formattedDate}</TableCell>
            {isAdmin && (
                <TableCell>
                    <Button
                        floated="right"
                        size="small"
                        type="button"
                        icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                        onClick={onClick}
                    />
                </TableCell>
            )}
        </TableRow>
    )
}

export const SystematicTasksTableSectionModule: FunctionComponent<SystematicTasksTableProps> = ({
    documentId,
    isAdmin,
}) => {
    const { t } = useTranslation()
    const { dispatch } = getState()

    const onRemindClick = (systematicTask: SystematicTaskDto) => {
        sendReminder(
            systematicTask.responsibleId,
            {
                templateKey: 'SystematicTaskReminder',
                values: {
                    topic: systematicTask.topic,
                    date: systematicTask.date,
                    ownId: systematicTask.id.toString(),
                },
            },
            {
                attachedId: systematicTask.id.toString(),
                set: 'SystematicTaskReminder',
            },
        )
            .then(() => {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            })
            .catch(handleAxiosError(dispatch))
    }

    const { data } = useSystematicTasksForDocument(documentId)
    return data?.length ? (
        <DocumentSectionModule
            title={t('systematicTasks')}
            icon={<ModuleIcon moduleId={MODULES.SYSTEMATIC_TASKS} dark={true} size={32} />}
            hideInPrint={!data.length}
        >
            <SystematicTasksTableDumb
                tasks={data}
                onRemindClick={onRemindClick}
                isAdmin={isAdmin}
            />
        </DocumentSectionModule>
    ) : null
}
