import { IActionPlanDto, IKeyFigureDto, ISupplierResponse } from "../../../api/suppliers";

export const mockedSupplier = (): ISupplierResponse => ({
    id: 0,
    name: "",
    group: "A",
    active: true,
    language: "swe",
    service: "",
    quality: null,
    environment: null,
    workEnvironment: null,
    csr: null,
    loadBearingStructureWeldingCertificate: null,
    qualityCertificationExpires: null,
    environmentCertificationExpires: null,
    workEnvironmentCertificationExpires: null,
    csrCertificationExpires: null,
    otherCertificationExpires: null,
    email: "",
    interval: null,
    contact: "",
    position: "",
    zipCode: "",
    organizationNumber: "",
    location: "",
    telephoneNumber: "",
    country: "",
    webpage: "",
    description: "",
    comment: "",
    address: "",
    sId: null
});

export const mockedKeyFigure = (): IKeyFigureDto => ({
    id: 0,
    companyId: null,
    supplierId: null,
    keyFigure: "",
    value: null,
    unit: "",
    year: null
})

export const mockedActionPlan = (): IActionPlanDto => ({
    id: 0,
    status: 1,
    companyId: null,
    supplierId: null,
    responsibleId: "",
    topic: "",
    description: "",
    doneBefore: null,
    useReview: null,
    reviewDate: null,
    reviewResponsibleId: "",
    reviewText: "",
    endedDate: null,
    endedBy: ""
})