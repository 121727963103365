import { FilterFn } from "@tanstack/react-table";
import React from "react";
import { Checkbox } from "semantic-ui-react";

export const booleanFilter: FilterFn<any> = (row, columnId, filterValue, addMeta) => {
    // If filterValue is null, don't filter
    if (filterValue === null) return true;

    // Compare with the row's value
    return row.original[columnId] === filterValue;
}

interface IBooleanFilterProps {
    setFilterValue: (updater: boolean) => void;
    value: boolean | null;
}

export function BooleanFilter({ setFilterValue, value }: IBooleanFilterProps) {

    return (
        <Checkbox
            toggle
            checked={value || false}
            onChange={(_, d) => setFilterValue(d.checked ?? false)}
        />
    );
}
