import React, { useState } from 'react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Button, Container, Header, Table } from 'semantic-ui-react'
import { formatDate } from '../../../../actions/util'
import { MODULES } from '../../../../auth/modules'
import { useDocumentTemplateFiles, useDocumentTemplates } from '../../../../hooks/docs/templates'
import { useLanguage } from '../../../../hooks/language'
import { useLawDocumentConnection } from '../../../../hooks/laws/documentConnection'
import { FileIcon, FileLink } from '../../../common/files'
import ModuleIcon from '../../../icons/ModuleIcon'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import DocumentSectionModule from '../viewPage/DocumentSectionModule'

const DocumentTemplatePage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { templateId } = useParams()
    const language = useLanguage()

    const { data: templateDocuments } = useDocumentTemplates(language, 12)
    const { data: templateFiles } = useDocumentTemplateFiles(language)

    const [currentlyExpandedDocumentId, setCurrentExpandedDocumentId] = useState<
        string | undefined
    >(templateId)

    const { connectedLaws } = useLawDocumentConnection(parseInt(currentlyExpandedDocumentId ?? '0'))

    return (
        <Container>
            <Header>{t('updatedTemplateRoutinesInLast12Months')}</Header>

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>{t('topic')}</Table.HeaderCell>
                        <Table.HeaderCell collapsing>{t('lastUpdated')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('version')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {templateDocuments?.map(x => (
                        <React.Fragment key={x.id}>
                            <Table.Row>
                                <Table.Cell collapsing>
                                    <Button
                                        icon={
                                            <SemanticFeatherIcon
                                                FeatherIcon={
                                                    currentlyExpandedDocumentId === x.id.toString()
                                                        ? ChevronDown
                                                        : ChevronRight
                                                }
                                                style={{ marginTop: '-2px' }}
                                            />
                                        }
                                        type="button"
                                        onClick={() =>
                                            setCurrentExpandedDocumentId(
                                                currentlyExpandedDocumentId === x.id.toString()
                                                    ? undefined
                                                    : x.id.toString(),
                                            )
                                        }
                                        size="tiny"
                                        compact
                                    />
                                </Table.Cell>
                                <Table.Cell>{x.name}</Table.Cell>
                                <Table.Cell>{formatDate(x.lastChanged)}</Table.Cell>
                                <Table.Cell collapsing>{x.version}</Table.Cell>
                            </Table.Row>
                            {currentlyExpandedDocumentId === x.id.toString() && (
                                <Table.Row>
                                    <Table.Cell colSpan="4">
                                        <Header>{x.name}</Header>
                                        <div dangerouslySetInnerHTML={{ __html: x.content }}></div>

                                        {!!connectedLaws?.length && (
                                            <DocumentSectionModule
                                                title={t('suggestedLawConnections')}
                                                icon={
                                                    <ModuleIcon
                                                        moduleId={MODULES.LAWS}
                                                        dark={true}
                                                        size={32}
                                                    />
                                                }
                                            >
                                                <Table basic="very">
                                                    <Table.Body>
                                                        {connectedLaws.map(c => (
                                                            <Table.Row key={c.id}>
                                                                <Table.Cell collapsing>
                                                                    {c.denomination}
                                                                </Table.Cell>
                                                                <Table.Cell>{c.name}</Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                    </Table.Body>
                                                </Table>
                                            </DocumentSectionModule>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </React.Fragment>
                    ))}
                </Table.Body>
            </Table>

            <Header>{t('updatedTemplateFiles')}</Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('routine')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('lastUpdated')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {templateFiles?.map(x => (
                        <Table.Row key={x.id}>
                            <Table.Cell collapsing>
                                <FileIcon file={x} />
                            </Table.Cell>
                            <Table.Cell>
                                <FileLink module="docs" file={x} />
                            </Table.Cell>
                            <Table.Cell>{x.documentName}</Table.Cell>
                            <Table.Cell collapsing>{formatDate(x.created)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Container>
    )
}

export default DocumentTemplatePage
