import React from "react";
import { RowRendererProps, Row } from "react-data-grid";
import styled from "styled-components";

interface GroupHeadingRowRendererContainerProps {
    depth?: number;
}

const GroupHeadingRowRendererContainer = styled.div<GroupHeadingRowRendererContainerProps>`
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    padding-left: ${props => ((props.depth ?? 0) * 10) + 5}px;
`;

const GroupHeadingRowRenderer: React.FunctionComponent<RowRendererProps<any>> = (props) => {
    const { row } = props;

    if (row.__metaData?.isGroup) {
        const title = props.viewportColumns.find(x => x.key === row.__metaData.columnGroupName)?.name 
            ?? row.__metaData.columnGroupName;
            
        return (
            <GroupHeadingRowRendererContainer depth={row.__metaData.treeDepth}>
                {title}: {row.name} {row.__metaData.childCount ? `(${row.__metaData.childCount})` : ''}
            </GroupHeadingRowRendererContainer>
        );
    } else {
        return <Row {...props} />;
    }
}

export default GroupHeadingRowRenderer;