import React, { useEffect, useState } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalHeader,
    ModalContent,
    Form,
    ModalActions,
    Button,
    InputOnChangeData,
    Input,
    TextArea,
} from 'semantic-ui-react'
import { Decision } from '../../../hooks/goals/decisions'
import DateInput, { DateOnChangeData } from '../../common/dateInput'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'

export interface DecisionsModalProps {
    selectedDecision?: Decision
    onSave?(decision: Decision): void
}

const DecisionsModal: React.FunctionComponent<DecisionsModalProps> = ({
    selectedDecision,
    onSave,
}) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [newDecision, setNewDecision] = useState<Decision>({})

    useEffect(() => {
        if (!selectedDecision) return

        setNewDecision(selectedDecision ?? {})
        setOpen(true)
    }, [selectedDecision])

    const onClose = () => {
        setNewDecision({})
        setOpen(false)
    }

    return (
        <Modal onClose={onClose} onOpen={() => setOpen(true)} open={open}>
            <ModalHeader>{newDecision.id ? t('updateDecision') : t('addDecision')}</ModalHeader>
            <ModalContent>
                <Form>
                    <Form.Group widths={2}>
                        <Form.Field
                            required
                            value={newDecision.opportunity ?? ''}
                            label={t('occasion')}
                            control={Input}
                            onChange={(_: Event, data: InputOnChangeData) =>
                                setNewDecision(d => ({ ...d, opportunity: data.value }))
                            }
                        />
                        <Form.Field
                            required
                            value={newDecision.date ?? ''}
                            label={t('date')}
                            control={DateInput}
                            onChange={(_: Event, data: DateOnChangeData) =>
                                setNewDecision(d => ({ ...d, date: data.value ?? undefined }))
                            }
                        />
                    </Form.Group>
                    <Form.Field
                        required
                        label={t('decision')}
                        value={newDecision.decision ?? ''}
                        control={TextArea}
                        onChange={(_: Event, data: InputOnChangeData) =>
                            setNewDecision(d => ({ ...d, decision: data.value }))
                        }
                    />
                </Form>
            </ModalContent>
            <ModalActions>
                <Button
                    disabled={
                        !newDecision.opportunity || !newDecision.date || !newDecision.decision
                    }
                    onClick={() => {
                        onClose()
                        onSave?.(newDecision)
                    }}
                    icon
                    labelPosition="left"
                    primary
                    type="button"
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </Button>
                <Button onClick={onClose} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </ModalActions>
        </Modal>
    )
}

export default DecisionsModal
