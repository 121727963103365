import { useCrud } from '../crud/use-crud'

export interface GoalActivity {
    id?: number
    status?: number
    activity?: string
    description?: string
    responsibleId?: string
    cost?: number
    time?: number
    doneBefore?: string
    doneDate?: string
    doneBy?: string
}

export const useGoalActivities = (goalId: string) => {
    const { fetch, create, remove, update } = useCrud<GoalActivity, GoalActivity>(
        `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${goalId}/activity`,
        `${window.env.REACT_APP_GOAL_SERVICE}/api/goal/${goalId}/activity`,
        'id',
        undefined,
        'POST',
    )

    return {
        loading: fetch.loading,
        remove: (id: number) => remove(id),
        data: (fetch.data || []) as GoalActivity[],
        error: fetch.error,
        save: (activity: GoalActivity) =>
            activity.id
                ? update(activity.id, activity, false, true)
                : create(activity, false, true),
    }
}
