import React from 'react'
import { Routes, Route } from 'react-router'
import ProjectDetailsPage from './detailPage'
import { Grid } from 'semantic-ui-react'
import ProjectMenu from './projectMenu'
import AdminTemplateDetailsPage from './adminTemplateDetailsPage'
import AdminTemplateListPage from './adminTemplateListPage'
import { isInPageRenderMode } from '../../../actions/util'
import ProjectList from './ProjectList'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import Loading from '../../common/Loading'
import * as API from '../../../api'

const ProjectsModule: React.FunctionComponent = () => {
    const { isLoading, data: projects, refresh } = useGetQueryByParams(true, API.projects.getAll, "projectList")
    if (isLoading || !projects) {
        return <Loading />
    }
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                {isInPageRenderMode() && (
                    <Grid.Column tablet={16} largeScreen={4} widescreen={3}>
                        <ProjectMenu />
                    </Grid.Column>
                )}
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route index element={<ProjectList projects={projects} refresh={refresh} />} />
                        <Route path="/admin/templates/">
                            <Route path=":templateId" element={<AdminTemplateDetailsPage />} />
                            <Route path="new" element={<AdminTemplateDetailsPage />} />
                            <Route index element={<AdminTemplateListPage />} />
                        </Route>

                        <Route path="/detail/">
                            <Route path="new" element={<ProjectDetailsPage isNew refreshProjectList={refresh} />} />
                            <Route path={`:projectId`} element={<ProjectDetailsPage isNew={false} refreshProjectList={refresh} />} />
                        </Route>
                    </Routes>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ProjectsModule
