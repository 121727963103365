import React from 'react'
import { getState } from '../../state'
import { getDepartmentFromState, getSupplierFromState, getLocationFromState } from '../../state/helpers'
import { useTranslation } from 'react-i18next'

export interface DepartmentDisplayProps {
    departmentId?: number | null
}

export const DepartmentDisplay: React.FunctionComponent<DepartmentDisplayProps> = ({ departmentId }) => {
    const { state } = getState()

    if (!departmentId) return null

    return <>{getDepartmentFromState(state)(departmentId)?.name}</>
}

export interface ISupplierDisplayProps {
    supplierId?: number | null
}

export const SupplierDisplay: React.FunctionComponent<ISupplierDisplayProps> = ({ supplierId }) => {
    const { state } = getState()

    if (!supplierId) return null

    return <>{getSupplierFromState(state)(supplierId)?.name}</>
}


export interface ILocationDisplayProps {
    locationId?: number | null
}

export const LocationDisplay = ({ locationId }: ILocationDisplayProps) => {
    const { state } = getState()
    const { t } = useTranslation()
    if (!locationId) return <>{t('notSpecified')}</>

    return <>{getLocationFromState(state)(locationId)?.name}</>
}
