import React, { useState } from 'react'
import { Button, ButtonProps } from 'semantic-ui-react'

interface ActionButtonProps extends ButtonProps {
    action: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        data: ButtonProps,
    ) => Promise<unknown>
    postActionCallback?: () => void
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
    action,
    postActionCallback,
    ...buttonProps
}) => {
    const [loading, setLoading] = useState<boolean>(false)

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
        event.preventDefault()

        setLoading(true)

        action(event, data)
            .finally(() => setLoading(false))
            .then(() => postActionCallback?.())
    }

    return <Button onClick={onClick} loading={loading} {...buttonProps} />
}

export default ActionButton
