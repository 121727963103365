import useSWR from 'swr'
import { DocumentListing } from './listing'
import { IViqmaDocument } from '../../api/documents'

export const useDocumentVersions = (documentId: string) =>
    useSWR<DocumentListing[], any>(
        `${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}/versions`,
    )

export const useDocumentVersion = (
    documentId: string,
    versionNumber: number | string | undefined,
) =>
    useSWR<IViqmaDocument, any>(
        !versionNumber
            ? null
            : `${window.env.REACT_APP_DOC_SERVICE}/api/document/${documentId}/versions/${versionNumber}`,
    )
