import styled from "styled-components";
import React from 'react';
import { Segment } from "semantic-ui-react";

export interface GridContainerProps {
    autoResize?: boolean,
    loading?: boolean
}

const GridContainer: React.FunctionComponent<GridContainerProps> = ({
    autoResize, 
    loading,
    children
}) => {

    const extraStyles = autoResize 
        ? {
            'position': 'relative',
            'top': 0,
            'left': 0,
            'right': 0,
            'bottom': 0,
            'display': 'flex',
            'flexDirection': 'column',
            'flexGrow': 1
        }
        : {};

    return (
        <Segment basic loading={loading} style={{'padding': 0, ...extraStyles}}>
            {children}
        </Segment>
    );
}

export default GridContainer;