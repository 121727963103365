import React, { FunctionComponent, useState, useEffect } from 'react'
import { isDevelopment } from '../../../actions/util'
import {
    AdminRoles,
    isInRole,
} from '../../../auth/roles'
import { handleTakePhotoOnIphone, useFileListing } from '../../../hooks/files'
import { useMobileMode } from '../../../hooks/mobileMode'
import { getState } from '../../../state'
import ErrorBox from '../errorBox'
import { addPendingFiles, removePendingFile, updatePendingFile } from './actions'
import { FilesDumb } from './filesDumb'
import { FileListing, PendingFile } from './types'

export type UploadAllPendingFilesCallback = (collectionId?: string) => Promise<any>
export interface FilesProps {
    module: string
    displayTitle?: boolean
    collectionId?: string
    mayAutoUpload?: boolean
    readOnly?: boolean
    enableDragDrop?: boolean
    disableDelete?: boolean
    includeToken?: boolean
    onFilesAdded?(files: PendingFile[], uploadAllPendingFiles: UploadAllPendingFilesCallback): void
}

const Files: FunctionComponent<FilesProps> = ({
    module,
    displayTitle,
    mayAutoUpload,
    collectionId,
    onFilesAdded,
    includeToken = true,
    readOnly = false,
    disableDelete = false,
    enableDragDrop = true,
}) => {
    const { isInMobileMode } = useMobileMode()
    const [pendingFiles, setPendingFiles] = useState<PendingFile[]>([])
    const {
        state: { userProfile },
    } = getState()

    const {
        data: files,
        loading,
        upload,
        deleteFile,
        error,
    } = useFileListing(module, collectionId, pendingFile => {
        setPendingFiles(pendingFiles => updatePendingFile(pendingFiles, pendingFile))
    })

    const doUpload = (pendingFile: PendingFile, collectionIdOverride?: string) =>
        upload(pendingFile, collectionIdOverride).then(() => {
            setPendingFiles(oldPendingFiles => removePendingFile(oldPendingFiles, pendingFile))
        })

    const uploadAllPending = (collectionIdArg?: string) => {
        const filesToUpload: PendingFile[] = pendingFiles
            .filter(f => f.status === 'pending')
            .map(file => ({ ...file, status: 'uploading' }))

        setPendingFiles(oldPendingFiles =>
            oldPendingFiles.map(file => ({ ...file, status: 'uploading' })),
        )

        return Promise.all(filesToUpload.map(f => doUpload(f, collectionIdArg ?? collectionId)))
    }

    const isPendingFile = (file: PendingFile) => file.status === 'pending'

    useEffect(() => {
        if (collectionId && mayAutoUpload && pendingFiles.length) {
            if (pendingFiles.some(isPendingFile)) {
                uploadAllPending()
            }
        }
    }, [collectionId, pendingFiles, mayAutoUpload, uploadAllPending])

    useEffect(() => {
        onFilesAdded?.(pendingFiles, (collectionId?: string) => uploadAllPending(collectionId))
    }, [pendingFiles.length])

    const handleFilesPicked = (files: File[]) => {
        const newPendingFiles: PendingFile[] = []
        const fileArray = [...files]

        fileArray.forEach(f => {
            const file = handleTakePhotoOnIphone(f)
            const existingFile = pendingFiles.find(x => x.file.name === file.name)

            if (!existingFile) {
                const pendingFile: PendingFile = {
                    status: 'pending',
                    file: file,
                    bytesUploaded: 0,
                }
                newPendingFiles.push(pendingFile)
            }
        })
        setPendingFiles(pendingFiles => addPendingFiles(pendingFiles, newPendingFiles))
    }

    const canDeleteFiles = () => {
        if (disableDelete) return false

        if (module === 'Projects') return isInRole(userProfile, AdminRoles.ProjectsAdminRole)
        if (module === 'Documents') return isInRole(userProfile, AdminRoles.DocumentsAdminRole)
        if (module === 'ImprovementIdeas') return isInRole(userProfile, AdminRoles.ImprovementsAdmin)
        if (module === 'ImprovementActivities') return isInRole(userProfile, AdminRoles.ImprovementsAdmin)
        if (module === 'Goals') return isInRole(userProfile, AdminRoles.GoalsAdminRole)
        if (module === 'Issues') return isInRole(userProfile, AdminRoles.IssuesAdminRole)
        if (module === 'IssuesAccidents') return isInRole(userProfile, AdminRoles.IssuesAdminRole)
        if (module === 'CompetenceUser') return isInRole(userProfile, AdminRoles.CompetenceAdmin)
        if (module === "supplier-external") return isInRole(userProfile, AdminRoles.SupplierAdminRole)
        if (module === "supplier-internal") return isInRole(userProfile, AdminRoles.SupplierAdminRole)
        if (module === "supplier-actionPlan") return isInRole(userProfile, AdminRoles.SupplierAdminRole)


        if (isDevelopment()) console.log('canDeleteFiles Not implemented!!!!')

        return false
    }

    const onFileUnstaged = (pendingFile: PendingFile) => {
        setPendingFiles(oldPendingFiles => removePendingFile(oldPendingFiles, pendingFile))
    }

    return (
        <div className={files?.length === 0 ? "hide-in-print" : ''}>
            <ErrorBox error={error} />

            <FilesDumb
                module={module}
                displayTitle={displayTitle}
                files={files ?? []}
                pendingFiles={pendingFiles}
                loading={loading}
                readOnly={readOnly}
                enableDragDrop={enableDragDrop}
                handleFilesPicked={handleFilesPicked}
                canDeleteFiles={canDeleteFiles}
                onFileDeleted={deleteFile}
                onFileUnstaged={onFileUnstaged}
                isInMobileMode={isInMobileMode}
                includeToken={includeToken}
            />
        </div>
    )
}

export type { FileListing, PendingFile }
export { FilesDumb }
export { guessIconFromName } from './common'
export { FileIcon, FileLink } from './fileRow'

export default Files
