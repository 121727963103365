import React from 'react'
import { Route, Routes } from 'react-router'
import { useMobileMode } from '../../hooks/mobileMode'

import ChemicalInfo from '../chemicals/info'
import MobilePageContainer from '../global/mobilePageContainer'
import AddImprovementSuggestion from '../modules/improvements/addSuggestion'
import ObjectCasesPage from '../objects/cases'
import NewObjectCase from '../objects/newCase'
import MySkills from '../modules/skills/mobile/mySkils'
import MobileSystematicTaskForm from '../systematicTasks/MobileSystematicTaskForm'
import MobileSwitchBar from './mobile/mobileSwitchBar'
import MobileStartPage from './mobile/startPage'
import SearchPage from './searchPage'
import StartPage from './startPage'
import DocsModule from '../modules/docs'
import IssueForm from '../forms/IssueForm'
import AccidentForm from '../forms/AccidentForm'
import * as API from '../../api'
import { useGetQueryByParams } from '../../hooks/useGetQueryByParams'
import Loading from '../common/Loading'

const StartModule: React.FunctionComponent = () => {
    const { isInMobileMode } = useMobileMode()
    const {
        isLoading: isLoadingResponsibles,
        data: responsibleSafetyUsers,
    } = useGetQueryByParams(true, API.issues.getUserSettings, "responsibleSafetyUsers")
    if (isLoadingResponsibles || !responsibleSafetyUsers) {
        return <Loading />
    }
    return (
        <>
            <MobileSwitchBar />

            <Routes>
                {isInMobileMode && (
                    <Route path="mobile" element={<MobilePageContainer />}>
                        <Route path="issues/add" element={<IssueForm responsibleSafetyUsers={responsibleSafetyUsers} isNew />} />
                        <Route path="accident/add" element={<AccidentForm responsibleSafetyUsers={responsibleSafetyUsers} isNew />} />
                        <Route path="chemicals" element={<ChemicalInfo />} />
                        <Route
                            path="improvement/suggestion/add"
                            element={<AddImprovementSuggestion />}
                        />
                        <Route path="skills/me" element={<MySkills />} />
                        <Route path="objects/cases" element={<ObjectCasesPage />} />
                        <Route path="objects/cases/new" element={<NewObjectCase />} />
                        <Route path="docs/*" element={<DocsModule />} />
                        <Route path="systematic-tasks/:id" element={<MobileSystematicTaskForm />} />
                    </Route>
                )}

                <Route path="/" element={isInMobileMode ? <MobileStartPage /> : <StartPage />} />

                <Route path="/search" element={<SearchPage />} />
                <Route path="/search/:searchPhrase" element={<SearchPage />} />
            </Routes>
        </>
    )
}

export default StartModule
