import React from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { getState } from '../../state'

const LocationDropdown: React.FunctionComponent<DropdownProps> = ({ ...dropdownProps }) => {
    const {
        state: { locations },
    } = getState()

    const options = locations?.map(d => ({ value: d.id, text: d.name })) ?? []

    return <Dropdown {...dropdownProps} options={options} />
}

export default LocationDropdown
