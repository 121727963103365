import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React from 'react';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as API from '../../../../../api'
import { ICompetenceUserPositionBatchRequest, ICompetenceUserPositionDto } from '../../../../../api/skills';
import FormActions from '../../../FormActions';
import ControlCheckbox from '../../../../fields/ControlCheckbox';
import ControlDropdown from '../../../../fields/ControlDropdown';
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams';
import Loading from '../../../../common/Loading';

export interface IUserPositionBatchFormProps {
    userId: string
    userPositions: ICompetenceUserPositionDto[]
    onCancel: () => void
    refresh: () => void
}

export const userPositionBatchRequestSchema = z.object({
    isRegular: z.boolean(),
    positionIds: z.array(z.number()).min(1, "required"),
});

const UserPositionBatchForm = ({ userId, userPositions, onCancel, refresh }: IUserPositionBatchFormProps) => {
    const { t } = useTranslation();
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<ICompetenceUserPositionBatchRequest>({
        defaultValues: { positionIds: [], isRegular: false },
        resolver: zodResolver(userPositionBatchRequestSchema),
    });
    const {
        isLoading,
        data,
    } = useGetQueryByParams(true, API.skills.getPositions, "competenceUserposition")

    const onPositionRequest = async (data: ICompetenceUserPositionBatchRequest) => await API.skills.batchCompetenceUserPosition(userId, data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onPositionRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: () => {
            toast.success(t("saved"))
            onCancel()
            refresh()
        },
    })
    if (isLoading || !data) {
        return <Loading />
    }
    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown
                            name="positionIds"
                            label={t('skillPositions')}
                            popupText={t('addMultiValue', { value: t('skillPositions').toLowerCase() })}
                            control={control}
                            disabled={isSaving}
                            multiple
                            required
                            collection={data.companyPositions.filter(x => !userPositions.some(p => p.id === x.id)).map(d => ({ value: d.id, text: d.name })) ?? []} />
                    </GridColumn>
                    <GridColumn verticalAlign='middle'>
                        <ControlCheckbox name="isRegular" label={t('isRegular')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default UserPositionBatchForm;