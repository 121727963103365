import { AxiosRequestConfig, AxiosResponse } from "axios"
import { getAuthedAxios } from "../auth"

function handleError(error: any) {
  if (error.response?.status === 401) {
    console.log("unauthorized")
  }
  return error
}

export async function get<T>(resourceEndpointPath: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  try {
    const axios = await getAuthedAxios()
    return await axios.get(resourceEndpointPath, config)
  } catch (error) {
    throw handleError(error)
  }
}

export async function post<T>(resourceEndpointPath: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  try {
    const axios = await getAuthedAxios()
    return await axios.post(resourceEndpointPath, data, config)
  } catch (error) {
    throw handleError(error)
  }
}

export async function put<T>(resourceEndpointPath: string, data?: any): Promise<AxiosResponse<T>> {
  try {
    const axios = await getAuthedAxios()
    return await axios.put(resourceEndpointPath, data)
  } catch (error) {
    throw handleError(error)
  }
}

export async function destroy<T>(resourceEndpointPath: string): Promise<AxiosResponse<T>> {
  try {
    const axios = await getAuthedAxios()
    return await axios.delete(resourceEndpointPath)
  } catch (error) {
    throw handleError(error)
  }
}
