import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dropdown,
    Form,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    Popup
} from 'semantic-ui-react'
import { usePositionOptions } from '../../../../hooks/positions'
import { Edit, Save, ArrowLeftCircle } from 'react-feather'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import ActionButton from '../../../common/actionButton'

export interface ResponbileSelectorProps {
    value?: number[]
    onChange: (body: number[]) => Promise<any>
}

const ResponsibleSelector: React.FunctionComponent<ResponbileSelectorProps> = props => {
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()
    return (
        <>
            <Popup
                content={t('configure responsible')}
                trigger={
                    <SemanticFeatherIcon
                        FeatherIcon={Edit}
                        onClick={() => setIsOpen(true)}
                        cursor="pointer"
                    />
                }
            />
            <Modal
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                closeOnDimmerClick={false}
                size="small"
            >
                <ResponsibleSelectorInner {...props} close={() => setIsOpen(false)} />
            </Modal>
        </>
    )
}

interface ResponbileSelectorInnerProps extends ResponbileSelectorProps {
    close: () => void
}

const ResponsibleSelectorInner: React.FunctionComponent<ResponbileSelectorInnerProps> = ({
    value: initialValue,
    onChange,
    close,
}) => {
    const { t } = useTranslation()
    const options = usePositionOptions()
    const [value, setValue] = useState(initialValue ?? [])

    return (
        <>
            <ModalHeader>{t('configure responsible')}</ModalHeader>
            <ModalContent>
                <Form>
                    <Form.Field>
                        <label>{t('responsiblePosition')}</label>

                        <Dropdown
                            options={options}
                            value={value}
                            onChange={(_, x) => setValue(x.value as number[])}
                            fluid
                            multiple
                            search
                            selection
                        />
                    </Form.Field>
                </Form>
            </ModalContent>
            <ModalActions>
                <ActionButton
                    icon
                    labelPosition="left"
                    type="button"
                    action={() => onChange(value).then(close)}
                    primary
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />
                    {t('save')}
                </ActionButton>

                <Button icon labelPosition="left" type="button" onClick={() => close()}>
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />
                    {t('cancel')}
                </Button>
            </ModalActions>
        </>
    )
}

export default ResponsibleSelector
