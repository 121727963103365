import React from 'react'
import { useTranslation } from 'react-i18next'
import { Header, Message } from 'semantic-ui-react'
import { decodeErrorObject } from '../../actions/error'

export interface ErrorBoxProps {
    error: any
}

const ErrorBox: React.FunctionComponent<ErrorBoxProps> = ({ error }) => {
    const { t } = useTranslation()

    if (!error) return null

    const { topic, message } = decodeErrorObject(error)
    return (
        <Message error>
            {!!topic && <Header>{t(topic)}</Header>}
            {t(message)}
        </Message>
    )
}

export default ErrorBox
