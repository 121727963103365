import React from 'react'
import { useDrag } from 'react-dnd'
import { HeaderRendererProps } from 'react-data-grid'

export interface ColumnDragObject {
    key: string
    name: string
}

export function DraggableHeaderRenderer<R>({ ...props }: HeaderRendererProps<R>) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'COLUMN_DRAG',
        item: { key: props.column.key, name: props.column.name },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }))

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
            }}
        >
            {props.column.name}
        </div>
    )
}
