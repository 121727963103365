import React from 'react'
import { Routes, Route } from 'react-router'
import { Grid } from 'semantic-ui-react'
import { isInPageRenderMode } from '../../../actions/util'
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams'
import Loading from '../../common/Loading'
import * as API from '../../../api'
import IssueList from './IssueList'
import IssueFormPage from './IssueFormPage'
import AccidentList from './AccidentList'
import IssueMenu from './IssueMenu'
import AccidentFormPage from './AccidentFormPage'
import ModuleSettingsPage from './ModuleSettingsPage'
import IssueStatisticPage from './IssueStatisticPage'

const IssuesModule: React.FunctionComponent = () => {
    const { isLoading, data, refresh } = useGetQueryByParams(true, API.issues.getAll, "deviationsList")
    const { isLoading: isLoadingAccidents, data: accidents, refresh: refreshAccidents } = useGetQueryByParams(true, API.issues.getAllAccidents, "accidentsList")
    const {
        isLoading: isLoadingTypes,
        data: types,
        refresh: refreshTypeHierarchy
    } = useGetQueryByParams(true, API.issues.getDeviationTypesHierarchy, "deviationTypesHierarchy")

    const {
        isLoading: isLoadingResponsibles,
        data: responsibleSafetyUsers,
    } = useGetQueryByParams(true, API.issues.getUserSettings, "responsibleSafetyUsers")

    if (isLoading || !data || isLoadingAccidents || !accidents || isLoadingTypes || !types || isLoadingResponsibles || !responsibleSafetyUsers) {
        return <Loading />
    }
    return (
        <Grid
            stackable
            columns={isInPageRenderMode() ? 2 : 1}
            style={{ minHeight: '100%', flexGrow: 1 }}
        >
            <Grid.Row>
                {isInPageRenderMode() && (
                    <Grid.Column tablet={16} largeScreen={4} widescreen={3}>
                        <IssueMenu />
                    </Grid.Column>
                )}
                <Grid.Column
                    tablet={16}
                    largeScreen={isInPageRenderMode() ? 12 : 16}
                    widescreen={isInPageRenderMode() ? 13 : 16}
                >
                    <Routes>
                        <Route index element={<IssueList issues={data} types={types} refresh={refresh} />} />
                        <Route path="/detail/">
                            <Route path="new" element={<IssueFormPage responsibleSafetyUsers={responsibleSafetyUsers} isNew refresh={refresh} />} />
                            <Route path={`:deviationId`} element={<IssueFormPage responsibleSafetyUsers={responsibleSafetyUsers} isNew={false} refresh={refresh} />} />
                        </Route>
                        <Route path="/accidents/">
                            <Route index element={<AccidentList accidents={accidents} refresh={refreshAccidents} />} />
                            <Route path="new" element={<AccidentFormPage responsibleSafetyUsers={responsibleSafetyUsers} isNew refresh={refreshAccidents} />} />
                            <Route path={`:accidentId`} element={<AccidentFormPage responsibleSafetyUsers={responsibleSafetyUsers} isNew={false} refresh={refreshAccidents} />} />
                        </Route>
                        <Route path="/admin/settings/">
                            <Route index element={<ModuleSettingsPage types={types} refresh={refreshTypeHierarchy} />} />
                        </Route>
                        <Route path="/admin/statistics/">
                            <Route index element={<IssueStatisticPage />} />
                        </Route>
                    </Routes>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default IssuesModule
