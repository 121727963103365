import React from 'react'
import { Edit, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Container, Header, Loader, Table } from 'semantic-ui-react'
import { useSurveys } from '../../../hooks/surveys/survey'
import CustomConfirm from '../../common/customConfirm'
import SemanticFeatherIcon from '../../icons/SemanticFeatherIcon'

const SurveyAdminListPage: React.FunctionComponent = () => {
    const { t } = useTranslation()
    const { data, isValidating } = useSurveys()

    return (
        <Container>
            <Header as="h2">{t('surveys')}</Header>

            <Loader active={!data && isValidating} />

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data?.map(x => (
                        <Table.Row key={x.id}>
                            <Table.Cell>{x.name}</Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    size="mini"
                                    icon={
                                        <SemanticFeatherIcon
                                            FeatherIcon={Edit}
                                            style={{ marginTop: '-3px' }}
                                        />
                                    }
                                    onClick={() => {}}
                                    type="button"
                                />

                                <CustomConfirm
                                    text={t('sureYouWantToDeleteDocument')}
                                    trigger={
                                        <Button
                                            size="mini"
                                            icon={
                                                <SemanticFeatherIcon
                                                    FeatherIcon={Trash2}
                                                    style={{
                                                        marginTop: '-3px',
                                                        marginLeft: '-1px',
                                                    }}
                                                />
                                            }
                                            negative
                                            type="button"
                                        />
                                    }
                                    onConfirm={() => {}}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Container>
    )
}

export default SurveyAdminListPage
