import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Dropdown,
    DropdownProps,
    Form,
    FormButton,
    FormField,
    FormGroup,
    Header,
    Message,
    Segment,
    TextArea,
    TextAreaProps,
} from 'semantic-ui-react'
import EditDocument from './editDocument'
import { Editor } from 'tinymce/tinymce'
import { useUserInRoleOptions } from '../../../../../hooks/users'
import {
    DocumentSuggestionRequest,
    DocumentSuggestionUpdateRequest,
} from './types/suggestionChangesTypes'
import { getAuthedAxios } from '../../../../../auth'
import { AdminRoles, isInRole } from '../../../../../auth/roles'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { Save } from 'react-feather'
import { toast } from 'react-toastify'
import { getState } from '../../../../../state'
import ActionButton from '../../../../common/actionButton'

export type ReviewState = 'idle' | 'requesting' | 'success' | 'error'

export interface CreateSuggestedChangeDumbProps {
    title: string
    defaultContent: string
    reviewState?: ReviewState
    onReviewRequested: (suggestedChange: DocumentSuggestionRequest) => Promise<void>
    onAbort: () => void
    editingSuggestionId?: number
    isAdmin: boolean
    isConcernDocument: boolean
}

export const CreateSuggestedChangeDumb: FunctionComponent<CreateSuggestedChangeDumbProps> = ({
    title,
    defaultContent,
    onReviewRequested,
    reviewState: requestState = 'idle',
    onAbort,
    editingSuggestionId,
    isAdmin,
    isConcernDocument,
}) => {
    const { t } = useTranslation()
    const {
        state: { userProfile, concern },
    } = getState()

    const [editor, setEditor] = useState<Editor>()
    const [editText, setEditText] = useState<string>(defaultContent)
    const [approver, setApprover] = useState<string>('')
    const [comment, setComment] = useState<string>('')
    const { userOptions } = useUserInRoleOptions(
        AdminRoles.DocumentsAdminRole,
        u => u.active,
        isConcernDocument,
    )

    const isViqmaAdmin = isInRole(userProfile, AdminRoles.ViqmaAdminRole)

    const displayDirectApproveButton =
        ((isAdmin && !isConcernDocument) || isViqmaAdmin) && requestState !== 'success'

    const onSubmit = async (autoApprove: boolean) => {
        if (requestState !== 'success') {
            if (editor) {
                await editor.uploadImages()
            }

            await onReviewRequested?.({
                approver,
                comment,
                content: editor?.getContent() ?? editText,
                autoApprove,
            })
        } else {
            onAbort()
        }
    }

    return (
        <>
            <EditDocument
                initialValue={defaultContent}
                onChange={c => setEditText(c)}
                readOnly={requestState === 'success'}
                onEditorInit={editor => setEditor(editor)}
                imageUploadSettings={
                    isConcernDocument && concern?.id
                        ? {
                            overrideCompanyId: concern.id,
                        }
                        : undefined
                }
            />
            <Segment>
                {editingSuggestionId ? (
                    <ActionButton
                        action={() => onSubmit(false)}
                        icon
                        labelPosition="left"
                        primary
                        type="button"
                    >
                        <SemanticFeatherIcon
                            FeatherIcon={Save}
                            size={'60%'}
                            centerIcon={true}
                        />{' '}
                        {t('save')}
                    </ActionButton>
                ) : (
                    <Segment vertical>
                        <Header
                            as="h4"
                            content={t('sendForApproval')}
                        />
                        <Form loading={requestState === 'requesting'}>
                            <FormField
                                width="8"
                                label={t('sendTo')}
                                control={Dropdown}
                                selection
                                search
                                value={approver}
                                options={userOptions}
                                onChange={(_: any, d: DropdownProps) =>
                                    setApprover(d.value as string)
                                }
                            />
                            <FormField
                                control={TextArea}
                                label={t('comment')}
                                placeholder={t('commentYourChanges')}
                                value={comment}
                                onChange={(_: any, d: TextAreaProps) =>
                                    setComment(d.value as string)
                                }
                            />
                            <Message
                                visible={requestState === 'error'}
                                error
                                header="Error"
                                content="Some error occured, try again"
                            />
                            <FormGroup inline>
                                <FormButton
                                    primary
                                    content={requestState !== 'success' ? t('send') : t('ok')}
                                    disabled={!approver || !comment}
                                    onClick={() => onSubmit(false)}
                                    type="button"
                                />
                                {displayDirectApproveButton && (
                                    <ActionButton
                                        primary
                                        content={t('directApproval')}
                                        disabled={
                                            !approver ||
                                            (!isViqmaAdmin && approver !== userProfile?.sub)
                                        }
                                        action={() => onSubmit(true)}
                                        type="button"
                                    />
                                )}
                            </FormGroup>
                        </Form>
                    </Segment>
                )}
            </Segment>
        </>
    )
}

export interface CreateSuggestedChangeProps {
    onAbort(): void
    onStateInvalid(): void
    documentId: string
    title: string
    defaultContent: string
    editingSuggestionId?: number
    isAdmin: boolean
    isConcernDocument: boolean
}

export const CreateSuggestedChange: FunctionComponent<CreateSuggestedChangeProps> = ({
    onAbort,
    onStateInvalid,
    documentId,
    title,
    defaultContent,
    editingSuggestionId,
    isAdmin,
    isConcernDocument,
}) => {
    const { t } = useTranslation()
    const [reviewState, setReviewState] = useState<ReviewState>()

    const requestReview = async ({
        approver,
        comment,
        content,
        autoApprove,
    }: DocumentSuggestionRequest) => {
        setReviewState('requesting')
        const axios = await getAuthedAxios()

        const dto = editingSuggestionId
            ? ({ content } as DocumentSuggestionUpdateRequest)
            : ({
                approver,
                content,
                comment,
                autoApprove,
            } as DocumentSuggestionRequest)

        const url = editingSuggestionId
            ? `${window.env.REACT_APP_DOC_SERVICE
            }/api/Document/${documentId}/suggestion/${editingSuggestionId!}`
            : `${window.env.REACT_APP_DOC_SERVICE}/api/Document/${documentId}/suggestion`

        axios
            .post(url, dto)
            .then(() => {
                toast.success(editingSuggestionId ? t('changesSaved') : t('hasBeenSend'))
                onStateInvalid()
            })
            .catch(err => {
                setReviewState('error')
            })
    }
    return (
        <CreateSuggestedChangeDumb
            title={title}
            defaultContent={defaultContent}
            reviewState={reviewState}
            onReviewRequested={requestReview}
            onAbort={onAbort}
            editingSuggestionId={editingSuggestionId}
            isAdmin={isAdmin}
            isConcernDocument={isConcernDocument}
        />
    )
}

export default CreateSuggestedChange
