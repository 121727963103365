import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { getState } from '../../../../../state';
import ControlInput from '../../../../fields/ControlInput';
import * as API from '../../../../../api'
import { handleAxiosError } from '../../../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IKeyFigureDto } from '../../../../../api/suppliers';
import FormActions from '../../../FormActions';
import { mockedKeyFigure } from '../../../../../utils/MockedData/Suppliers';
import { hasSupplierConcernModule } from '../../../../../auth/modules';

interface IKeyFigureFormProps {
    isNew: boolean
    onCancel: () => void
    refresh: () => void
    selectedKeyFigure?: IKeyFigureDto
    supplierId: number
}

export const keyFigureSchema = z.object({
    id: z.number(),
    companyId: z.number().nullable(),
    supplierId: z.number().nullable(),
    keyFigure: z.string().min(1, "required"),
    value: z.coerce.number().nullable(),
    unit: z.string().min(1, "required"),
    year: z.coerce.number().nullable(),
});

const KeyFigureForm = ({ refresh, onCancel, isNew, selectedKeyFigure, supplierId }: IKeyFigureFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()
    const { control, handleSubmit } = useForm<IKeyFigureDto>({
        resolver: zodResolver(keyFigureSchema),
        defaultValues: selectedKeyFigure ? selectedKeyFigure : mockedKeyFigure()
    });

    const onKeyFigureRequest = async (data: IKeyFigureDto) => {
        if (isNew) {
            return await API.suppliers.createKeyFigure(supplierId, data)
        } else {
            return await API.suppliers.editKeyFigure(supplierId, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onKeyFigureRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                toast.success(t("saved"))
                refresh()
                onCancel()
            }

        },
    })

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="keyFigure" label={t('keyFigure')} required control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="value" label={t('value')} type='number' control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="unit" label={t('unit')} required control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlInput name="year" type='number' label={t('year')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn>
                        {!hasSupplierConcernModule() && <FormActions
                            onCancel={() => onCancel()}
                            disabled={isSaving}
                        />}
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default KeyFigureForm;
