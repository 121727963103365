import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Header, Popup } from 'semantic-ui-react'
import {
    ColumnDef, ColumnFiltersState, ExpandedState, GroupingState, PaginationState, SortingState, VisibilityState, createColumnHelper, getCoreRowModel,
    getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel,
    getGroupedRowModel, getPaginationRowModel, getSortedRowModel, useReactTable
} from '@tanstack/react-table'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Bell, Edit } from 'react-feather'
import { getUserFromState } from '../../../../state/helpers'
import { getState } from '../../../../state'
import UserNameDisplay from '../../../common/UserNameDisplay'
import { formatDate } from '../../../../actions/util'
import DateCell from '../../../common/cells/DateCell'
import { EnumMultiFilterSelect, enumMultiFilter } from '../../../wrappers/TableReact/Filters/EnumMultiFilter'
import useTableStorageBackedState from '../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { globalFilterFn } from '../../../wrappers/TableReact/Filters/GlobalFilter'
import TableReact from '../../../wrappers/TableReact'
import { issueActivityStatusRowClass } from '../../../wrappers/TableReact/RowClassHelpers'
import * as API from '../../../../api'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import Loading from '../../../common/Loading'
import ModalWrapper from '../../../wrappers/ModalWrapper'
import ActivityForm from '../ActivityForm'
import { IDropdownOption } from '../../../fields/ControlDropdown'
import { IActivityDto } from '../../../../api/issues'
import { useMutation } from 'react-query'
import { handleAxiosError } from '../../../../actions/error'
import { toast } from 'react-toastify'
import { AxiosResponse } from 'axios'
import { IssueStatus } from '../../../../api/types/Enum'

export interface IActivityListLocalStorageKeysProps {
    visibility: string
    grouping: string
    pagination: string
    show: string
    global: string
    sorting: string
    columnFilters: string
    expanded: string
}

export interface IActivityListApiFunctions {
    listKey: string
    getActivitiesById: (id: number) => Promise<AxiosResponse<API.issues.IActivityDto[], any>>
    notifyActivity: (issueId: number, activityId: number) => Promise<AxiosResponse<unknown, any>>
    editActivity: (issueId: number, activityId: number, data: IActivityDto) => Promise<AxiosResponse<unknown, any>>
    createActivity: (id: number, data: IActivityDto) => Promise<AxiosResponse<unknown, any>>
}

export interface IActivityListProps {
    deviationId: number
    userOptions: IDropdownOption[]
    localStorageKeys: IActivityListLocalStorageKeysProps
    apiFunctions: IActivityListApiFunctions
    notifyClosingTranslationKey: string
    notifyCreationTranslationKey: string
}
const defaultHiddenColumns: { [x: string]: boolean; } = {
    "description": false,
    "doneBy": false,
    "doneDate": false,
}
interface INotifyActivityData {
    issueId: number
    activityId: number
}

const ActivityList: React.FunctionComponent<IActivityListProps> = ({
    deviationId,
    userOptions,
    localStorageKeys,
    apiFunctions,
    notifyClosingTranslationKey,
    notifyCreationTranslationKey
}) => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()
    const [open, setOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState<IActivityDto | null>(null);
    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, apiFunctions.getActivitiesById, apiFunctions.listKey, deviationId)

    const onActivityRemind = async (data: INotifyActivityData) => await apiFunctions.notifyActivity(data.issueId, data.activityId)

    const { isLoading: isSending, mutate: onNotify } = useMutation(onActivityRemind, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>(localStorageKeys.visibility, defaultHiddenColumns);
    const [grouping, setGrouping] = useTableStorageBackedState<GroupingState>(localStorageKeys.grouping, []);
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>(localStorageKeys.pagination, { pageSize: 10, pageIndex: 0 });
    const [showAll, setShowAll] = useTableStorageBackedState(localStorageKeys.show, false);
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>(localStorageKeys.columnFilters, []);
    const [globalFilter, setGlobalFilter] = useTableStorageBackedState(localStorageKeys.global, "");
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>(localStorageKeys.sorting, []);
    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>(localStorageKeys.expanded, {})

    const onCancel = () => {
        setOpen(false)
        setSelectedActivity(null)
    }

    const handleSetSelectedActivity = (activity: IActivityDto) => {
        setOpen(true)
        setSelectedActivity(activity)
    }

    const columnHelper = createColumnHelper<API.issues.IActivityDto>()
    const activityColumns: ColumnDef<API.issues.IActivityDto, any>[] = useMemo(
        () => [
            columnHelper.display({
                id: "actions",
                enableResizing: false,
                enableHiding: false,
                enableGrouping: false,
                size: 95,
                minSize: 95,
                maxSize: 95,
                cell: ({ row }) =>
                    <>
                        <Button title={t('edit')} type='button' onClick={() => handleSetSelectedActivity(row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                        <Popup
                            content={!getUserFromState(state)(row.original.responsibleId)?.active ? t('disabledResponsibleReminder') : t('sendReminder')}
                            trigger={
                                <span>
                                    <Button type='button'
                                        onClick={() => onNotify({ issueId: deviationId, activityId: row.original.id })}
                                        icon={<SemanticFeatherIcon FeatherIcon={Bell} />}
                                        disabled={!getUserFromState(state)(row.original.responsibleId)?.active}
                                        loading={isSending} />
                                </span>
                            }
                        />
                    </>
            }),
            columnHelper.accessor((r) => t(`issueStatus.${r.status}`), {
                id: "status",
                header: t('status'),
                filterFn: enumMultiFilter,
                size: 80,
                meta: {
                    filterComponent: (setFilterValue: (updater: string | IssueStatus[]) => void, value: IssueStatus[]) => (
                        <EnumMultiFilterSelect
                            setFilterValue={setFilterValue}
                            value={value}
                            Enum={IssueStatus}
                            enumLangKey="issueStatus"
                        />
                    ),
                },
                cell: ({ row }) => <span>{t(`issueStatus.${row.original.status}`)}</span>
            }),
            columnHelper.accessor((r) => getUserFromState(state)(r.responsibleId)?.name ?? "", {
                id: "responsibleId",
                header: t('responsibleId'),
                size: 100,
                cell: ({ row }) => <UserNameDisplay
                    userId={row.original.responsibleId ?? undefined}
                    fallbackValue={t('userFromAnotherCompany')}
                />
            }),
            columnHelper.accessor('activity', {
                id: "activity",
                header: t('activity'),
                size: 500,
            }),
            columnHelper.accessor("description", {
                id: "description",
                header: t('description'),
                size: 80,
            }),
            columnHelper.accessor("doneBy", {
                id: "doneBy",
                header: t('doneBy'),
                size: 80,
            }),
            columnHelper.accessor(r => r.doneDate ? formatDate(r.doneDate) : "", {
                id: "doneDate",
                header: t('doneDate'),
                cell: ({ row }) => <DateCell date={row.original.doneDate} />
            }),
            columnHelper.accessor(r => r.doneBefore ? formatDate(r.doneBefore) : "", {
                id: "doneBefore",
                header: t('doneBefore'),
                cell: ({ row }) => <DateCell date={row.original.doneBefore} />
            }),

        ], [columnHelper, state, t, deviationId, isSending, onNotify])
    const table = useReactTable({
        data: data ?? [],
        columns: activityColumns,
        columnResizeMode: "onChange",
        state: {
            columnVisibility,
            grouping,
            pagination,
            columnFilters,
            globalFilter,
            sorting,
            expanded
        },
        initialState: {
            globalFilter: "",
            columnFilters: [{ id: "status", value: [IssueStatus.Active] }],
            grouping: [],
            sorting: [{ id: "doneBefore", desc: false }],
        },
        globalFilterFn: globalFilterFn,
        onExpandedChange: setExpanded,
        onGroupingChange: setGrouping,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onColumnVisibilityChange: setColumnVisibility,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading) {
        return <Loading />
    }
    return (
        <>
            <Header dividing content={t('activity')} />
            <TableReact table={table}
                onAddCallback={() => setOpen(true)}
                rowClassRenderer={(r) => issueActivityStatusRowClass(r)}
                canExport={false}
                overflowable
                showAllFilter={{ showAll, setShowAll, checked: [{ id: "status", value: [] }], notChecked: [{ id: "status", value: [IssueStatus.Active] }] }} />
            <ModalWrapper title={t('registerNew')} open={open} onClose={onCancel}>
                <ActivityForm
                    onCancel={onCancel}
                    userOptions={userOptions}
                    selectedActivity={selectedActivity}
                    issueId={deviationId}
                    refresh={refresh}
                    apiFunctions={apiFunctions}
                    notifyClosingTranslationKey={notifyClosingTranslationKey}
                    notifyCreationTranslationKey={notifyCreationTranslationKey} />
            </ModalWrapper>
        </>
    )
}

export default ActivityList
