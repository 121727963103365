import React, { ReactNode, useState } from 'react'
import { Save, ArrowLeftCircle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Button, Form, FormDropdown, Modal } from 'semantic-ui-react'
import { useDocumentListingOptions } from '../../../../hooks/docs/listing'
import { ReadConfirmationCaseCreateDto } from '../../../../hooks/docs/readConfirmation'
import { usePositionOptions } from '../../../../hooks/positions'
import ActionButton from '../../../common/actionButton'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'

const defaultValues = { documentId: -1, positionIds: [] }

export interface CreateReadConfirmationModalProps {
    title: string
    trigger: ReactNode
    onSave: (data: ReadConfirmationCaseCreateDto) => Promise<void>
    requireDocumentId?: boolean
}

const CreateReadConfirmationModal: React.FunctionComponent<CreateReadConfirmationModalProps> = ({
    title,
    trigger,
    onSave,
    requireDocumentId = true,
}) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [readConfirmationCase, setReadConfirmationCase] = useState<ReadConfirmationCaseCreateDto>(
        { ...defaultValues },
    )

    const positionOptions = usePositionOptions()
    const documentOptions = useDocumentListingOptions()

    const cancel = () => {
        setReadConfirmationCase({ ...defaultValues })
        setIsOpen(false)
    }

    const save = () => onSave(readConfirmationCase).then(cancel)

    return (
        <Modal
            trigger={trigger}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            closeOnDimmerClick={false}
            size="small"
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    {requireDocumentId && (
                        <FormDropdown
                            label={t('routine')}
                            options={documentOptions}
                            fluid
                            selection
                            search
                            value={readConfirmationCase.documentId}
                            onChange={(_, d) =>
                                setReadConfirmationCase(x => ({
                                    ...x,
                                    documentId: d.value as number,
                                }))
                            }
                            required={true}
                        />
                    )}

                    <FormDropdown
                        label={t('skillPositions')}
                        options={positionOptions}
                        fluid
                        multiple
                        selection
                        value={readConfirmationCase.positionIds}
                        onChange={(_, d) =>
                            setReadConfirmationCase(x => ({
                                ...x,
                                positionIds: d.value as number[],
                            }))
                        }
                        required={true}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <ActionButton
                    type="button"
                    action={save}
                    icon
                    labelPosition="left"
                    primary
                    disabled={
                        (requireDocumentId && readConfirmationCase.documentId < 1) ||
                        !readConfirmationCase.positionIds?.length
                    }
                >
                    <SemanticFeatherIcon FeatherIcon={Save} size={'60%'} centerIcon={true} />{' '}
                    {t('save')}
                </ActionButton>
                <Button onClick={cancel} icon labelPosition="left" type="button">
                    <SemanticFeatherIcon
                        FeatherIcon={ArrowLeftCircle}
                        size={'60%'}
                        centerIcon={true}
                    />{' '}
                    {t('cancel')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default CreateReadConfirmationModal
