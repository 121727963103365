import React from 'react'
import styled from 'styled-components'

export enum ResponsiveTarget {
    Phone,
    Desktop,
}

export interface ResponsiveContentProps {
    target: ResponsiveTarget
}

const PhoneDisplayContainer = styled.div`
    display: none;

    @media (max-width: 600px) {
        display: block;
    }
`

const DesktopDisplayContainer = styled.div`
    display: none;

    @media (min-width: 600px) {
        display: block;
    }
`

const ResponsiveContent: React.FunctionComponent<ResponsiveContentProps> = ({
    target,
    children,
}) => {
    if (target === ResponsiveTarget.Phone)
        return <PhoneDisplayContainer>{children}</PhoneDisplayContainer>

    return <DesktopDisplayContainer>{children}</DesktopDisplayContainer>
}

export default ResponsiveContent
