import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import {en} from './locales/en/en'
import {sv} from './locales/sv/sv'

const resources = {
  en: {
    translation: en
  },
  sv: {
    translation: sv
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "sv",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  }, (err) => {
    if(err)
      console.error(err);

    if((window as any).language)
      i18n.changeLanguage((window as any).language);
  });

  export default i18n;