import React from 'react'
import { Routes, Route } from 'react-router'
import GoalDetailsPage from './goalDetails'
import GoalsPage from './goals'
import MeasurementsPage from './measurements'

const GoalsModule: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path={'/'} element={<GoalsPage />} />
            <Route path={':id'} element={<GoalDetailsPage />} />
            <Route path={'new'}>
                <Route path="" element={<GoalDetailsPage />} />
                <Route path=":parentId" element={<GoalDetailsPage />} />
            </Route>
            <Route path={'measurements'} element={<MeasurementsPage />} />
        </Routes>
    )
}

export default GoalsModule
