import React from 'react'
import { Modal, Grid, Checkbox, Segment, Header, Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Settings } from 'react-feather'
import SemanticFeatherIcon from '../../../icons/SemanticFeatherIcon'
import { Table } from '@tanstack/react-table'
import Spacer from '../../../common/spacer'

interface TableSettingsProps<T = any> {
    table: Table<T>

}

const TableSettings: React.FunctionComponent<TableSettingsProps> = ({
    table,
}) => {
    const { t } = useTranslation()

    return (
        <Modal
            trigger={
                <Button
                    icon={<SemanticFeatherIcon FeatherIcon={Settings} />}
                    floated="right"
                    type="button"
                    title={t('showSettings')}
                />
            }
            closeIcon
        >
            <Modal.Header>{t('settings')}</Modal.Header>
            <Modal.Content>
                <Segment>
                    <Header>{t('visibleColumns')}</Header>
                    <Spacer />
                    <Grid columns={3} stackable>
                        {table.getAllLeafColumns().filter((c) => c.columnDef.enableHiding !== false).map((column, i) => (
                            <Grid.Column
                                key={column.id}
                                style={{ paddingTop: '2px', paddingBottom: '2px' }}
                            >
                                <Checkbox
                                    toggle
                                    label={`${column.columnDef.header}`}
                                    checked={column.getIsVisible()}
                                    onChange={column.getToggleVisibilityHandler()}
                                />
                            </Grid.Column>
                        ))}
                    </Grid>
                    <Spacer />
                </Segment>
            </Modal.Content>
        </Modal>
    )
}

export default TableSettings
