import { useState } from 'react'

export type SortDirection = 'ascending' | 'descending'

export interface UseSortProps {
    initialSortDirection?: SortDirection
    initialSortProp?: string
}

export const useSort = <T extends unknown>(
    rows: T[] | undefined,
    options: UseSortProps | null = null,
) => {
    const [sortProp, setSortProp] = useState<string | undefined>(options?.initialSortProp)
    const [sortDirection, setSortDirection] = useState<SortDirection>(
        options?.initialSortDirection ?? 'ascending',
    )

    const sortBy = (prop: string, direction: SortDirection | null = null) => {
        if (direction) {
            setSortProp(prop)
            setSortDirection(direction)
        } else {
            if (sortProp === prop) {
                setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
            } else {
                setSortProp(prop)
            }
        }
    }

    const sortedRows = !sortProp
        ? rows
        : rows?.sort((a, b) => {
              const aProp = (a as any)[sortProp]
              const bProp = (b as any)[sortProp]

              if (aProp === bProp) return 0
              if (aProp < bProp) return sortDirection === 'ascending' ? -1 : 1
              return sortDirection === 'ascending' ? 1 : -1
          })

    return {
        sortedRows,
        sortProp,
        sortDirection,
        sortBy,
    }
}
