import React from 'react'
import { Divider } from 'semantic-ui-react'
import CustomRating from '../../../common/customRating'
import FlexBox from '../../../common/flexBox'
import SelectedButton from '../../../common/selectedButton'
import { QuestionTypeProps } from './questionTypeProps'

const RatingQuestion: React.FunctionComponent<QuestionTypeProps> = ({
    question: { addDontKnow },
    survey,
    currentAnswer,
    onAnswer,
}) => {
    const { maxRating, dontKnowText } = survey

    return (
        <FlexBox alignItems="center" direction="column">
            <CustomRating
                maxRating={maxRating ?? 5}
                rating={+currentAnswer}
                onChange={x => onAnswer(x)}
            />
            {addDontKnow && (
                <>
                    <Divider />

                    <SelectedButton selected={currentAnswer === 0} onChange={() => onAnswer(0)}>
                        {dontKnowText}
                    </SelectedButton>
                </>
            )}
        </FlexBox>
    )
}

export default RatingQuestion
