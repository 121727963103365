import { TableRow } from 'semantic-ui-react'
import styled from 'styled-components'

const InfoTableRow = styled(TableRow)<{
    $isInfo?: boolean
}>`
    ${props =>
        props.$isInfo
            ? `
        background-color: #f8ffff;
    `
            : ''}
`

export default InfoTableRow
