import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import * as z from 'zod';
import ControlInput from '../../fields/ControlInput';
import ControlTextArea from '../../fields/ControlTextArea';
import { getState } from '../../../state';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api'
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../actions/error';
import { toast } from 'react-toastify';
import { IssueHierarchyType, IssueType, IssueTypeHierarchy } from '../../../api/issues';

import FormActions from '../FormActions';
import { ExpandedState } from '@tanstack/react-table';

interface IssueTypeFormProps {
    onCancel: () => void
    refresh: () => void
    isNew: boolean
    issueHierarchyType: IssueHierarchyType
    selectedHierarchyItem: IssueTypeHierarchy | null
    selectedTableRowId?: string | null
    setExpanded?: React.Dispatch<React.SetStateAction<ExpandedState>>
    expanded?: ExpandedState
}

export const issueTypeRequestSchema = z.object({
    id: z.number(),
    name: z.string().min(1, "required"),
    description: z.string().optional().nullable(),
});

const IssueTypeForm = ({
    onCancel,
    refresh,
    isNew,
    issueHierarchyType,
    selectedHierarchyItem,
    selectedTableRowId,
    setExpanded,
    expanded
}: IssueTypeFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()

    const { control, handleSubmit } = useForm<IssueType>({
        resolver: zodResolver(issueTypeRequestSchema),
        defaultValues: selectedHierarchyItem && !isNew ? selectedHierarchyItem : { id: 0, description: "", name: "" }
    });

    const onIssueTypeRequest = async (data: IssueType) => {
        if (isNew) {
            if ((issueHierarchyType === IssueHierarchyType.ErrorCode || issueHierarchyType === IssueHierarchyType.CauseCode) && setExpanded && selectedTableRowId) {
                const castExpanded = expanded as Record<string, boolean>
                setExpanded({ ...castExpanded, ...{ [selectedTableRowId]: true } })
            }
            switch (issueHierarchyType) {
                case IssueHierarchyType.DeviationType:
                    return await API.issues.createIssueType(data)
                case IssueHierarchyType.ErrorCode:
                    return await API.issues.createIssueErrorCode(selectedHierarchyItem!.parentId, data)
                case IssueHierarchyType.CauseCode:
                    return await API.issues.createIssueCauseCode(selectedHierarchyItem!.id, data)
            }
        } else {
            switch (issueHierarchyType) {
                case IssueHierarchyType.DeviationType:
                    return await API.issues.editIssueType(selectedHierarchyItem!.id, data);
                case IssueHierarchyType.ErrorCode:
                    return await API.issues.editIssueErrorCode(selectedHierarchyItem!.id, data)
                case IssueHierarchyType.CauseCode:
                    return await API.issues.editIssueCauseCode(selectedHierarchyItem!.id, data)
            }
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onIssueTypeRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            refresh()
            onCancel()
        },
    })

    return (

        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn >
                        <ControlInput name="name" label={t('name')} control={control} required disabled={isSaving} />
                    </GridColumn>

                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlTextArea name="description" label={t('description')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default IssueTypeForm;
