import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Form, Dropdown, DropdownProps } from 'semantic-ui-react'
import { ConnectedMeasurementInfo } from '../../../hooks/goals'
import { Measurement, PeriodType } from '../../../hooks/goals/measurement'

export interface MeasurementConnectionProps {
    measurements?: Measurement[]
    standardMeasurements?: Measurement[]
    measurementsLoading?: boolean
    isKpi?: boolean
    connectedMeasurement?: ConnectedMeasurementInfo
    initialValueId?: number
    onMeasurementSelected: (
        connectedMeasurement: ConnectedMeasurementInfo | undefined,
        initialValueId: number | undefined,
    ) => void
}

const makeValue = (id: number, isStandard: boolean) => `${isStandard ? 's' : 'c'}${id}`

const MeasurementConnection: React.FunctionComponent<MeasurementConnectionProps> = ({
    measurements,
    standardMeasurements,
    measurementsLoading,
    isKpi,
    connectedMeasurement,
    initialValueId,
    onMeasurementSelected,
}) => {
    const { t } = useTranslation()
    const [selectedMeasurement, setSelectedMeasurement] = useState<
        ConnectedMeasurementInfo | undefined
    >()

    useEffect(() => {
        setSelectedMeasurement(connectedMeasurement)
    }, [connectedMeasurement])

    const onConnectedMeasurementChanged = (_: Event, data: DropdownProps) => {
        if (data.value) {
            const lookupValue = aggregatedMeasurements.find(x => x.value === data.value)
            if (!lookupValue) return

            setSelectedMeasurement({
                id: lookupValue.id,
                isStandard: lookupValue.isStandard,
            })

            onMeasurementSelected(
                {
                    id: lookupValue.id,
                    isStandard: lookupValue.isStandard,
                },
                undefined,
            )
        } else {
            setSelectedMeasurement(undefined)
            onMeasurementSelected(undefined, undefined)
        }
    }

    const onFirstMeasurementValueChanged = (_: Event, data: DropdownProps) => {
        if (!data.value) {
            onMeasurementSelected(selectedMeasurement, undefined)
        } else {
            onMeasurementSelected(selectedMeasurement, +data.value)
        }
    }

    const aggregatedMeasurements = useMemo(
        () => [
            ...(standardMeasurements
                ?.filter(x => x.periodId === PeriodType.Yearly)
                .map(x => ({
                    isStandard: true,
                    id: x.id!,
                    value: makeValue(x.id!, true),
                    text: x.detailedType,
                    measurements: x.measurements,
                })) ?? []),
            ...(measurements?.map(x => ({
                isStandard: false,
                id: x.id!,
                value: makeValue(x.id!, false),
                text: x.detailedType,
                measurements: x.measurements,
            })) ?? []),
        ],
        [measurements, standardMeasurements],
    )

    return (
        <>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Form.Field
                        label={t('connectedMeasurement')}
                        loading={measurementsLoading}
                        control={Dropdown}
                        selection
                        value={
                            selectedMeasurement
                                ? makeValue(
                                      selectedMeasurement?.id ?? 0,
                                      selectedMeasurement?.isStandard ?? false,
                                  )
                                : ''
                        }
                        options={aggregatedMeasurements.map(x => ({
                            text: x.text,
                            value: x.value,
                        }))}
                        onChange={onConnectedMeasurementChanged}
                        clearable
                    />
                </Grid.Column>
                <Grid.Column>
                    <Form.Field
                        label={t('start')}
                        loading={measurementsLoading}
                        control={Dropdown}
                        selection
                        disabled={isKpi}
                        value={initialValueId ?? ''}
                        options={
                            aggregatedMeasurements
                                ?.find(
                                    x =>
                                        x.id === selectedMeasurement?.id &&
                                        x.isStandard === selectedMeasurement?.isStandard,
                                )
                                ?.measurements?.map(x => ({
                                    text: `${x.displayKey} (${x.value})`,
                                    value: x.id,
                                })) ?? []
                        }
                        onChange={onFirstMeasurementValueChanged}
                        clearable
                    />
                </Grid.Column>
            </Grid.Row>
        </>
    )
}

export default MeasurementConnection
