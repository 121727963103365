import * as API from "../../../../../api"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { handleAxiosError } from "../../../../../actions/error"
import { getState } from "../../../../../state"

export function useSendActivityNotification() {
    const { t } = useTranslation()
    const { dispatch } = getState()
    const notificationActivity = async (activityId: number) => await API.improvements.notifyActivity(activityId)

    const { isLoading: isSending, mutate: onNotify } = useMutation(notificationActivity, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: (data) => {
            if (data) {
                toast(t('mailHasBeenSend'), {
                    type: 'success',
                })
            }
        },
    })

    return {
        isSending,
        onNotify,
    }
}