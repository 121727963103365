import React from "react"
import { IExtraField, } from "../../../../api/issues"
import { isArray } from "lodash"
import { getState } from "../../../../state"
import { getUserFromState } from "../../../../state/helpers"

interface IExtraFieldCellProps {
    field: IExtraField
    value: string | string[]

}

const ExtraFieldCell = ({ field, value }: IExtraFieldCellProps) => {
    const { state } = getState()
    if (field.type === 4 && value && isArray(value)) return <span>{value.map(id => getUserFromState(state)(id)?.name ?? '')
        .join(', ')}</span>
    return <span>{value || ''}</span>
}
export default ExtraFieldCell
