import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Confirm, ConfirmProps } from 'semantic-ui-react';

export interface CustomConfirmProps {
    text?: string;
    buttonOkText?: string;
    buttonCancelText?: string;
    onCancel?: (event: React.MouseEvent<HTMLAnchorElement>, data: ConfirmProps) => void
    onConfirm: (event: React.MouseEvent<HTMLAnchorElement>, data: ConfirmProps) => void
    trigger?: React.ReactNode;
};

const CustomConfirm: React.FunctionComponent<CustomConfirmProps> = ({
    text,
    buttonOkText,
    buttonCancelText,
    onConfirm,
    onCancel,
    trigger
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false); 

    const derivedOnConfirm = (event: React.MouseEvent<HTMLAnchorElement>, data: ConfirmProps) => {
        setOpen(false);
        onConfirm?.(event, data);
    };

    const derivedOnCancel = (event: React.MouseEvent<HTMLAnchorElement>, data: ConfirmProps) => {
        setOpen(false);
        onCancel?.(event, data);
    };

    return <Confirm content={text ?? t('areYouSure')} 
                    confirmButton={buttonOkText ?? t('yes')}
                    cancelButton={buttonCancelText ?? t('no')}
                    onConfirm={derivedOnConfirm}
                    onCancel={derivedOnCancel}
                    trigger={trigger}
                    size="mini" 
                    open={open}
                    onOpen={() => setOpen(true) }
                    onClose={() => setOpen(false) } />;
};

export default CustomConfirm;