import React, { FunctionComponent } from 'react'
import { AxiosRequestConfig } from 'axios'
import { SWRConfig, SWRConfiguration, Fetcher } from 'swr'
import { handleAxiosError } from './actions/error'
import { getAuthedAxios } from './auth'
import { getState } from './state'

export const defaultFetcher = (args: AxiosRequestConfig) =>
    getAuthedAxios().then(axios => axios(args).then(response => response.data))

const SWRConfigurationProvider: FunctionComponent = ({ children }) => {
    const { dispatch } = getState()

    const configValues: SWRConfiguration<any, any, Fetcher<any>> = {
        fetcher: defaultFetcher,
        onError: error => handleAxiosError(dispatch)(error),
        focusThrottleInterval: 20000,
    }

    return <SWRConfig value={configValues}>{children}</SWRConfig>
}

export default SWRConfigurationProvider
